<mat-dialog-content class="report-sharing">
  <p *ngIf="message" class="message">{{ message }}</p>
  <p class="mt-0">Share the report</p>
  <div class="flex flex-column align-items-stretch">
    <button class="mb-2" mat-stroked-button color="primary" (click)="sendByMail()">
      <mat-icon class="mr-1">mail</mat-icon>
      <span class="inline-block mr-2">Share by mail</span>
    </button>
    <!--    Commented out until we have share by SMS feature   -->
    <!--    <button class="mb-2" mat-stroked-button color="primary"><mat-icon class="mr-1">sms</mat-icon>Share SMS link</button>-->

    <button
      class="mb-2"
      mat-stroked-button
      color="primary"
      (click)="showMessage()"
      [ngxClipboard]="reportSharingLink"
      (cbOnSuccess)="isCopied = true"
      [class.copied-success]="isCopied"
    >
      <mat-icon class="mr-1">link</mat-icon>
      <span class="inline-block mr-5">Copy Link</span>
    </button>

    <mat-form-field class="link-form">
      <textarea #reportSharingLink matInput readonly [value]="link"></textarea>
    </mat-form-field>
  </div>

  <mat-divider *ngIf="data.project?.nextProjectId || (shouldShowAnchor && link)" class="align-self-stretch my-3"></mat-divider>

  <section *ngIf="data.project?.nextProjectId || (shouldShowAnchor && link)" class="export-and-share-link-section">
    <div *ngIf="data.project?.nextProjectId">
      <p class="mt-0">Export to your connected project</p>
      <button class="export-button" mat-flat-button (click)="shareToNextProject()">Export to Next Project</button>
    </div>

    <div *ngIf="shouldShowAnchor && link">
      <p>Click <a class="go-to-report-link" [href]="link" target="_blank">here</a> to go to the report</p>
    </div>
  </section>
</mat-dialog-content>
