<div
  fxLayout
  fxLayoutAlign="start center"
  class="project-wrapper"
  tooltipStyleClass="project-tooltip"
  pTooltip="{{ tooltipText }}"
  tooltipPosition="top"
>
  <div class="star-wrapper">
    <button mat-icon-button [class.not-favorite]="!isFavorite" (click)="favoriteToggled.emit()">
      <mat-icon>
        {{ isFavorite ? "star" : "star_outline" }}
      </mat-icon>
    </button>
  </div>

  <mat-card class="project-card tooltip" (click)="projectSelected.emit(project)" matRipple fxLayout="column">
    <div fxLayout="row" fxLayoutGap="10px">
      <div mat-card-avatar class="project-icon"></div>
      <div fxLayout="column" fxLayoutAlign="space-evenly">
        <span class="project-title">
          {{ project.name }}
        </span>

        <div class="project-counts" fxLayout="row" fxLayoutGap="10px">
          <span *ngIf="usersCount" class="users-count">{{ usersCountStr }}</span>
          <span *ngIf="drawingsCount" class="drawings-count">{{ drawingsCountStr }}</span>
          <span *ngIf="itemsCount" class="items-count">{{ itemsCountStr }}</span>
          <!--          <span *ngIf="totalReportsCount" class="reports-count">{{totalReportsCountStr}}</span>-->
          <span *ngIf="currentUserActiveItems !== null" [ngClass]="currentUserActiveItems > 0 ? 'red-text' : 'green-text'">
            {{ currentUserActiveItemsStr }}
          </span>
        </div>
      </div>
    </div>
  </mat-card>
</div>
