<div class="project-drawings-container" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
  <app-drawing-list
    *ngIf="projectService.hasDrawingsFeature(project)"
    [currentUser]="currentUser"
    [showDrawingOptions]="hasCRUDRights"
    [userProjectRole]="currentUserProjectRole"
    [canDeleteDrawings]="canDeleteDrawings"
    [drawings]="projectDrawings"
    (menuOptionSelected)="onMenuOptionSelected($event)"
    (drawingClick)="openDrawing($event)"
  >
  </app-drawing-list>
</div>

<div *ngIf="projectService.hasDrawingsFeature(project)" class="floating-bottom-actions">
  <button
    primary-button
    [isRounded]="true"
    *ngIf="canAddDrawings"
    (click)="addDrawing()"
    [disabled]="project.archived || project.lockedByCheckd"
  >
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3910_12557)">
        <path
          d="M24 11.9994C24 12.7667 23.3827 13.384 22.6154 13.384H13.3846V22.6148C13.3846 23.3792 12.7644 24 12 24C11.2356 24 10.6154 23.3821 10.6154 22.6148V13.384H1.38462C0.620192 13.384 0 12.7644 0 12C0 11.2379 0.620192 10.6148 1.38462 10.6148H10.6154V1.38404C10.6154 0.619615 11.2356 0 12 0C12.7644 0 13.3846 0.619615 13.3846 1.38404V10.6148H22.6154C23.3827 10.6148 24 11.2379 24 11.9994Z"
          fill="currentcolor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3910_12557">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <span>Add drawings</span>
  </button>
</div>
