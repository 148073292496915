import { TemplateElement, TemplateElementType } from "./template-element"

class CheckboxValueElement extends TemplateElement {
  id?: number = 0
  override name: string = ""
  override type: TemplateElementType = TemplateElementType.CHECKBOX_VALUE
  override typeAlias: string = "Checkbox"
  override value = false
  override children: any[] = []
}

export { CheckboxValueElement }
