<div class="wrapper">
  <mat-form-field>
    <mat-label>{{ _placeholder }}</mat-label>
    <mat-date-range-input [formGroup]="rangeForm" [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Start date" />
      <input matEndDate formControlName="end" placeholder="End date" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="rangeForm.controls['start'].hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="rangeForm.controls['end'].hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field>
</div>
