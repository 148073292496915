import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core"

@Component({
  selector: "checkd-empty-state",
  templateUrl: "./empty-state.component.html",
  styleUrls: ["./empty-state.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input() title: string = "No results"
  @Input() text: string = ""
  @Input() image: string = ""
}
