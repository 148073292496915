import { Injectable } from "@angular/core"
import firestore = firebase.firestore
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { ILegacyTemplateChangelogData } from "@models/common/legacy-template-changelog.interface"
import { Timestamp } from "firebase/firestore"
import { COLLECTIONS, ModelInCollection, Person } from "../models/common"
import firebase from "firebase/compat/app"
@Injectable({
  providedIn: "root",
})
export class DocumentHistoryService {
  constructor(private db: AngularFirestore) {}

  async batchAddToHistory(batch: firestore.WriteBatch, model: ModelInCollection, editor: Person) {
    const previous = await this.db.doc(model.ref!.path).get().toPromise()
    const previousData = previous!.data() as any
    const subCollection = model.ref!.collection(COLLECTIONS.HISTORY)
    const historySnapshotDate = firebase.firestore.FieldValue.serverTimestamp()
    const editorUid = editor.uid || null
    const data = {
      ...previousData,
      historySnapshotDate,
      editorUid,
    }
    const doc = subCollection.doc()
    batch.set(doc, data)

    return doc
  }

  batchAddChangelog(
    batch: firestore.WriteBatch,
    model: ModelInCollection,
    data: { changelog: string; editorUid: string; historyUid: string | null; internalVersion: number; publicVersion: string }
  ) {
    const changelogData: ILegacyTemplateChangelogData = {
      ...data,
      publishedAt: Timestamp.now(),
      severity: "info",
    }

    const changelogRef = model.ref!.collection(COLLECTIONS.CHANGELOG).doc()
    batch.set(changelogRef, changelogData)
  }
}
