import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router"
import { Company, CompanyFeatures, LABELS, Role } from "@models/common"
import { UserService } from "@services"
import { combineLatest, Observable } from "rxjs"
import { map } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class InsightsGuard {
  constructor(private userService: UserService) {}

  // Should return true if these conditions are met:
  // 1. the current user's company has the 'Company Insights' feature
  // 2. the current user is a company admin
  // false otherwise
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([this.userService.currentCompany$, this.userService.currentUserCompanyRole$]).pipe(
      map(([company, userRole]) => {
        return this.currentCompanyHasCompanyInsightsFeature(company) && this.userIsCompanyAdmin(userRole)
      })
    )
  }

  currentCompanyHasCompanyInsightsFeature(currentCompany: Company) {
    return currentCompany && (currentCompany.features || []).includes(CompanyFeatures.COMPANY_INSIGHTS)
  }

  userIsCompanyAdmin(userCompanyRole: Role): boolean {
    return userCompanyRole && userCompanyRole.labels.includes(LABELS.ADMINISTRATOR)
  }
}
