import { DateFormatPipe, FromUnixPipe } from "ngx-moment"

export function formatTimestamp(timestamp: any, withTime = false) {
  // debugger
  if (isNaN(timestamp as any)) {
    return timestamp
  }

  // FIXME Ugly hack to make sure we don't get weird dates from createdAt in items stored in e.g. reports
  const fixedTimestamp = (timestamp || 0) > 32503680000 ? timestamp * 0.001 : timestamp

  // In case timestamp is 0
  if (!fixedTimestamp) {
    return ""
  }

  // const fromUnix = new FromUnixPipe().transform(timestamp / 1000)
  const fromUnix = new FromUnixPipe().transform(fixedTimestamp)

  // return new DateFormatPipe().transform(fromUnix, 'D MMMM YYYY HH:mm')
  return new DateFormatPipe().transform(fromUnix, withTime ? "MMM Do, YYYY HH:mm" : "MMM Do, YYYY")
}

// used with pdfmake
export function mmToPoints(mm: string | number) {
  const pointRatio = 2.8346456692913384

  if (typeof mm === "string") {
    return parseFloat(mm) * pointRatio
  }

  return mm * pointRatio
}
