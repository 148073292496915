<div #notificationsContainer class="notifications-dropdown-container">
  <button mat-icon-button class="notifications-dropdown-button" (click)="toggleNotificationsDropdownMenuShownState()">
    <mat-icon
      matBadge="{{ unreadCount$ | async }}"
      matBadgeSize="small"
      matBadgeColor="warn"
      aria-hidden="false"
      [matBadgeHidden]="(unreadCount$ | async)! <= 0"
    >
      notifications_none
    </mat-icon>
  </button>

  <div
    id="notifications"
    class="notifications-dropdown-content show-notifications-dropdown"
    [ngClass]="{ 'show-notifications-dropdown': notificationsShownState }"
  >
    <div class="arrow-container"></div>
    <div class="notifications-top-menu" fxLayout="row">
      <div class="top-menu-1st-col" fxFlex="75">
        <span class="notifications-text">Notifications</span>
      </div>

      <div class="top-menu-2nd-col" fxFlex="25">
        <div (click)="markAllAsRead()" class="mark-all-as-read-div">
          <mat-icon class="mark-all-as-read-icon">done_all</mat-icon>
          <span class="mark-all-as-read">Mark all as read</span>
        </div>
      </div>
    </div>

    <cdk-virtual-scroll-viewport class="notifications-area" itemSize="70" minBufferPx="900" maxBufferPx="1800">
      <ng-container *cdkVirtualFor="let notification of notifications$ | async; trackBy: trackByFn; templateCacheSize: 0">
        <checkd-notification-row-item (notificationClicked)="notificationClicked($event)" [notification]="notification">
        </checkd-notification-row-item>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
