export enum NotificationType {
  // -New task # <title> is assigned to you by <name>
  TASK_NEW_ASSIGNED = "TASK_NEW_ASSIGNED",

  // -Task # <title_task> was rejected by <name>, and needs your attention
  TASK_REJECTED = "TASK_REJECTED",

  TASK_CHAT_MESSAGE_NEW = "TASK_CHAT_MESSAGE_NEW",
  ITEM_CHAT_MESSAGE_NEW = "ITEM_CHAT_MESSAGE_NEW",

  // -Task # <title_task> was set as fixed by <name>, and is ready for your review
  TASK_FIXED = "TASK_FIXED",

  TASK_FIX_REJECTED = "TASK_FIX_REJECTED",

  TASK_COLLABORATOR_ADDED = "TASK_COLLABORATOR_ADDED",
  TASK_ACCEPTED = "TASK_ACCEPTED",
  TASK_RETRACTED = "TASK_RETRACTED",
  TASK_COMPLETED = "TASK_COMPLETED",
  TASK_DUE_TOMORROW = "TASK_DUE_TOMORROW",
  TASK_DUE_ONEWEEK = "TASK_DUE_ONEWEEK",
  TASK_OVERDUE = "TASK_OVERDUE",
  TASK_NOTIFIED_BY = "TASK_NOTIFIED_BY",
  PROJECT_DRAWING_ADDED = "PROJECT_DRAWING_ADDED",
  PROJECT_DRAWING_REVISED = "PROJECT_DRAWING_REVISED",
  PROJECT_USER_ADDED = "PROJECT_USER_ADDED",
  PROJECT_USER_INVITED = "PROJECT_USER_INVITED",
  PROJECT_ARCHIVED = "PROJECT_ARCHIVED",
  PROJECT_UNARCHIVED = "PROJECT_UNARCHIVED",

  NONE = "NONE",
}

export const INSTANT_NOTIFICATION_TYPES = [
  NotificationType.TASK_COLLABORATOR_ADDED,
  NotificationType.TASK_DUE_TOMORROW,
  NotificationType.TASK_DUE_ONEWEEK,
  NotificationType.TASK_OVERDUE,
  NotificationType.TASK_NOTIFIED_BY,
  NotificationType.TASK_FIX_REJECTED,

  NotificationType.PROJECT_DRAWING_ADDED,
  NotificationType.PROJECT_DRAWING_REVISED,
  NotificationType.PROJECT_USER_ADDED,
  NotificationType.PROJECT_USER_INVITED,
]

export interface NotificationDataSnapshot {
  [key: string]: any
}

export interface TaskNotificationDataSnapshot extends NotificationDataSnapshot {
  taskTitle?: string
  assignerName?: string
}
