import { EMPTY, Observable } from "rxjs"

import { map } from "rxjs/operators"
import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import {
  COLLECTIONS,
  convertWorkflowStateToStatus,
  Image,
  Item,
  ItemTaskHandler,
  LABELS,
  Person,
  Task,
  WorkflowStates,
} from "@models/common"

import { RelationService } from "./relation.service"
import { ModelService } from "./model.service"
import { TimelineService } from "@services/timeline.service"

@Injectable()
export class TaskService {
  constructor(
    private db: AngularFirestore,
    private modelService: ModelService,
    private relationService: RelationService,
    private timelineService: TimelineService
  ) {}

  listenToUid(taskUid: string) {
    return this.modelService.listenTo(COLLECTIONS.TASKS, taskUid) as Observable<Task>
  }

  public async updateTaskStatus(updater: Person, task: Task, item: Item, status: string, itemImages: Image[]) {
    const batch = this.db.firestore.batch()
    ItemTaskHandler.batchSetStatus(batch, status, item, task)

    await Promise.all(
      itemImages.map((image) =>
        image.batchUpdateAggregateData(batch, {
          itemStatus: status,
        })
      )
    )

    await this.timelineService.batchStatusChanged(batch, updater, item, task)
    await batch.commit()
  }

  async assign(task: Task, assigner: Person, assignee: Person, itemImages: Image[]): Promise<Task> {
    const batch = this.db.firestore.batch()
    const assignedTask = await ItemTaskHandler.batchAssignOrReassignTask(batch, task, assigner, assignee)

    if (assignedTask.status) {
      await Promise.all(
        itemImages.map((image) =>
          image.batchUpdateAggregateData(batch, {
            itemStatus: assignedTask.status,
          })
        )
      )
    }

    await batch.commit()
    return assignedTask
  }

  workflowStateToStatus(state: string) {
    return convertWorkflowStateToStatus(state) || "OPEN"
  }

  statusToColorClass(status: string) {
    const mappings: { [key: string]: string } = {
      OPEN: "status-color-white",
      DELEGATED: "status-color-red",
      INPROGRESS: "status-color-yellow",
      FIXED: "status-color-blue",
      CLOSED: "status-color-green",
    }

    return mappings[status] ?? "status-color-white"
  }
}
