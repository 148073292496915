<div class="project-settings-view flex flex-column">
  <app-settings-group [readonly]="!canEditProject" title="Info" (onEditable)="onEditable($event, 'info')" (onSave)="save('info')">
    <div class="project-info-box flex flex-column lg:flex-row flex-wrap justify-content-around">
      <span *ngIf="project.aggregateData.projectCreatorName">Created by: {{ project.aggregateData.projectCreatorName }}</span>
      <span>Created on: {{ formatTimestamp(project.createdAt) }}</span>
      <span>Owner Company: {{ project.aggregateData.companyName }}</span>
    </div>
    <form [formGroup]="projectInfo" class="flex flex-column justify-content-between" novalidate>
      <mat-form-field>
        <input
          formControlName="address"
          matInput
          placeholder="Adresse/Address"
          matTooltip="Her fyller du inn hvor prosjektet skal være. / Here you fill where the project is located."
        />
      </mat-form-field>

      <mat-form-field>
        <input
          formControlName="name"
          matInput
          placeholder="Navn/Name"
          matTooltip="Her fyller du ut prosjektnavn. / Fill in the name of your project."
        />
      </mat-form-field>
    </form>

    <h4>Item tags</h4>
    <checkd-tags
      [tags]="tags"
      [areTagsEditable]="isInfoEditable"
      (onTagsUpdated)="onTagsUpdated($event)"
      matTooltip="Disse taggene vil vises som valg hver gang du oppretter ett punkt i dette prosjektet.
Tags er nøkkelord som beskriver punkter, og ved å bruke de vil punktene dine bli organisert og lettere å finne senere.
Eksempler på tagger inkluderer: rørlegger, maler, snekker, HMS og KS, kvalitetskontroll, avvik. /
These tags will appear as choices every time you create an item in this project.
Tags are keywords that describe items, and by using them, your items and tasks will be organised and easier to find later.
Examples of tags include: plumber, painter, carpenter, HSEQ, snag, quality check."
    >
    </checkd-tags>

    <h4>Report tags</h4>
    <checkd-tags
      [tags]="reportTagsCollection != null ? reportTagsCollection.tags : []"
      [areTagsEditable]="isInfoEditable"
      (onTagsUpdated)="onReportTagsUpdated($event)"
      placeholder="Report tags"
      secondaryPlaceholder="Report tags"
      matTooltipPosition="above"
      matTooltip="Report tags works in the same way as Item tags, and will help you to sort and filter your reports.
      You and your project members can now tag reports created in the project, and later filter out only the relevant reports.
      Examples for good report tags are: “APPROVED”, “HSEQ”, “SENT-FOR-REVIEW” etc.
      You have the freedom to define how you want to sort all your documentation.
      PS: Report tags are only visible in the project they have been implemented in, just as regular item tags."
    >
    </checkd-tags>
  </app-settings-group>

  <app-settings-group
    *ngIf="canAddProjectTemplates"
    [readonly]="!canEditProject || !canEditProjectTemplatesSection"
    title="Project FORMS Templates"
    (onEditable)="onEditable($event, 'templates')"
    (onSave)="save('templates')"
  >
    <p *ngIf="projectTemplates && projectTemplates.length === 0 && !isTemplatesEditable">
      This project has no templates yet. You can add templates by clicking the edit button in the top right corner of this box.
    </p>
    <ng-container *ngIf="!isTemplatesEditable">
      <p
        *ngIf="project.hasPublicTemplates"
        matTooltip="All project members can see, create, and use FORMS based on the templates you chose here.
This includes subcontractors that are not members of your company."
        matTooltipPosition="above"
      >
        All members connected to this project can use project-specific FORMS from templates chosen here.
      </p>
      <p
        *ngIf="!project.hasPublicTemplates"
        matTooltip="Only project members that are also members of your company can see, create and use FORMS based on the templates you chose here."
        matTooltipPosition="above"
      >
        Project members in{{ companyNameText }}can use FORMS from templates chosen here. Other members connected to this project can not.
      </p>
      <mat-chip-list [compareWith]="compareTemplateChips">
        <mat-chip *ngFor="let template of projectTemplates" [selectable]="false" disableRipple="true">{{ template.name }}</mat-chip>
      </mat-chip-list>
    </ng-container>

    <ng-container *ngIf="canAddProjectTemplates && isTemplatesEditable">
      <checkd-project-template-list
        [companyTemplates]="companyTemplates"
        [preselectedTemplates]="projectTemplates"
        [hasPublicTemplates]="project?.hasPublicTemplates"
        (selectedTemplates)="onSelectedTemplatesChange($event)"
        (hasPublicTemplates)="onHasPublicTemplatesChange($event)"
      >
      </checkd-project-template-list>
    </ng-container>
  </app-settings-group>

  <app-settings-group
    title="FORMS notification/alerts rules"
    [readonly]="true"
    *ngIf="currentCompany?.features?.includes(CompanyFeatures.FORMS)"
  >
    <checkd-project-forms-notifications-rules
      [projectOwnersAndAdmins]="projectOwnersAndAdmins"
      [projectTemplates]="projectTemplates"
      [companyTemplates]="projectCompanyTemplates"
      [project]="project"
      [readOnly]="!canEditProject"
    >
    </checkd-project-forms-notifications-rules>
  </app-settings-group>

  <app-settings-group
    [readonly]="!canEditProject"
    title="Description"
    (onEditable)="onEditable($event, 'description')"
    (onSave)="save('description')"
  >
    <form [formGroup]="projectDescription" class="flex flex-column justify-content-between" novalidate>
      <mat-form-field>
        <textarea
          cdkTextareaAutosize="true"
          cdkAutosizeMaxRows="20"
          cdkAutosizeMinRows="3"
          matAutosize="true"
          matTextAreaAutosize="true"
          matAutosizeMaxRows="20"
          matAutosizeMinRows="3"
          formControlName="description"
          matInput
          matTooltip="Her kan du legge til mer informasjon om prosjektet. / Here you can add more information about the project."
        ></textarea>
      </mat-form-field>
    </form>
  </app-settings-group>

  <app-settings-group [readonly]="!canEditProject" title="Details" (onEditable)="onEditable($event, 'details')" (onSave)="save('details')">
    <form [formGroup]="projectDetails" class="flex flex-column justify-content-between" novalidate>
      <mat-form-field>
        <input
          formControlName="municipalNumber"
          matInput
          placeholder="Kommunenummer/Municipal number"
          matTooltip="Her fyller du inn kommunenummeret hvor prosjektet skal utføres. Dette er valgfritt. / Here you can add the municipal number where your project is located. This is optional."
        />
      </mat-form-field>

      <mat-form-field>
        <input
          formControlName="cadastrialUnitNumber"
          matInput
          placeholder="Gårdsnummer/Cadastral unit number"
          matTooltip="Her fyller du inn gårdsnummeret hvor prosjektet skal utføres. Dette er valgfritt. / Here you can add the cadastral unit number where your project is located. This is optional."
        />
      </mat-form-field>

      <mat-form-field>
        <input
          formControlName="idNumber"
          matInput
          placeholder="ID-nummer/ID number"
          matTooltip="Her kan du legge til prosjektets ID-nummer. Dette er valgfritt. / If your project has a specific ID number, you can fill it in here."
        />
      </mat-form-field>

      <mat-form-field>
        <input
          formControlName="projectPhase"
          matInput
          placeholder="Prosjektfase/Project phase"
          matTooltip="Her kan du beskrive hvilken fase, eller faser, prosjektet er i. Dette er valgfritt. / Here you can describe whice phase your project is in. This is optional."
        />
      </mat-form-field>

      <mat-form-field>
        <input
          formControlName="owner"
          matInput
          placeholder="Prosjekteier/Owner"
          matTooltip="Her kan du definere prosjektets eier. Typisk er dette en byggherre, hovedentreprenør e.l. Dette er valgfritt. / Here you can define who owns the project. This is typically a main entrepreneur, a building client etc. This is optional."
        />
      </mat-form-field>

      <mat-form-field>
        <input
          formControlName="client"
          matInput
          placeholder="Klient/Client"
          matTooltip="Her kan du definere hvem som bestiller prosjektet, eller hvem som skal overta det. Dette kan være byggherre, hovedentreprenør, kunde som tar over bolig e.l. Dette er valgfritt. / Here you can define who’s the project client. This is typically a main entrepreneur, a building client, customer etc. This is optional."
        />
      </mat-form-field>

      <mat-form-field>
        <input
          formControlName="cost"
          matInput
          placeholder="Kostnad/Cost"
          matTooltip="Her fyller du inn estimert kostnad for prosjektet. Du kan revidere dette feltet senere ved behov. / Here you fill in the cost related to the project. You can edit this at any time."
        />
      </mat-form-field>
    </form>
  </app-settings-group>

  <button
    *ngIf="canEditProject && projectCompany?.showNextProjectIntegration"
    (click)="connectNextProjectClicked()"
    class="flex-1 align-self-center"
    primary-button
    [isRounded]="true"
  >
    <span>
      Connect this project to a <strong><u>Next Project</u></strong> project
    </span>
  </button>
  <p *ngIf="project.nextProjectId && currentlyConnectedNextProject" class="flex-1 align-self-center">
    Currently connected to: {{ currentlyConnectedNextProject.name }}<strong></strong>
  </p>
</div>
