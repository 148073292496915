import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-leave-room-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" [attr.viewBox]="getViewBox()" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.875 20.25H4.5C3.2625 20.25 2.25 19.2375 2.25 18V6C2.25 4.7625 3.2625 3.75 4.5 3.75H7.875C8.49844 3.75 9 3.24609 9 2.625C9 2.00344 8.49844 1.5 7.875 1.5H4.5C2.01469 1.5 0 3.51469 0 6V18C0 20.4853 2.01469 22.5 4.5 22.5H7.875C8.49844 22.5 9 21.9984 9 21.375C9 20.7516 8.49844 20.25 7.875 20.25ZM23.6203 11.1844L16.4766 4.89375C16.0151 4.48359 15.3516 4.38375 14.7848 4.63739C14.2312 4.88906 13.875 5.43281 13.875 6.03281V8.62547H8.25C7.21594 8.62547 6.375 9.46641 6.375 10.5005V13.5005C6.375 14.5345 7.21594 15.3755 8.25 15.3755H13.875V17.9681C13.875 18.5658 14.2324 19.1123 14.7848 19.3612C14.9929 19.455 15.214 19.5004 15.4336 19.5004C15.8115 19.5004 16.185 19.3642 16.4766 19.1049L23.6203 12.8658C23.8641 12.6516 24 12.3469 24 12.0234C24 11.7 23.8641 11.3953 23.6203 11.1844ZM16.125 16.4062V13.0828H8.625V10.8328H16.125V7.55156L21.1781 11.9831L16.125 16.4062Z"
        fill="currentColor"
        [attr.transform]="getScale()"
      />
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class LeaveRoomIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }

  getViewBox(): string | undefined {
    return this.svgIconsService.getViewBox(this.size)
  }
  getScale(): string | undefined {
    return this.svgIconsService.getScale(this.size)
  }
}
