import { Component } from "@angular/core"
import { DefaultElementComponent } from "../default-element/default-element.component"

@Component({
  selector: "app-checkbox-element",
  templateUrl: "./checkbox-element.component.html",
  styleUrls: ["./checkbox-element.component.scss"],
})
export class CheckboxElementComponent extends DefaultElementComponent {
  override get value() {
    return this.getValue() === "true"
  }

  override set value(checked: boolean) {
    this.setValue(`${checked}`)
  }
}
