<!-- For large screens -->
<mat-toolbar fxHide fxShow.gt-md>
  <section class="left" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="40">
    <ng-container *ngTemplateOutlet="itemsCountLabel"></ng-container>

    <ng-container *ngIf="!hideItemActions">
      <ng-container *ngTemplateOutlet="createReportButton"></ng-container>
      <ng-container *ngTemplateOutlet="moreActionsButton"></ng-container>
    </ng-container>
  </section>

  <section class="middle" fxFlex>
    <ng-container *ngTemplateOutlet="searchBox"></ng-container>
  </section>

  <section class="right" fxFlex="40" fxLayout fxLayoutAlign="end center" fxLayoutGap="10px">
    <ng-container *ngTemplateOutlet="columnSelectionButton"></ng-container>
    <ng-container *ngTemplateOutlet="newItemButton"></ng-container>
  </section>
</mat-toolbar>

<!-- For smaller screens -->
<mat-toolbar fxHide fxShow.lt-lg fxLayoutAlign="space-between center" fxLayoutGap="10px">
  <ng-container *ngTemplateOutlet="itemsCountLabel"></ng-container>

  <ng-container *ngIf="!hideItemActions">
    <ng-container *ngTemplateOutlet="actionsButton"></ng-container>
  </ng-container>

  <span fxFlex="1 1 auto"></span>
  <ng-container *ngTemplateOutlet="columnSelectionButton"></ng-container>
  <ng-container *ngTemplateOutlet="newItemButton"></ng-container>
</mat-toolbar>

<ng-template #newItemButton>
  <!-- <button
    *ngIf="!hideNewItemButton"
    mat-button
    class="checkd-btn cancel-btn-color new-item-button"
    (click)="itemActionClicked.emit('new_item')"
    [disabled]="projectIsArchivedOrLocked"
  >
    <mat-icon class="new-item-icon">add_circle_outline</mat-icon>
    NEW ITEM
  </button> -->
  <button
    primary-button
    [isRounded]="true"
    *ngIf="!hideNewItemButton"
    (click)="itemActionClicked.emit('new_item')"
    [disabled]="projectIsArchivedOrLocked"
    class="new-item-button"
  >
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3910_12557)">
        <path
          d="M24 11.9994C24 12.7667 23.3827 13.384 22.6154 13.384H13.3846V22.6148C13.3846 23.3792 12.7644 24 12 24C11.2356 24 10.6154 23.3821 10.6154 22.6148V13.384H1.38462C0.620192 13.384 0 12.7644 0 12C0 11.2379 0.620192 10.6148 1.38462 10.6148H10.6154V1.38404C10.6154 0.619615 11.2356 0 12 0C12.7644 0 13.3846 0.619615 13.3846 1.38404V10.6148H22.6154C23.3827 10.6148 24 11.2379 24 11.9994Z"
          fill="currentcolor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3910_12557">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <span> New item </span>
  </button>
</ng-template>

<ng-template #columnSelectionButton>
  <checkd-item-table-column-selection-menu
    fxHide.xs
    *ngIf="columnsEditable"
    [columns]="editableColumns"
    (columnsChange)="editableColumnsChange.emit($event)"
  >
  </checkd-item-table-column-selection-menu>
</ng-template>

<ng-template #itemsCountLabel>
  <div *ngIf="!hideItemsSelectedText">
    <span class="rounded-borders" [class.items-selected]="selectedItems.length > 0">{{ selectedItems.length }}</span>
    <span class="selected-text" fxHide.xs>selected</span>
  </div>
</ng-template>

<ng-template #createReportButton>
  <button
    fxHide.lt-md
    mat-stroked-button
    class="create-report-button"
    [disabled]="projectIsArchivedOrLocked"
    (click)="itemActionClicked.emit('create_report')"
  >
    <mat-icon class="report-icon" svgIcon="items-report"></mat-icon>
    CREATE REPORT
  </button>
</ng-template>

<ng-template #actionsButton>
  <button [matMenuTriggerFor]="moreActionsMenu" mat-stroked-button class="actions-button">
    ACTIONS <mat-icon>arrow_drop_down</mat-icon>
  </button>
</ng-template>

<ng-template #moreActionsButton>
  <button [matMenuTriggerFor]="moreActionsMenu" mat-stroked-button class="actions-button">
    MORE ACTIONS <mat-icon>arrow_drop_down</mat-icon>
  </button>
</ng-template>

<ng-template #searchBox>
  <div class="search-input-container" fxLayout="row" fxLayoutAlign="center center">
    <input
      type="text"
      [ngModel]="searchString"
      (ngModelChange)="searchStringChange.emit($event)"
      placeholder="Search for items by title"
      size="30"
    />
    <mat-icon>search</mat-icon>
  </div>
</ng-template>

<mat-menu #moreActionsMenu="matMenu">
  <button [disabled]="projectIsArchivedOrLocked" mat-menu-item fxHide.gt-md (click)="itemActionClicked.emit('create_report')">
    CREATE REPORT
  </button>
  <button mat-menu-item (click)="itemActionClicked.emit('export_to_CSV')">EXPORT TO CSV</button>
  <button mat-menu-item *ngIf="canExportToBcf" (click)="itemActionClicked.emit('export_to_BCF')">EXPORT TO BCF</button>
  <button [disabled]="projectIsArchivedOrLocked" mat-menu-item *ngIf="canUseItemFlow" (click)="itemActionClicked.emit('attach_to_Report')">
    ATTACH TO REPORT
  </button>

  <!-- bulk actions-->
  <button mat-menu-item [disabled]="projectIsArchivedOrLocked" (click)="itemActionClicked.emit('assign')">
    <span [class.too-many-items-for-bulk-actions]="tooManyItemsForBulkActions">ASSIGN</span>
  </button>

  <button mat-menu-item [disabled]="projectIsArchivedOrLocked" (click)="itemActionClicked.emit('add_tags')">
    <span [class.too-many-items-for-bulk-actions]="tooManyItemsForBulkActions">ADD TAGS</span>
  </button>

  <button mat-menu-item [disabled]="projectIsArchivedOrLocked" (click)="itemActionClicked.emit('accept')">
    <span [class.too-many-items-for-bulk-actions]="tooManyItemsForBulkActions">ACCEPT</span>
  </button>

  <button mat-menu-item [disabled]="projectIsArchivedOrLocked" (click)="itemActionClicked.emit('close')">
    <span [class.too-many-items-for-bulk-actions]="tooManyItemsForBulkActions">CLOSE</span>
  </button>
</mat-menu>
