import { TemplateElement, TemplateElementType } from "./template-element"

export class PagebreakElement extends TemplateElement {
  override name: string = ""
  override icon = "forms:text-field"
  override type: TemplateElementType = TemplateElementType.PAGE_BREAK
  override typeAlias: string = "Page Break"
  override value = ""
  override options: any[] = []
}
