import { CheckdColors } from "@checkd-colors"
import { GeneralReport } from "../general-report"
import { IMenuOption, ReportMenuActions } from "./menu-actions.interface"

/**
 * Connects menu actions, sets up menu button names and colors.
 * @param report
 * @param reportMenuAction
 */
function createReportMenuActionData(report: GeneralReport, reportMenuAction: ReportMenuActions): IMenuOption {
  switch (reportMenuAction) {
    case ReportMenuActions.ADD_TAGS:
      return {
        name: "Add/edit tags",
        color: CheckdColors.CHECKD_DARK,
        action: ReportMenuActions.ADD_TAGS,
      }

    case ReportMenuActions.LOCK_REPORT:
      return {
        name: "Lock report",
        color: CheckdColors.CHECKD_DARK,
        action: ReportMenuActions.LOCK_REPORT,
        confirmationDialog: { title: "Are you sure?", content: `Report ${report.name} will be locked for everyone.` },
      }

    case ReportMenuActions.UNLOCK_REPORT:
      return {
        name: "Unlock report",
        color: CheckdColors.CHECKD_DARK,
        action: ReportMenuActions.UNLOCK_REPORT,
        confirmationDialog: { title: "Are you sure?", content: `Report ${report.name} will be unlocked for everyone.` },
      }

    case ReportMenuActions.REMOVE_REPORT:
      return {
        name: "Remove report",
        color: CheckdColors.CHECKD_RED,
        action: ReportMenuActions.REMOVE_REPORT,
        confirmationDialog: { title: "Are you sure?", content: `Report ${report.name} will be removed for everyone.` },
      }

    case ReportMenuActions.DUPLICATE_REPORT:
      return {
        name: "Duplicate report",
        color: CheckdColors.CHECKD_DARK,
        action: ReportMenuActions.DUPLICATE_REPORT,
      }

    default:
      // @ts-ignore
      return null
  }
}

export { createReportMenuActionData }
