<h2 mat-dialog-title>{{message}}</h2>
<mat-dialog-content>
  <p *ngIf="successfulUploads.length > 0">
    The following files could not be uploaded:
    <mat-list>
      <mat-list-item *ngFor="let failedUpload of failedUploads">{{failedUpload}}</mat-list-item>
    </mat-list>
  </p>
  <p>Please contact support for assistance.</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
