<h1 mat-dialog-title>
  {{ data?.title }}
</h1>

<div mat-dialog-content>

  <p>{{ data?.content }}</p>

  <mat-form-field class="full-width">
    <input (keyup.enter)="ok()" matInput placeholder="{{data?.placeholder}}" [(ngModel)]="data.value">
  </mat-form-field>

</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between begin">

  <checkd-button
    (buttonClick)="cancel()"
    fg="white"
    bg="red">
    {{ data?.cancelValue || 'Cancel' }}
  </checkd-button>

  <checkd-button
    (buttonClick)="ok()"
    [disabled]="!isValidValue"
    fg="white"
    bg="green">
    {{ data?.okValue || 'Ok' }}
  </checkd-button>

</div>
