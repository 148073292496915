<div class="tw-grid tw-align-middle tw-w-full tw-h-auto tw-max-w-md tw-relative tw-bg-white tw-rounded-lg tw-shadow">
  <button
    type="button"
    class="tw-absolute tw-top-3 tw-right-2.5 tw-text-gray-400 tw-bg-transparent hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-rounded-lg tw-text-sm tw-p-1.5 tw-ml-auto tw-inline-flex tw-items-center focus-visible:tw-outline-next-navy-500"
    data-modal-hide="popup-modal"
    (click)="closeOverlay()"
    tabindex="5"
  >
    <svg aria-hidden="true" class="tw-w-5 tw-h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clip-rule="evenodd"
      ></path>
    </svg>
    <span class="tw-sr-only">Close modal</span>
  </button>
  <div class="tw-p-6 tw-grid tw-gap-6">
    <h2 class="tw-px-4 tw-text-3xl tw-font-bold tw-brand-text-500">{{ dialogTitleHeader }}</h2>
    <form [formGroup]="registerChannelForm" (ngSubmit)="onSubmit()">
      <div class="tw-grid tw-gap-4">
        <div class="tw-flex tw-flex-col tw-gap-2 tw-items-start">
          <label for="room_name" class="tw-text-sm tw-font-bold tw-text-gray-900 tw-px-2">Room name (required)</label>
          <input
            type="text"
            id="room_name"
            class="tw-border tw-border-gray-900 tw-text-gray-900 tw-text-sm tw-rounded-lg focus-visible:tw-outline-next-navy-500 tw-block tw-w-full tw-p-2.5"
            placeholder="Introduce a name to your room"
            required
            formControlName="name"
            cdkTrapFocus
            [cdkTrapFocusAutoCapture]="true"
            tabindex="1"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-items-start">
          <label for="room_name" class="tw-text-sm tw-font-bold tw-text-gray-900 tw-px-2">Description</label>
          <textarea
            placeholder="Introduce a description. Max. 150 characters."
            [rows]="5"
            [maxLength]="150"
            pInputTextarea
            [autoResize]="true"
            formControlName="description"
            class="prime-textarea tw-border tw-h-44 tw-border-gray-900 tw-text-gray-900 tw-text-sm tw-rounded-lg focus-visible:tw-outline-next-navy-500 tw-block tw-w-full tw-p-2.5 tw-resize-none"
            tabindex="2"
          ></textarea>
        </div>
        <div class="tw-flex tw-gap-4 tw-items-center tw-justify-end">
          <button
            data-modal-hide="popup-modal"
            type="button"
            class="tw-text-next-orange-500 tw-font-bold tw-py-2 tw-px-4 tw-rounded hover:tw-bg-next-orange-50 tw-transform tw-transition-transform active:tw-scale-90 hover:tw-text-next-orange-500 active:tw-bg-next-orange-500 active:tw-text-next-white-50 focus-visible:tw-bg-next-orange-50 focus-visible:tw-text-next-orange-500 focus-visible:tw-outline-2 focus-visible:tw-outline-next-orange-500 disabled:tw-pointer-events-none disabled:tw-opacity-30"
            (click)="closeOverlay()"
            tabindex="3"
          >
            Cancel
          </button>
          <button
            data-modal-hide="popup-modal"
            type="submit"
            [disabled]="registerChannelForm.invalid || registerChannelForm.pristine"
            class="room-data__save-btn add-members__add-btn tw-bg-next-orange-500 tw-py-2 tw-px-4 tw-text-white tw-cursor-pointer tw-h-11 tw-min-h-full tw-rounded-md tw-flex tw-justify-center tw-items-center tw-gap-2 tw-transform tw-transition-transform active:tw-scale-90 hover:tw-bg-next-orange-700 active:tw-bg-next-orange-300 focus-visible:tw-bg-next-orange-500 focus-visible:tw-text-next-white-50 disabled:tw-pointer-events-none disabled:tw-opacity-30"
            tabindex="4"
          >
            {{ buttonTitle }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
