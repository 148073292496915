import { Injectable } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { LegacyTemplateEditDialogComponent } from "@checkd-form/legacy-template-edit-dialog/legacy-template-edit-dialog.component"
import { ItemMenuActions } from "@models/common"
import { GeneralReport } from "@models/common/general-report"
import { Item } from "@models/common/item"
import { Person } from "@models/common/person"
import { Project } from "@models/common/project"
import { Role } from "@models/common/roles-and-permissions"
import { ReportSharingDialogComponent } from "@reports"
import { take } from "rxjs/operators"
import { DialogService } from "../../dialogs/dialog.service"
import { ItemDialogService } from "../../items/item-dialog.service"
import { ReportPdfLinkDialogComponent } from "@checkd-form/dialogs/report-pdf-link-dialog/report-pdf-link-dialog.component"
import { ShareToNextProjectDialogComponent } from "../../reports/drawing-report/share-to-next-project-dialog/share-to-next-project-dialog.component"
import { ReportEmailDialogComponent } from "../../reports/report-email-dialog/report-email-dialog.component"

@Injectable({
  providedIn: "root",
})
export class CheckdFormDialogService {
  constructor(private dialogRef: MatDialog, private itemDialogService: ItemDialogService, public checkdDialog: DialogService) {}

  shareReport(report: GeneralReport, reportLink: string, project: Project) {
    return this.dialogRef
      .open(ReportSharingDialogComponent, {
        width: "300px",
        data: {
          reportUid: report.uid,
          reportSharingLink: reportLink,
          showAnchor: false,
          closeOnCopyLink: true,
          project,
          report,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .toPromise()
  }

  shareReportWithNextProject(project: Project, report: GeneralReport) {
    return this.dialogRef
      .open(ShareToNextProjectDialogComponent, {
        width: "400px",
        data: {
          report,
          project,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .toPromise()
  }

  editReportTitle(reportName: string): Promise<string> {
    return this.dialogRef
      .open(LegacyTemplateEditDialogComponent, {
        data: { reportName },
      })
      .afterClosed()
      .pipe(take(1))
      .toPromise()
  }

  createItem(creator: Person, project: Project, report: GeneralReport, projectMembers: Person[], prefill?: { [p: string]: any }) {
    return this.itemDialogService.createReportItem(creator, project, report, projectMembers, prefill!)
  }

  openItem(
    item: Item,
    project: Project,
    projectMembers: Person[],
    userProjectRole: Role,
    restrictedItemMenuActions: ItemMenuActions[] = []
  ) {
    return this.itemDialogService.openItem(item, project, projectMembers, userProjectRole, restrictedItemMenuActions)
  }

  showReportPdfLinkDialog(report: GeneralReport, url: string) {
    return this.checkdDialog.openDialog(ReportPdfLinkDialogComponent, {
      data: { report, url },
    })
  }

  showSendEmailDialog() {
    return this.checkdDialog.openDialog(ReportEmailDialogComponent, {
      data: {},
    })
  }
}
