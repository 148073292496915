import {
  StateData,
  WORKFLOW_STATES_MAPPING,
  WORKFLOW_STATES_MAPPING_REVERSE,
  WorkflowData,
  WorkflowStates,
  WorkflowStatusFilter,
} from "./workflow.interface"
import firebase from "firebase/compat/app"
export function convertWorkflowStateToStatus(state: string): string {
  if (Object.keys(WORKFLOW_STATES_MAPPING_REVERSE).includes(state)) {
    // @ts-ignore
    return WORKFLOW_STATES_MAPPING_REVERSE[state]
  }

  // @ts-ignore
  return null
}

export function convertStatusToWorkflowStates(status: string): string[] {
  // @ts-ignore
  return Object.keys(WORKFLOW_STATES_MAPPING).includes(status) ? WORKFLOW_STATES_MAPPING[status] : null
}

export function convertStatusFilterToWorkflowStates(statusFilter: WorkflowStatusFilter): string[] {
  return [].concat(
    // @ts-ignore
    ...Object.keys(statusFilter)
      // @ts-ignore
      .filter((status) => statusFilter[status])
      .map(convertStatusToWorkflowStates)
      .filter((it) => it)
  )
}

export function convertLegacyWorkflowStates(state: string) {
  let conversions = {
    OPEN: WorkflowStates.OPEN,
    CREATED: WorkflowStates.OPEN,
    CLOSED: WorkflowStates.CLOSED,
    DELEGATED: WorkflowStates.DELEGATED,
    ACCEPTED: WorkflowStates.ACCEPTED,
    REJECTED: WorkflowStates.REJECTED,
    FIXED: WorkflowStates.FIXED,
    CANCELLED: WorkflowStates.CANCELLED,
    FIXED_REJECTED: WorkflowStates.FIXED_REJECTED,
    FIXED_ACCEPTED: WorkflowStates.FIXED_ACCEPTED,
    COMPLETED: WorkflowStates.CLOSED,
    INPROGRESS: WorkflowStates.DELEGATED,
  }
  let ucState = state.toUpperCase()
  // @ts-ignore
  return Object.keys(conversions).includes(ucState) ? conversions[ucState] : WorkflowStates.OPEN
}

export class Workflow {
  protected workflow: any
  protected states: { [name: string]: StateData } = {}
  protected _current: string

  constructor(protected data: WorkflowData) {
    data.states.forEach((state) => {
      this.states[state.name] = state
    })
  }

  get start() {
    return this.data.start
  }
  get stop() {
    return this.data.stop
  }
  get current() {
    return this.data.current
  }
  set current(state: string) {
    this.data.current = state
  }

  get nextPossibleStates() {
    return Object.keys(this.states[this.current].transitions)
  }

  filterStates(
    states: string[],
    currentUserId: string,
    assignerId: string,
    assigneeId: string,
    collaboratorRefs: firebase.firestore.DocumentReference[] = []
  ) {
    let userIsAssigner = [
      WorkflowStates.CANCELLED,
      WorkflowStates.CLOSED,
      WorkflowStates.DELEGATED,
      WorkflowStates.FIXED_ACCEPTED,
      WorkflowStates.FIXED_REJECTED,
      WorkflowStates.OPEN,
    ]

    let userIsAssignee = [WorkflowStates.ACCEPTED, WorkflowStates.FIXED, WorkflowStates.REJECTED]

    if (currentUserId == null) return []

    if (currentUserId == assignerId && currentUserId == assigneeId) return states

    if (currentUserId == assignerId) {
      // @ts-ignore
      return states.filter((state) => userIsAssigner.includes(WorkflowStates[state]))
    }

    if (currentUserId == assigneeId || collaboratorRefs.map((ref) => ref.id).includes(currentUserId)) {
      // @ts-ignore
      return states.filter((state) => userIsAssignee.includes(WorkflowStates[state]))
    }

    return []
  }

  reset() {
    this.current = this.data.start
  }

  isAccessibleState(state: string): boolean {
    return this.nextPossibleStates.includes(state)
  }

  isState(state: string) {
    return state != null && Object.keys(this.states).includes(state)
  }

  isAtStart() {
    return this.data.current === this.data.start
  }

  isAtStop() {
    return this.data.current === this.data.stop
  }

  next(name: string) {
    if (!this.isState(name)) throw new Error(`Undefined state: ${name}`)
    if (!this.isAccessibleState(name))
      throw new Error(`Unaccessible state ${name} from current state (current state: ${this.data.current})`)
    this.data.current = name
  }

  setCurrentState(name: string) {
    if (!name) {
      return
    }
    if (!this.isState(name)) {
      throw new Error(`Unknown workflow state: ${name}`)
    }
    this.data.current = name
  }

  public static get(name: string) {
    let testWorkflowData: WorkflowData = {
      name: name,
      start: WorkflowStates.OPEN,
      stop: WorkflowStates.CLOSED,
      current: WorkflowStates.OPEN,
      states: [
        {
          name: WorkflowStates.OPEN,
          transitions: {
            [WorkflowStates.DELEGATED]: {},
          },
        },
        {
          name: WorkflowStates.CANCELLED,
          transitions: {
            [WorkflowStates.DELEGATED]: {},
          },
        },
        {
          name: WorkflowStates.DELEGATED,
          transitions: {
            [WorkflowStates.ACCEPTED]: {},
            [WorkflowStates.REJECTED]: {},
          },
        },
        {
          name: WorkflowStates.ACCEPTED,
          transitions: {
            [WorkflowStates.FIXED]: {},
          },
        },
        {
          name: WorkflowStates.REJECTED,
          transitions: {
            [WorkflowStates.DELEGATED]: {},
          },
        },
        {
          name: WorkflowStates.FIXED,
          transitions: {
            [WorkflowStates.FIXED_REJECTED]: {},
            [WorkflowStates.CLOSED]: {},
          },
        },
        {
          name: WorkflowStates.FIXED_REJECTED,
          transitions: {
            [WorkflowStates.FIXED]: {},
            [WorkflowStates.CANCELLED]: {},
          },
        },
        {
          name: WorkflowStates.FIXED_ACCEPTED,
          transitions: {
            [WorkflowStates.CLOSED]: {},
          },
        },
        {
          name: WorkflowStates.CLOSED,
          transitions: {},
        },
      ],
    }
    return new Workflow(testWorkflowData)
  }
}
