<!-- Header section -->
<div class="field-chat__header tw-bg-next-green-500">
  <div class="header__left-navigation tw-flex tw-items-center tw-gap-4">
    <ng-container *ngIf="false">
      <field-chat-lib-custom-candy-box-icon></field-chat-lib-custom-candy-box-icon>
    </ng-container>
    <div class="header__chat-logo tw-flex tw-items-center tw-gap-2">
      <img src="./assets/chat-images/icons/chat.svg" alt="Chat Logo" class="tw-object-contain" />
    </div>
  </div>
  <div class="header__navigation-icons tw-text-white tw-flex tw-gap-4 tw-items-center">
    <field-chat-lib-circle-question-icon id="intercom-field-chat-help-icon"></field-chat-lib-circle-question-icon>
    <field-chat-lib-chat-info-icon id="intercom-field-chat-info-icon"></field-chat-lib-chat-info-icon>
    <!--    <div class="header__user"><img src="/assets/chat-images/icons/user-logo.svg" alt="User logo" /></div>-->
  </div>
</div>
<!--Main content section-->

<div class="field-chat__content" [ngClass]="!detailsPanel.isOpen ? 'field-chat__content--room-hidden' : ''">
  <!--TOP BUTTON BAR SECTION-->
  <div class="content__btn-bar tw-bg-next-green-50">
    <div class="tw-flex tw-gap-8 tw-text-next-green-500 tw-items-end">
      <button
        class="btn-bar__btn tw-text-next-green-500 tw-text-lg tw-flex tw-justify-center tw-items-center tw-gap-1 before:tw-bg-next-green-500 active:before:tw-bg-next-green-500 tw-relative"
        (click)="changeChannelType('company')"
        [ngClass]="{ active: (fieldChatViewService.channelTypeVisible$ | async) == 'company' }"
      >
        <ng-container *ngIf="(fieldChatViewService.channelTypeVisible$ | async) == 'company'; else buildingEmptyIcon">
          <field-chat-lib-building-solid-icon></field-chat-lib-building-solid-icon>
        </ng-container>
        <span class="tw-p-1">Company</span>
        <span class="tw-absolute tw-left-5 tw-top-1 tw-w-3 tw-h-3 tw-bg-red-500 tw-rounded-full" *ngIf="false"></span>
      </button>
      <button
        class="btn-bar__btn tw-text-next-green-500 tw-text-lg tw-flex tw-justify-center tw-items-center tw-gap-1 before:tw-bg-next-green-500 active:before:tw-bg-next-green-500 tw-relative"
        (click)="changeChannelType('project')"
        [ngClass]="{ active: (fieldChatViewService.channelTypeVisible$ | async) == 'project' }"
      >
        <ng-container *ngIf="(fieldChatViewService.channelTypeVisible$ | async) == 'project'; else briefcaseEmptyIcon">
          <field-chat-lib-briefcase-project-solid-icon></field-chat-lib-briefcase-project-solid-icon>
        </ng-container>
        <span class="tw-p-1">Projects</span>
        <span class="tw-absolute tw-left-5 tw-top-1 tw-w-3 tw-h-3 tw-bg-red-500 tw-rounded-full" *ngIf="false"></span>
      </button>
    </div>
  </div>
  <!-- CHANNEL LIST SECTION -->
  <div class="content__channel-list tw-overflow-hidden">
    <div class="tw-flex tw-flex-col">
      <!-- Company name btn active -->

      <ng-container *ngIf="(fieldChatViewService.channelTypeVisible$ | async) == 'company'">
        <div class="tw-flex tw-items-center tw-gap-2 tw-px-4 tw-min-h-[48px]">
          <field-chat-lib-company-icon [size]="'sm'"></field-chat-lib-company-icon>
          <h2 class="tw-text-next-navy-500 tw-font-bold tw-text-sm">{{ userCompanyName$ | async }}</h2>
        </div>
      </ng-container>

      <!-- Project name btn active -->
      <ng-container *ngIf="(fieldChatViewService.channelTypeVisible$ | async) == 'project'">
        <div class="tw-min-h-[48px] tw-flex tw-flex-col tw- tw-justify-center tw-px-4">
          <div
            class="channel-list__project-drop-btn tw-min-w-0 tw-flex tw-items-center tw-justify-between tw-gap-2 tw-p-2 tw-cursor-pointer tw-border tw-text-next-navy-500 tw-border-next-navy-500 tw-rounded-md tw-bg-next-white-50 hover:tw-border-next-orange-500 tw-z-50"
            (click)="toggleShowProjects()"
          >
            <div class="tw-flex tw-items-center tw-gap-2 tw-min-w-0">
              <field-chat-lib-briefcase-project-icon [size]="'sm'"></field-chat-lib-briefcase-project-icon>
              <h2 class="tw-truncate">
                <ng-container *ngIf="fieldChatViewService.projectSelected$ | async as projectSelected; else noSelectedProject">
                  {{ projectSelected.name }}
                </ng-container>
                <ng-template #noSelectedProject> Select a project </ng-template>
              </h2>
            </div>
            <div [ngClass]="fieldChatViewService.showProjects ? 'channel-list__arrow-up' : 'channel-list__arrow-down'">
              <field-chat-lib-chevron-down-icon [size]="'sm'"></field-chat-lib-chevron-down-icon>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="tw-p-4">
        <button
          (click)="onCreateRoomClicked()"
          class="channel-list__create-btn tw-bg-white tw-p-2 tw-text-next-orange-500 tw-border-2 tw-border-next-orange-500 tw-w-full tw-cursor-pointer tw-h-11 tw-min-h-full tw-rounded-md tw-flex tw-justify-center tw-items-center tw-gap-2 tw-transform tw-transition-transform active:tw-scale-90 hover:tw-bg-next-orange-700 hover:tw-text-white active:tw-bg-next-orange-300 active:tw-border-none focus-visible:tw-bg-next-orange-500 focus-visible:tw-text-next-white-50 disabled:tw-pointer-events-none disabled:tw-opacity-30"
        >
          <field-chat-lib-plus-icon></field-chat-lib-plus-icon>
          <span> Create room </span>
        </button>
      </div>
      <div class="channel-list__channels-container tw-relative tw-flex tw-flex-col tw-gap-4 tw-h-full tw-overflow-auto">
        <div
          class="channel-list__dropdown-container tw-bg-white tw-h-full tw-w-48 tw-absolute tw-top-0 tw-left-0 tw-transition-all tw-ease-in-out tw-duration-300 tw-z-30"
          [ngClass]="
            fieldChatViewService.showProjects
              ? 'tw-transition-transform tw-translate-x-0 tw-opacity-100'
              : '-tw-translate-x-full tw-opacity-0 '
          "
        >
          <field-chat-projects-list (onProjectSelected)="onProjectSelected($event)"></field-chat-projects-list>
        </div>
        <ng-container *ngIf="channelService.activeChannel$ | async; else emptyChannelList">
          <div
            class="tw-flex tw-flex-col tw-h-full tw-bg-white"
            [ngClass]="fieldChatViewService.showProjects ? 'tw-invisible tw-transition-transform' : 'tw-visible  tw-transition-transform'"
          >
            <stream-channel-list></stream-channel-list>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- MAIN CHAT SECTION -->
  <div class="content__main-chat">
    <div class="main-chat__path tw-px-8 tw-flex tw-justify-between tw-items-center">
      <div class="tw-flex tw-gap-2 tw-items-center">
        <span>{{ userCompanyName$ | async }}</span>
        <field-chat-lib-chevron-right-icon [size]="'sm'"></field-chat-lib-chevron-right-icon>
        <span class="tw-font-bold">{{ fieldChatService.channelName$ | async }}</span>
      </div>
      <div class="tw-flex tw-items-center tw-gap-1">
        <span> {{ membersCount$ | async }} participants, </span>
        <span> {{ membersOnline$ | async }} online </span>
        <button
          class="main-chat__info-icon tw-cursor-pointer hover:tw-scale-105 hover:tw-transition-all"
          (click)="detailsPanel.toggleIsOpen()"
        >
          <field-chat-lib-chat-info-icon></field-chat-lib-chat-info-icon>
        </button>
      </div>
    </div>
    <div class="main-chat__channel-container">
      <ng-container
        *ngIf="
          (isError$ | async) === false && (isInitializing$ | async) === false && (isActiveChannel$ | async) === true;
          else emptyChannelMessage
        "
      >
        <stream-channel>
          <stream-message-list></stream-message-list>
          <stream-notification-list></stream-notification-list>
          <stream-message-input></stream-message-input>
          <stream-thread name="thread">
            <stream-message-list mode="thread"></stream-message-list>
            <stream-message-input mode="thread"></stream-message-input>
          </stream-thread>
        </stream-channel>
      </ng-container>
    </div>
  </div>

  <!--ROOM DETAILS SECTION -->
  <div class="content__room-details tw-relative tw-overflow-hidden" [ngClass]="!detailsPanel.isOpen ? 'tw-hidden' : ''">
    <field-chat-room-details-panel
      #detailsPanel
      [(isOpen)]="fieldChatViewService.isDetailsPanelVisible"
      (onEditRoomClicked)="openEditChannelDialog()"
      class="room-details"
    ></field-chat-room-details-panel>
  </div>
</div>
<!-- CHANNEL PREVIEW COMPONENT -->
<!-- <ng-template #channelPreview let-channel="channel">
  <div class="tw-h-20 tw-py-1 tw-px-2 tw-flex tw-flex-col tw-justify-around tw-relative">
    <div class="">
      <h2>{{ channel.data?.name }}</h2>
      <div
        class="tw-grid tw-place-items-center tw-text-xs tw-text-white tw-bg-red-500 tw-border-white tw-absolute tw-rounded-full tw-top-2 tw-right-2 dark:tw-border-gray-900"
        [ngClass]="channel?.state?.unreadCount > 0 ? 'tw-px-3 tw-py-1' : ' tw-p-2'"
        *ngIf="channel?.state?.unreadCount"
      >
        <span *ngIf="channel?.state?.unreadCount > 0">{{ channel?.state?.unreadCount }}</span>
      </div>
    </div>
    <div class="tw-flex tw-justify-between tw-gap-1 tw-flex-wrap">
      <p class="tw-max-w-[190px] tw-w-full tw-overflow-ellipsis tw-whitespace-nowrap tw-overflow-hidden">
        {{ channel?.state?.messages?.at(-1)?.text }}
      </p>
      <div>{{ channel?.state?.last_message_at | fieldChatDate }}</div>
    </div>
  </div>
</ng-template> -->
<ng-template #emptyTemplate></ng-template>
<ng-template #mentionTemplate let-user="user">
  <div>
    <b>@{{ user.name || user.id }}</b>
  </div>
</ng-template>
<ng-template #emptyChannelList>
  <ng-container *ngIf="(isInitializing$ | async) === false && ((isError$ | async) === true || (isActiveChannel$ | async) === false)">
    <field-chat-lib-empty-channel-list></field-chat-lib-empty-channel-list>
  </ng-container>
</ng-template>
<ng-template #emptyChannelMessage>
  <ng-container *ngIf="(isInitializing$ | async) === false && ((isError$ | async) === true || (isActiveChannel$ | async) === false)">
    <field-chat-lib-empty-channel-main-chat (onCreateChannelClicked)="onCreateRoomClicked()"></field-chat-lib-empty-channel-main-chat>
  </ng-container>
  <ng-container *ngIf="(isInitializing$ | async) === true && ((isError$ | async) === false || (isActiveChannel$ | async) === true)">
    <field-chat-lib-skeletton-loader-main-chat></field-chat-lib-skeletton-loader-main-chat>
  </ng-container>
</ng-template>

<ng-template #buildingEmptyIcon>
  <field-chat-lib-company-icon></field-chat-lib-company-icon>
</ng-template>
<ng-template #briefcaseEmptyIcon>
  <field-chat-lib-briefcase-project-icon></field-chat-lib-briefcase-project-icon>
</ng-template>
