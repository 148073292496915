import { Injectable, OnDestroy } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { COLLECTIONS, LegacyTemplate, LegacyTemplateData } from "@models/common"
import { filter, Observable, pipe, Subject, takeUntil, zip } from "rxjs"
import { map, shareReplay, switchMap } from "rxjs/operators"

interface IFormsLibraryConfig {
  tags: string[]
  promotedTemplates: {
    featured: string[]
    mostUsed: string[]
    newAndUpdated: string[]
  }
}

@Injectable({
  providedIn: "root",
})
export class FormsLibraryConfigService implements OnDestroy {
  private readonly destroy$ = new Subject<void>()

  constructor(private db: AngularFirestore) {}

  readonly currentConfig$: Observable<IFormsLibraryConfig> = this.db
    .collection(COLLECTIONS.CHECKD_CONFIG)
    .doc<IFormsLibraryConfig>(COLLECTIONS.FORMS_LIBRARY_CONFIG)
    .valueChanges()
    .pipe(filter(Boolean), takeUntil(this.destroy$), shareReplay({ bufferSize: 1, refCount: true }))

  readonly tags$ = this.currentConfig$.pipe(
    map((data) => data.tags),
    map(this.cleanTags),
    takeUntil(this.destroy$)
  )

  private cleanTags(tags: string[]): string[] {
    return (tags ?? []).map((tag) => tag.trim().toUpperCase()).sort()
  }

  private readonly templateUidsToLegacyTemplates = () =>
    pipe(
      switchMap((templateUids: string[]) =>
        zip(templateUids.map((uid) => this.db.collection(COLLECTIONS.LEGACY_TEMPLATES).doc<LegacyTemplateData>(uid).snapshotChanges()))
      ),
      map((templateSnaps) => templateSnaps.filter((snap) => snap.payload.exists)),
      map((templateSnaps) => templateSnaps.map((snap) => new LegacyTemplate(snap.payload.data()!, snap.payload.id, snap.payload.ref)))
    )

  public readonly featuredTemplates$ = this.currentConfig$.pipe(
    map((config) => config.promotedTemplates.featured),
    this.templateUidsToLegacyTemplates()
  )

  public readonly mostUsedTemplates$ = this.currentConfig$.pipe(
    map((config) => config.promotedTemplates.mostUsed),
    this.templateUidsToLegacyTemplates()
  )

  public readonly newAndUpdatedTemplates$ = this.currentConfig$.pipe(
    map((config) => config.promotedTemplates.newAndUpdated),
    this.templateUidsToLegacyTemplates()
  )

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
