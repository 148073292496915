import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from "@angular/core"
import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { MatDialog } from "@angular/material/dialog"
import { Router } from "@angular/router"
import { OneLoginService } from "@services/one-login.service"
import { combineLatest, firstValueFrom, type Observable, of as observableOf, ReplaySubject, tap } from "rxjs"
import { combineLatestWith, map, shareReplay, switchMap, take } from "rxjs/operators"

import { ConfirmDialogComponent } from "../../../dialogs"
import { type Company, type Invitation, InvitationType, type Person, Project } from "../../../models/common"
import { InvitationService, SnackbarService, UserService } from "../../../services"

interface ICombinedInvitationData {
  invitation: Invitation
  sourceUserName: string
  companyName: string
  invitationCompany: Company
  isTargetMatch: boolean
  invitationProjects: Project[]
}

@Component({
  selector: "checkd-accept-invitation-uid",
  templateUrl: "./accept-invitation-uid.component.html",
  styleUrls: ["./accept-invitation-uid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptInvitationUidComponent implements OnInit, OnDestroy {
  private readonly router = inject(Router)
  private readonly invitationService = inject(InvitationService)
  private readonly userService = inject(UserService)
  private readonly afAuth = inject(AngularFireAuth)
  private readonly snackbarService = inject(SnackbarService)
  private readonly dialogRef = inject(MatDialog)

  acceptingInProgress = false

  readonly invitationUid$ = new ReplaySubject<string>(1)
  readonly invitation$: Observable<Invitation> = this.invitationUid$.pipe(
    takeUntilDestroyed(),
    switchMap((invitationUid) => this.invitationService.listenToUid(invitationUid)),
    combineLatestWith(this.afAuth.user),
    tap(async ([invitation, authUser]) => {
      // The user should be logged in when accepting the invitation in this component. If this ends up not being the case, we need to
      // handle that properly.
      if (!authUser) {
        await this.router.navigate(["/login"])

        return
      }
      // modelService.listenTo doesn't take into account whether the document it listens to actually exists,
      // so we add a check like this here
      if (!invitation.data) {
        console.error(`invitation with ID: ${invitation.uid} was not found in the database`)
        await this.router.navigate(["/invitations/missing-invitation"])
      }

      if (invitation.isExpired) {
        await this.router.navigate(["invitations/expired"])
      }

      if (invitation.invitationType === InvitationType.FORMS_LIBRARY_MEMBER_COMPANY_INVITATION_EMAIL) {
        await this.router.navigate([`/forms/invitations/${invitation.uid}`])
      }
    }),
    map(([invitation, authUser]) => invitation),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  invitationCompany$: Observable<Company> = this.invitation$.pipe(
    switchMap((invitation) => this.invitationService.listenToCompany(invitation))
  )

  //////////////////////////////////////////////////////////////////////////////////////
  // NB: sourceUser$ and sourceCompany$ can probably be removed once we've made sure that
  //     all invitations are created with the required aggregate data. This way we don't
  //     need to provide read access to much else than the actual invitation document.
  sourceUser$: Observable<Person> = this.invitation$.pipe(
    switchMap((invitation) => this.userService.listenToUid(invitation.sourceUid)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  sourceCompany$: Observable<Company> = this.sourceUser$.pipe(switchMap((person) => this.userService.listenToMainCompany(person)))
  //////////////////////////////////////////////////////////////////////////////////////

  sourceUserEmail$: Observable<string> = this.invitation$.pipe(
    switchMap((invitation) =>
      invitation.aggregateData && invitation.aggregateData["sourceEmail"]
        ? observableOf(invitation.aggregateData["sourceEmail"])
        : // TODO remove once we are sure we create the required aggregate data
          this.sourceUser$.pipe(map((user) => user.email))
    )
  )

  sourceUserName$: Observable<string> = this.invitation$.pipe(
    switchMap((invitation) =>
      invitation.aggregateData && invitation.aggregateData["sourceName"]
        ? observableOf(invitation.aggregateData["sourceName"])
        : this.sourceUser$.pipe(map((user) => user.name))
    )
  )

  sourceCompanyName$: Observable<string> = this.invitation$.pipe(
    switchMap((invitation) =>
      invitation.aggregateData && invitation.aggregateData["sourceCompanyName"]
        ? observableOf(invitation.aggregateData["sourceCompanyName"])
        : // TODO remove once we are sure we create the required aggregate data
          this.sourceCompany$.pipe(map((company) => company.name))
    )
  )

  readonly invitationIsForTheCurrentUser$: Observable<boolean> = combineLatest([this.afAuth.user, this.invitation$]).pipe(
    map(([user, invitation]) => {
      // The user should be logged in when this component is activated, but we check just in case
      if (!user) {
        this.router.navigate(["/login"])

        return false
      }

      // If one-login supports phone numbers, we should add a proper check here
      if (invitation.deliveryMethod === "sms") {
        return true
      }

      const targetEmail = (invitation.targetEmail ?? "").trim().toLowerCase()

      return !!(targetEmail && user.email && targetEmail === user.email)
    })
  )

  invitationProjects$: Observable<Project[]> = this.invitation$.pipe(
    switchMap((invitation) => this.invitationService.listenToProjects(invitation))
  )

  readonly targetString$ = this.invitation$.pipe(
    map((invitation) => {
      if (invitation.deliveryMethod === "sms") {
        return invitation.targetPhoneNumber ?? "You"
      }

      return invitation.targetEmail ?? "You"
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  readonly combinedInvitationData$: Observable<ICombinedInvitationData> = combineLatest([
    this.invitation$,
    this.sourceUserName$,
    this.sourceCompanyName$,
    this.invitationCompany$,
    this.invitationIsForTheCurrentUser$,
    this.invitationProjects$,
  ]).pipe(
    map(([invitation, sourceUserName, companyName, invitationCompany, isTargetMatch, invitationProjects]) => {
      return { invitation, sourceUserName, companyName, invitationCompany, isTargetMatch, invitationProjects }
    })
  )

  constructor() {
    const invitationUid = inject(OneLoginService).getInvitationUid()
    if (!invitationUid) {
      this.router.navigate(["/invitations/missing-invitation"])

      return
    }

    this.invitationUid$.next(invitationUid)
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.invitationUid$.complete()
  }

  async acceptInvitation(invitation: Invitation) {
    this.acceptingInProgress = true
    if (invitation.invitationType === InvitationType.COMPANY) {
      const dialog = this.dialogRef.open(ConfirmDialogComponent, {
        width: "300px",
        data: {
          content:
            'If you are part of another company in the system, you will be removed from that company and moved to this one when you accept the invite. Press "Yes" to confirm.',
        },
      })

      const userConfirmed = await firstValueFrom(dialog.afterClosed().pipe(take(1)))
      if (!userConfirmed) {
        this.acceptingInProgress = false

        return
      }
    }
    const userRecord = await firstValueFrom(this.afAuth.user.pipe(take(1)))
    if (!userRecord) {
      this.acceptingInProgress = false

      return this.snackbarService.showMessage("Could not fetch user record")
    }

    try {
      await this.invitationService.acceptInvitation(invitation, userRecord.uid)

      return this.doneAcceptingInvitation()
    } catch (err) {
      this.snackbarService.showMessage(err.message)
    }
  }

  async doneAcceptingInvitation() {
    this.snackbarService.showMessage("You have accepted the invitation!")
    await this.router.navigateByUrl("/projects")
  }
}
