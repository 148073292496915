<div *ngIf="itemLength > 0">
  <p>({{ itemLength }} Items)</p>
  <ng-container *ngIf="itemListFormElement != null">
    <ul *ngIf="itemListFormElement.value != null && itemListFormElement.value.length > 0">
      <ng-container *ngFor="let itemUid of items">
        <li *ngIf="reportItemsMap[itemUid]" (click)="openItem(reportItemsMap[itemUid])" style="cursor: pointer">
          <span> ({{ reportItemsMap[itemUid].number }}) {{ reportItemsMap[itemUid].name }} - {{ reportItemsMap[itemUid].status }} </span>
        </li></ng-container
      >
    </ul>

    <!-- FIXME This only updates upon the first time a report is opened. Figure out why.
    <app-form-element
      *ngIf="itemListFormElement.value != null && itemListFormElement.value.length > 0"
      [element]="itemListFormElement"
      [readOnly]="readOnly">
    </app-form-element>
    -->
  </ng-container>
</div>

<button primary-button [type]="'text'" (click)="createItem()" [disabled]="readOnly">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2511_16756)">
      <path
        d="M24 11.9994C24 12.7667 23.3827 13.384 22.6154 13.384H13.3846V22.6148C13.3846 23.3792 12.7644 24 12 24C11.2356 24 10.6154 23.3821 10.6154 22.6148V13.384H1.38462C0.620192 13.384 0 12.7644 0 12C0 11.2379 0.620192 10.6148 1.38462 10.6148H10.6154V1.38404C10.6154 0.619615 11.2356 0 12 0C12.7644 0 13.3846 0.619615 13.3846 1.38404V10.6148H22.6154C23.3827 10.6148 24 11.2379 24 11.9994Z"
        fill="currentcolor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2511_16756">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
  <span>
    {{ title }}
  </span>
</button>
