<div class="report-list-container" ngClass.lt-sm="full-width-container">
  <table mat-table matSort [dataSource]="dataSource" class="report-list-container__table" *ngIf="isReportListReady; else reportIsNotReady">
    <!-- Items count -->
    <ng-container matColumnDef="itemsCount">
      <th class="report-itemcount-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header>
        <mat-icon svgIcon="marker_status_open"></mat-icon>
      </th>

      <td class="report-itemcount-cell" mat-cell *matCellDef="let element">
        <ng-container *ngIf="element?.report?.aggregateData?.itemsCount as itemsCount">
          <a
            *ngIf="itemsCount > 0"
            matTooltip="There are {{ itemsCount }} item{{ itemsCount == 1 ? '' : 's' }} attached to this report"
            [href]="element?.report?.urlPath"
            target="_blank"
          >
            {{ itemsCount }}
          </a>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="itemsCountSearch">
      <th class="report-itemcount-cell" mat-header-cell *matHeaderCellDef></th>
    </ng-container>

    <!-- Type Icon -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef><span class="header-text">Type</span></th>
      <td class="report-type-cell" mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.type == 'ITEM_REPORT'" svgIcon="items-report"></mat-icon>
        <mat-icon *ngIf="element.type == 'FORM_REPORT'" svgIcon="forms-report"></mat-icon>
        <mat-icon *ngIf="element.type == 'TEMPLATE'" svgIcon="forms-report"></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="typeSearch">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>

    <!-- Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="header-text">Name</span></th>
      <td class="report-name-cell" mat-cell *matCellDef="let element">
        <span *ngIf="element?.type === 'TEMPLATE'">{{ element.name }}</span>

        <a *ngIf="element?.type !== 'TEMPLATE'" [href]="element?.report?.urlPath" target="_blank">
          {{ element.name }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="nameSearch">
      <th mat-header-cell *matHeaderCellDef>
        <checkd-ac-search #nameFilter (onSelection)="onAcFilterChange('title', $event.searchValue)" placeholder="Search"></checkd-ac-search>
      </th>
    </ng-container>

    <!-- Template -->
    <ng-container matColumnDef="template">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="header-text">Template</span></th>
      <td class="report-name-cell" mat-cell *matCellDef="let element">
        <span>{{ element?.templateName }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="templateSearch">
      <th mat-header-cell *matHeaderCellDef>
        <checkd-ac-search
          #templateFilter
          (onSelection)="onAcFilterChange('templateName', $event.searchValue)"
          placeholder="Search"
        ></checkd-ac-search>
      </th>
    </ng-container>

    <!-- Tags -->
    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span class="header-text">Tags</span>
      </th>
      <td class="item-tags-cell" mat-cell *matCellDef="let element" (click)="reportClicked(element?.report)">
        <checkd-tags [tags]="element?.report?.tags"></checkd-tags>
      </td>
    </ng-container>

    <ng-container matColumnDef="tagsSearch">
      <th mat-header-cell *matHeaderCellDef>
        <checkd-multiselect
          #tagsFilter
          (onSelection)="onAcFilterChange('tags', $event)"
          [values]="reportTags"
          [triCheck]="true"
        ></checkd-multiselect>
      </th>
    </ng-container>

    <!-- Creator Name -->
    <ng-container matColumnDef="reportCreatorName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="header-text">Creator</span></th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element?.type === 'TEMPLATE'">{{ element.reportCreatorName }}</span>

        <a *ngIf="element?.type !== 'TEMPLATE'" [href]="element?.report?.urlPath" target="_blank">
          {{ element.reportCreatorName }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="reportCreatorNameSearch">
      <th mat-header-cell *matHeaderCellDef>
        <checkd-multiselect
          #reportCreatorNameFilter
          (onSelection)="onAcFilterChange('reportCreatorNames', $event)"
          [values]="reportCreatorNames"
          [triCheck]="true"
        ></checkd-multiselect>
      </th>
    </ng-container>

    <!-- Creator Company -->
    <ng-container matColumnDef="reportCreatorCompanyName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="header-text">Company</span></th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element?.type === 'TEMPLATE'">{{ element.reportCreatorCompanyName }}</span>

        <a *ngIf="element?.type !== 'TEMPLATE'" [href]="element?.report?.urlPath" target="_blank">
          {{ element.reportCreatorCompanyName }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="reportCreatorCompanyNameSearch">
      <th mat-header-cell *matHeaderCellDef>
        <checkd-multiselect
          #reportCreatorCompanyNameFilter
          (onSelection)="onAcFilterChange('reportCreatorCompanyNames', $event)"
          [values]="reportCreatorCompanyNames"
          [triCheck]="true"
        ></checkd-multiselect>
      </th>
    </ng-container>

    <!-- Created At -->
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="header-text">Created at</span></th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element?.type === 'TEMPLATE'">{{ element.createdAt | amFromUnix | amDateFormat: "DD-MM-YYYY HH:mm" }}</span>

        <a *ngIf="element?.type !== 'TEMPLATE'" [href]="element?.report?.urlPath" target="_blank">
          {{ element.createdAt | amFromUnix | amDateFormat: "DD-MM-YYYY HH:mm" }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAtSearch">
      <th mat-header-cell *matHeaderCellDef>
        <checkd-daterange #createdAtFilter (onSelection)="onDateRangeSelection('createdAt', $event)"></checkd-daterange>
      </th>
    </ng-container>

    <!-- Updated At-->
    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="header-text">Updated at</span></th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element?.type === 'TEMPLATE'">{{ element.updatedAt | amFromUnix | amDateFormat: "DD-MM-YYYY HH:mm" }}</span>

        <a *ngIf="element?.type !== 'TEMPLATE'" [href]="element?.report?.urlPath" target="_blank">
          {{ element.updatedAt | amFromUnix | amDateFormat: "DD-MM-YYYY HH:mm" }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="updatedAtSearch">
      <th mat-header-cell *matHeaderCellDef>
        <checkd-daterange #updatedAtFilter (onSelection)="onDateRangeSelection('updatedAt', $event)"></checkd-daterange>
      </th>
    </ng-container>

    <!-- Menu -->
    <ng-container matColumnDef="menu" stickyEnd>
      <th class="report-menu-cell" mat-header-cell *matHeaderCellDef></th>
      <td class="report-menu-cell" mat-cell *matCellDef="let reportRow">
        <checkd-report-contextmenu
          [report]="reportRow.report"
          [userProjectRole]="userProjectRole"
          [currentUser]="currentUser"
          (menuOptionSelected)="onMenuOptionSelected($event)"
        >
        </checkd-report-contextmenu>
      </td>
    </ng-container>

    <ng-container matColumnDef="menuSearch" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>

    <tr class="sort-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="filter-header" mat-header-row *matHeaderRowDef="displayedSearchColumns; sticky: true"></tr>

    <tr
      [class.item-report]="row.type == 'ITEM_REPORT'"
      [class.form-report]="row.type == 'FORM_REPORT'"
      class="report-row"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="reportClicked(row)"
    ></tr>
  </table>
  <ng-template #reportIsNotReady>
    <div class="skeleton">
      <ngx-skeleton-loader
        count="1"
        animation="progress"
        [theme]="{
          'border-radius': '5px',
          height: '50px',
          'background-color': '#e9ecef',
          border: '1px solid white'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="10"
        animation="progress"
        [theme]="{
          'border-radius': '5px',
          height: '40px',
          'background-color': '#e9ecef',
          border: '1px solid white'
        }"
      ></ngx-skeleton-loader>
    </div>
  </ng-template>
</div>

<div class="table-footer" fxLayout="row" fxLayoutAlign="end">
  <mat-paginator #paginator [pageSizeOptions]="pageSizes" class="mat-paginator-header" showFirstLastButtons></mat-paginator>
</div>
