import { Component, Inject, OnInit } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"

@Component({
  selector: "checkd-project-duplication-dialog",
  templateUrl: "./project-duplication-dialog.component.html",
  styleUrls: ["./project-duplication-dialog.component.scss"],
})
export class ProjectDuplicationDialogComponent implements OnInit {
  duplicationForm: UntypedFormGroup

  constructor(
    public dialogRef: MatDialogRef<ProjectDuplicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { currentName: string; currentDescription: String },
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.duplicationForm = this.fb.group({
      newProjectName: [
        `${this.data.currentName} (copy)`,
        [
          Validators.required, // At least one character required
          Validators.pattern("^(?=\\s*\\S).*$"), // at least one non-whitespace character required
        ],
      ],
      newProjectDescription: this.data.currentDescription,
      includeDrawings: false,
      includePeople: false,
      includeTemplates: false,
    })
  }
}
