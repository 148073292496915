import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { Company } from "@models/common"
import { IModelQuery, ModelService, UserService } from "@services"
import { Observable } from "rxjs"
import { switchMap } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class PublisherService {
  constructor(private db: AngularFirestore, private userService: UserService, private modelService: ModelService) {}

  public listenToPublishers(): Observable<Company[]> {
    const params: IModelQuery = {
      collection: Company.COLLECTION,
      modelData: { isTemplatePublisher: true },
    }

    return this.modelService.queryAndListen(params)
  }
}
