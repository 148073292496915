<div class="filter-component">
  <div class="filter-component__view">
    <h2 class="view__title">View:</h2>
    <div class="view__checkbox">
      <p-checkbox
        styleClass="filter-checkbox"
        name="view"
        value="1 checkbox"
        [(ngModel)]="viewCompanyTemplatesChecked"
        inputId="binary"
      ></p-checkbox>
      <label for="binary">Company templates </label>
    </div>
  </div>
  <div class="filter-component__filter-by" styleClass="search-btn">
    <h2 class="filter-by__title">Filter by:</h2>
    <p-accordion [multiple]="true" styleClass="accordion-menu" expandIcon="pi pi-fw pi-chevron-up">
      <p-accordionTab [selected]="true" header="Publisher" styleClass="accordion-tab">
        <div class="filter-by__checkboxes-container">
          <div class="filter-by__checkbox" *ngFor="let company of formsLibViewService.publisherCompanies$ | async">
            <p-checkbox
              styleClass="filter-checkbox"
              name="publisher"
              [value]="company.uid"
              [(ngModel)]="selectedPublisherCompanyUids"
              [inputId]="company.uid"
            ></p-checkbox>
            <label for="{{ company.uid }}" class="filter-by__label-text">{{ company.name }}</label>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab [selected]="true" header="Predefined tags">
        <div class="filter-by__checkboxes-container">
          <ng-container *ngFor="let tag of formsLibraryConfigService.tags$ | async">
            <div class="filter-by__checkbox">
              <p-checkbox
                styleClass="filter-checkbox"
                name="pred-tags"
                [value]="tag"
                [(ngModel)]="selectedPredefinedTags"
                [inputId]="tag"
              ></p-checkbox>
              <label [for]="tag" class="filter-by__label-text">{{ tag }}</label>
            </div>
          </ng-container>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
  <p-button (click)="onFilterTemplatesClicked()" label="Filter" class="filter-component__search-button" styleClass="filter-btn"></p-button>
</div>
