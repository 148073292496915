<div
  class="leaflet-container"
  leaflet
  leafletCheckdDrawing
  [initializeMarkerInMiddle]="initializeMarkerInMiddle"
  [enableGestureToAddItemMarker]="enableGestureToAddItemMarker"
  [maxAddableItemMarkersWithGesture]="maxAddableItemMarkersWithGesture"
  [drawingBounds]="drawingLatLngBounds"
  [leafletOptions]="drawingMapOptions"
  (leafletMapReady)="onMapReady($event)"
  (addItemGesture)="addItemGesture($event)"
  (markerMoved)="markerMoved($event)"
>
  <div
    *ngFor="let m of itemLayers"
    leafletCheckdItem
    [leafletLayer]="m"
    [drawingBounds]="drawingLatLngBounds"
    (itemClicked)="itemClicked($event, $event.target.options.item)"
    (itemMoved)="itemMoved($event, $event.layer.options.item)"
    (onItemContextMenu)="onItemContextMenuTriggered($event, $event.target.options.item)"
  ></div>
</div>
