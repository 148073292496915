<ng-template #templateContent>
  <form [ngSwitch]="element.type" (keyup)="onElementChange($event)">
    <!-- TITLE AND INFO -->
    <ng-container *ngIf="!isElementWithoutTitleAndInfoInputs">
      <mat-form-field class="full-width" (mousedown)="$event.stopPropagation()">
        <mat-label>Title</mat-label>
        <input *ngIf="element.type != templateElementType.TEXT" [(ngModel)]="element.name" matInput value="" name="elementTitle" />
        <textarea
          *ngIf="element.type == templateElementType.TEXT"
          [(ngModel)]="element.name"
          matInput
          placeholder="Title"
          name="elementTitle"
        ></textarea>
      </mat-form-field>
      <mat-form-field class="full-width" (mousedown)="$event.stopPropagation()">
        <mat-label>Info</mat-label>
        <textarea [(ngModel)]="element.info" matInput placeholder="Put in info text here" name="elementInfo"></textarea>
      </mat-form-field>
    </ng-container>

    <!-- OPTIONS -->
    <div class="options-container flex flex-wrap align-items-center gap-3">
      <ng-container *ngFor="let option of element.options; let i = index">
        <ng-container [ngSwitch]="true">
          <mat-checkbox
            *ngSwitchCase="isCheckboxOption(option)"
            (change)="onElementChange($event)"
            [(ngModel)]="option.value"
            [name]="'option' + i"
          >
            {{ (option.name || "").toLowerCase() == "quater" ? "Quarter" : option.name }}
          </mat-checkbox>

          <p-inputNumber
            *ngSwitchCase="option.code == ElementOptionCode.TEXT_FIELD_MAX_LENGTH"
            [(ngModel)]="option.value"
            [name]="'option' + i"
            [showButtons]="true"
            suffix=" max text length"
            min="5"
            [step]="5"
            [size]="14"
            (onInput)="onElementChange($event)"
          >
          </p-inputNumber>

          <p-inputNumber
            *ngSwitchCase="option.code == ElementOptionCode.TEXT_FIELD_HEIGHT"
            [(ngModel)]="option.value"
            [name]="'option' + i"
            [showButtons]="true"
            suffix=" text field height"
            min="5"
            [step]="5"
            [size]="14"
            (onInput)="onElementChange($event)"
          >
          </p-inputNumber>

          <div *ngSwitchCase="isColorInputOption(option.code)" fxLayout fxLayoutAlign="start center" fxLayoutGap="8px" fxFlex="0 0 auto">
            <div fxLayout fxLayoutAlign="start stretch">
              <div class="color-input-wrapper">
                <input
                  matInput
                  type="color"
                  *ngIf="option.value"
                  [(ngModel)]="option.value"
                  [name]="'option' + i"
                  (change)="onElementChange($event)"
                />
              </div>
            </div>
            <div fxFlex="0 0 auto" class="color-input-text">{{ option.name }}</div>
          </div>

          <mat-form-field *ngSwitchCase="isnumberDropdownOption(option.code)" class="full-width">
            <mat-label>{{ option.name }}</mat-label>
            <mat-select [(ngModel)]="option.value" [name]="'option' + i">
              <mat-option [value]="1">1</mat-option>
              <mat-option [value]="2">2</mat-option>
              <mat-option [value]="3">3</mat-option>
            </mat-select>
          </mat-form-field>

          <button
            *ngSwitchCase="isSmallMediumLargeSizeSelectionOption(option.code)"
            class="font-size-selection-button"
            [matMenuTriggerFor]="fontSizeSelectionMenu"
            mat-stroked-button
          >
            <span style="font-weight: normal">{{ option.name }}: {{ option.value | titlecase }}</span> <mat-icon>arrow_drop_down</mat-icon>
          </button>

          <mat-menu #fontSizeSelectionMenu="matMenu">
            <button mat-menu-item *ngFor="let size of ['small', 'medium', 'large']" (click)="option.value = size; onElementChange($event)">
              {{ size | titlecase }}
            </button>
          </mat-menu>
        </ng-container>
        <!-- END SWITCH CASE -->
      </ng-container>
      <!-- END *ngFor -->
    </div>
    <!-- END OPTIONS -->

    <ng-container *ngSwitchCase="templateElementType.PAGE_BREAK">
      <div class="page-break-element-container" fxLayout="column" fxLayoutAlign="center center">
        <span>This element starts a new page in the PDF</span>
      </div>
    </ng-container>

    <!-- CHECKBOX -->
    <ng-container *ngSwitchCase="templateElementType.CHECKBOX">
      <div fxLayout="column" fxLayoutGap="10px" cdkDropList (cdkDropListDropped)="dropValue($event, element.children)">
        <div class="border-solid-blue" fxLayout="column" *ngFor="let element of element.children; let i = index" cdkDrag>
          <mat-form-field fxLayout="column">
            <mat-label>Checkbox title</mat-label>
            <input
              matInput
              type="text"
              placeholder="Add a title for the checkbox here"
              [(ngModel)]="element.name"
              name="{{ 'elementName' + i }}"
            />
            <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="removeValue(i)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-checkbox *ngIf="allowConditionals" [ngModelOptions]="{ standalone: true }" [(ngModel)]="element.canHaveChildren">
            Conditional
          </mat-checkbox>

          <div
            (mousedown)="$event.stopPropagation()"
            *ngIf="element.canHaveChildren && allowConditionals"
            class="border-solid-blue drop-container"
            fxLayout="column"
            fxLayoutAlign="begin center"
            cdkDropList
            (cdkDropListDropped)="dropChildElement($event, element.children)"
          >
            <ng-container *ngFor="let child of element.children; let i = index">
              <mat-accordion class="full-width" [multi]="true">
                <checkd-template-element-config
                  [element]="child"
                  [currentCompany]="currentCompany"
                  (elementRemoved)="onElementRemoved(element.children, $event)"
                  (duplicateClicked)="onDuplicate(element.children, $event, i)"
                  (elementChange)="OnChildElementChange()"
                  cdkDrag
                >
                </checkd-template-element-config>
              </mat-accordion>
            </ng-container>

            <checkd-element-selection-menu [company]="currentCompany" (onElementClicked)="appendElementToChild($event, element.children)">
            </checkd-element-selection-menu>
          </div>
        </div>
        <button mat-button (click)="addCheckboxValue()">Add new value</button>
      </div>
    </ng-container>

    <!-- DROPDOWN -->
    <ng-container *ngSwitchCase="templateElementType.DROPDOWN">
      <div fxLayout="column" cdkDropList (cdkDropListDropped)="dropValue($event, element.children)">
        <div *ngFor="let element of element.children; let i = index" cdkDrag>
          <mat-form-field class="full-width dropdown-child">
            <input matInput type="text" placeholder="dropdown value" [(ngModel)]="element.name" name="{{ 'elementName' + i }}" />
            <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="removeValue(i)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button mat-button (click)="addDropdownValue()">Add new value</button>
      </div>
    </ng-container>

    <!-- TOGGLE - YES/NO  -->
    <ng-container *ngSwitchCase="templateElementType.TOGGLE">
      <span *ngFor="let element of element.children; let i = index" fxLayout="column" fxLayoutGap="15px">
        {{ element.name }}
        <div
          class="border-solid-blue padding-10 drop-container"
          cdkDropList
          (cdkDropListDropped)="dropChildElement($event, element.children)"
        >
          <div *ngFor="let child of element.children; let i = index" fxLayout="column" fxLayoutAlign="begin center">
            <checkd-template-element-config
              [element]="child"
              (elementRemoved)="onElementRemoved(element.children, $event)"
              [currentCompany]="currentCompany"
              (duplicateClicked)="onDuplicate(element.children, $event, i)"
              (elementChange)="OnChildElementChange()"
              cdkDrag
            >
            </checkd-template-element-config>
          </div>
          <checkd-element-selection-menu [company]="currentCompany" (onElementClicked)="appendElementToChild($event, element.children)">
          </checkd-element-selection-menu>
        </div>
      </span>
    </ng-container>

    <!-- TABULAR  -->
    <ng-container *ngSwitchCase="templateElementType.TABULAR">
      <span fxLayout="column">
        <div
          class="border-solid-blue padding-10 drop-container"
          cdkDropList
          (cdkDropListDropped)="dropChildElement($event, element.children)"
        >
          <div *ngFor="let child of element.children; let i = index" fxLayout="column" fxLayoutAlign="begin center">
            <checkd-template-element-config
              [element]="child"
              (elementRemoved)="onElementRemoved(element.children, $event)"
              [currentCompany]="currentCompany"
              (duplicateClicked)="onDuplicate(element.children, $event, i)"
              (elementChange)="OnChildElementChange()"
              cdkDrag
            >
            </checkd-template-element-config>
          </div>

          <checkd-element-selection-menu
            [company]="currentCompany"
            [filterElements]="[templateElementType.TABLE]"
            (onElementClicked)="appendElementToChild($event, element.children)"
          >
          </checkd-element-selection-menu>
        </div>
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="templateElementType.TABLE">
      <div fxLayout fxLayoutGap="20px">
        <div fxLayout fxLayoutAlign="start center" class="num-cols-controls-wrapper">
          <button mat-icon-button (click)="decreaseColumns()" matTooltip="Decrease columns" [disabled]="!canDecreaseNumColumns()">
            <mat-icon>remove</mat-icon>
          </button>

          <div>{{ numCols }} columns</div>

          <button mat-icon-button (click)="increaseColumns()" matTooltip="Increase columns" [disabled]="!canIncreaseNumColumns()">
            <mat-icon>add</mat-icon>
          </button>
        </div>

        <ng-container *ngIf="tableColumnWidthOption">
          <button class="font-size-selection-button" [matMenuTriggerFor]="cellSpacingSelectionMenu" mat-stroked-button>
            <span>{{ tableColumnWidthOption.name }}</span> <mat-icon>arrow_drop_down</mat-icon>
          </button>

          <mat-menu #cellSpacingSelectionMenu="matMenu">
            <div fxLayout="column" fxLayoutGap="10px" class="column-widths-menu-wrapper" (click)="$event.stopPropagation()">
              <ng-container
                *ngFor="
                  let elementOption of tableColumnWidthOption.value;
                  let columnIndex = index;
                  let isLastOption = last;
                  trackBy: trackFnColumnWidthMenuByIndex
                "
              >
                <div fxLayout="column" fxLayoutGap="4px">
                  <label class="column-width-label">Column {{ columnIndex + 1 }}:</label>
                  <mat-radio-group
                    [(ngModel)]="tableColumnWidthOption.value[columnIndex]"
                    [name]="'tableWidthOption' + columnIndex"
                    (change)="columnWidthChanged(columnIndex, $event.value)"
                  >
                    <mat-radio-button value="evenly" style="margin-right: 12px"> evenly </mat-radio-button>

                    <mat-radio-button value="content"> content </mat-radio-button>
                  </mat-radio-group>
                </div>
                <mat-divider *ngIf="!isLastOption"></mat-divider>
              </ng-container>
              <!-- END ngFor -->
            </div>
          </mat-menu>
        </ng-container>

        <button mat-icon-button (click)="addCell()" matTooltip="Add table cell">
          <mat-icon>dashboard_customize</mat-icon>
        </button>
      </div>

      <mat-grid-list cols="{{ numCols }}" rowHeight="100">
        <mat-grid-tile
          *ngFor="let tableChild of tableChildren; let i = index"
          [colspan]="getColspanForCell(tableChild)"
          [rowspan]="getRowspanForCell(tableChild)"
          [style.background]="getBackgroundColor(tableChild)"
        >
          <div class="grid-tile-content" fxLayout="column" fxLayoutAlign="start center">
            <section class="cell-description">
              <div *ngIf="!isCellConfigured(tableChild); else previewCell" fxLayout fxLayoutAlign="start center" fxLayoutGap="8px">
                <mat-icon color="warn">error</mat-icon>
                <div fxFlex="1 1 0">This cell is not configured</div>
              </div>

              <ng-template #previewCell>
                <div>
                  {{ getTableChildPreviewText(tableChild) }}
                </div>
              </ng-template>
            </section>

            <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="3px" class="cell-configuration-wrapper">
              <button [disabled]="tableChild.isOccupied" mat-icon-button matTooltip="Remove" (click)="removeCell(i)">
                <mat-icon>delete</mat-icon>
              </button>

              <button mat-icon-button matTooltip="Set row and column span" [matMenuTriggerFor]="cellDimensionConfigMenu">
                <mat-icon>space_dashboard</mat-icon>
              </button>

              <button
                [disabled]="tableChild.isOccupied"
                mat-icon-button
                matTooltip="Configure"
                (click)="openCellConfigurationDialog(tableChild)"
              >
                <mat-icon>build_circle</mat-icon>
              </button>
            </div>

            <mat-menu #cellDimensionConfigMenu="matMenu">
              <span class="span-dim-menu-label" (click)="$event.stopPropagation()">Column Span: {{ getColspanForCell(tableChild) }}</span>
              <button
                mat-menu-item
                (click)="$event.stopPropagation(); decreaseColspan(tableChild)"
                [disabled]="!canDecreaseColSpan(tableChild)"
              >
                <mat-icon>arrow_backward</mat-icon>
                <span>Decrease</span>
              </button>
              <button mat-menu-item (click)="$event.stopPropagation(); increaseColspan(tableChild)">
                <mat-icon>arrow_forward</mat-icon>
                <span>Increase</span>
              </button>

              <mat-divider></mat-divider>

              <span class="span-dim-menu-label" (click)="$event.stopPropagation()">Row Span: {{ getRowspanForCell(tableChild) }}</span>
              <button
                mat-menu-item
                (click)="$event.stopPropagation(); decreaseRowspan(tableChild)"
                [disabled]="!canDecreaseRowSpan(tableChild)"
              >
                <mat-icon>arrow_upward</mat-icon>
                <span>Decrease</span>
              </button>
              <button mat-menu-item (click)="$event.stopPropagation(); increaseRowspan(tableChild)">
                <mat-icon>arrow_downward</mat-icon>
                <span>Increase</span>
              </button>
            </mat-menu>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </ng-container>

    <ng-container *ngSwitchCase="templateElementType.TABLE_CHILD">
      <span fxLayout="column">
        <div class="border-solid-blue padding-10 drop-container">
          <div *ngFor="let child of element.children; let i = index" fxLayout="column" fxLayoutAlign="begin center">
            <checkd-template-element-config
              [element]="child"
              [currentCompany]="currentCompany"
              (elementRemoved)="onElementRemoved(element.children, $event)"
              (duplicateClicked)="onDuplicate(element.children, $event, i)"
              (elementChange)="OnChildElementChange()"
            >
            </checkd-template-element-config>
          </div>
        </div>
      </span>
    </ng-container>

    <!-- MAIN AND SUBFIELD -->
    <ng-container *ngSwitchCase="templateElementType.MAIN_SUB_FIELD">
      <mat-form-field class="full-width" (mousedown)="$event.stopPropagation()">
        <textarea [(ngModel)]="element.children[0].value" matInput placeholder="Main field" name="elementMain"></textarea>
      </mat-form-field>
      <mat-form-field class="full-width" (mousedown)="$event.stopPropagation()">
        <textarea [(ngModel)]="element.children[1].value" matInput placeholder="Sub field" name="elementSub"></textarea>
      </mat-form-field>
    </ng-container>

    <!-- FORMS ITEMS -->
    <ng-container *ngSwitchCase="templateElementType.FORMS_ITEMS">
      <mat-form-field class="full-width" (mousedown)="$event.stopPropagation()">
        <textarea
          [ngModel]="element?.prefill?.name"
          (ngModelChange)="element.prefill && (element.prefill.name = $event)"
          matInput
          placeholder="Prefilled Item Title"
          name="itemTitle"
        ></textarea>
      </mat-form-field>
      <mat-form-field class="full-width" (mousedown)="$event.stopPropagation()">
        <textarea
          [ngModel]="element?.prefill?.description"
          (ngModelChange)="element.prefill && (element.prefill.description = $event)"
          matInput
          placeholder="Prefilled Item Description"
          name="itemDescription"
        ></textarea>
      </mat-form-field>

      <div class="tags">
        <checkd-tags
          [tags]="tags"
          [tagSet]="[]"
          [areTagsEditable]="true"
          (onTagsUpdated)="onTagsUpdated($event)"
          placeholder="Prefilled Item Tags"
          secondaryPlaceholder="Prefilled Item Tags"
        >
        </checkd-tags>
      </div>
    </ng-container>

    <!-- TEXT ELEMENT -->
    <ng-container *ngSwitchCase="templateElementType.TEXT"></ng-container>

    <!-- IMAGE -->
    <ng-container *ngSwitchCase="templateElementType.IMAGE">
      <p>Upload an image:</p>
      <app-filestack-uploader *ngIf="!element.value" (onUpload)="onUpload($event)"></app-filestack-uploader>
      <img class="image-thumbnail" *ngIf="element.value" alt="" src="{{ element.value }}" />
    </ng-container>

    <!-- 'NONE' and 'TEXT_FIELD' -->
    <ng-container *ngSwitchDefault></ng-container>
  </form>
</ng-template>

<ng-template #standAlone>
  <div>
    <ng-container *ngTemplateOutlet="templateContent"></ng-container>
  </div>
</ng-template>

<mat-expansion-panel *ngIf="embedInDropdown; else standAlone" [expanded]="opened" (opened)="opened = true" (closed)="opened = false">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-icon class="handle"> open_with </mat-icon>

      <mat-icon class="element-icon" svgIcon="{{ element.icon }}"> </mat-icon>
    </mat-panel-title>

    <mat-panel-description fxLayout="row" fxLayoutAlign="start center">
      <span class="mat-panel-title" fxFlex="0 1 135px">{{ element.typeAlias }}</span>

      <span class="mat-panel-title blue-color-text" fxFlex="1 2 0">
        <ng-container *ngIf="element?.type === 'MAIN_SUB_FIELD'">
          <ng-container *ngIf="element.children[0].value; else checkSub">{{ element.children[0].value }}</ng-container>

          <ng-template #checkSub>
            <ng-container *ngIf="element.children[1].value">{{ element.children[1].value }}</ng-container>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="element?.name && element?.type !== 'MAIN_SUB_FIELD'">{{ element?.name }}</ng-container>
      </span>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>
    <ng-container *ngTemplateOutlet="templateContent"></ng-container>
  </ng-template>

  <mat-action-row *ngIf="opened">
    <button mat-button (click)="duplicate()">Duplicate</button>
    <button mat-button color="warn" (click)="removeClicked()">Remove</button>
  </mat-action-row>
</mat-expansion-panel>
