<div fxLayout="row">
  <h2 mat-dialog-title>Choose the report you want to attach the items to</h2>
  <mat-icon
    matTooltip="You are about to attach items to a specific Forms Report.
                You can only select reports that are not locked, deleted or removed.
                The attached items will be shown in the “Attached Items” section in the report"
    >info
  </mat-icon>
</div>
<mat-dialog-content>
  <ng-container *ngIf="{ reports: reports$ | async } as subs">
    <mat-selection-list *ngIf="!isLoading; else loadingState" [multiple]="false" tabindex="-1" (selectionChange)="reportSelected($event)">
      <mat-list-option *ngFor="let report of subs.reports" [value]="report">
        <div mat-line>{{ report?.name }}</div>
        <div mat-line>By {{ report?.aggregateData?.creatorName }} on {{ formatTimestamp(report?.createdAt) }}</div>
      </mat-list-option>
    </mat-selection-list>
  </ng-container>

  <ng-template #loadingState>
    <div class="flex flex-column justify-content-center align-items-center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</mat-dialog-content>
