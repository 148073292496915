import { Component, Inject, OnInit } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"

@Component({
  selector: "app-option-list-dialog",
  templateUrl: "./option-list-dialog.component.html",
  styleUrls: ["./option-list-dialog.component.scss"],
})
export class OptionListDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { list: any[]; title?: string },
    private dialogRef: MatDialogRef<OptionListDialogComponent>
  ) {}

  ngOnInit() {}

  optionSelected(option: any) {
    this.dialogRef.close(option)
  }
}
