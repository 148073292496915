<div class="filter-buttons-wrapper" [class.with-padding]="!(this.isForMyItemsPage || this.noPadding)">
  <div fxLayout="column" fxLayoutAlign="center center">
    <span>Open</span>
    <img [src]="getImageSource('open')" alt="Toggle open items" (click)="toggleFilter('open')" />
    <span
      *ngIf="showItemNumbers && !isForMyItemsPage"
      [style.visibility]="statusFilter.OPEN ? 'visible' : 'hidden'"
      >{{statusCounts?.["OPEN"] || 0}}</span
    >
  </div>

  <div fxLayout="column" fxLayoutAlign="center center">
    <span>Delegated</span>
    <img [src]="getImageSource('delegated')" alt="Toggle delegated items" (click)="toggleFilter('delegated')" />
    <span
      *ngIf="showItemNumbers && !isForMyItemsPage"
      [style.visibility]="statusFilter.DELEGATED ? 'visible' : 'hidden'"
      >{{statusCounts?.["DELEGATED"] || 0}}</span
    >
  </div>

  <div fxLayout="column" fxLayoutAlign="center center">
    <span>In Progress</span>
    <img [src]="getImageSource('in-progress')" alt="Toggle items in progress" (click)="toggleFilter('in-progress')" />
    <span
      *ngIf="showItemNumbers && !isForMyItemsPage"
      [style.visibility]="statusFilter.INPROGRESS ? 'visible' : 'hidden'"
      >{{statusCounts?.["INPROGRESS"] || 0}}</span
    >
  </div>

  <div fxLayout="column" fxLayoutAlign="center center">
    <span>Fixed</span>
    <img [src]="getImageSource('fixed')" alt="Toggle fixed items" (click)="toggleFilter('fixed')" />
    <span
      *ngIf="showItemNumbers && !isForMyItemsPage"
      [style.visibility]="statusFilter.FIXED ? 'visible' : 'hidden'"
      >{{statusCounts?.["FIXED"] || 0}}</span
    >
  </div>

  <div fxLayout="column" fxLayoutAlign="center center">
    <span>Closed</span>
    <img [src]="getImageSource('closed')" alt="Toggle closed items" (click)="toggleFilter('closed')" />
    <span
      *ngIf="showItemNumbers && !isForMyItemsPage"
      [style.visibility]="statusFilter.CLOSED ? 'visible' : 'hidden'"
      >{{statusCounts?.["CLOSED"] || 0}}</span
    >
  </div>
</div>
