import { Directive, HostBinding, Input } from "@angular/core"

@Directive({
  selector: "[tertiary-button]",
})
export class TertiaryButtonDirective {
  constructor() {}
  @Input() isRounded = false

  @HostBinding("class")
  get classes(): string {
    return "button button--tertiary"
  }

  @HostBinding("class.button__rounded") get roundedClass(): boolean {
    return this.isRounded
  }
}
