import { Directive, HostBinding, Input } from "@angular/core"

@Directive({
  selector: "[secondary-button]",
})
export class SecondaryButtonDirective {
  constructor() {}
  @Input() isRounded = false
  @Input() type: string = ""

  @HostBinding("class")
  get buttonType(): string {
    if (this.type === "text") {
      return "button button__secondary--text-btn"
    } else if (this.type === "outline") {
      return "button button__secondary--outline"
    } else {
      return "button button__secondary--filled"
    }
  }

  @HostBinding("class.button__rounded") get roundedClass(): boolean {
    return this.isRounded
  }
}
