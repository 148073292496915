import { Component, Inject, OnInit } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { PdfDataService } from "../../../services/pdf-data.service"

@Component({
  selector: "app-pdf-preview-dialog",
  templateUrl: "./pdf-preview-dialog.component.html",
  styleUrls: ["./pdf-preview-dialog.component.scss"],
})
export class PdfPreviewDialogComponent implements OnInit {
  pdfData: SafeResourceUrl
  public static ACTION_CLOSE: string = "close"
  public static ACTION_SAVE: string = "save"

  isLoading = true

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PdfPreviewDialogComponent>,
    private pdfDataService: PdfDataService,
    private sanitizer: DomSanitizer
  ) {
    if (this.data.dataUrl) {
      this.pdfData = this.data.dataUrl
    } else {
      const pdf = this.pdfDataService
        .generateReport(
          this.data.form.toPdfDocDefinition(
            this.data.projectTitle,
            this.data.user,
            this.data.qrCodeLink,
            this.data.companyLogo,
            this.data.createdAt,
            this.data.creatorName,
            null,
            null,
            this.data.pdfFooterText,
            {},
            null,
            this.data.templateVersion,
            this.data.footerLogo
          )
        )
        .getDataUrl()
        .then((dataurl: string) => {
          const sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(dataurl)
          this.pdfData = this.base64ToArrayBuffer(dataurl.split(",")[1])
          this.isLoading = false
        })
    }
  }

  base64ToArrayBuffer(base64: string) {
    const binary_string = window.atob(base64)
    const len = binary_string.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i)
    }

    return bytes.buffer
  }

  ngOnInit() {}

  close() {
    this.dialogRef.close(PdfPreviewDialogComponent.ACTION_CLOSE)
  }

  save() {
    this.dialogRef.close(PdfPreviewDialogComponent.ACTION_SAVE)
  }
}
