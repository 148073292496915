import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input"
import { AutoCompleteModule } from "primeng/autocomplete"

import { DataViewModule } from "primeng/dataview"
import { DialogModule } from "primeng/dialog"
import { InputTextModule } from "primeng/inputtext"
import { InputTextareaModule } from "primeng/inputtextarea"
import { ProgressSpinnerModule } from "primeng/progressspinner"
import { RadioButtonModule } from "primeng/radiobutton"
import { RippleModule } from "primeng/ripple"
import { TagModule } from "primeng/tag"
import { TooltipModule } from "primeng/tooltip"

import { EditFormsTemplateDialogComponent } from "./edit-forms-template-dialog/edit-forms-template-dialog.component"
import { ButtonModule } from "app/dashboard/next-ui/button/button.module"
import { TagSelectionComponent } from "./components/tag-selection/tag-selection.component"

const PRIME_NG_MODULES = [
  AutoCompleteModule,
  ButtonModule,
  DataViewModule,
  DialogModule,
  InputTextModule,
  InputTextareaModule,
  TooltipModule,
  ProgressSpinnerModule,
  RadioButtonModule,
  RippleModule,
  TagModule,
]

@NgModule({
  declarations: [EditFormsTemplateDialogComponent, TagSelectionComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ...PRIME_NG_MODULES, MatInputModule, ButtonModule],
  exports: [EditFormsTemplateDialogComponent],
})
export class EditFormsTemplateDialogModule {}
