import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import { ICheckdAgreement } from "@models/common/agreements.interface"
import { SnackbarService, UserService } from "@services"

// export interface

@Component({
  selector: "checkd-terms-and-conditions-dialog",
  templateUrl: "./terms-and-conditions-dialog.component.html",
  styleUrls: ["./terms-and-conditions-dialog.component.scss"],
})
export class TermsAndConditionsDialogComponent {
  readonly privacyPolicyUrl = "https://www.checkd.it/personvern-sikkerhet"
  readonly termsAndConditionsUrl = "https://www.checkd.it/terms"

  language: "en" | "no" = "no"

  constructor(
    private userService: UserService,
    private snackbar: SnackbarService,
    private matDialogRef: MatDialogRef<TermsAndConditionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ICheckdAgreement[]
  ) {}

  get title(): string {
    // tslint:disable-next-line:switch-default
    switch (this.language) {
      case "en":
        return "Updated Terms"
      case "no":
        return "Oppdaterte Vilkår"
    }
  }

  get agreeText(): string {
    // tslint:disable-next-line:switch-default
    switch (this.language) {
      case "en":
        return "I have read and agree to the terms"
      case "no":
        return "Jeg har lest og er enig med vilkårene"
    }
  }

  get buttonText(): string {
    // tslint:disable-next-line:switch-default
    switch (this.language) {
      case "en":
        return "Continue"
      case "no":
        return "Fortsett"
    }
  }

  async confirmButtonClicked() {
    await this.userService.updateUserAgreements(this.data)
    this.snackbar.showMessage("Thank you for accepting our terms and agreements!")
    this.matDialogRef.close()
  }
}
