<div fxLayout="row" matTooltip="You can't edit this element! The report is locked" [matTooltipDisabled]="!readOnly">
  <mat-form-field>
    <mat-label>{{title}}</mat-label>
    <mat-select
      [placeholder]="title"
      [(ngModel)]="dropdownValue"
      (selectionChange)="selectionChange()"
      [multiple]="isMultiSelect"
      [disabled]="readOnly">

      <mat-option  *ngFor="let item of values" [value]="item.name">{{item.name}}</mat-option>

      <mat-option *ngIf="!isMultiSelect">None</mat-option>

      <ng-container *ngIf="isAddable">
        <mat-option *ngIf="isMultiSelect && (element?.values || []).length === 0" disabled>
          Click the button below to add an option
        </mat-option>
        <mat-divider></mat-divider>

        <button class="add-new" mat-button (click)="addOption($event)">
          <mat-icon>add_circle_outline</mat-icon> Add option
        </button>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
