import { Directive, EventEmitter, Input } from "@angular/core"
import { LeafletService } from "../../services"
import { LatLng, LatLngBounds, Map, Marker, Point } from "leaflet"
import { LeafletDirective } from "@asymmetrik/ngx-leaflet"

interface LeafletClickEvent {
  containerPoint: Point
  latlng: LatLng
  layerPoint: Point
  originalEvent: any
  target: any
  type: string
  [propName: string]: any
}

@Directive({
  selector: "[leafletCheckdItem]",
  outputs: ["itemClicked", "itemMoved", "onItemContextMenu"],
})
export class LeafletCheckdItemDirective {
  leafletDirective: LeafletDirective

  itemClicked = new EventEmitter()

  itemMoved = new EventEmitter()
  onItemContextMenu = new EventEmitter()

  @Input("leafletLayer") layer: Marker

  @Input() drawingBounds: LatLngBounds

  constructor(protected leafletService: LeafletService, leafletDirective: LeafletDirective) {
    this.leafletDirective = leafletDirective
  }

  ngOnInit() {
    if (null != this.leafletDirective.getMap()) {
      this.preventMarkerToGoOutOfBounds(this.layer, this.leafletDirective.getMap())
      this.emitClickEvents()
      this.emitPositionChangeEvents()
      this.emitContextMenuEvents()
    }
  }

  private preventMarkerToGoOutOfBounds(marker: Marker, map: Map) {
    let lastValidPosition = marker.getLatLng()
    let bounds = this.drawingBounds
    marker.addEventListener("drag", (event) => {
      let newPosition = marker.getLatLng()
      if (bounds.contains(newPosition)) lastValidPosition = newPosition
      else marker.setLatLng(lastValidPosition)
    })
  }

  private emitContextMenuEvents() {
    this.layer.addEventListener("contextmenu", (event) => {
      this.onItemContextMenu.emit(event)
    })
  }

  private emitClickEvents() {
    this.layer.addEventListener("click", (event) => {
      this.itemClicked.emit(event)
    })
  }

  private emitPositionChangeEvents() {
    // @ts-ignore
    this.layer.addEventListener("dragend", (event: LeafletClickEvent) => {
      this.itemMoved.emit(Object.assign({ layer: this.layer }, this.normalizeCoords(event, this.leafletDirective.getMap())))
    })
  }

  // Returns an object with x and y attributes between 0.0 and 1.0, e.g.:
  //
  // {x: 0.0, y: 0.0} is the upper left corner of the image/map
  // {x: 1.0, y: 1.0} is the bottom right corner of the image/map
  private normalizeCoords(event: LeafletClickEvent, map: Map): { x: number; y: number } {
    const bounds = this.drawingBounds
    return this.leafletService.normalizeLatLngCoordinates(this.layer.getLatLng(), bounds.getNorthWest(), bounds.getSouthEast())
  }
}
