import { identity } from "lodash-es"
import { pickBy } from "lodash-es"
import { mmToPoints } from "../../../utilities"

import { Style, styles } from "./styles.docdefinition"
import { CheckdColors } from "@checkd-colors"

export const heading = (headingText: string, additionalStyles: Style[] = []) => ({
  text: headingText,
  style: [Style.heading01, ...additionalStyles],
  margin: [0, 0, 0, 20],
})

export const qrCode = (url: string, fit = 300, additionalStyles: Style[] = []) => ({
  qr: url,
  fit,
  style: [...additionalStyles],
  margin: [0, 0, 0, 15],
})

export const paragraph = (paragraphText: string, additionalStyles: Style[] = []) => ({
  text: paragraphText,
  style: [Style.paragraph01, ...additionalStyles],
  margin: [0, 0, 0, 30],
})

export const image = (imageUrl: string, fit = [50, 50], additionalStyles: Style[] = []) => ({
  image: imageUrl,
  fit,
  style: additionalStyles,
})

export const horizontalLine = {
  margin: [0, 5, 0, 5],
  canvas: [
    {
      type: "line",
      x1: 0,
      y1: 0,
      x2: 580,
      y2: 0,
      lineWidth: 0.5,
      lineColor: CheckdColors.CHECKD_RED,
    },
  ],
  style: [Style.center],
}

export const templateLogoHeader = {
  header: [
    {
      ...image("templateLogo", [200, 100], [Style.center]),
    },
    horizontalLine,
  ],
}

export const checkdFooter = (companyName?: string) => ({
  footer: [
    horizontalLine,
    {
      margin: [mmToPoints(6.25), 8, mmToPoints(6.25), mmToPoints(6.25)],
      columns: [
        {
          ...image("checkdLogo", [150, 80], [Style.left]),
          width: 150,
          margin: [0, -5, 0, 0],
        },
        {
          text: [
            "Template created with ",
            {
              text: "www.checkd.it",
              link: "https://www.checkd.it",
              bold: true,
            },
            companyName ? " by\n" : "\n",
            {
              text: companyName ? `${companyName}` : "",
              bold: true,
            },
          ],
          style: Style.center,
          margin: [0, 0, 0, 0],
          width: "*",
        },
        {
          ...image("companyLogo", [150, 80], [Style.right]),
          width: 150,
          margin: [0, 0, 0, 0],
        },
      ],
    },
  ],
})

export const images = (additionalImages: { [key: string]: string }) => ({
  images: {
    ...pickBy(additionalImages, identity),
  },
})

interface IDocDefinitionConfig {
  content: any[]
  header?: any
  footer?: any
  images?: any
}

// export const docDefinition = (content: any[], footer: any, images: any) => ({
export const docDefinition = (config: IDocDefinitionConfig) => ({
  pageSize: "A4",
  pageMargins: [mmToPoints(6.25), mmToPoints(6.25), mmToPoints(6.25), 105],
  content: [...config.content],
  ...styles,
  ...(config.header || {}),
  ...(config.footer || {}),
  ...(config.images || {}),
})
