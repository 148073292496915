<div class="password-recovery" fxLayout="column" fxLayoutAlign="center center">

  <div *ngIf="!wasEmailSubmitted" class="message-before-recovery">
    <p>Enter the email address that you used to register. </p>
    <p>You will receive an email with instructions on how to reset your password.</p>
    <p><strong>NB:</strong> This link expires 30 minutes after you received the email.</p>
  </div>

  <div *ngIf="!wasEmailSubmitted" class="password-recovery-form">
    <mat-form-field class="example-full-width">
      <input #emailInput matInput placeholder="Enter your email" value="" [formControl]="email">
    </mat-form-field>
    <checkd-button (click)="onSubmit(emailInput.value.trim())">Submit</checkd-button>
  </div>

  <div *ngIf="wasEmailSubmitted" class="message-after-recovery">

    <p>
      Email has been sent.
    </p>

    <p>
      Please check your inbox.
    </p>

    <p>
      You will soon receive and email with instructions to set a new password.
    </p>

  </div>
</div>
