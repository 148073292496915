<div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center">
  <div style="max-width: 85%;" fxLayout="column">
    <field-report-list-item *ngFor="let report of reports | slice:0:sliceSize" [report]="report"></field-report-list-item>
  </div>

  <app-placeholder *ngIf="(reports?.length > 0) == false" svgIcon="reportlist_empty" heading="No reports">
    <p>Generate a new report from the item list</p>
  </app-placeholder>

  <button
    mat-button
    *ngIf="sliceSize < reports?.length"
    (click)="setMaxSliceSize()">
      show all reports ({{reports?.length}})
  </button>
</div>
