<h2 mat-dialog-title>{{data.title}}</h2>

<mat-dialog-content>
  <checkd-tags #tagThing
    [areTagsEditable]="true"
    [tags]="tags"
    [tagSet]="tagSet"
    (onTagsUpdated)="onTagsUpdated($event)">
  </checkd-tags>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="checkd-btn cancel-btn-color" mat-button (click)="dialogRef.close(null)">Cancel</button>
  <button class="checkd-btn save-btn-color"
          [disabled]="!(tagThing.tagsChanged | async)" mat-button (click)="onSaveClicked()">
    Save
  </button>
</mat-dialog-actions>
