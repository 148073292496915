import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from "@angular/core"
import { COMMA, ENTER } from "@angular/cdk/keycodes"
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocomplete as MatAutocomplete,
} from "@angular/material/legacy-autocomplete"
import { MatLegacyChipInputEvent as MatChipInputEvent } from "@angular/material/legacy-chips"

import { UntypedFormControl, Validators, FormGroupDirective, NgForm } from "@angular/forms"
import { ErrorStateMatcher } from "@angular/material/core"

import { Observable } from "rxjs"
import { map, startWith } from "rxjs/operators"

import { EmailWithRoles, PersonWithRoles, Person } from "@models/common"

@Component({
  selector: "checkd-autocomplete-emails",
  templateUrl: "./checkd-autocomplete-emails.component.html",
  styleUrls: ["./checkd-autocomplete-emails.component.scss"],
})
export class CheckdAutocompleteEmailsComponent implements OnInit {
  @ViewChild("auto", { static: true }) matAutocomplete: MatAutocomplete
  @ViewChild("mailInput", { static: true }) mailInput: ElementRef<HTMLInputElement>

  @Output() emailInvitesChanged = new EventEmitter<string[]>()

  myControl = new UntypedFormControl("", [Validators.required, Validators.email])

  separatorKeysCodes: number[] = [ENTER, COMMA]

  emailInvitations: string[] = []

  autocompleteGroups: { name: string; values: Person[] }[] = []
  // filteredGroups: Observable<{ name: string, values: Person[]}>[]

  ngOnInit() {}

  pasteEvent(event: ClipboardEvent) {
    const pasteText = event!.clipboardData!.getData("text/plain")
    const mails = this.extractEmails(pasteText)
    if (mails && mails.length > 1) {
      mails.forEach((email) => {
        // const companyMember = this._filter(mail, this.companyMembers)[0]
        // const companyAssociate = this._filter(mail, this.companyAssociates)[0]
        // if (companyMember) {
        //   this.addCompanyMember(companyMember, this.selectedRole)
        // } else if (companyAssociate) {
        //   this.addCompanyAssociate(companyMember, this.selectedRole)
        // } else {
        //   this.emailInvitations.push({email: mail, roles: [this.selectedRole]})
        // }
        this.addEmail(email)
      })

      // Timeout required to clear input otherwise it doesnt work
      setTimeout(() => {
        this.mailInput.nativeElement.value = ""
        this.myControl.setValue(null)
      }, 100)
    }
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen && this.myControl.valid) {
      const input = event.input
      const value = event.value

      if ((value || "").trim()) {
        this.addEmail(value.trim())
      }

      if (input) {
        input.value = ""
      }

      this.myControl.setValue(null)
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // const person = event.option.value
    // if (event.option.group.label === this.companyName) {
    //   this.addCompanyMember(person, this.selectedRole)
    // } else {
    //   this.addCompanyAssociate(person, this.selectedRole)
    // }
    this.mailInput.nativeElement.value = ""
    this.myControl.setValue(null)
  }

  addEmail(email: string) {
    const cleanedEmail = email.trim().toLowerCase()

    if (this.emailInvitations.includes(cleanedEmail)) {
      return
    }

    this.emailInvitations.push(cleanedEmail)
    this.emailInvitesChanged.emit(this.emailInvitations)
  }

  remove(element: any, from: any[]) {
    const index = from.indexOf(element)
    if (index > -1) {
      from.splice(index, 1)
    }
    this.emailInvitesChanged.emit(this.emailInvitations)
  }

  private extractEmails(text: string) {
    return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)
  }

  private _filter(value: string, from: Person[]): Person[] {
    if (typeof value === "string") {
      const filterValue = value.toLowerCase()
      return from.filter((person) => {
        const email = person.email.toLowerCase()
        const name = person.name.toLowerCase()
        return email.includes(filterValue) || name.includes(filterValue)
      })
    } else {
      return from
    }
  }
}
