import { IDocumentPermissions, IPartialDocumentPermissions, PermissionMap } from "./roles-and-permissions.interface"

export class PermissionsHandler {
  static mergePermissionMaps(permissionMapA: PermissionMap, permissionMapB?: Partial<PermissionMap>): PermissionMap {
    return permissionMapB != null ? { ...permissionMapA, ...permissionMapB } : permissionMapA
  }

  static mergePermissions(permissionsA: IPartialDocumentPermissions, permissionsB: IPartialDocumentPermissions): IDocumentPermissions {
    const roles = {}
    const allRoleKeys = Array.from(new Set([...Object.keys(permissionsA.roles), ...Object.keys(permissionsB.roles)]))

    allRoleKeys.forEach((roleType) => {
      // @ts-ignore
      roles[roleType] = {
        // @ts-ignore
        ...(permissionsA.roles[roleType] || {}),
        // @ts-ignore
        ...(permissionsB.roles[roleType] || {}),
      }
    })

    return { roles }
  }
}
