<h1 mat-dialog-title>Select which <strong>Next Project</strong> to connect</h1>

<input
  #projectSearch
  matInput
  placeholder="Search for project"
  (keyup)="nextExperimentalService.nextProjectProjectSearch$.next(projectSearch.value)"
/>

<section mat-dialog-content>
  <mat-action-list *ngIf="nextExperimentalService.nextProjectProjects$ | async as availableProjects; else loading">
    <mat-list-item *ngFor="let project of availableProjects.items" (click)="dialogRef.close(project)">
      <div mat-line>{{ project.name }}</div>
      <mat-icon>cloud_sync</mat-icon>
    </mat-list-item>
  </mat-action-list>

  <ng-template #loading>
    <mat-progress-spinner></mat-progress-spinner>
  </ng-template>
</section>
