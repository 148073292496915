<div class="top-item-actions">
  <div class="top-item-actions__left-actions">
    <div class="left-actions__items-count">
      <ng-container *ngIf="itemTableService.count | async as count">
        <ng-container *ngIf="itemsHaveBeenFiltered(count); then showFilteredAndTotal; else justShowTotalItems"> </ng-container>

        <ng-template #showFilteredAndTotal>
          <p>
            <b>{{ count.itemsShown }}</b> of <b>{{ count.total }}</b> item{{ count.total === 1 ? "" : "s" }} shown
          </p>
        </ng-template>

        <ng-template #justShowTotalItems>
          <p>
            <b>{{ count.total }}</b> item{{ count.total === 1 ? "" : "s" }}
          </p>
        </ng-template>
      </ng-container>
    </div>
    <div class="left-actions__items-button-container">
      <button
        id="intercom-company-items-button"
        class="left-actions__items-button"
        [class.left-actions__items-button--active]="projectItemsDisplayed$.value === projectItemsDisplayedEnum.UserCompanyItems"
        matRipple
        (click)="onItemsDisplayedButtonClicked(projectItemsDisplayedEnum.UserCompanyItems)"
      >
        <span class="left-actions__img-container">
          <img class="left-actions__img" src="assets/icons/project_view/company-items.png" />
        </span>

        <span class="left-actions__text">
          <span>Company</span>
          <span>items</span>
        </span>
      </button>

      <button
        class="left-actions__items-button"
        [class.left-actions__items-button--active]="projectItemsDisplayed$.value === projectItemsDisplayedEnum.AllItems"
        *ngIf="canSeeAllItems"
        matRipple
        (click)="onItemsDisplayedButtonClicked(projectItemsDisplayedEnum.AllItems)"
      >
        <div class="left-actions__img-container">
          <img src="assets/icons/project_view/group.svg" class="left-actions__img" />
        </div>

        <div class="left-actions__text">
          <span>All</span>
          <span>items</span>
        </div>
      </button>

      <button
        class="left-actions__items-button"
        [class.left-actions__items-button--active]="projectItemsDisplayed$.value === projectItemsDisplayedEnum.UserItems"
        matRipple
        (click)="onItemsDisplayedButtonClicked(projectItemsDisplayedEnum.UserItems)"
      >
        <ngx-avatars [src]="currentUser?.image || ''" [name]="currentUser?.name" size="30" class="left-actions__img"> </ngx-avatars>
        <div class="left-actions__text">
          <span>My</span>
          <span>items</span>
        </div>
      </button>
    </div>
  </div>

  <div class="top-item-actions__center-actions" fxHide.lt-md>
    <div class="trapezoid-shadow-box">
      <div class="item-filters">
        <checkd-status-filter
          #statusFilter
          [statusCounts]="projectItemsStatusCounts"
          (change)="notifyParentFilterStateChanged.emit($event)"
        >
        </checkd-status-filter>
      </div>

      <svg>
        <path d="M0,0 H400 L350,60 H50, Z" class="trapezoid-shadow-box__trapezoid-shape"></path>
      </svg>
    </div>
  </div>

  <div class="top-item-actions__right-actions">
    <button (click)="resetFilters()" primary-button [type]="'outline'"><span>Clear filters</span></button>
  </div>
</div>
