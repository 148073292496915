import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { AgreementsService } from "@services/agreements.service"
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox"
import { Subscription } from "rxjs"
import { ICheckdAgreement } from "@models/common/agreements.interface"

@Component({
  selector: "checkd-agreements",
  templateUrl: "./checkd-agreements.component.html",
  styleUrls: ["./checkd-agreements.component.scss"],
})
export class CheckdAgreementsComponent implements OnInit, OnDestroy {
  @Output() agreementsChange = new EventEmitter()

  agreementsToUpdate: ICheckdAgreement[] = []

  private subscription = new Subscription()

  constructor(public agreements: AgreementsService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.agreements.currentUserAgreementsStatus$.subscribe((status) => {
        this.agreementsToUpdate = [...status.notUpToDate, ...status.notAgreedTo]
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  updateAgreements(change: MatCheckboxChange) {
    if (change.checked) {
      this.agreementsChange.emit(this.agreementsToUpdate)
    }
  }
}
