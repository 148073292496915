import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable } from "rxjs"

export interface IItemCount {
  itemsShown: number
  total: number
}

@Injectable({
  providedIn: "root",
})
export class ItemTableService {
  private counter = new BehaviorSubject<IItemCount>({ itemsShown: 0, total: 0 })

  // A count of total items and the number of items shown (total - items filtered out)
  readonly count: Observable<IItemCount> = this.counter.asObservable()

  updateCount(shown: number, total: number) {
    this.counter.next({ itemsShown: shown, total })
  }
}
