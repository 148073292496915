<div class="drawing-list-container flex flex-column gap-2 justify-content-center">
  <div class="flex justify-content-center">
    <input type="text" placeholder="Search for drawing" #drawingFilterSearch (keyup)="(0)" value="" size="30" />
  </div>
  <ng-container *ngIf="orderedDrawings | nameFilter: drawingFilterSearch.value:sliceSize | slice: 0:sliceSize as filteredDrawings">
    <div class="flex flex-wrap justify-content-center align-items-center">
      <app-drawing-list-item
        *ngFor="let drawing of filteredDrawings"
        [currentUser]="currentUser"
        [drawing]="drawing"
        [userProjectRole]="userProjectRole"
        (drawingClick)="drawingClick.emit(drawing)"
        (menuOptionSelected)="onMenuOptionSelected($event, drawing)"
      ></app-drawing-list-item>
    </div>
    <ng-container *ngIf="!filteredDrawings.length">
      <div class="empty-state__component-container">
        <checkd-empty-state [title]="noProjectsTitle" [text]="noProjectsDescription" [image]="noProjectsImgSrc"></checkd-empty-state>
      </div>
    </ng-container>
  </ng-container>
  <button mat-button *ngIf="sliceSize < orderedDrawings?.length" (click)="setMaxSliceSize()">
    show all drawings ({{ orderedDrawings?.length }})
  </button>
</div>
