import { Component, OnInit } from "@angular/core"

@Component({
  selector: "checkd-publisher-card",
  templateUrl: "./publisher-card.component.html",
  styleUrls: ["./publisher-card.component.scss"],
})
export class PublisherCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
