/// #if environment=="web"
import { environment } from "environments/environment"
import firebase from "firebase/compat/app"
import undefined from "firebase/compat/firestore"
// import { firestore } from 'firebase/compat/app'
firebase.initializeApp(environment.firebase)

const DB = firebase.firestore()
/// #endif

export { DB }
