import { mergeMap, map, switchMap } from "rxjs/operators"
import { Injectable } from "@angular/core"
import {
  COLLECTIONS,
  LegacyReport,
  LegacyReportData,
  Project,
  Relation,
  ProjectData,
  Person,
  LABELS,
  Company,
  Timeline,
} from "@models/common"
import { RelationService, ModelService, ReportTimelineService } from "@services"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { Observable, from as observableFrom } from "rxjs"

@Injectable()
export class LegacyReportService {
  constructor(
    private db: AngularFirestore,
    private modelService: ModelService,
    private relationService: RelationService,
    private reportTimelineService: ReportTimelineService
  ) {}

  listenToUid(legacyReportUid: string): Observable<LegacyReport> {
    return this.modelService.listenTo(LegacyReport.COLLECTION, legacyReportUid)
  }

  listenToCreator(legacyReport: LegacyReport): Observable<Person> {
    return this.relationService.listenToFirstTarget(legacyReport, COLLECTIONS.PEOPLE, [LABELS.CREATED_BY])
  }

  listenToCompany(legacyReport: LegacyReport): Observable<Company> {
    return this.relationService.listenToFirstTarget(legacyReport, COLLECTIONS.COMPANIES, [LABELS.CONTAINED_BY])
  }

  listenToProject(legacyReport: LegacyReport): Observable<Project> {
    return this.relationService.listenToFirstTarget(legacyReport, COLLECTIONS.PROJECTS)
  }

  listenToTimeline(legacyReport: LegacyReport): Observable<Timeline> {
    return this.reportTimelineService.listenForReport(legacyReport)
  }

  listenToLastTimelineElementMessage(legacyReport: LegacyReport): Observable<string> {
    return this.reportTimelineService.listenToLastTimelineElement(legacyReport).pipe(
      switchMap((timelineElement) => {
        return observableFrom(timelineElement.getCreator()).pipe(
          map((creator) => this.reportTimelineService.createMessageFromTimelineElement(timelineElement, creator))
        )
      })
    )
  }

  addReadOnlyChangedTimelineElement(creator: Person, legacyReport: LegacyReport, readonly: boolean) {
    return this.reportTimelineService.readOnlyChanged(creator, legacyReport, readonly)
  }
}
