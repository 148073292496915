import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-briefcase-project-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" [attr.viewBox]="getViewBox()" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g [attr.transform]="getScale()">
        <path
          d="M21 5.25H18V3.75C18 2.09297 16.657 0.75 15 0.75H9C7.34531 0.75 6 2.09297 6 3.75V5.25H3C1.34297 5.25 0 6.59531 0 8.25V20.25C0 21.907 1.34297 23.25 3 23.25H21C22.657 23.25 24 21.907 24 20.25V8.25C24 6.59531 22.6547 5.25 21 5.25ZM9 3H15C15.4142 3 15.75 3.33577 15.75 3.75V5.25H8.25V3.75C8.25 3.33562 8.5875 3 9 3ZM3 7.5H21C21.4125 7.5 21.75 7.8375 21.75 8.25V12.75H2.25V8.25C2.25 7.8375 2.5875 7.5 3 7.5ZM21 21H3C2.5875 21 2.25 20.6625 2.25 20.25V15H9V15.75C9 16.5783 9.67172 17.25 10.5 17.25H13.5C14.3283 17.25 15 16.5783 15 15.75V15H21.75V20.25C21.75 20.6625 21.4125 21 21 21Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2511_17603">
          <rect [attr.width]="getSize()" [attr.height]="getSize()" fill="white" />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class BriefcaseProjectIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }

  getViewBox(): string | undefined {
    return this.svgIconsService.getViewBox(this.size)
  }
  getScale(): string | undefined {
    return this.svgIconsService.getScale(this.size)
  }
}
