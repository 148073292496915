import { Component, OnInit } from "@angular/core"
import moment, { Moment } from "moment"
import { DefaultElementComponent } from "../default-element/default-element.component"

@Component({
  selector: "app-time-element",
  templateUrl: "./time-element.component.html",
  styleUrls: ["./time-element.component.scss"],
})
export class TimeElementComponent extends DefaultElementComponent implements OnInit {
  // @ts-ignore
  private _dateBackingField: Moment = null

  get date(): Moment {
    return this._dateBackingField
  }

  set date(newDate: Moment) {
    this._dateBackingField = newDate
    this.setValue(this.date == null ? "" : newDate.format("HH:mm"))
  }

  override ngOnInit() {
    if (this.element?.value) {
      // this._dateBackingField = moment(this.element.value)
      this._dateBackingField = moment(this.element.value, "HH:mm")
    }
  }
}
