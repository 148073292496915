<mat-dialog-content>
  <item-creation-view
    #itemCreationView
    [project]="project"
    [projectMembers]="projectMembers"
    [currentUser]="currentUser"
    (onSave)="save($event)"
  >
    <div class="mid-right-content">
      <p class="drawing-instructions">Drag the marker to change position on the drawing</p>
      <div>
        <app-checkd-drawing
          *ngIf="drawing"
          [enableGestureToAddItemMarker]="false"
          [initializeMarkerInMiddle]="true"
          [drawingData]="drawing.data"
          (onItemCreate)="itemCreated($event)"
          (onItemLayerMoved)="itemMoved($event)"
        >
        </app-checkd-drawing>
      </div>
    </div>
  </item-creation-view>
</mat-dialog-content>
