import firebase from "firebase/compat/app"
import firestore = firebase.firestore
import { Relation } from "./relation"
import { IDocumentPermissions } from "./roles-and-permissions"

export abstract class ModelInCollection {
  public static COLLECTION: string
  protected static duplicationAttributeBlacklist: string[]
  public abstract get collectionName(): string
  public uid?: string
  public ref?: firestore.DocumentReference
  public abstract get data(): any
  public abstract collection(collection: string): firestore.CollectionReference
  public abstract add<M extends ModelInCollection>(target: M, labels: string[]): Promise<Relation>
  public abstract duplicate(attributeBlacklist: string[]): any
  public abstract batchUpdate(batch: firestore.WriteBatch, data: any): this
  public abstract get createdAt(): number
  public abstract get updatedAt(): number
  public abstract get permissions(): IDocumentPermissions
}
