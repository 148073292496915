import { Component, OnInit } from "@angular/core"

@Component({
  selector: "checkd-subscriptor-card-component",
  templateUrl: "./subscriptor-card.component.html",
  styleUrls: ["./subscriptor-card.component.scss"],
})
export class SubscriptorCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
