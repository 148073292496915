<mat-dialog-content>
  <form class="full-width">
    <mat-form-field class="full-width">
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let person of selectedCompanyPeople"
          [selectable]="true"
          [removable]="true"
          (removed)="remove(person, selectedCompanyPeople)"
        >
          {{ person?.person?.email }}
          <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
        </mat-chip>
        <mat-chip
          *ngFor="let person of selectedCompanyAssociates"
          [selectable]="true"
          [removable]="true"
          (removed)="remove(person, selectedCompanyAssociates)"
        >
          {{ person?.person?.email }}
          <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
        </mat-chip>
        <mat-chip
          *ngFor="let invite of emailInvitations"
          [selectable]="true"
          [removable]="true"
          (removed)="remove(invite, emailInvitations)"
        >
          {{ invite?.email }}
          <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
        </mat-chip>
        <input
          #mailInput
          (paste)="pasteEvent($event)"
          placeholder="Enter, or copy and paste, multiple email addresses"
          [formControl]="myControl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="add($event)"
        />
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-optgroup [label]="companyName">
          <mat-option *ngFor="let person of filteredCompanyMembers$ | async" [value]="person">
            <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <ngx-avatars [src]="person.image" [name]="person.name" size="25"></ngx-avatars>
              <span>{{ person?.name }}</span>
              <span>|</span>
              <small> {{ person?.email }}</small>
            </span>
          </mat-option>
        </mat-optgroup>
        <mat-optgroup label="Your company associates">
          <mat-option *ngFor="let person of filteredCompanyAssociates$ | async" [value]="person">
            <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <ngx-avatars [src]="person.image" [name]="person.name" size="25"></ngx-avatars>
              <span>{{ person?.name }}</span>
              <span>|</span>
              <small> {{ person?.email }}</small>
            </span>
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
      <mat-hint *ngIf="myControl.hasError('email')" i18n><span class="mat-error">Enter a valid email</span></mat-hint>
    </mat-form-field>
  </form>
  <div class="actions" fxLayout="column">
    <span *ngIf="!canSendInvites" class="dark-text">Select people from your company, or write a list of emails to send invitations to</span>
    <span *ngIf="canSendInvites" class="dark-text">
      <p>The recipients will receive an email with instructions</p>
    </span>
  </div>
  <br />
  <div fxLayout="column">
    <label>Select role: </label>
    <mat-radio-group
      [disabled]="!hasAdministratorPrivileges"
      [(ngModel)]="selectedRole"
      aria-label="Select role"
      fxLayoutGap="10px"
      matTooltip="Only project administrators and owners can specify rights for users"
      [matTooltipDisabled]="hasAdministratorPrivileges"
      (change)="updateSelectedRole()"
    >
      <mat-radio-button value="DEFAULT">Normal Rights</mat-radio-button>
      <mat-radio-button value="ADMINISTRATOR"> Admin Rights </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="project-invitation__btn-container">
  <button primary-button [type]="'outline'" mat-dialog-close>Close</button>
  <button primary-button *ngIf="canSendInvites" (click)="sendInvites()">{{ inviteButtonMessage }}</button>
</mat-dialog-actions>
