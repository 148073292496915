import { Component, Inject, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA } from "@angular/material/dialog"
import { Invitation, InvitationType } from "@models/common"

@Component({
  selector: "checkd-project-pending-invitation-removal-dialog",
  templateUrl: "./pending-invitation-removal-dialog.component.html",
  styleUrls: ["./pending-invitation-removal-dialog.component.scss"],
})
export class PendingInvitationRemovalDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { invitation: Invitation }) {}

  ngOnInit() {
    return
  }

  get target(): string {
    const invitation = this.data.invitation

    // @ts-ignore
    return invitation.deliveryMethod === "sms" ? invitation.targetPhoneNumber : invitation.targetEmail
  }

  get invitationTypeString() {
    switch (this.data.invitation.invitationType) {
      case InvitationType.TASK:
        return "task"
      case InvitationType.COMPANY:
      case InvitationType.ENTERPRISE_COMPANY:
        return "company"
      case InvitationType.PROJECT:
      case InvitationType.PROJECT_AND_TASK:
      case InvitationType.NONE:
      default:
        return "project"
    }
  }
}
