import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { ActivatedRoute } from "@angular/router"
import { COLLECTIONS, Person, PersonData } from "@models/common"
import type { IFormsLibraryMemberCompanyEmailInvitation } from "@models/common/forms-library-member-company-invitation.interface"
import { combineLatest, distinctUntilChanged, Observable, of as observableOf, startWith } from "rxjs"
import { map, shareReplay, switchMap, tap } from "rxjs/operators"
import { OneLoginService } from "../../../../services/one-login.service"

interface IInvitationData {
  isLoading: boolean
  data: null | {
    invitation: IFormsLibraryMemberCompanyEmailInvitation
    invitationUid: string
    // user document in the database if the user exists. If not, it means a new user was invited
    targetUser: Person | null
    isUserSignedIn: boolean
  }
}

@Component({
  selector: "checkd-forms-library-accept-invitation-view",
  templateUrl: "./forms-library-accept-invitation-view.component.html",
  styleUrls: ["./forms-library-accept-invitation-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormsLibraryAcceptInvitationViewComponent implements OnInit {
  private readonly route: ActivatedRoute = inject(ActivatedRoute)
  private readonly afs: AngularFirestore = inject(AngularFirestore)
  private readonly afAuth: AngularFireAuth = inject(AngularFireAuth)
  private readonly oneLoginService: OneLoginService = inject(OneLoginService)

  ngOnInit(): void {}

  // If invitationUid is missing, user will be redirected to the 404 page, so we only handle the case where invitationUid exists
  private readonly invitationSnap$ = this.route.params.pipe(
    map((params) => params["invitationUid"]),
    switchMap((invitationUid) =>
      this.afs.collection<IFormsLibraryMemberCompanyEmailInvitation>(COLLECTIONS.INVITATIONS).doc(invitationUid).snapshotChanges()
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  // Emits a Person object if the user can be found, otherwise emits null (i.e. user is not registered in our system)
  private readonly targetUser$: Observable<Person | null> = this.invitationSnap$.pipe(
    switchMap((inviteSnap) => {
      if (!inviteSnap.payload.exists) {
        return observableOf(null)
      }

      const email = inviteSnap.payload.get("targetEmail")
      if (!email) {
        return observableOf(null)
      }

      return observableOf(email.trim() as string)
    }),
    distinctUntilChanged(),
    switchMap((email) => {
      if (email) {
        return this.afs.collection<PersonData>(COLLECTIONS.USERS, (ref) => ref.where("email", "==", email).limit(1)).snapshotChanges()
      }

      return observableOf(null)
    }),
    map((query) => {
      if (!query || query.length < 1) {
        return null
      }

      const doc = query[0].payload.doc

      return new Person(doc.data(), doc.id, doc.ref)
    })
  )

  readonly invitationData$: Observable<IInvitationData> = combineLatest([this.invitationSnap$, this.targetUser$, this.afAuth.user]).pipe(
    tap(async ([invitationSnap, targetUser, afsUser]) => {
      // user is signed in, proceed to accept the invitation
      if (afsUser) return

      const targetEmail = invitationSnap.payload.get("targetEmail")
      const preFilledOneLoginRegistrationInfo = targetEmail ? { email: targetEmail } : {}
      const oneLoginResponse = await this.oneLoginService.createOneLoginInvite(preFilledOneLoginRegistrationInfo)
      this.oneLoginService.redirectToOneLoginWithInvite(invitationSnap.payload.id, oneLoginResponse)
    }),
    map(([snap, targetUser, afsUser]) => {
      if (snap.payload.exists) {
        return {
          isLoading: false,
          data: { invitation: snap.payload.data(), invitationUid: snap.payload.id, targetUser, isUserSignedIn: !!afsUser },
        }
      }

      return { isLoading: false, data: null }
    }),
    startWith({ isLoading: true, data: null }),
    shareReplay({ bufferSize: 1, refCount: true })
  )
}
