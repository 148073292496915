import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { MatDialog } from "@angular/material/dialog"
import { LegacyReportCreationDialogComponent } from "@checkd-form"
import { COLLECTIONS, Company, CompanyFeatures, LegacyReport, LegacyReportData, LegacyTemplate, Person, Project } from "@models/common"
import { GeneralReport } from "@models/common/general-report"
import { ITagCollectionData, TagCollection } from "@models/common/tag-collection"
import { ProjectService, RelationService, ReportService, ReportTimelineService, SnackbarService, UserService } from "@services"
import { TagCollectionService } from "@services/tag-collection.service"
import firebase from "firebase/compat/app"
import { Observable } from "rxjs"
import firestore = firebase.firestore
import { map, take } from "rxjs/operators"
import { DialogService } from "../../dialogs/dialog.service"

@Injectable({
  providedIn: "root",
})
export class ProjectReportsService {
  legacyReportCreationInProgress: boolean = false

  // @ts-ignore
  public reportTagCollection$: Observable<TagCollection> = this.projectService.currentProjectTagCollections$.pipe(
    map((collections) => collections.find((collection) => collection.name === "reports"))
  )

  constructor(
    private dialogRef: MatDialog,
    private db: AngularFirestore,
    private reportService: ReportService,
    private projectService: ProjectService,
    private tagCollectionService: TagCollectionService,
    private snackbarService: SnackbarService,
    private relationService: RelationService,
    private reportTimelineService: ReportTimelineService,
    private dialogService: DialogService,
    private userService: UserService
  ) {}

  async promptForAnonymousReport(legacyTemplate: LegacyTemplate, creator: Person) {
    if (!legacyTemplate.enableAnonymousReports) {
      return false
    }

    return this.dialogService.showAnonymousReportDialog(legacyTemplate, creator)
  }

  async newLegacyReport(
    creator: Person,
    userCompany: Company,
    projectCompany: Company,
    project: Project,
    projectTemplates: LegacyTemplate[],
    currentCompanyTemplates: LegacyTemplate[]
  ) {
    if (creator == null || project == null || userCompany == null) {
      this.snackbarService.showMessage("Unable to create report, missing information for creator/project/company")

      return null
    }

    const legacyTemplate = await this.dialogRef
      .open(LegacyReportCreationDialogComponent, {
        data: {
          project,
          userCompany,
          projectCompany,
          projectTemplates: projectTemplates || [],
          currentCompanyTemplates: currentCompanyTemplates || [],
        },
      })
      .afterClosed()
      .pipe(take(1))
      .toPromise()

    if (legacyTemplate == null || legacyTemplate.uid.trim() === "") {
      return null
    }

    try {
      this.legacyReportCreationInProgress = true

      // Result is either:
      // - true (fill out anonymously)
      // - false (fill out as user)
      // - undefined (cancel)
      const anonymousDialogResult = await this.promptForAnonymousReport(legacyTemplate, creator)

      // If the user clicks outside of the dialog, cancel the whole flow
      if (anonymousDialogResult == null) {
        return undefined
      }

      this.snackbarService.showMessage(`Creating new report...`)
      const report = await this.reportService.createLegacyReportFromTemplate(
        legacyTemplate,
        project,
        creator,
        userCompany,
        anonymousDialogResult
      )
      this.snackbarService.showMessage(`New report ${name} created!`)

      return report
    } catch (err) {
      this.snackbarService.showMessage(err.message)

      return null
    } finally {
      this.legacyReportCreationInProgress = false
    }
  }

  // OPEN / CLOSED
  async setReportStatus(report: GeneralReport, user: Person, reportStatus: string) {
    try {
      this.snackbarService.showMessage(`Report ${report.name} is now set as ${reportStatus}`)
    } catch (err) {
      this.snackbarService.showMessage(err.message)
    }
  }

  async removeReport(report: GeneralReport, user: Person) {
    try {
      await report.disable()
      this.snackbarService.showMessage(`Report ${report.name} is now removed!`)
    } catch (err) {
      this.snackbarService.showMessage(err.message)
    }
  }

  async lockReport(report: GeneralReport, user: Person) {
    try {
      const batch = this.db.firestore.batch()
      report.batchUpdate(batch, { readOnly: true })
      this.reportTimelineService.batchReadOnlyChanged(batch, user, report, true)
      await batch.commit()
      this.snackbarService.showMessage(`Report ${report.name} is now locked!`)
    } catch (err) {
      this.snackbarService.showMessage(err.message)
    }
  }

  async unlockReport(report: GeneralReport, user: Person) {
    try {
      const batch = this.db.firestore.batch()
      report.batchUpdate(batch, { readOnly: false })
      this.reportTimelineService.batchReadOnlyChanged(batch, user, report, false)
      await batch.commit()
      this.snackbarService.showMessage(`Report ${report.name} is now unlocked!`)
    } catch (err) {
      this.snackbarService.showMessage(err.message)
    }
  }

  async addReportTags(report: GeneralReport, user: Person, tagCollection: TagCollection, project: Project) {
    try {
      const previousTags = report.tags || []
      const newTags: string[] = await this.dialogService.showTagSelectionDialog(report, tagCollection)
      if (newTags != null) {
        const batch = this.db.firestore.batch()
        report.batchUpdate(batch, { tags: newTags })
        this.reportTimelineService.tagsChanged(batch, user, report, previousTags, newTags)
        if (newTags.length > 0) {
          this.addTagsToReportsTagCollection(batch, project, tagCollection, newTags)
        }
        await batch.commit()
        this.snackbarService.showMessage(`Tags updated for report ${report.name}!`)
      }
    } catch (err) {
      this.snackbarService.showMessage(err.message)
    }
  }

  /***
   * Opens a dialog for the user to choose amount of duplicates and sends a batch update to firebase to store copies of the report.
   * @param report
   * @param user
   * @param project
   */
  async duplicateReport(report: GeneralReport, user: Person, project: Project) {
    try {
      // Let user decide amount of duplicates it wants and name of the new copies
      const duplicateNames: string[] = await this.dialogService.showDuplicateDialog(report)
      if (!duplicateNames || duplicateNames.length === 0) {
        return
      }
      this.snackbarService.showMessage(
        `Duplicating ${report.name} ${duplicateNames.length} times. They will show up automatically as soon it's done.`,
        10000
      )

      // Listen to originalCreator and originalLegacyTemplate in an array
      const [originalReportCreator, originalLegacyTemplate] = await Promise.all([
        this.userService.listenToUid(report.creatorUid).pipe(take(1)).toPromise(),
        this.relationService.listenToFirstTarget(report, COLLECTIONS.LEGACY_TEMPLATES).pipe(take(1)).toPromise(),
      ])

      const batch = this.db.firestore.batch()
      // Go through all duplications and duplicate with their selected names
      duplicateNames.forEach((duplicateName) => {
        const duplicate = LegacyReport.batchCreate(batch, LegacyReport, {
          ...(report.data as LegacyReportData),
          name: duplicateName,
          // @ts-ignore TODO change interface
          createdAt: null,
          // @ts-ignore TODO change interface
          updatedAt: null,
          aggregateData: { creatorCompanyName: user.companyName, creatorName: user.name, creatorUid: user.uid },
        })

        // Duplicate timeline
        this.reportTimelineService.batchDuplicateReportTimeline(batch, user, duplicate, originalReportCreator!)

        // Create similar relations
        this.reportService.batchAddLegacyReportToProject(batch, duplicate, project)
        this.reportService.batchSetLegacyReportCreator(batch, duplicate, user)
        this.reportService.setLegacyTemplateFor(batch, duplicate, originalLegacyTemplate)
      })

      return batch.commit()
    } catch (e) {
      console.error(e)

      return e
    }
  }

  updateReportsTagCollection(project: Project, tagCollection: TagCollection, tags: string[]) {
    if (tagCollection) {
      return tagCollection.updateTags(tags)
    }
    const tagsCollectionData = {
      name: COLLECTIONS.REPORTS,
      tags,
    } as ITagCollectionData

    return this.tagCollectionService.addTagCollection(project, tagsCollectionData)
  }

  addTagsToReportsTagCollection(batch: firestore.WriteBatch, project: Project, tagCollection: TagCollection, tags: string[]) {
    if (tagCollection) {
      return tagCollection.addTagsBatch(batch, tags)
    }
    const tagsCollectionData = {
      name: COLLECTIONS.REPORTS,
      tags,
    } as ITagCollectionData

    return this.tagCollectionService.addTagCollectionBatch(batch, project, tagsCollectionData)
  }

  hasPermissionsToCreateForms(project: Project, company: Company) {
    return (
      (project.aggregateData.companyFeatures || []).indexOf(CompanyFeatures.PROJECT_TEMPLATES) > -1 ||
      (company.features || []).indexOf(CompanyFeatures.FORMS) > -1
    )
  }

  hasPermissionsToSeeProjectTemplates(project: Project, userCompany: Company, projectCompany: Company) {
    return (
      (project.aggregateData.companyFeatures || []).indexOf(CompanyFeatures.PROJECT_TEMPLATES) > -1 &&
      (project.hasPublicTemplates || userCompany.uid === projectCompany.uid)
    )
  }

  hasPermissionsToSeeCompanyTemplates(company: Company) {
    return (company.features || []).indexOf(CompanyFeatures.FORMS) > -1
  }
}
