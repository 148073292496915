import { Injectable, OnDestroy } from "@angular/core"
import { Title } from "@angular/platform-browser"
import { NavigationEnd, NavigationStart, Router } from "@angular/router"
import { BehaviorSubject, Observable, Subscription } from "rxjs"
import { distinctUntilChanged, filter, map, shareReplay } from "rxjs/operators"

const TITLES_AND_PATHS = [
  { title: "Items", paths: ["items"] },
  { title: "Forms Builder", paths: ["formsbuilder", "templatecreator"] },
  { title: "Profile", paths: ["profile"] },
  { title: "Page not Found", paths: ["404"] },
  { title: "Login", paths: ["login"] },
  { title: "Drawings", paths: ["Drawings"] },
  { title: "Reports", paths: ["fieldreports", "legacyreports", "reports"] },
  { title: "Projects", paths: ["", "projects"] },
  { title: "Invitations", paths: ["invitations"] },
  { title: "Company", paths: ["company"] },
  { title: "Registration", paths: ["registration", "register"] },
  { title: "Recover Password", paths: ["passwordrecovery"] },
  { title: "User Activation", paths: ["useractivation"] },
  { title: "Reset Password", paths: ["passwordreset"] },
  { title: "Email Verification", paths: ["emailverification"] },
]

@Injectable()
export class NavigationService implements OnDestroy {
  private topBar = new BehaviorSubject<boolean>(true)
  private sideNav = new BehaviorSubject<boolean>(true)

  private subscriptions = new Subscription()

  showTopbar$ = this.topBar.asObservable()
  showSideNav$ = this.sideNav.asObservable()

  private splitUrlSegments(url: string): string[] {
    return url.split("/").filter((segment) => segment.length > 0)
  }

  public pathComponents$: Observable<string[]> = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map((event: NavigationEnd) => event.url),
    distinctUntilChanged(),
    map((url) => this.splitUrlSegments(url)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  constructor(private router: Router, private titleService: Title) {
    this.subscriptions.add(
      this.router.events
        .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
        .subscribe((e: NavigationEnd) => this.setWebPageTitle(e.urlAfterRedirects))
    )

    this.subscriptions.add(
      this.router.events
        .pipe(filter((e): e is NavigationStart => e instanceof NavigationStart))
        .subscribe((e: NavigationStart) => this.listenToRouteChanges(e))
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  public hideTopAndSideBar() {
    this.topBar.next(false)
    this.sideNav.next(false)
  }

  private listenToRouteChanges(routeChange: NavigationStart) {
    const { url } = routeChange

    NavigationService.hideNavElement(
      [
        "/404",
        "404",
        "/login",
        "/drawings/",
        "/reports/",
        "registration",
        "/invitations/",
        "password",
        "emailverification",
        "authaction",
        "useractivation",
        "legacyReports",
        "fieldReports",
        "templateCreator",
        "formsBuilder",
        "/projects/new",
        "openTemplates",
        "openReports",
        "forms/builder",
        "/chat",
      ],
      url,
      this.sideNav
    )
    NavigationService.hideNavElement(
      ["fieldReports", "/drawings/", "/legacyReports/", "/404", "404", "/projects/new", "/login", "openTemplates", "openReports", "/chat"],
      url,
      this.topBar
    )
  }

  private setWebPageTitle(inputURL: string) {
    const path = inputURL
      .toLocaleLowerCase()
      .replace(/^\/+|\/+$/, "") // remove leading and trailing '/'
      .split("/")

    const [firstPathItem] = path

    let title = "Next Field Dashboard"

    // special case
    if (path.length === 1 && firstPathItem === "items") {
      title = "My Items"
    } else if (firstPathItem) {
      title = (TITLES_AND_PATHS.find((x) => x.paths.includes(firstPathItem)) || { title }).title
    }

    this.titleService.setTitle(title)
  }

  private static hideNavElement(targetUrls: string[], currentUrl: string, navElement: BehaviorSubject<boolean>) {
    if (NavigationService.findOccurrence(targetUrls, currentUrl)) {
      navElement.next(false)
    } else {
      navElement.next(true)
    }
  }

  private static findOccurrence(searchArray: string[], targetString: string): boolean {
    for (const searchStr of searchArray) {
      if (targetString.includes(searchStr)) {
        return true
      }
    }

    return false
  }

  goToProfilePage() {
    this.router.navigate(["/profile"])
  }

  goToLoginPage() {
    this.router.navigate(["/login"])
  }

  goToOpenReportReceiptPage(openReportUid: string) {
    return this.router.navigate([`/openReports/${openReportUid}/receipt`])
  }
}
