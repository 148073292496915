import { Component, OnInit } from "@angular/core"
import { DefaultElementComponent } from "../../default-element/default-element.component"

@Component({
  selector: "app-mainfield-element",
  templateUrl: "./mainfield-element.component.html",
  styleUrls: ["./mainfield-element.component.scss"],
})
export class MainfieldElementComponent extends DefaultElementComponent {
  override ngOnInit() {}
}
