import { Component, OnInit } from "@angular/core"
import { DefaultElementComponent } from "../../default-element/default-element.component"

@Component({
  selector: "app-gps-coordinate-element",
  templateUrl: "./gps-coordinate-element.component.html",
  styleUrls: ["./gps-coordinate-element.component.scss"],
})
export class GpsCoordinateElementComponent extends DefaultElementComponent {
  override ngOnInit() {}
}
