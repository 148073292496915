import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from "@angular/core"
import { BehaviorSubject, combineLatest } from "rxjs"
import { map } from "rxjs/operators"
import { IFieldChatProjectData, UserService } from "../../services"

@Component({
  selector: "field-chat-projects-list",
  templateUrl: "./projects-list.component.html",
  styleUrls: ["./projects-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsListComponent implements OnInit {
  private inputSearchText$: BehaviorSubject<string> = new BehaviorSubject("")
  isThereANotification = false
  isUnreadMention = "10"

  //Delete this when the logic is ready
  isActive = false

  @Output() onProjectSelected = new EventEmitter<IFieldChatProjectData>()

  constructor(private userService: UserService) {}

  ngOnInit(): void {}

  // TODO: double check that archived/disabled projects are disabled
  private readonly currentActiveProjects$ = this.userService.userProjects$

  readonly filteredProjects$ = combineLatest([this.currentActiveProjects$, this.inputSearchText$]).pipe(
    map(([projects, searchText]) =>
      projects.filter((project) => {
        return (project?.name ?? "").trim().toLowerCase().includes(searchText)
      })
    )
  )

  showNotification() {
    if (this.isThereANotification) {
      this.toggleUnreadMention()
    }
  }

  updateInputSearchText(text: string) {
    this.inputSearchText$.next(text.trim().toLowerCase())
  }

  toggleUnreadMention() {
    return this.isUnreadMention === ""
  }
}
