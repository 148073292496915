import { Component, OnInit, Output, EventEmitter, Input, ViewChild, OnChanges, SimpleChanges } from "@angular/core"
import { CheckdAcSearchComponent } from "@checkd-ui"
import { SearchFilterCollection, emptyAcSearchFilter } from "@services"
import { CheckdMultiselectComponent } from "../../checkd-ui/checkd-multiselect/checkd-multiselect.component"

export interface ItemFilterChangeData {
  searchValue?: string
  values?: any[]
}

export interface ItemFilterComponentConfig {}

const defaultItemFilterComponentConfig: ItemFilterComponentConfig = {}

@Component({
  selector: "checkd-item-filter",
  templateUrl: "./item-filter.component.html",
  styleUrls: ["./item-filter.component.scss"],
})
export class ItemFilterComponent implements OnChanges {
  @Output() acFilterChange = new EventEmitter()
  @Input() numbers: number[] = []
  @Input() titles: string[] = []
  @Input() tags: string[] = []
  @Input() creatorNames: string[] = []
  @Input() assignerNames: string[] = []
  @Input() assigneeNames: string[] = []
  @Input() assignerCompanyNames: string[] = []
  @Input() drawingNames: string[] = []
  @Input() projectNames: string[] = []
  @Input() config: ItemFilterComponentConfig = defaultItemFilterComponentConfig

  @ViewChild("numberFilter", { static: true }) numberFilter: CheckdMultiselectComponent
  @ViewChild("titleFilter", { static: true }) titleFilter: CheckdAcSearchComponent
  @ViewChild("tagsFilter", { static: true }) tagsFilter: CheckdMultiselectComponent
  @ViewChild("creatorFilter", { static: true }) creatorFilter: CheckdMultiselectComponent
  @ViewChild("assignerFilter", { static: true }) assignerFilter: CheckdMultiselectComponent
  @ViewChild("assignerCompanyFilter", { static: true }) assignerCompanyFilter: CheckdMultiselectComponent
  @ViewChild("drawingFilter", { static: true }) drawingFilter: CheckdMultiselectComponent
  @ViewChild("projectFilter", { static: true }) projectFilter: CheckdMultiselectComponent
  @ViewChild("assigneeFilter", { static: true }) assigneeFilter: CheckdMultiselectComponent

  public stringNumbers: string[] = []
  public acSearchFilter: SearchFilterCollection = Object.assign({}, emptyAcSearchFilter)

  ngOnChanges(changes: SimpleChanges) {
    this.stringNumbers = this.numbers.filter((it) => it).map((n) => n.toString())
    this.tags = Array.from(new Set(this.tags.filter((it) => it).map((tag) => tag.toUpperCase())))
  }

  onAcFilterChange(filterName: string, data: ItemFilterChangeData) {
    this.acSearchFilter = Object.assign({}, this.acSearchFilter, {
      [filterName]: data.searchValue != null ? data.searchValue : data.values,
    })
    this.acSearchFilter.number = this.acSearchFilter.number.map((n) => Number(n))
    this.acFilterChange.emit(this.acSearchFilter)
  }

  public resetFilters() {
    this.numberFilter.clear()
    this.titleFilter.clear()
    this.tagsFilter.clear()
    this.creatorFilter.clear()
    this.assignerFilter.clear()
    this.assignerCompanyFilter.clear()
    this.drawingFilter.clear()
    this.projectFilter.clear()
    this.assigneeFilter.clear()
    this.acSearchFilter = Object.assign({}, emptyAcSearchFilter)
    this.acFilterChange.emit(this.acSearchFilter)
  }

  public updateFilter(filterName: string, data: any) {
    this.acSearchFilter = Object.assign({}, this.acSearchFilter, { [filterName]: data })
  }
}
