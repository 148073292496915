import { DOCUMENT } from "@angular/common"
import { Inject, Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { Form } from "@checkd-form/models/form"

import {
  COLLECTIONS,
  Company,
  Item,
  LABELS,
  LegacyReport,
  LegacyReportAggregateData,
  LegacyReportData,
  LegacyTemplate,
  LegacyTemplateMenuActions,
  Person,
  Project,
  Relation,
  Report,
  ReportGenerationSettings,
  ReportMenuActions,
  Role,
  RoleType,
} from "@models/common"
import { createLegacyTemplateMenuActionData } from "@models/common/actions/legacy-template-menu-actions"
import { createReportMenuActionData } from "@models/common/actions/report-menu-actions"
import { GeneralReport } from "@models/common/general-report"
import { ReportStatus } from "@models/common/workflow.interface"
// Importing directly (ignoring barrel) to prevent runtime cyclic dependencies
import { RelationService } from "@services/relation.service"
import firebase from "firebase/compat/app"
import { Observable } from "rxjs"
import { OpenReport } from "../reports/models/open-report"
import { ModelService } from "./model.service"
import firestore = firebase.firestore
import { TimelineService } from "@services/timeline.service"

@Injectable()
export class ReportService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private db: AngularFirestore,
    private modelService: ModelService,
    private relationService: RelationService,
    private timelineService: TimelineService
  ) {}

  listenToUid(reportUid: string): Observable<Report> {
    return this.modelService.listenTo(COLLECTIONS.REPORTS, reportUid) as Observable<Report>
  }

  listenToCreator(report: GeneralReport): Observable<Person> {
    return this.relationService.listenToFirstTarget(report, COLLECTIONS.PEOPLE, [LABELS.CREATED_BY])
  }

  createReportSharingLink(reportUid: string, reportType: string) {
    const protocol = this.document.location.protocol
    const domain: string = this.document.location.hostname
    const port = domain.includes("localhost") ? `:${this.document.location.port}` : ""

    let reportRouter = ""
    switch (reportType) {
      case "FIELD":
        reportRouter = "fieldReports"
        break
      default:
        reportRouter = "legacyReports"
        break
    }

    return `${protocol}//${domain}${port}/${reportRouter}/${reportUid}`
  }

  getDefaultReportSettings(): ReportGenerationSettings {
    return {
      projectName: true,
      drawing: true,
      itemTitle: true,
      itemDescription: true,
      itemTags: true,
      taskStatus: true,
      creator: true,
      assignee: true,
      collaborators: true,
      createdDate: true,
      modifiedDate: true,
      company: true,
      dueDate: true,
    } as ReportGenerationSettings
  }

  disableReport(report: Report) {
    return report.disable()
  }

  async createLegacyReportFromTemplate(
    template: LegacyTemplate,
    project: Project,
    creator: Person,
    creatorCompany: Company,
    isAnonymous = false
  ): Promise<LegacyReport> {
    const legacyReportData = this.legacyReportDataFromTemplate(template, isAnonymous)

    const aggregateData = {
      aggregateData: {
        creatorUid: isAnonymous ? "" : creator.uid || null,
        creatorName: isAnonymous ? "" : creator.name || null,
        creatorCompanyName: isAnonymous ? "" : creatorCompany.name || null,
        projectName: project.name || null,
        projectUid: project.uid || null,
        legacyTemplateUid: template.uid || null,
        legacyTemplateName: template.name || null,
      } as LegacyReportAggregateData,
    }

    const batch = this.db.firestore.batch()
    const report = LegacyReport.batchCreate(batch, LegacyReport, { ...legacyReportData, ...aggregateData })

    if (report == null) {
      throw new Error(`Unable to create report from template ${template.uid}`)
    }

    this.batchAddLegacyReportToProject(batch, report, project)

    if (!isAnonymous) {
      this.batchSetLegacyReportCreator(batch, report, creator)
    }

    this.setLegacyTemplateFor(batch, report, template)
    await batch.commit()

    return report
  }

  async createOpenReportFromTemplate(reportTitle: string, template: LegacyTemplate, projectUid: string): Promise<OpenReport> {
    const legacyReportData = this.legacyReportDataFromTemplate(template)
    legacyReportData.name = reportTitle

    const aggregateData = {
      aggregateData: {
        projectUid: projectUid,
        legacyTemplateUid: template.uid || null,
        legacyTemplateName: template.name || null,
      } as LegacyReportAggregateData,
    }

    const batch = this.db.firestore.batch()
    const report = OpenReport.batchCreate(batch, OpenReport, { ...legacyReportData, ...aggregateData })

    if (report == null) {
      throw `Unable to create report from template ${template.uid}`
    }

    await batch.commit()

    return report
  }

  legacyReportDataFromTemplate(template: LegacyTemplate, isAnonymous = false): LegacyReportData {
    return {
      headerTemplateData: JSON.stringify(template.headerTemplate),
      detailTemplateData: JSON.stringify(template.detailTemplate),
      name: template.name,
      templatePublicVersion: template.publicVersion || null,
      templateInternalVersion: template.internalVersion || null,
      status: "",
      description: "",
      logo: template.logo || null,
      pdfFooterText: template.pdfFooterText,
      footerLogo: template.footerLogo,
      tags: template.tags,
      isAnonymous,
    } as LegacyReportData
  }

  batchAddLegacyReportToProject(batch: firestore.WriteBatch, legacyReport: LegacyReport, project: Project): LegacyReport {
    legacyReport.batchAdd(batch, project, [Relation.LABELS.CONTAINED_BY])
    project.batchAdd(batch, legacyReport, [Relation.LABELS.CONTAINS])
    return legacyReport
  }

  batchSetLegacyReportCreator(batch: firestore.WriteBatch, legacyReport: LegacyReport, person: Person): LegacyReport {
    legacyReport.batchAdd(batch, person, [Relation.LABELS.CREATED_BY])
    person.batchAdd(batch, legacyReport, [Relation.LABELS.CREATOR])
    return legacyReport
  }

  /**
   * Creates relation between template and report
   * @param batch
   * @param legacyReport
   * @param template
   */
  setLegacyTemplateFor(batch: firestore.WriteBatch, legacyReport: LegacyReport, template: LegacyTemplate) {
    legacyReport.batchAdd(batch, template, [LABELS.HAS_TEMPLATE])
    template.batchAdd(batch, legacyReport, [LABELS.IS_TEMPLATE_FOR])
    return legacyReport
  }

  isCreator(report: GeneralReport, user: Person) {
    if (report == null || report.aggregateData["creatorUid"] == null || user == null) {
      return false
    }

    return report.aggregateData["creatorUid"] === user.uid
  }

  canDeleteTargetDocuments(userProjectRole: Role, report: GeneralReport) {
    return userProjectRole != null && userProjectRole.canDeleteTargetDocuments(report.collectionName)
  }

  canUpdateTargetDocuments(userProjectRole: Role, report: GeneralReport) {
    return userProjectRole != null && userProjectRole.canUpdateTargetDocuments(report.collectionName)
  }

  // Add tags
  getMenuOptions(report: GeneralReport, userProjectRole: Role, user: Person) {
    const menuOptions = []
    // TODO: restrict to project admin, owner and creator
    if (!report.readOnly && (this.isCreator(report, user) || this.canUpdateTargetDocuments(userProjectRole, report))) {
      menuOptions.push(createReportMenuActionData(report, ReportMenuActions.ADD_TAGS))
    }
    // Duplicate reports
    if (!report.readOnly && (this.isCreator(report, user) || this.canUpdateTargetDocuments(userProjectRole, report))) {
      // Only able to duplicate legacy reports
      if (report.reportType.includes("LEGACY")) {
        // Only allowed if report doesn't have items connected
        if (!report.aggregateData["itemsCount"]) {
          menuOptions.push(createReportMenuActionData(report, ReportMenuActions.DUPLICATE_REPORT))
        }
      }
    }

    // Lock Report
    if (!report.readOnly && (this.isCreator(report, user) || this.canUpdateTargetDocuments(userProjectRole, report))) {
      menuOptions.push(createReportMenuActionData(report, ReportMenuActions.LOCK_REPORT))
    }

    // Unlock Report
    if (report.readOnly && (this.isCreator(report, user) || this.canUpdateTargetDocuments(userProjectRole, report))) {
      menuOptions.push(createReportMenuActionData(report, ReportMenuActions.UNLOCK_REPORT))
    }

    // Remove Report
    if (
      !report.ref!.path.includes("legacyTemplates") && // <-- quick hack to remove this option for the template list
      (this.isCreator(report, user) ||
        this.canDeleteTargetDocuments(userProjectRole, report) ||
        [RoleType.PROJECT_OWNER, RoleType.PROJECT_ADMINISTRATOR].includes(userProjectRole.roleType))
    ) {
      menuOptions.push(createReportMenuActionData(report, ReportMenuActions.REMOVE_REPORT))
    }

    // QR code and URL options
    if (report.collectionName === COLLECTIONS.LEGACY_TEMPLATES) {
      menuOptions.push(
        createLegacyTemplateMenuActionData(report as unknown as LegacyTemplate, LegacyTemplateMenuActions.GO_TO_QR_CODE_VIEW)
      )
      menuOptions.push(
        createLegacyTemplateMenuActionData(report as unknown as LegacyTemplate, LegacyTemplateMenuActions.GET_URL_IN_CLIPBOARD)
      )
    }

    return menuOptions
  }

  listenToProject(report: GeneralReport) {
    return this.relationService.listenToFirstTarget(report, COLLECTIONS.PROJECTS)
  }

  update(report: GeneralReport, form: Form) {
    return report.update({
      headerTemplateData: JSON.stringify(form.header.transformAttributes().values),
      detailTemplateData: JSON.stringify(form.details.transformAttributes().values),
      noOfElements: form.noOfElements,
      noOfFilledElements: form.noOfFilledElements,
    })
  }

  async attachItemsToReport(selectedItems: Item[], selectedReport: LegacyReport, personProjectRole: Role, user: Person) {
    const allowedRoleTypes = [RoleType.PROJECT_OWNER, RoleType.PROJECT_ADMINISTRATOR]

    if (!allowedRoleTypes.includes(personProjectRole.roleType)) {
      throw new Error(`Insufficient permissions for attaching items to a report. You are not allowed to perform this action.`)
    }

    return Promise.all(
      selectedItems.map(async (item) => {
        const batch = this.db.firestore.batch()
        // @ts-ignore
        selectedReport.batchAdd(batch, item, [LABELS.CONTAINS])
        item.batchAdd(batch, selectedReport, [LABELS.CONTAINED_BY])
        item.batchUpdate(batch, {
          aggregateData: {
            ...item.aggregateData,
            legacyReportName: selectedReport.name,
            legacyReportUid: selectedReport.uid,
          },
        })
        this.timelineService.batchItemConnectedToReport(batch, user, item, selectedReport)
        await batch.commit()
      })
    )
  }
}
