<div class="email-invitation" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center">
  <div fxLayoutAlign="row center" fxLayoutGap="10px">
    <span class="email-icon"><mat-icon svgIcon="email"></mat-icon></span>
    <mat-form-field>
      <input type="email"
        email="true"
        [formControl]="invitationEmail"
        matInput
        placeholder="Write in the e-mail"
        (keyup.enter)="addEmail($event)">
    </mat-form-field>
    <checkd-button (click)="addInvitationEmail()" *ngIf="isValidInvitationEmail"  bg="blue" fg="white">Add</checkd-button>
  </div>

  <div class="email-list">
    <people-list-item *ngFor="let email of emails" [email]="email.email">
      <span fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field>
          <mat-select value="DEFAULT" (selectionChange)="updateInvitedEmail(email.email, $event.value)">
            <mat-option value="DEFAULT">Normal rights</mat-option>
            <mat-option value="ADMINISTRATOR">Admin rights</mat-option>
          </mat-select>
        </mat-form-field>
        <checkd-button bg="white" fg="blue" (click)="removeEmail(email.email)">Remove</checkd-button>
      </span>
    </people-list-item>
    <!-- TODO -->
  </div>

  <div class="actions" fxLayout="row" fxLayoutAlign="end">
    <checkd-button *ngIf="shouldShowInviteButton" bg="blue" fg="white" (click)="sendInvites()">Send invites</checkd-button>
  </div>

</div>
