<project-creation
  *ngIf="currentCompany"
  (projectDataChange)="onProjectDataChange($event)"
  (selectedTemplatesChange)="onSelectedTemplatesChange($event)"
  (reportTagsChange)="onReportTagsChange($event)"
  [companyFeatures]="currentCompany?.features"
  [canCreateProject]="canCreateProject"
  [isCreatingProject]="isCreatingProject"
  (createProjectButtonEventEmitter)="createProject()"
  [companyTags]="currentCompany?.tags"
  [companyTemplates]="companyTemplates$ | async">
</project-creation>
