export interface FilestackUploadData {
  filename: string
  handle: string
  mimetype: string
  originalFile: any
  originalPath: string
  size: number
  source: string
  status: string
  uploadId: string
  url: string
}

export interface DocDimensions {
  width: number
  height: number
}

export interface DocInfo {
  dimensions: DocDimensions
}

export interface PdfDocInfo extends DocInfo {
  numpages: number
}

export interface FilestackUploadResult {
  filesFailed: any[] | null
  filesUploaded: FilestackUploadData[]
}

export interface FilestackMetadataOptions {
  size?: boolean
  mimetype?: boolean
  filename?: boolean
  width?: boolean
  height?: boolean
  uploaded?: boolean
  writeable?: boolean
  cloud?: boolean
  source_url?: boolean
}
