<div class="tw-flex tw-justify-between tw-items-center">
  <div class="tw-flex tw-items-center tw-gap-4">
    <div class="tw-grid tw-place-items-center tw-aspect-square">
      <ngx-avatars [name]="name" [src]="image" size="40" class="member-card__profile-img"></ngx-avatars>
    </div>

    <div>
      <h3 class="tw-font-bold">{{ name }}</h3>
    </div>
  </div>
  <button
    *ngIf="currentUserCanRemoveThisUser$ | async"
    (click)="onRemoveMemberClicked()"
    class="hover:tw-scale-105 tw-cursor-pointer tw-grid tw-place-items-center focus-visible:tw-outline-next-navy-500 tw-flex-none"
  >
    <field-chat-lib-close-chat-room-icon></field-chat-lib-close-chat-room-icon>
  </button>
</div>
