import { inject } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router"
import { map } from "rxjs/operators"

import { FirebaseAuthService } from "@services"

export const authGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(FirebaseAuthService)
  const router = inject(Router)

  return authService.isLoggedIn().pipe(
    map((isLoggedIn) => {
      if (!isLoggedIn) {
        return router.parseUrl("/login")
      }

      return true
    })
  )
}
