import { COLLECTIONS } from "../collections.interface"
import { LABELS } from "../relation.interface"
import { Role } from "./role"
import { RoleType } from "./roles-and-permissions.interface"

export class ReportCreatorRole extends Role {
  setupRoleData() {
    this.roleData = {
      name: "Report creator",
      description: "The person who created a report",
      roleType: RoleType.REPORT_CREATOR,
      requirements: {
        sourceCollection: COLLECTIONS.PEOPLE,
        labels: [LABELS.CREATOR],
        targetCollection: COLLECTIONS.REPORTS,
      },

      targetPermissions: { create: true, read: true, update: true, delete: true },

      permissions: [
        {
          description: "Can do everything with report items.",
          collection: COLLECTIONS.ITEMS,
          documentPermissions: { create: true, read: true, update: true, delete: true },
          relationPermissions: { create: true, read: true, update: true, delete: true },
        },
      ],
      inferredRoleTypes: {},
    }
  }
}

export class LegacyReportCreatorRole extends Role {
  setupRoleData() {
    this.roleData = {
      name: "Report creator",
      description: "The person who created a report",
      roleType: RoleType.LEGACY_REPORT_CREATOR,
      requirements: {
        sourceCollection: COLLECTIONS.PEOPLE,
        labels: [LABELS.CREATOR],
        targetCollection: COLLECTIONS.LEGACY_REPORTS,
      },

      targetPermissions: { create: true, read: true, update: true, delete: true },

      permissions: [
        {
          description: "Can do everything with report items.",
          collection: COLLECTIONS.ITEMS,
          documentPermissions: { create: true, read: true, update: true, delete: true },
          relationPermissions: { create: true, read: true, update: true, delete: true },
        },
      ],
      inferredRoleTypes: {},
    }
  }
}
