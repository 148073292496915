<span *ngIf="hasOptions">
  <button mat-button class="right-actions-button" [matMenuTriggerFor]="rightActionsMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #rightActionsMenu="matMenu" overlapTrigger="false" xPosition="after">
    <button
      *ngFor="let option of menuOptions"
      [ngStyle]="{ 'color': option.color }"
      (click)="menuClicked(option.action, option.confirmationDialog)"
      mat-menu-item>{{option.name}}</button>
  </mat-menu>
</span>
