<div class="section-template__header">
  <h2 class="section-template__title">{{ sectionTitle }}</h2>
  <button
    pButton
    pRipple
    type="button"
    label="Browse all templates"
    icon="pi pi-arrow-right"
    iconPos="right"
    (click)="onBrowseAllTemplatesClicked()"
    class="section-template__btn p-button-outlined"
  ></button>
</div>
<div class="section-template__cards-container">
  <ng-content></ng-content>
</div>
