import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core"

@Component({
  selector: "checkd-item-list-paginator",
  templateUrl: "./item-list-paginator.component.html",
  styleUrls: ["./item-list-paginator.component.scss"],
})
export class ItemListPaginatorComponent implements OnInit {
  @Input() maxPageLength: number = 1
  @Input() pageSizes: number[] = [10, 20, 30]

  @Input()
  public get pageSize() {
    return this._pageSize || 10
  }
  _pageSize: number = this.pageSizes[0] || 10

  @Output() pageSizeChange = new EventEmitter()
  public set pageSize(n: number) {
    this._pageSize = n
    this.pageSizeChange.emit(this._pageSize)
  }

  @Input()
  public get pageNumber() {
    return Math.min(this._pageNumber, this.maxPageLength)
  }
  _pageNumber: number = 1

  @Output() pageNumberChange = new EventEmitter()
  public set pageNumber(n: number) {
    this._pageNumber = Math.min(n, this.maxPageLength)
    this.pageNumberChange.emit(this._pageNumber)
  }

  public get previousPageButtonDisabled() {
    return this.pageNumber < 2
  }
  public get nextPageButtonDisabled() {
    return this.pageNumber >= this.maxPageLength
  }

  constructor() {}

  ngOnInit() {}

  previousPage() {
    this.pageNumber = this.pageNumber - 1
  }
  nextPage() {
    this.pageNumber = this.pageNumber + 1
  }
}
