<div class="project-creation-container" fxLayout="column">
  <div class="overlay" *ngIf="isCreatingProject" fxLayout fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>

  <mat-toolbar fxFlex="0 0 auto">
    <div fxFlex="0 0 50px">
      <button mat-icon-button (click)="location.back()">
        <mat-icon>navigate_before</mat-icon>
      </button>
    </div>

    <div class="title" [ngClass.gt-xs]="'larger-title'" fxFlex="1 1 0">
      {{ projectNameField.value.trim() ? 'Project "' + projectNameField.value.trim() + '"' : "Create a New Project" }}
    </div>

    <div fxFlex="0 0 50px"></div>
  </mat-toolbar>

  <main>
    <mat-card [ngClass.xs]="'small-mat-card'" [ngClass.gt-xs]="'big-mat-card'">
      <mat-card-title>Main Project Details</mat-card-title>
      <form [formGroup]="mainProjectInfoForm" fxLayout="column">
        <mat-form-field id="intercom-tour-project-name">
          <mat-label>Project Name</mat-label>
          <input #projectNameField matInput formControlName="name" required />
          <mat-error *ngIf="nameField!.invalid">Project name is required</mat-error>
        </mat-form-field>

        <mat-form-field id="intercom-tour-project-address">
          <input matInput formControlName="address" />
          <mat-label>Address</mat-label>
        </mat-form-field>

        <mat-form-field id="intercom-tour-project-description">
          <textarea matInput formControlName="description"></textarea>
          <mat-label>Description</mat-label>
        </mat-form-field>
      </form>
    </mat-card>

    <mat-card [ngClass.xs]="'small-mat-card'" [ngClass.gt-xs]="'big-mat-card'">
      <mat-card-title id="intercom-tour-tags-section">Tags</mat-card-title>

      <mat-accordion>
        <mat-expansion-panel id="intercom-tour-project-item-tags">
          <mat-expansion-panel-header> <mat-icon svgIcon="tag" class="tag-icon"></mat-icon>Project Item Tags </mat-expansion-panel-header>

          <mat-icon class="tags-info-button" (click)="openProjectItemTagsInfoDialog()">info</mat-icon>

          <checkd-tags [tags]="projectTags" [areTagsEditable]="true" (onTagsUpdated)="onItemTagsUpdated($event)" placeholder="Item tags">
          </checkd-tags>
        </mat-expansion-panel>

        <mat-expansion-panel id="intercom-tour-project-report-tags">
          <mat-expansion-panel-header> <mat-icon svgIcon="tag" class="tag-icon"></mat-icon>Project Report Tags </mat-expansion-panel-header>

          <mat-icon class="tags-info-button" (click)="openProjectReportTagsInfoDialog()">info</mat-icon>

          <checkd-tags [areTagsEditable]="true" (onTagsUpdated)="onReportTagsUpdated($event)" placeholder="Report tags"> </checkd-tags>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>

    <mat-card *ngIf="canAddProjectTemplates" [ngClass.xs]="'small-mat-card'" [ngClass.gt-xs]="'big-mat-card'">
      <mat-card-title id="intercom-tour-project-routines-and-templates-section">Project Routines and Templates</mat-card-title>
      <mat-expansion-panel id="intercom-tour-project-templates">
        <mat-expansion-panel-header>
          <mat-icon svgIcon="forms-report" class="forms-report-icon"></mat-icon>Project Templates
        </mat-expansion-panel-header>

        <checkd-project-template-list
          [companyTemplates]="companyTemplates"
          (selectedTemplates)="selectedTemplatesChange.emit($event)"
          (hasPublicTemplates)="onHasPublicTemplatesChange($event)"
        >
        </checkd-project-template-list>
      </mat-expansion-panel>
    </mat-card>

    <mat-card [ngClass.xs]="'small-mat-card'" [ngClass.gt-xs]="'big-mat-card'">
      <mat-card-title>Extra Details</mat-card-title>

      <mat-expansion-panel id="intercom-tour-extra-project-details">
        <mat-expansion-panel-header>Optional Project Details </mat-expansion-panel-header>
        <form [formGroup]="projectDetails" fxLayout="column" novalidate>
          <mat-form-field id="intercom-tour-municipal-number">
            <input
              formControlName="municipalNumber"
              matInput
              matTooltip="Here you can add the municipal number where your project is located. / Her fyller du inn kommunenummeret hvor prosjektet skal utføres."
            />
            <mat-label>Municipal number/Kommunenummer</mat-label>
          </mat-form-field>

          <mat-form-field id="intercom-tour-cadestral-number">
            <input
              formControlName="cadastrialUnitNumber"
              matInput
              matTooltip="Here you can add the cadastral unit number where your project is located. / Her fyller du inn gårdsnummeret hvor prosjektet skal utføres."
            />
            <mat-label>Cadastral unit number/Gårdsnummer</mat-label>
          </mat-form-field>

          <mat-form-field id="intercom-tour-id-number">
            <input
              formControlName="idNumber"
              matInput
              matTooltip="If your project has a specific ID number, you can fill it in here. / Her kan du legge til prosjektets ID-nummer."
            />
            <mat-label>ID number/ID-nummer</mat-label>
          </mat-form-field>

          <mat-form-field id="intercom-tour-project-phase">
            <input
              formControlName="projectPhase"
              matInput
              matTooltip="Here you can describe which phase your project is in. / Her kan du beskrive hvilken fase, eller faser, prosjektet er i."
            />
            <mat-label>Project Phase/Prosjektfase</mat-label>
          </mat-form-field>

          <mat-form-field id="intercom-tour-project-owner">
            <input
              formControlName="owner"
              matInput
              matTooltip="Here you can define who owns the project. This is typically a main entrepreneur, a building client etc. / Her kan du definere prosjektets eier. Typisk er dette en byggherre, hovedentreprenør e.l."
            />
            <mat-label>Project Owner/Prosjekteier</mat-label>
          </mat-form-field>

          <mat-form-field id="intercom-tour-project-client">
            <input
              formControlName="client"
              matInput
              matTooltip="Here you can define who’s the project client. This is typically a main entrepreneur, a building client, customer etc. / Her kan du definere hvem som bestiller prosjektet, eller hvem som skal overta det. Dette kan være byggherre, hovedentreprenør, kunde som tar over bolig e.l."
            />
            <mat-label>Client/Klient</mat-label>
          </mat-form-field>

          <mat-form-field id="intercom-tour-project-cost">
            <input
              formControlName="cost"
              matInput
              matTooltip="Here you fill in the cost related to the project. You can edit this at any time. / Her fyller du inn estimert kostnad for prosjektet. Du kan revidere dette feltet senere ved behov."
            />
            <mat-label>Cost/Kostnad</mat-label>
          </mat-form-field>
        </form>
      </mat-expansion-panel>
    </mat-card>
    <!--
    <mat-card [ngClass.xs]="'small-mat-card'" [ngClass.gt-xs]="'big-mat-card'">

      <p>Forms created with Next Field can be exported to this project</p>
      <mat-select placeholder="Nothing selected" [(value)]="nextProjectProjectIdToConnect" (selectionChange)="emitProjectData()">
        <mat-option *ngFor="let project of availableNextProjectProjects$ | async" [value]="project.id">{{ project.name }} </mat-option>
      </mat-select>
    </mat-card>
-->
  </main>
  <footer>
    <button
      primary-button
      [isRounded]="true"
      (click)="createProjectButtonEventEmitter.emit()"
      [disabled]="!this.canCreateProject || this.isCreatingProject || nameField!.invalid"
      id="{{ createButtonIdText }}"
    >
      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3910_12557)">
          <path
            d="M24 11.9994C24 12.7667 23.3827 13.384 22.6154 13.384H13.3846V22.6148C13.3846 23.3792 12.7644 24 12 24C11.2356 24 10.6154 23.3821 10.6154 22.6148V13.384H1.38462C0.620192 13.384 0 12.7644 0 12C0 11.2379 0.620192 10.6148 1.38462 10.6148H10.6154V1.38404C10.6154 0.619615 11.2356 0 12 0C12.7644 0 13.3846 0.619615 13.3846 1.38404V10.6148H22.6154C23.3827 10.6148 24 11.2379 24 11.9994Z"
            fill="currentcolor"
          />
        </g>
        <defs>
          <clipPath id="clip0_3910_12557">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <span> Create </span>
    </button>
  </footer>
</div>
