import { Injectable } from "@angular/core"

// @ts-ignore
import * as pdfMake from "@checkdas/pdfmake-field/build/pdfmake"

// import * as pdfFonts from 'pdfmake/build/vfs_fonts'
// import * as pdfMakeUnicode from 'pdfmake-unicode'
// pdfMake.vfs = pdfMakeUnicode.pdfMake.vfs
// pdfMake.vfs = pdfFonts.pdfMake.vfs
// @ts-ignore
import * as customFonts from "../../../assets/vfs_fonts"

// @ts-ignore
pdfMake.vfs = customFonts.pdfMake.vfs

const fonts = {
  Lato: {
    normal:
      "https://firebasestorage.googleapis.com/v0/b/checkdstageu.appspot.com/o/fonts%2FLato-Regular.ttf?alt=media&token=15403a07-80e8-4371-b298-44ae993f7579",
    bold: "https://firebasestorage.googleapis.com/v0/b/checkdstageu.appspot.com/o/fonts%2FLato-Bold.ttf?alt=media&token=15a2bdc0-f182-4b65-8342-b9aaea1bd667",
    italics:
      "https://firebasestorage.googleapis.com/v0/b/checkdstageu.appspot.com/o/fonts%2FLato-Italic.ttf?alt=media&token=dd4363ee-448d-4527-b339-0f74c893068d",
    bolditalics:
      "https://firebasestorage.googleapis.com/v0/b/checkdstageu.appspot.com/o/fonts%2FLato-BoldItalic.ttf?alt=media&token=35acaa7b-007b-47e4-b5cb-90cca11ff6f3",
  },
  Fontello: {
    normal:
      "https://firebasestorage.googleapis.com/v0/b/checkdstageu.appspot.com/o/fonts%2Ffontello.ttf?alt=media&token=ff480d49-233b-4baa-b025-77671784ae46",
    bold: "https://firebasestorage.googleapis.com/v0/b/checkdstageu.appspot.com/o/fonts%2Ffontello.ttf?alt=media&token=ff480d49-233b-4baa-b025-77671784ae46",
    italics:
      "https://firebasestorage.googleapis.com/v0/b/checkdstageu.appspot.com/o/fonts%2Ffontello.ttf?alt=media&token=ff480d49-233b-4baa-b025-77671784ae46",
    bolditalics:
      "https://firebasestorage.googleapis.com/v0/b/checkdstageu.appspot.com/o/fonts%2Ffontello.ttf?alt=media&token=ff480d49-233b-4baa-b025-77671784ae46",
  },
}
// @ts-ignore
pdfMake.fonts = fonts
pdfMake.addFonts(fonts)

import { reportTemplate } from "../_templates"
import { Form } from "../checkd-form/models/form"

@Injectable()
export class PdfDataService {
  constructor() {}

  generateReport(docDefinition: any) {
    // return pdfMake.createPdf(docDefinition, null, fonts)
    return pdfMake.createPdf(docDefinition)
  }
}
