import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core"
import { ButtonModule } from "../next-ui/button/button.module"

@Component({
  selector: "app-settings-group",
  templateUrl: "./settings-group.component.html",
  styleUrls: ["./settings-group.component.scss"],
})
export class SettingsGroupComponent implements OnInit {
  @Input() title: string
  @Input() readonly: boolean = false
  @Input() saveButtonDisabled: boolean

  @Output() onEditable = new EventEmitter<boolean>()
  @Output() onSave = new EventEmitter()
  editMode: boolean = false

  constructor() {}

  ngOnInit() {}

  setEditMode(editMode: boolean) {
    this.editMode = editMode
    this.onEditable.emit(editMode)
  }

  save() {
    this.setEditMode(false)
    this.onSave.emit()
  }
}
