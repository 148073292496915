import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling"
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { Company, DummyRole, Person, Project, ProjectMenuActions, Role } from "@models/common"
import { Observable, Subscription } from "rxjs"
import { SortOption } from "../../ordering/orderby.pipe"

@Component({
  selector: "app-project-list",
  templateUrl: "./project-list.component.html",
  styleUrls: ["./project-list.component.scss"],
})
export class ProjectListComponent implements OnInit, OnDestroy {
  @Input() projects: Project[]
  @Input() projectTypeChangedEvent: Observable<void>
  @Input() projectRoles: { [projectUid: string]: Role }
  @Input() currentCompany: Company
  @Input() userCompanyRole: Role
  @Input() currentUser: Person
  @Input() searchValue: string
  @Input() selectedSortOption?: SortOption

  _favoritesList: string[] = []

  @Input() set favoriteProjects(projects: Project[]) {
    this._favoritesList = projects ? projects.map((project) => project.uid) : []
  }

  @Input() emptyStateData: { title: string; description: string; image: string } = { title: "No results", description: "", image: "" }

  @Input() finishedLoading: boolean

  @Output() projectSelected = new EventEmitter<Project>()
  @Output() favoriteToggled = new EventEmitter<Project>()
  @Output() onProjectActionSelected = new EventEmitter()

  @ViewChild("virtualScroll") virtualScrollViewport: CdkVirtualScrollViewport
  private subscriptions: Subscription = new Subscription()

  projectRole(projectUid: string) {
    if (this.projectRoles == null) {
      return new DummyRole()
    }

    return this.projectRoles[projectUid] || new DummyRole()
  }

  ngOnInit() {
    this.subscriptions.add(
      this.projectTypeChangedEvent.subscribe(() => {
        if (this.virtualScrollViewport) {
          this.virtualScrollViewport.scrollToOffset(0)
        }
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  // TODO: Refactor duplicateSettings into Interface
  menuOptionSelected(event: { project: Project; action: ProjectMenuActions; duplicateSettings: any }) {
    this.onProjectActionSelected.emit(event)
  }

  trackByFn(index: number, project: Project) {
    return project.uid
  }
}
