import { FieldReport, LegacyReport, ReportGenerationSettings, SortingConfig, SortingDirection, SortingType } from "@models/common"
import { isEqual } from "lodash-es"
import { OpenReport } from "../../reports/models/open-report"
import { reportTemplate } from "../../_templates"
import { FormElement } from "./form-element"
import { ReportStatus_FROM_20_DONT_USE } from "./report-status.old-dont-use"
import { filterObjectPropertiesByKey, sortItemRowsBy } from "./utilities"

export interface IFormStatistics {
  totalElementCount: number
  filledElementCount: number
}

export interface ICheckdFormConfig {
  itemListSort?: {
    sortingType: SortingType
    sortingDirection: SortingDirection
  }
  pdfFieldSettings?: any
}

/**
 * Default attributes for a form model. Used when constructing a form,
 * converting JSON to a form, and serializing a form to JSON.
 *
 * We do this to ensure that all necessary attributes are set both
 * incoming and outgoing as some missing attributes can cause a null
 * pointer exception in the Forms API and/or the apps, and crashing
 * apps / Forms API can cause some of these attributes to be
 * null/undefined.
 *
 * The Forms API interprets a form with a reportDataId of 0 as a new
 * report, so we initialize a new report with that as default.
 *
 * NB: Don't send imageList attribute as anything other than null on
 * new reports. The Forms API will crash if you do.
 *
 * NB2: The description attribute needs to be present (with e.g. an
 * empty string), otherwise the Android app crashes when trying to
 * open the report.
 */
export const DEFAULT_FORM_ATTRIBUTES = {
  reportDataId: 0,
  reportName: "Untitled",
  description: "",
  assignUsers: [] as any[],
  reportStatus: ReportStatus_FROM_20_DONT_USE.Inprogress,
  statusTimeLines: [] as Record<string, any>[],
  noOfElements: 0,
  noOfFilledElements: 0,
  isArchived: false,
  isDeleted: false,
  itemDtos: [] as string[] | null,
  createdUserId: 0,
  isCrashed: false,
}

export class Form {
  reportType?: string
  reportDataId: number
  id: number
  number: number
  qrCode: string
  reportName: string
  description: string
  assignUsers: number[]
  reportStatus: ReportStatus_FROM_20_DONT_USE
  ownerUserId: number
  verifierUserId: number
  notSynced: boolean
  projectId: number
  noOfElements: number
  noOfFilledElements: number
  isArchived: boolean
  isLocal: boolean
  reportId: number
  imageList: any[]
  displayUserImageUrl: string
  createdUserId: number
  modifiedUserId: number
  isCrashed: boolean
  // TODO use StatusTimeLine[] instead of any[]
  statusTimeLines: any[]

  // TODO convert to Date objects
  createdDateTime: string
  modifiedDateTime: string

  itemDtos: any[]
  isDeleted: boolean

  header: FormElement
  details: FormElement

  static fromJson(report: LegacyReport | FieldReport | OpenReport): Form {
    return new Form(
      Object.assign({}, report.data || report || {}, {
        header: new FormElement({
          values: report.headerTemplateData.map((element: any) => FormElement.fromJson(element)),
        }),
        details: new FormElement({
          values: report.detailTemplateData.map((element: any) => FormElement.fromJson(element)),
        }),
      })
    )
  }

  constructor(attrs: any = {}) {
    // Set instance properties using the merged properties of the
    // default form attributes and the filtered attributes
    Object.assign(
      this,
      DEFAULT_FORM_ATTRIBUTES,
      // Ignore attributes that have a value of null or undefined
      filterObjectPropertiesByKey(attrs, (key) => attrs[key] != null)
    )

    if (attrs.data) {
      this.reportName = attrs.data.name || attrs.data.reportName || "Untitled"
    }

    // Fix
    // Field report title is name ( old forms used reportName as title)

    if (attrs.data != null && attrs.data.reportType === "FIELD") {
      this.reportName = attrs.data.name
    }

    if (attrs.name != null) {
      this.reportName = attrs.name
    }
  }

  public toJson() {
    const attributeBlacklist = ["header", "details"]

    // Build the JSON object by merging properties from the default
    // form attributes with the actual form attributes.
    const formTransformed = Object.assign(
      {},
      DEFAULT_FORM_ATTRIBUTES,
      // Ignore form attributes with a key matching any element in attributeBlacklist is ignored
      filterObjectPropertiesByKey(this, (key) => !attributeBlacklist.includes(key)),
      {
        headerTemplateData: this.header.transformAttributes().values,
        detailTemplateData: this.details.transformAttributes().values,
      }
    )

    return JSON.stringify(formTransformed)
  }

  sortDetailsContent(detailsContent: any[], sortingConfig: SortingConfig) {
    return detailsContent.map((element) => {
      const shouldNotBeSorted = element.type == null || element.type !== "itemlist" || element.values == null || element.values.body == null

      if (shouldNotBeSorted) {
        return element
      }

      const sortedItemRows = sortItemRowsBy(element.values.body, sortingConfig)
      const values = { ...element.values, ...{ body: sortedItemRows } }

      return {
        ...element,
        values,
      }
    })
  }

  /**
   * Returns the datastructure needed for the PDFmake template.
   */
  public toPdfDocDefinition(
    projectTitle: string,
    user: any,
    qrcodeLink: string | null = null,
    companyLogo: string | null = null,
    createdAt: string | null = null,
    creatorName: string | null = null,
    contentTotal: { totalItems: number; totalDrawings: number } | null = null,
    settings: ReportGenerationSettings | null = null,
    pdfFooter: string | null = null,
    config: ICheckdFormConfig = {},
    timeZone: string | null = null,
    templateVersion: string | null = null,
    footerLogo: string | null = null
  ) {
    const headerContent = this.header.toPdfDocDefinition()

    let detailsContent =
      config.itemListSort == null
        ? this.details.toPdfDocDefinition()
        : this.sortDetailsContent(this.details.toPdfDocDefinition(), config.itemListSort)

    // During the wonderful weeks where we piled features on Forms Builder for NELFO, it was possible to add Table Elements to Tabular
    // Elements for a short period of time (about a month). Some customers tried this non-intentional feature when creating templates,
    // and got stuck with a Table Child at the end of the tabular list. This would crash the PDF generation, because it added extra
    // objects to the array we get out of details.toDocDefinition().
    // This hack removes those extra objects
    detailsContent = detailsContent.filter((elem: any) => !isEqual(elem, { text: " ", rowSpan: 1, colSpan: 1 }))
    // end of hack

    const content = headerContent.concat(detailsContent) // this.header.toPdfDocDefinition().concat(...this.details.toPdfDocDefinition());

    if (contentTotal || creatorName || createdAt || timeZone) {
      const contentHeader = Object.assign({ creatorName, createdAt, timeZone }, contentTotal)
      content.unshift(this.getContentElement("", contentHeader))
    }

    return reportTemplate(
      content,
      this.number,
      this.reportName,
      projectTitle,
      user,
      // @ts-ignore
      qrcodeLink,
      companyLogo,
      createdAt,
      creatorName,
      settings,
      pdfFooter,
      templateVersion,
      footerLogo
    )
  }

  private getTextElement(title: string, value: string) {
    return new FormElement({
      id: 1,
      title,
      name: title,
      type: "default",
      value,
      options: [
        {
          id: 1,
          code: "half",
          type: "checkbox",
          name: "Half",
          value: "true",
        },
      ],
    })
  }

  private getContentElement(title: string, content: any) {
    return {
      title,
      value: content,
      type: "reportcontent",
      width: 100,
    }
  }

  public getFillableElements(values: FormElement[]) {
    const blackList = ["mainandsubfield"]

    return values.filter((v) => !blackList.includes(v.type))
  }

  public getFormStatistics(): IFormStatistics {
    const totalHeaderElements = this.getFillableElements(this.header.values!).map((elm) => elm.hasValue())
    const totalDetailElements = this.getFillableElements(this.details.values!).map((elm) => elm.hasValue())

    const totalHeaderElementCount = totalHeaderElements.length
    const totalDetailElementCount = totalDetailElements.length
    const filledHeaderElementCount = totalHeaderElements.filter((elm) => elm).length
    const filledDetailElementCount = totalDetailElements.filter((elm) => elm).length

    return {
      totalElementCount: totalHeaderElementCount + totalDetailElementCount,
      filledElementCount: filledHeaderElementCount + filledDetailElementCount,
    }
  }

  public updateFilledElementCount(): void {
    const formStatistics = this.getFormStatistics()
    this.noOfElements = formStatistics.totalElementCount
    this.noOfFilledElements = formStatistics.filledElementCount
  }
}
