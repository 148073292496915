<div class="camera-element">
  <p>{{ title }}</p>
  <div class="camera-element__image-gallery">
    <div *ngFor="let image of imageArray; let index = index" class="flex flex-column">
      <p-image
        src="{{ image.thumbnail }}"
        [preview]="true"
        alt="static image"
        styleClass="digital-report-static-image"
        class="mt-auto"
      ></p-image>
      <button *ngIf="!readOnly" mat-button color="warn" (click)="deleteImage(image.thumbnail, index)" class="mt-auto">Delete</button>
    </div>
  </div>
  <div class="camera-element__upload-container">
    <app-filestack-uploader *ngIf="!readOnly" (onUpload)="onUpload($event)"></app-filestack-uploader>
  </div>
</div>
