<div class="tag" [ngStyle]="{ background: checked ? '#D4DDFF' : 'white' }">
  <p-checkbox
    name="tag"
    [value]="label"
    [(ngModel)]="checked"
    [binary]="true"
    inputId="{{ label }}"
    styleClass="tag__checkbox"
    (onChange)="onTagCheckedChange($event)"
  ></p-checkbox>
  <label [for]="label" class="tag__text-label">{{ label }}</label>
</div>
