import { Component } from "@angular/core"
import { getCorrectOptionValue } from "@checkd-form/models/utilities"
import { DefaultElementComponent } from "../default-element/default-element.component"

@Component({
  selector: "app-toggle-element",
  templateUrl: "./toggle-element.component.html",
  styleUrls: ["./toggle-element.component.scss"],
})
export class ToggleElementComponent extends DefaultElementComponent {
  override get value() {
    return getCorrectOptionValue(this.getValue()) === true
  }

  override set value(checked: boolean) {
    this.setValue(checked)
  }

  isConditional() {
    const value = this.getOption("conditional")!.value

    return getCorrectOptionValue(value) === true
  }
}
