<mat-toolbar class="mat-elevation-z6">
  <mat-toolbar-row class="justify-content-between">
    <button (click)="goToDrawingsList()" mat-icon-button>
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>

    <span class="title truncate-ellipsis min-w-0">
      {{ (drawing$ | async)?.name }}
      <sup *ngIf="(drawing$ | async)?.archived" class="sup-archived">Archived</sup>
    </span>

    <mat-menu #appMenu="matMenu">
      <button mat-menu-item (click)="openDrawingDetail(drawing)">Drawing information</button>
    </mat-menu>
    <button [disabled]="!!(drawing$ | async)?.archived" mat-icon-button [matMenuTriggerFor]="appMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </mat-toolbar-row>

  <mat-toolbar-row>
    <span class="px-2 mx-auto truncate-ellipsis min-w-0"><strong>Project </strong>{{ (project$ | async)?.name }}</span>
  </mat-toolbar-row>
</mat-toolbar>

<main class="flex flex-column md:flex-row" [class.watermark]="(drawing$ | async)?.archived">
  <ng-container *ngIf="drawing$ | async as drawing">
    <app-checkd-drawing
      #checkdDrawing
      class="flex-1 min-w-0"
      [drawingData]="drawing.data"
      [items]="itemListFilteredDrawingItems"
      (onItemSelected)="onItemSelected($event)"
      (onItemContextMenu)="onItemContextMenu($event)"
    >
    </app-checkd-drawing>

    <section class="item-list flex flex-column flex-1 min-w-0">
      <div class="filter-wrapper flex justify-content-center align-items-center">
        <div class="flex-1"></div>
        <checkd-status-filter
          class="mat-elevation-z1 flex-initial"
          #statusFilter
          [noPadding]="true"
          [showItemNumbers]="false"
          (change)="onStatusFilterChange($event)"
        >
        </checkd-status-filter>

        <div class="flex align-items-center flex-1">
          <button (click)="resetFilters()" mat-icon-button>
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>

      <checkd-item-list
        #checkdItemList
        [ngStyle.lt-md]="{ 'margin-bottom': '30px' }"
        [items]="statusFilteredDrawingItems$ | async"
        [isItemListReady]="finishedLoadingStatusFilteredDrawingItems$ | async"
        [tableFor]="'drawings'"
        [currentUser]="currentUser"
        [userProjectRole]="userProjectRole"
        (itemSelected)="itemSelected($event)"
        (itemsFiltered)="onItemsFiltered($event)"
        (itemsSelected)="onItemsSelected($event)"
        (menuOptionSelected)="onMenuOptionSelected($event)"
        [hideNewItemButton]="true"
        [hideItemsActions]="true"
        [itemCreatorNames]="drawing.aggregateData.itemCreatorNames"
        [taskAssignerNames]="drawing.aggregateData.taskAssignerNames"
        [taskAssignerCompanyNames]="drawing.aggregateData.taskAssignerCompanyNames"
        [taskAssigneeNames]="drawing.aggregateData.taskAssigneeNames"
        [itemTags]="drawing.aggregateData.itemTags"
      >
      </checkd-item-list>

      <div class="floating-bottom-right flex justify-content-end gap-1">
        <checkd-button
          bg="green"
          fg="white"
          [ngClass]="{ 'animated bounce': hasSelectedItems }"
          [matTooltip]="hasSelectedItems ? '' : 'Please select items to create an export'"
          [disabled]="!hasSelectedItems || drawing.archived"
          (buttonClick)="generateReport()"
        >
          Generate report ({{ selectedItems.length }} selected items)
        </checkd-button>

        <checkd-button [disabled]="!hasSelectedItems || drawing.archived" bg="blue" fg="white" (buttonClick)="bulkAssign()">
          Assign ({{ selectedItems.length }} items)
        </checkd-button>

        <checkd-button [disabled]="drawing.archived || drawing.lockedByCheckd" bg="red" fg="white" (buttonClick)="createDrawingItem()">
          New item
        </checkd-button>
      </div>
    </section>
  </ng-container>
</main>
