import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-members-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" [attr.viewBox]="getViewBox()" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.4 11.9984C11.0513 11.9984 13.2 9.84931 13.2 7.19844C13.2 4.54756 11.0513 2.39844 8.4 2.39844C5.74875 2.39844 3.6 4.54756 3.6 7.19844C3.6 9.84931 5.74875 11.9984 8.4 11.9984ZM8.4 4.19844C10.0541 4.19844 11.4 5.54431 11.4 7.19844C11.4 8.85256 10.0541 10.1984 8.4 10.1984C6.74588 10.1984 5.4 8.85219 5.4 7.19844C5.4 5.54431 6.74625 4.19844 8.4 4.19844ZM10.3013 13.7984H6.49875C2.90887 13.7984 0 16.7084 0 20.2972C0 21.0172 0.582 21.5984 1.29975 21.5984H15.4987C16.2188 21.5984 16.8 21.0172 16.8 20.2972C16.8 16.7084 13.89 13.7984 10.3013 13.7984ZM1.82662 19.7984C2.07675 17.4397 4.07625 15.5984 6.49875 15.5984H10.3013C12.7241 15.5984 14.6925 17.4408 14.9738 19.7984H1.82662ZM17.9663 14.3984H15.1969C16.92 15.8122 18 17.9272 18 20.2972C18 20.7772 17.8575 21.2197 17.625 21.5984H22.8C23.4638 21.5984 24 21.0584 24 20.3647C24 17.0834 21.315 14.3984 17.9663 14.3984ZM16.2 11.9984C18.5213 11.9984 20.4 10.1197 20.4 7.79844C20.4 5.47719 18.5213 3.59844 16.2 3.59844C15.2584 3.59844 14.3985 3.91925 13.698 4.44256C14.13 5.27206 14.4 6.20094 14.4 7.19844C14.4 8.53044 13.9526 9.75369 13.2154 10.7501C13.9763 11.5184 15.03 11.9984 16.2 11.9984Z"
        fill="currentColor"
        [attr.transform]="getScale()"
      />
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class MembersIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }

  getViewBox(): string | undefined {
    return this.svgIconsService.getViewBox(this.size)
  }
  getScale(): string | undefined {
    return this.svgIconsService.getScale(this.size)
  }
}
