<ng-container *ngIf="userHasGalleryAccess; else noGalleryAccess">
  <div class="flex flex-row justify-content-between align-content-center">
    <div class="flex-1 flex-row justify-content-start align-content-center ml-1"></div>

    <div class="topbar-view-switcher flex flex-0 flex-row pl-8 pr-8">
      <button (click)="layout = 'grid'" pButton type="button" class="m-1">
        <img src="{{ layout === 'grid' ? menuIcons.gridFilled : menuIcons.grid }}" />
      </button>

      <button (click)="layout = 'list'" pButton type="button" class="m-1">
        <img src="{{ layout === 'list' ? menuIcons.listFilled : menuIcons.list }}" />
      </button>
    </div>

    <div class="flex flex-1 flex-row justify-content-end align-content-center mr-1">
      <p-calendar
        class="image-daterange flex flex-column justify-content-center align-content-center"
        selectionMode="range"
        [(ngModel)]="filterDate"
        [readonlyInput]="true"
        [showButtonBar]="true"
        icon="pi pi-calendar"
        placeholder="Enter a date range"
        [showIcon]="true"
        dateFormat="dd.mm.yy"
        (onClearClick)="clearDateFilter()"
        (onTodayClick)="filterImagesByDate()"
        (onSelect)="filterImagesByDate()"
      >
      </p-calendar>
    </div>
  </div>

  <ng-container *ngIf="projectGalleryService.currentProjectItemImages$ | async as images">
    <p-galleria
      [value]="images"
      [(visible)]="showFullScreen"
      [containerStyle]="{ 'max-width': '600px' }"
      [numVisible]="7"
      [circular]="true"
      [fullScreen]="true"
      [showItemNavigators]="true"
      [showThumbnails]="false"
      [baseZIndex]="100000"
      [activeIndex]="activeIndexFullScreenImage"
    >
      <ng-template pTemplate="item" let-item>
        <img [src]="item.url" style="width: 100%; display: block" />
      </ng-template>

      <ng-template pTemplate="caption" let-image>
        <section class="footer-section flex justify-content-between mt-2">
          <div class="flex flex-column">
            <ng-container *ngIf="image.createdAt as createdAt">
              <span>Taken on {{ createdAt | date: "fullDate" }}</span>

              <span>{{ createdAt | amTimeAgo }}</span>
            </ng-container>

            <ng-container *ngIf="image.aggregateData as ag">
              <span *ngIf="ag.itemName && ag.itemNumber && ag.itemStatus">
                #{{ ag.itemNumber }} - {{ ag.itemName }} ({{ ag.itemStatus | itemStatus: "humanize" | lowercase }})
              </span>
            </ng-container>
          </div>

          <div class="flex flex-column">
            <ng-container *ngIf="image.createdAt as createdAt">
              <span>Time: {{ createdAt | date: "HH:mm" }}</span>
              <span>Date: {{ createdAt | date: "dd.M.YYYY" }}</span>
            </ng-container>
          </div>
        </section>
      </ng-template>
    </p-galleria>

    <p-dataView
      #dataView
      [value]="images"
      [paginator]="false"
      [rows]="projectGalleryService.limit$ | async"
      [layout]="layout"
      styleClass="project-gallery-dataview"
    >
      <ng-template pTemplate="header">
        <div class="flex flex-row justify-content-between align-content-center shadow-2 p-0">
          <div class="flex flex-1 flex-row justify-content-start align-content-center ml-1">
            <ng-container *ngIf="layout === 'list'" class="flex flex-column justify-content-center">
              <p-checkbox class="mx-2" [binary]="true" [ngModel]="isAllSelected" (onChange)="selectAllImages(images)"></p-checkbox>
              <div class="flex flex-column justify-content-center">
                <button (click)="downloadSelectedImages()" pButton type="button" label="" class="download-button p-button-outlined">
                  <i class="pi pi-download"></i>
                  <p style="padding-left: 1em">DOWNLOAD IMAGES ({{ selectedImages.length }} images)</p>
                </button>
              </div>
            </ng-container>
          </div>

          <div class="back-forward-buttons flex flex-0 flex-row justify-content-around">
            <button pButton [disabled]="!(projectGalleryService.hasPrevious$ | async)" (click)="projectGalleryService.previous()">
              <i class="pi pi-backward"></i>
            </button>
            <button pButton [disabled]="!(projectGalleryService.hasNext$ | async)" (click)="projectGalleryService.next()">
              <i class="pi pi-forward"></i>
            </button>
          </div>

          <div class="flex flex-1 flex-row justify-content-end">
            <div class="flex flex-column justify-content-center align-content-center">
              <p-dropdown
                placeholder="Images per page"
                [options]="imagesPerPageOptions"
                (onChange)="projectGalleryService.setLimit($event.value)"
              >
              </p-dropdown>
            </div>

            <div class="flex mx-2 flex-column justify-content-center align-content-center">
              <button (click)="launchImageUploader()" class="upload-button" primary-button [isRounded]="true">
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3910_12557)">
                    <path
                      d="M24 11.9994C24 12.7667 23.3827 13.384 22.6154 13.384H13.3846V22.6148C13.3846 23.3792 12.7644 24 12 24C11.2356 24 10.6154 23.3821 10.6154 22.6148V13.384H1.38462C0.620192 13.384 0 12.7644 0 12C0 11.2379 0.620192 10.6148 1.38462 10.6148H10.6154V1.38404C10.6154 0.619615 11.2356 0 12 0C12.7644 0 13.3846 0.619615 13.3846 1.38404V10.6148H22.6154C23.3827 10.6148 24 11.2379 24 11.9994Z"
                      fill="currentcolor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3910_12557">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>Upload</span>
              </button>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template let-image let-index="rowIndex" pTemplate="listItem">
        <div class="product-list-item grid">
          <div class="col-1">
            <p-checkbox [value]="image" [(ngModel)]="selectedImages"></p-checkbox>
          </div>

          <div class="col-9 flex flex-row justify-content-between">
            <div class="flex flex-column full-width">
              <div
                *ngIf="image.aggregateData?.itemNumber || image.aggregateData?.itemStatus"
                class="item-number-and-status-in-list flex align-self-start"
              >
                <div class="number-area px-1 select-none">{{ image?.aggregateData?.itemNumber || "" }}</div>

                <div *ngIf="image.aggregateData?.itemStatus as status" class="status-area px-1" [ngClass]="status | itemStatus">
                  {{ status | itemStatus: "humanize" }}
                </div>
              </div>

              <div
                [ngClass]="
                  image.aggregateData?.itemNumber || image.aggregateData?.itemStatus ? 'justify-content-between' : 'justify-content-end'
                "
                class="flex align-content-center"
              >
                <h5 class="text-overflow-ellipsis overflow-hidden" *ngIf="image.aggregateData?.itemName as itemName">
                  {{ itemName || "" }}
                </h5>

                <h5>{{ image.createdAt | date: dateFormat }}</h5>
              </div>
            </div>
          </div>

          <div class="col-1 flex flex-column">
            <img
              class="listImg clickable"
              [src]="filestackService.getThumbnailUrl(image.url, imageWidth, true, 'jpg', 'crop')"
              (click)="showFullScreenGallery(dataView.first + index)"
            />
          </div>
        </div>
      </ng-template>

      <ng-template let-image let-index="rowIndex" pTemplate="gridItem">
        <div class="image-grid-container test-class-name m-2 flex flex-column justify-content-start align-content-center">
          <img
            class="image-thumbnail clickable"
            [src]="filestackService.getThumbnailUrl(image.url, imageWidth, true, 'jpg', 'crop')"
            (click)="showFullScreenGallery(dataView.first + index)"
          />

          <div class="image-grid-description p-1 flex flex-column flex-1 justify-content-between">
            <div class="flex justify-content-between align-items-center min-w-0">
              <div class="flex-1 mr-2">
                <div class="item-number-and-status flex">
                  <div class="number-area px-1 select-none">{{ image?.aggregateData?.itemNumber || "" }}</div>

                  <div
                    class="status-area px-2 select-none"
                    *ngIf="image?.aggregateData?.itemStatus as status"
                    [ngClass]="status | itemStatus"
                  >
                    {{ status | itemStatus: "humanize" }}
                  </div>
                </div>
              </div>

              <div class="date-container flex flex-column align-items-end">
                <span class="inline-flex">{{ image.createdAt | date: "MMM d y" }}</span>
                <span class="inline-flex">{{ image.createdAt | date: "HH:mm" }}</span>
              </div>
            </div>

            <div class="flex text-xs pt-1 min-w-0">
              <span
                (click)="onItemNameClicked(image)"
                class="inline-block cursor-pointer text-overflow-ellipsis white-space-nowrap overflow-hidden flex-auto min-w-0"
              >
                {{ image?.aggregateData?.itemName || "" }}
              </span>
              <!--              <div class='flex-none'>-->
              <!--                -->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </ng-container>
</ng-container>

<ng-template #noGalleryAccess>
  <div *ngIf="userProjectRole != null" class="empty-state__component-container">
    <checkd-empty-state
      [title]="noAccessToGalleryTitle"
      [text]="noAccessToGalleryText"
      [image]="noAccessToGalleryImgSrc"
    ></checkd-empty-state>
  </div>
</ng-template>
