<app-settings-group title="Personal" [saveButtonDisabled]="name!.value!.trim() == ''" (onEditable)="onEditable($event)" (onSave)="save()">
  <form [formGroup]="personalFormGroup" fxLayout="column" fxLayoutAlign="space-between">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" type="text" />
      <mat-error *ngIf="name!.invalid && name!.touched">A name is required</mat-error>
    </mat-form-field>

    <!-- <mat-form-field>
         <input matInput formControlName="email" type="text" placeholder="Email" />
         </mat-form-field>
    -->
    <!-- <mat-form-field>
         <input matInput formControlName="image" type="text" placeholder="Image" />
         </mat-form-field>
    -->
    <mat-form-field>
      <mat-label>Phone</mat-label>
      <input matInput formControlName="phone" type="text" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Job title</mat-label>
      <input matInput formControlName="jobTitle" type="text" />
    </mat-form-field>

    <p>Click <a href="/passwordrecovery" target="_blank">here</a> if you need to reset your password.</p>
  </form>
</app-settings-group>
