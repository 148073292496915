import { Image } from "@models/common/image"
import { WorkflowData, WorkflowStates } from "./workflow.interface"
import firebase from "firebase/compat/app"
import firestore = firebase.firestore
import { Workflow } from "./workflow"
import { Item } from "./item"
import { TaskData, Task } from "./task"
import { Person } from "./person"
import { Relation } from "./relation"
import { ItemAggregateData } from "./aggregate-data"
import { COLLECTIONS } from "./collections.interface"
import { LABELS } from "./relation.interface"

export class TaskHandler {
  protected workflow: Workflow

  constructor() {
    this.workflow = Workflow.get("")
  }
}

export class ItemTaskHandler extends TaskHandler {
  protected item: Item
  protected task: Task

  constructor(item: Item, task?: Task) {
    super()
    this.item = item
    if (task) {
      this.task = task
      this.workflow.setCurrentState(task.data.status)
    }
  }

  static batchSetStatus(batch: firestore.WriteBatch, status: string, item: Item, task: Task, bypassWorkflow = false) {
    item.batchUpdate(batch, { status })
    if (task != null) {
      task.batchUpdate(batch, { status })
    }
  }

  static async batchAssignOrReassignTask(batch: firestore.WriteBatch, task: Task, assigner: Person, assignee: Person, bulkUid?: string) {
    return task.isAssigned
      ? ItemTaskHandler.batchReassignTask(batch, task, assigner, assignee, bulkUid)
      : ItemTaskHandler.batchAssignTask(batch, task, assigner, assignee, bulkUid)
  }

  static async batchAssignTask(batch: firestore.WriteBatch, task: Task, assigner: Person, assignee: Person, bulkUid?: string) {
    const items = await Relation.getAllTargets(task, COLLECTIONS.ITEMS, [LABELS.CONCERNS])
    ItemTaskHandler.batchSetAssignmentRelations(batch, task, assigner, assignee)

    ItemTaskHandler.batchUpdateAssignedTask(batch, task, assigner, assignee, bulkUid)
    for (const item of items) {
      ItemTaskHandler.batchUpdateAssignedItem(batch, item, assigner, assignee)
    }

    return task
  }

  static batchUpdateAssignedTask(batch: firestore.WriteBatch, task: Task, assigner: Person, assignee: Person, bulkUid?: string) {
    const taskData: TaskData = {
      assigner: assigner.ref,
      assignee: assignee.ref,
      status: WorkflowStates.DELEGATED,
      bulkUid: bulkUid || "",
    }

    task.batchUpdate(batch, taskData)
    task.batchUpdateAggregateData(batch, {
      taskAssignerUid: assigner.uid || null,
      taskAssignerName: assigner.name || null,
      taskAssignerCompanyName: (assigner.aggregateData || {})["companyName"] || null,
      taskAssigneeUid: assignee.uid || null,
      taskAssigneeName: assignee.name || null,
      taskAssigneeCompanyName: (assignee.aggregateData || {})["companyName"] || null,
    })
    return task
  }

  static batchUpdateAssignedItem(batch: firestore.WriteBatch, item: Item, assigner: Person, assignee: Person) {
    const itemAggregateData: ItemAggregateData = {
      ...item.aggregateData,
      // @ts-ignore
      taskAssignerUid: assigner.uid || null,
      // @ts-ignore
      taskAssignerName: assigner.name || null,
      taskAssignerCompanyName: (assigner.aggregateData || {})["companyName"] || null,
      // @ts-ignore
      taskAssigneeUid: assignee.uid || null,
      // @ts-ignore
      taskAssigneeName: assignee.name || null,
      taskAssigneeCompanyName: (assignee.aggregateData || {})["companyName"] || null,
    }

    item.batchUpdate(batch, {
      status: WorkflowStates.DELEGATED,
      aggregateData: itemAggregateData,
    })
  }

  static async batchAssignItem(batch: firestore.WriteBatch, item: Item, assigner: Person, assignee: Person, bulkUid?: string) {
    const task = Task.batchCreate(batch, Task, { status: WorkflowStates.DELEGATED })

    ItemTaskHandler.batchSetTaskCreatorRelations(batch, task, assigner)
    const assignedTask = await ItemTaskHandler.batchAssignTask(batch, task, assigner, assignee, bulkUid)
    ItemTaskHandler.batchUpdateAssignedItem(batch, item, assigner, assignee)

    item.batchAdd(batch, task, [Relation.LABELS.CONCERNED_BY])
    // @ts-ignore
    task.batchAdd(batch, item, [Relation.LABELS.CONCERNS])

    return { item: item, task: assignedTask }
  }

  static batchSetTaskCreatorRelations(batch: firestore.WriteBatch, task: Task, creator: Person) {
    creator.batchAdd(batch, task, [Relation.LABELS.CREATOR])
    task.batchAdd(batch, creator, [Relation.LABELS.CREATED_BY])
  }

  static batchSetAssignmentRelations(batch: firestore.WriteBatch, task: Task, assigner: Person, assignee: Person) {
    assigner.batchAdd(batch, task, [Relation.LABELS.HAS_ASSIGNED])
    task.batchAdd(batch, assigner, [Relation.LABELS.ASSIGNED_BY])
    task.batchAdd(batch, assignee, [Relation.LABELS.ASSIGNED_TO])
    assignee.batchAdd(batch, task, [Relation.LABELS.IS_ASSIGNED])
  }

  static async batchReassignTask(batch: firestore.WriteBatch, task: Task, assigner: Person, newAssignee: Person, bulkUid?: string) {
    const items = await Relation.getAllTargets(task, COLLECTIONS.ITEMS, [LABELS.CONCERNS])
    await ItemTaskHandler.batchUnassign(batch, task)

    await ItemTaskHandler.batchAssignTask(batch, task, assigner, newAssignee, bulkUid)
    ItemTaskHandler.batchUpdateAssignedTask(batch, task, assigner, newAssignee, bulkUid)

    for (const item of items) {
      ItemTaskHandler.batchUpdateAssignedItem(batch, item, assigner, newAssignee)
    }
    return task
  }

  static async batchUnassign(batch: firestore.WriteBatch, task: Task): Promise<Task> {
    const taskAssignerRelations = await Relation.getAll(task, COLLECTIONS.PEOPLE, [LABELS.ASSIGNED_BY])
    const assignerTaskRelations = await Relation.getAll(task, COLLECTIONS.PEOPLE, [LABELS.HAS_ASSIGNED])
    const taskAssigneeRelations = await Relation.getAll(task, COLLECTIONS.PEOPLE, [LABELS.ASSIGNED_TO])
    const assigneeTaskRelations = await Promise.all(taskAssigneeRelations.map((r) => Relation.getInverseRelation(r)))

    for (const r of taskAssignerRelations) {
      const newRel = r.batchRemoveLabels(batch, [LABELS.ASSIGNED_BY])

      if (newRel.hasNoLabels) {
        Relation.batchDelete(batch, newRel)
      }
    }

    for (const r of assignerTaskRelations) {
      const newRel = r.batchRemoveLabels(batch, [LABELS.HAS_ASSIGNED])

      if (newRel.hasNoLabels) {
        Relation.batchDelete(batch, newRel)
      }
    }

    for (const r of taskAssigneeRelations) {
      const newRel = r.batchRemoveLabels(batch, [LABELS.ASSIGNED_TO])

      if (newRel.hasNoLabels) {
        Relation.batchDelete(batch, newRel)
      }
    }

    for (const r of assigneeTaskRelations) {
      const newRel = r.batchRemoveLabels(batch, [LABELS.IS_ASSIGNED])
      if (newRel.hasNoLabels) {
        Relation.batchDelete(batch, newRel)
      }
    }

    task.batchUpdate(batch, { assignee: null })
    task.batchUpdateAggregateData(batch, {
      taskAssigneeName: null,
      taskAssigneeCompanyName: null,
    })
    return task
  }

  static forItem(uid: string): Promise<ItemTaskHandler> {
    return Item.get(uid).then((item) => {
      return (
        item
          .collection(Task.COLLECTION)
          .get()
          // @ts-ignore
          .then((snapshot) => {
            if (snapshot.empty) return { item: item, task: null }
            return Task.get(snapshot.docs[0].id).then((task) => {
              return { item: item, task: task }
            })
          })
          // @ts-ignore
          .then((result: { item: Item; task: Task }) => {
            return new ItemTaskHandler(result.item, result.task)
          })
      )
    })
  }

  static forTask(uid: string): Promise<ItemTaskHandler> {
    return Task.get(uid).then((task) => {
      return (
        task
          .collection(Item.COLLECTION)
          .get()
          // @ts-ignore
          .then((snapshot) => {
            if (snapshot.empty) return { item: null, task: null }
            return Item.get(snapshot.docs[0].id).then((item) => {
              return { item: item, task: task }
            })
          })
          // @ts-ignore
          .then((result: { item: Item; task: Task }) => {
            return new ItemTaskHandler(result.item, result.task)
          })
      )
    })
  }
}
