import { LegacyReportData, LegacyTemplateData } from "../../../../../src/app/dashboard/models/common"
import {
  IInternalCameraFormsElement,
  IInternalCheckboxFormsElement,
  IInternalCheckboxGroupFormsElement,
  IInternalDateFormsElement,
  IInternalDropdownFormsElement,
  IInternalFormsData,
  IInternalFormsItemsFormsElement,
  IInternalGpsFormsElement,
  IInternalImageFormsElement,
  IInternalMainAndSubFieldFormsElement,
  IInternalPagebreakFormsElement,
  IInternalSignatureFormsElement,
  IInternalTableChildFormsElement,
  IInternalTableFormsElement,
  IInternalTabularFormFormsElement,
  IInternalTextFieldFormsElement,
  IInternalTextFormsElement,
  IInternalTimeFormsElement,
  IInternalUpDownFormsElement,
  IInternalYesNoFormsElement,
  InternalFormsElement,
  InternalFormsElementConfig,
  InternalFormsElementConfigCategories,
  InternalFormsElementDataType,
  InternalFormsElementType,
} from "../interfaces-and-types/internal.interface"
import {
  ILegacyCameraFormsElement,
  ILegacyDropdownFormsElement,
  ILegacyFormsElementOption,
  ILegacyTextFieldFormsElement,
  LegacyFormsElement,
  LegacyFormsElementOptionType,
  LegacyFormsElementType,
} from "../interfaces-and-types/legacy.interface"

function transformElement(element: LegacyFormsElement): InternalFormsElement {
  const commonData = transformCommonElementData(element)
  const config = transformElementOptions(element)
  let transformedElementData

  switch (element.type as LegacyFormsElementType) {
    case LegacyFormsElementType.Camera:
      transformedElementData = transformCameraElement(element)
      break
    case LegacyFormsElementType.Checkbox:
      transformedElementData = transformCheckboxGroupElement(element)
      break
    case LegacyFormsElementType.Date:
      transformedElementData = transformDateElement(element)
      break
    case LegacyFormsElementType.Dropdown:
      transformedElementData = transformDropdownElement(element)
      break
    case LegacyFormsElementType.FormsItems:
      transformedElementData = transformFormsItemsElement(element)
      break
    case LegacyFormsElementType.Gps:
      transformedElementData = transformGPSElement(element)
      break
    case LegacyFormsElementType.MainAndSubField:
      transformedElementData = transformMainAndSubField(element)
      break
    case LegacyFormsElementType.MultiLineTextField:
      transformedElementData = transformMultilineTextField(element)
      break
    case LegacyFormsElementType.PageBreak:
      transformedElementData = transformPagebreakElement(element)
      break
    case LegacyFormsElementType.Signature:
      transformedElementData = transformSignatureElement(element)
      break
    case LegacyFormsElementType.Slider:
      transformedElementData = transformSliderElement(element)
      break
    case LegacyFormsElementType.TabularForm:
      transformedElementData = transformTabularFormElement(element)
      break
    case LegacyFormsElementType.TextField:
      transformedElementData = transformTextFieldElement(element)
      break
    case LegacyFormsElementType.TextFieldInt:
      transformedElementData = transformTextFieldIntElement(element)
      break
    case LegacyFormsElementType.Time:
      transformedElementData = transformTimeElement(element)
      break
    case LegacyFormsElementType.UpDown:
      transformedElementData = transformUpDownElement(element)
      break
    case LegacyFormsElementType.YesNo:
      transformedElementData = transformYesNoElement(element)
      break
    case LegacyFormsElementType.Table:
      transformedElementData = transformTableElement(element)
      break
    case LegacyFormsElementType.TableChild:
      transformedElementData = transformTableChildElement(element)
      break
    case LegacyFormsElementType.Text:
      transformedElementData = transformTextElement(element)
      break
    case LegacyFormsElementType.Image:
      transformedElementData = transformImageElement(element)
      break
    default:
      console.error("type error", element)
    // throw Error(`This element did not have a type, or had an unknown type: ${element}`)
  }

  // FIXME Some strange typing issues here. Uncomment to see the error. Fix and remove ts-ignore
  // @ts-ignore
  return { ...commonData, ...transformedElementData, config }
}

function transformElementOption(option: ILegacyFormsElementOption): any {
  const optionValue = transformLegacyOptionValue(option.value)

  if (!optionValue) {
    return {}
  }

  if (typeof optionValue === "string" && !optionValue.trim()) {
    return {}
  }

  switch (option.code) {
    /* deprecated */
    case LegacyFormsElementOptionType.isdescription:
    case LegacyFormsElementOptionType.autogenarated:
      return {}
    case LegacyFormsElementOptionType.checkbox_size:
      return { checkbox_size: optionValue }
    case LegacyFormsElementOptionType.include_title_in_pdf:
      return { include_title_in_pdf: true }
    case LegacyFormsElementOptionType.main_sub_field_background_color:
    case LegacyFormsElementOptionType.text_background_color:
      return { background_color: optionValue }
    case LegacyFormsElementOptionType.text_color:
    case LegacyFormsElementOptionType.main_sub_field_text_color:
      return { font_color: optionValue }
    case LegacyFormsElementOptionType.table_columns:
      return { num_columns: optionValue }
    case LegacyFormsElementOptionType.table_column_widths:
      return { table_column_widths: optionValue }
    case LegacyFormsElementOptionType.table_child_row_span:
      return { row_span: optionValue }
    case LegacyFormsElementOptionType.table_child_col_span:
      return { col_span: optionValue }
    case LegacyFormsElementOptionType.font_size:
      return { font_size: optionValue }
    case LegacyFormsElementOptionType.font_bold:
      return { font_bold: true }
    case LegacyFormsElementOptionType.font_cursive:
      return { font_cursive: optionValue }
    case LegacyFormsElementOptionType.include_in_email:
      return { include_in_email: true }
    case LegacyFormsElementOptionType.text_field_max_length:
      return { text_field_max_length: optionValue }
    case LegacyFormsElementOptionType.hide_title_in_pdf:
      return { hide_title_in_pdf: true }
    case LegacyFormsElementOptionType.addnew:
      return { addnew: true }
    case LegacyFormsElementOptionType.conditional:
      return { conditional: true }
    case LegacyFormsElementOptionType.half:
      return { width: 50 }
    case LegacyFormsElementOptionType.ismultiselect:
      return { multiselect: true }
    case LegacyFormsElementOptionType.isvertical:
      return { vertical: true }
    case LegacyFormsElementOptionType.quater:
      return { width: 25 }
    case LegacyFormsElementOptionType.required:
      return { required: true }
    default:
      return {}
  }
}

function transformLegacyOptionValue(value: unknown): string | number | string[] | boolean | unknown | null | undefined {
  switch (true) {
    case value === "true":
      return true
    case value === "false":
      return false
    case typeof value === "string":
    case typeof value === "number":
    case typeof value === "boolean":
    case Array.isArray(value):
    default:
      return value
  }
}

function generateElementGeneralDefaultConfig(obj: any) {
  return {
    required: false,
  }
}

function generateElementSpecificDefaultConfig(obj: any) {
  switch (obj.type) {
    case LegacyFormsElementType.CheckboxGroup:
      return { vertical: false }
    case LegacyFormsElementType.Dropdown:
      return { addnew: false, multiselect: false }
    default:
      return {}
  }
}

function generateElementPdfDefaultConfig(obj: any) {
  return {
    width: 100,
  }
}

const legacyElementOptionCategory = {
  [LegacyFormsElementOptionType.quater]: InternalFormsElementConfigCategories.pdf,
  [LegacyFormsElementOptionType.half]: InternalFormsElementConfigCategories.pdf,

  [LegacyFormsElementOptionType.required]: InternalFormsElementConfigCategories.general,

  [LegacyFormsElementOptionType.conditional]: InternalFormsElementConfigCategories.specific,
  [LegacyFormsElementOptionType.ismultiselect]: InternalFormsElementConfigCategories.specific,
  [LegacyFormsElementOptionType.isvertical]: InternalFormsElementConfigCategories.specific,
  [LegacyFormsElementOptionType.addnew]: InternalFormsElementConfigCategories.specific,

  [LegacyFormsElementOptionType.checkbox_size]: InternalFormsElementConfigCategories.specific,
  [LegacyFormsElementOptionType.include_title_in_pdf]: InternalFormsElementConfigCategories.pdf,
  [LegacyFormsElementOptionType.main_sub_field_text_color]: InternalFormsElementConfigCategories.specific,
  [LegacyFormsElementOptionType.main_sub_field_background_color]: InternalFormsElementConfigCategories.specific,
  [LegacyFormsElementOptionType.table_columns]: InternalFormsElementConfigCategories.specific,
  [LegacyFormsElementOptionType.table_column_widths]: InternalFormsElementConfigCategories.specific,
  [LegacyFormsElementOptionType.table_child_row_span]: InternalFormsElementConfigCategories.specific,
  [LegacyFormsElementOptionType.table_child_col_span]: InternalFormsElementConfigCategories.specific,
  [LegacyFormsElementOptionType.text_background_color]: InternalFormsElementConfigCategories.general,
  [LegacyFormsElementOptionType.text_color]: InternalFormsElementConfigCategories.general,
  [LegacyFormsElementOptionType.font_size]: InternalFormsElementConfigCategories.general,
  [LegacyFormsElementOptionType.font_bold]: InternalFormsElementConfigCategories.general,
  [LegacyFormsElementOptionType.font_cursive]: InternalFormsElementConfigCategories.general,
  [LegacyFormsElementOptionType.include_in_email]: InternalFormsElementConfigCategories.general,
  [LegacyFormsElementOptionType.text_field_max_length]: InternalFormsElementConfigCategories.specific,
  [LegacyFormsElementOptionType.hide_title_in_pdf]: InternalFormsElementConfigCategories.general,

  // These are legacy options that we want to get rid of:
  //   - isdescription
  //   - autogenerated
  // TODO: should we also get rid of 'required'?
}

export function transformElementOptions(obj: any): InternalFormsElementConfig {
  const config: InternalFormsElementConfig = {
    [InternalFormsElementConfigCategories.general]: generateElementGeneralDefaultConfig(obj),
    [InternalFormsElementConfigCategories.pdf]: generateElementPdfDefaultConfig(obj),
    [InternalFormsElementConfigCategories.specific]: generateElementSpecificDefaultConfig(obj),
  }

  const options: ILegacyFormsElementOption[] = obj.options || []

  for (const option of options) {
    if (Object.keys(legacyElementOptionCategory).includes(option.code)) {
      // @ts-ignore
      config[legacyElementOptionCategory[option.code]] = {
        // @ts-ignore
        ...config[legacyElementOptionCategory[option.code]],
        ...transformElementOption(option),
      }
    }
  }

  return config
}

// TODO: add proper type to argument and return value
function transformCommonElementData(obj: any): Pick<InternalFormsElement, "name" | "description" | "config"> {
  return {
    name: obj.title || "",
    description: obj.info || "",
    config: transformElementOptions(obj),
  }
}

// TODO: add proper type to argument
function transformCameraElement(obj: ILegacyCameraFormsElement): IInternalCameraFormsElement {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.Camera,
    dataType: InternalFormsElementDataType.String,
    data: "",
  }
}

// TODO: add proper type to argument
function transformCheckboxElement(obj: any): IInternalCheckboxFormsElement {
  const legacyConditionalData = obj.child || []
  const conditionalData = {
    ...(legacyConditionalData.length > 0 ? { ifTrue: transformElements(legacyConditionalData) } : {}),
  }

  return {
    type: InternalFormsElementType.Checkbox,
    name: obj.title || obj.name || "",
    data: (obj.value && obj.value.toString() === "true") || false,

    // TODO Discuss if we need this for a single checkbox
    description: obj.description || "",

    dataType: InternalFormsElementDataType.Boolean,

    conditionalData,
  }
}

function transformCheckboxGroupElement(obj: any): IInternalCheckboxGroupFormsElement {
  const data = (obj.values || []).map(transformCheckboxElement)

  return {
    type: InternalFormsElementType.CheckboxGroup,
    name: obj.title || "",
    description: obj.description || "",
    data,
    dataType: InternalFormsElementDataType.CheckboxList,
  }
}

function transformDropdownElement(obj: ILegacyDropdownFormsElement): IInternalDropdownFormsElement {
  const data = (obj.values || []).map((element: any) => {
    return {
      type: LegacyFormsElementType.DropdownMenuItem,
      name: element.name || "",
      data: element.value && element.value.toLowerCase() === "true",
      dataType: InternalFormsElementDataType.Boolean,
    }
  })

  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.Dropdown,
    dataType: InternalFormsElementDataType.DropdownMenuItemList,
    data,
  }
}

// TODO: add proper type to argument
function transformFormsItemsElement(obj: any): IInternalFormsItemsFormsElement {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.FormsItems,
    dataType: InternalFormsElementDataType.AnyList,
    data: [] as any[],
  }
}

// TODO: add proper type to argument
function transformGPSElement(obj: any): IInternalGpsFormsElement {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.Gps,
    dataType: InternalFormsElementDataType.String,
    data: "",
  }
}

// TODO: add proper type to argument
function transformMainAndSubField(obj: any): IInternalMainAndSubFieldFormsElement {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.MainAndSubField,
    dataType: InternalFormsElementDataType.StringList,
    data: [obj.title || "", obj.info || ""],
  }
}

// TODO: add proper type to argument and return value
function transformMultilineTextField(obj: any) {
  return {
    ...transformCommonElementData(obj),
    data: [] as any[],
  }
}

// TODO: add proper type to argument
function transformSignatureElement(obj: any): IInternalSignatureFormsElement {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.Signature,
    dataType: InternalFormsElementDataType.String,
    data: "",
  }
}

// TODO: add proper type to argument and return value
function transformSliderElement(obj: any) {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.Slider,
    dataType: InternalFormsElementDataType.Number,
    data: obj.value || 0,
  }
}

// TODO: add proper type to argument
function transformTabularFormElement(obj: any): IInternalTabularFormFormsElement {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.TabularForm,
    dataType: InternalFormsElementDataType.AnyList,
    // NB: Not sure if all elements are in a nested array, need to check with more templates
    data: transformElements([].concat(...(obj.child || []))),
  }
}

// TODO: add proper type to argument
function transformTextFieldElement(obj: any): IInternalTextFieldFormsElement {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.TextField,
    dataType: InternalFormsElementDataType.String,
    data: obj.value || "",
  }
}

function transformTextFieldIntElement(obj: ILegacyTextFieldFormsElement): IInternalTextFieldFormsElement {
  return {
    // TODO Discuss if we need the TextFieldInt type, or just TextField
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.TextFieldInt,
    dataType: InternalFormsElementDataType.String,
    data: `${obj.value || ""}`,
  }
}

// TODO: add proper type to argument
function transformDateElement(obj: any): IInternalDateFormsElement {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.Date,
    dataType: InternalFormsElementDataType.String,
    data: obj.value,
  }
}

// TODO: add proper type to argument
function transformTimeElement(obj: any): IInternalTimeFormsElement {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.Time,
    dataType: InternalFormsElementDataType.String,
    data: obj.value,
  }
}

// TODO: add proper type to argument
function transformUpDownElement(obj: any): IInternalUpDownFormsElement {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.UpDown,
    dataType: InternalFormsElementDataType.String,
    data: obj.value || "",
  }
}

// TODO: add proper type to argument
function transformYesNoElement(obj: any): IInternalYesNoFormsElement {
  const legacyConditionalData = {
    accepted: obj.accepted || [],
    rejected: obj.rejected || [],
  }
  const conditionalData = {
    ...(legacyConditionalData.accepted.length > 0 ? { ifTrue: transformElements(legacyConditionalData.accepted) } : {}),
    ...(legacyConditionalData.rejected.length > 1 ? { ifFalse: transformElements(legacyConditionalData.rejected) } : {}),
  }

  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.Toggle,
    dataType: InternalFormsElementDataType.Boolean,
    data: obj.value || false,
    conditionalData,
  }
}

// TODO: add proper type to argument
function transformTableElement(obj: any): IInternalTableFormsElement {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.Table,
    dataType: InternalFormsElementDataType.TableChildList,
    data: transformElements([].concat(...(obj.child || []))),
  }
}

// TODO: add proper type to argument
function transformTableChildElement(obj: any): IInternalTableChildFormsElement {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.TableChild,
    dataType: InternalFormsElementDataType.Any,
    data: transformElements([].concat(...(obj.child || []))),
  }
}

// TODO: add proper type to argument
function transformTextElement(obj: any): IInternalTextFormsElement {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.Text,
    dataType: InternalFormsElementDataType.None,
    data: null,
  }
}

// TODO: add proper type to argument
function transformPagebreakElement(obj: any): IInternalPagebreakFormsElement {
  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.PageBreak,
    dataType: InternalFormsElementDataType.None,
    data: null,
  }
}

// TODO: add proper type to argument
function transformImageElement(obj: any): IInternalImageFormsElement {
  const value = typeof obj === "object" && obj !== null && obj.value ? obj.value : ""

  return {
    ...transformCommonElementData(obj),
    type: InternalFormsElementType.Image,
    dataType: InternalFormsElementDataType.String,
    data: value,
  }
}

// TODO: add proper types for argument and return value
function transformElements(data: LegacyFormsElement[]): InternalFormsElement[] {
  return data.map(transformElement)
}

export function transformLegacyToInternal(report: Partial<LegacyTemplateData> & Partial<LegacyReportData>): IInternalFormsData {
  // Remove isStandard
  // introduce new status attribute: status: "active" | "draft" | "archived"
  // Create utility functions to convert to and from this new status attribute
  // add sharedWith attribute
  // convert createdAt and updatedAt to new JS Temporal
  // Ensure all documents have lockedByCheck with false fallback in case attribute does not exist
  const result: IInternalFormsData = {
    name: report.name || report.reportName || "",
    description: report.description || "",
    createdAt: report.createdAt || null,
    updatedAt: report.updatedAt || null,
    lockedByCheckd: report.lockedByCheckd || false,
    isDraft: report.isDraft || false,
    isStandard: report.isStandard || false,
    archived: report.archived || false,
    disabled: report.disabled || false,
    data: [
      ...transformElements(JSON.parse(report.headerTemplate || "[]")),
      ...transformElements(JSON.parse(report.detailTemplate || "[]")),
    ],
  }

  return result
}
