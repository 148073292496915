import { Component, OnInit } from "@angular/core"
import { CloudFunctionsService } from "@services"
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog"

@Component({
  selector: "checkd-template-update-dialog",
  templateUrl: "./template-update-dialog.component.html",
  styleUrls: ["./template-update-dialog.component.scss"],
})
export class TemplateUpdateDialogComponent implements OnInit {
  state: "working" | "done" | "errored" = "working"
  errorMsg = "An error has occurred"

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig, private cfService: CloudFunctionsService) {}

  async ngOnInit() {
    const templateUid = this.config.data.templateUid

    try {
      await this.cfService.updateFormsTemplate(templateUid)
      this.state = "done"
    } catch (e: any) {
      if (e.message) {
        this.errorMsg += `: ${e.message}`
      }

      this.state = "errored"
    }
  }

  submitHandler() {
    this.ref.close()
  }
}
