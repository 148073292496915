import { ChangeDetectionStrategy, Component, Input, OnDestroy } from "@angular/core"
import { combineLatest, firstValueFrom, of, ReplaySubject, switchMap } from "rxjs"
import { distinctUntilChanged, map } from "rxjs/operators"
import { ChannelService } from "stream-chat-angular"
import { AdminService, FieldChatViewService, UserService } from "../../services"

@Component({
  selector: "field-chat-member-card",
  templateUrl: "./member-card.component.html",
  styleUrls: ["./member-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberCardComponent implements OnDestroy {
  @Input() name: string | undefined = ""
  @Input() image: string | undefined = ""
  @Input() set userUid(value: string) {
    this._userUidSubj$.next(value)
  }

  private readonly _userUidSubj$ = new ReplaySubject<string>(1)
  private readonly _userUid$ = this._userUidSubj$.pipe(distinctUntilChanged())

  constructor(
    private fieldChatViewService: FieldChatViewService,
    private userService: UserService,
    private userManagementService: AdminService,
    private channelService: ChannelService
  ) {}

  private readonly isCurrentUser$ = combineLatest([this._userUid$, this.userService.currentUser$]).pipe(
    map(([userUid, currentUser]) => userUid === currentUser.uid)
  )

  public readonly currentUserCanRemoveThisUser$ = this.isCurrentUser$.pipe(
    switchMap((isCurrentUser) => (isCurrentUser ? of(true) : this.fieldChatViewService.currentUserCanEditRooms$))
  )

  ngOnDestroy() {
    this._userUidSubj$.complete()
  }

  async onRemoveMemberClicked() {
    // Did user click to remove him/herself?
    const [currentUser, memberUid, activeChannel] = await Promise.all([
      firstValueFrom(this.userService.currentUser$),
      firstValueFrom(this._userUid$),
      firstValueFrom(this.channelService.activeChannel$),
    ])
    if (activeChannel == null) {
      return console.error("Could not fetch current active channel")
    }

    const isRemovingSelf = currentUser.uid === memberUid

    let text = "Are you sure you want to leave this channel?"
    if (!isRemovingSelf) {
      const name = this.name ?? "this user"
      text = `Are you sure you want to remove ${name} from this chatroom?`
    }

    await this.userManagementService.removeUserFromChatRoom(text, memberUid, activeChannel)
  }
}
