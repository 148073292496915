import { ElementOptionCode, TemplateElement, TemplateElementType } from "./template-element"

class FormsItemsElement extends TemplateElement {
  override name: string = ""
  override icon?: string = "forms:item-element"
  override type: TemplateElementType = TemplateElementType.FORMS_ITEMS
  override typeAlias: string = "Item element"
  override value = ""
  override prefill = {
    name: "",
    description: "",
    tags: [] as string[],
  }
  override children: any[] = []
  override options = [
    {
      id: 1,
      name: "Include title in PDF",
      type: "checkbox",
      code: ElementOptionCode.INCLUDE_TITLE_IN_PDF,
      value: false,
    },
  ]
}

export { FormsItemsElement }
