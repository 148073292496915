import { ElementOptionCode, TemplateElement, TemplateElementType } from "./template-element"

class SignatureElement extends TemplateElement {
  override name: string = ""
  override icon = "forms:signature"
  override type: TemplateElementType = TemplateElementType.SIGNATURE
  override typeAlias: string = "Signature"
  override value = ""
  override options = [
    {
      id: 1,
      name: "Quarter",
      type: "checkbox",
      code: ElementOptionCode.QUARTER,
      value: false,
    },
    {
      id: 2,
      name: "Half",
      type: "checkbox",
      code: ElementOptionCode.HALF,
      value: false,
    },
    {
      id: 3,
      name: "Required",
      type: "checkbox",
      code: ElementOptionCode.REQUIRED,
      value: false,
    },
  ]
}

export { SignatureElement }
