import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { ConfirmDialogComponent } from "@dialogs"
import { IMenuOption, Item, ItemMenuActions, Person, Project, Role } from "@models/common"
import { ItemService } from "@services"
import { take } from "rxjs/operators"

@Component({
  selector: "item-contextmenu",
  templateUrl: "./item-contextmenu.component.html",
  styleUrls: ["./item-contextmenu.component.scss"],
})
export class ItemContextmenuComponent implements OnInit {
  get hasOptions() {
    return this.menuOptions.length > 0
  }
  @Input() item: Item
  @Input() project: Project
  @Input() currentUser: Person
  @Input() userProjectRole: Role
  @Input() restrictedItemMenuActions: ItemMenuActions[] = []
  @Input() additionalItemMenuActions: ItemMenuActions[] = []
  @Input() isProjectLockedOrArchived: boolean

  @Output() menuOptionSelected = new EventEmitter()

  menuOptions: IMenuOption[] = []

  constructor(public dialog: MatDialog, private itemService: ItemService) {}

  ngOnInit() {
    this.menuOptions = this.itemService
      .getMenuOptions(this.item, this.item.creatorName, this.userProjectRole, this.currentUser, this.additionalItemMenuActions)
      // @ts-ignore
      .filter((menuOption) => this.restrictedItemMenuActions.indexOf(menuOption.action) < 0)
  }

  menuClicked(action: ItemMenuActions, confirmationDialog?: any) {
    if (confirmationDialog) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: { title: confirmationDialog.title, content: confirmationDialog.content },
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe((data) => {
          if (data == true) {
            this.emitMenuSelection(action)
          }
        })
    } else {
      this.emitMenuSelection(action)
    }
  }

  private emitMenuSelection(action: ItemMenuActions) {
    this.menuOptionSelected.emit({ item: this.item, action })
  }
}
