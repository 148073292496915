import { Component, OnInit } from "@angular/core"

import { UserService, SnackbarService } from "@services"

@Component({
  selector: "checkd-password-recovery-view",
  templateUrl: "./password-recovery-view.component.html",
  styleUrls: ["./password-recovery-view.component.scss"],
})
export class PasswordRecoveryViewComponent implements OnInit {
  constructor(private userService: UserService, private snackbarService: SnackbarService) {}

  ngOnInit() {}

  onSubmit(email: string) {
    this.userService
      .sendPasswordResetEmail(email)
      .then((_) => {
        this.snackbarService.showMessage(`Password reset email sent to ${email}!`)
      })
      .catch((err) => {
        this.snackbarService.showMessage(`ERROR: ${err}`)
      })
  }
}
