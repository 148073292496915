import { Component, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { tap } from "rxjs/operators"
import { FormsLibraryViewService } from "../../../../services/forms-library-view.service"

@Component({
  selector: "checkd-forms-library-search-bar",
  templateUrl: "./forms-library-search-bar.component.html",
  styleUrls: ["./forms-library-search-bar.component.scss"],
})
export class FormsLibrarySearchBarComponent implements OnInit, OnDestroy {
  templatesAreLoading = true
  private subscriptions = new Subscription()

  constructor(public flViewService: FormsLibraryViewService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.flViewService.availableTemplatesFiltered$.pipe(tap((data) => (this.templatesAreLoading = data.isLoading))).subscribe()
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  setSearchTextAndNavigateToBrowseAllTemplates(searchText: string) {
    this.flViewService.setSearchbarText(searchText)
    this.flViewService.displayedComponent = "browse_all_templates"
  }
}
