import { Component, Input, OnInit } from "@angular/core"
import { Person } from "@models/common"
import { IFormsLibraryMemberCompanyEmailInvitation } from "@models/common/forms-library-member-company-invitation.interface"

@Component({
  selector: "checkd-not-signed-in",
  templateUrl: "./not-signed-in.component.html",
  styleUrls: ["./not-signed-in.component.scss"],
})
export class NotSignedInComponent implements OnInit {
  @Input() invitation!: IFormsLibraryMemberCompanyEmailInvitation
  @Input() targetUser!: Person | null

  constructor() {}

  ngOnInit(): void {}
}
