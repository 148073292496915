<label>{{element.title}}</label>
<div fxLayout="row" matTooltip="You can't edit this element! The report is locked" [matTooltipDisabled]="!readOnly"
  fxLayoutGap="8px">
  <mat-icon class="calendar-icon" [owlDateTimeTrigger]="dt1" [disabled]="readOnly">today</mat-icon>
  <input
    fxFlex
    [owlDateTime]="dt1"
    [(ngModel)]="date"
    [owlDateTimeTrigger]="dt1"
    [placeholder]="title || ''"
    [disabled]="readOnly"
    [readOnly]="true">
  <owl-date-time [pickerType]="'calendar'" [pickerMode]="'dialog'" [disabled]="readOnly" #dt1></owl-date-time>
</div>
