<h1 mat-dialog-title>This report can be filled out anonymously</h1>

<mat-dialog-content>
  <!-- Creating a custom actions section because I couldn't get mat-dialog-actions to play nice with flexbox -->
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between stretch" fxLayoutAlign.lt-sm="begin center" fxLayoutGap="20px">
    <div fxLayout="column" fxFlex="50%" fxLayoutAlign="space-between begin">
      <p fxShow fxHide.xs>
        Choose <strong>Fill out as an anonymous user</strong> to prevent any personal information from being stored for this report.
      </p>

      <div style="max-width: 99%" fxLayout="row" fxLayoutAlign="begin center">
        <mat-icon>person_off</mat-icon>
        <button style="max-width: 99%" mat-stroked-button (click)="dialogRef.close(true)">
          <strong style="overflow: hidden; text-overflow: ellipsis">Fill out as an anonymous user</strong>
        </button>
      </div>
    </div>

    <div fxLayout="column" fxFlex="50%" fxLayoutAlign="space-between begin">
      <p fxShow fxHide.xs>
        Choose <strong>Fill out as {{ data?.creator?.name || "yourself" }}</strong> to add personal information to this report.
      </p>

      <div style="max-width: 99%" fxLayout="row" fxLayoutAlign="begin center">
        <ngx-avatars
          *ngIf="data?.creator?.image || data?.creator?.name"
          [src]="data?.creator?.image"
          [name]="data?.creator?.name"
          size="23"
        >
        </ngx-avatars>
        <button mat-stroked-button (click)="dialogRef.close(false)" style="overflow: hidden; text-overflow: ellipsis">
          <strong fxHide fxShow.gt-xs style="overflow: hidden; text-overflow: ellipsis"
            >Fill out as {{ data?.creator?.name || "yourself" }}</strong
          >
          <strong fxHide fxShow.lt-sm style="overflow: hidden; text-overflow: ellipsis">Fill out as yourself</strong>
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
