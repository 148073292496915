import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { CheckdStatusFilterComponent, defaultStatusFilter } from "@checkd-ui"
import { ExportGenerationData, ExportType, Item, Person, Project, WorkflowStatusFilter } from "@models/common"
import { CloudFunctionsService, FileHandlerService, ItemFilterService, SnackbarService, UserService } from "@services"
import { uniquifyAndSort } from "@services/utilities"
import { FilterableItem } from "app/dashboard/project/models"
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs"
import { map, switchMap } from "rxjs/operators"
import { ItemDetailsDialogComponent } from "../item-details-dialog/item-details-dialog.component"
import { ItemListComponent } from "../item-list/item-list.component"
import { DialogService } from "../../dialogs/dialog.service"
import { CsvExportDialogComponent } from "../../project/dialogs/csv-export-dialog/csv-export-dialog.component"

@Component({
  selector: "my-items",
  templateUrl: "./my-items.component.html",
  styleUrls: ["./my-items.component.scss"],
})
export class MyItemsComponent implements OnInit, OnDestroy {
  @ViewChild("statusFilter", { static: true }) statusFilter: CheckdStatusFilterComponent
  @ViewChild("checkdItemList", { static: true }) itemList: ItemListComponent

  filterableItems: FilterableItem[]
  filteredItems: Item[]

  public get itemsCount() {
    return (this.filteredItems || []).length
  }

  subscriptions: Subscription[] = []
  currentUser$: Observable<Person>
  currentUserItems$: Observable<Item[]>

  userProjects$: Observable<Project[]>

  titleValues: string[] = []
  tagValues: string[] = []
  creatorValues: string[] = []
  assignerValues: string[] = []
  assigneeValues: string[] = []
  companyValues: string[] = []
  updatedValues: any[] = []
  drawingValues: string[] = []
  projectValues: string[] = []

  itemListFilteredDrawingItems: Item[] = []
  csvExportInProgress: boolean = false

  statusFilter$ = new BehaviorSubject(defaultStatusFilter)

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private itemFilterService: ItemFilterService,
    private cloudFunctionsService: CloudFunctionsService,
    private snackbarService: SnackbarService,
    private fileHandlerService: FileHandlerService,
    private dialogService: DialogService
  ) {}

  public isItemListReady: boolean = false

  ngOnInit() {
    this.setupListeners()
    this.setupSubscriptions()
  }

  setupListeners() {
    this.currentUser$ = this.userService.listenToCurrentUser()
    this.currentUserItems$ = this.userService.currentUserItems$

    this.userProjects$ = this.currentUser$.pipe(
      switchMap((currentUser) => this.userService.listenToProjects(currentUser)),
      map((projects) => projects.filter((project) => !project.archived && !project.lockedByCheckd))
    )
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.userProjects$.subscribe((projects) => {
        for (const project of projects) {
          this.tagValues = this.tagValues.concat(...uniquifyAndSort(project.aggregateData.itemTags || [], project.tags || []))
          this.assignerValues = this.assignerValues.concat(...(project.aggregateData.taskAssignerNames || []))
          this.companyValues = this.companyValues.concat(...(project.aggregateData.taskAssignerCompanyNames || []))
          this.drawingValues = this.drawingValues.concat(...(project.aggregateData.drawingNames || []))
          this.assigneeValues = this.assigneeValues.concat(...(project.aggregateData.taskAssigneeNames || []))
        }

        this.projectValues = uniquifyAndSort(projects.map((project) => project.name).filter((it) => it))
        this.creatorValues = uniquifyAndSort(
          // @ts-ignore
          [].concat(...projects.map((project) => project.aggregateData.itemCreatorNames)).filter((it) => it)
        )
        this.tagValues = uniquifyAndSort(this.tagValues)
        this.assignerValues = uniquifyAndSort(this.assignerValues)
        this.companyValues = uniquifyAndSort(this.companyValues)
        this.drawingValues = uniquifyAndSort(this.drawingValues)
        this.assigneeValues = uniquifyAndSort(this.assigneeValues)
      }),

      combineLatest(this.currentUserItems$, this.statusFilter$).subscribe((it) => {
        this.filteredItems = (it[0] || []).filter((item) => this.itemFilterService.hasIncludedStatus(item, it[1]))
      }),

      this.currentUserItems$.subscribe((items) => {
        this.isItemListReady = true
      }),
    ]
  }

  async exportToCsv() {
    const csvColumns: string[] = await this.dialogService.openDialog(CsvExportDialogComponent, {})
    // csvColumns being null indicates that the user either clicked "cancel" or outside of the dialog.
    // In that case, we don't proceed.
    if (csvColumns == null) {
      return
    }

    const exportGenerationData: ExportGenerationData = {
      name: "checkd_csv_export.csv",
      exportType: ExportType.ITEMS_CSV,
      items: this.itemListFilteredDrawingItems.map((item) => item.uid),
      csvColumns,
    }

    this.csvExportInProgress = true

    try {
      const result = await this.cloudFunctionsService.generateCSVExport(exportGenerationData)
      this.csvExportInProgress = false
      this.fileHandlerService.saveCsv(exportGenerationData.name, result.data)
    } catch (err) {
      this.csvExportInProgress = false
      this.snackbarService.showMessage(err.message)
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  itemSelected(item: Item) {
    const availableItemActions = ["UPLOAD_IMAGE", "EDIT_TAGS", "SEND_COMMENT"]

    const dialogRef = this.dialog.open(ItemDetailsDialogComponent, {
      panelClass: "no-padding-dialog",
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "60%",
      width: "100%",
      position: {
        bottom: "0px",
        left: "0px",
      },
      autoFocus: false,
      data: {
        item,
        availableItemActions,
        withCarousel: true,
      },
    })
  }

  ///////////////////////////////////////////////////////////////////////////////
  // ITEM FILTERING
  ///////////////////////////////////////////////////////////////////////////////

  resetFilters() {
    this.statusFilter.clear()
    this.itemList.clear()
  }

  onStatusFilterChange(filter: WorkflowStatusFilter) {
    this.statusFilter$.next(filter)
  }

  onItemsFiltered(items: Item[]) {
    this.itemListFilteredDrawingItems = items
  }

  noItemsTitle = "No items matching your search or filter"
  noItemsDescription = "Try using fewer words and letters, or search using the table columns."
  noItemsImgSrc = "./assets/images/ilustrations/no-found-search.svg"
}
