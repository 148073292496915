import { Injectable } from "@angular/core"
import { MissingFeatureOverlayService } from "./missing-feature-overlay.service"
import { CompanyFeatures } from "@models/common/company.interface"

// TODO We need to generalize our feature checks over time and move them to this service

@Injectable({
  providedIn: "root",
})
export class FeatureCheckerService {
  constructor(private missingFeatureOverlayService: MissingFeatureOverlayService) {}

  public displayMissingFeatureOverlay(feature: CompanyFeatures, redirectUrl?: string) {
    this.missingFeatureOverlayService.open({
      data: {
        feature,
        redirectUrl,
        buttonText: "Chat with us to unlock",
      },
    })
  }
}
