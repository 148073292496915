<div class="settings-view" fxLayout="column" fxLayoutAlign="space-between">
  <div class="settings-header" fxLayout="row" fxLayoutAlign="space-between">
    <span fxFlexAlign="start" class="settings-header-title">{{ title }}</span>

    <button *ngIf="!readonly && !editMode" mat-icon-button matTooltip="Edit" (click)="setEditMode(true)" fxFlexAlign="end">
      <mat-icon svgIcon="settings_edit_off"></mat-icon>
    </button>

    <button *ngIf="!readonly && editMode" mat-icon-button matTooltip="Cancel" (click)="setEditMode(false)" fxFlexAlign="end">
      <mat-icon svgIcon="settings_edit_on"></mat-icon>
    </button>
  </div>

  <div class="settings-content" fxLayout="column" fxLayoutAlign="space-between">
    <ng-content></ng-content>
  </div>

  <div *ngIf="!readonly" class="settings-footer" fxLayout="row" fxLayoutAlign="center center">
    <button class="button button--primary" [disabled]="saveButtonDisabled" *ngIf="editMode" (click)="save()">Save</button>
  </div>
</div>
