<p-card header="Sign up">
  <div class="content" *ngIf="signUpForm">
    <span class="text-center inline-block w-full">
      To get started using Forms Templates from <strong>{{ invite.invitedByPublisherCompany.name }}</strong> you need to create a
      <strong>Next Field</strong> account
    </span>

    <form id="sign-up-form" [formGroup]="signUpForm" (ngSubmit)="handleSubmit()">
      <h5 class="mb-2">E-mail</h5>
      <input type="email" pInputText class="w-full" formControlName="email" />

      <h5 class="mb-2">Your company name</h5>
      <input type="text" pInputText class="w-full" formControlName="companyName" />

      <h5 class="mb-2">Your name</h5>
      <input type="text" pInputText class="w-full" formControlName="name" />

      <h5 class="mb-2">Password</h5>
      <input type="password" pInputText class="w-full mb-2" formControlName="password1" />
      <input type="password" pInputText class="w-full" formControlName="password2" />
    </form>
  </div>

  <ng-template pTemplate="footer">
    <div *ngIf="signUpForm" class="w-full flex justify-content-end">
      <button pButton form="sign-up-form" type="submit" label="Sign up" [disabled]="signUpInProgress || signUpForm!.invalid"></button>
    </div>
  </ng-template>
</p-card>

<div class="flex justify-content-center">
  <p class="footer">
    By signing in, you agree to Next One Technology's
    <a href="https://www.checkd.it/terms" target="_blank" rel="noreferrer noopener"><b>Terms and conditions</b></a>
    &amp;
    <a href="https://www.checkd.it/personvern-sikkerhet" target="_blank" rel="noreferrer noopener"><b>privacy policy</b></a>
  </p>
</div>
<p-toast></p-toast>
