import { WorkflowStates } from "@models/common/workflow.interface"
import { Item } from "./item"
import { convertWorkflowStateToStatus } from "./workflow"

export enum SortingType {
  NUMBER,
  STATUS,
}

export enum SortingDirection {
  ASC,
  DESC,
}

export interface SortingConfig {
  sortingType: SortingType
  sortingDirection: SortingDirection
}

export const SORT_ITEM_STATUS_RANK = {
  none: 0,
  OPEN: 1,
  DELEGATED: 2,
  INPROGRESS: 3,
  FIXED: 4,
  CLOSED: 5,
}

export class ItemSorter {
  protected static statusRank = SORT_ITEM_STATUS_RANK

  public static sortByNumber(items: Item[], sortingDirection: SortingDirection): Item[] {
    return items.sort((a, b) => {
      switch (sortingDirection) {
        case SortingDirection.ASC:
          return (a.number || 0) - (b.number || 0)
        case SortingDirection.DESC:
          return (b.number || 0) - (a.number || 0)
      }
    })
  }

  public static sortByStatus(items: Item[], sortingDirection: SortingDirection): Item[] {
    return items.sort((a, b) => {
      switch (sortingDirection) {
        case SortingDirection.ASC: {
          return (
            // @ts-ignore
            this.statusRank[convertWorkflowStateToStatus(a.status) || "none"] -
            // @ts-ignore
            this.statusRank[convertWorkflowStateToStatus(b.status) || "none"]
          )
        }
        case SortingDirection.DESC: {
          return (
            // @ts-ignore
            this.statusRank[convertWorkflowStateToStatus(b.status) || "none"] -
            // @ts-ignore
            this.statusRank[convertWorkflowStateToStatus(a.status) || "none"]
          )
        }
      }
    })
  }

  public static sortBy(items: Item[], sortingConfig: SortingConfig): Item[] {
    switch (sortingConfig.sortingType) {
      case SortingType.NUMBER: {
        return this.sortByNumber(items, sortingConfig.sortingDirection)
      }
      case SortingType.STATUS: {
        return this.sortByStatus(items, sortingConfig.sortingDirection)
      }
      default: {
        return items
      }
    }
  }
}
