import { Component, Inject, Output, EventEmitter } from "@angular/core"

@Component({
  selector: "app-forgot-password-dialog",
  templateUrl: "./forgot-password-dialog.component.html",
  styleUrls: ["./forgot-password-dialog.component.scss"],
})
export class ForgotPasswordDialogComponent {
  @Output() onEmailEntered = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  onKeyupEnter(email: string) {}
}
