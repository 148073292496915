<button
  [matMenuTriggerFor]="columnSelectionMenu"
  mat-stroked-button>
  COLUMNS
  <mat-icon>arrow_drop_down</mat-icon>
</button>

<mat-menu #columnSelectionMenu="matMenu">
  <!-- $event.stopPropagation() prevents the menu from closing when clicking inside the menu -->
  <div class="selection-list" cdkDropList (cdkDropListDropped)="drop($event)" (click)="$event.stopPropagation()">
    <ng-container *ngFor="let column of columns; let i = index">
        <div class="column-selection-item" cdkDrag>
          <mat-checkbox [checked]="column.checked" (change)="checkBoxClicked(i)"></mat-checkbox>
          <span class="truncate">{{column.column.displayName || column.column.columnName}}</span>
          <mat-icon>drag_handle</mat-icon>
        </div>
    </ng-container>
  </div>
</mat-menu>
