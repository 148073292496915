<div fxLayout="row" class="action-bar" fxLayoutAlign="space-evenly">
  <div fxLayout="column" fxLayoutAlign="start center">
    <button mat-icon-button (click)="onShareLink()">
      <mat-icon class="share-icon" svgIcon="share-outline"></mat-icon>
    </button>
    <span style="color: white; font-size: 13px; margin-top: -2px">Share</span>
  </div>
  <div fxLayout="column" fxLayoutAlign="start center">
    <button mat-icon-button style="color: white" (click)="onPreviewPdf()">
      <mat-icon class="pdf-icon" svgIcon="download-pdf-outline"></mat-icon>
    </button>
    <span style="color: white; font-size: 13px; margin-top: -2px">Download</span>
  </div>
  <div *ngIf="hasReportLockFeature && !report?.archived && report?.status !== 'CLOSED'" fxLayout="column" fxLayoutAlign="start center">
    <button [disabled]="!canLockAndUnlockReport" mat-icon-button (click)="onLockReport()">
      <mat-icon *ngIf="report?.readOnly" class="lock-icon" svgIcon="locked-outline"></mat-icon>
      <mat-icon *ngIf="!report?.readOnly" class="unlock-icon" svgIcon="unlocked-outline"></mat-icon>
    </button>
    <span style="color: white; font-size: 13px; margin-top: -2px">{{ report?.readOnly == false ? "Lock Form" : "Unlock Form" }}</span>
  </div>
  <div fxLayoutAlign="center">
    <div
      *ngIf="isFormChanged"
      fxLayout="column"
      fxLayoutAlign="start center"
      style="background: #f3eeec; width: 53px; height: 53px; border-radius: 8.3px; margin-top: 5px"
    >
      <button mat-icon-button style="color: white" (click)="onSaveForm()">
        <mat-icon class="save-icon" svgIcon="blue-circle-filled-esclamation"></mat-icon>
      </button>
      <span style="color: #415bd8; font-size: 13px; margin-top: -7px">Save</span>
    </div>
    <div *ngIf="!isFormChanged" fxLayout="column" fxLayoutAlign="start center">
      <button disabled mat-icon-button style="color: white"><mat-icon class="saved-icon" svgIcon="checkmark-filled"></mat-icon></button>
      <span style="color: white; font-size: 13px; margin-top: -2px">Saved</span>
    </div>
  </div>
</div>
