import { DrawingData } from "@models/common/drawing.interface"
import { ProjectData } from "@models/common/project.interface"
import { ReportStatus } from "./workflow.interface"
import { BaseModel } from "./base-model"
import { Drawing } from "./drawing"
import { Image } from "./image"
import { Item } from "./item"
import { Person } from "./person"
import { Project } from "./project"
import { Relation } from "./relation"
import { Task } from "./task"

export enum ReportType {
  FIELD = "FIELD",
  LEGACY = "LEGACY",
  OPEN = "OPEN",
  NONE = "NONE",
}

export interface ReportGenerationSettings {
  projectName: boolean
  drawing: boolean
  drawingName: boolean
  itemTitle: boolean
  itemDescription: boolean
  itemTags: boolean
  taskStatus: boolean
  creator: boolean
  assignee: boolean
  collaborators: boolean
  createdDate: boolean
  modifiedDate: boolean
  company: boolean
  dueDate: boolean
  qrCode: boolean
  pageNumber?: boolean
}

export interface ReportGenerationData {
  name: string
  description: string
  projectUid?: string
  drawingUid?: string
  creatorUid?: string
  reportType: string
  items: string[]
  settings: ReportGenerationSettings
}

// TODO Create proper interface
export interface ReportData {
  uid?: string
  name?: string
  number?: number
  description?: string
  projectUid?: string
  drawingUid?: string
  creatorUid?: string
  reportType?: string
  settings?: ReportGenerationSettings
  createdDate?: number
  reports?: ReportData[]
  readOnly?: boolean
  logo?: string
  [key: string]: any
}

export interface TaskReportData extends ReportData {
  status: string
  assignerName: string
  assigneeName: string
  dueDate: string
  number?: number
}

export interface ItemReportData extends ReportData {
  name: string
  number?: number
  creatorName?: string
  description: string
  tags: string[]
  positionX?: number
  positionY?: number
  task?: TaskReportData
  images?: any
  status?: string
}

export interface DrawingReportData extends ReportData {
  name: string
  reportType: string
  drawing: DrawingData
  project: ProjectData
  items: ItemReportData[]
  [key: string]: any
}

export class Report extends BaseModel<ReportData> {
  public static override COLLECTION: string = "reports"

  get items() {
    return this.data["items"] || []
  }
  get name() {
    return this.data.name != null ? this.data.name : ""
  }
  get description() {
    return this.data.description
  }
  get createdDate() {
    return this.data.createdDate || null
  }
  get projectUid() {
    return this.data.projectUid || null
  }
  get drawingUid() {
    return this.data.drawingUid || null
  }
  get settings() {
    return this.data.settings
  }
  get signatures() {
    return this.data["signatures"] || []
  }
  get totalItems(): number {
    if (this.data["reportGenerationData"] && this.data["reportGenerationData"].settings && this.data["reportGenerationData"].items) {
      return this.data["reportGenerationData"].items.length
    } else {
      return 0
    }
  }

  get status() {
    return this.data["status"] || ReportStatus.OPEN
  }

  get reportType() {
    return this.data && this.data.reportType ? this.data.reportType : "NONE"
  }
  get urlPath() {
    return this.reportType === "FIELD" ? `fieldReports/${this.uid}` : `reports/${this.uid}`
  }
  get creatorUid() {
    return this.aggregateData["creatorUid"] || ""
  }
  get number() {
    return this.data.number || null
  }
  get logo() {
    return this.data.logo || ""
  }
  get templatePublicVersion() {
    return this.data["templatePublicVersion"] || ""
  }

  public static get(uid: string): Promise<Report> {
    return Report.doc(uid)
      .get()
      .then((snapshot) => {
        if (!snapshot.exists) throw new Error(`Report ${uid} not found`)
        return new Report(snapshot.data() as ReportData, snapshot.id, snapshot.ref)
      })
  }

  public static create(data: ReportData): Promise<Report> {
    return Report.db
      .collection(Report.COLLECTION)
      .add(data)
      .then((docRef) => docRef.get())
      .then((docSnapshot) => new Report(docSnapshot.data() as ReportData, docSnapshot.id, docSnapshot.ref))
  }

  public static createItemReportData(item: Item): Promise<ItemReportData> {
    return item.getTask().then((task) => {
      let itemReportData = Object.assign({}, item.data, {
        name: item.name != null ? item.name : "",
        description: item.description != null ? item.description : "",
        status: item.status || "OPEN",
        tags: item.tags,
        task: null,
        positionX: item.positionX || null,
        positionY: item.positionY || null,
        number: item.data.number || null,
      }) as any

      return Report.createTaskReportData(task)
        .then((taskReportData) => {
          itemReportData.task = taskReportData
          return itemReportData
        })
        .then((_) =>
          item
            .getImages()
            .then((images) => images.map((image) => Report.createImageReportData(image)))
            .then((imageData) => {
              itemReportData.images = imageData
              return itemReportData
            })
        )
    })
  }

  public static createImageReportData(image: Image) {
    return image.data
  }

  public static createTaskReportData(task: Task): Promise<TaskReportData> {
    // TODO add createdAt/updatedAt
    let taskReportData: TaskReportData = {
      status: "",
      assignerName: "",
      assigneeName: "",
      dueDate: "",
    }
    if (task != null) {
      taskReportData = Object.assign(taskReportData, task.data)

      // TODO add company information

      return task
        .getAssignerName()
        .then((assignerName) => {
          return task.getAssigneeName().then((assigneeName) => {
            // @ts-ignore
            taskReportData.assignerName = assignerName || null
            // @ts-ignore
            taskReportData.assigneeName = assigneeName || null
            // @ts-ignore
            taskReportData.status = task.status || null
            taskReportData.dueDate = task.dueDate || null
          })
        })
        .then((_) => taskReportData)
    }
    return Promise.resolve(taskReportData)
  }

  static createProjectReportRelations(project: Project, report: Report) {
    return project
      .add(report, [Relation.LABELS.CONCERNED_BY])
      .then((rel1) => report.add(project, [Relation.LABELS.CONCERNS]))
      .then((_) => report)
  }

  static createCreatorReportRelations(creator: Person, report: Report) {
    return creator
      .add(report, [Relation.LABELS.CREATOR])
      .then((_) => report.add(creator, [Relation.LABELS.CREATED_BY]))
      .then((_) => report)
  }

  static createDrawingReportRelations(drawing: Drawing, report: Report) {
    return drawing
      .add(report, [Relation.LABELS.CONCERNED_BY])
      .then((_) => report.add(drawing, [Relation.LABELS.CONCERNS]))
      .then((_) => report)
  }
}
