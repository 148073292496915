<div class="content p-2">
  <p-card *ngIf="{ memberData: memberData$ | async } as subs" styleClass="card-container">
    <ng-template pTemplate="title">
      <div class="subscriber">
        <div class="subscriber__text-container">
          <h1 class="subscriber__title">Subscriber list</h1>
          <p class="subscriber__paragraph">
            Manage your subscriber list by adding and removing user companies that have access to your subscriber spesific templates.
          </p>
          <div>
            <!--            <p class="subscriber__paragraph">You can also select witch subscriber group a user company is a part of.</p>-->
            <p class="subscriber__paragraph">
              Current subscribers:
              <span class="subscriber__amount-text">{{ subs.memberData[0] ? subs.memberData[0].length : 0 }} companies</span>
            </p>
          </div>
          <div class="subscriber__search-container">
            <i class="subscriber__search-icon pi pi-search"></i>
            <input
              pInputText
              icon="pi pi-plus"
              type="search"
              placeholder="Name of the company"
              class="subscriber__search-input"
              (input)="table.filterGlobal($event.target.value, 'contains')"
            />
          </div>
        </div>
        <button primary-button (click)="inviteDialogVisible = true" [isRounded]="true">
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3910_12557)">
              <path
                d="M24 11.9994C24 12.7667 23.3827 13.384 22.6154 13.384H13.3846V22.6148C13.3846 23.3792 12.7644 24 12 24C11.2356 24 10.6154 23.3821 10.6154 22.6148V13.384H1.38462C0.620192 13.384 0 12.7644 0 12C0 11.2379 0.620192 10.6148 1.38462 10.6148H10.6154V1.38404C10.6154 0.619615 11.2356 0 12 0C12.7644 0 13.3846 0.619615 13.3846 1.38404V10.6148H22.6154C23.3827 10.6148 24 11.2379 24 11.9994Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_3910_12557">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <span> Add a subscriber </span>
        </button>
      </div>
    </ng-template>

    <p-table
      [paginator]="true"
      [rows]="15"
      [rowsPerPageOptions]="[15, 30, 45]"
      [showCurrentPageReport]="true"
      [value]="subs.memberData[0]"
      responsiveLayout="scroll"
      [globalFilterFields]="['name', 'date']"
      #table
      styleClass="subscriber-table"
      currentPageReportTemplate="{first} to {last} of {totalRecords} entries"
    >
      <!--      <ng-template pTemplate="emptyMessage" let-columns="columns">-->
      <!--        <tr>-->
      <!--          <td [attr.colspan]="columns.length">-->
      <!--            -->
      <!--          </td>-->
      <!--        </tr>-->
      <!--      </ng-template>-->
      <ng-template pTemplate="header" let-member>
        <tr>
          <!-- <th>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th> -->
          <th>LOGO</th>
          <th pSortableColumn="name">
            COMPANY NAME
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="date">
            MEMBER SINCE
            <p-sortIcon field="date"></p-sortIcon>
          </th>
          <!-- <th pSortableColumn="added">#ADDED TO COMPANY TEMPLATES<p-sortIcon field="added"></p-sortIcon></th> -->
          <!--            <th pSortableColumn="last">LAST USED<p-sortIcon field="last"></p-sortIcon></th>-->
          <!-- <th pSortableColumn="member-group">MEMBER GROUP<p-sortIcon field="member-group"></p-sortIcon></th> -->
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-member>
        <tr>
          <!-- <td><p-tableCheckbox [value]="member"></p-tableCheckbox></td> -->
          <td>
            <div>
              <img [src]="member.logo" class="subscriber-table__logo" />
            </div>
          </td>
          <td>{{ member.name }}</td>
          <td>{{ subs.memberData[1].get(member.uid)?.invitationAcceptedByUser.acceptDate.toDate() | date }}</td>
          <td>
            <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
            <!--              <button-->
            <!--                type="button"-->
            <!--                pButton-->
            <!--                icon="pi pi-ellipsis-v"-->
            <!--                class="p-button-rounded p-button-text p-button-secondary"-->
            <!--                (click)="menu.toggle($event)"-->
            <!--              ></button>-->
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog header="Invite a company" [(visible)]="inviteDialogVisible" (onShow)="resetForm()">
  <section class="dialog-content">
    Invite a company to see and subscribe to your templates. Enter a company administrator's email below to send an invite
    <form id="email-form" [formGroup]="emailAddressForm" (ngSubmit)="sendInvitationClicked()">
      <div class="p-inputgroup mt-3">
        <span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
        <input type="text" formControlName="email" pInputText placeholder="Email" />
      </div>
    </form>
  </section>
  <ng-template pTemplate="footer">
    <div class="dialog__btn-container">
      <button primary-button [type]="'outline'" type="button" (click)="inviteDialogVisible = false">
        <span>Cancel</span>
      </button>
      <button primary-button form="email-form" type="submit" [disabled]="emailAddressForm.invalid || emailAddressForm.pristine">
        Send invitation
      </button>
    </div>
  </ng-template>
</p-dialog>
