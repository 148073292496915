import { ChangeDetectionStrategy, Component } from "@angular/core"
import { CommonModule } from "@angular/common"
import { EmptyChannelListIconComponent } from "../../icons-components"

@Component({
  selector: "field-chat-lib-empty-channel-list",
  standalone: true,
  imports: [CommonModule, EmptyChannelListIconComponent],
  template: `
    <div class="tw-flex tw-flex-col tw-items-center tw-h-full tw-pt-14">
      <div class="tw-grid tw-place-items-center tw-gap-6">
        <field-chat-lib-empty-channel-list-icon></field-chat-lib-empty-channel-list-icon>
        <h3 class="tw-text-lg tw-font-semibold tw-text-next-navy-500">No channels currently...</h3>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyChannelListComponent {}
