import { Injectable } from "@angular/core"
import { Item, SortingDirection, SortingType } from "@models/common"
import { BehaviorSubject, Observable, Subject } from "rxjs"
import { map, tap } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class FormMessageService {
  // Add message names here and use them for pub and sub instead of
  // magic strings
  public static MSG_TEST = "MSG_TEST"
  public static SIGNATURE_ADDED = "SIGNATURE_ADDED"
  public static IMAGE_ADDED = "IMAGE_ADDED"
  public static CHECKBOX_VALUE_CHANGE = "CHECKBOX_VALUE_CHANGE"
  public static CREATE_ITEM = "CREATE_ITEM"
  public static OPEN_ITEM: "OPEN_ITEM"

  // emitted upon any form value changes
  // TODO compare with original (at form load time) to detect changes
  public static MSG_ELEMENT_VALUE_CHANGED = "MSG_ELEMENT_VALUE_CHANGED"

  // emitted when an object is added to the element 'child' property
  public static MSG_ELEMENT_CHILD_ADDED = "MSG_ELEMENT_CHILD_ADDED"

  // emitted when an object is removed from the element 'child' property
  public static MSG_ELEMENT_CHILD_REMOVED = "MSG_ELEMENT_CHILD_REMOVED"

  // emitted when an object is removed from the element 'child' property
  public static MSG_FORM_ELEMENT_MODIFIED = "MSG_FORM_ELEMENT_MODIFIED"

  // calculate number of filled elements

  public isFieldReport: boolean = false

  public config$ = new BehaviorSubject({
    itemListSort: {
      sortingType: SortingType.NUMBER,
      sortingDirection: SortingDirection.ASC,
    },
    pdfFieldsSettings: {},
    reportSettings: {},
  })

  public reportItems$ = new BehaviorSubject<Item[]>([])
  public reportItemsMap$ = this.reportItems$.pipe(map((items) => Object.assign({}, ...items.map((item) => ({ [item.uid]: item })))))

  private subject = new Subject<any>()

  updateConfig(key: string, val: any) {
    const newConfig = { ...this.config$.getValue(), [key]: val }
    this.config$.next(newConfig)
  }

  sendMessage(name: string, data: any) {
    this.subject.next({ name, data })
  }

  clearMessage() {
    this.sendMessage("NOOP", {})
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable()
  }
}
