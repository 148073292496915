<div *ngIf="showDrawing$ | async" class="drawing-screen">
  <div class="flex justify-content-between mb-2">
    <span class="drawing-title mr-3">{{ drawing.name }}</span>
    <mat-slide-toggle [(ngModel)]="interactiveDrawing"> Interactive drawing </mat-slide-toggle>
  </div>

  <checkd-report-drawing *ngIf="interactiveDrawing; else staticDrawing" [itemReportDataList]="items" [drawingReportData]="drawing">
  </checkd-report-drawing>

  <ng-template #staticDrawing>
    <div *ngIf="drawingUrl; else waitingForDrawingUrl" class="drawing-container">
      <img [src]="drawingUrl" />
    </div>

    <ng-template #waitingForDrawingUrl>
      <div class="drawing-container spinner">
        <mat-spinner diameter="60" mode="indeterminate" *ngIf="!drawingUrl" color="white"></mat-spinner>
      </div>
    </ng-template>
  </ng-template>
</div>

<app-form-element *ngFor="let value of values" [element]="value" [parentElement]="element"></app-form-element>
