<ng-container
  *ngIf="{
    currentUser: currentUser$ | async,
    currentCompany: currentCompany$ | async,
    userCompanyRole: userCompanyRole$ | async,
    userProjectRoles: userProjectRoles$ | async,
    canCreateProject: canCreateProject$ | async,
    userProjects: myProjects$ | async,
    favoriteProjects: userFavoriteProjects$ | async,
    currentProjects: currentProjects$ | async
  } as subs"
>
  <div class="projects-view" id="intercom-projects-view" fxLayout="column" fxLayoutAlign="start center">
    <div class="projects-view__top-bar" fxLayout="column" fxLayoutAlign="start center">
      <div fxLayout fxLayoutGap="12px">
        <mat-icon class="briefcase-icon" [ngClass.lt-md]="'smaller-icon'" svgIcon="project"></mat-icon>
        <h1 class="projects-header checkd-white" [ngClass.lt-md]="'smaller-header'">Projects</h1>
      </div>

      <div fxHide.lt-md class="project-type-selection-row" fxLayout fxLayoutGap="10px">
        <button
          mat-button
          class="project-type-btn"
          (click)="switchProjectType('myProjects')"
          id="intercom-my-projects"
          [class.active-btn]="currentProjectType$.value == 'myProjects'"
        >
          MY PROJECTS
          {{ formattedMyProjectsCount$ | async }}
        </button>

        <button
          mat-button
          class="project-type-btn"
          (click)="switchProjectType('favorites')"
          id="intercom-favorite-projects"
          [class.active-btn]="currentProjectType$.value == 'favorites'"
        >
          FAVORITES
          {{ formattedFavoriteProjectsCount$ | async }}
        </button>

        <button
          mat-button
          class="project-type-btn"
          (click)="switchProjectType('company')"
          [class.active-btn]="currentProjectType$.value == 'company'"
          id="intercom-company-projects"
          [disabled]="!canViewCompanyProjects(subs.userCompanyRole)"
        >
          COMPANY PROJECTS
          {{ formattedCompanyProjectsCount$ | async }}
        </button>

        <button
          mat-button
          class="project-type-btn"
          (click)="switchProjectType('archived')"
          id="intercom-archived-projects"
          [class.active-btn]="currentProjectType$.value == 'archived'"
        >
          ARCHIVED
          {{ formattedArchivedProjectsCount$ | async }}
        </button>
      </div>

      <mat-form-field fxHide.gt-sm>
        <mat-label>Select project type</mat-label>
        <mat-select (selectionChange)="switchProjectType($event.value)">
          <mat-option value="myProjects">MY PROJECTS</mat-option>
          <mat-option value="favorites">FAVORITES</mat-option>
          <mat-option value="company" [disabled]="!canViewCompanyProjects(subs.userCompanyRole)"> COMPANY PROJECTS</mat-option>
          <mat-option value="archived" [disabled]="!canViewCompanyProjects(subs.userCompanyRole)"> ARCHIVED</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="main-content" fxLayout="column" fxLayoutAlign="start">
      <div class="search-and-new-project-container" fxLayout fxLayoutAlign="space-between center">
        <div fxFlex="1 1 30" fxShow fxHide.lt-md></div>

        <div fxFlex="1 1 0" class="search-input-container" fxLayout="row" fxLayoutAlign="center center">
          <input type="text" [(ngModel)]="searchValue" placeholder="Search for projects" size="30" />
          <mat-icon>search</mat-icon>
        </div>

        <div class="new-project-btn-container" fxLayout fxLayoutAlign="end" fxFlex.gt-sm="1 1 30" fxShow>
          <button
            primary-button
            [isRounded]="true"
            [id]="subs.canCreateProject ? 'button-project-new' : 'button-project-new-disabled'"
            class="intercom-tour-new-project-button"
            [disabled]="!subs.canCreateProject"
            (click)="createProject(subs.currentCompany)"
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_3910_12557)">
                <path
                  d="M24 11.9994C24 12.7667 23.3827 13.384 22.6154 13.384H13.3846V22.6148C13.3846 23.3792 12.7644 24 12 24C11.2356 24 10.6154 23.3821 10.6154 22.6148V13.384H1.38462C0.620192 13.384 0 12.7644 0 12C0 11.2379 0.620192 10.6148 1.38462 10.6148H10.6154V1.38404C10.6154 0.619615 11.2356 0 12 0C12.7644 0 13.3846 0.619615 13.3846 1.38404V10.6148H22.6154C23.3827 10.6148 24 11.2379 24 11.9994Z"
                  fill="currentcolor"
                />
              </g>
              <defs>
                <clipPath id="clip0_3910_12557">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>New project</span>
          </button>
        </div>
      </div>

      <div
        *ngIf="subs.userProjects && subs.favoriteProjects != null"
        class="sorting-container"
        fxLayout
        fxLayoutAlign="start center"
        fxLayoutGap.xs="6px"
        fxLayoutGap.sm="22px"
        fxLayoutGap.gt-sm="35px"
      >
        <div class="sort-star-icon-wrapper" fxLayout fxLayoutAlign="center center">
          <mat-icon (click)="selectSortOption('favorite')">
            {{ selectedSortOption == "favorite" ? "star" : "star_outline" }}
          </mat-icon>
        </div>
        <span [class.active]="selectedSortOption == 'name'" (click)="selectSortOption('name')">NAME</span>
        <span [class.active]="selectedSortOption == 'people'" (click)="selectSortOption('people')">PEOPLE</span>
        <span [class.active]="selectedSortOption == 'drawings'" (click)="selectSortOption('drawings')">DRAWINGS</span>
        <span [class.active]="selectedSortOption == 'items'" (click)="selectSortOption('items')">ITEMS</span>
        <span [class.active]="selectedSortOption == 'activeItems'" (click)="selectSortOption('activeItems')">ACTIVE ITEMS</span>
      </div>

      <div class="project-list-container">
        <app-project-list
          [searchValue]="searchValue"
          (projectSelected)="projectSelected($event)"
          (onProjectActionSelected)="handleProjectAction($event, subs.currentUser)"
          [projects]="subs.currentProjects"
          [projectRoles]="subs.userProjectRoles"
          [currentCompany]="subs.currentCompany"
          [currentUser]="subs.currentUser"
          [userCompanyRole]="subs.userCompanyRole"
          [favoriteProjects]="subs.favoriteProjects"
          (favoriteToggled)="favoriteToggled(subs.currentUser, $event)"
          [projectTypeChangedEvent]="projectTypeChanged$.asObservable()"
          [selectedSortOption]="selectedSortOption"
          [finishedLoading]="finishedLoading"
          [emptyStateData]="emptyStateData$ | async"
        >
        </app-project-list>
      </div>
    </div>
  </div>
</ng-container>
