<div fxLayout="row" fxLayoutAlign="start center" class="top-row" *ngIf="showLanguageSelection || title">

  <h1 mat-dialog-title *ngIf="title">{{title}}</h1>

  <ng-container *ngIf="showLanguageSelection">
    <ng-container *ngFor="let entry of data.languageEntries; index as idx">
      <button mat-button (click)="currentLanguageIndex = idx" fxFlexAlign="end"
              [ngClass]="{'active-button': currentLanguageIndex==idx}">
              {{entry.lang_button_label}}
      </button>
    </ng-container>
  </ng-container>
</div>

<mat-dialog-content>
  <p *ngFor="let paragraph of data.languageEntries[currentLanguageIndex].paragraphs">{{paragraph}}</p>
</mat-dialog-content>

<mat-dialog-actions align="end" fxLayoutGap="5px">
  <checkd-button  *ngIf="data.cancelButton" bg="red" mat-dialog-close>{{cancelButtonText}}</checkd-button>
  <checkd-button [mat-dialog-close]="true">{{confirmButtonText}}</checkd-button>
</mat-dialog-actions>
