import { Injectable } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { ItemCreationDialogComponent } from "@dialogs"
import { DrawingItemCreationDialogComponent } from "@drawings"
import { ItemMenuActions } from "@models/common"
import { Drawing } from "@models/common/drawing"
import { GeneralReport } from "@models/common/general-report"
import { Item } from "@models/common/item"
import { Person } from "@models/common/person"
import { Project } from "@models/common/project"
import { Role } from "@models/common/roles-and-permissions"
import { take } from "rxjs/operators"
import { ItemDetailsDialogComponent } from "./item-details-dialog/item-details-dialog.component"
import { DialogService } from "../dialogs/dialog.service"

@Injectable({
  providedIn: "root",
})
export class ItemDialogService {
  constructor(private dialog: MatDialog, public dialogService: DialogService) {}

  async createProjectItem(creator: Person, project: Project, projectMembers: Person[]) {
    return this.dialog
      .open(ItemCreationDialogComponent, {
        width: "400px",
        data: {
          project,
          currentUser: creator,
          projectMembers,
        },
      })
      .componentInstance.onSave.pipe(take(1))
      .toPromise()
  }

  async createDrawingItem(creator: Person, project: Project, drawing: Drawing, projectMembers: Person[]) {
    return this.dialog
      .open(DrawingItemCreationDialogComponent, {
        data: {
          drawing,
          project,
          projectMembers,
          currentUser: creator,
        },
      })
      .componentInstance.onSave.pipe(take(1))
      .toPromise()
  }

  async createReportItem(
    creator: Person,
    project: Project,
    report: GeneralReport,
    projectMembers: Person[],
    prefill: { [p: string]: any }
  ) {
    return this.dialog
      .open(ItemCreationDialogComponent, {
        data: {
          project,
          projectMembers,
          currentUser: creator,
          prefill,
        },
      })
      .componentInstance.onSave.pipe(take(1))
      .toPromise()
  }

  openItem(
    item: Item,
    project: Project,
    projectMembers: Person[],
    userProjectRole: Role,
    restrictedItemMenuActions: ItemMenuActions[] = [],
    withCarousel = true
  ) {
    return this.dialogService.openDialog(ItemDetailsDialogComponent, {
      panelClass: "no-padding-dialog",
      maxWidth: "100vw",
      // maxHeight: '100vh',
      height: "60vh",
      width: "100%",
      position: {
        bottom: "0px",
        left: "0px",
      },
      autoFocus: false,
      data: {
        item,
        project,
        projectMembers,
        userProjectRole,
        restrictedItemMenuActions,
        withCarousel,
      },
    })
  }

  editItemDetails(item: Item, editor: Person, currentProject: Project) {
    return this.dialog
      .open(ItemCreationDialogComponent, {
        width: "400px",
        data: { item, currentUser: editor, project: currentProject },
      })
      .componentInstance.onSave.pipe(take(1))
      .toPromise()
  }
}
