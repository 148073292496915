import { Pipe, PipeTransform } from "@angular/core"
import { convertWorkflowStateToStatus } from "@models/common"

@Pipe({
  name: "itemStatus",
})
export class ItemStatusPipe implements PipeTransform {
  /*
   * Transforms raw item status to item statuses that we use
   *
   * Can take an optional 'humanize' argument to make the statuses human-readable.
   * If given the 'humanize' argument, it will return the status as a title cased human-readable string (ex. INPROGRESS -> In Progress).
   * If you need it to be upper-cased or lower-cased, combine this with those pipes
   *
   * Returns an empty string if `state` is invalid
   *
   * Usage:
   *   item.status | itemStatus
   * Example 1:
   *   {{ 'ACCEPTED' | itemStatus:humanize }}
   *   formats to: 'In Progress'
   * Example 2:
   *   {{ 'ACCEPTED' | itemStatus }}
   *   formats to: 'INPROGRESS'
   */
  transform(state: string, arg?: "humanize"): string {
    const converted = convertWorkflowStateToStatus(state)

    if (!converted) {
      return ""
    }

    if (!arg) {
      return converted
    }

    switch (converted) {
      case "OPEN":
        return "Open"
      case "DELEGATED":
        return "Delegated"
      case "INPROGRESS":
        return "In Progress"
      case "FIXED":
        return "Fixed"
      case "CLOSED":
        return "Closed"
      default:
        return ""
    }
  }
}
