import { Component, OnInit } from "@angular/core"
import { DefaultElementComponent } from "../default-element/default-element.component"

@Component({
  selector: "app-invisible-element",
  templateUrl: "./invisible-element.component.html",
  styleUrls: ["./invisible-element.component.scss"],
})
export class InvisibleElementComponent extends DefaultElementComponent {
  override ngOnInit() {}
}
