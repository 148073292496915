import { Pipe, PipeTransform } from "@angular/core"
import { ReportStatus_FROM_20_DONT_USE } from "../models/report-status.old-dont-use"

@Pipe({
  name: "reportStatus",
})
export class ReportStatusPipe implements PipeTransform {
  transform(value: number): string {
    let statusMessage = ""
    switch (value) {
      case ReportStatus_FROM_20_DONT_USE.Archived:
        statusMessage = "Archived"
        break
      case ReportStatus_FROM_20_DONT_USE.Shared:
        statusMessage = "Shared"
        break
      case ReportStatus_FROM_20_DONT_USE.Handover:
        statusMessage = "Handed Over"
        break
      case ReportStatus_FROM_20_DONT_USE.SendToVerification:
        statusMessage = "Sent For Verification"
        break
      case ReportStatus_FROM_20_DONT_USE.ArchivedAsAccepted:
        statusMessage = "Accepted and Archived"
        break
      case ReportStatus_FROM_20_DONT_USE.Rejected:
        statusMessage = "Rejected"
        break
      case ReportStatus_FROM_20_DONT_USE.Inprogress:
        statusMessage = "In Progress"
        break
      case ReportStatus_FROM_20_DONT_USE.Modified:
        statusMessage = "Modified"
        break
      case undefined:
        statusMessage = "Draft"
    }
    return statusMessage
  }
}
