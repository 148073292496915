import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, Type } from "@angular/core"
import { firstValueFrom } from "rxjs"
import { ChannelService } from "stream-chat-angular"
import { AdminService, FieldChatService, FieldChatViewService, UserService } from "../../services"
import { PenToSquareIconComponent, LeaveRoomIconComponent, TrashIconComponent } from "../../icons-components"

@Component({
  selector: "field-chat-room-details-panel",
  templateUrl: "./room-details-panel.component.html",
  styleUrls: ["./room-details-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomDetailsPanelComponent implements OnInit {
  @Input() isOpen!: boolean
  @Output() isOpenChange = new EventEmitter<boolean>()

  @Output() onEditRoomClicked = new EventEmitter<void>()

  showMembers = false

  penToSquareIcon = PenToSquareIconComponent
  leaveRoomIcon = LeaveRoomIconComponent
  trashIcon = TrashIconComponent

  constructor(
    public fieldChatService: FieldChatService,
    public fieldChatViewService: FieldChatViewService,
    private adminService: AdminService,
    private userService: UserService,
    private channelService: ChannelService
  ) {}

  ngOnInit(): void {}

  toggleIsOpen() {
    this.isOpen = !this.isOpen
    this.isOpenChange.emit(this.isOpen)
  }

  toggleShowMembers() {
    this.showMembers = !this.showMembers
  }

  async onLeaveRoomClicked() {
    // TODO: if user is the only admin in the room, can user leave?
    const text = "Are you sure you want to leave this room?"

    const [currentUser, currentChannel] = await Promise.all([
      firstValueFrom(this.userService.currentUser$),
      firstValueFrom(this.channelService.activeChannel$),
    ])

    if (currentUser == null) {
      return console.error("Could not fetch current user from user service")
    }
    if (currentChannel == null) {
      return console.error("Could not fetch current channel from channel service")
    }

    await this.adminService.removeUserFromChatRoom(text, currentUser.uid, currentChannel)
  }

  async onDeleteChannelClicked() {
    // TODO check for permissions to delete channel
    const channel = await firstValueFrom(this.channelService.activeChannel$)

    if (channel == null) {
      return console.error("Could not fetch the current active channel")
    }

    await this.adminService.deleteChannel(channel)
  }
}
