export class FormElementOption {
  id: number
  code: string
  name: string
  type: string
  value: string | number | boolean

  // Used for compability with the Forms API
  public transformAttributes() {
    let transformed: any = {}

    transformed.id = this.id
    transformed.code = this.code
    transformed.name = this.name
    transformed.type = this.type
    transformed.value = this.value

    return transformed
  }

  static fromJson(option: any) {
    let formElementOption = new FormElementOption()
    formElementOption.id = option.id
    formElementOption.code = option.code
    formElementOption.name = option.name
    formElementOption.type = option.type
    formElementOption.value = option.value
    return formElementOption
  }
}
