import { Injectable } from "@angular/core"
import { LatLng } from "leaflet"
import { DivIcon } from "leaflet"

export interface XYCoord {
  x: number
  y: number
}

export interface XYBounds {
  x0: number
  y0: number
  x1: number
  y1: number
}

@Injectable()
export class LeafletService {
  constructor() {}

  // Returns an object with x and y attributes between 0.0 and 1.0, e.g.:
  //
  // {x: 0.0, y: 0.0} is the upper left corner of the image/map
  // {x: 1.0, y: 1.0} is the bottom right corner of the image/map
  normalizeLatLngCoordinates(position: LatLng, nwBounds: LatLng, seBounds: LatLng) {
    // Get lengths
    const lenX = seBounds.lng - nwBounds.lng
    const lenY = seBounds.lat - nwBounds.lat

    // Get positions
    const posX = position.lng - nwBounds.lng
    const posY = position.lat - nwBounds.lat

    // Get diffs
    const diffX = lenX - posX
    const diffY = lenY - posY

    // Normalize
    const normX = diffX / lenX
    const normY = diffY / lenY

    // Invert
    const invX = 1.0 - normX
    const invY = 1.0 - normY

    return { x: invX, y: invY }
  }

  isNormalizedCoords(coords: XYCoord) {
    return 0.0 <= coords.x && coords.x <= 1.0 && 0.0 <= coords.y && coords.y <= 1.0
  }

  normalizeXYCoordinates(coords: XYCoord, bounds: XYBounds): XYCoord {
    let position = new LatLng(coords.y, coords.x)
    let nwBounds = new LatLng(bounds.y0, bounds.x0)
    let seBounds = new LatLng(bounds.y1, bounds.x1)
    return this.normalizeLatLngCoordinates(position, nwBounds, seBounds)
  }

  denormalizeXYCoordinates(coords: XYCoord, bounds: XYBounds): XYCoord {
    if (!this.isNormalizedCoords(coords))
      console.error(`Normalized Coordinates must be between 0 and 1 (was x: ${coords.x}, y: ${coords.y})`)
    const lenX = bounds.x1 - bounds.x0
    const lenY = bounds.y1 - bounds.y0

    return { x: lenX * coords.x, y: lenY * coords.y }
  }

  statusToIconHtml(status: string, num?: number): DivIcon {
    var numberColorStyle = "item-number-black"
    var numberDigits = ""
    var iconHtml = ""

    if (status == "OPEN") {
      numberColorStyle = "item-number-white"
    }

    if (num) {
      numberDigits = `item-number-${num.toString().length}-digit`
      iconHtml = `<img src="assets/${status.toLowerCase()}.svg"><span class="${numberDigits} ${numberColorStyle}">${num}</span>`
    } else iconHtml = `<img src="assets/${status.toLowerCase()}.svg">`

    return new DivIcon({
      className: "item-div-icon",
      html: iconHtml,
      iconSize: [51, 63],
      iconAnchor: [26, 63],
    })
  }
}
