import { Component, OnInit, Input } from "@angular/core"
import { Image } from "@models/common"
import { FilestackService } from "@services"

@Component({
  selector: "item-image",
  templateUrl: "./item-image.component.html",
  styleUrls: ["./item-image.component.scss"],
  providers: [],
})
export class ItemImageComponent implements OnInit {
  @Input() image: Image

  constructor(private filestackService: FilestackService) {}

  ngOnInit() {}

  get imageUrl() {
    const imageUrl = (this.image || { url: "" }).url
    return imageUrl ? this.filestackService.getExifRotationUrl(this.image.url) : ""
  }

  // NB: ngx-imageviewer supports 'pdf' as well, figure out if we want
  // to support that for item images.
  get fileType() {
    return "image"
  }

  get hasImageName() {
    return this.image != null && this.image.name != null
  }
  get imageName() {
    return this.hasImageName ? this.image.name : ""
  }

  get hasImageDescription() {
    return this.image != null && this.image.description != null
  }
  get imageDescription() {
    return this.hasImageDescription ? this.image.description : ""
  }
}
