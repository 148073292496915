import { Component } from "@angular/core"
import { Router } from "@angular/router"
import { COLLECTIONS, Company, Person, Project, ProjectMenuActions, Role, RoleType } from "@models/common"
import { CompanyService, ProjectService, RoleHandlerService, SnackbarService, UserService } from "@services"
import { BehaviorSubject, combineLatest, Observable, pipe, startWith, Subject } from "rxjs"
import { combineLatestWith, map, shareReplay, tap } from "rxjs/operators"
import { IProjectDuplicationSettings } from "../../../../../functions/src/projects/project-duplication.interface"
import { DialogService } from "../../dialogs/dialog.service"
import { SortOption } from "../../ordering/orderby.pipe"
import { ProjectDuplicationDialogComponent } from "../project-duplication-dialog/project-duplication-dialog.component"

type CurrentProjectType = "myProjects" | "favorites" | "company" | "archived"

@Component({
  selector: "app-projects-view",
  templateUrl: "./projects-view.component.html",
  styleUrls: ["./projects-view.component.scss"],
})
export class ProjectsViewComponent {
  // @ts-ignore
  selectedSortOption?: SortOption = null
  currentProjectType$ = new BehaviorSubject<CurrentProjectType>("myProjects")
  currentUser$: Observable<Person> = this.userService.currentUser$
  currentCompany$: Observable<Company> = this.userService.currentCompany$
  userFavoriteProjects$: Observable<Project[]> = this.userService.currentUserFavoriteProjects$.pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  )

  countPipe = () => pipe(map((data: any[]) => data?.length ?? 0))

  parenthesizeCountPipe = () => pipe(map((count) => `(${count})`))

  readonly userFavoriteProjectsCount$: Observable<number> = this.userFavoriteProjects$.pipe(this.countPipe())

  readonly formattedFavoriteProjectsCount$: Observable<string> = this.userFavoriteProjectsCount$.pipe(this.parenthesizeCountPipe())

  userCompanyRole$: Observable<Role> = this.userService.currentUserCompanyRole$

  myProjects$: Observable<Project[]> = this.userService.currentUserProjects$.pipe(
    map((projects) => projects.filter((project) => !project.archived)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  readonly myProjectsCount$: Observable<number> = this.myProjects$.pipe(this.countPipe())

  readonly formattedMyProjectsCount$: Observable<string> = this.myProjectsCount$.pipe(this.parenthesizeCountPipe())

  archivedUserProjects$: Observable<Project[]> = this.userService.currentUserProjects$.pipe(
    map((projects) => projects.filter((project) => project.archived))
  )

  companyProjects$: Observable<Project[]> = this.userService.currentCompanyProjects$.pipe(
    map((projects) => projects.filter((project) => !project.archived)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  readonly companyProjectsCount$: Observable<number> = this.companyProjects$.pipe(this.countPipe())

  readonly formattedCompanyProjectsCount$: Observable<string> = this.companyProjectsCount$.pipe(this.parenthesizeCountPipe())

  archivedCompanyProjects$: Observable<Project[]> = this.userService.currentCompanyProjects$.pipe(
    map((projects) => projects.filter((project) => project.archived))
  )

  archivedProjects$: Observable<Project[]> = combineLatest([
    this.userCompanyRole$,
    this.archivedUserProjects$,
    this.archivedCompanyProjects$,
  ]).pipe(
    map(([userCompanyRole, archivedUserProjects, archivedCompanyProjects]) => {
      if (userCompanyRole.roleType === RoleType.COMPANY_ADMINISTRATOR) {
        const m = new Map([...archivedUserProjects, ...archivedCompanyProjects].map((p) => [p.uid, p]))

        return Array.from(m.values())
      }

      return archivedUserProjects
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  readonly archivedProjectsCount$: Observable<number> = this.archivedProjects$.pipe(this.countPipe())

  readonly formattedArchivedProjectsCount$: Observable<string> = this.archivedProjectsCount$.pipe(this.parenthesizeCountPipe())

  userProjectRoles$: Observable<{ [projectUid: string]: Role }> = this.userService.currentUserProjectRoles$

  canCreateProject$: Observable<boolean> = combineLatest([this.currentCompany$, this.userCompanyRole$]).pipe(
    map(([currentCompany, userCompanyRole]) => !currentCompany.lockedByCheckd)
  )

  // Used to notify the project list that project type has changed and that we should scroll to the top of the list
  projectTypeChanged$: Subject<void> = new Subject<void>()

  finishedLoading = false
  currentProjects$: Observable<Project[]> = combineLatest([
    this.currentProjectType$,
    this.myProjects$,
    this.archivedProjects$,
    this.companyProjects$,
    this.userFavoriteProjects$,
  ]).pipe(
    tap((_) => (this.finishedLoading = true)),
    map(([currentProjectType, userProjects, archivedProjects, companyProjects, favoriteProjects]) => {
      switch (currentProjectType) {
        case "archived":
          return archivedProjects || []
        case "company":
          return companyProjects || []
        case "favorites":
          return favoriteProjects || []
        case "myProjects":
          return userProjects || []
        default:
          return []
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  readonly emptyStateData$: Observable<{ title: string; description: string; image: string }> = combineLatest([
    this.currentProjects$,
    this.currentProjectType$,
  ]).pipe(
    map(([projects, projectType]) => {
      const hasProjects = (projects?.length ?? 0) > 0
      let title = ""
      let description = ""
      const noProjectsImgSrc = "./assets/images/ilustrations/not-found-project.svg"

      switch (projectType) {
        case "myProjects":
          if (hasProjects) {
            title = "No projects matching your search."
            description = "Try using fewer words and letters, or contact the project owner."
          } else {
            title = "You don't have any projects yet"
            description = "They will appear here when you create them or are added to them"
          }
          break
        case "favorites":
          if (hasProjects) {
            title = "No favorites projects matching your search."
            description = "Try using fewer words and letters, or contact the project owner."
          } else {
            title = "You have not added any favorite projects yet"
            description = "Your favorite projects will appear here when you add them"
          }
          break
        case "company":
          if (hasProjects) {
            title = "No company projects matching your search."
            description = "Try using fewer words and letters, or contact the project owner."
          } else {
            title = "You company hasn't created any projects yet"
            description = "They will appear here when someone in your company create them"
          }
          break
        case "archived":
          if (hasProjects) {
            title = "No archived projects matching your search."
            description = "Try using fewer words and letters, or check if the project has been unarchived."
          } else {
            title = "You don't have any archived projects yet"
            description = "Projects will be in this area when you archive them"
          }
          break

        default:
          break
      }

      return { title, description, image: noProjectsImgSrc }
    })
  )

  searchValue: String = ""

  // To access enum in template
  COLLECTIONS = COLLECTIONS

  constructor(
    private projectService: ProjectService,
    private userService: UserService,
    private roleHandlerService: RoleHandlerService,
    private companyService: CompanyService,
    private router: Router,
    private snackbarService: SnackbarService,
    private dialogService: DialogService
  ) {}

  canViewCompanyProjects(userCompanyRole: Role) {
    return (
      userCompanyRole &&
      userCompanyRole.canReadTargetRelations(Project.COLLECTION) &&
      userCompanyRole.canReadTargetDocuments(Project.COLLECTION)
    )
  }

  switchProjectType(projectType: CurrentProjectType) {
    if (projectType === this.currentProjectType$.value) {
      return
    }

    this.searchValue = ""
    // @ts-ignore
    this.selectedSortOption = null
    this.currentProjectType$.next(projectType)
    this.projectTypeChanged$.next()
  }

  selectSortOption(option: SortOption) {
    if (option === this.selectedSortOption) {
      // @ts-ignore
      this.selectedSortOption = null

      return
    }

    this.selectedSortOption = option
  }

  createProject(currentCompany: Company) {
    if (currentCompany == null) {
      this.snackbarService.showMessage("ERROR: Cannot create project without a company")

      return
    }

    if (currentCompany.lockedByCheckd) {
      this.snackbarService.showMessage("ERROR: This company is locked and cannot create any new projects")

      return
    }

    this.router.navigateByUrl("/projects/new")
  }

  async favoriteToggled(user: Person, project: Project) {
    await this.userService.toggleFavoriteProject(user, project)
  }

  async disableProject(project: Project, currentUser: Person) {
    try {
      this.snackbarService.showMessage(`Removing project ${project.name} ...`)
      await this.projectService.disableProject(currentUser, project)
      this.snackbarService.showMessage(`Project ${project.name} successfully removed!`)
    } catch (err) {
      console.error(err)
      this.snackbarService.showMessage(err.message)
    }
  }

  async archiveProject(project: Project, currentUser: Person) {
    const dialog = this.dialogService.showSpinner(`Archiving project ${project.name} ...`, {
      disableClose: true,
      subTitle: "This may take some time depending on the size of the project",
    })

    try {
      await this.projectService.toggleArchivedState(currentUser, project, true)

      await dialog.componentInstance.finishWithSuccess({
        message: `Project ${project.name} successfully archived!`,
        timeOut: 5000,
        disableClose: false,
      })
    } catch (err) {
      console.error(err)
      await dialog.componentInstance.finishWithError({
        message: `Something went wrong`,
        subTitle: err.toString(),
        disableClose: false,
      })
    }
  }

  async unarchiveProject(project: Project, currentUser: Person) {
    const dialog = this.dialogService.showSpinner(`Unarchiving project ${project.name}`, {
      disableClose: true,
      subTitle: "This may take some time depending on the size of the project",
    })

    try {
      await this.projectService.toggleArchivedState(currentUser, project, false)

      await dialog.componentInstance.finishWithSuccess({
        message: `Project ${project.name} successfully unarchived!`,
        timeOut: 5000,
        disableClose: false,
      })
    } catch (err) {
      console.error(err)
      await dialog.componentInstance.finishWithError({
        message: `Something went wrong`,
        subTitle: err.toString(),
        disableClose: false,
      })
    }
  }

  async addCompanyAdminToProject(project: Project, user: Person) {
    try {
      this.snackbarService.showMessage(`Joining project ${project.name} ...`)
      await this.projectService.addComapnyAdminToProject(user, project)
      this.snackbarService.showMessage(`Project ${project.name} joined successfully!`)
      this.router.navigateByUrl(`/projects/${project.uid}`)
    } catch (err) {
      console.error(err)
      this.snackbarService.showMessage(err.message)
    }
  }

  private async duplicateProject(project: Project, currentUser: Person) {
    const data = { currentName: project.name || "", currentDescription: project.description || "" }
    const duplicationDialogResult: IProjectDuplicationSettings = await this.dialogService.openDialog(ProjectDuplicationDialogComponent, {
      data,
    })

    if (!duplicationDialogResult) {
      return
    }

    const dialog = this.dialogService.showSpinner("Duplicating project...", {
      disableClose: true,
      subTitle: "This may take some time depending on the size of the project",
    })
    try {
      await this.projectService.duplicateProject(project.uid, duplicationDialogResult)

      await dialog.componentInstance.finishWithSuccess({
        message: `Project ${project.name} successfully duplicated!`,
        timeOut: 5000,
        disableClose: false,
      })
    } catch (err) {
      await dialog.componentInstance.finishWithError({
        message: `Something went wrong`,
        subTitle: err.toString(),
        disableClose: false,
      })
    }
  }

  async handleProjectAction(event: { project: Project; action: ProjectMenuActions }, currentUser: Person) {
    switch (event.action) {
      case ProjectMenuActions.REMOVE_PROJECT:
        await this.disableProject(event.project, currentUser)
        break
      case ProjectMenuActions.ARCHIVE_PROJECT:
        await this.archiveProject(event.project, currentUser)
        break
      case ProjectMenuActions.JOIN_PROJECT:
        await this.addCompanyAdminToProject(event.project, currentUser)
        break
      case ProjectMenuActions.UNARCHIVE_PROJECT:
        await this.unarchiveProject(event.project, currentUser)
        break
      case ProjectMenuActions.DUPLICATE_PROJECT:
        await this.duplicateProject(event.project, currentUser)
        break
    }
  }

  projectSelected(project: Project) {
    // this.projectService.setCurrentProject(project.uid)
    this.router.navigate([`/projects/${project.uid}/items`])
  }
}
