<div class="checkd-checkbox-collection">
  <label>{{ element.title }}</label>
  <div fxLayout="{{ getLayoutDirection() + ' wrap' }}" class="app-form-element-container">
    <app-form-element
      (valueChanged)="selectionChanged($event)"
      *ngFor="let value of values"
      [element]="value"
      elementType="checkbox-item"
      [readOnly]="readOnly"
      [ngStyle]="{ flex: getLayoutDirection() === 'column' ? '1 1 auto' : '1 1 150px' }"
    >
    </app-form-element>
  </div>
</div>
