<form (ngSubmit)="submitHandler()" class="flex flex-column align-items-center justify-content-center">
  <div class="graphics-container flex justify-content-center align-items-center">
    <ng-container [ngSwitch]="state">
      <p-progressSpinner *ngSwitchCase="'working'"></p-progressSpinner>
      <i *ngSwitchCase="'done'" class="pi pi-check-circle" style="color: lightgreen"></i>
      <i *ngSwitchCase="'errored'" class="pi pi-times-circle" style="color: red"></i>
    </ng-container>
  </div>

  <p [ngSwitch]="state">
    <ng-container *ngSwitchCase="'working'">Hold on, updating your template</ng-container>
    <ng-container *ngSwitchCase="'done'">Done updating template</ng-container>
    <ng-container *ngSwitchCase="'errored'">{{ errorMsg }}</ng-container>
  </p>

  <button class="mt-3 align-self-end" pButton type="submit" [disabled]="state == 'working'" label="Close"></button>
</form>
