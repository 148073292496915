import { ChangeDetectionStrategy, Component } from "@angular/core"
import { CommonModule } from "@angular/common"

@Component({
  selector: "field-chat-lib-skeletton-loader-main-chat",
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="tw-p-4 tw-animate-pulse md:tw-p-6 tw-flex tw-flex-col tw-h-full">
      <div class="tw-flex tw-flex-col tw-gap-6 tw-flex-grow">
        <div class="tw-flex tw-gap-4 tw-items-center tw-justify-start">
          <svg
            class="tw-w-10 tw-h-10 tw-text-next-orange-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"
            />
          </svg>
          <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <div class="tw-h-3 tw-rounded-full tw-bg-next-orange-300 tw-w-32"></div>
            <div class="tw-h-3 tw-rounded-full tw-bg-next-orange-300 tw-w-full"></div>
          </div>
        </div>
        <div class="tw-flex tw-flex-row-reverse tw-gap-4 tw-items-center tw-justify-start">
          <svg
            class="tw-w-10 tw-h-10 tw-text-next-orange-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"
            />
          </svg>
          <div class="tw-flex tw-flex-col tw-items-end tw-gap-2 tw-w-full">
            <div class="tw-h-3 tw-rounded-full tw-bg-next-orange-300 tw-w-32"></div>
            <div class="tw-h-3 tw-rounded-full tw-bg-next-orange-300 tw-w-full"></div>
          </div>
        </div>
        <div class="tw-flex tw-gap-4 tw-items-center tw-justify-start">
          <svg
            class="tw-w-10 tw-h-10 tw-text-next-orange-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"
            />
          </svg>
          <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <div class="tw-h-3 tw-rounded-full tw-bg-next-orange-300 tw-w-32"></div>
            <div class="tw-h-3 tw-rounded-full tw-bg-next-orange-300 tw-w-full"></div>
          </div>
        </div>
        <div class="tw-flex tw-flex-row-reverse tw-gap-4 tw-items-center tw-justify-start">
          <svg
            class="tw-w-10 tw-h-10 tw-text-next-orange-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"
            />
          </svg>
          <div class="tw-flex tw-flex-col tw-items-end tw-gap-2 tw-w-full">
            <div class="tw-h-3 tw-rounded-full tw-bg-next-orange-300 tw-w-32"></div>
            <div class="tw-h-3 tw-rounded-full tw-bg-next-orange-300 tw-w-full"></div>
          </div>
        </div>
        <div class="tw-flex tw-gap-4 tw-items-center tw-justify-start">
          <svg
            class="tw-w-10 tw-h-10 tw-text-next-orange-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"
            />
          </svg>
          <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <div class="tw-h-3 tw-rounded-full tw-bg-next-orange-300 tw-w-32"></div>
            <div class="tw-h-3 tw-rounded-full tw-bg-next-orange-300 tw-w-full"></div>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-gap-4 tw-items-center">
        <div class="tw-h-8 tw-w-full tw-bg-next-orange-300 tw-rounded-full"></div>
        <div class="tw-rounded-full tw-w-8 tw-h-8 tw-bg-next-orange-300"></div>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkelettonLoaderMainChatComponent {}
