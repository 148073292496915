import { ElementOptionCode, TemplateElement, TemplateElementType } from "./template-element"

class ImageElement extends TemplateElement {
  override name: string = ""
  override icon?: string = "forms:camera"
  override type: TemplateElementType = TemplateElementType.IMAGE
  override typeAlias: string = "Image"
  override value = ""
  override children: any[] = []
  override options = [
    {
      id: 1,
      name: "Quarter",
      type: "checkbox",
      code: ElementOptionCode.QUARTER,
      value: false,
    },
    {
      id: 2,
      name: "Half",
      type: "checkbox",
      code: ElementOptionCode.HALF,
      value: false,
    },
    {
      id: 3,
      name: "Required",
      type: "checkbox",
      code: ElementOptionCode.REQUIRED,
      value: false,
    },
  ]
}

export { ImageElement }
