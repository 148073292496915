<mat-toolbar class="fixed-header mat-elevation-z6 small">
  <mat-toolbar-row class="justify-content-between">
    <a class="flex-none align-self-end" href="https://www.next-tech.com/"
      ><img src="assets/logos/next-logo-full-navy.svg" width="100" target="_blank"
    /></a>

    <h2 class="title report-title-container min-w-0 text-center">
      <span *ngIf="reportNumber" class="report-number">{{ reportNumber }}</span>
      <span class="report-title-read">{{ reportName }}</span>
    </h2>

    <div>
      <mat-menu #itemSortMenu="matMenu">
        <button (click)="onSortItemsBy(sortingType.NUMBER, sortingDirection.ASC)" mat-menu-item>Sort items by number (ascending)</button>
        <button (click)="onSortItemsBy(sortingType.NUMBER, sortingDirection.DESC)" mat-menu-item>Sort items by number (descending)</button>
        <button (click)="onSortItemsBy(sortingType.STATUS, sortingDirection.ASC)" mat-menu-item>Sort items by status (ascending)</button>
        <button (click)="onSortItemsBy(sortingType.STATUS, sortingDirection.DESC)" mat-menu-item>Sort items by status (descending)</button>
      </mat-menu>

      <mat-menu #reportMenu="matMenu">
        <button [disabled]="!editable" mat-menu-item (click)="editReportTitle.emit()">Edit report title</button>
        <button *ngIf="hasSortableItems" mat-menu-item [matMenuTriggerFor]="itemSortMenu">Item sorting</button>
        <button mat-menu-item *ngIf="report.reportType === 'FIELD'" (click)="generateDocx.emit()">Export to word</button>
        <button [disabled]="!canEditStatus" mat-menu-item (click)="markReportAsClosed.emit()">Set report as closed</button>
      </mat-menu>

      <button class="flex-none" [disabled]="report?.archived" mat-icon-button [matMenuTriggerFor]="reportMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px" style="height: 50px">
    <checkd-report-topbar-info
      [creatorName]="creatorName"
      [projectTitle]="projectTitle"
      [createdAt]="createdAt"
      [templatePublicVersion]="report.templatePublicVersion"
      [lastTimelineElementMessage]="lastTimelineElementMessage"
      [reportStatus]="reportStatus"
    >
    </checkd-report-topbar-info>

    <checkd-report-visibility-toggle
      *ngIf="showVisibilityToggle"
      [editable]="editable"
      [reportPermissions]="report.permissions"
      (changes)="visibilityChange.emit($event)"
    >
    </checkd-report-visibility-toggle>
  </mat-toolbar-row>
</mat-toolbar>
