import { Component, Inject, OnInit } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import { Company, LegacyTemplate, Project } from "@models/common"

@Component({
  selector: "legacy-report-creation-dialog",
  templateUrl: "./legacy-report-creation-dialog.component.html",
  styleUrls: ["./legacy-report-creation-dialog.component.scss"],
})
export class LegacyReportCreationDialogComponent implements OnInit {
  get userCompany(): Company {
    return this.data.userCompany
  }
  get projectCompany(): Company {
    return this.data.projectCompany
  }

  get currentCompanyTemplates() {
    return this.data.currentCompanyTemplates
  }

  get projectTemplates() {
    return this.data.projectTemplates
  }

  constructor(
    private dialogRef: MatDialogRef<LegacyReportCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      project: Project
      userCompany: Company
      projectCompany: Company
      projectTemplates: LegacyTemplate[]
      currentCompanyTemplates: LegacyTemplate[]
    }
  ) {}

  ngOnInit() {
    return
  }

  templateSelected(template: LegacyTemplate) {
    this.dialogRef.close(template)
  }
}
