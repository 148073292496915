import { SortingDirection, convertWorkflowStateToStatus, SortingConfig, SortingType, SORT_ITEM_STATUS_RANK } from "../../models/common"

/**
 * Returns a new object based on `obj`, but without attributes that
 * fail to satisfy the supplied predicate function.
 */
export function filterObjectPropertiesByKey(obj: Record<string, any>, predicate: (key: any) => boolean) {
  return Object.keys(obj)
    .filter(predicate)
    .reduce((res, k) => Object.assign(res, { [k]: obj[k] }), {})
}

///////////////////////////////////////////////////////////////////////////////
// ITEM ROW SORTING
//
// Used for sorting item rows generated by toPdfDocDefinition()
//
// NB: If the data structure or positioning of the item row cells
// change, we have to update it here too.
///////////////////////////////////////////////////////////////////////////////

const NUMBER_POSITION: number = 0
const STATUS_POSITION: number = 1

function sortItemRowsByNumber(itemRows: any[], sortingDirection: SortingDirection): any {
  return itemRows.sort((a, b) => {
    const numA = a.values == null || a.values == null || a.values[NUMBER_POSITION] == null ? 0 : a.values[NUMBER_POSITION]

    const numB = b.values == null || b.values == null || b.values[NUMBER_POSITION] == null ? 0 : b.values[NUMBER_POSITION]

    switch (sortingDirection) {
      case SortingDirection.ASC:
        return numA - numB
      case SortingDirection.DESC:
        return numB - numA
    }
  })
}

function sortItemRowsByStatus(itemRows: any[], sortingDirection: SortingDirection): any[] {
  return itemRows.sort((a, b) => {
    // @ts-ignore
    const numA = SORT_ITEM_STATUS_RANK[convertWorkflowStateToStatus(a.values[STATUS_POSITION]) || "none"]
    // @ts-ignore
    const numB = SORT_ITEM_STATUS_RANK[convertWorkflowStateToStatus(b.values[STATUS_POSITION]) || "none"]

    switch (sortingDirection) {
      case SortingDirection.ASC: {
        return numA - numB
      }
      case SortingDirection.DESC: {
        return numB - numA
      }
    }
  })
}

export function sortItemRowsBy(itemRows: any[], sortingConfig: SortingConfig): any[] {
  switch (sortingConfig.sortingType) {
    case SortingType.NUMBER: {
      return sortItemRowsByNumber(itemRows, sortingConfig.sortingDirection)
    }
    case SortingType.STATUS: {
      return sortItemRowsByStatus(itemRows, sortingConfig.sortingDirection)
    }
    default: {
      console.error(`Unknown SortingType: ${sortingConfig.sortingType}`)
      return itemRows
    }
  }
}

///////////////////////////////////////////////////////////////////////////////
// END ITEM ROW SORTING
///////////////////////////////////////////////////////////////////////////////

// This helper function was added because we used to store boolean values as strings in the database.
export function getCorrectOptionValue(value: string | boolean | number): string | boolean | number {
  switch (typeof value) {
    case "boolean":
      return value
    case "number":
      return value
    case "string":
      const stringValue = value.toLowerCase().trim()

      if (stringValue === "true" || stringValue === "false") {
        return stringValue === "true"
      }

      return value
    default:
      return false
  }
}
