import { OverlayRef } from "@angular/cdk/overlay"
import { Intercom } from "@supy-io/ngx-intercom"
import { InjectionToken } from "@angular/core"
import { CompanyFeatures } from "@models/common/company.interface"
import { IFeatureDescription } from "./feature-description.interface"

export const MISSING_FEATURE_DIALOG_DATA = new InjectionToken<any>("MISSING_FEATURE_DIALOG_DATA")

export class MissingFeatureOverlayRef {
  constructor(private overlayRef: OverlayRef, private intercom: Intercom) {}

  close(): void {
    this.overlayRef.dispose()
  }

  triggerIntercom(): void {
    this.intercom.show()
  }
}

export interface IMissingFeatureOverlayConfigData {
  feature: CompanyFeatures
  featureDescription: IFeatureDescription
  buttonText?: string
  redirectUrl?: string
  [key: string]: any
}

export interface MissingFeatureOverlayConfig {
  panelClass: string
  hasBackdrop: boolean
  backdropClass: string
  data: Partial<IMissingFeatureOverlayConfigData>
}
