<div *ngIf="oobCodeValid" fxLayout="column" fxLayoutAlign="center center">

  <h2>Password Reset</h2>

  <form [formGroup]="passwordReset" fxLayout="column" fxLayoutAlign="space-between" novalidate>

    <mat-form-field>
      <input matInput formControlName="password" placeholder="Enter new password" [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <input matInput formControlName="confirmPassword" placeholder="Confirm new password" [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
    </mat-form-field>

  </form>

  <checkd-button *ngIf="isValid()" (click)="resetPassword()">Submit</checkd-button>
</div>
