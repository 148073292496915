import { Directive, ElementRef, HostBinding, Input, OnInit, Output, Renderer2, EventEmitter, HostListener } from "@angular/core"

@Directive({
  selector: "[primary-button]",
})
export class PrimaryButtonDirective {
  constructor(private element: ElementRef, private renderer: Renderer2) {}

  @Input() isRounded = false
  @Input() inProgress: boolean = false

  @Input() type: string = ""

  @HostBinding("class")
  get buttonType(): string {
    if (this.type === "text") {
      return "button button__primary--text-btn"
    } else if (this.type === "outline") {
      return "button button__primary--outline"
    } else {
      return "button button__primary--filled"
    }
  }

  @HostBinding("class.button__rounded") get roundedClass(): boolean {
    return this.isRounded
  }
}
