import { Component, OnInit } from "@angular/core"

@Component({
  selector: "checkd-item-list-header",
  templateUrl: "./item-list-header.component.html",
  styleUrls: ["./item-list-header.component.scss"],
})
export class ItemListHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
