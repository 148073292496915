<mat-toolbar *ngIf="showTopbar" style="background-color: rgba(0, 0, 0, 0)">
  <a *ngIf="showBackButton" href="#" routerLink="/projects">
    <mat-icon>
      <i style="color: gray" class="material-icons">keyboard_arrow_left</i>
    </mat-icon>
  </a>

  <span>{{ title }}</span>
  <div *ngIf="showTopbarMenu" class="ml-top-menu">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon svgIcon="ml-logo-gray"></mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button *ngFor="let item of topbarMenuItems" (click)="onTopbarMenuItemClicked(item.name)" mat-menu-item>
        <mat-icon>dialpad</mat-icon>
        <span>{{ item.name }}</span>
      </button>

      <button (click)="logout()" mat-menu-item>
        <mat-icon>dialpad</mat-icon>
        <span>Logg ut</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
