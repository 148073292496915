import { SelectionModel } from "@angular/cdk/collections"
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator"
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table"
import { LegacyTemplate } from "@models/common"
import { BehaviorSubject, Subscription, zip } from "rxjs"

@Component({
  selector: "checkd-project-template-list",
  templateUrl: "./project-template-list.component.html",
  styleUrls: ["./project-template-list.component.scss"],
})
export class ProjectTemplateListComponent implements OnInit, OnDestroy {
  _allRows: LegacyTemplate[] = []
  isLoading = true
  displaySelectedOnlyToggle = false

  // @ts-ignore
  _companyTemplates = new BehaviorSubject<LegacyTemplate[]>(null)
  // @ts-ignore
  _selectedProjectTemplates = new BehaviorSubject<LegacyTemplate[]>(null)
  _hasPublicTemplates: boolean

  @Input("companyTemplates") set companyTemplates(templates: LegacyTemplate[]) {
    this.initTemplateTable(templates)
    if (templates) {
      this._companyTemplates.next(templates)
    }
  }

  @Input() set preselectedTemplates(templates: LegacyTemplate[]) {
    if (templates) {
      this._selectedProjectTemplates.next(templates)
    }
  }

  @Input("hasPublicTemplates") set publicTemplates(val: boolean) {
    this._hasPublicTemplates = val
  }

  @Output() selectedTemplates = new EventEmitter<LegacyTemplate[]>()
  @Output() hasPublicTemplates = new EventEmitter<boolean>()

  @Output() singleTemplateSelected = new EventEmitter<LegacyTemplate>()
  @Output() singleTemplateDeselected = new EventEmitter<LegacyTemplate>()

  dataSource = new MatTableDataSource<LegacyTemplate>()

  displayedColumns: string[] = ["select", "name"]

  selection = new SelectionModel<LegacyTemplate>(true, this._selectedProjectTemplates.value)

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator
  }
  pageSize = 10
  hidePageSize = true

  subscriptions = new Subscription()

  constructor() {
    return
  }

  ngOnInit() {
    this.subscriptions.add(
      this.selection.changed.subscribe((_) => {
        this.selectedTemplates.emit(this.selection.selected)
      })
    )

    this.subscriptions.add(
      zip(this._companyTemplates, this._selectedProjectTemplates).subscribe(([companyTemplates, selectedProjectTemplates]) => {
        if (companyTemplates && selectedProjectTemplates) {
          this.dataSource.data
            .filter((template) => {
              return selectedProjectTemplates.some((t) => t.uid === template.uid)
            })
            .forEach((t) => this.selection.select(t))
        }
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  handleCheckboxToggled(template: LegacyTemplate) {
    // Ugly hack: selection.isSelected will not be updated until *after* the click has been handled, so we negate the bool
    if (!this.selection.isSelected(template)) {
      // IF SELECTED
      this.singleTemplateSelected.emit(template)
    } else {
      // ELSE NOT SELECTED
      this.singleTemplateDeselected.emit(template)
    }
  }

  initTemplateTable(templates: LegacyTemplate[]) {
    if (templates === null) {
      return
    }
    this.dataSource = new MatTableDataSource(templates)
    this._allRows = this.dataSource.data

    this.dataSource.filterPredicate = function (data: LegacyTemplate, filter: string): boolean {
      return data.name.toLowerCase().includes(filter)
    }

    this.isLoading = false
  }

  get tableHasData(): boolean {
    return this._allRows.length > 0
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length

    return numSelected === numRows
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row))
  }

  toggleDisplaySelectedOnly() {
    this.displaySelectedOnlyToggle = !this.displaySelectedOnlyToggle

    if (this.displaySelectedOnlyToggle === true) {
      this.dataSource.data = this.selection.selected
    } else {
      this.dataSource.data = this._allRows
    }
  }

  onTogglePublicTemplatesChange(change: any) {
    this.hasPublicTemplates.emit(change.checked)
  }
}
