import { ElementOptionCode, TemplateElement, TemplateElementType } from "./template-element"

class DateElement extends TemplateElement {
  override name: string = ""
  override icon = "forms:date"
  override type: TemplateElementType = TemplateElementType.DATE
  override typeAlias: string = "Date"
  override value = ""
  override options = [
    {
      id: 1,
      name: "Quarter",
      type: "checkbox",
      code: ElementOptionCode.QUARTER,
      value: false,
    },
    {
      id: 2,
      name: "Half",
      type: "checkbox",
      code: ElementOptionCode.HALF,
      value: false,
    },
    {
      id: 3,
      name: "Required",
      type: "checkbox",
      code: ElementOptionCode.REQUIRED,
      value: false,
    },
    {
      id: 4,
      name: "Autogenerated",
      type: "checkbox",
      code: ElementOptionCode.AUTOGENARATED,
      value: false,
    },
    {
      id: 5,
      name: "Include in email",
      type: "checkbox",
      code: ElementOptionCode.INCLUDE_IN_EMAIL,
      value: false,
    },
  ]
}

export { DateElement }
