<h2 mat-dialog-title>Edit report title and click save to confirm</h2>

<div mat-dialog-content class="main-content">
  <form [formGroup]="reportForm">
    <mat-form-field>
      <mat-label>Report title</mat-label>
      <input matInput formControlName="title" />
      <mat-error *ngIf="title!.errors && title!.touched">Report title cannot be blank</mat-error>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button mat-button class="checkd-btn cancel-btn-color" (click)="cancel()">Cancel</button>
  <button mat-button class="checkd-btn save-btn-color" [disabled]="reportForm.invalid || !reportForm.dirty" (click)="save()">Save</button>
</div>
