import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"
import { AutoComplete } from "primeng/autocomplete"
import { Subscription } from "rxjs"
import { FormsLibraryConfigService } from "../../services/forms-library-config.service"

@Component({
  selector: "checkd-tag-selection",
  templateUrl: "./tag-selection.component.html",
  styleUrls: ["./tag-selection.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TagSelectionComponent,
    },
    FormsLibraryConfigService,
  ],
})
export class TagSelectionComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private _tags: string[] = []
  set tags(newTags: string[]) {
    if (newTags.length > this._tags.length) {
      this.onAdd(newTags)
    } else if (newTags.length < this._tags.length) {
      this.onRemove(newTags)
    }
  }
  get tags(): string[] {
    return this._tags
  }

  tagSearchResult: string[] = []
  defaultTags: string[] = []

  onChange = (_: string[]) => {}
  onTouched = () => {}
  touched = false
  disabled = false

  private subscriptions = new Subscription()

  constructor(public cdr: ChangeDetectorRef, public flConfigService: FormsLibraryConfigService) {}

  ngOnInit() {
    this.subscriptions.add(this.flConfigService.tags$.subscribe((tags) => (this.defaultTags = tags)))
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  addHack(elementRef: AutoComplete) {
    console.dir(elementRef)
    const currentTags: string[] = elementRef.value
    let newTag = elementRef.inputValue as string

    if (!newTag) {
      return
    }

    if (elementRef.highlightOption !== null) {
      newTag = elementRef.highlightOption
    }

    elementRef.multiInputEl!.nativeElement.value = ""
    this.onAdd([...currentTags, newTag])
  }

  onAdd(newTags: string[]) {
    this.markAsTouched()
    if (!this.disabled) {
      const lastIdx = newTags.length - 1

      const newTag = newTags[lastIdx]?.trim().toUpperCase()
      if (!newTag || this.tags.includes(newTag)) {
        return
      }
      newTags[lastIdx] = newTag

      this._tags = newTags.map(this.removeExtraWhitespace).filter(Boolean).sort()
      this.onChange(this.tags)
    }
  }

  private removeExtraWhitespace(word: string): string {
    return word.split(/\s+/).filter(Boolean).join(" ")
  }

  onRemove(tags: string[]) {
    this.markAsTouched()
    if (!this.disabled) {
      this._tags = tags
      this.onChange(this.tags)
    }
  }

  // register a callback that reports changes back to the parent form
  registerOnChange(onChange: any): void {
    this.onChange = onChange
  }

  // registers a callback to report to the parent form that the control was touched
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched
  }

  // Called by the parent form using the Angular Forms API to enable/disable this form control
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  // called by the Forms module to write a value into a form control
  writeValue(tags: string[]): void {
    this._tags = tags
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched()
      this.touched = true
    }
  }

  // Filters the default tags dropdown as the user types
  searchTags(event: any) {
    const filtered: string[] = []
    const query = event.query ?? ""

    for (const tag of this.defaultTags) {
      if (tag.toUpperCase().includes(query.toUpperCase())) {
        filtered.push(tag)
      }
    }

    this.tagSearchResult = filtered
  }
}
