import { Component } from "@angular/core"
import { FormsLibraryViewService } from "../../services/forms-library-view.service"

@Component({
  selector: "checkd-forms-library-view",
  templateUrl: "./forms-library-view.component.html",
  styleUrls: ["./forms-library-view.component.scss"],
  providers: [FormsLibraryViewService],
})
export class FormsLibraryViewComponent {
  constructor(public flViewService: FormsLibraryViewService) {}
}
