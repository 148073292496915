import { CheckdColors } from "@checkd-colors"
import { IConfirmDialogData } from "@dialogs"
import { GeneralReport } from "../general-report"
import { LegacyTemplate } from "../legacy-template"

export enum ReportMenuActions {
  REMOVE_REPORT,
  LOCK_REPORT,
  UNLOCK_REPORT,
  ADD_TAGS,

  // TODO We're not using this one - should we?
  SHARE_REPORT,
  DUPLICATE_REPORT,
}

export enum ItemMenuActions {
  REMOVE_ITEM,
  SET_AS_CLOSED,
  DUPLICATE_ITEM,
  SEE_PICTURES,
  DOWNLOAD_PDF,
  ADD_COLLABORATOR,
  EDIT_DETAILS,
  UPLOAD_IMAGE,
  VIEW_IMAGES,
  REMOVE_FROM_REPORT,
}

export enum ProjectMenuActions {
  REMOVE_PROJECT,
  ARCHIVE_PROJECT,
  UNARCHIVE_PROJECT,
  JOIN_PROJECT,
  DUPLICATE_PROJECT,
}

export enum DrawingMenuActions {
  REMOVE_DRAWING,
  RENAME_DRAWING,
}

export enum LegacyTemplateMenuActions {
  EDIT_TEMPLATE,
  DUPLICATE_TEMPLATE,
  PUBLISH_TEMPLATE,
  UNPUBLISH_TEMPLATE,
  REMOVE_TEMPLATE,
  ACTIVATE_TEMPLATE,
  DEACTIVATE_TEMPLATE,
  GO_TO_QR_CODE_VIEW,
  GET_URL_IN_CLIPBOARD,
}

export type MenuActions = ReportMenuActions | ItemMenuActions | ProjectMenuActions | DrawingMenuActions | LegacyTemplateMenuActions

export interface IMenuOption {
  name: string
  color: CheckdColors
  action: MenuActions
  confirmationDialog?: IConfirmDialogData
}

export interface IReportMenuActionsResponse {
  report: GeneralReport | LegacyTemplate
  action: ReportMenuActions | LegacyTemplateMenuActions
}
