<h3 mat-dialog-title>Invite people to your company via</h3>

<mat-dialog-content>
  <mat-tab-group [selectedIndex]="selectedTab" (selectedIndexChange)="changeTab($event)" mat-align-tabs="center">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1">email_outline</mat-icon>
        Email
      </ng-template>

      <div class="mt-2">
        <checkd-autocomplete-emails (emailInvitesChanged)="updateEmailInvites($event)"></checkd-autocomplete-emails>
      </div>
    </mat-tab>

    <mat-tab [disabled]="!enableSMSInvitations">
      <ng-template mat-tab-label>
        <mat-icon class="mr-1">phone_iphone</mat-icon>
        SMS
      </ng-template>

      <div class="flex gap-1 mt-2 align-items-start phone-input-container">
        <button mat-button [matMenuTriggerFor]="flagMenu">
          <div fxLayout="row" fxLayoutGap="5px">
            <span class="fi fi-{{ selectedCountryCode }}"></span>
            <span>+{{ geoService.findCountryCode(selectedCountryCode) }}</span>
          </div>
        </button>
        <mat-menu #flagMenu="matMenu">
          <button mat-menu-item *ngFor="let countryCode of countryCodes" (click)="selectedCountryCode = countryCode">
            <div fxLayout="row" fxLayoutGap="5px">
              <span class="fi fi-{{ countryCode }}"></span>
              <span>+{{ geoService.findCountryCode(countryCode) }}</span>
            </div>
          </button>
        </mat-menu>

        <form [formGroup]="phoneTypeFormGroup" class="phone-input">
          <input
            appPhoneMask
            formControlName="phone"
            [countryCode]="selectedCountryCode"
            matInput
            placeholder="Enter a phone number"
            maxlength="20"
            [(ngModel)]="phoneInput"
          />
          <mat-error *ngIf="phoneTypeFormGroup.controls['phone'].invalid">Please enter a valid phone number</mat-error>
        </form>
      </div>

      <div class="flex mt-2 flex-column">
        <textarea class="flex-1" [(ngModel)]="textAreaInput" maxlength="100" rows="5" placeholder="Type a message here..."> </textarea>
        <p class="sms-char-limit" *ngIf="textAreaInput">{{ textAreaInput.length }}/100</p>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="role-selection">
    <label>Select role: </label>
    <mat-radio-group [(ngModel)]="selectedRole" aria-label="Select role" fxLayoutGap="12px" (change)="updateSelectedRole()">
      <mat-radio-button value="DEFAULT"><strong>Normal Rights</strong></mat-radio-button>
      <mat-radio-button value="ADMINISTRATOR"><strong>Admin Rights</strong></mat-radio-button>
    </mat-radio-group>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button primary-button [type]="'outline'" mat-dialog-close>Close</button>
  <button primary-button [disabled]="!canSendInvites" (click)="sendInvites()">
    {{ inviteButtonMessage }}
  </button>
</mat-dialog-actions>
