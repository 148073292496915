import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core"
import { Company } from "@models/common/company"
import { CompanyFeatures } from "@models/common/company.interface"
import { Project } from "@models/common/project"
import { ProjectService } from "@services/project.service"
import { FeatureCheckerService } from "../../features/feature-checker/services/feature-checker.service"

@Component({
  selector: "checkd-project-tabbar",
  templateUrl: "./project-tabbar.component.html",
  styleUrls: ["./project-tabbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectTabbarComponent {
  @Input() set currentTab(tabName: string) {
    if (tabName !== this._currentTab) {
      this._currentTab = tabName
    }
  }
  @Output() tabSelected = new EventEmitter()
  @Input() currentCompany: Company
  @Input() project: Project
  @Input() projectReady: boolean = false

  _currentTab: string = "drawings"

  constructor(public projectService: ProjectService, private featureCheckerService: FeatureCheckerService) {}

  onTabSelected(tabName: string) {
    if (tabName === "drawings" && !this.projectService.hasDrawingsFeature(this.project)) {
      this.featureCheckerService.displayMissingFeatureOverlay(CompanyFeatures.DRAWINGS)

      return
    }

    this._currentTab = tabName
    this.tabSelected.emit(tabName)
  }

  isActive(tabName: string) {
    return this._currentTab === tabName
  }
}
