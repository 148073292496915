import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-trash-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" [attr.viewBox]="getViewBox()" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.49219 2.41875L8.60156 3.75H15.3984L14.5078 2.41875C14.4375 2.31562 14.3203 2.25 14.1937 2.25H9.80156C9.675 2.25 9.55781 2.31094 9.4875 2.41875H9.49219ZM16.3828 1.17188L18.1031 3.75H18.75H21H21.375C21.9984 3.75 22.5 4.25156 22.5 4.875C22.5 5.49844 21.9984 6 21.375 6H21V20.25C21 22.3219 19.3219 24 17.25 24H6.75C4.67812 24 3 22.3219 3 20.25V6H2.625C2.00156 6 1.5 5.49844 1.5 4.875C1.5 4.25156 2.00156 3.75 2.625 3.75H3H5.25H5.89688L7.61719 1.16719C8.10469 0.440625 8.925 0 9.80156 0H14.1937C15.0703 0 15.8906 0.440625 16.3781 1.16719L16.3828 1.17188ZM5.25 6V20.25C5.25 21.0797 5.92031 21.75 6.75 21.75H17.25C18.0797 21.75 18.75 21.0797 18.75 20.25V6H5.25ZM9 9V18.75C9 19.1625 8.6625 19.5 8.25 19.5C7.8375 19.5 7.5 19.1625 7.5 18.75V9C7.5 8.5875 7.8375 8.25 8.25 8.25C8.6625 8.25 9 8.5875 9 9ZM12.75 9V18.75C12.75 19.1625 12.4125 19.5 12 19.5C11.5875 19.5 11.25 19.1625 11.25 18.75V9C11.25 8.5875 11.5875 8.25 12 8.25C12.4125 8.25 12.75 8.5875 12.75 9ZM16.5 9V18.75C16.5 19.1625 16.1625 19.5 15.75 19.5C15.3375 19.5 15 19.1625 15 18.75V9C15 8.5875 15.3375 8.25 15.75 8.25C16.1625 8.25 16.5 8.5875 16.5 9Z"
        fill="currentColor"
        [attr.transform]="getScale()"
      />
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class TrashIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }

  getViewBox(): string | undefined {
    return this.svgIconsService.getViewBox(this.size)
  }
  getScale(): string | undefined {
    return this.svgIconsService.getScale(this.size)
  }
}
