<ng-container *ngIf="item">
  <div fxLayout="row" fxLayoutAlign="space-between begin">
    <div class="date-column" fxLayout="column" fxLayoutGap="10px">
      <div *ngIf="item.createdAt && item.createdAt > 0" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon class="element-icon extra-margin-left" svgIcon="calendar"></mat-icon>
        <span class="element extra-margin-left" fxLayout="column" fxLayoutGap="3px">
          <span class="element-title">Created at</span>
          <span>{{item.createdAt | amFromUnix | amDateFormat: dateFormat}}</span>
        </span>
      </div>

      <div *ngIf="item.updatedAt && item.updatedAt > 0" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon class="element-icon extra-margin-left" svgIcon="calendar"></mat-icon>
        <span class="element extra-margin-left" fxLayout="column" fxLayoutGap="3px">
          <span class="element-title">Updated</span>
          <span>{{item.updatedAt | amFromUnix | amTimeAgo}}</span>
        </span>
      </div>

      <div *ngIf="item.dueDate" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon [owlDateTimeTrigger]="dt" class="element-icon extra-margin-left calendar-icon" svgIcon="calendar"></mat-icon>
        <input
          [(ngModel)]="_dueDate"
          class="stupid-hide"
          [owlDateTime]="dt"
          [owlDateTimeTrigger]="dt">
        <owl-date-time [pickerMode]="'dialog'" #dt></owl-date-time>

        <span class="element" fxLayout="column" fxLayoutGap="3px">
          <span class="element-title">Due date</span>
          <span *ngIf="!itemOverDue; else itemOverDueView"
            matTooltip="{{item.dueDate | amFromUnix | amDateFormat: dateFormat}}">
            {{item.dueDate | amFromUnix | amTimeAgo}}
          </span>
          <ng-template #itemOverDueView>
            <span>{{item.dueDate | amFromUnix | amDateFormat: dateFormat}}</span>
          </ng-template>
        </span>
      </div>
    </div>

    <div fxLayout="column" fxLayoutGap="10px" class="project-info-column">
      <div *ngIf="itemCreatorName" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon class="element-icon" svgIcon="user"></mat-icon>
        <span class="element" fxLayout="column" fxLayoutGap="3px">
          <span class="element-title">Creator of item</span>
          <span>
            {{itemCreatorName}}
            <span *ngIf="itemCreatorCompanyName">, {{itemCreatorCompanyName}}</span>
          </span>
        </span>
      </div>

      <div *ngIf="taskAssigneeName" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon class="element-icon" svgIcon="user"></mat-icon>
        <span class="element" fxLayout="column" fxLayoutGap="3px">
          <span class="element-title">Assignee</span>
          <span>
            {{taskAssigneeName}}
            <span *ngIf="taskAssigneeCompanyName">, {{taskAssigneeCompanyName}}</span>
          </span>

        </span>
      </div>

      <div *ngIf="legacyReportName" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon class="element-icon" svgIcon="report-dark"></mat-icon>
        <span class="element" fxLayout="column" fxLayoutGap="3px">
          <span class="element-title">Report</span>
          <a *ngIf="canViewLinks && legacyReportUrl" href="{{legacyReportUrl}}" target="_blank">{{legacyReportName}}</a>
          <span *ngIf="!canViewLinks || !legacyReportUrl">{{legacyReportName}}</span>
        </span>
      </div>

      <div *ngIf="projectName" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon class="element-icon" svgIcon="project-dark"></mat-icon>
        <span class="element" fxLayout="column" fxLayoutGap="3px">
          <span class="element-title">Project</span>
          <a *ngIf="canViewLinks && projectUrl" href="{{projectUrl}}">{{projectName}}</a>
          <span *ngIf="!canViewLinks || !projectUrl">{{projectName}}</span>
        </span>
      </div>

      <div *ngIf="collaborators && collaborators.length > 0" class="collaborators" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <button mat-stroked-button class="collaborators-button" [matMenuTriggerFor]="collaboratorsMenu">
          Collaborators
          <mat-icon>arrow_drop_down</mat-icon>
        </button>

        <mat-menu #collaboratorsMenu="matMenu">
          <button mat-menu-item
                  matTooltip="Click to remove this collaborator"
                  matTooltipPosition="right"
                  *ngFor="let collaborator of collaborators; trackBy: trackByUid"
                  (click)="removeCollaborator.emit({ collaboratorUid: collaborator.uid, item })">
            {{ collaborator.name }}
            <mat-icon>delete</mat-icon>
          </button>
        </mat-menu>

      </div>

    </div>
  </div>
</ng-container>
