<div class="forms-library">
  <section class="forms-library__search">
    <div class="search__header">
      <h1 class="search__title">Explore, add, use</h1>
      <h4 class="search__subtitle">Add published templates to your company templates and use them in your own projects.</h4>
    </div>
    <div class="search__searchbar-container flex mx-auto justify-content-center align-items-center gap-1">
      <checkd-forms-library-search-bar class="col"></checkd-forms-library-search-bar>
      <button
        pButton
        pRipple
        type="button"
        label="See all templates"
        styleClass="templates-btn"
        class="search__templates-btn p-button-outlined col-fixed"
        (click)="flViewService.toggleDisplayedComponent()"
      ></button>
    </div>
    <div class="search__tags-container">
      <div class="search__tags-wrapper">
        <checkd-tag-component
          *ngFor="let tag of flConfigService.tags$ | async"
          [label]="tag"
          (onChange)="onTagSelectionChanged($event)"
        ></checkd-tag-component>
      </div>
      <button
        pButton
        pRipple
        (click)="onFindTemplatesClicked()"
        type="button"
        label="Find templates"
        class="search__find-templates-btn"
      ></button>
    </div>
  </section>

  <!--  <section class="forms-library__your-subscriptions">-->
  <!--    <div class="subscriptions__header">-->
  <!--      <img src="./assets/icons/forms_library/subscription-icon.svg" alt="" />-->
  <!--      <h2 class="subscriptions__title">Your subscriptions</h2>-->
  <!--    </div>-->
  <!--    <div class="subscriptions__subscriptors-container">-->
  <!--      <ng-container>-->
  <!--        <checkd-subscriptor-card-component></checkd-subscriptor-card-component>-->
  <!--        <checkd-subscriptor-card-component></checkd-subscriptor-card-component>-->
  <!--      </ng-container>-->
  <!--    </div>-->
  <!--  </section>-->

  <!--  <section class="forms-library__highlights-publishers">-->
  <!--    <h2 class="publishers__title">Highlighted publishers</h2>-->
  <!--    <div class="publishers__cards-container">-->
  <!--      <checkd-publisher-card></checkd-publisher-card>-->
  <!--      <checkd-publisher-card></checkd-publisher-card>-->
  <!--    </div>-->
  <!--  </section>-->

  <section class="forms-library__new-and-updated">
    <checkd-template-cards-section sectionTitle="New & Updated">
      <checkd-template-card
        *ngFor="let template of flConfigService.newAndUpdatedTemplates$ | async"
        [title]="template.name"
        [description]="template.description"
        class="cursor-pointer"
        (click)="onTemplateCardClicked(template.uid)"
      >
      </checkd-template-card>
    </checkd-template-cards-section>
  </section>

  <section class="forms-library__featured">
    <checkd-template-cards-section sectionTitle="Featured">
      <checkd-template-card
        *ngFor="let template of flConfigService.featuredTemplates$ | async"
        [title]="template.name"
        [description]="template.description"
        class="cursor-pointer"
        (click)="onTemplateCardClicked(template.uid)"
      >
      </checkd-template-card>
    </checkd-template-cards-section>
  </section>

  <section *ngIf="currentCompanyCanUseFormsBuilder$ | async" class="forms-library__try-out">
    <h2 class="try-out__title">Build your own</h2>
    <div class="try-out__bg-container">
      <h3 class="try-out__subtitle">Build your own templates!</h3>
      <button pButton pRipple type="button" routerLink="/forms/builder" label="Go to Forms Builder" class="try-out__btn"></button>
    </div>
  </section>

  <section class="forms-library__most-used">
    <checkd-template-cards-section sectionTitle="Most Used">
      <checkd-template-card
        *ngFor="let template of flConfigService.mostUsedTemplates$ | async"
        [title]="template.name"
        [description]="template.description"
        class="cursor-pointer"
        (click)="onTemplateCardClicked(template.uid)"
      >
      </checkd-template-card>
    </checkd-template-cards-section>
  </section>
</div>
