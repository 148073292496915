import { Injectable } from "@angular/core"
import * as FileSaver from "file-saver"

@Injectable()
export class FileHandlerService {
  constructor() {}

  saveCsv(filename: string, data: string) {
    const blob = new Blob([data], { type: "text/csv;charset=utf-8" })
    FileSaver.saveAs(blob, filename)
  }
}
