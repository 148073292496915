<p-overlayPanel #opThumbnails>
  <p>For items, this option will add thumbnails of the item images below the item.</p>
  <p>For camera elements, this option will add a thumbnail of the image below the title</p>
</p-overlayPanel>

<p-overlayPanel #opNextProjectUpload>
  <p>
    This option will also upload this PDF to your NeXT Project project, <br />
    and is only available if you already have connected your project with NeXT Project.
  </p>
</p-overlayPanel>

<p-overlayPanel #opImageSection>
  <p>p-overlayPanelThis option will add a separate section under each item list containing your item images.</p>
</p-overlayPanel>

<p-overlayPanel #opConnectedItems>
  <p>This option will either:</p>
  <ul>
    <li>Include a section at the end of the report that contains <strong>all</strong> the items that belong to the report</li>
    <li>Include a section at the end of the report that only contains the items that have not appeared earlier in the report</li>
  </ul>
</p-overlayPanel>

<div class="overlay__container">
  <div class="overlay__data-container" [ngSwitch]="pdfGenerationState">
    <ng-container *ngSwitchCase="'initial'">
      <ng-container *ngTemplateOutlet="pdfGenerationOptions"></ng-container>
    </ng-container>

    <div class="state-container" *ngSwitchCase="'generatingPdf'">
      <mat-spinner></mat-spinner>
      Generating PDF &mdash; please wait
    </div>
    <div class="state-container" *ngSwitchCase="'success'">
      <mat-icon [ngStyle]="{ 'font-size': iconSize, height: iconSize, width: iconSize }" class="success">check_circle</mat-icon>
      <p>Your PDF is ready</p>
      <button
        style="width: 100%"
        pButton
        type="button"
        icon="pi pi-external-link"
        iconPos="right"
        label="Click here to open PDF"
        (click)="openPdfClicked(downloadUrl)"
      ></button>
    </div>
    <div class="state-container" *ngSwitchCase="'error'">
      <mat-icon [ngStyle]="{ 'font-size': iconSize, height: iconSize, width: iconSize }" class="error">error</mat-icon>
      <h2>An error has occurred :-(</h2>
      <p>
        An error occurred when generating your PDF. If this keeps happening, please
        <span id="intercom-contact-customer-support-error-generating-pdf">contact our customer support team.</span>
      </p>
    </div>
  </div>

  <ng-template #pdfGenerationOptions>
    <div>
      <div class="flex flex-row justify-content-between align-content-center">
        <p class="mb-2">Include image thumbnails</p>
        <i class="pi pi-info-circle" (click)="opThumbnails.show($event)" style="padding-top: 1.2em; cursor: pointer"></i>
      </div>
      <p-inputSwitch [(ngModel)]="pdfGenerationSettings.includeItemImageThumbnails"></p-inputSwitch>
    </div>

    <div>
      <div class="flex flex-row justify-content-between align-content-center">
        <p class="mb-2 mr-2">Include image section under item list</p>
        <i class="pi pi-info-circle" (click)="opImageSection.show($event)" style="padding-top: 1.2em; cursor: pointer"></i>
      </div>
    </div>
    <p-inputSwitch [(ngModel)]="pdfGenerationSettings.includeItemListImageSection"></p-inputSwitch>

    <!--    <ng-container *ngIf="pdfGenerationSettings.includeItemListImageSection && !pdfGenerationSettings.includeItemImageThumbnails">-->
    <!--      <div>-->
    <!--        <p>Image size:</p>-->
    <!--        <div class="overlay__radio-buttons-container">-->
    <!--          <div class="field-radiobutton">-->
    <!--            <p-radioButton name="size" value="small" [(ngModel)]="size" inputId="size1"></p-radioButton>-->
    <!--            <label for="size1">Small</label>-->
    <!--          </div>-->
    <!--          <div class="field-radiobutton">-->
    <!--            <p-radioButton name="size" value="medium" [(ngModel)]="size" inputId="size2"></p-radioButton>-->
    <!--            <label for="size2">Medium</label>-->
    <!--          </div>-->
    <!--          <div class="field-radiobutton">-->
    <!--            <p-radioButton name="size" value="large" [(ngModel)]="size" inputId="size3"></p-radioButton>-->
    <!--            <label for="size3">Large</label>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </ng-container>-->
    <div *ngIf="data?.report?.reportType === 'LEGACY'">
      <div class="flex flex-row justify-content-between align-content-center">
        <p class="mb-2">Connected items section</p>
        <i class="pi pi-info-circle" (click)="opConnectedItems.show($event)" style="padding-top: 1.2em; cursor: pointer"></i>
      </div>

      <div class="flex flex-column gap-3 my-2">
        <p-radioButton
          name="groupname"
          label="All items"
          value="full"
          [(ngModel)]="pdfGenerationSettings.connectedItemsSection"
        ></p-radioButton>
        <p-radioButton
          name="groupname"
          label="Attached items"
          value="filtered"
          [(ngModel)]="pdfGenerationSettings.connectedItemsSection"
        ></p-radioButton>
        <p-radioButton name="groupname" label="None" value="none" [(ngModel)]="pdfGenerationSettings.connectedItemsSection"></p-radioButton>
      </div>
    </div>
    <button style="width: 100%" pButton type="button" label="Generate PDF" (click)="generatePdfClicked()"></button>
  </ng-template>

  <div *ngIf="pdfGenerationState == 'initial'" class="overlay__image-container" [ngSwitch]="true">
    <ng-container *ngSwitchCase="pdfGenerationSettings.includeItemListImageSection && !pdfGenerationSettings.includeItemImageThumbnails">
      <ng-container [ngSwitch]="size">
        <img *ngSwitchCase="'small'" src="/assets/images/items-reports/image-under-item-list.svg" />
        <img *ngSwitchCase="'medium'" src="/assets/images/items-reports/text-with-medium-image.svg" />
        <img *ngSwitchCase="'large'" src="/assets/images/items-reports/text-with-big-image.svg" />
      </ng-container>
    </ng-container>

    <!-- Section with text -->
    <img
      *ngSwitchCase="!pdfGenerationSettings.includeItemImageThumbnails && !pdfGenerationSettings.includeItemListImageSection"
      src="/assets/images/items-reports/image-text-list.svg"
    />
    <!-- Section with text, thumbnails and images -->
    <img
      *ngSwitchCase="pdfGenerationSettings.includeItemImageThumbnails && pdfGenerationSettings.includeItemListImageSection"
      src="/assets/images/items-reports/text-thumbnails-and-images.svg"
    />
    <!-- Section with text and thumbnails -->
    <img
      *ngSwitchCase="pdfGenerationSettings.includeItemImageThumbnails && !pdfGenerationSettings.includeItemListImageSection"
      src="/assets/images/items-reports/text-with-thumbnails.svg"
    />
  </div>
</div>
<!--
<div *ngIf="canUploadToNextProject">
  <div class="flex flex-row justify-content-between align-content-center">
    <p class="mb-2 mr-2">Upload to NeXT Project</p>
    <i class="pi pi-info-circle" (click)="opNextProjectUpload.show($event)" style="padding-top: 1.2em; cursor: pointer"></i>
  </div>

  <p-inputSwitch [(ngModel)]="pdfGenerationSettings.uploadPdfToNextProject"></p-inputSwitch>
</div>
-->
