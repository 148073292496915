import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { UntypedFormControl, Validators } from "@angular/forms"

export interface EmailWithRoles {
  email: string
  roles: string[]
}

@Component({
  selector: "email-invitation",
  templateUrl: "./email-invitation.component.html",
  styleUrls: ["./email-invitation.component.scss"],
})
export class EmailInvitationComponent implements OnInit {
  // emails: string[] = [];
  emails: EmailWithRoles[] = []
  @Input() showInviteButton: boolean = false
  @Output() emailsChanged = new EventEmitter()
  @Output("sendInvites") sendInvitesEmitter = new EventEmitter()

  invitationEmail: UntypedFormControl = new UntypedFormControl("", Validators.email)

  constructor() {}

  ngOnInit() {}

  get haveEmails() {
    return this.emails != null && this.emails.length > 0
  }

  get isValidInvitationEmail() {
    return this.invitationEmail.valid
  }

  removeEmail(email: string) {
    this.emails = this.emails.filter((emailWithRole) => emailWithRole.email !== email)
    this.emailsChanged.emit(this.emails)
  }

  addInvitationEmail() {
    const email = this.invitationEmail.value
    if (this.emails.map((e) => e.email).includes(email)) return
    this.emails.push({ email: email, roles: ["DEFAULT"] })
    this.invitationEmail.setValue("")
    this.emailsChanged.emit(this.emails)
  }

  addEmail(event: any) {
    if (!event.target.validity.valid) return
    if (this.emails.map((e) => e.email).includes(event.target.value)) return
    this.emails.push({ email: event.target.value, roles: ["DEFAULT"] })
    event.target.value = ""
    this.emailsChanged.emit(this.emails)
  }

  sendInvites() {
    this.sendInvitesEmitter.emit(this.emails)
  }

  get shouldShowInviteButton() {
    return this.showInviteButton && this.haveEmails
  }

  updateInvitedEmail(email: string, label: string) {
    let emailToUpdate = this.emails.find((e) => e.email == email)
    emailToUpdate!.roles = [label]
  }
}
