import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-chat-bubble-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3303_16573)">
        <path
          d="M12.2167 1.48154C5.57692 1.48154 0.196877 5.85328 0.196877 11.2487C0.196877 13.4853 1.1318 15.5336 2.6814 17.1747C1.98267 19.0295 0.527458 20.597 0.503979 20.6144C0.192886 20.9431 0.11071 21.4244 0.286801 21.8352C0.468799 22.2461 0.870848 22.5185 1.32258 22.5185C4.21046 22.5185 6.44564 21.3093 7.85436 20.3467C9.16917 20.7717 10.6812 21.0158 12.2167 21.0158C18.8565 21.0158 24.1956 16.6427 24.1956 11.2487C24.1956 5.85468 18.8565 1.48154 12.2167 1.48154ZM12.2214 18.7619C10.9653 18.7619 9.72703 18.5682 8.54088 18.1928L7.4726 17.8582L6.55693 18.5039C5.88778 18.9791 4.96601 19.5078 3.85687 19.8656C4.20318 19.2965 4.53165 18.6565 4.79039 17.9756L5.28908 16.6608L4.32081 15.6338C3.47417 14.7282 2.45285 13.2303 2.45285 11.2487C2.45285 7.10469 6.83163 3.73549 12.22 3.73549C17.6084 3.73549 21.9871 7.10469 21.9871 11.2487C21.9871 15.3927 17.6074 18.7619 12.2214 18.7619Z"
          fill="currentcolor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3303_16573">
          <rect [attr.width]="getSize()" [attr.height]="getSize()" fill="white" transform="translate(0.195557)" />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class ChatBubbleIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }
}
