import { Component, OnDestroy, OnInit } from "@angular/core"
import { CompanyFeatures } from "@models/common"
import { UserService } from "@services"
import { Observable, Subscription } from "rxjs"
import { tap } from "rxjs/operators"
import { FormsLibraryViewService } from "../../../../services/forms-library-view.service"
import { PublicTemplatesViewService } from "../../../../services/public-templates-view.service"
import { FormsLibraryConfigService } from "../../../../edit-forms-template-dialog/services/forms-library-config.service"

@Component({
  selector: "checkd-template-showcase",
  templateUrl: "./template-showcase.component.html",
  styleUrls: ["./template-showcase.component.scss"],
})
export class TemplateShowcaseComponent implements OnInit, OnDestroy {
  templatesAreLoading = true
  private subscriptions = new Subscription()

  constructor(
    public flViewService: FormsLibraryViewService,
    public flConfigService: FormsLibraryConfigService,
    private publicTemplatesViewService: PublicTemplatesViewService,
    private userService: UserService
  ) {}

  selectedTags: string[] = []

  onTagSelectionChanged(tagChangeEvent: { checked: boolean; label: string }) {
    if (tagChangeEvent.checked) {
      this.selectedTags = [...this.selectedTags, tagChangeEvent.label]
    } else {
      this.selectedTags = this.selectedTags.filter((tag) => tag !== tagChangeEvent.label)
    }
  }

  onFindTemplatesClicked() {
    this.flViewService.setCompanyAndTagsSearchFilter({ predefinedTags: this.selectedTags })
    this.flViewService.displayedComponent = "browse_all_templates"
  }

  public readonly currentCompanyCanUseFormsBuilder$: Observable<boolean> = this.userService.currentCompanyHasFeature(CompanyFeatures.FORMS)

  ngOnInit(): void {
    this.subscriptions.add(
      this.flViewService.availableTemplatesFiltered$.pipe(tap((data) => (this.templatesAreLoading = data.isLoading))).subscribe()
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  async onTemplateCardClicked(templateUid: string) {
    await this.publicTemplatesViewService.navigateToLandingPageForTemplate(templateUid)
  }
}
