import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { ModelInCollection } from "@models/common/collections"
import { SUB_COLLECTIONS } from "@models/common/collections.interface"
import { ITagCollectionData, TagCollection } from "@models/common/tag-collection"
import firebase from "firebase/compat/app"
import firestore = firebase.firestore
import { Observable } from "rxjs"
import { map } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class TagCollectionService {
  constructor(private db: AngularFirestore) {}

  listenFor(m: ModelInCollection): Observable<TagCollection[]> {
    return this.db
      .doc(m!.ref!.path)
      .collection(SUB_COLLECTIONS.TAG_COLLECTIONS)
      .snapshotChanges()
      .pipe(map((docs) => docs.map((doc) => new TagCollection(doc.payload.doc.ref, doc.payload.doc.data() as ITagCollectionData))))
  }

  addTagCollection(m: ModelInCollection, data: ITagCollectionData) {
    // @ts-ignore
    return m.ref.collection(SUB_COLLECTIONS.TAG_COLLECTIONS).add(data)
  }

  addTagCollectionBatch(batch: firestore.WriteBatch, m: ModelInCollection, data: ITagCollectionData): firestore.WriteBatch {
    // @ts-ignore
    const ref = m.ref.collection(SUB_COLLECTIONS.TAG_COLLECTIONS).doc()

    return batch.set(ref, data, { merge: true })
  }
}
