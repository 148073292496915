import { Component, Input, OnInit } from "@angular/core"

@Component({
  selector: "checkd-report-topbar-info",
  templateUrl: "./report-topbar-info.component.html",
  styleUrls: ["./report-topbar-info.component.scss"],
})
export class ReportTopbarInfoComponent {
  @Input() creatorName: string
  @Input() projectTitle: string
  @Input() createdAt: number
  @Input() lastTimelineElementMessage: string
  @Input() templatePublicVersion: string | null
  @Input() reportStatus: string
}
