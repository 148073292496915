<div class="element-screen">
  <mat-accordion displayMode="default">
    <mat-expansion-panel *ngFor="let c of child | slice:1; let i=index">
      <mat-expansion-panel-header>{{title + ' ' + (i + 1)}}</mat-expansion-panel-header>
      <app-form-element [element]="c" [readOnly]="readOnly"></app-form-element>
      <mat-action-row>
        <button [color]="'warn'" mat-button (click)="removeTabularElement(i)" [disabled]="readOnly">Remove</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>

  <button class="add-element-button" mat-stroked-button (click)="addTabularElement()"
          [disabled]="!formMessageService.isFieldReport && readOnly">
    <mat-icon>add_circle_outline</mat-icon>
    <span>{{title}}</span>
  </button>
</div>

<span class="element-print">
  <span *ngFor="let c of child | slice:1; let i=index">
    <p>{{title + ' ' + (i + 1)}}</p>
    <app-form-element [element]="c"></app-form-element>
  </span>
</span>
