import { Component, EventEmitter, Output } from "@angular/core"
import { FormElementComponent } from "../form-element/form-element.component"

@Component({
  selector: "app-form-details",
  templateUrl: "./form-details.component.html",
  styleUrls: ["./form-details.component.scss"],
})
export class FormDetailsComponent extends FormElementComponent {
  @Output() contentChange = new EventEmitter()

  get values() {
    if (this.element == null || this.element.values == null) {
      return []
    }

    return this.element.values
  }

  onContentChange(element: any) {
    this.contentChange.emit(this.element.transformAttributes().values)
  }
}
