<div class="wrapper">
  <mat-form-field>
    <mat-label>{{ placeholder }}</mat-label>
    <input type="text" [readonly]="true" matInput [matMenuTriggerFor]="menu" [ngModel]="searchBoxString" />
  </mat-form-field>
</div>

<mat-menu #menu="matMenu">
  <div class="menu-wrapper" (click)="$event.stopPropagation()">
    <div class="column-selection-item">
      <mat-checkbox (change)="selectAllClicked()" [checked]="allSelected()" [indeterminate]="someSelected()">
        {{ allSelected() ? "Deselect all" : "Select all" }}
      </mat-checkbox>
    </div>

    <mat-divider></mat-divider>

    <section class="search-wrapper">
      <label class="flex">
        <input type="text" matInput placeholder="Search..." [(ngModel)]="searchString" />
        <mat-icon style="color: rgb(148, 148, 148)">search</mat-icon>
      </label>
    </section>

    <mat-divider></mat-divider>

    <section class="list-wrapper">
      <div class="column-selection-item" *ngFor="let value of _filteredValues; let i = index">
        <mat-checkbox
          [checked]="value.checked"
          [indeterminate]="value.checked === null"
          [color]="value.checked === null ? 'warn' : 'accent'"
          (change)="checkBoxClicked(i)"
        >
        </mat-checkbox>

        <span class="checkbox__text truncate">{{ value.name }}</span>
      </div>
    </section>
  </div>
</mat-menu>
