import { Component } from "@angular/core"
import { FormElement } from "../../../models/form-element"
import { DefaultElementComponent } from "../default-element/default-element.component"

@Component({
  selector: "app-tabular-form-element",
  templateUrl: "./tabular-form-element.component.html",
  styleUrls: ["./tabular-form-element.component.scss"],
})
export class TabularFormElementComponent extends DefaultElementComponent {
  addTabularElement() {
    this.addChild(this.tabularElementDeepCopy(this.element.child![0]))
  }

  removeTabularElement(index: number) {
    this.removeChild(index)
  }

  private tabularElementDeepCopy(element: FormElement): FormElement {
    const keysToCopy = ["type", "values"]
    const formElementCopy = FormElement.fromJson(JSON.parse(JSON.stringify(element)))

    for (const key of Object.keys(formElementCopy)) {
      if (keysToCopy.includes(key)) {
        continue
      }

      // @ts-ignore
      delete formElementCopy[key]
    }

    return formElementCopy
  }
}
