import { Component, OnInit } from "@angular/core"

@Component({
  selector: "checkd-tag",
  templateUrl: "./checkd-tag.component.html",
  styleUrls: ["./checkd-tag.component.scss"],
})
export class CheckdTagComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
