<mat-toolbar *ngIf="form" class="fixed-header mat-elevation-z6 small">
  <mat-toolbar-row>
    <div fxLayout="row" fxFill>
      <div fxFlex="10" fxLayoutAlign="start">
        <button (click)="goToReportsList()" mat-icon-button>
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
      </div>
      <div fxFlex="80" fxLayoutAlign="center center">
        <span class="title">#{{form.number}}: {{form.reportName}}</span>
      </div>
      <div fxFlex="10" fxLayoutAlign="end">
        <button mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row>
    <div fxLayout="row" fxLayoutGap="40px" fxFlex fxLayoutAlign="center">
      <div fxLayoutGap="15px" fxHide.xs>
        <span><strong>Status of report: </strong>{{ form.reportStatus | reportStatus }}</span>
        <!--<span><strong>Prepared</strong> by Mikel von Krogh</span>-->
      </div>
      <div class="remove-gap">
        <span><strong>Project: </strong>{{ projectTitle }}</span>
      </div>
      <div fxHide.xs>
        <span><strong>Updated: </strong>{{ (form.modifiedDateTime) ?  (form.modifiedDateTime | amTimeAgo) : 'Not Saved Yet'  }}</span>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div *ngIf="form" class="form-pages" fxLayout="column" fxLayoutGap="30px">
  <mat-card>
    <mat-card-content>
      <app-form-header [element]="form.header"></app-form-header>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <app-form-details [element]="form.details"></app-form-details>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="form" class="fixed-footer small">
  <div fxLayout="row" fxFlex>
    <div fxLayoutAlign="start center" fxFlex>
      <button mat-button (click)="previewPdf()"><mat-icon class="pdf-icon">picture_as_pdf</mat-icon></button>
    </div>
    <div fxLayoutAlign="end" matTooltip="{{ getSaveButtonToolTip() }}" [matTooltipPosition]="'above'">
      <button mat-button [disabled]="!isFormChanged" (click)="saveForm()" [color]="'primary'">SAVE</button>
    </div>
  </div>
</div>
