<div class="people-list-item" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between">
  <span fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="start center">
    <ngx-avatars [src]="image" [name]="avatarName" size="30"></ngx-avatars>
    <div class="details" fxLayout="column">
      <div>{{ name }}</div>
      <div>{{ email }}</div>
    </div>
  </span>
  <div>
    <ng-content></ng-content>
  </div>
</div>
