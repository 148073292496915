import { Style } from "./styles.docdefinition"

interface IBodyCell {
  colSpan?: number
  rowSpan?: number
  styles?: Style[]
  element?: any
}

export const tableCell = (element: any, config: IBodyCell = {}): IBodyCell => ({
  style: config.styles || [],
  ...(element || {}),
  ...(config || {}),
})

export const tableRow = (...cells: IBodyCell[]): IBodyCell[] => cells
export const tableHeader = (...rows: IBodyCell[][]) => ({ headerRows: rows })
export const tableBody = (...rows: IBodyCell[][]) => ({ bodyRows: rows })

export const table = (...config: any[]) => createTable(Object.assign({}, ...config))

interface ITableConfig {
  headerRows?: IBodyCell[][]
  bodyRows: IBodyCell[][]
  layout?: any
  columnWidths: string[]
}
/*
const defaultTableLayout = {
  fillColor: function(rowIndex, node, columnIndex) {
    if (rowIndex === 0) {
      return '#164790'
    }
  },
  vLineColor: function(i, node) {
    return '#848484'
  },
  hLineColor: function(i, node) {
    return '#848484'
  }
}
 */

export const defaultTableLayout = {
  fillColor: (_: any) => "white",
  vLineColor: (_: any) => "black",
  hLineColor: (_: any) => "black",
}

export const nestedTableLayout = {
  fillColor: (_: any) => "white",
  hLineWidth: (i: number, node: any) => (i === 0 || i === node.table.body.length ? 0 : 1),
  vLineWidth: (i: number, node: any) => (i === 0 || i === node.table.widths.length ? 0 : 1),

  /*
  hLineWidth: (i, node) => (i === 0 || i === node.table.body.length) ? 0 : 1,
  vLineWidth: (i, node) => (i === 0 || i === node.table.widths.length) ? 0 : 1,
   */
  paddingLeft: (_: any) => 0,
  paddingRight: (_: any) => 0,
  paddingTop: (_: any) => 0,
  paddingBottom: (_: any) => 0,
}

export const nestableTableLayout = {
  fillColor: (_: any) => "white",
  vLineColor: (_: any) => "black",
  hLineColor: (_: any) => "black",
  vLineWidth: (_: any) => 1,
  vLineHeight: (_: any) => 1,
}

function processCellColspan(cell: IBodyCell, i: number, columnCount: number) {
  // Don't set colSpans for empty cells
  if (Object.keys(cell).length === 0) {
    return cell
  }

  // Make sure all non-empty cells get a colSpan of 1 (default)
  if (cell.colSpan == null) {
    return {
      ...cell,
      colSpan: 1,
    }
  }

  // A colSpan of 0 is interpreted as max colspan
  if (cell.colSpan === 0) {
    return {
      ...cell,
      colSpan: columnCount - i,
    }
  }

  // Keep the colSpan if it doesn't exceed the length of the row from its position
  if (cell.colSpan + i < columnCount) {
    return cell
  }

  const safeColspan = cell.colSpan - (cell.colSpan + i - columnCount)

  return {
    ...cell,
    colSpan: safeColspan,
  }
}

function processRow(row: IBodyCell[], columnCount: number) {
  const defaultRow = Array.from(Array(columnCount)).map((_) => ({ text: " " }))
  const filledRow = defaultRow.map((v, i) => (i < row.length && row[i] ? row[i] : v))
  // @ts-ignore
  const safeColspansRow = filledRow.map((cell, i) => processCellColspan(cell, i, columnCount))

  return safeColspansRow
}

function createTable(config: ITableConfig) {
  if (!config.bodyRows || config.bodyRows.length < 1) {
    return {
      table: {
        body: [[]],
        headerRows: 0,
        widths: [],
      },
      layout: {
        ...defaultTableLayout,
      },
    }
  }

  const headerRowCount = (config.headerRows || []).length

  const body = [...(config.headerRows || []), ...(config.bodyRows || [])]

  const columnCount = Math.max(...body.map((row) => row.length), (config.columnWidths || []).length)

  const completeBody = body.map((row) => processRow(row, columnCount))
  const columnWidths = Array.from(Array(columnCount)).map((_) => "*")

  for (let i = 0; i < (config.columnWidths || []).length; i++) {
    columnWidths[i] = config.columnWidths[i]
  }

  return {
    table: {
      body: completeBody,
      headerRows: headerRowCount,
      widths: columnWidths,
    },
    layout: {
      ...defaultTableLayout,
      ...(config.layout || {}),
    },
  }
}
