import { Component, EventEmitter, Input, Output } from "@angular/core"

import { MatDialog } from "@angular/material/dialog"
import { FormMessageService } from "@checkd-form/services/form-message.service"

import { FormElement } from "../../../models/form-element"
import { InfoDialogComponent } from "./info-dialog/info-dialog.component"

enum FormComponents {
  default = "default",
  time = "time",
  date = "date",
  gps = "gps",
  latitude = "latitude",

  // We need both longtitude and longitude due to spelling errors in the data
  longtitude = "longtitude",
  longitude = "longitude",
  dropdown = "dropdown",
  dropdownItem = "dropdown-item",
  checkbox = "checkbox",
  checkboxItem = "checkbox-item",
  checkboxRadioItem = "checkbox-radio-item",
  text = "text",
  textfield = "textfield",
  textfieldint = "textfieldint",
  multilinetextfield = "multilinetextfield",
  signature = "signature",

  // NB These elements are defined differently in different forms.
  mainandsubfield = "mainandsubfield",
  main = "main",
  subfield = "subfield",
  header = "header",
  subject = "subject",

  address = "address",
  slider = "slider",
  camera = "camera",
  tabularform = "tabularform",
  table = "table",
  updown = "updown",
  yesno = "yesno",
  image = "image",
  empty = "empty",
  fieldreport = "fieldreport",
  drawing = "drawing",
  itemlist = "itemlist",
  formsitems = "formsitems",
}

@Component({
  selector: "app-form-element",
  templateUrl: "./form-element.component.html",
  styleUrls: ["./form-element.component.scss"],
})
export class FormElementComponent {
  @Input() element: FormElement
  @Input() elementType: string
  @Input() parentElement: FormElement
  @Input() config: { [key: string]: any }
  @Input() readOnly = false
  @Output() valueChanged = new EventEmitter()

  public components = FormComponents

  constructor(protected formMessageService: FormMessageService, protected dialogRef: MatDialog) {}

  public getElementType() {
    // console.log(this.element.type)
    // if (this.element.type == 'text') { debugger }

    // NB The element types are described differently in different
    // reports (either in the type or name attribute).
    // NB2 We also need to convert to lowercase to handle different case
    // variations in the data
    return (this.elementType || this.element.type || this.element.name || "default").toLowerCase()
  }

  hasInfo() {
    return this.element && this.element.info ? this.element.info.length > 0 : false
  }

  openInfoDialog() {
    this.dialogRef.open(InfoDialogComponent, {
      data: this.element.info,
    })
  }
}
