import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-magnifying-glass-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.1326 27.5781L22.8514 21.2969C24.2108 19.6328 24.9608 17.5375 24.9608 15.25C24.9608 9.86406 20.5953 5.5 15.2108 5.5C9.82622 5.5 5.50293 9.86547 5.50293 15.25C5.50293 20.6345 9.86794 25 15.2108 25C17.4978 25 19.5959 24.2073 21.2576 22.8888L27.5389 29.17C27.8014 29.3922 28.092 29.5 28.3779 29.5C28.6639 29.5 28.9536 29.3901 29.1734 29.1704C29.6108 28.7312 29.6108 28.0187 29.1326 27.5781ZM7.75294 15.25C7.75294 11.1147 11.1176 7.75 15.2529 7.75C19.3883 7.75 22.7529 11.1147 22.7529 15.25C22.7529 19.3853 19.3883 22.75 15.2529 22.75C11.1176 22.75 7.75294 19.3844 7.75294 15.25Z"
        fill="black"
      />
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class MagnifyingGlassIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }
}
