import { Component, OnInit } from "@angular/core"
import { DefaultElementComponent } from "@checkd-form/form-view/elements/default-element/default-element.component"

@Component({
  selector: "checkd-table-element",
  templateUrl: "./table-element.component.html",
  styleUrls: ["./table-element.component.scss"],
})
export class TableElementComponent extends DefaultElementComponent implements OnInit {
  override ngOnInit(): void {}

  get tableChild() {
    // @ts-ignore
    if (Array.isArray(this.element?.child[0])) {
      // @ts-ignore
      return this.element?.child[0] ?? []
    }

    // @ts-ignore
    return this.element?.child[0].values ?? []
  }
}
