<mat-dialog-content>
  <item-creation-view
    [prefill]="prefill"
    [item]="item"
    [project]="project"
    [projectMembers]="projectMembers"
    [currentUser]="currentUser"
    (onSave)="save($event)"
  >
  </item-creation-view>
</mat-dialog-content>
