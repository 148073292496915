<div class="all-templates">
  <!-- <div class="all-templates__go-back-container">
    <button tertiary-button (click)="flViewService.toggleDisplayedComponent()">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.7732 15.7251L3.97762 8.63263C3.77795 8.3951 3.69556 8.17242 3.69556 7.98314C3.69556 7.79386 3.77791 7.53888 3.94378 7.36742L10.7394 0.274927C11.079 -0.0811446 11.6427 -0.0927242 11.9975 0.248253C12.3547 0.586919 12.3664 1.15272 12.0242 1.50679L5.81996 7.98314L12.0551 14.4929C12.3973 14.8455 12.3857 15.4126 12.0284 15.7515C11.6751 16.0926 11.1109 16.0814 10.7732 15.7251Z"
          fill="currentcolor"
        />
      </svg>
      <span>Forms library</span>
    </button>
  </div> -->

  <div class="mx-auto all-templates__search-container">
    <checkd-forms-library-search-bar class="col"></checkd-forms-library-search-bar>
  </div>
  <div class="all-templates__content">
    <!--    <div class="content__filter-container">-->
    <!--      <checkd-filter-templates></checkd-filter-templates>-->
    <!--    </div>-->
    <h2 class="content__table-title">Browse all templates</h2>
    <div class="content__table-container">
      <ng-container *ngIf="flViewService.availableTemplatesFiltered$ | async as templateData">
        <ng-template #loadingSpinner>
          <p-progressSpinner class="grid m-auto"></p-progressSpinner>
        </ng-template>
        <ng-container *ngIf="!templateData.isLoading; else loadingSpinner">
          <ng-template #emptySearchResult>
            <section class="empty-search">
              <div class="empty-search__titles">
                <h2>No forms matching your search?</h2>
                <h5 class="text-center">We can help you get what you need, or you can build your own</h5>
              </div>

              <div class="flex gap-3 mt-3 flex-column">
                <button primary-button [type]="'outline'" id="intercom-chat-with-support"><span>Chat with support</span></button>
                <button *ngIf="currentCompanyHasFormsFeature$ | async" primary-button routerLink="/forms/builder">
                  <span>Try Forms Builder</span>
                </button>
              </div>
              <div class="empty-search__empty-img-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                  viewBox="0 0 647.63626 632.17383"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <path
                    d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z"
                    transform="translate(-276.18187 -133.91309)"
                    fill="#f2f2f2"
                  />
                  <path
                    d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z"
                    transform="translate(-276.18187 -133.91309)"
                    fill="#3f3d56"
                  />
                  <path
                    d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z"
                    transform="translate(-276.18187 -133.91309)"
                    fill="#075643"
                  />
                  <circle cx="190.15351" cy="24.95465" r="20" fill="#075643" />
                  <circle cx="190.15351" cy="24.95465" r="12.66462" fill="#fff" />
                  <path
                    d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z"
                    transform="translate(-276.18187 -133.91309)"
                    fill="#e6e6e6"
                  />
                  <path
                    d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z"
                    transform="translate(-276.18187 -133.91309)"
                    fill="#3f3d56"
                  />
                  <path
                    d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z"
                    transform="translate(-276.18187 -133.91309)"
                    fill="#075643"
                  />
                  <circle cx="433.63626" cy="105.17383" r="20" fill="#075643" />
                  <circle cx="433.63626" cy="105.17383" r="12.18187" fill="#fff" />
                </svg>
              </div>
            </section>
          </ng-template>
          <ng-container *ngIf="templateData.legacyTemplates.length > 0; else emptySearchResult">
            <p-table
              [columns]="cols"
              [value]="templateData.legacyTemplates"
              [paginator]="true"
              [scrollable]="true"
              styleClass="p-datatable-sm table"
              [rows]="15"
              [rowsPerPageOptions]="[15, 30, 45]"
              responsiveLayout="stack"
              [breakpoint]="'960px'"
              [showCurrentPageReport]="true"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [resizableColumns]="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <!-- <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th> -->
                  <ng-container *ngFor="let col of cols">
                    <th pResizableColumn [pSortableColumn]="col.field" [class]="col.field">
                      <div class="table__header-cell">
                        <span>{{ col.header }}</span>
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                      </div>
                    </th>
                  </ng-container>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-content let-columns="columns">
                <tr>
                  <ng-container *ngFor="let col of columns">
                    <td [ngSwitch]="col.field" [class]="col.field" (click)="navigateToTemplateLandingPage(content)">
                      <ng-container *ngSwitchCase="'name'"
                        ><span class="col-content">{{ content[col.field] }}</span></ng-container
                      >
                      <ng-container *ngSwitchCase="'tags'">
                        <div *ngIf="content['tags']" class="flex flex-wrap gap-2 mt-1">
                          <p-tag *ngFor="let tag of content['tags']" [value]="tag"></p-tag>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="'updatedAt'"
                        ><span class="col-content">{{ content[col.field] | date: "mediumDate" }}</span></ng-container
                      >
                      <ng-container *ngSwitchCase="'templateUpdateInfo'"
                        ><span class="col-content">{{ content[col.field] }}</span></ng-container
                      >
                    </td>
                  </ng-container>
                </tr>
              </ng-template>
            </p-table>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
