<ng-container *ngIf="isTimeline; then event; else userContent"></ng-container>

<ng-template #userContent>
  <div [fxLayout]="layoutClass" fxFlexAlign="{{ alignment }}" class="timeline-event-wrapper">
    <div fxLayout="column" [ngSwitch]="timelineElement.type" fxLayoutGap="5px">
      <ng-container *ngSwitchCase="'COMMENT_ADDED'">
        <timeline-comment-element [creator]="creator" [currentUser]="currentUser" [timeline]="timelineElement"></timeline-comment-element>
      </ng-container>

      <ng-container *ngSwitchCase="'IMAGE_ADDED'">
        <timeline-image-element [creator]="creator" [currentUser]="currentUser" [timeline]="timelineElement"></timeline-image-element>
      </ng-container>

      <div fxLayout="{{ layoutClass }}" fxLayoutGap="10px">
        <div class="circle shadow" fxFlexAlign="center center">
          <ngx-avatars [src]="creatorImage" [name]="creatorName(false)" size="30"> </ngx-avatars>
        </div>
        <div fxLayout="column" fxLayoutAlign="{{ isCreatorCurrentUser() ? 'start end' : 'start start' }}">
          <span>
            <span matTooltip="{{ infoTooltipMessage('creator') }}">{{ creatorName() | titlecase }}</span>
            <ng-container *ngIf="timelineElement.type == 'IMAGE_ADDED'"> added an image</ng-container>
          </span>
          <span class="user-content-date-text">{{ formatTimestamp(timelineElement.data.createdDate) }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #event>
  <div fxLayout="column" fxLayoutAlign="center center" class="timeline-event-wrapper">
    <div class="circle shadow">
      <ngx-avatars [src]="creatorImage" [name]="creatorName(false)" size="30"></ngx-avatars>
    </div>

    <span class="created-date">{{ formatTimestamp(timelineElement.data.createdDate) }}</span>

    <span [ngSwitch]="timelineElement.type" class="centered-text">
      <ng-container *ngSwitchCase="'STATUS_CHANGE'">
        <b matTooltip="{{ infoTooltipMessage('creator') }}">{{ creatorName() | titlecase }}</b>
        {{ statusChangeMessage }}
      </ng-container>

      <ng-container *ngSwitchCase="'ASSIGNEE_ADDED'">
        <b matTooltip="{{ infoTooltipMessage('creator') }}">{{ creatorName() | titlecase }}</b> assigned the task
        <ng-container *ngIf="targetName"
          >to <b matTooltip="{{ infoTooltipMessage('target') }}">{{ targetName }}</b></ng-container
        >
      </ng-container>

      <ng-container *ngSwitchCase="'ASSIGNEE_INVITED'">
        <b matTooltip="{{ infoTooltipMessage('creator') }}">{{ creatorName() | titlecase }}</b> invited
        <b *ngIf="inviteeName; else noInviteeName" matTooltip="{{ infoTooltipMessage('invitee') }}">
          {{ inviteeName | titlecase }}
        </b>
        <ng-template #noInviteeName>a person</ng-template>
        to this task
      </ng-container>

      <ng-container *ngSwitchCase="'ITEM_CREATED'">
        <b matTooltip="{{ infoTooltipMessage('creator') }}">{{ creatorName() | titlecase }}</b> created the item
      </ng-container>

      <ng-container *ngSwitchCase="'ITEM_EDITED'">
        <b matTooltip="{{ infoTooltipMessage('creator') }}">{{ creatorName() | titlecase }}</b> edited this item
      </ng-container>

      <ng-container *ngSwitchCase="'ITEM_EXPORTED_TO_BCF'">
        <b matTooltip="{{ infoTooltipMessage('creator') }}">{{ creatorName() | titlecase }}</b> exported this item to BCF
      </ng-container>

      <ng-container *ngSwitchCase="'COLLABORATOR_ADDED'">
        <b matTooltip="{{ infoTooltipMessage('creator') }}">{{ creatorName() | titlecase }}</b> added
        <ng-container *ngIf="targetName">
          <b matTooltip="{{ infoTooltipMessage('target') }}">{{ targetName | titlecase }}</b> as
        </ng-container>
        a collaborator
      </ng-container>

      <ng-container *ngSwitchCase="'IMAGE_REMOVED'">
        <b matTooltip="{{ infoTooltipMessage('creator') }}">{{ creatorName() | titlecase }}</b> deleted an image
      </ng-container>

      <ng-container *ngSwitchCase="'DUE_DATE_SET'">
        <b matTooltip="{{ infoTooltipMessage('creator') }}">{{ creatorName() | titlecase }}</b> {{ dueDateSetMessage }}
      </ng-container>

      <ng-container *ngSwitchCase="'ITEM_CONNECTED_TO_REPORT'">
        <ng-container *ngIf="!timelineElement.disabled; then itemConnected; else itemConnectedDisabled"></ng-container>

        <ng-template #itemConnected>
          <strong matTooltip="{{ infoTooltipMessage('creator') }}">{{ creatorName() | titlecase }}</strong>
          attached the item to {{ reportName && reportUid && legacyReportUrl ? "the" : "a" }} report

          <ng-container *ngIf="reportName && reportUid && legacyReportUrl">
            <a href="{{ legacyReportUrl }}" target="_blank">{{ reportName }}</a>
          </ng-container>
        </ng-template>

        <ng-template #itemConnectedDisabled>
          The item attached to the report <strong>{{ reportName }}</strong> by
          <strong matTooltip="{{ infoTooltipMessage('creator') }}">{{ creatorName() | titlecase }}</strong> was detached by
          <strong>{{ disablerName }}</strong> on {{ formatTimestamp(timelineElement.disabledAt) }}
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="'ITEM_REMOVED_FROM_REPORT'">
        <b matTooltip="{{ infoTooltipMessage('creator') }}">{{ creatorName() | titlecase }}</b> detached the item from
        {{ reportName ? "the" : "a" }} report
        <ng-container *ngIf="reportName"
          ><strong>{{ reportName }}</strong></ng-container
        >
      </ng-container>

      <!-- Disabled image -->
      <ng-container *ngSwitchCase="'IMAGE_ADDED'">
        <ng-container *ngIf="this.creatorName() && this.disablerName">
          This image uploaded by
          <b matTooltip="{{ infoTooltipMessage('creator') }}">{{ creatorName() | titlecase }}</b>
          was deleted by <b>{{ disablerName }}</b> on {{ formatTimestamp(timelineElement.disabledAt) }}
        </ng-container>
      </ng-container>
    </span>
  </div>
</ng-template>
