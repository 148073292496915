<mat-card>
  <mat-card-content>
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-icon>lock</mat-icon>
      <p class="locked">This project is locked</p>
      <ng-container *ngIf="(userCompany$ | async)?.uid == (projectCompany$ | async)?.uid">
        <p>Your company {{ (userCompany$ | async)?.name }} does not have an active <br>CHECKD <strong>subscription</strong>.</p>
        <p>Please contact sales to activate the subscription and access your project data.</p>
        <!-- <checkd-button fg="white" bg="blue" (buttonClick)="dialogRef.triggerIntercom()">Talk to us</checkd-button> -->
      </ng-container>

      <ng-container *ngIf="(userCompany$ | async)?.uid != (projectCompany$ | async)?.uid">
        <p>The company which created this project ({{ (projectCompany$ | async)?.name }}) does not have an active <br> CHECKD <strong>subscription</strong>.</p>
        <p>Please contact the project owner to get them to active the <br> subscription and access your project data.</p>
      <!-- <checkd-button fg="white" bg="blue">Contact project owner</checkd-button> -->
      </ng-container>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <checkd-button fg="white" bg="blue" (buttonClick)="dialogRef.triggerIntercom()">Talk to us</checkd-button>
    <a mat-stroked-button routerLink="./projects" (click)="dialogRef.close()">Go to project list</a>
  </mat-card-actions>
</mat-card>
