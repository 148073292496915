import {
  CompanyAdministratorRole,
  CompanyNormalRole,
  DummyRole,
  ItemCreatorRole,
  LegacyReportCreatorRole,
  PermissionRequirement,
  ProjectAdministratorRole,
  ProjectNormalRole,
  ProjectOwnerRole,
  ReportCreatorRole,
  Role,
  RoleType,
} from "."

import { ModelInCollection } from "../collections"
import { Relation } from "../relation"

export interface RoleCollection {
  [roleName: string]: Role
}

export class RoleHandler {
  requirementsMap: { [key: string]: any } = {}

  constructor(private roleCollection: RoleCollection, private roleTypePrecedences: RoleType[]) {
    this.populateRequirementsMap()
  }

  public fromRelation(relation: Relation) {
    const [sourceCollection, sourceUid, targetCollection, targetUid] = relation.ref!.path.split("/")

    const labels = relation.labels || []
    const search: PermissionRequirement = {
      sourceCollection,
      targetCollection,
      labels,
    }
    const filteredRoles = Object.keys(this.requirementsMap)
      .filter((k) => this.matchesRequirement(search, this.requirementsMap[k]))
      .map((k) => this.requirementsMap[k])
    const role = this.getHighestPrecedentRole(filteredRoles)

    role.roleData.sourceCollection = sourceCollection
    role.roleData.sourceUid = sourceUid
    role.roleData.targetCollection = sourceCollection
    role.roleData.targetUid = targetUid

    return role
  }

  /**
   * Returns a promised role based on the relation between the source
   * and target if one exists. If no relation is found, a dummy role
   * with no permissions is returned.
   *
   * This includes a get() call to the database that should be unnecessary as long as we have the relations loaded, which we usually do.
   *
   * @deprecated Use relation listeners combined with this.fromRelation() instead
   *
   * @param source a model (e.g. Person)
   * @param target a model (e.g. Company)
   */
  public getRoleFor<M1 extends ModelInCollection, M2 extends ModelInCollection, R extends Role>(source: M1, target: M2): Promise<R> {
    return Relation.get(source, target).then((rel) => {
      if (rel == null || rel.data == null || rel.data.disabled === true) {
        return new DummyRole() as R
      }

      return this.findInRequirementsMap(source, rel, target) as R
    })
  }

  /**
   * Returns a list of roles for toplevel documents accessible
   * from the target through relations, based on the relation between
   * the source and the target.
   *
   * @param source a model (e.g. Person)
   * @param target a model (e.g. Company)
   */
  public getInferredRolesFor<M1 extends ModelInCollection, M2 extends ModelInCollection>(source: M1, target: M2) {
    // @ts-ignore
    return this.getRoleFor(source, target).then((role) => Object.keys(role.inferredRoleTypes))
  }

  /**
   * Returns a role instance if there is a match.
   *
   * A match is found if the result of requirementToKeyString() on the
   * source collection name, the relation labels and the target
   * collection name matches one of the keys in the requirementsMap
   *
   * @param source A model (e.g. Person)
   * @param relation The Relation instance between the source and the target
   * @param target A model (e.g. Company)
   */
  public findInRequirementsMap<M1 extends ModelInCollection, M2 extends ModelInCollection, R extends Role>(
    source: M1,
    relation: Relation,
    target: M2
  ): R {
    const search: PermissionRequirement = {
      sourceCollection: source.collectionName,
      labels: relation.labels,
      targetCollection: target.collectionName,
    }

    const filteredRoles = Object.keys(this.requirementsMap)
      .filter((k) => this.matchesRequirement(search, this.requirementsMap[k]))
      .map((k) => this.requirementsMap[k])

    // @ts-ignore
    return this.getHighestPrecedentRole(filteredRoles)
  }

  private populateRequirementsMap() {
    Object.keys(this.roleCollection).forEach((k) => {
      this.requirementsMap[k] = this.roleCollection[k]
    })
  }

  /**
   * Returns the role with the highest precendence as defined by the
   * role type precedences list. If none of the roles are found in the
   * list, a DummyRole with no permissions are returned.
   *
   * @param roles a list of Role instances
   */
  private getHighestPrecedentRole<R extends Role>(roles: R[]) {
    return this.orderRolesByPrecedence(roles)[0] || new DummyRole()
  }

  /**
   * Orders the roles according to their precedence as defined by the
   * role type precedences list.
   *
   * @param roles a list of Role instances
   */
  private orderRolesByPrecedence<R extends Role>(roles: R[]) {
    const matchingRoles: Role[] = []

    this.roleTypePrecedences.forEach((roleType) => {
      roles.forEach((role) => {
        if (role.roleType === roleType) {
          matchingRoles.push(role.clone())
        }
      })
    })

    return matchingRoles
  }

  private matchesRequirement<R extends Role>(search: PermissionRequirement, role: R) {
    const requirement = role.requirements
    if (search.sourceCollection !== requirement.sourceCollection) {
      return false
    }

    if (search.targetCollection !== requirement.targetCollection) {
      return false
    }

    const filteredLabels = requirement.labels.filter((label) => search.labels.includes(label))
    if (filteredLabels.length < 1) {
      return false
    }

    return true
  }

  // NB:; This method doesn't do anything yet, it's just here to
  // standardize the way of creating the instance in case we move the
  // role and permission data to the database, and thus need to fetch
  // it like we do with the models.
  public static get(roleHandlerUid: string) {
    return new RoleHandler(DEFAULT_ROLE_COLLECTION, DEFAULT_ROLE_TYPE_PRECEDENCES)
  }
}

const DEFAULT_ROLE_TYPE_PRECEDENCES = [
  RoleType.SYSTEM_ADMINISTRATOR,
  RoleType.COMPANY_ADMINISTRATOR,
  RoleType.ITEM_CREATOR,
  RoleType.PROJECT_OWNER,
  RoleType.PROJECT_ADMINISTRATOR,
  RoleType.PROJECT_ADMINISTRATOR_RESTRICTED,
  RoleType.REPORT_CREATOR,
  RoleType.LEGACY_REPORT_CREATOR,
  RoleType.COMPANY_NORMAL,
  RoleType.PROJECT_NORMAL,
  RoleType.PROJECT_NORMAL_RESTRICTED,
  RoleType.PROJECT_VIEWER,
  RoleType.DRAWING_VIEWER,
  RoleType.USER,
  RoleType.NONE,
]

const DEFAULT_ROLE_COLLECTION: RoleCollection = {
  [RoleType.PROJECT_ADMINISTRATOR]: new ProjectAdministratorRole(),
  [RoleType.PROJECT_OWNER]: new ProjectOwnerRole(),
  [RoleType.PROJECT_NORMAL]: new ProjectNormalRole(),
  [RoleType.COMPANY_ADMINISTRATOR]: new CompanyAdministratorRole(),
  [RoleType.COMPANY_NORMAL]: new CompanyNormalRole(),
  [RoleType.REPORT_CREATOR]: new ReportCreatorRole(),
  [RoleType.ITEM_CREATOR]: new ItemCreatorRole(),
  [RoleType.LEGACY_REPORT_CREATOR]: new LegacyReportCreatorRole(),
}
