import { Inject, Injectable } from "@angular/core"
import { AngularFireFunctions } from "@angular/fire/compat/functions"
import { BehaviorSubject, firstValueFrom, Observable } from "rxjs"
import { combineLatestWith, distinctUntilChanged, filter, map, shareReplay, switchMap, tap, timeout } from "rxjs/operators"
import { ChannelFilters } from "stream-chat"
import { ChannelService, ChatClientService, StreamI18nService } from "stream-chat-angular"

import { GET_STREAM_ACCESS_TOKEN } from "../constants"
import { UserService } from "./user.service"

interface IStreamUserData {
  id: string
  name: string
  image: string
}

@Injectable({
  providedIn: "root",
})
export class FieldChatService {
  private _getStreamUserTokenFn = this.fns.httpsCallable("ext-auth-chat-getStreamUserToken")
  private _user$ = this.userService.currentUser$
  private _streamAccessKey$ = new BehaviorSubject<string>("")

  public currentStreamUserToken$: Observable<string> = this._user$.pipe(switchMap((_) => this._getStreamUserTokenFn({})))

  private readonly credentials$ = this._streamAccessKey$.pipe(
    filter(Boolean),
    distinctUntilChanged(),
    combineLatestWith(this._user$, this.currentStreamUserToken$),
    filter(([key, user, token]) => [key, user, token].every((value) => !!value)),
    distinctUntilChanged((p, c) => [p[0], p[1].ref.id, p[2]].join("") === [c[0], c[1].ref.id, c[2]].join("")),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public async initializeChatClient() {
    const credentials = await firstValueFrom(this.credentials$.pipe(timeout(10_000)))
    // TODO: properly check for errors in fetching credentials
    const [key, user, token] = credentials
    await this.chatClientService.init(key, { id: user.ref.id, name: user.name, image: user.image }, token)
    this.streamI18nService.setTranslation()
  }

  /**
   * Emits the current number of unread messages that the user has
   */
  currentUserUnreadCount$ = this.chatClientService.events$.pipe(
    filter((n) => ["notification.message_new", "notification.mark_read", "connection.changed"].includes(n.eventType)),
    filter((event) => "total_unread_count" in event.event),
    map((e) => e.event.total_unread_count)
  )

  /**
   * Queries GetStream and loads the relevant channel list for the given context
   *
   * @param projectUid If you want to see the channels for a project, give the project UID.
   *                   If you want to see the channels for a company, projectUid should be null
   */
  public async initChannels(projectUid: string | null) {
    const user = await firstValueFrom(this._user$)
    const filters: ChannelFilters = {
      type: "messaging",
      members: { $in: [user.ref.id] },
    }

    if (projectUid === null) {
      filters["fieldCompanyUid"] = await firstValueFrom(this.userService.userCompanyUid$)
      filters.type = "team"
    } else {
      filters["fieldProjectUid"] = projectUid
    }

    this.channelService.reset()
    await this.channelService.init(filters)
  }

  public async disconnectUser() {
    this.channelService.reset()
    await this.chatClientService.disconnectUser()
  }

  constructor(
    private fns: AngularFireFunctions,
    private userService: UserService,
    private chatClientService: ChatClientService,
    private channelService: ChannelService,
    private streamI18nService: StreamI18nService,
    @Inject(GET_STREAM_ACCESS_TOKEN) STREAM_ACCESS_KEY: string
  ) {
    this._streamAccessKey$.next(STREAM_ACCESS_KEY)
  }

  readonly channelName$ = this.channelService.activeChannel$.pipe(
    filter(Boolean),
    map((channel) => channel.data?.name)
  )

  readonly channelDesc$ = this.channelService.activeChannel$.pipe(
    filter(Boolean),
    map((channel) => channel.data?.["description"])
  )
}
