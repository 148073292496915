<p-autoComplete
  #autoComplete
  class="p-fluid"
  [(ngModel)]="tags"
  [suggestions]="tagSearchResult"
  (completeMethod)="searchTags($event)"
  [multiple]="true"
  (keydown.escape)="$event.stopPropagation()"
  (keydown.enter)="addHack(autoComplete); $event.preventDefault()"
>
</p-autoComplete>
