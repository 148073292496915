<h2 mat-dialog-title>Choose which columns to export</h2>

<mat-dialog-content>
  <mat-checkbox *ngFor="let column of selectedColumns" [(ngModel)]="column.selected">
    {{ column.name }}
  </mat-checkbox>
</mat-dialog-content>

<mat-dialog-actions>
  <button primary-button [isRounded]="true" [type]="'outline'" (click)="dialogRef.close()"><span>Cancel</span></button>
  <button primary-button [isRounded]="true" (click)="startExport()">Start export</button>
</mat-dialog-actions>
