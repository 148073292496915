<div fxLayout="row" fxLayoutAlign="center center">
  <label for="">{{title}}</label>
</div>
<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
  <button [color]="'primary'" mat-raised-button (click)="decrease()" [disabled]="readOnly">
    <mat-icon>remove</mat-icon>
  </button>
  <span>{{value}}</span>
  <button [color]="'primary'" mat-raised-button (click)="increase()" [disabled]="readOnly">
    <mat-icon>add</mat-icon>
  </button>
</div>
