import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { Item, Person, Project, Role, RoleType } from "@models/common"
import { ItemService } from "@services"

@Component({
  selector: "item-metadata",
  templateUrl: "./item-metadata.component.html",
  styleUrls: ["./item-metadata.component.scss"],
})
export class ItemMetadataComponent implements OnInit {
  @Input() item: Item
  @Input() project: Project
  @Input() userProjectRole: Role
  @Input() currentUser: Person

  @Output() removeCollaborator = new EventEmitter<any>()

  _dueDateBackingField: Date

  get _dueDate(): Date {
    return this._dueDateBackingField
  }

  set _dueDate(newDate: Date) {
    this._dueDateBackingField = newDate
    this.dueDateChanged(newDate).then()
  }

  readonly dateFormat = "DD-MM-YYYY HH:mm"

  get aggregateData() {
    return this.item.aggregateData || {}
  }
  get itemCreatorName() {
    return this.aggregateData.itemCreatorName || ""
  }
  get itemCreatorCompanyName() {
    return this.aggregateData.itemCreatorCompanyName || ""
  }
  get taskAssignerName() {
    return this.aggregateData.taskAssignerName || ""
  }
  get taskAssignerCompanyName() {
    return this.aggregateData.taskAssignerCompanyName || ""
  }
  get taskAssigneeName() {
    return this.aggregateData.taskAssigneeName || ""
  }
  get taskAssigneeCompanyName() {
    return this.aggregateData.taskAssigneeCompanyName || ""
  }
  get legacyReportName() {
    return this.aggregateData.legacyReportName || ""
  }
  get legacyReportUrl() {
    return this.aggregateData.legacyReportUid != null ? `legacyReports/${this.aggregateData.legacyReportUid}` : ""
  }
  get projectName() {
    return this.aggregateData.projectName || ""
  }
  get projectUrl() {
    return this.aggregateData.projectUid != null ? `projects/${this.aggregateData.projectUid}` : ""
  }

  get canViewLinks() {
    return this.userProjectRole != null && this.userProjectRole.roleType != null && this.userProjectRole.roleType !== RoleType.NONE
  }

  get itemOverDue(): boolean | null {
    if (!this.item.dueDate) {
      return null
    }

    return Date.now() * 0.001 > this.item.dueDate
  }

  get collaborators() {
    return this.item.collaborators
  }

  constructor(private itemService: ItemService, private db: AngularFirestore) {}

  ngOnInit() {
    if (this.item && this.item.dueDate) {
      this._dueDateBackingField = new Date(this.item.dueDate * 1000)
    }
  }

  async dueDateChanged(date: number | Date) {
    const batch = this.db.firestore.batch()
    await this.itemService.updateDueDate(batch, this.currentUser, this.item, date)

    return batch.commit()
  }

  SendRemoveCollaboratorValues(collaborator: string) {
    const item = this.item

    return { collaborator, item }
  }

  public trackByUid(index: number, item: { uid: string }) {
    return item.uid
  }
}
