import { ImageData } from "./image.interface"
import { ITimelineData, TimelineType } from "./timeline.interface"
import firebase from "firebase/compat/app"
import { ModelInCollection } from "./collections"
import { DB } from "./constants"
import { Person } from "./person"
import firestore = firebase.firestore

export class TimelineElement {
  get type() {
    return this.data.type
  }
  get currentData() {
    return this.data.currentData
  }
  get previousData() {
    return this.data.previousData
  }
  get message() {
    return this.data.message
  }
  get disabled() {
    return this.data.disabled || false
  }
  get disabledBy() {
    return this.data.disabledBy || null
  }
  get disabledAt() {
    return this.data.disabledAt || 0
  }
  get creatorUid() {
    return this.data.creator ? this.data.creator.id : null
  }
  public static COLLECTION: string = "timeline"

  creator: Person
  disabler: Person

  constructor(public data: ITimelineData, public uid: string, public ref: firestore.DocumentReference) {}

  getCreator(): Promise<Person> {
    return Person.get(this.data.creator.id)
  }

  getDisabler(): Promise<Person> {
    return Person.get(this.data.disabledBy!)
  }

  disable(batch: firestore.WriteBatch, disabler: Person) {
    return batch.update(this.ref, {
      disabled: true,
      disabledAt: Date.now(),
      disabledBy: disabler.uid,
    })
  }
}

export class Timeline {
  public static COLLECTION: string = "timeline"

  constructor(public elements: TimelineElement[], public ref: firestore.CollectionReference) {}

  public createItemImageElement(creator: Person, imageData: ImageData) {
    const data: ITimelineData = {
      type: TimelineType.IMAGE_ADDED,
      creator: creator.ref!,
      createdDate: Date.now(),
      currentData: { image: imageData },
    }

    return this.ref.add(data).then((_) => Timeline.fromColRef(this.ref))
  }

  public add(data: ITimelineData) {
    return this.ref.add(data)
  }

  public static batchAdd(batch: firestore.WriteBatch, m: ModelInCollection, data: ITimelineData) {
    const docRef = m.ref!.firestore.doc(m.ref!.path).collection(Timeline.COLLECTION).doc()
    batch.set(docRef, data, { merge: true })

    return docRef
  }

  static fromColRef(colRef: firestore.CollectionReference) {
    return colRef.get().then((qSnapshot) => {
      const elements = qSnapshot.docs.map((snap) => new TimelineElement(snap.data() as ITimelineData, snap.id, snap.ref))

      return new Timeline(elements, colRef)
    })
  }

  static getFor(m: ModelInCollection): Promise<Timeline> {
    if (m.ref == null) {
      throw new Error(`No ref found for ${m.uid}`)
    }

    return Timeline.fromColRef(DB.doc(m.ref.path).collection(Timeline.COLLECTION))
  }
}
