import { Component, Inject, OnInit } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import { CheckdFormDialogService } from "@checkd-form/services/checkd-form-dialog.service"
import { Project } from "@models/common"
import { GeneralReport } from "@models/common/general-report"
import { DEFAULT_PDF_GENERATION_SETTINGS } from "@models/common/pdf/settings.interface"
import { SnackbarService } from "@services"
import { NextExperimentalService } from "@services/apis/next-experimental.service"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { GeneralReportViewService } from "../../general-report-view/general-report-view.service"

@Component({
  selector: "checkd-share-to-next-project-dialog",
  templateUrl: "./share-to-next-project-dialog.component.html",
  styleUrls: ["./share-to-next-project-dialog.component.scss"],
})
export class ShareToNextProjectDialogComponent implements OnInit {
  nextProjectProjectName$: Observable<string>
  pdfGenerationSettings = DEFAULT_PDF_GENERATION_SETTINGS

  constructor(
    private dialogRef: MatDialogRef<ShareToNextProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { nextProjectProject: number; nextFieldProject: string; project: Project; report: GeneralReport },
    private nextIntegration: NextExperimentalService,
    public generalReportViewService: GeneralReportViewService,
    private dialogService: CheckdFormDialogService,
    private snackBarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.getNextProjectProjectInfo()
  }

  getNextProjectProjectInfo() {
    const nextProjectProjectId = this.data.project.nextProjectId
    this.nextProjectProjectName$ = this.nextIntegration.getNextProject(nextProjectProjectId).pipe(map((project: any) => project.name))
  }

  async exportToNext() {
    const spinnerDialog = this.dialogService.checkdDialog.showSpinner("Generating PDF - please wait.")
    // Upload to nextProject project
    try {
      this.pdfGenerationSettings.uploadPdfToNextProject = true
      this.pdfGenerationSettings.timezone = Intl?.DateTimeFormat().resolvedOptions().timeZone ?? null
      const result: any = await this.generalReportViewService.fetchReportPdf(this.data.report, this.pdfGenerationSettings)

      if (result.downloadUrl) {
        spinnerDialog.close()
        this.snackBarService.showMessage("PDF uploaded to Next Project!")
      }
    } catch (err) {
      this.snackBarService.showMessage("Error generating pdf!")
      console.log(err)
    }
    this.closeDialog()
  }

  closeDialog() {
    this.dialogRef.close()
  }
}
