import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-chevron-right-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" [attr.viewBox]="getViewBox()" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.85891 0.413276L18.0738 11.0745C18.3231 11.3325 18.4469 11.6669 18.4469 12C18.4469 12.3331 18.3231 12.6678 18.0738 12.9255L7.85891 23.5867C7.34834 24.122 6.50102 24.1394 5.96768 23.6268C5.43071 23.1178 5.41325 22.2667 5.92758 21.735L15.3001 11.9497L5.92919 2.2649C5.41487 1.73491 5.43233 0.882458 5.96928 0.373108C6.50269 -0.139589 7.35124 -0.121736 7.85891 0.413276Z"
        fill="currentColor"
        [attr.transform]="getScale()"
      />
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class ChevronRightIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }

  getViewBox(): string | undefined {
    return this.svgIconsService.getViewBox(this.size)
  }

  getScale(): string | undefined {
    return this.svgIconsService.getScale(this.size)
  }
}
