import { ReportStatus } from "./workflow.interface"
import { BaseModel } from "./base-model"
import { LegacyReport } from "./legacy-report"
import { LegacyReportData } from "./legacy-report.interface"
import { IDocumentPermissions, RoleType } from "./roles-and-permissions"

export class FieldReport extends BaseModel<LegacyReportData> {
  public static override COLLECTION: string = "reports"

  protected override readonly defaultPermissions: IDocumentPermissions = {
    roles: {
      [RoleType.PROJECT_OWNER]: { create: true, read: true, update: true, delete: true },
      [RoleType.PROJECT_ADMINISTRATOR]: { create: true, read: true, update: true, delete: true },
      [RoleType.PROJECT_NORMAL]: { create: true, read: true, update: true, delete: true },
      [RoleType.NONE]: { create: false, read: true, update: false, delete: false },
    },
  }

  // TODO reportName added for legacy support. Remove when appropriate.
  get name() {
    return this.data.name || this.data.reportName || ""
  }

  // TODO reportName added for legacy support. Switch to name attribute
  get reportName() {
    return this.data.name || this.data.reportName
  }

  get creatorUid() {
    return this.aggregateData["creatorUid"] || ""
  }
  get status() {
    return this.data.status || ReportStatus.OPEN
  }
  get description() {
    return this.data.description
  }
  get header() {
    return this.headerTemplateData
  }
  get details() {
    return this.detailTemplateData
  }
  get reportDataId() {
    return this.data.reportDataId
  }
  get number() {
    return this.data.number
  }
  get headerTemplateData() {
    return JSON.parse(this.data.headerTemplateData || "[]")
  }
  get detailTemplateData() {
    return JSON.parse(this.data.detailTemplateData || "[]")
  }
  get reportStatus() {
    return this.data.reportStatus
  }
  get notSynced() {
    return this.data.notSynced
  }
  get noOfElements() {
    return this.data.noOfElements
  }
  get noOfFilledElements() {
    return this.data.noOfFilledElements
  }
  get isArchived() {
    return this.data.isArchived
  }
  get reportId() {
    return this.data.reportId
  }
  get createdUserId() {
    return this.data.createdUserId
  }
  get modifiedUserId() {
    return this.data.modifiedUserId
  }
  get ownerUserId() {
    return this.data.ownerUserId
  }
  get isDeleted() {
    return this.data.isDeleted
  }
  get pdfFieldsSettings() {
    return this.data.reportGenerationData != null ? this.data.reportGenerationData.settings : null
  }
  get totalItems(): number {
    return 0
  }
  get urlPath() {
    return this.reportType === "FIELD" ? `fieldReports/${this.uid}` : `reports/${this.uid}`
  }
  get reportType() {
    return this.data && this.data.reportType ? this.data.reportType : "NONE"
  }
  get logo() {
    return this.data.logo || ""
  }
  get templatePublicVersion() {
    return ""
  }

  public static get(uid: string): Promise<LegacyReport> {
    return LegacyReport.doc(uid)
      .get()
      .then((snapshot) => {
        if (!snapshot.exists) {
          throw new Error(`LegacyReport ${uid} not found`)
        }

        return new LegacyReport(snapshot.data() as LegacyReportData, snapshot.id, snapshot.ref)
      })
  }

  public static create(data: LegacyReportData): Promise<LegacyReport> {
    return FieldReport.db
      .collection(LegacyReport.COLLECTION)
      .add(data)
      .then((docRef) => docRef.get())
      .then((docSnapshot) => new LegacyReport(docSnapshot.data() as LegacyReportData, docSnapshot.id, docSnapshot.ref))
  }
}
