import { DragDropModule } from "@angular/cdk/drag-drop"

import { ScrollingModule } from "@angular/cdk/scrolling"
import { HttpClientModule } from "@angular/common/http"
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core"

import { AngularFireModule } from "@angular/fire/compat"
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from "@angular/fire/compat/analytics"
import { AngularFireAuthModule } from "@angular/fire/compat/auth"
import { AngularFirestoreModule, SETTINGS } from "@angular/fire/compat/firestore"
import { AngularFireFunctionsModule, REGION } from "@angular/fire/compat/functions"
import { AngularFirePerformanceModule } from "@angular/fire/compat/performance"
import { AngularFireStorageModule } from "@angular/fire/compat/storage"
import { FlexLayoutModule } from "@angular/flex-layout"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatBadgeModule } from "@angular/material/badge"
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from "@angular/material/core"
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator"
import { MatSortModule } from "@angular/material/sort"
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table"
import { BrowserModule, Title } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { Router } from "@angular/router"

import { LeafletModule } from "@asymmetrik/ngx-leaflet"
import { FormMessageService } from "@checkd-form/services/form-message.service"

import { CompanyInvitationDialogComponent, CompanyViewComponent } from "@company"

import { ConfirmDialogComponent, ItemCreationDialogComponent, OptionListDialogComponent, PeopleListDialogComponent } from "@dialogs"

import {
  DrawingDetailsDialogComponent,
  DrawingItemCreationDialogComponent,
  DrawingListComponent,
  DrawingListItemComponent,
  DrawingViewComponent,
} from "@drawings"

import { GalleryModule } from "@ks89/angular-modal-gallery"

// TODO Remove these dependencies and lazy-load project.module instead
import {
  ProjectDrawingsComponent,
  ProjectInvitationDialogComponent,
  ProjectListComponent,
  ProjectListItemComponent,
  ProjectModule,
  ProjectPeopleComponent,
  ProjectSettingsComponent,
  ProjectsViewComponent,
  ProjectViewComponent,
} from "@project"
import { MessageService } from "primeng/api"
import { CheckboxModule } from "primeng/checkbox"
import { DataViewModule } from "primeng/dataview"
import { InputNumberModule } from "primeng/inputnumber"
import { OrderListModule } from "primeng/orderlist"
import { ToastModule } from "primeng/toast"
import { ProjectInvitationReceivedDialogComponent } from "./dashboard/dialogs/project-invitation-recieved-dialog/project-invitation-received-dialog.component"
import { InvitationModule } from "./dashboard/invitations/invitation.module"

// TODO Remove this dependency and lazy-load project.module instead
import { ProjectGalleryComponent } from "./dashboard/project/project-gallery/project-gallery.component"

import { ReportGenerationDialogComponent, ReportListComponent, ReportSharingDialogComponent } from "@reports"

import * as Sentry from "@sentry/angular-ivy"

import {
  CloudFunctionsService,
  CompanyService,
  DrawingService,
  ExportService,
  FileHandlerService,
  FilestackService,
  FirebaseAuthService,
  ImageService,
  InvitationService,
  ItemFilterService,
  ItemService,
  LeafletService,
  MigrationService,
  ModelService,
  NavigationService,
  PdfDataService,
  ProjectService,
  RelationService,
  ReportService,
  RoleHandlerService,
  SharedConfigService,
  SnackbarService,
  TaskService,
  TimelineService,
} from "@services"
import { AnalyticsService } from "@services/analytics.service"
import { LoggingService } from "@services/logging.service"

import { AngularSignaturePadModule } from "@almothafar/angular-signature-pad"

import { IntercomModule } from "@supy-io/ngx-intercom"
import { AvatarModule } from "ngx-avatars"

import { ClipboardModule } from "ngx-clipboard"
import { MomentModule } from "ngx-moment"
import { DropdownModule } from "primeng/dropdown"
import { InputSwitchModule } from "primeng/inputswitch"
import { OverlayPanelModule } from "primeng/overlaypanel"
import { RadioButtonModule } from "primeng/radiobutton"
import { TooltipModule } from "primeng/tooltip"
import { environment } from "../environments/environment"

import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"

import { CheckdFormModule } from "./dashboard/checkd-form/checkd-form.module"

import { CheckdCommonModule, CheckdMaterialModule } from "./dashboard/checkd-ui"

import { DashboardComponent } from "./dashboard/dashboard.component"
import { MultiLangConfirmationDialogComponent } from "./dashboard/dialogs/multi-lang-confirmation-dialog/multi-lang-confirmation-dialog.component"
import { ReportSelectionDialogComponent } from "./dashboard/dialogs/report-selection-dialog/report-selection-dialog.component"

import { SpinnerDialogComponent } from "./dashboard/dialogs/spinner-dialog/spinner-dialog.component"
import { TagsSelectionDialogComponent } from "./dashboard/dialogs/tags-selection-dialog/tags-selection-dialog.component"
import { TextfieldDialogComponent } from "./dashboard/dialogs/textfield-dialog/textfield-dialog.component"
import { DrawingContextmenuComponent } from "./dashboard/drawings/components/drawing-contextmenu/drawing-contextmenu.component"
import { NameFilterPipe } from "./dashboard/filters/project-filter.pipe"

import { ItemsModule } from "./dashboard/items/items.module"
import { LoginViewComponent } from "./dashboard/login-view/login-view.component"
import { AccountActivationViewComponent } from "./dashboard/login/account-activation-view/account-activation-view.component"
import { ForgotPasswordDialogComponent } from "./dashboard/login/forgot-password-dialog/forgot-password-dialog.component"
import { PasswordRecoveryViewComponent } from "./dashboard/login/password-recovery-view/password-recovery-view.component"
import { PasswordRecoveryComponent } from "./dashboard/login/password-recovery/password-recovery.component"
import { PasswordResetComponent } from "./dashboard/login/password-reset/password-reset.component"
import { NotFoundComponent } from "./dashboard/not-found/not-found.component"
import { NotificationRowItemComponent } from "./dashboard/notifications/notification-row-item/notification-row-item.component"
import { NotificationsComponent } from "./dashboard/notifications/notifications.component"

import { OrderbyPipe } from "./dashboard/ordering/orderby.pipe"
import { ProjectReportsComponent } from "./dashboard/project/project-reports/project-reports.component"

import { GalleriaModule } from "primeng/galleria"
import { ImageModule } from "primeng/image"
import { AnonymousReportDialogComponent } from "./dashboard/dialogs/anonymous-report-dialog/anonymous-report-dialog.component"
import { ConnectNextProjectDialogComponent } from "./dashboard/dialogs/connect-next-project-dialog/connect-next-project-dialog.component"
import { FailedUploadsDialogComponent } from "./dashboard/dialogs/failed-uploads-dialog/failed-uploads-dialog.component"
import { GeneratePdfSettingsDialogComponent } from "./dashboard/dialogs/generate-pdf-settings-dialog/generate-pdf-settings-dialog.component"
import { LinkSharingDialogComponent } from "./dashboard/dialogs/link-sharing-dialog/link-sharing-dialog.component"
import { TermsAndConditionsDialogComponent } from "./dashboard/dialogs/terms-and-conditions-dialog/terms-and-conditions-dialog.component"
import { FeatureCheckerModule } from "./dashboard/features/feature-checker/feature-checker.module"
import { PublicTemplateLibraryModule } from "./dashboard/features/public-template-library/public-template-library.module"
import { ReportDrawingModule } from "./dashboard/reports/drawing-report/report-drawing/report-drawing.module"
import { FieldReportListItemComponent } from "./dashboard/reports/field-report-list-item/field-report-list-item.component"
import { FieldReportListComponent } from "./dashboard/reports/field-report-list/field-report-list.component"
import { ReportContextmenuComponent } from "./dashboard/reports/report-contextmenu/report-contextmenu.component"
import { ChatBubbleIconComponent } from "./dashboard/svg-components/chat-bubble-icon/chat-bubble-icon.component"
import { TemplateCreatorModule } from "./dashboard/template-creator/template-creator.module"
import { TopBarComponent } from "./dashboard/top-bar/top-bar.component"
import { PhoneMaskDirective } from "./dashboard/utilities/phone-mask.directive"
import { ItemStatusPipe } from "./dashboard/utilities/pipes/item-status.pipe"
import { EmailVerificationViewComponent } from "./dashboard/login/email-verification-view/email-verification-view.component"
import { AuthActionComponent } from "./dashboard/login/auth-action/auth-action.component"
import { SsoActivationSuccessDialogComponent } from "./dashboard/dialogs/sso-activation-success-dialog/sso-activation-success-dialog.component"
import { DuplicateReportDialogComponent } from "./dashboard/dialogs/duplicate-report-dialog/duplicate-report-dialog.component"
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader"
import { FontAwesomeModule, FaIconLibrary } from "@fortawesome/angular-fontawesome"
import { TranslateModule } from "@ngx-translate/core"
import { GET_STREAM_ACCESS_TOKEN } from "field-chat-lib"

Sentry.init({
  dsn: "https://3537d377ffa64e1abcf0c9803056fc0a@o239530.ingest.sentry.io/1410307",
  environment: environment.sentry.environment,
  release: environment.sentry.version,

  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", "https://web-stag.checkd.it", "https://web.checkd.it"],
    }),
  ],
  tracesSampleRate: 0.5,
})

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  // override this method to make the week start on Mondays instead of Sundays
  override getFirstDayOfWeek(): number {
    return 1
  }
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    return
  }
  handleError(error: any) {
    const err = error.originalError || error.error || error
    const msg: string = (err.message || "").toLowerCase()

    if (msg.includes("chunkload") || msg.includes("non-error")) {
      return
    }

    if (err) {
      Sentry.captureException(err)
    }

    throw error
  }
}

const SVG_COMPONENTS = [ChatBubbleIconComponent]

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    DashboardComponent,
    ProjectsViewComponent,
    ProjectListComponent,
    ProjectListItemComponent,
    ProjectGalleryComponent,
    NameFilterPipe,
    LoginViewComponent,
    ConfirmDialogComponent,
    ProjectViewComponent,
    ProjectSettingsComponent,
    ProjectPeopleComponent,
    ProjectDrawingsComponent,
    CompanyViewComponent,
    DrawingListComponent,
    DrawingListItemComponent,
    DrawingViewComponent,
    DrawingDetailsDialogComponent,
    DrawingDetailsDialogComponent,
    PeopleListDialogComponent,
    ReportSharingDialogComponent,
    ItemCreationDialogComponent,
    ProjectInvitationDialogComponent,
    ReportGenerationDialogComponent,
    DrawingItemCreationDialogComponent,
    ForgotPasswordDialogComponent,
    PasswordRecoveryComponent,
    PasswordRecoveryViewComponent,
    SpinnerDialogComponent,
    ProjectReportsComponent,
    FieldReportListComponent,
    FieldReportListItemComponent,
    CompanyInvitationDialogComponent,
    PasswordResetComponent,
    AccountActivationViewComponent,
    OptionListDialogComponent,
    NotFoundComponent,
    ReportListComponent,
    ReportContextmenuComponent,
    DrawingContextmenuComponent,
    TextfieldDialogComponent,
    NotificationsComponent,
    NotificationRowItemComponent,
    ReportSelectionDialogComponent,
    MultiLangConfirmationDialogComponent,
    TagsSelectionDialogComponent,
    FailedUploadsDialogComponent,
    OrderbyPipe,
    TermsAndConditionsDialogComponent,
    TopBarComponent,
    LinkSharingDialogComponent,
    AnonymousReportDialogComponent,
    GeneratePdfSettingsDialogComponent,
    ItemStatusPipe,
    PhoneMaskDirective,
    EmailVerificationViewComponent,
    AuthActionComponent,
    SsoActivationSuccessDialogComponent,
    ConnectNextProjectDialogComponent,
    ProjectInvitationReceivedDialogComponent,
    DuplicateReportDialogComponent,
    ...SVG_COMPONENTS,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    CheckdMaterialModule,
    CheckdCommonModule,
    CheckdFormModule,
    DragDropModule,
    ReportDrawingModule,
    AppRoutingModule,
    FlexLayoutModule,
    LeafletModule,
    AvatarModule,
    IntercomModule.forRoot({
      appId: environment.INTERCOM_APP_ID,
      updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    }),
    ReactiveFormsModule,
    AngularSignaturePadModule,
    ClipboardModule,
    HttpClientModule,
    MomentModule,
    ProjectModule,
    FeatureCheckerModule,
    ItemsModule,
    GalleryModule,
    TemplateCreatorModule,
    ScrollingModule,
    MatBadgeModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TooltipModule,
    PublicTemplateLibraryModule,
    InputSwitchModule,
    DropdownModule,
    RadioButtonModule,
    OverlayPanelModule,
    OrderListModule,
    DataViewModule,
    CheckboxModule,
    ImageModule,
    GalleriaModule,
    ToastModule,
    InvitationModule,
    InputNumberModule,
    TranslateModule.forRoot(),
    FontAwesomeModule,
    NgxSkeletonLoaderModule,
  ],
  providers: [
    RelationService,
    ProjectService,
    SharedConfigService,
    FilestackService,
    // { provide: BrowserXhr, useClass: NgProgressBrowserXhr },
    { provide: REGION, useValue: "europe-west1" },
    // Use this only in dev when debugging with cloud functions
    // { provide: ORIGIN, useValue: 'http://localhost:5000' },
    ExportService,
    PdfDataService,
    ImageService,
    SnackbarService,
    FirebaseAuthService,
    MigrationService,
    CompanyService,
    DrawingService,
    NavigationService,
    ItemService,
    TaskService,
    TimelineService,
    LeafletService,
    ItemFilterService,
    ReportService,
    InvitationService,
    RoleHandlerService,
    CloudFunctionsService,
    FileHandlerService,
    ModelService,
    AnalyticsService,
    LoggingService,
    MessageService,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: SETTINGS,
      useValue: environment.emulator
        ? {
            host: "localhost:8081",
            ssl: false,
          }
        : undefined,
    },
    {
      provide: GET_STREAM_ACCESS_TOKEN,
      useValue: environment.STREAM_ACCESS_KEY,
    },
    FormMessageService,
    Title,
    ScreenTrackingService,
    UserTrackingService,
    { provide: MAT_DATE_LOCALE, useValue: "no" },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
