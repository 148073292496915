import { inject, Injectable } from "@angular/core"
import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { TableFor } from "@items"
import { OAuthCredential, OAuthProvider } from "firebase/auth"

import {
  COLLECTIONS,
  Company,
  CompanyData,
  CompanyFeatures,
  createNewModelInstance,
  Item,
  LABELS,
  Notification,
  Person,
  PersonData,
  Project,
  Relation,
  RelationData,
  Role,
  RoleType,
  SortingDirection,
  Task,
  UserRegistrationData,
} from "@models/common"
import { ICheckdAgreement } from "@models/common/agreements.interface"
import * as Sentry from "@sentry/angular-ivy"
import { AgreementsService } from "@services/agreements.service"
import { CloudFunctionsService } from "@services/cloud-functions.service"
import firebase from "firebase/compat/app"
import { BehaviorSubject, combineLatest, EMPTY, finalize, firstValueFrom, Observable, of as observableOf, takeWhile } from "rxjs"
import { combineLatestWith, debounceTime, distinctUntilChanged, map, shareReplay, switchMap, take, tap } from "rxjs/operators"
import { IUserTableConfig, transformFromDBFormat, transformToDBFormat } from "../items/item-list/table-configs"

import { IUserGeneral } from "@models/common/person.interface"
import { NotificationsService } from "../notifications/services/notifications.service"
import { CompanyService } from "./company.service"
import { FilestackService } from "./filestack.service"
import { FirebaseAuthService } from "./firebase-auth.service"
import { ModelService } from "./model.service"
import { RelationService } from "./relation.service"
import { RoleHandlerService } from "./role-handler.service"
import { orderByCreatedAt, orderByCreatedAtDesc } from "./utilities"

@Injectable({
  providedIn: "root",
})
export class UserService {
  readonly firebaseAuthService = inject(FirebaseAuthService)
  readonly afAuth = inject(AngularFireAuth)
  readonly db = inject(AngularFirestore)
  readonly modelService = inject(ModelService)
  readonly relationService = inject(RelationService)
  readonly filestackService = inject(FilestackService)
  readonly companyService = inject(CompanyService)
  readonly roleHandlerService = inject(RoleHandlerService)
  readonly notificationsService = inject(NotificationsService)
  readonly agreementsService = inject(AgreementsService)
  readonly cloudFunctionsService = inject(CloudFunctionsService)

  readonly authority$ = new BehaviorSubject<string>("")

  readonly currentUser$ = this.afAuth.authState.pipe(
    distinctUntilChanged((previous, current) => previous?.uid === current?.uid),
    takeWhile((user) => user !== null),
    tap((_) => {
      const issuer = localStorage.getItem("one-login:issuer")
      this.authority$.next(issuer ?? "")
    }),
    tap((user) => Sentry.configureScope((scope) => scope.setUser({ id: user!.uid }))),
    tap((user) => this.agreementsService.currentUserUid$.next(user!.uid)),
    switchMap((user) => (user?.uid ? this.listenToUid(user.uid) : EMPTY)),
    takeUntilDestroyed(),
    finalize(() => this.authority$.next("")),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  private currentUserGeneral$ = this.currentUser$.pipe(
    // @ts-ignore
    switchMap((currentUser) => this.db.doc(currentUser.ref.path).collection("private").doc("general").snapshotChanges()),
    map((doc) => ((doc.payload && doc.payload.data && doc.payload.data()) as IUserGeneral) || {}),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public userFeatures$ = this.currentUserGeneral$.pipe(map((general) => general.features || []))

  private currentUserPreferences$ = this.currentUser$.pipe(
    // @ts-ignore
    switchMap((currentUser) => this.db.doc(currentUser.ref.path).collection("private").doc("userPreferences").snapshotChanges()),
    map((doc) => (doc.payload && doc.payload.data && doc.payload.data()) || {}),
    shareReplay(1)
  )

  // @ts-ignore
  public currentUserProjectItemsTablePreferences$: Observable<IUserTableConfig> = this.currentUserPreferences$.pipe(
    map((data: any) => (data?.projectItemsTable ? data.projectItemsTable : undefined)),
    map((data: any) => (data ? transformFromDBFormat(data) : undefined))
  )

  // @ts-ignore
  public currentUserDrawingsItemsTablePreferences$: Observable<IUserTableConfig> = this.currentUserPreferences$.pipe(
    map((data: any) => (data?.drawingsItemsTable ? data.drawingsItemsTable : undefined)),
    map((data: any) => (data ? transformFromDBFormat(data) : undefined))
  )

  // @ts-ignore
  public currentUserMyItemsTablePreferences$: Observable<IUserTableConfig> = this.currentUserPreferences$.pipe(
    map((data: any) => (data?.myItemsTable ? data.myItemsTable : undefined)),
    map((data: any) => (data ? transformFromDBFormat(data) : undefined))
  )

  public currentUserCompanyRelation$: Observable<Relation> = this.currentUser$.pipe(
    switchMap((currentUser) => this.relationService.listenToRelations(currentUser as Person, COLLECTIONS.COMPANIES)),
    map((relations: Relation[]) => relations.find((relation) => relation.labels.includes(LABELS.HAS_MAIN_COMPANY)) || relations[0]),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public currentCompany$: Observable<Company> = this.currentUserCompanyRelation$.pipe(
    switchMap((rel) => this.relationService.relationToTargetListener(COLLECTIONS.COMPANIES, rel)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  readonly currentCompanyFeatures$: Observable<CompanyFeatures[]> = this.currentCompany$.pipe(
    map((company) => (company.features ?? []) as CompanyFeatures[]),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  private readonly paidFeatures = [CompanyFeatures.DRAWINGS, CompanyFeatures.FORMS, CompanyFeatures.BASIC]

  private readonly currentCompanyPaidFeatures$: Observable<CompanyFeatures[]> = this.currentCompanyFeatures$.pipe(
    map((features) => (features as CompanyFeatures[]).filter((feature: CompanyFeatures) => this.paidFeatures.includes(feature))),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  readonly currentCompanyOnlyHasFormsLibraryFeature$: Observable<boolean> = this.currentCompanyPaidFeatures$.pipe(
    combineLatestWith(this.currentCompanyFeatures$),
    map(
      ([paidFeatures, allFeatures]) => paidFeatures.length < 1 && (allFeatures as CompanyFeatures[]).includes(CompanyFeatures.FORMS_LIBRARY)
    ),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  currentCompanyHasFeature(feature: CompanyFeatures): Observable<boolean> {
    return this.currentCompanyFeatures$.pipe(map((companyFeatures) => (companyFeatures ?? []).includes(feature)))
  }

  public currentCompanyMembers$: Observable<Person[]> = this.currentCompany$.pipe(
    switchMap((company) => this.companyService.listenToPeople(company)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public currentUserProjectRelations$: Observable<any> = this.currentUser$.pipe(
    switchMap((user) => this.relationService.listenToRelations(user, COLLECTIONS.PROJECTS)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public currentUserCompanyRole$: Observable<Role> = this.currentUserCompanyRelation$.pipe(
    map((rel) => this.roleHandlerService.fromRelation(rel)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  /**
   * Whether the current user is an administrator for their company
   */
  readonly currentUserIsCompanyAdmin$: Observable<boolean> = this.currentUserCompanyRole$.pipe(
    map((role) => (role?.labels ?? []).includes(LABELS.ADMINISTRATOR)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public currentCompanyProjects$: Observable<Project[]> = combineLatest([this.currentUserCompanyRole$, this.currentCompany$]).pipe(
    switchMap(([currentUserCompanyRole, currentCompany]) => {
      return currentUserCompanyRole.roleType === RoleType.COMPANY_ADMINISTRATOR
        ? this.modelService.queryAndListen({
            collection: COLLECTIONS.PROJECTS,
            aggregateData: {
              companyUid: currentCompany.uid,
            },
          })
        : observableOf([])
    }),
    map((projects) => orderByCreatedAt(projects, SortingDirection.DESC)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public currentUserProjectRoles$: Observable<any> = this.currentUserProjectRelations$.pipe(
    map((relations) => {
      const roles = this.roleHandlerService.fromRelations(relations)
      const roleMap = {}

      for (const role of roles) {
        // @ts-ignore
        roleMap[role.roleData.targetUid] = role
      }

      return roleMap
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  // @ts-ignore
  public currentUserProjects$: Observable<Project[]> = this.currentUserProjectRelations$.pipe(
    switchMap((relations) => this.relationService.relationsToTargetListener(COLLECTIONS.PROJECTS, relations)),
    // @ts-ignore
    map((projects) => projects.filter((project) => project && !project.disabled)),
    // @ts-ignore
    map((projects) => orderByCreatedAt(projects, SortingDirection.DESC)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  // @ts-ignore
  public currentUserFavoriteProjects$: Observable<Project[]> = combineLatest([
    this.currentUserProjects$,
    this.currentUserPreferences$,
  ]).pipe(
    map(([projects, preferences]) => {
      const favoriteProjectUids: string[] = preferences["favoriteProjects"] || []

      return favoriteProjectUids
        .map((projectUid) => projects.find((project) => project.uid === projectUid))
        .filter((project) => project && !project.disabled && !project.archived)
    })
  )

  public currentUserItemsCreated$: Observable<Item[]> = this.currentUser$.pipe(
    switchMap((currentUser) =>
      this.modelService.queryAndListen({
        collection: COLLECTIONS.ITEMS,
        aggregateData: { itemCreatorUid: currentUser.uid },
      })
    ),
    map((items) => items.filter((item) => !item.archived))
  )

  public currentUserItemsAssigned$: Observable<Item[]> = this.currentUser$.pipe(
    switchMap((currentUser) =>
      this.modelService.queryAndListen({
        collection: COLLECTIONS.ITEMS,
        aggregateData: { taskAssignerUid: currentUser.uid },
      })
    ),
    map((items) => items.filter((item) => !item.archived))
  )

  public currentUserItemsAssignee$: Observable<Item[]> = this.currentUser$.pipe(
    switchMap((currentUser) =>
      this.modelService.queryAndListen({
        collection: COLLECTIONS.ITEMS,
        aggregateData: { taskAssigneeUid: currentUser.uid },
      })
    ),
    map((items) => items.filter((item) => !item.archived))
  )

  public currentUserItemsCollaborator$: Observable<Item[]> = this.currentUser$.pipe(
    switchMap((currentUser) =>
      this.db
        .collection(COLLECTIONS.ITEMS, (ref) => ref.where("aggregateData.taskCollaboratorUids", "array-contains", currentUser.uid))
        .snapshotChanges()
    ),
    map((it) =>
      it.map((itemSnap) =>
        createNewModelInstance(COLLECTIONS.ITEMS, itemSnap.payload.doc.data(), itemSnap.payload.doc.id, itemSnap.payload.doc.ref)
      )
    )
  )

  // @ts-ignore
  public currentUserItems$: Observable<Item[]> = combineLatest([
    this.currentUserProjects$,
    this.currentUserItemsCreated$,
    this.currentUserItemsAssigned$,
    this.currentUserItemsAssignee$,
    this.currentUserItemsCollaborator$,
  ]).pipe(
    map(([userProjects, itemsCreated, itemsAssigned, itemsAssignee, itemsCollaborators]) => {
      const allItems = [...itemsCreated, ...itemsAssigned, ...itemsAssignee, ...itemsCollaborators]
      const projectFilteredItems = allItems.filter((item) =>
        // @ts-ignore
        userProjects.map((project) => project.uid).includes(item.aggregateData.projectUid)
      )

      const uniqueItemsMap: { [uid: string]: Item } = {}

      for (const item of projectFilteredItems) {
        uniqueItemsMap[item.uid] = item
      }

      return Object.values(uniqueItemsMap)
    }),
    // @ts-ignore
    map((items) => orderByCreatedAt(items, SortingDirection.DESC))
  )

  public currentUserNotifications$: Observable<Notification[]> = this.currentUser$.pipe(
    switchMap((user) => this.notificationsService.listenToNotificationsForUser(user))
  )

  public currentUserUnreadNotificationsCount$: Observable<number> = this.currentUser$.pipe(
    switchMap((user) => this.notificationsService.listenToUnreadNotificationCountForUser(user)),
    debounceTime(1000)
  )

  async updateUserAgreements(agreements: ICheckdAgreement[]) {
    const batch = this.db.firestore.batch()
    const currentUser = await firstValueFrom(this.currentUser$)
    await this.agreementsService.acceptUserAgreements(batch, currentUser, agreements)

    return batch.commit()
  }

  createAvatarThumbnailUrl(person: Person) {
    if (person == null || person.image == null || person.image.trim() === "") {
      return ""
    }

    return this.filestackService.getThumbnailUrl(person.image, 100)
  }

  async updateItemsTablePreferences(user: Person, tableFor: TableFor, newPrefs: IUserTableConfig) {
    const snapshot = await user?.ref?.collection("private").doc("userPreferences").get()

    const transformed = transformToDBFormat(newPrefs)

    const batch = this.db.firestore.batch()

    switch (tableFor) {
      case "projectItems":
        batch.set(snapshot!.ref, { projectItemsTable: transformed }, { merge: true })
        break
      case "drawings":
        batch.set(snapshot!.ref, { drawingsItemsTable: transformed }, { merge: true })
        break
      case "reports":
      case "myItems":
        batch.set(snapshot!.ref, { myItemsTable: transformed }, { merge: true })
        break
      default:
        throw Error(`Saving table '${tableFor}' is currently not supported`)
    }

    await batch.commit()
  }

  async toggleFavoriteProject(user: Person, project: Project) {
    const snapshot = await user?.ref?.collection("private").doc("userPreferences").get()
    const data = snapshot!.data()

    let favorites: String[] = data && data["favoriteProjects"] ? data["favoriteProjects"] : []

    // @ts-ignore
    if (favorites.includes(project.ref.id)) {
      // @ts-ignore
      favorites = favorites.filter((uid) => uid !== project.ref.id)
    } else {
      // @ts-ignore
      favorites.push(project.ref.id)
    }

    const batch = this.db.firestore.batch()
    batch.set(snapshot!.ref, { favoriteProjects: favorites }, { merge: true })
    await batch.commit()
  }

  listenToUid(personUid: string): Observable<Person> {
    return this.modelService.listenTo(COLLECTIONS.PEOPLE, personUid) as Observable<Person>
  }

  listenToMainCompany(person: Person): Observable<Company> {
    return this.relationService.listenToRelations(person, Company.COLLECTION).pipe(
      map((relations: Relation[]) => relations.find((relation) => relation.labels.includes(LABELS.HAS_MAIN_COMPANY)) || relations[0]),
      // @ts-ignore
      switchMap((relation) => (relation != null ? this.db.doc(relation.targetPath).snapshotChanges() : EMPTY)),
      switchMap((companySnap) =>
        companySnap != null
          ? observableOf(new Company(companySnap.payload.data() as CompanyData, companySnap.payload.id, companySnap.payload.ref))
          : EMPTY
      )
    )
  }

  listenToProjects(person: Person): Observable<Project[]> {
    return this.relationService.listenToTargets(person, COLLECTIONS.PROJECTS).pipe(map(orderByCreatedAtDesc)) as Observable<Project[]>
  }

  listenToArchivedProjects(person: Person): Observable<Project[]> {
    return this.relationService.listenToArchivedTargets(person, COLLECTIONS.PROJECTS).pipe(map(orderByCreatedAtDesc)) as Observable<
      Project[]
    >
  }

  listenToPersonalItems(person: Person): Observable<Item[]> {
    return this.relationService.listenToTargets(person, COLLECTIONS.ITEMS) as Observable<Item[]>
  }

  listenToPersonalTasks(person: Person): Observable<Task[]> {
    return this.relationService.listenToTargets(person, Task.COLLECTION) as Observable<Task[]>
  }

  listenToTaskItem(task: Task): Observable<Item> {
    return this.relationService
      .listenToTargets(task, COLLECTIONS.ITEMS, [LABELS.CONCERNS])
      .pipe(map((items) => (items ? items[0] : EMPTY))) as Observable<Item>
  }

  orderItemsByUpdatedAt(items: Item[]) {
    return items.sort((a: Item, b: Item): number => {
      return (b.updatedAt || 0) - (a.updatedAt || 0)
    })
  }

  listenToCurrentUserProjects(): Observable<Project[]> {
    return this.listenToCurrentUser().pipe(switchMap((user) => this.listenToProjects(user)))
  }

  listenToCurrentUser(): Observable<Person> {
    return this.getFirebaseAuthState().pipe(switchMap((user) => (user ? this.listenToUid(user.uid) : EMPTY)))
  }

  listenToCurrentMainCompany(): Observable<Company> {
    return this.listenToCurrentUser().pipe(switchMap((user) => this.listenToMainCompany(user)))
  }

  listenToCurrentUserAndMainCompany(): Observable<{ user: Person; company: Company }> {
    return this.listenToCurrentUser().pipe(switchMap((user) => this.listenToMainCompany(user).pipe(map((company) => ({ user, company })))))
  }

  checkIfUserEmailExists(email: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.firebaseAuthService.auth
        .signInWithEmailAndPassword(email, Math.random().toString(36).substring(2))
        .then((_) => resolve(true))
        .catch((err) => {
          switch (err.code) {
            case "auth/user-not-found": {
              return resolve(false)
            }
            case "auth/invalid-email": {
              return resolve(false)
            }
            case "auth/wrong-password": {
              return resolve(true)
            }
            default: {
              return reject(err)
            }
          }
        })
    })
  }

  async getMainCompany(person: Person): Promise<Company | null> {
    const companiesQuerySnapshot = await person.collection(COLLECTIONS.COMPANIES).get()

    // Return null if the querysnapshot contains zero docs
    if (companiesQuerySnapshot.empty) {
      return null
    }

    // Attempt to return the first company with a relation containing
    // a HAS_MAIN_COMPANY label
    for (const rel of companiesQuerySnapshot.docs) {
      const relationData = rel.data() as RelationData
      if ((relationData.labels || []).indexOf(LABELS.HAS_MAIN_COMPANY) > -1) {
        return Relation.getTarget(new Relation(relationData, rel.id, rel.ref)) as Promise<Company>
      }
    }

    // Return the first company if there are no relations with a HAS_MAIN_COMPANY label
    const firstRel = companiesQuerySnapshot.docs[0]

    return Relation.getTarget(new Relation(firstRel.data() as RelationData, firstRel.id, firstRel.ref)) as Promise<Company>
  }

  async updateCurrentUser(data: any): Promise<Person> {
    let currentUser = await this.currentUser$.pipe(take(1)).toPromise()
    if (currentUser != null && currentUser.update) {
      currentUser = await currentUser.update(data)
    }

    return currentUser!
  }

  async createUserAndCompany(
    userUid: string,
    personData: PersonData,
    companyData: CompanyData
  ): Promise<{ person: Person; company: Company }> {
    if (userUid == null) {
      throw new Error(`Unable to create user document, user uid was null or undefined`)
    }

    const batch = this.db.firestore.batch()

    // Create user document
    const personRef = this.db.collection(COLLECTIONS.PEOPLE).doc(userUid).ref
    batch.set(personRef, personData, { merge: true })
    // @ts-ignore
    const person = new Person(personData, userUid, personRef)

    // Create company document for user and add default user<->company relations
    const company = Company.batchCreate(batch, Company, companyData)
    person.batchAdd(batch, company, [LABELS.ADMINISTRATOR])
    company.batchAdd(batch, person, [LABELS.ADMINISTRATED_BY])

    await batch.commit()

    return { person, company }
  }

  async fetchUserDocSnap(userUid: string) {
    const personRef = this.db.collection(COLLECTIONS.PEOPLE).doc(userUid).ref

    return personRef.get()
  }

  async createAuthUserAndUserData(registrationData: UserRegistrationData) {
    const result = await this.cloudFunctionsService.createAuthUserAndCompany(registrationData).toPromise()

    if (result && result.success) {
      await this.firebaseAuthService.login(registrationData.personData.email, registrationData.password)

      return true
    }

    throw new Error(result?.error ?? "Error in user registration!")
  }

  async createOrFetchUserDocForMicrosoftAdUser(user: firebase.auth.UserCredential) {
    const personData = {
      name: user?.user?.displayName ?? "Untitled name",
      fullName: user?.user?.displayName ?? "Untitled fullname",
      email: user?.user?.email,
    } as PersonData

    const batch = this.db.firestore.batch()

    // Create user document if doesn't exist
    const personRef = this.db.collection(COLLECTIONS.PEOPLE).doc(user!.user!.uid).ref
    const personDoc = await personRef.get()
    if (!personDoc.exists) {
      batch.set(personRef, personData, { merge: true })
      // @ts-ignore
      const person = new Person(personData, user.user.uid, personRef)
      await batch.commit()

      return person
    }

    // @ts-ignore
    return new Person(personDoc.data() as PersonData, personDoc.id, personDoc.ref)
  }

  public sendPasswordResetEmail(email: string) {
    return this.firebaseAuthService.auth.sendPasswordResetEmail(email)
  }

  public async sendEmailVerificationEmail() {
    const user = await this.firebaseAuthService.auth.currentUser

    return user?.sendEmailVerification()
  }

  addProject(personUid: string, projectUid: string, labels: string[]) {
    return Person.get(personUid).then((person) => {
      return Project.get(projectUid).then((project) => {
        // @ts-ignore
        return person.add(project, labels).then((_) => project.add(person, Relation.invertLabels(labels)))
      })
    })
  }

  addItem(personUid: string, itemUid: string, labels: string[]) {
    return Person.get(personUid).then((person) => {
      return Item.get(itemUid).then((item) => {
        // @ts-ignore
        return person.add(item, labels).then((_) => item.add(person, Relation.invertLabels(labels)))
      })
    })
  }

  getFirebaseAuthState() {
    return this.firebaseAuthService.afAuth.authState
  }

  async login(email: string, password: string) {
    const auth = await this.firebaseAuthService.login(email, password)

    return auth
  }

  logout(): Promise<void> {
    localStorage.clear()

    return this.firebaseAuthService.logout()
  }

  public async loginWithMicrosoft() {
    const provider = new OAuthProvider("oidc.hydra")
    provider.setCustomParameters({
      // This is disabled because it is causing issues with sweco tenants
      // prompt: "consent",
    })

    return this.firebaseAuthService.afAuth.signInWithPopup(provider).then((x) => {
      if (x.credential?.providerId === "oidc.hydra") {
        const credentials = x.credential as OAuthCredential
        const { idToken: id_token, accessToken: access_token } = credentials
        const additionalUserInfo = x.additionalUserInfo!
        // INFO: assume oidc.hydra gives us all this (as it should), rigmarole
        // casting (with additional hidden props)
        const { profile } = additionalUserInfo as unknown as { profile: { iss: string } }
        const { iss: issuer } = profile
        localStorage.setItem("one-login:issuer", issuer)
        this.authority$.next(issuer)
        // INFO: store authentication information in local-storage (firebase auth session is not
        // tied to session).
        localStorage.setItem(`oidc.user:${issuer}:field`, JSON.stringify({ access_token, id_token, profile }))
      } else {
        this.authority$.next("")
      }

      return x
    })
  }
}
