import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import { IAnonymousReportDialogData } from "./anonymous-report-dialog.interface"

@Component({
  selector: "checkd-anonymous-report-dialog",
  templateUrl: "./anonymous-report-dialog.component.html",
  styleUrls: ["./anonymous-report-dialog.component.scss"],
})
export class AnonymousReportDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AnonymousReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAnonymousReportDialogData
  ) {}
}
