import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout"
import { Injectable } from "@angular/core"
import { Observable, Subject } from "rxjs"
import { map, shareReplay } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class SideNavService {
  readonly isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay(1)
  )

  readonly sideNavButtonClicked$ = new Subject<"open" | "close" | "toggle">()

  constructor(private breakpointObserver: BreakpointObserver) {}

  toggle() {
    this.sideNavButtonClicked$.next("toggle")
  }

  close() {
    this.sideNavButtonClicked$.next("close")
  }

  open() {
    this.sideNavButtonClicked$.next("open")
  }
}
