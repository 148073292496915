import { ElementOptionCode, TemplateElement, TemplateElementType } from "./template-element"

class MainElement extends TemplateElement {
  override name: string = ""
  override type: TemplateElementType = TemplateElementType.MAIN_FIELD
  override typeAlias: string = "Main field"
  override value = ""
}

class SubElement extends TemplateElement {
  override name: string = ""
  override type: TemplateElementType = TemplateElementType.SUB_FIELD
  override typeAlias: string = "Sub Field"
  override value = ""
}

class MainSubElement extends TemplateElement {
  override name: string = "Main and Sub Field"
  override icon?: string = "forms:main-and-subfield"
  override type: TemplateElementType = TemplateElementType.MAIN_SUB_FIELD
  override typeAlias: string = "Main and Sub Field"
  override value: any[] | null = null
  override children = [new MainElement(), new SubElement()]
  override options = [
    {
      id: 1,
      name: "Quarter",
      type: "checkbox",
      code: ElementOptionCode.QUARTER,
      value: false,
    },
    {
      id: 2,
      name: "Half",
      type: "checkbox",
      code: ElementOptionCode.HALF,
      value: false,
    },
    {
      id: 3,
      name: "Required",
      type: "checkbox",
      code: ElementOptionCode.REQUIRED,
      value: false,
    },
    {
      id: 4,
      name: "Text Color",
      type: "color",
      code: ElementOptionCode.MAIN_SUB_FIELD_TEXT_COLOR,
      value: "#000000",
    },
    {
      id: 5,
      name: "Background Color",
      type: "color",
      code: ElementOptionCode.MAIN_SUB_FIELD_BACKGROUND_COLOR,
      value: "#DCDCDC",
    },
  ]
}

export { MainSubElement, MainElement, SubElement }
