import { Overlay } from "@angular/cdk/overlay"
import { ComponentPortal } from "@angular/cdk/portal"
import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core"
import { combineLatest, firstValueFrom, race, Subject } from "rxjs"
import { map, take, takeUntil } from "rxjs/operators"
import { ChannelService } from "stream-chat-angular"

import { FieldChatViewService, UserService } from "../../services"

import { AddMembersDialogComponent } from "../add-members-dialog/add-members-dialog.component"

@Component({
  selector: "field-chat-member-list",
  templateUrl: "./member-list.component.html",
  styleUrls: ["./member-list.component.scss"],
})
export class MemberListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>()

  @Output() closeMemberListBtn = new EventEmitter<boolean>()

  readonly members$ = this.channelService.activeChannel$.pipe(
    map((channel) => {
      const members = Object.values(channel?.state?.members ?? {}) ?? []

      return members.map((member) => member.user)
    })
  )

  readonly currentUserCanAddMembers$ = combineLatest([
    this.fieldChatViewService.channelTypeVisible$,
    this.userService.currentUserIsAdminForTheirCompany$,
    this.fieldChatViewService.userIsCurrentProjectAdmin$,
  ]).pipe(
    map(([channelType, userIsAdminForTheirCompany, userIsProjectAdmin]) => {
      if (channelType === "company") {
        return userIsAdminForTheirCompany
      }

      return userIsProjectAdmin
    })
  )

  constructor(
    public channelService: ChannelService,
    private overlay: Overlay,
    private fieldChatViewService: FieldChatViewService,
    private userService: UserService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  closeMemberList(value: boolean) {
    this.closeMemberListBtn.emit(value)
  }

  onAddMembersClicked() {
    const overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      hasBackdrop: true,
    })
    const addMemberOverlayPortal = new ComponentPortal(AddMembersDialogComponent)

    const componentRef = overlayRef.attach(addMemberOverlayPortal)

    race(overlayRef.backdropClick().pipe(map((_) => null)), componentRef.instance.onClose)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(async (selectedMemberUids) => {
        overlayRef.detach()

        if (selectedMemberUids == null) {
          return
        }

        const channel = await firstValueFrom(this.channelService.activeChannel$)
        await channel?.addMembers(selectedMemberUids)
      })
  }
}
