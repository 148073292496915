<ng-container *ngIf="agreements.currentUserAgreementsStatus$ | async as userAgreementsStatus">
  <ng-container *ngIf="userAgreementsStatus.notAgreedTo.length > 0 || userAgreementsStatus.notUpToDate.length > 0">

    <h3>User Agreements</h3>

    <ng-container *ngIf="userAgreementsStatus.notAgreedTo.length > 0">
      <p>You need to read and agree to the following:</p>
      <mat-list>
        <mat-list-item *ngFor="let agreement of userAgreementsStatus.notAgreedTo">
          <a target="_blank" href="{{agreement.url}}">{{agreement.name}}</a>
        </mat-list-item>
      </mat-list>
    </ng-container>

    <ng-container *ngIf="userAgreementsStatus.notUpToDate.length > 0">
      <p>We have updated some of our terms. Please read and agree to the following:</p>
      <mat-list-item *ngFor="let agreement of userAgreementsStatus.notUpToDate">
        <a target="_blank" href="{{agreement.url}}">{{agreement.name}}</a>
      </mat-list-item>
    </ng-container>

    <mat-checkbox (change)="updateAgreements($event)">I have read and agreed to this</mat-checkbox>

  </ng-container>
</ng-container>
