import { LegacyTemplateData, TemplateSharingOption } from "@models/common/legacy-template.interface"
import { LegacyTemplate } from "../../models/common/legacy-template"
import { Template } from "./template"
import { CheckboxValueElement } from "./template-elements/checbox-value-element"
import { DropdownValueElement } from "./template-elements/dropdown-element"
import { ElementOptionCode, OptionElement, TemplateElement, TemplateElementType } from "./template-elements/template-element"
import { AcceptedElement, RejectedElement } from "./template-elements/toggle-element"

function getLegacyFormsItems(title: string, info = "", options: OptionElement[], prefill: any): { [key: string]: any } {
  return {
    id: 1,
    title,
    templateType: "typeformsitems",
    type: "formsitems",
    typeAlies: "Item element",
    items: [],
    prefill,
    info,
    filledby: "",
    values: [],
    child: [],
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacyText(title: string, content: string, info = "", options: OptionElement[]): { [key: string]: any } {
  return {
    id: 0,
    title,
    templateType: "typedefault",
    type: "text",
    typeAlies: "Text",
    info,
    filledby: "",
    value: "",
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacyPageBreak(): { [key: string]: any } {
  return {
    type: TemplateElementType.PAGE_BREAK.toLowerCase(),
    typeAlies: "Page Break",
    value: "",
    options: [],
  }
}

function getLegacyTextField(title: string, info = "", options: OptionElement[], value = ""): { [key: string]: any } {
  return {
    id: 0,
    title,
    templateType: "typedefault",
    type: "textfield",
    typeAlies: "Text Field",
    info,
    filledby: "",
    value,
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacySlider(title: string, info = "", options: OptionElement[]): { [key: string]: any } {
  return {
    id: 0,
    title,
    templateType: "typedefault",
    type: "slider",
    typeAlies: "Slider",
    info,
    filledby: "",
    value: "",
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacyDate(title: string, info = "", options: OptionElement[]): { [key: string]: any } {
  return {
    id: 0,
    title,
    templateType: "typedefault",
    type: "date",
    typeAlies: "Date",
    info,
    filledby: "",
    value: "",
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacyTime(title: string, info = "", options: OptionElement[]): { [key: string]: any } {
  return {
    id: 0,
    title,
    templateType: "typedefault",
    type: "time",
    typeAlies: "Time",
    info,
    filledby: "",
    value: "",
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacySignature(title: string, info: string, options: OptionElement[]): { [key: string]: any } {
  return {
    id: 0,
    title,
    templateType: "typedefault",
    type: "signature",
    typeAlies: "Signature",
    info,
    filledby: "",
    value: "",
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacyMainSubField(mainfield: string, subfield: string, options: OptionElement[]): { [key: string]: any } {
  return {
    id: 0,
    title: mainfield,
    templateType: "typedefault",
    type: "mainandsubfield",
    typeAlies: "Main and Sub Field",
    info: subfield,
    filledby: "",
    values: [
      { id: 1, name: "main", value: "" },
      { id: 2, name: "subfield", value: "" },
    ],
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacyCamera(title: string, info = "", options: OptionElement[]): { [key: string]: any } {
  return {
    id: 0,
    title,
    templateType: "typedefault",
    type: "camera",
    typeAlies: "Camera",
    info,
    filledby: "",
    value: "",
    values: [],
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacyImageElement(title: string, info = "", value: string, options: OptionElement[]): { [key: string]: any } {
  return {
    id: 0,
    title,
    templateType: "typedefault",
    type: "image",
    typeAlies: "Image",
    info,
    filledby: "",
    value,
    values: [],
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacyToggle(
  title: string,
  info = "",
  accepted: AcceptedElement,
  rejected: RejectedElement,
  options: OptionElement[]
): { [key: string]: any } {
  return {
    id: 0,
    title,
    templateType: "typeyesno",
    type: "yesno",
    typeAlies: "Yes/No",
    info,
    filledby: "",
    value: "",
    accepted: (accepted.children || []).map(transformTemplateElement),
    rejected: (rejected.children || []).map(transformTemplateElement),
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacyCheckbox(title: string, info = "", values: CheckboxValueElement[], options: OptionElement[]): { [key: string]: any } {
  return {
    id: 0,
    title,
    templateType: "typecheckbox",
    type: "checkbox",
    typeAlies: "Check Box",
    info,
    filledby: "",
    isMultiSelect: true,
    isVertical: false,
    values: (values || []).map(getLegacyCheckboxValue),
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacyDropdown(title: string, info = "", values: DropdownValueElement[], options: OptionElement[]): { [key: string]: any } {
  return {
    id: 0,
    title,
    templateType: "typedropdown",
    type: "dropdown",
    typeAlies: "Drop Down",
    info,
    filledby: "",
    isMultiSelect: false,
    values: (values || []).map((value) => getLegacyDropdownValue(value.name)),
    addNew: false,
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacyTabular(title: string, info = "", children: TemplateElement[], options: OptionElement[]): { [key: string]: any } {
  return {
    id: 0,
    title,
    templateType: "typetabularform",
    type: "tabularform",
    typeAlies: "Tabular Form",
    info,
    filledby: "",
    values: [],
    child: [(children || []).map(transformTemplateElement)],
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacyTable(title: string, info = "", children: TemplateElement[], options: OptionElement[]): { [key: string]: any } {
  return {
    id: 0,
    title,
    templateType: "typetable",
    type: "table",
    typeAlies: "Table",
    info,
    filledby: "",
    values: [],
    child: [(children || []).map(transformTemplateElement)],
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}
function getLegacyTableChild(title: string, info = "", children: TemplateElement[], options: OptionElement[]): { [key: string]: any } {
  return {
    id: 0,
    title,
    templateType: "typetablechild",
    type: "table_child",
    typeAlies: "Table Child",
    info,
    filledby: "",
    values: [],
    child: [(children || []).map(transformTemplateElement)],
    options: (options || []).map(getLegacyOption),
    sortOrder: 0,
  }
}

function getLegacyCheckboxValue(value: CheckboxValueElement, id: number): { [key: string]: any } {
  return {
    id,
    name: value.name,
    value: false,
    options: [],
    values: [],
    child: (value.children || []).map(transformTemplateElement),
    rejected: null,
    accepted: null,
    condition: false,
  }
}

function getLegacyDropdownValue(name: string): { [key: string]: any } {
  return {
    id: 0,
    name,
    value: "false",
    options: [],
    values: [],
    child: [],
    rejected: null,
    accepted: null,
    condition: false,
  }
}

function getLegacyOption(option: OptionElement): { [key: string]: any } {
  return {
    id: option.id,
    code: getLegacyCode(option.code),
    type: option.type,
    name: option.name,
    value: option.value,
  }
}

function getLegacyCode(code: ElementOptionCode): string {
  switch (code) {
    case ElementOptionCode.QUARTER:
      return "quater"
    default:
      return code.toLowerCase()
  }
}

export function transformTemplateElement(element: TemplateElement): Record<string, any> | null {
  switch (element.type) {
    case TemplateElementType.TEXT:
      return getLegacyText(element.name, element.value, element.info, element.options)
    case TemplateElementType.TEXT_FIELD:
      return getLegacyTextField(element.name, element.info, element.options, element.value)
    case TemplateElementType.SLIDER:
      return getLegacySlider(element.name, element.info, element.options)
    case TemplateElementType.CHECKBOX:
      return getLegacyCheckbox(element.name, element.info, element.children as CheckboxValueElement[], element.options)
    case TemplateElementType.DROPDOWN:
      return getLegacyDropdown(element.name, element.info, element.children as DropdownValueElement[], element.options)
    case TemplateElementType.DATE:
      return getLegacyDate(element.name, element.info, element.options)
    case TemplateElementType.TIME:
      return getLegacyTime(element.name, element.info, element.options)
    case TemplateElementType.MAIN_SUB_FIELD:
      return getLegacyMainSubField(element.children[0].value, element.children[1].value, element.options)
    case TemplateElementType.CAMERA:
      return getLegacyCamera(element.name, element.info, element.options)
    case TemplateElementType.SIGNATURE:
      return getLegacySignature(element.name, element.info, element.options)
    case TemplateElementType.TABULAR:
      return getLegacyTabular(element.name, element.info, element.children, element.options)
    case TemplateElementType.TABLE:
      return getLegacyTable(element.name, element.info, element.children, element.options)
    case TemplateElementType.TABLE_CHILD:
      return getLegacyTableChild(element.name, element.info, element.children, element.options)
    case TemplateElementType.FORMS_ITEMS:
      return getLegacyFormsItems(element.name, element.info, element.options, element.prefill)
    case TemplateElementType.PAGE_BREAK:
      return getLegacyPageBreak()

    case TemplateElementType.TOGGLE:
      return getLegacyToggle(
        element.name,
        element.info,
        element.children[0] as AcceptedElement,
        element.children[1] as RejectedElement,
        element.options
      )
    case TemplateElementType.IMAGE:
      return getLegacyImageElement(element.name, element.info, element.value, element.options)
    default:
      return null
  }
}

export function transformTemplateElements(elements: TemplateElement[]) {
  return elements
    .map(transformTemplateElement)
    .filter((it) => it)
    .map((element, i) => ({ ...element, id: i }))
}

export function transformTemplate(template: Template) {
  const data: LegacyTemplateData = {
    reportName: template.name,
    headerTemplate: "[]",
    detailTemplate: JSON.stringify(transformTemplateElements(template.elements)),
    isDraft: template.isDraft,
    description: template.description,
    logo: template.logo,
    pdfFooterText: template.pdfFooterText,
    publicVersion: template.publicVersion,
    internalVersion: template.internalVersion,
    footerLogo: template.footerLogo,
    sharedWith: template.sharingType || TemplateSharingOption.NONE,
    enableAnonymousReports: template.enableAnonymousReports,
    tags: template.tags,
  }

  // @ts-ignore
  return new LegacyTemplate(data, template.uid || null, template.ref || null)
}
