import { Component, Inject, Input, OnInit } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { ActivatedRoute, Router } from "@angular/router"

import { Company, Invitation, LABELS, Person, PersonWithRoles, Project, ProjectAdministratorRole, Role, RoleType } from "@models/common"

import { Clipboard } from "@angular/cdk/clipboard"
import { DOCUMENT } from "@angular/common"
import { CompanyService, InvitationService, ProjectService, SnackbarService, TimelineService, UserService } from "@services"
import { IProjectMembersWithRoles } from ".."
import { DialogService } from "../../dialogs/dialog.service"
import { IMemberRemovalDialogData } from "../project-member-removal-dialog/project-member-removal-dialog.component"
import { ProjectDialogService } from "../services/project-dialog.service"

@Component({
  selector: "checkd-project-people",
  templateUrl: "./project-people.component.html",
  styleUrls: ["./project-people.component.scss"],
})
export class ProjectPeopleComponent implements OnInit {
  @Input() project: Project
  @Input() projectMembers: Person[]
  @Input() currentUser: Person
  @Input() currentUserProjectRole: Role
  @Input() currentCompany: Company
  @Input() currentCompanyMembers: Person[]
  @Input() currentCompanyAssociates: Person[]
  @Input() projectEditorRoles: Role[]
  @Input() projectOwnerRoles: Role[]
  @Input() pendingProjectInvitations: Invitation[]
  @Input() projectMembersWithRoles: IProjectMembersWithRoles[]

  roles: any[] = [
    { label: LABELS.DEFAULT, description: "Project normal user" },
    { label: LABELS.ADMINISTRATOR, description: "Project administrator" },
    { label: LABELS.OWNER, description: "Project owner" },
  ]

  get editorCount() {
    return this.projectEditorRoles ? this.projectEditorRoles.length : 0
  }

  get ownerCount() {
    return this.projectOwnerRoles ? this.projectOwnerRoles.length : 0
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private projectDialogService: ProjectDialogService,
    private userService: UserService,
    private companyService: CompanyService,
    private snackbarService: SnackbarService,
    private invitationService: InvitationService,
    private timelineService: TimelineService,
    private db: AngularFirestore,
    private clipboard: Clipboard,
    @Inject(DOCUMENT) private document: Document,
    private dialogService: DialogService
  ) {}

  get canInvitePeople() {
    return (
      this.currentUserProjectRole != null &&
      this.currentUserProjectRole.canCreateTargetDocuments(Invitation.COLLECTION) &&
      this.currentUserProjectRole.canCreateTargetRelations(Invitation.COLLECTION)
    )
  }

  get canChangePeopleRole() {
    return this.currentUserProjectRole != null && this.currentUserProjectRole.canUpdateTargetRelations(Person.COLLECTION)
  }

  get canEditPosition() {
    return (this.canChangePeopleRole || this.currentUserProjectRole instanceof ProjectAdministratorRole) && !this.project.archived
  }

  ngOnInit() {
    return
  }

  addPerson() {
    this.showPeopleInvitationDialog(
      this.currentUser.uid,
      this.currentCompany.name,
      this.currentCompanyMembers,
      this.currentCompanyAssociates,
      this.project
    )
  }

  createAvatarThumbnailUrl(person: Person) {
    return this.userService.createAvatarThumbnailUrl(person)
  }

  async addPeopleDirectlyToProject(peopleAndRoles: PersonWithRoles[]) {
    return Promise.all(
      peopleAndRoles.map((personWithRole) => {
        const batch = this.db.firestore.batch()
        const roleName = (personWithRole.roles || ["DEFAULT"])[0]

        let roleType: RoleType

        switch (roleName) {
          case "ADMINISTRATOR":
            roleType = RoleType.PROJECT_ADMINISTRATOR
            break
          case "DEFAULT":
          default:
            roleType = RoleType.PROJECT_NORMAL
        }

        this.projectService.addProjectMember(batch, this.project, personWithRole.person, personWithRole.roles)
        this.timelineService.projectMemberAdded(batch, this.currentUser, personWithRole.person, roleType, this.project)

        return batch.commit()
      })
    )
  }

  contextMenuRemoveMemberText(person: Person): string {
    return this.currentUser.uid === person.uid ? "Leave project" : "Remove from project"
  }

  /*
    Notes:
    Anyone can remove themselves
    project owner: remove admins and normal users
                   remove self -> Check if project has another owner. If another owner, remove. If not transfer ownership?
    Project admin: Can't remove owners. Can't remove other admins.
                   Can leave project and remove normal users
    Normal users:  Can only remove self
  */
  userCanRemoveProjectMember(otherPerson: Person, otherRole: Role) {
    if (this.project.archived) {
      return false
    }

    // all project members can remove themselves
    if (this.currentUser.uid === otherPerson.uid) {
      return true
    }

    // project owners can remove anyone
    if (this.currentUserProjectRole.roleType === RoleType.PROJECT_OWNER) {
      return true
    }

    // project administrators can only remove normal project users
    if (this.currentUserProjectRole.roleType === RoleType.PROJECT_ADMINISTRATOR && otherRole.roleType === RoleType.PROJECT_NORMAL) {
      return true
    }

    return false
  }

  get canManageInvitations(): boolean {
    return [RoleType.PROJECT_ADMINISTRATOR, RoleType.PROJECT_OWNER].includes(this.currentUserProjectRole.roleType)
  }

  private activeItemsForMember(member: Person): number {
    try {
      const totalActivePerUser = this.project.aggregateData.totalAndActiveItemsPerUser

      return totalActivePerUser[member.uid].active
    } catch (error) {
      return 0
    }
  }

  async removeInvitationDialog(invitation: Invitation) {
    await this.invitationService.removeInvitationDialog(invitation)
  }

  public removeMember(memberToRemove: Person, memberToRemoveRole: Role) {
    const numActiveItems = this.activeItemsForMember(memberToRemove)

    if (this.currentUser.uid === memberToRemove.uid) {
      if (this.currentUserProjectRole.roleType === RoleType.PROJECT_OWNER && this.ownerCount <= 1) {
        return this.showRemoveUserDialog({
          member: memberToRemove,
          canRemoveMember: false,
          selfRemove: true,
          errorMsg: "You need to give ownership of this project to at least one more project member before removing yourself",
          numActiveItems,
        })
      }

      return this.showRemoveUserDialog({
        member: memberToRemove,
        canRemoveMember: true,
        selfRemove: true,
        errorMsg: "",
        numActiveItems,
      })
    }

    return this.showRemoveUserDialog({
      member: memberToRemove,
      canRemoveMember: true,
      selfRemove: false,
      errorMsg: "",
      numActiveItems,
    })
  }

  private async showRemoveUserDialog(dialogData: IMemberRemovalDialogData) {
    const result = await this.projectDialogService.showRemoveUserDialog(dialogData)

    if (result !== "userClickedConfirm" || !dialogData.canRemoveMember) {
      return
    }

    await this.projectService.removeProjectMember(this.project, this.currentUser, dialogData.member)

    if (dialogData.selfRemove) {
      await this.router.navigate(["/"])
      await this.snackbarService.showMessage("You have been removed from the project")
    } else {
      this.snackbarService.showMessage(`${dialogData.member.name} has been removed from this project`)
    }
  }

  private async showPeopleInvitationDialog(
    inviterUid: string,
    companyName: string,
    companyMembers: Person[],
    companyAssociates: Person[],
    project: Project
  ) {
    const result = await this.projectDialogService.showPeopleInvitationDialog(
      this.currentUserProjectRole,
      companyName,
      companyMembers,
      companyAssociates,
      project
    )

    if (result == null) {
      return
    }

    if (result.members != null && result.members.length > 0) {
      await this.addPeopleDirectlyToProject(result.members)
      this.snackbarService.showMessage(`${result.members.length} company members added to project!`)
    }

    if (result.associates != null && result.associates.length > 0) {
      await this.addPeopleDirectlyToProject(result.associates)
      this.snackbarService.showMessage(`${result.associates.length} company associates added to project!`)
    }

    if (result.emails != null && result.emails.length > 0) {
      const invitations = await this.invitationService.createProjectEmailInvitations(result.emails, inviterUid, project)
      this.snackbarService.showMessage(`${invitations.length} invitations are sent!`)
    }
  }

  public copyInvitationLink(invitation: Invitation) {
    const url = `${this.document.location.origin}/${invitation.ref!.path}`
    const success = this.clipboard.copy(`${url}`)
    if (success) {
      this.dialogService.confirm(
        `Invitation for ${invitation.targetEmail} copied!`,
        [
          `This link (${url}) can be sent to ${invitation.targetEmail} by e.g. email or an sms.`,
          `The receiver should be able to accept the invitation by pressing it.`,
          `NB: This invitation will not work for anyone other than the owner of this email.`,
        ].join(" "),
        true
      )
    }
  }
}
