import { pick } from "lodash"
import { IInternalFormsData } from "../interfaces-and-types/internal.interface"

export interface IFormsExternalElement {
  name: string
  description: string
  data: any
  type: string
  dataType: string
}

export interface IExternalFormsData {
  name: string
  description: string
  data: IFormsExternalElement[]
}

function transformCommonElementData(element: any) {
  return {
    ...pick(element, ["name", "description", "type", "dataType", "data"]),
  }
}

function transformElement(element: any): IFormsExternalElement {
  return transformCommonElementData(element)
}

export function transformInternalToExternal(template: IInternalFormsData): IExternalFormsData {
  const transformedResult = {
    name: template.name,
    description: template.description,
    data: template.data.map(transformElement),
  }

  // console.log(transformedResult)

  return transformedResult
}
