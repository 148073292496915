<h2 mat-dialog-title>Duplicate Project</h2>
<mat-dialog-content>
  <form [formGroup]="duplicationForm" class="flex flex-column" [ngStyle.gt-xs]="{'min-width': '400px'}">
    <mat-form-field>
      <mat-label>Project name</mat-label>
      <input matInput formControlName="newProjectName" required>
    </mat-form-field>


    <mat-form-field id="intercom-tour-project-description">
      <textarea matInput formControlName="newProjectDescription"></textarea>
      <mat-label>Description</mat-label>
    </mat-form-field>

    <section class="checkboxes flex flex-column">
      <h4 class="my-1">Include...</h4>
      <p>
        <mat-checkbox formControlName="includeDrawings">Drawings</mat-checkbox>
      </p>
      <p>
        <mat-checkbox formControlName="includePeople">People</mat-checkbox>
      </p>
      <p>
        <mat-checkbox formControlName="includeTemplates">Templates</mat-checkbox>
      </p>
    </section>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button class="checkd-btn cancel-btn-color" mat-dialog-close>Cancel</button>
  <button mat-button class="checkd-btn save-btn-color" [mat-dialog-close]="duplicationForm.value" [disabled]="duplicationForm.invalid">
    Duplicate
  </button>
</mat-dialog-actions>
