<h1 matDialogTitle>{{ data.title }}</h1>

<mat-dialog-content>
  <p>{{ data.content }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end" class="gap-2">
  <checkd-button *ngIf="!data?.hideCancelButton" bg="red" fg="white" (buttonClick)="dialogRef.close(false)">Cancel</checkd-button>
  <checkd-button bg="green" fg="white" (buttonClick)="dialogRef.close(true)">OK</checkd-button>
</mat-dialog-actions>
