<p-dialog header="Edit Template" [(visible)]="open" (onHide)="closeDialog()">
  <form *ngIf="open" id="dialogForm" [formGroup]="dialogForm" (ngSubmit)="saveAndPublishFormDialogButtonClicked(dialogForm!)">
    <section class="dialog-content flex flex-column">
      <label for="form-title" class="block">Form title:</label>
      <input id="form-title" class="mb-2" type="text" pInputText formControlName="title" />

      <label for="float-input">Description:</label>
      <textarea id="float-input" class="mb-2" pInputTextarea [rows]="5" formControlName="description"></textarea>

      <label for="float-public-version">Public version:</label>
      <input id="float-public-version" class="mb-2" type="text" pInputText formControlName="publicVersion" />

      <label for="float-changelog">What's changed: <span class="grayout">(Optional)</span></label>
      <textarea id="float-changelog" class="mb-2" pInputTextarea [rows]="2" formControlName="changelog"></textarea>

      <label for="tag-selection">Template tags:</label>
      <checkd-tag-selection id="tag-selection" formControlName="tags"></checkd-tag-selection>

      <label for="float-input" class="mt-3">Publish Options:</label>
      <span class="form-accessible-by-header mb-1">Form is accessible by:</span>

      <div class="field-radiobutton">
        <p-radioButton value="{{ TemplateSharingOption.NONE }}" formControlName="sharedWith" inputId="internal"></p-radioButton>
        <i class="pi pi-lock ml-3"></i>
        <label class="flex flex-column" for="internal">
          <span class="inline-block font-bold">{{ currentUserCompanyName$ | async }}</span>
          <span class="inline-block text-sm">Your company colleagues can use this template in active projects </span>
        </label>
      </div>

      <div class="field-radiobutton">
        <p-radioButton
          value="{{ TemplateSharingOption.COMPANY_SUBSCRIBERS }}"
          formControlName="sharedWith"
          inputId="internal"
          [disabled]="this.currentCompany ? !this.currentCompany.isTemplatePublisher : false"
        ></p-radioButton>
        <img src="assets/icons/subscribers_icon.svg" width="18" height="18" class="ml-3" />
        <label class="flex flex-column" for="internal">
          <span class="inline-block font-bold">Your subscriber list</span>
          <span class="inline-block text-sm">Your subscribers can add this template to their company templates</span>
        </label>
      </div>

      <div class="field-radiobutton">
        <p-radioButton
          value="{{ TemplateSharingOption.ALL_PUBLIC_TEMPLATE_USERS }}"
          formControlName="sharedWith"
          inputId="internal"
          [disabled]="this.currentCompany ? !this.currentCompany.isTemplatePublisher : false"
        ></p-radioButton>
        <i class="pi pi-globe ml-3"></i>
        <label class="flex flex-column" for="internal">
          <span class="inline-block font-bold">Public - Forms Library</span>
          <span class="inline-block text-sm">Anyone with the access to Forms Library can add it to their company templates</span>
        </label>
      </div>
    </section>
  </form>

  <ng-template pTemplate="footer">
    <div class="edit-forms__btn-container">
      <button primary-button [type]="'outline'" type="button" label="Cancel" (click)="editFormDialogCancelButtonClicked()">Cancel</button>
      <button primary-button form="dialogForm" type="submit" [disabled]="saveButtonDisabled()">Save and publish</button>
    </div>
  </ng-template>
</p-dialog>
