<div class="drawing-section-container flex flex-column justify-content-center align-items-center overflow-y-auto flex-1 h-full">
  <ng-container *ngIf="currentCompanyHasDrawingFeature; else currentCompanyDoesNotHaveDrawingFeature">
    <ng-container *ngIf="itemIsAttachedToDrawing; else itemIsNotAttachedToADrawing">
      <ng-container *ngIf="drawing$ | async as drawing; else spinner">
        <a [href]="drawing?.getUrlPath(project.uid)" target="_blank" class="drawing-link">
          <div>
            <span>{{ truncate((drawingData$ | async)?.name) }}</span>
            <mat-icon>open_in_new</mat-icon>
          </div>
        </a>

        <checkd-drawing class="mb-4 flex-auto" [drawing]="drawingData$" [items]="items$"> </checkd-drawing>
      </ng-container>

      <ng-template #spinner>
        <mat-spinner></mat-spinner>
      </ng-template>
    </ng-container>

    <ng-template #itemIsNotAttachedToADrawing>
      <section class="no-item-attached" *ngIf="!userIsSelectingDrawing && !selectedDrawing; else selectDrawingToAttachTo">
        <img (click)="imgOnClick()" class="placeholder-icon" alt="placeholder" src="assets/icons/3d_drawing_icon.svg" />
        <p>This item is not attached to a drawing.</p>
        <button primary-button [isRounded]="true" [disabled]="project.archived" (click)="attachToDrawingClicked()">
          Attach item to a drawing
        </button>
      </section>

      <ng-template #selectDrawingToAttachTo>
        <section class="drawing-selection mb-auto" *ngIf="!selectedDrawing; else selectLocationOnSelectedDrawing">
          <span>Select drawing</span>
          <mat-selection-list *ngIf="projectDrawings$ | async as drawingList" [multiple]="false">
            <ng-container *ngFor="let drawingOption of drawingList; let last = last">
              <mat-list-option (click)="userSelectedDrawing(drawingOption)">
                {{ drawingOption.name }}
              </mat-list-option>
              <mat-divider *ngIf="!last"></mat-divider>
            </ng-container>
          </mat-selection-list>
        </section>
      </ng-template>

      <ng-template #selectLocationOnSelectedDrawing>
        <app-checkd-drawing
          *ngIf="selectedDrawing"
          [enableGestureToAddItemMarker]="true"
          [drawingData]="selectedDrawing.data"
          (onItemCreate)="itemCreated($event)"
          (onItemLayerMoved)="itemMoved($event)"
        >
        </app-checkd-drawing>
      </ng-template>
    </ng-template>
  </ng-container>
</div>

<ng-template #currentCompanyDoesNotHaveDrawingFeature>
  <p>This project's company does not have the Drawings feature</p>
  <p>Contact sales to enable it</p>
</ng-template>
