import { Component, OnInit, Input } from "@angular/core"

import { FilestackService } from "@services"

@Component({
  selector: "item-image-thumbnail",
  templateUrl: "./item-image-thumbnail.component.html",
  styleUrls: ["./item-image-thumbnail.component.scss"],
})
export class ItemImageThumbnailComponent implements OnInit {
  @Input() url: string
  @Input() maxWidth: number = 150

  constructor(private filestackService: FilestackService) {}

  ngOnInit() {}

  get thumbnailUrl() {
    if (this.url == null) return ""
    const thumbnailUrl = this.filestackService.getThumbnailUrl(this.url, this.maxWidth)
    return thumbnailUrl
  }
}
