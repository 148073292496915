<h2 mat-dialog-title>Specify a name (required) and a description (optional) for your template:</h2>

<mat-dialog-content class="flex flex-column">
  <mat-form-field>
    <input matInput placeholder="Name" [(ngModel)]="name" />
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Description" [(ngModel)]="description" />
  </mat-form-field>
  <div class="save-template__btn-container">
    <button primary-button [type]="'outline'" (click)="cancel()">Cancel</button>
    <button primary-button [disabled]="name.trim().length <= 0" (click)="save()">Save</button>
  </div>
</mat-dialog-content>
