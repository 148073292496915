<mat-dialog-content class="flex flex-column">
  <div class="align-self-center flex flex-column">
    <img class="next-logo" src="assets/icons/next_project_dark_background.svg" />
    <h1 class="mt-2">NEXT <span style="color: #ff6e01">Project</span></h1>
  </div>

  <p class="header-text">Export</p>
  <p class="content-text">{{ data.project.name }}</p>

  <mat-divider class="align-self-stretch my-3"></mat-divider>

  <p class="header-text">To Next Project</p>
  <p class="content-text">{{ nextProjectProjectName$ | async }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button (click)="closeDialog()">Cancel</button>
  <button class="save-btn-color" mat-flat-button (click)="exportToNext()">Export form</button>
</mat-dialog-actions>
