import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { VisibilitySelection } from "@checkd-form/components/report-visibility-toggle/report-visibility-toggle.component"
import { Person, TimelineType } from "@models/common"
import { ModelInCollection } from "@models/common/collections"
import { COLLECTIONS } from "@models/common/collections.interface"
import { IDocumentPermissions, IPartialDocumentPermissions, PermissionsHandler, Role, RoleType } from "@models/common/roles-and-permissions"
import { TimelineService } from "@services/timeline.service"

@Injectable({
  providedIn: "root",
})
export class PermissionsService {
  // FIXME This is a workaround for the missing defaultPermissions property in the LegacyReport model.
  //   Remove this when it's fixed
  legacyReportDefaultPermissions: IDocumentPermissions = {
    roles: {
      [RoleType.PROJECT_OWNER]: { create: true, read: true, update: true, delete: true },
      [RoleType.PROJECT_ADMINISTRATOR]: { create: true, read: true, update: true, delete: true },
      [RoleType.PROJECT_NORMAL]: { create: false, read: false, update: false, delete: false },
      [RoleType.NONE]: { create: false, read: false, update: false, delete: false },
    },
  }

  constructor(private db: AngularFirestore, private timelineService: TimelineService) {}

  canCreate(currentRole: Role, model: ModelInCollection) {
    const documentPermissions = model.permissions.roles[currentRole.roleType]

    return currentRole.canCreateTargetDocuments(model.collectionName, {
      documentPermissions,
    })
  }

  canRead(currentRole: Role, model: ModelInCollection) {
    // FIXME This is a workaround for the missing defaultPermissions property in the LegacyReport model.
    //   Remove this when it's fixed
    const hack =
      model.collectionName && model.collectionName === COLLECTIONS.LEGACY_REPORTS
        ? PermissionsHandler.mergePermissions(this.legacyReportDefaultPermissions, model.permissions)
        : model.permissions

    const documentPermissions = hack.roles[currentRole.roleType]

    return currentRole.canReadTargetDocuments(model.collectionName, {
      documentPermissions,
    })
  }

  canUpdate(currentRole: Role, model: ModelInCollection) {
    const documentPermissions = model.permissions.roles[currentRole.roleType]

    return currentRole.canUpdateTargetDocuments(model.collectionName, {
      documentPermissions,
    })
  }

  canDelete(currentRole: Role, model: ModelInCollection) {
    const documentPermissions = model.permissions.roles[currentRole.roleType]

    return currentRole.canDeleteTargetDocuments(model.collectionName, {
      documentPermissions,
    })
  }

  async updateDocumentPermissions(model: ModelInCollection, visibility: VisibilitySelection, user: Person) {
    let payload: IPartialDocumentPermissions
    let timelineType:
      | TimelineType.REPORT_SHARE_WITH_EVERYONE
      | TimelineType.REPORT_SHARE_WITH_PROJECT_MEMBERS
      | TimelineType.REPORT_SHARE_WITH_PROJECT_OWNERS_AND_ADMINS

    switch (visibility) {
      case "owners_and_admins":
        payload = {
          roles: {
            PROJECT_ADMINISTRATOR: { read: true },
            PROJECT_OWNER: { read: true },
            PROJECT_NORMAL: { read: false },
            NONE: { read: false },
          },
        }
        timelineType = TimelineType.REPORT_SHARE_WITH_PROJECT_OWNERS_AND_ADMINS
        break
      case "all_project_members":
        payload = {
          roles: {
            PROJECT_ADMINISTRATOR: { read: true },
            PROJECT_OWNER: { read: true },
            PROJECT_NORMAL: { read: true },
            NONE: { read: false },
          },
        }
        timelineType = TimelineType.REPORT_SHARE_WITH_PROJECT_MEMBERS
        break
      case "public_for_everyone":
        payload = {
          roles: {
            PROJECT_ADMINISTRATOR: { read: true },
            PROJECT_OWNER: { read: true },
            PROJECT_NORMAL: { read: true },
            NONE: { read: true },
          },
        }
        timelineType = TimelineType.REPORT_SHARE_WITH_EVERYONE
        break
      default:
        const assertUnreachable: never = visibility
    }

    const batch = this.db.firestore.batch()
    // We need to bypass the merged default permissions here because we don't want to
    // write the defaults to the database.
    // If we do that, the permissions precedence will stop making sense
    const permissions = PermissionsHandler.mergePermissions(model.data.permissions || { roles: {} }, payload!)
    await model.batchUpdate(batch, { permissions })
    this.timelineService.reportVisibilityChanged(batch, user, model, timelineType!)
    await batch.commit()
  }
}
