import { ComponentType } from "@angular/cdk/portal"
import { Injectable, TemplateRef } from "@angular/core"
import { MatDialog, MatDialogConfig } from "@angular/material/dialog"
import { GeneralReport } from "@models/common/general-report"
import { LegacyTemplate } from "@models/common/legacy-template"
import { Person } from "@models/common/person"
import { TagCollection } from "@models/common/tag-collection"
import { take } from "rxjs/operators"
import { AnonymousReportDialogComponent } from "./anonymous-report-dialog/anonymous-report-dialog.component"
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component"
import { DuplicateReportDialogComponent } from "./duplicate-report-dialog/duplicate-report-dialog.component"
import { FailedUploadsDialogComponent } from "./failed-uploads-dialog/failed-uploads-dialog.component"
import { GeneratePdfSettingsDialogComponent } from "./generate-pdf-settings-dialog/generate-pdf-settings-dialog.component"
import { LinkSharingDialogComponent } from "./link-sharing-dialog/link-sharing-dialog.component"
import { ReportSelectionDialogComponent } from "./report-selection-dialog/report-selection-dialog.component"
import { ISpinnerDialogConfig, SpinnerDialogComponent } from "./spinner-dialog/spinner-dialog.component"
import { TagsSelectionDialogComponent } from "./tags-selection-dialog/tags-selection-dialog.component"
import { ITextfieldDialogData, TextfieldDialogComponent } from "./textfield-dialog/textfield-dialog.component"

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(public dialog: MatDialog) {}

  openDialog<T, D = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>): Promise<any> {
    return this.dialog.open(componentOrTemplateRef, config).afterClosed().pipe(take(1)).toPromise()
  }

  confirm(title: string, content: string, hideCancelButton = false, disableClose = false) {
    return this.dialog
      .open(ConfirmDialogComponent, {
        data: { title, content, hideCancelButton },
        disableClose,
      })
      .afterClosed()
      .pipe(take(1))
      .toPromise()
  }

  showSpinner(message = "", config: Partial<ISpinnerDialogConfig> = {}) {
    const disableClose = config.hasOwnProperty("disableClose") ? config.disableClose : false

    return this.dialog.open(SpinnerDialogComponent, {
      disableClose,
      data: {
        message,
        diameter: 100,
        strokeWidth: 10,
        ...config,
      },
    })
  }

  showShareableLink(title: string, message: string[], link: string) {
    return this.dialog.open(LinkSharingDialogComponent, {
      data: { title, message, link },
    })
  }

  showTextfield(data: ITextfieldDialogData) {
    return this.dialog.open(TextfieldDialogComponent, { data }).afterClosed().pipe(take(1)).toPromise()
  }

  showReportSelectionDialog() {
    return this.openDialog(ReportSelectionDialogComponent)
  }

  showTagSelectionDialog(report: GeneralReport, tagCollection?: TagCollection) {
    return this.openDialog(TagsSelectionDialogComponent, {
      minWidth: "60vw",
      data: {
        title: report.name,
        selectedTags: report.tags,
        tagSet: (tagCollection && tagCollection.tags) || [] || [],
      },
    })
  }

  /**
   * Opens a dialog for user to decide amount of duplicated reports, and choose name of each one.
   * Returns a list of duplicated reports, and name and UID of the original report.
   * @param report
   */
  showDuplicateDialog(report: GeneralReport) {
    return this.openDialog(DuplicateReportDialogComponent, {
      data: {
        title: report.name,
        uid: report.uid,
      },
    })
  }

  showTagSelectionDialogForItems(tagCollection?: string[]) {
    return this.openDialog(TagsSelectionDialogComponent, {
      minWidth: "60vw",
      data: {
        title: "",
        selectedTags: [],
        tagSet: tagCollection || [],
      },
    })
  }

  showCreatePdfSettingsDialog(report: GeneralReport) {
    return this.openDialog(GeneratePdfSettingsDialogComponent, {
      data: {
        report,
      },
      panelClass: "overlay__wrapper",
    })
  }

  showFailedUploadsDialog(failedUploads: string[], successfulUploads?: string[]) {
    return this.openDialog(FailedUploadsDialogComponent, {
      data: {
        failedUploads,
        successfulUploads,
      },
    })
  }

  showAnonymousReportDialog(template: LegacyTemplate, creator: Person): Promise<boolean> {
    return this.openDialog(AnonymousReportDialogComponent, {
      data: { template, creator },
    })
  }
}
