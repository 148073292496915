<ng-container *ngIf="areTagsEditable; else nonEditableTags">
  <mat-form-field class="tags-form-field" appearance="standard">
    <mat-chip-list #tagList aria-label="Tag Selection" id="intercom-tour-tag-list">
      <mat-chip disableRipple *ngFor="let tag of tags" (removed)="removeTag(tag)">
        {{tag}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        id="intercom-tour-add-more-item-tags"
        #tagInput
        placeholder="{{placeholder}}"
        [formControl]="tagsControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="tagList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addTagByTyping($event)"
      />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addTagByDropdownSelection($event)">
      <mat-option *ngFor="let tag of dropdownTagsList | async" [value]="tag">
        {{tag}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>

<ng-template #nonEditableTags>
  <div fxLayout="row wrap" class="non-editable-tags-wrapper">
    <checkd-tag *ngFor="let tag of tags">{{tag}}</checkd-tag>
  </div>
</ng-template>
