import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { RouterModule, Routes } from "@angular/router"
import { CheckdCommonModule, CheckdMaterialModule } from "@checkd-ui"
import { MomentModule } from "ngx-moment"
import { invitationUidGuard } from "../guards/invitation-uid-guard"
import { InvitationLoginDialogComponent } from "./dialogs/invitation-login-dialog/invitation-login-dialog.component"
import { PendingInvitationRemovalDialogComponent } from "./dialogs/pending-invitation-removal-dialog/pending-invitation-removal-dialog.component"
import { EmailAddressSubmissionComponent } from "./email-address-submission/email-address-submission.component"
import { InvitationViewComponent } from "./invitation-view/invitation-view.component"
import { PendingInvitationsListComponent } from "./pending-invitations-list/pending-invitations-list.component"
import { AcceptInvitationUidComponent } from "./views/accept-invitation-uid/accept-invitation-uid.component"
import { ExpiredInvitationComponent } from "./views/expired-invitation/expired-invitation.component"
import { InvalidInvitationUidComponent } from "./views/invalid-invitation-uid/invalid-invitation-uid.component"
import { MissingInvitationUidComponent } from "./views/missing-invitation-uid/missing-invitation-uid.component"

const routes: Routes = [
  {
    // TODO: change this to /invitations when the old invitation-view refactored to show loading state only
    path: "accept-invitation",
    pathMatch: "full",
    component: AcceptInvitationUidComponent,
  },
  {
    path: "missing-invitation",
    pathMatch: "full",
    component: MissingInvitationUidComponent,
  },
  {
    path: "invalid-invitation",
    pathMatch: "full",
    component: InvalidInvitationUidComponent,
  },
  {
    path: "expired",
    pathMatch: "full",
    component: ExpiredInvitationComponent,
  },
  // This route should only display some loading state before redirecting to either one-login for sign-in or sign-up
  // if the user isn't logged in. If user is logged in, it should redirect to accept-invitation
  // It also adds the invitationUid to session storage so that it can be retrieved when accepting the invitation.
  // See the invitationUidGuard for the logic.
  {
    path: ":invitationUid",
    component: InvitationViewComponent,
    canActivate: [invitationUidGuard],
  },
]

@NgModule({
  declarations: [
    InvitationViewComponent,
    AcceptInvitationUidComponent,
    MissingInvitationUidComponent,
    InvalidInvitationUidComponent,
    EmailAddressSubmissionComponent,
    InvitationLoginDialogComponent,
    PendingInvitationsListComponent,
    PendingInvitationRemovalDialogComponent,
    ExpiredInvitationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CheckdCommonModule,
    CheckdMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
  ],
  exports: [PendingInvitationsListComponent, PendingInvitationRemovalDialogComponent],
})
export class InvitationModule {}
