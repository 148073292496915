import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-pen-to-square-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" [attr.viewBox]="getViewBox()" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2511_17615)" [attr.transform]="getScale()">
        <path
          d="M17.6237 0.996041C18.951 -0.332014 21.1048 -0.332014 22.4321 0.996041L23.0036 1.56851C24.3308 2.8967 24.3308 5.05006 23.0036 6.37731L13.6892 15.6964C13.2783 16.1026 12.7729 16.4002 12.2155 16.5608L7.49222 17.9116C7.09546 18.025 6.67036 17.9116 6.37751 17.581C6.08939 17.3307 5.97603 16.9056 6.08939 16.5088L7.44026 11.7855C7.60085 11.2282 7.89842 10.7228 8.30463 10.3118L17.6237 0.996041ZM20.7883 2.59913C20.3869 2.15656 19.6689 2.15656 19.2249 2.59913L17.8504 3.97315L20.0279 6.15059L21.4024 4.7336C21.8464 4.33212 21.8464 3.61417 21.4024 3.1716L20.7883 2.59913ZM9.62243 12.409L8.82891 15.1721L11.5921 14.3786C11.781 14.3267 11.9463 14.2275 12.0833 14.0905L18.4267 7.7518L16.2492 5.57435L9.91055 11.9178C9.77358 12.0547 9.67439 12.2201 9.62243 12.409ZM9.44767 2.83955C10.0759 2.83955 10.5813 3.34731 10.5813 3.97315C10.5813 4.60135 10.0759 5.10674 9.44767 5.10674H4.15755C3.11417 5.10674 2.26823 5.95221 2.26823 6.99607V19.8435C2.26823 20.8873 3.11417 21.7328 4.15755 21.7328H17.005C18.0488 21.7328 18.8943 20.8873 18.8943 19.8435V14.5534C18.8943 13.9252 19.3997 13.4198 20.0279 13.4198C20.6561 13.4198 21.1615 13.9252 21.1615 14.5534V19.8435C21.1615 22.139 19.3005 24 17.005 24H4.15755C1.86202 24 0.0010376 22.139 0.0010376 19.8435V6.99607C0.0010376 4.70054 1.86202 2.83955 4.15755 2.83955H9.44767Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2511_17615">
          <rect [attr.width]="getSize()" [attr.height]="getSize()" fill="white" />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class PenToSquareIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }

  getViewBox(): string | undefined {
    return this.svgIconsService.getViewBox(this.size)
  }
  getScale(): string | undefined {
    return this.svgIconsService.getScale(this.size)
  }
}
