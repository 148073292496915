import { animate, style, transition, trigger } from "@angular/animations"
import { BreakpointObserver } from "@angular/cdk/layout"
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import {
  Company,
  CompanyFeatures,
  IReportMenuActionsResponse,
  LegacyTemplate,
  LegacyTemplateMenuActions,
  Person,
  Project,
  ReportMenuActions,
  Role,
} from "@models/common"
import { GeneralReport } from "@models/common/general-report"
import { TagCollection } from "@models/common/tag-collection"
import { ReportListComponent } from "@reports"
import { UserService } from "@services"
import { ClipboardService } from "ngx-clipboard"
import { BehaviorSubject, Observable, Subscription } from "rxjs"
import { map } from "rxjs/operators"
import { QrService } from "../../features/qr-views/services/qr.service"
import { ProjectReportsService } from "./project-reports.service"

type TabType = "all_reports" | "my_reports" | "open_reports" | "templates"

@Component({
  selector: "checkd-project-reports",
  templateUrl: "./project-reports.component.html",
  styleUrls: ["./project-reports.component.scss"],
  animations: [
    trigger("showNewFormButton", [
      transition(":enter", [style({ opacity: 0 }), animate("1s ease-in")]),
      transition(":leave", [style({ opacity: 1 }), animate("1s ease-out")]),
    ]),
  ],
})
export class ProjectReportsComponent implements OnInit, OnDestroy {
  _projectReports: GeneralReport[]
  _projectTemplates: LegacyTemplate[]
  _projectUserReports: GeneralReport[]
  _projectOpenReports: GeneralReport[]

  @Input() project: Project
  @Input() currentUser: Person
  @Input() currentCompany: Company
  @Input() currentCompanyLegacyTemplates: LegacyTemplate[]
  @Input() projectCompany: Company

  set projectReports(value: GeneralReport[]) {
    this._projectReports = value
    this.currentlyShownReports.next(this.currentlyShownReports.value)
  }

  get projectReports(): GeneralReport[] {
    return this._projectReports
  }

  @Input() userProjectRole: Role

  @Input() set projectTemplates(value: LegacyTemplate[]) {
    this._projectTemplates = value
    this.currentlyShownReports.next(this.currentlyShownReports.value)
  }

  get projectTemplates(): LegacyTemplate[] {
    return this._projectTemplates
  }

  set projectUserReports(value: GeneralReport[]) {
    this._projectUserReports = value
    this.currentlyShownReports.next(this.currentlyShownReports.value)
  }

  get projectUserReports(): GeneralReport[] {
    return this._projectUserReports
  }

  @Input() set projectOpenReports(value: GeneralReport[]) {
    this._projectOpenReports = value
    this.currentlyShownReports.next(this.currentlyShownReports.value)
  }

  get projectOpenReports(): GeneralReport[] {
    return this._projectOpenReports
  }

  @Input() projectReportsReady: boolean

  @Input() set reports(data: { allReports: GeneralReport[]; myReports: GeneralReport[] } | null) {
    if (data == null) {
      return
    }
    this.projectReports = data.allReports
    this.projectUserReports = data.myReports
  }

  @ViewChild("reportList", { static: true }) reportListComponent: ReportListComponent

  currentlyShownReports = new BehaviorSubject<TabType>("my_reports")

  selectedReports$ = this.currentlyShownReports.pipe(
    map((selectedTab) => {
      switch (selectedTab) {
        case "all_reports":
          return this.projectReports || []
        case "my_reports":
          return this.projectUserReports || []
        case "open_reports":
          return this.projectOpenReports || []
        case "templates":
          return this.projectTemplates || []
      }
    })
  )

  reportsCountText = this.selectedReports$.pipe(map((reports) => `${reports.length} ${reports.length < 2 ? "report" : "reports"}`))

  subscriptions: Subscription = new Subscription()

  legacyReportCreationInProgress: boolean = false

  readonly reportListColumns$ = this.currentlyShownReports.pipe(map(this.generateReportListColumns))

  generateReportListColumns(selectedTab: TabType): string[] {
    const columns = [
      "itemsCount",
      "type",
      "name",
      "tags",
      "reportCreatorName",
      "reportCreatorCompanyName",
      "createdAt",
      "updatedAt",
      "menu",
    ]

    if (selectedTab === "open_reports") {
      columns.splice(3, 0, "template")
    }

    return columns
  }

  reportTagCollection: TagCollection

  get hasQrOpenTemplatesFeature() {
    return this.currentCompany && this.currentCompany.features && this.currentCompany.features.includes(CompanyFeatures.QR_OPEN_TEMPLATES)
  }

  get hasProjectTemplates() {
    return this.projectTemplates && this.projectTemplates.length > 0
  }

  get hasCompanyTemplates() {
    return this.currentCompanyLegacyTemplates && this.currentCompanyLegacyTemplates.length > 0
  }

  constructor(
    public projectReportsService: ProjectReportsService,
    public userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    public breakpointObserver: BreakpointObserver,
    private qrService: QrService,
    private clipboardService: ClipboardService
  ) {}

  ngOnInit() {
    this.setupSubscriptions()
  }

  setupSubscriptions() {
    this.subscriptions.add(
      this.projectReportsService.reportTagCollection$.subscribe((collection) => {
        this.reportTagCollection = collection
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  async newLegacyReport() {
    const report = await this.projectReportsService.newLegacyReport(
      this.currentUser,
      this.currentCompany,
      this.projectCompany,
      this.project,
      this.projectTemplates,
      this.currentCompanyLegacyTemplates
    )

    if (report != null) {
      await this.router.navigate([`../legacyReports/${report.uid}`], { relativeTo: this.route })
    }
  }

  // Actual action performed when pressing the menu buttons
  async onMenuOptionSelected(event: IReportMenuActionsResponse) {
    switch (event.action) {
      case ReportMenuActions.REMOVE_REPORT: {
        await this.projectReportsService.removeReport(event.report as GeneralReport, this.currentUser)
        this.currentlyShownReports.next(this.currentlyShownReports.value)
        return
      }
      case ReportMenuActions.LOCK_REPORT: {
        return this.projectReportsService.lockReport(event.report as GeneralReport, this.currentUser)
      }
      case ReportMenuActions.UNLOCK_REPORT: {
        return this.projectReportsService.unlockReport(event.report as GeneralReport, this.currentUser)
      }
      case ReportMenuActions.ADD_TAGS: {
        return this.projectReportsService.addReportTags(
          event.report as GeneralReport,
          this.currentUser,
          this.reportTagCollection,
          this.project
        )
      }
      case ReportMenuActions.DUPLICATE_REPORT: {
        return this.projectReportsService.duplicateReport(event.report as GeneralReport, this.currentUser, this.project)
      }
      case LegacyTemplateMenuActions.GO_TO_QR_CODE_VIEW: {
        const url = this.qrService.getProjectTemplateQrUrl(this.project.uid, event.report.uid)

        return this.router.navigate([url])
      }
      case LegacyTemplateMenuActions.GET_URL_IN_CLIPBOARD: {
        const url = this.qrService.getProjectTemplateUrl(this.project.uid, event.report.uid)
        this.clipboardService.copy(url)
        return null
      }
      default: {
        return
      }
    }
  }

  resetFilters() {
    this.reportListComponent.resetFilters()
  }

  showNewFormButton(project: Project, currentCompany: Company, hasProjectTemplates: boolean, hasCompanyTemplates: boolean): boolean {
    if (!project || !currentCompany) {
      return false
    }

    return this.projectReportsService.hasPermissionsToCreateForms(project, currentCompany) && (hasProjectTemplates || hasCompanyTemplates)
  }
}
