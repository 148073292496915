<ng-container *ngIf="projects | orderBy: currentUser:_favoritesList:selectedSortOption | nameFilter: searchValue as filteredProjects">
  <ng-container *ngIf="finishedLoading; else skeletonLoader">
    <ng-container *ngIf="filteredProjects.length > 0; else emptyState">
      <cdk-virtual-scroll-viewport itemSize="55" class="project-list" fxLayout="column" fxLayoutAlign="begin center" #virtualScroll>
        <div class="project-list-element" *cdkVirtualFor="let project of filteredProjects; trackBy: trackByFn; templateCacheSize: 0">
          <app-project-list-item
            class="project-list-item"
            [project]="project"
            [currentUser]="currentUser"
            [isFavorite]="_favoritesList.includes(project.uid)"
            (favoriteToggled)="favoriteToggled.emit(project)"
            (projectSelected)="projectSelected.emit($event)"
          ></app-project-list-item>

          <project-contextmenu
            (menuOptionSelected)="menuOptionSelected($event)"
            *ngIf="projectRoles"
            class="project-element-contextmenu"
            [project]="project"
            [currentCompany]="currentCompany"
            [userProjectRole]="projectRole(project.uid)"
            [userCompanyRole]="userCompanyRole"
          >
          </project-contextmenu>
        </div>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #skeletonLoader>
  <div class="skeleton">
    <ngx-skeleton-loader
      count="1"
      animation="progress"
      [theme]="{
        'border-radius': '5px',
        height: '50px',
        'background-color': '#e9ecef',
        border: '1px solid white'
      }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="10"
      animation="progress"
      [theme]="{
        'border-radius': '5px',
        height: '40px',
        'background-color': '#e9ecef',
        border: '1px solid white'
      }"
    ></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #emptyState>
  <div class="empty-state__component-container">
    <checkd-empty-state
      [title]="emptyStateData.title"
      [text]="emptyStateData.description"
      [image]="emptyStateData.image"
    ></checkd-empty-state>
  </div>
</ng-template>
