import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { BehaviorSubject, combineLatest, Observable } from "rxjs"
import { combineLatestWith, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, tap } from "rxjs/operators"
import { ChatRoomType } from "../constants"
import { IFieldChatProjectData, UserService } from "./user.service"

const PROJECT_ADMIN_LABELS = new Set(["CREATED_BY", "OWNED_BY", "ADMINISTRATED_BY"])

@Injectable({
  providedIn: "root",
})
export class FieldChatViewService {
  private readonly _channelTypeVisible$ = new BehaviorSubject<"project" | "company">("company")
  readonly channelTypeVisible$ = this._channelTypeVisible$.asObservable()

  /** When in the field chat view and `channelTypeVisible` is 'project', this value decides whether to show
   * the list of field projects the current user is a part of, or whether the user is viewing the chat rooms
   * they are part of for a selected project
   */
  showProjects: boolean = false

  private readonly _projectSelected$ = new BehaviorSubject<null | IFieldChatProjectData>(null)

  readonly projectSelected$ = this._projectSelected$.asObservable()

  /**
   * Emits the current chat room type (i.e. the channel type in getStream)
   */
  readonly currentChatRoomType$: Observable<ChatRoomType> = this._channelTypeVisible$.pipe(
    map((channelType) => {
      switch (channelType) {
        case "project":
          return "messaging"
        case "company":
          return "team"
        default:
          throw new Error("Could not get the ChannelType")
      }
    })
  )

  readonly userIsCurrentProjectAdmin$ = this._projectSelected$.pipe(
    filter((data): data is IFieldChatProjectData => !!data),
    map((data) => data.uid),
    combineLatestWith(this.userService.currentUser$),
    switchMap(([projectUid, user]) => this.afs.doc<{ labels: string[] }>(`projects/${projectUid}/users/${user.uid}`).valueChanges()),
    map((rel) => (rel?.labels ?? []).some((label) => PROJECT_ADMIN_LABELS.has(label))),
    startWith(false),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  readonly currentUserCanEditRooms$ = combineLatest([
    this.channelTypeVisible$,
    this.userService.currentUserIsAdminForTheirCompany$,
    this.userIsCurrentProjectAdmin$,
  ]).pipe(
    map(([channelType, userIsAdminForTheirCompany, userIsProjectAdmin]) => {
      if (channelType === "company") {
        return userIsAdminForTheirCompany
      }

      return userIsProjectAdmin
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  /** Whether the chat room details panel is visible */
  isDetailsPanelVisible = true

  constructor(private afs: AngularFirestore, private userService: UserService) {}

  setSelectedProject(project: IFieldChatProjectData | null) {
    this._projectSelected$.next(project)
  }

  getSelectedProject() {
    return this._projectSelected$.value
  }

  setCurrentlyVisibleChannelType(value: "company" | "project") {
    this._channelTypeVisible$.next(value)
  }

  getCurrentlyVisibleChannelType() {
    return this._channelTypeVisible$.value
  }
}
