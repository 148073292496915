<div class="checkd-form-details" fxLayout="column" fxLayoutGap="20px">
  <div *ngFor="let value of values">
    <app-form-element
      (valueChanged)="onContentChange($event)"
      [config]="config"
      [element]="value"
      [readOnly]="readOnly">
    </app-form-element>
  </div>
</div>
