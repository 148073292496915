import { ProjectData, ProjectDetailsData, ProjectLocationData, ProjectUserData } from "@models/common/project.interface"
import { ProjectAggregateData } from "./aggregate-data"
import { BaseModel } from "./base-model"
import { COLLECTIONS, SUB_COLLECTIONS } from "./collections.interface"
import { Company } from "./company"
import { Drawing } from "./drawing"
import { Item } from "./item"
import { LegacyReport } from "./legacy-report"
import { Person } from "./person"
import { Relation } from "./relation"
import { Report } from "./report"
import { ITagCollectionData } from "./tag-collection"

export class Project extends BaseModel<ProjectData> {
  public static override COLLECTION: string = "projects"

  get lastTaskNumber(): number {
    return this.data.lastTaskNumber || 0
  }
  set lastTaskNumber(n: number) {
    this.data.lastTaskNumber = n
  }
  get nextTaskNumber() {
    return this.lastTaskNumber + 1
  }

  override get tags() {
    return this.data.tags || []
  }
  get name() {
    return this.data.name
  }
  get details() {
    return (this.data.details || {}) as ProjectDetailsData
  }
  get location() {
    return (this.data.location || {}) as ProjectLocationData
  }
  get description() {
    return this.data.description || ""
  }

  override get aggregateData(): ProjectAggregateData {
    return this.data.aggregateData || {}
  }

  get nextProjectProjectId() {
    return this.data["nextProjectId"] || null
  }

  get hasPublicTemplates(): boolean {
    return this.data.hasPublicTemplates || false
  }

  get nextProjectId(): number {
    return this.data["nextProjectId"]
  }

  getDrawings(): Promise<Drawing[]> {
    return Relation.getAllTargets(this, COLLECTIONS.DRAWINGS).then((targets) => targets as Drawing[])
  }

  getProjectUserData(userUid: string): ProjectUserData {
    if (this.data.peopleData != null && this.data.peopleData[userUid] != null) {
      return this.data.peopleData[userUid] as ProjectUserData
    }

    // @ts-ignore
    return null
  }

  setProjectUserData(userUid: string, data: ProjectUserData): Promise<this> {
    let peopleData = this.data.peopleData || {}
    peopleData[userUid] = data
    return this.update({ peopleData })
  }

  addItem(item: Item): Promise<Project> {
    // @ts-ignore
    return Promise.all([this.add(item, [Relation.LABELS.CONTAINS]), item.add(this, [Relation.LABELS.CONTAINED_BY])]).then((_) => this)
  }

  addTagCollection(data: ITagCollectionData) {
    return this.ref!.collection(SUB_COLLECTIONS.TAG_COLLECTIONS).add(data)
  }

  async getReports(): Promise<Report[]> {
    const targets = await Relation.getAllTargets(this, COLLECTIONS.REPORTS)
    return targets as Report[]
  }

  async getLegacyReports(): Promise<LegacyReport[]> {
    const targets = await Relation.getAllTargets(this, COLLECTIONS.LEGACY_REPORTS)
    return targets as LegacyReport[]
  }

  async getItems(): Promise<Item[]> {
    const itemArrays = await Promise.all([this.getProjectItems(), this.getDrawingItems()])
    let joined = itemArrays.reduce((acc, arr) => [...acc, ...arr], [])
    let m = {}
    joined.forEach((item) => {
      // @ts-ignore
      m[item.uid] = item
    })
    return (
      Object.keys(m)
        // @ts-ignore
        .map((k) => m[k])
        .sort((a: Item, b: Item): number => {
          return (b.updatedAt || 0) - (a.updatedAt || 0)
        })
    )
  }

  async getProjectItems(): Promise<Item[]> {
    const targets = await Relation.getAllTargets(this, COLLECTIONS.ITEMS)
    return targets as Item[]
  }

  async getDrawingItems(): Promise<Item[]> {
    const drawings = await this.getDrawings()
    const drawingItems = await Promise.all(drawings.map((drawing) => drawing.getItems()))
    return drawingItems.reduce((acc, arr) => [...acc, ...arr], [])
  }

  async getCompany(): Promise<Company> {
    const companies = await Relation.getAllTargets(this, COLLECTIONS.COMPANIES)

    // @ts-ignore
    return companies.length > 0 ? (companies[0] as Company) : null
  }

  async getPeople(): Promise<Person[]> {
    const targets = await Relation.getAllTargets(this, COLLECTIONS.PEOPLE)
    return targets as Person[]
  }

  public static get(uid: string): Promise<Project> {
    return this.getModel(Project, uid)
  }

  // TODO deprecate
  public static create(data: ProjectData): Promise<Project> {
    return this.db
      .collection(Project.COLLECTION)
      .add(data)
      .then((docRef) => docRef.get())
      .then((docSnapshot) => new Project(docSnapshot.data() as ProjectData, docSnapshot.id, docSnapshot.ref))
  }
}
