import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core"
import { Item, Person, Timeline } from "@models/common"

@Component({
  selector: "item-timeline",
  templateUrl: "./item-timeline.component.html",
  styleUrls: ["./item-timeline.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemTimelineComponent implements OnChanges, OnInit {
  @Input() item: Item
  @Input() currentUser: Person
  @Input() timeline: Timeline

  @ViewChild("timelineDiv", { static: true }) timelineRef: ElementRef

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["timeline"] != null) {
      this.cdr.markForCheck()
      setTimeout(() => {
        this.scrollToBottom()
      }, 200)
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.scrollToBottom()
    }, 1000)
  }

  scrollToBottom(): void {
    try {
      const lastElement = this.timelineRef.nativeElement.lastElementChild
      if (lastElement) {
        lastElement.scrollIntoView({ block: "end", inline: "nearest", behavior: "smooth" })
      }
    } catch (err) {}
  }
}
