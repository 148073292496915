import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { AngularFireFunctions } from "@angular/fire/compat/functions/"
import { VisibilitySelection } from "@checkd-form/components/report-visibility-toggle/report-visibility-toggle.component"
import { Form } from "@checkd-form/models/form"
import { FormElementOption } from "@checkd-form/models/form-element-option"
import { CheckdFormDialogService } from "@checkd-form/services/checkd-form-dialog.service"
import { FieldReportService, IPdfDocDefinitionConfig } from "@checkd-form/services/field-report.service"
import { FormMessageService } from "@checkd-form/services/form-message.service"
import { LegacyReportService } from "@checkd-form/services/legacy-report.service"
import { ItemMenuActions, Relation, TimelineElement } from "@models/common"
import { COLLECTIONS } from "@models/common/collections.interface"
import { FieldReport } from "@models/common/field-report"
import { GeneralReport } from "@models/common/general-report"
import { Item } from "@models/common/item"
import { LegacyReport } from "@models/common/legacy-report"
import { Person } from "@models/common/person"
import { Project } from "@models/common/project"
import { LABELS } from "@models/common/relation.interface"
import { ReportGenerationSettings } from "@models/common/report"
import { PermissionsHandler, Role } from "@models/common/roles-and-permissions"
import {
  ItemService,
  ModelService,
  RelationService,
  ReportService,
  ReportTimelineService,
  SnackbarService,
  TimelineService,
} from "@services"
import { PermissionsService } from "@services/permissions.service"
import { BehaviorSubject, EMPTY, from as observableFrom, Observable } from "rxjs"
import { map, switchMap, tap } from "rxjs/operators"
import { environment } from "../../../../environments/environment"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { DEFAULT_PDF_GENERATION_SETTINGS, IPdfGenerationSettings } from "@models/common/pdf/settings.interface"

enum SupportedReportTypes {
  reports = "reports",
  fieldReports = "fieldReports",
  legacyReports = "legacyReports",
}

@Injectable({
  providedIn: "root",
})
export class GeneralReportViewService {
  isFormChanged$ = new BehaviorSubject(false)
  get formMessageListener$() {
    return this.formMessageService.getMessage()
  }

  constructor(
    private db: AngularFirestore,
    private legacyReportService: LegacyReportService,
    private fieldReportService: FieldReportService,
    public reportService: ReportService,
    private itemService: ItemService,
    private reportTimelineService: ReportTimelineService,
    public checkdFormDialogService: CheckdFormDialogService,
    private modelService: ModelService,
    private permissionsService: PermissionsService,
    public formMessageService: FormMessageService,
    private relationService: RelationService,
    private snackbarService: SnackbarService,
    private http: HttpClient,
    private firebaseFunctions: AngularFireFunctions,
    private timelineService: TimelineService
  ) {}

  loadImages(form: Form) {
    const worker = new Worker(new URL("./base64.worker", import.meta.url), { type: "module" })
    worker.postMessage({ form })
  }

  listenToReport(collectionName: string, uid: string) {
    if (collectionName == null || uid == null) {
      return EMPTY
    }

    switch (collectionName) {
      case SupportedReportTypes.fieldReports: {
        return this.fieldReportService.listenToUid(uid)
      }
      case SupportedReportTypes.legacyReports: {
        // FIXME This is a workaround for the missing defaultPermissions property in the LegacyReport model.
        //   Remove this when it's fixed
        return this.legacyReportService.listenToUid(uid).pipe(
          map((legacyReport) => {
            legacyReport.data.permissions = PermissionsHandler.mergePermissions(
              this.permissionsService.legacyReportDefaultPermissions,
              legacyReport.permissions
            )
            return legacyReport
          })
        )
      }
      default: {
        throw new Error(`Unsupported collection name: ${collectionName}`)
      }
    }
  }

  listenToReportCreator(report: GeneralReport) {
    return this.reportService.listenToCreator(report)
  }

  listenToProject(report: GeneralReport) {
    return this.reportService.listenToProject(report)
  }

  listenToLastTimelineMessage(report: GeneralReport) {
    return this.reportTimelineService.listenToLastTimelineElement(report).pipe(
      switchMap((timelineElement) => {
        return observableFrom(timelineElement.getCreator()).pipe(
          map((creator) => this.reportTimelineService.createMessageFromTimelineElement(timelineElement, creator))
        )
      })
    )
  }

  listenToReportItems(report: GeneralReport): Observable<Item[]> {
    return this.modelService.queryAndListen({
      collection: COLLECTIONS.ITEMS,
      aggregateData: { legacyReportUid: report.uid },
    })
  }

  updateConfig(key: string, val: any) {
    this.formMessageService.updateConfig(key, val)
  }

  async updateReport(report: GeneralReport, form: Form) {
    await this.reportService.update(report, form)
    this.isFormChanged$.next(false)
  }

  handleFormMessage(form: Form, message: { name: string; data: any }) {
    switch (message.name) {
      case FormMessageService.MSG_ELEMENT_CHILD_ADDED: {
        return this.updateFilledElementsCount(form)
      }
      case FormMessageService.MSG_ELEMENT_CHILD_REMOVED: {
        return this.updateFilledElementsCount(form)
      }
      case FormMessageService.MSG_ELEMENT_VALUE_CHANGED: {
        return this.updateFilledElementsCount(form)
      }
      case FormMessageService.IMAGE_ADDED: {
        return this.updateFilledElementsCount(form)
      }
      case FormMessageService.SIGNATURE_ADDED: {
        return this.updateFilledElementsCount(form)
      }
      default: {
        return
      }
    }
  }

  updateFilledElementsCount(form: Form) {
    form.updateFilledElementCount()
    this.isFormChanged$.next(true)
  }

  createReportSharingLink(report: GeneralReport) {
    return this.reportService.createReportSharingLink(report.uid, report.reportType)
  }

  async toggleReadOnly(report: GeneralReport, toggler: Person, readOnly: boolean) {
    report.readOnly = readOnly
    await report.ref!.update({ readOnly })

    return this.reportTimelineService.readOnlyChanged(toggler, report, readOnly)
  }

  // TODO: Make this function better with use of
  // recursion and should consider both the header and
  // the template
  calculateTotalItemsAndDrawings(report: GeneralReport) {
    let totalDrawings = 0
    let totalItems = 0

    if (report == null || !["FIELD", "LEGACY"].includes(report.reportType)) {
      return { totalDrawings, totalItems }
    }

    const calculatableReport = report as FieldReport | LegacyReport

    calculatableReport.details.map((element: any) => {
      if (element.type === "drawing") {
        totalDrawings += 1
      }
      if (element.type === "itemlist") {
        totalItems += element.value.length
      }
      element.values.map((elm: any) => {
        if (elm.type === "itemlist") {
          totalItems += elm.value.length
        }
      })
    })

    return { totalDrawings, totalItems }
  }

  getPdfDocDefinition(report: GeneralReport, form: Form, pdfDocDefinitionConfig: IPdfDocDefinitionConfig) {
    const contentTotal = this.calculateTotalItemsAndDrawings(report)
    const pdfFieldsSettings = this.formMessageService.config$.getValue().pdfFieldsSettings as ReportGenerationSettings

    form.header.values!.forEach((element) => {
      if (element.title === "Description") {
        if (element.options!.filter((option) => option.code === "bold").length <= 0) {
          const option = new FormElementOption()
          option.id = element.options!.length + 1
          option.name = "bold"
          option.code = "bold"
          option.type = "checkbox"
          option.value = "true"

          element.options!.push(option)
        }
      }
    })

    const config = this.formMessageService.config$ ? this.formMessageService.config$.getValue() : {}

    return form.toPdfDocDefinition(
      pdfDocDefinitionConfig.projectTitle,
      pdfDocDefinitionConfig.creator,
      pdfDocDefinitionConfig.reportLink,
      pdfDocDefinitionConfig.companyLogoBase64,
      pdfDocDefinitionConfig.localeCreatedDate,
      pdfDocDefinitionConfig.creatorName,
      contentTotal,
      pdfFieldsSettings,
      pdfDocDefinitionConfig.pdfFooter,
      config
    )
  }

  async createItem(creator: Person, project: Project, report: GeneralReport, projectMembers: Person[], prefill: { [p: string]: any }) {
    // @ts-ignore
    const { itemData, taskData } = await this.checkdFormDialogService.createItem(creator, project, report, projectMembers, prefill)

    return this.itemService.createReportItem(creator, project, report, itemData, taskData)
  }

  openItem(item: Item, project: Project, projectMembers: Person[], userProjectRole: Role) {
    this.checkdFormDialogService.openItem(item, project, projectMembers, userProjectRole, [ItemMenuActions.REMOVE_ITEM])
  }

  async removeItemFromLegacyReport(item: Item, report: LegacyReport, remover: Person, timeline: TimelineElement) {
    const batch = this.db.firestore.batch()
    // @ts-ignore
    const relations = await Relation.getBoth(item, report)
    const itemAggregateData = item.aggregateData || {}

    for (const r of relations) {
      r.batchDisable(batch)
    }
    item.batchUpdateAggregateData(batch, {
      ...itemAggregateData,
      legacyReportName: null,
      legacyReportUid: null,
    })

    timeline.disable(batch, remover)
    this.timelineService.batchItemRemovedFromReport(batch, remover, item, report)

    await batch.commit()
    this.snackbarService.showMessage(`Item ${item.number || ""} is removed from this report!`)
  }

  updateReportVisibility(report: GeneralReport, visibility: VisibilitySelection, user: Person) {
    this.permissionsService.updateDocumentPermissions(report, visibility, user)
  }

  fetchReportPdf(report: GeneralReport, pdfGenerationSettings: Partial<IPdfGenerationSettings> = {}) {
    const _pdfGenerationSettings: IPdfGenerationSettings = {
      ...DEFAULT_PDF_GENERATION_SETTINGS,
      ...pdfGenerationSettings,
    }

    return this.http
      .post(environment.PDF_GENERATOR_CF, {
        reportDocumentPath: `${report.collectionName}/${report.uid}`,
        pdfGenerationSettings: _pdfGenerationSettings,
      })
      .toPromise()
  }
}
