import { BreakpointObserver } from "@angular/cdk/layout"
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { MatDialog } from "@angular/material/dialog"
import { PeopleListDialogComponent } from "@dialogs"
import { ItemImageGalleryDialogComponent } from "@items"
import {
  Company,
  CompanyFeatures,
  Drawing,
  FilestackUploadResult,
  Image,
  Item,
  ItemMenuActions,
  Person,
  PersonWithRoles,
  Project,
  Role,
  Task,
  Timeline,
  WorkflowStates,
} from "@models/common"
import {
  FilestackService,
  ImageService,
  ItemService,
  ProjectService,
  SnackbarService,
  TaskService,
  TimelineService,
  UserService,
} from "@services"
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from "rxjs"
import { map, take } from "rxjs/operators"
import { DialogService } from "../../dialogs/dialog.service"
import { ProjectInvitationDialogComponent } from "../../project/project-invitation-dialog/project-invitation-dialog.component"
import { allAvailableActions } from "../item-actions/item-actions.component"
import { ItemDialogService } from "../item-dialog.service"

export type drawingActionButtonsEventTypes = "cancel" | "save_position"

@Component({
  selector: "item-details",
  templateUrl: "./item-details.component.html",
  styleUrls: ["./item-details.component.scss"],
})
export class ItemDetailsComponent implements OnInit, OnDestroy {
  @Input() item: Item
  @Input() availableItemActions: string[] = allAvailableActions
  @Input() restrictedItemMenuActions: ItemMenuActions[] = []
  @Input() task: Task
  @Input() withCarousel: boolean
  @Input() itemImages: Image[] = []
  @Input() currentUser: Person
  @Input() userProjectRole: Role
  @Input() userItemRole: Role
  @Input() project: Project
  @Input() itemDrawing: Drawing
  @Input() projectMembers: Person[]
  @Input() showDrawingSection = false
  @Input() timeline: Timeline
  // TODO: Get rid of currentCompanyFeatures
  @Input() currentCompanyFeatures: string[]
  @Input() currentCompany: Company
  @Input() currentCompanyMembers: Person[]
  @Input() currentCompanyAssociates: Person[]

  @Output() itemWasModified = new EventEmitter()
  @Output() taskWasModified = new EventEmitter()
  @Output() drawingSectionClick = new EventEmitter()

  public CompanyFeatures = CompanyFeatures

  areTagsEditable: boolean = false
  setFocusOnTagInput = new EventEmitter<boolean>()
  isLoading: boolean = false
  currentUserCompanyRole: Role
  subscriptions: Subscription[]

  // The following properties concerns the buttons on the right bottom side (the drawings section)
  drawingActionButtonClicked = new Subject<drawingActionButtonsEventTypes>()
  cancelButtonVisible = false
  savePositionButtonVisible = false

  // Variables for handling the responsiveness of the three main components
  currentVisibleComponent$ = new BehaviorSubject<"left" | "center" | "right">("center")
  isBelowMaxWidth$: Observable<boolean> = this.breakpointObserver.observe("(max-width: 959px)").pipe(map((state) => state.matches))

  showLeft$: Observable<boolean> = combineLatest([this.isBelowMaxWidth$, this.currentVisibleComponent$]).pipe(
    map(([isBelowMaxWidth, currentVisibleComponent]) => !isBelowMaxWidth || currentVisibleComponent === "left")
  )

  showCenter$: Observable<boolean> = combineLatest([this.isBelowMaxWidth$, this.currentVisibleComponent$]).pipe(
    map(([isBelowMaxWidth, currentVisibleComponent]) => !isBelowMaxWidth || currentVisibleComponent === "center")
  )

  showRight$: Observable<boolean> = combineLatest([this.isBelowMaxWidth$, this.currentVisibleComponent$]).pipe(
    map(([isBelowMaxWidth, currentVisibleComponent]) => !isBelowMaxWidth || currentVisibleComponent === "right")
  )

  constructor(
    public dialog: MatDialog,
    private itemService: ItemService,
    private taskService: TaskService,
    private userService: UserService,
    private timelineService: TimelineService,
    private itemDialogService: ItemDialogService,
    private snackbarService: SnackbarService,
    private imageService: ImageService,
    private filestackService: FilestackService,
    private projectService: ProjectService,
    public breakpointObserver: BreakpointObserver,
    private db: AngularFirestore,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.setupSubscriptions()
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.userService.currentUserCompanyRole$.subscribe((userCompanyRole) => (this.currentUserCompanyRole = userCompanyRole)),
    ]
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  showComponent(componentName: "left" | "center" | "right") {
    this.currentVisibleComponent$.next(componentName)
  }

  get hasTask() {
    return this.task != null
  }

  get hasDrawingFeature() {
    return (
      this.project &&
      this.project.aggregateData &&
      this.project.aggregateData.companyFeatures &&
      this.project.aggregateData.companyFeatures.includes(CompanyFeatures.DRAWINGS)
    )
  }

  async assign() {
    const assigner = this.currentUser
    const assignee = await this.dialog
      .open(PeopleListDialogComponent, {
        width: "100%",
        maxWidth: "350px",
        hasBackdrop: true,

        data: {
          title: "Assign to:",
          people: this.projectMembers || [],
        },
      })
      .afterClosed()
      .pipe(take(1))
      .toPromise()
    if (assignee) {
      if (this.hasTask) {
        const task = await this.taskService.assign(this.task, assigner, assignee, this.itemImages)

        this.item.aggregateData.taskAssignerName = assigner.name
        this.item.aggregateData.taskAssigneeName = assignee.name

        await this.timelineService.taskAssigneeAdded(assigner, this.item, task, assignee)
        this.taskWasModified.emit(task)
      } else {
        const task = await this.itemService.assign(this.item, assigner, assignee, this.itemImages)

        this.item.aggregateData.taskAssignerName = assigner.name
        this.item.aggregateData.taskAssignerCompanyName = assigner.aggregateData["companyName"] || null
        this.item.aggregateData.taskAssignerName = assigner.uid

        this.item.aggregateData.taskAssigneeName = assignee.name
        this.item.aggregateData.taskAssigneeCompanyName = assignee.aggregateData.companyName || null
        this.item.aggregateData.taskAssigneeUid = assignee.uid

        await this.timelineService.taskAssigneeAdded(assigner, this.item, task, assignee)
        this.taskWasModified.emit(task)
      }
    }
  }

  editTags() {
    this.areTagsEditable = !this.areTagsEditable
    this.setFocusOnTagInput.emit(this.areTagsEditable)
  }

  async updateTags(tags: string[]) {
    try {
      if (tags != null) {
        const batch = this.db.firestore.batch()
        this.item.batchUpdate(batch, { tags })
        this.timelineService.itemEdited(batch, this.currentUser, this.item)
        await batch.commit()
        this.snackbarService.showMessage(`Tags updated!`)
      }
    } catch (err) {
      this.snackbarService.showMessage(err.message)
    }
  }

  async uploadImage() {
    const result: FilestackUploadResult = await this.filestackService.pick({
      accept: ["image/jpeg", "image/png"],
      storeTo: { location: "gcs", path: "images/" },
    })
    this.isLoading = true
    await this.itemService.handleFilesUpload(this.item, result, this.currentUser)
    this.isLoading = false
  }

  sendComment(comment: string) {
    const creator = this.currentUser
    this.timelineService.addItemComment(creator, this.item, comment)
  }

  onDrawingSectionClicked(item: Item) {
    this.drawingSectionClick.emit(item)
  }

  async drawingSelected(drawing: Drawing, coordinates: { positionX: number; positionY: number }) {
    this.snackbarService.showMessage("Adding item to drawing...")
    await this.itemService.addItemToDrawingWithPosition(this.item, drawing, coordinates.positionX, coordinates.positionY)

    this.snackbarService.showMessage(`Item #${this.item.number} added to ${drawing.name}`)
  }

  onMenuOptionSelected(event: { action: ItemMenuActions; item: Item }) {
    const { action, item } = event
    this.executeItemAction(item, { action })
  }

  async executeItemAction(item: Item, optionSelected: { action: ItemMenuActions }) {
    switch (optionSelected.action) {
      case ItemMenuActions.DUPLICATE_ITEM:
        return this.duplicateItemOnDrawing(item)
      case ItemMenuActions.REMOVE_ITEM:
        return this.itemService.disable(item, this.itemImages)
      case ItemMenuActions.SET_AS_CLOSED:
        return this.itemService.close(this.currentUser, item, this.itemImages)
      case ItemMenuActions.EDIT_DETAILS:
        return this.itemService.editItemDetails(item, this.currentUser, this.project, this.itemImages)
      case ItemMenuActions.UPLOAD_IMAGE:
        return this.uploadImage()
      case ItemMenuActions.VIEW_IMAGES:
        return this.openImageGallery(item)
      case ItemMenuActions.ADD_COLLABORATOR:
        return this.addCollaborators(item)
      default:
        break
    }
  }

  async addCollaborators(item: Item) {
    const assignee = await item.getAssignee()
    if (!assignee) {
      this.dialogService.confirm("Not possible", "There are no assignee assigned", true)
      // throw new Error('No assignee assigned')

      return
    }
    if (this.currentUser.uid !== assignee.uid) {
      this.dialogService.confirm("Not possible", "You are not assignee. Only assignees can add collaborators", true)
      // throw new Error('User is not assignee')

      return
    }
    if (item.status === "REJECTED") {
      this.dialogService.confirm("Not possible", "Assignee has rejected being assignee", true)

      return
    }
    if (item.status === "DELEGATED") {
      this.dialogService.confirm("Not possible", "Assignee has not accepted being assignee yet", true)

      return
    }

    const role: Role = this.currentUserCompanyRole
    // TODO: members doesn't show in itemDetails add collaborator view
    const companyMembers: Person[] = this.currentCompanyMembers
    const companyAssociates: Person[] = this.currentCompanyAssociates
    const project: Project = this.project
    const companyName: string = this.currentCompany.name

    // Open invite popup
    const inviteDialogResult = await this.dialogService.openDialog(ProjectInvitationDialogComponent, {
      data: {
        role,
        companyMembers,
        companyAssociates,
        projectName: project.data.name,
        companyName,
      },
      disableClose: true,
    })

    // If user doesn't add anyone
    if (inviteDialogResult === "" || inviteDialogResult === undefined) {
      return
    }

    // Need to merge associates and members to send to addCollaborators
    const collaboratorsAssociates: Person[] = inviteDialogResult.associates.map((associate: PersonWithRoles) => associate.person)
    const collaboratorsMembers: Person[] = inviteDialogResult.members.map((member: PersonWithRoles) => member.person)
    let collaborators: Person[]
    if (
      collaboratorsAssociates !== undefined &&
      collaboratorsMembers !== undefined &&
      collaboratorsMembers.length !== 0 &&
      collaboratorsAssociates.length !== 0
    ) {
      // Merge arrays
      collaborators = [...collaboratorsAssociates, ...collaboratorsMembers]
    } else if (collaboratorsAssociates !== undefined && collaboratorsAssociates.length !== 0) {
      // return associates
      collaborators = collaboratorsAssociates
    } else if (collaboratorsMembers !== undefined && collaboratorsMembers.length !== 0) {
      // return members
      collaborators = collaboratorsMembers
    } else {
      throw new Error("could not add collaborator")
    }

    await this.itemService.addCollaborators(collaborators, item, this.project, this.currentUser)
  }

  async duplicateItemOnDrawing(originalItem: Item) {
    const messageStart = `Please wait, duplicating item ${originalItem.number}`
    const messageEnd = `Item ${originalItem.number} successfully duplicated!`
    this.snackbarService.showMessage(messageStart)
    if (this.itemDrawing) {
      await this.itemService.duplicateDrawingItem(originalItem, this.project, this.itemDrawing, this.currentUser)
    } else {
      await this.itemService.duplicateProjectItem(originalItem, this.project)
    }
    this.snackbarService.showMessage(messageEnd)
  }

  openImageGallery(item: Item) {
    this.dialog.open(ItemImageGalleryDialogComponent, {
      data: { item },
    })
  }

  async removeImage(image: Image) {
    try {
      await this.itemService.removeItemImage(this.currentUser, this.item, this.timeline, image)
      this.snackbarService.showMessage(`Image "${image.name} removed!"`)
    } catch (err) {
      this.snackbarService.showMessage(err.message)
    }
  }

  async removeCollaborator(event: { collaboratorUid: string; item: Item }) {
    const result = await this.dialogService.confirm("Are you sure?", "This will remove the collaborator from the item")

    if (!result) {
      return
    }

    const assignee = await event.item.getAssignee()
    if (!assignee) {
      throw new Error("No assignee assigned")

      return
    }
    if (this.currentUser.uid !== assignee.uid) {
      throw new Error("User is not assignee")

      return
    }
    await this.itemService.removeCollaborator(this.item, event.collaboratorUid)
  }

  async handleTaskAction(event: { type: string; item: Item; task: Task }) {
    if (
      [
        WorkflowStates.ACCEPTED,
        WorkflowStates.REJECTED,
        WorkflowStates.FIXED,
        WorkflowStates.FIXED_REJECTED,
        WorkflowStates.FIXED_ACCEPTED,
        WorkflowStates.CLOSED,
        // @ts-ignore
      ].includes(event.type)
    ) {
      await this.taskService.updateTaskStatus(this.currentUser, this.task, this.item, event.type, this.itemImages)
    }
  }
}
