import { CheckdColors } from "@checkd-colors"
import { Project } from "../project"
import { ProjectMenuActions } from "./menu-actions.interface"

function createProjectMenuActionData(project: Project, projectMenuAction: ProjectMenuActions) {
  switch (projectMenuAction) {
    case ProjectMenuActions.REMOVE_PROJECT:
      return {
        name: "Delete project",
        color: CheckdColors.CHECKD_RED,
        action: ProjectMenuActions.REMOVE_PROJECT,
        confirmationDialog: {
          title: "Are you sure?",
          content: "This project and all its drawings, reports and items will be removed for all users.",
        },
      }

    case ProjectMenuActions.ARCHIVE_PROJECT:
      return {
        name: "Archive project",
        color: CheckdColors.CHECKD_DARK,
        action: ProjectMenuActions.ARCHIVE_PROJECT,
        confirmationDialog: {
          title: "Are you sure?",
          content: "This project and all its drawings, reports and items will be archived for all users.",
        },
      }

    case ProjectMenuActions.JOIN_PROJECT:
      return {
        name: "Join project",
        color: CheckdColors.CHECKD_DARK,
        action: ProjectMenuActions.JOIN_PROJECT,
        confirmationDialog: {
          title: "Join project as admin",
          content: "You will be added to this project as an admin user.",
        },
      }
    case ProjectMenuActions.UNARCHIVE_PROJECT:
      return {
        name: "Unarchive project",
        color: CheckdColors.CHECKD_DARK,
        action: ProjectMenuActions.UNARCHIVE_PROJECT,
        confirmationDialog: {
          title: "Are you sure?",
          content: "This project and all its drawings, reports and items will be unarchived for all users.",
        },
      }
    case ProjectMenuActions.DUPLICATE_PROJECT:
      return {
        name: "Duplicate project",
        color: CheckdColors.CHECKD_DARK,
        action: ProjectMenuActions.DUPLICATE_PROJECT,
      }

    default:
      return null
  }
}

export { createProjectMenuActionData }
