<div fxLayout="row" fxLayoutGap="30px" fxFlex fxLayoutAlign="center">
  <div *ngIf="templatePublicVersion" class="remove-gap">
    <span><strong>Template Version: </strong>{{ templatePublicVersion }}</span>
  </div>
  <div *ngIf="creatorName" class="remove-gap">
    <span><strong>Created by: </strong>{{ creatorName }}</span>
  </div>
  <div *ngIf="projectTitle" fxHide.xs class="remove-gap">
    <span><strong>Project: </strong>{{ projectTitle }}</span>
  </div>
  <div *ngIf="createdAt" fxHide.xs>
    <span><strong>Created at: </strong>{{createdAt | amFromUnix | amDateFormat: 'DD-MM-YYYY HH:mm'}}</span>
  </div>
  <div *ngIf="lastTimelineElementMessage">
    <span><strong>{{lastTimelineElementMessage}}</strong></span>
  </div>
  <div *ngIf="reportStatus">
    <span><strong>Status: </strong>{{reportStatus}}</span>
  </div>
</div>
