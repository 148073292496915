import { PersonData } from "./person.interface"
import firebase from "firebase/compat/app"
import { BaseModel } from "./base-model"
import { ModelInCollection } from "./collections"
import { LABELS } from "./relation.interface"
import { Task } from "./task"
import firestore = firebase.firestore

export class Person extends BaseModel<PersonData> {
  public static override COLLECTION: string = "users"

  get name() {
    if (this.data.name != null && this.data.name.trim() != "") return this.data.name
    if (this.data.fullName != null && this.data.fullName.trim() != "") return this.data.fullName
    return ""
  }

  get email() {
    return this.data.email
  }
  get image() {
    return this.data.image
  }
  get jobTitle() {
    return this.data.jobTitle
  }
  get notifications() {
    return this.data.notifications || {}
  }
  get phone() {
    return this.data.phone != null ? this.data.phone : this.data.legacyDataFloorplan != null ? this.data.legacyDataFloorplan.phone : ""
  }

  get companyName() {
    const aggregateData = this.data && this.data.aggregateData

    if (!aggregateData) {
      return null
    }

    return aggregateData["companyName"] ? aggregateData["companyName"] : null
  }

  public static override doc(uid: string): firestore.DocumentReference {
    return Person.db.collection(Person.COLLECTION).doc(uid)
  }

  public static get(uid: string): Promise<Person> {
    return Person.doc(uid)
      .get()
      .then((snapshot) => {
        if (!snapshot.exists) throw `Person ${uid} not found`
        return new Person(snapshot.data() as PersonData, snapshot.id, snapshot.ref)
      })
  }

  public static getByPath(path: string): Promise<Person> {
    return Person.get(path.split("/")[1])
  }

  public static create(data: PersonData): Promise<Person> {
    return Person.db
      .collection(Person.COLLECTION)
      .add(data)
      .then((docRef) => docRef.get())
      .then((docSnapshot) => new Person(docSnapshot.data() as PersonData, docSnapshot.id, docSnapshot.ref))
  }

  public setAsAssignerForTask(task: Task): Promise<Person> {
    return this.add(task, [LABELS.HAS_ASSIGNED])
      .then((_) => task.add(this, [LABELS.ASSIGNED_BY]))
      .then((_) => this)
  }

  public setAsCreatorOf<M extends ModelInCollection>(it: M): Promise<Person> {
    return this.add(it, [LABELS.CREATOR])
      .then((_) => it.add(this, [LABELS.CREATED_BY]))
      .then((_) => this)
  }
}
