import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { ICheckdFormConfig, Form } from "@checkd-form/models/form"
import { FormElementOption } from "@checkd-form/models/form-element-option"
import { FormMessageService } from "@checkd-form/services/form-message.service"
import { COLLECTIONS, Company, FieldReport, LABELS, Person, Project, ReportGenerationSettings, Timeline } from "@models/common"
import { ModelService, RelationService, ReportService, ReportTimelineService } from "@services"
import { from as observableFrom, Observable } from "rxjs"
import { map, switchMap } from "rxjs/operators"
import { DialogService } from "../../dialogs/dialog.service"

@Injectable()
export class FieldReportService {
  public formMessageListener$ = this.formMessageService.getMessage()
  public formConfig$ = this.formMessageService.config$
  isFieldReport = false

  constructor(
    private db: AngularFirestore,
    private modelService: ModelService,
    private relationService: RelationService,
    public reportService: ReportService,
    public reportTimelineService: ReportTimelineService,
    private formMessageService: FormMessageService,
    private dialogService: DialogService
  ) {}

  testWorker() {
    const worker = new Worker(new URL("../workers/base64.worker", import.meta.url), { type: "module" })
    worker.onmessage = ({ data }) => {
      console.log(`page got message: ${data}`)
    }
    worker.postMessage("hello")
  }

  updateConfig(key: string, value: any) {
    return this.formMessageService.updateConfig(key, value)
  }

  listenToUid(fieldReportUid: string): Observable<FieldReport> {
    return (
      this.modelService
        .listenTo(FieldReport.COLLECTION, fieldReportUid)
        // NB: We need to manually change model from a Report to a
        // FieldReport as our model inference does not yet support
        // multiple models for a given top-level collection
        .pipe(map((report) => new FieldReport(report.data, report.uid, report.ref)))
    )
  }

  listenToCreator(fieldReport: FieldReport): Observable<Person> {
    return this.relationService.listenToFirstTarget(fieldReport, COLLECTIONS.PEOPLE, [LABELS.CREATED_BY])
  }

  listenToCompany(fieldReport: FieldReport): Observable<Company> {
    return this.relationService.listenToFirstTarget(fieldReport, COLLECTIONS.COMPANIES, [LABELS.CONTAINED_BY])
  }

  listenToProject(fieldReport: FieldReport): Observable<Project> {
    return this.relationService.listenToFirstTarget(fieldReport, COLLECTIONS.PROJECTS)
  }

  listenToTimeline(fieldReport: FieldReport): Observable<Timeline> {
    return this.reportTimelineService.listenForReport(fieldReport)
  }

  listenToLastTimelineElementMessage(fieldReport: FieldReport): Observable<string> {
    return this.reportTimelineService.listenToLastTimelineElement(fieldReport).pipe(
      switchMap((timelineElement) => {
        return observableFrom(timelineElement.getCreator()).pipe(
          map((creator) => this.reportTimelineService.createMessageFromTimelineElement(timelineElement, creator))
        )
      })
    )
  }

  addReadOnlyChangedTimelineElement(creator: Person, fieldReport: FieldReport, readonly: boolean) {
    return this.reportTimelineService.readOnlyChanged(creator, fieldReport, readonly)
  }

  async toggleReadOnly(fieldReport: FieldReport, user: Person) {
    const readOnly = !fieldReport.readOnly

    const result = await this.dialogService.confirm(
      readOnly ? "Do you want to lock the report?" : "Do you want to unlock the report?",
      readOnly ? "Only you can open the report again for editing." : "Everyone in the project will be able to edit the report."
    )

    if (result) {
      fieldReport.readOnly = readOnly
      fieldReport.ref!.update({ readOnly })
      this.addReadOnlyChangedTimelineElement(user, fieldReport, readOnly)
    }

    /*
    this.dialogRef
      .open(ConfirmDialogComponent, {
        data: {
          title: readOnly ? 'Do you want to lock the report?' : 'Do you want to unlock the report?',
          content: readOnly
            ? 'Only you can open the report again for editing.'
            : 'Everyone in the project will be able to edit the report.'
        }
      })
      .afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        this.fieldReport.readOnly = readOnly
        this.fieldReport.ref.update({ readOnly })
        this.fieldReportService.addReadOnlyChangedTimelineElement(this.currentUser, this.fieldReport, readOnly)
      }
    })
     */
  }

  // TODO: Make this function better with use of
  // recursion and should consider both the header and
  // the template
  calculateTotalItemsAndDrawings(report: FieldReport) {
    let totalDrawings = 0
    let totalItems = 0

    if (report == null) {
      return { totalDrawings, totalItems }
    }

    report.details.map((element: any) => {
      if (element.type == "drawing") {
        totalDrawings += 1
      }
      if (element.type == "itemlist") {
        totalItems += element.value.length
      }
      element.values.map((element: any) => {
        if (element.type == "itemlist") {
          totalItems += element.value.length
        }
      })
    })

    return { totalDrawings, totalItems }
  }

  getPdfDocDefinition(report: FieldReport, form: Form, pdfDocDefinitionConfig: IPdfDocDefinitionConfig) {
    const contentTotal = this.calculateTotalItemsAndDrawings(report)

    form.header!.values!.forEach((element) => {
      if (element.title === "Description") {
        if (element.options!.filter((option) => option.code === "bold").length <= 0) {
          const option = new FormElementOption()
          option.id = element.options!.length + 1
          option.name = "bold"
          option.code = "bold"
          option.type = "checkbox"
          option.value = "true"

          element.options!.push(option)
        }
      }
    })

    return form.toPdfDocDefinition(
      pdfDocDefinitionConfig.projectTitle,
      pdfDocDefinitionConfig.creator,
      pdfDocDefinitionConfig.reportLink,
      pdfDocDefinitionConfig.companyLogoBase64,
      pdfDocDefinitionConfig.localeCreatedDate,
      pdfDocDefinitionConfig.creatorName,
      contentTotal,
      report.pdfFieldsSettings!,
      pdfDocDefinitionConfig.pdfFooter,
      this.formConfig$ ? this.formConfig$.getValue() : {}
    )
  }

  updateFieldReport(report: FieldReport, form: Form) {
    return report.update({
      headerTemplateData: JSON.stringify(form.header.transformAttributes().values),
      detailTemplateData: JSON.stringify(form.details.transformAttributes().values),
      noOfElements: form.noOfElements,
      noOfFilledElements: form.noOfFilledElements,
    })
  }
}

export interface IPdfDocDefinitionConfig {
  projectTitle: string
  creator: any
  reportLink?: string
  companyLogoBase64?: string
  localeCreatedDate?: string
  creatorName?: string
  contentTotal?: { totalItems: number; totalDrawings: number }
  settings?: ReportGenerationSettings
  pdfFooter?: string
  config?: ICheckdFormConfig
}
