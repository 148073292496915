import { environment } from "../../../environments/environment"
import { inject } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { CanActivateFn, Router } from "@angular/router"
import { InvitationType } from "@models/common"
import { combineLatest } from "rxjs"
import { switchMap, take } from "rxjs/operators"

import { InvitationService } from "@services"
import { OneLoginService } from "../services/one-login.service"

export const invitationUidGuard: CanActivateFn = (route, state) => {
  const router = inject(Router)
  const oneLoginService = inject(OneLoginService)

  oneLoginService.clearInvitationUid()

  const invitationUid = route.paramMap.get("invitationUid")
  if (!invitationUid) {
    // this should never happen
    throw new Error("invitationUidGuard: could not get invitation UID from params")
  }
  oneLoginService.setInvitationUid(invitationUid)

  return combineLatest([inject(AngularFireAuth).authState, inject(InvitationService).listenToUid(invitationUid)]).pipe(
    take(1),
    switchMap(async ([maybeUser, invitation]) => {
      if (!invitation.data) {
        return router.createUrlTree(["invitations/missing-invitation"])
      }

      if (invitation.isExpired) {
        return router.createUrlTree(["invitations/expired"])
      }

      const isLoggedIn = !!maybeUser
      if (isLoggedIn) {
        if (invitation.invitationType === InvitationType.FORMS_LIBRARY_MEMBER_COMPANY_INVITATION_EMAIL) {
          return router.createUrlTree([`/forms/invitations/${invitation.uid}`])
        }

        return router.createUrlTree(["invitations/accept-invitation"])
      }

      const preFilledOneLoginRegistrationInfo = invitation.targetEmail ? { email: invitation.targetEmail } : {}
      const oneLoginResponse = await oneLoginService.createOneLoginInvite(preFilledOneLoginRegistrationInfo)
      oneLoginService.redirectToOneLoginWithInvite(invitation.uid, oneLoginResponse)

      return true
    })
  )
}
