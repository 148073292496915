import { Component, OnInit, Input } from "@angular/core"

import { Report } from "@models/common"

@Component({
  selector: "field-report-list",
  templateUrl: "./field-report-list.component.html",
  styleUrls: ["./field-report-list.component.scss"],
})
export class FieldReportListComponent implements OnInit {
  @Input() reports: Report[]
  sliceSize: number = 10

  constructor() {}

  ngOnInit() {}

  setMaxSliceSize() {
    this.sliceSize = this.reports.length
  }
}
