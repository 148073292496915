import { BaseModel } from "./base-model"
import { LegacyTemplateData, TemplateSharingOption } from "./legacy-template.interface"

export class LegacyTemplate extends BaseModel<LegacyTemplateData> {
  public static override COLLECTION: string = "legacyTemplates"
  public inactive: boolean = false

  // TODO reportName added for legacy support. Remove when appropriate.
  get name() {
    return this.data.name || this.data.reportName || ""
  }

  get description() {
    return this.data.description || ""
  }
  get detailTemplate() {
    return JSON.parse(this.data.detailTemplate || "[]")
  }
  get headerTemplate() {
    return JSON.parse(this.data.headerTemplate || "[]")
  }
  get isDraft() {
    return this.data.isDraft || false
  }

  /**
   * Whether this template is active, i.e. available for use by company members.
   * See `sharedWith()` to see whether the template is public/internal
   */
  get isActive(): boolean {
    return !(this.archived || this.isDraft)
  }
  get logo() {
    return this.data.logo || ""
  }
  get isStandard() {
    return this.data.isStandard || false
  }

  override get aggregateData() {
    return this.data.aggregateData || {}
  }
  get publicVersion() {
    return this.data.publicVersion || ""
  }
  get internalVersion() {
    return this.data.internalVersion || 1
  }
  get pdfFooterText(): string | null {
    return this.data.pdfFooterText || null
  }
  get footerLogo() {
    return this.data.footerLogo || ""
  }
  get sharedWith() {
    return this.data.sharedWith || TemplateSharingOption.NONE
  }

  get enableAnonymousReports() {
    return this.data.enableAnonymousReports || false
  }

  get templateUpdateInfo() {
    return this.data.templateUpdateInfo ?? null
  }

  override get tags(): string[] {
    return this.data.tags ? this.data.tags : []
  }

  override set tags(tags: string[]) {
    this.data.tags = this.cleanTags(tags)
  }

  private cleanTags(tags: string[]) {
    const newTags = (tags ?? []).map((tag) => tag.trim().toUpperCase()).filter(Boolean)

    return [...new Set(newTags)].sort()
  }

  public static get(uid: string): Promise<LegacyTemplate> {
    return LegacyTemplate.getModel(LegacyTemplate, uid)
  }

  //////////////////////////////////////////////////////////////////////
  // NB: The following properties are not stored in the database.
  //     They are used to mark templates for various purposes (e.g. UI) during runtime.
  // TODO Change all getters to normal attributes and make a child model with these attributes for public-template-library

  // Marks a template as being subscribed to (public-template-library)
  public isSubscribedTo = false

  // Marks a template as being created by the current company (public-template-library)
  public isCreatedByCurrentCompany = false

  // END NB
  //////////////////////////////////////////////////////////////////////
}
