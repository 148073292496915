import { COLLECTIONS } from "../collections.interface"
import { LABELS } from "../relation.interface"
import { Role } from "./role"
import { RoleType } from "./roles-and-permissions.interface"

export class ProjectViewerRole extends Role {
  setupRoleData() {
    this.roleData = {
      name: "Project viewer",
      description: "Someone who can view a project, its drawings, people and items",
      roleType: RoleType.COMPANY_ADMINISTRATOR,
      requirements: {
        sourceCollection: COLLECTIONS.PEOPLE,
        labels: [LABELS.ADMINISTRATOR],
        targetCollection: COLLECTIONS.COMPANIES,
      },

      targetPermissions: { create: false, read: true, update: false, delete: false },

      permissions: [
        {
          description: "Can view project drawings.",
          collection: COLLECTIONS.DRAWINGS,
          documentPermissions: { create: false, read: true, update: false, delete: false },
          relationPermissions: { create: false, read: true, update: false, delete: false },
        },
        {
          description: "Can view project items.",
          collection: COLLECTIONS.ITEMS,
          documentPermissions: { create: false, read: true, update: false, delete: false },
          relationPermissions: { create: false, read: true, update: false, delete: false },
        },
        {
          description: "Can view project people.",
          collection: COLLECTIONS.PEOPLE,
          documentPermissions: { create: false, read: true, update: false, delete: false },
          relationPermissions: { create: false, read: true, update: false, delete: false },
        },
        {
          description: "Can view project invitations.",
          collection: COLLECTIONS.INVITATIONS,
          documentPermissions: { create: false, read: true, update: false, delete: false },
          relationPermissions: { create: false, read: true, update: false, delete: false },
        },
      ],

      inferredRoleTypes: {},
    }
  }
}
