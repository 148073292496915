<mat-progress-bar *ngIf="showProgressBar" class="top-progress-bar" mode="indeterminate"></mat-progress-bar>
<div class="forms-builder-wrapper flex flex-column">
  <div class="template-creation-topbar flex align-items-center">
    <button mat-icon-button (click)="location.back()">
      <mat-icon> keyboard_arrow_leftd </mat-icon>
    </button>
    <p class="title"><strong>FORMS Builder </strong><i>Beta</i></p>
  </div>

  <checkd-template-creation
    *ngIf="currentUser && currentCompany; else loadingMain"
    [@inOutAnimation]
    class="template-creation-main flex flex-column flex-auto"
    (uploadLogo)="uploadLogo($event)"
    (onTemplateOptionsTextChanged)="templateCreationService.checkIfTemplatesAreEqual(template, previousTemplate)"
    [currentUser]="currentUser"
    [currentCompany]="currentCompany"
    [template]="template"
    [templateElements]="templateElements"
    [droppedElements]="droppedElements"
    [previewData]="previewData"
  >
  </checkd-template-creation>

  <ng-template #loadingMain>
    <div class="template-creation-main full-width full-height"></div>
  </ng-template>

  <checkd-template-creation-action-bar
    class="template-creation-action-bar"
    [@inOutAnimation]
    (onNewTemplate)="new()"
    (onSave)="save($event)"
    (onPublish)="publish($event)"
    (onExportJson)="exportTemplateToJson()"
    (onEdit)="edit()"
    [currentCompany]="currentCompany"
    [template]="template"
    [isDraft]="template?.isDraft"
  >
  </checkd-template-creation-action-bar>
</div>
