<mat-form-field *ngIf="canEdit">
  <input
  matInput
  placeholder="Position"
  [value]="position"
  (keyup)="inputString$.next($event.target.value)">
</mat-form-field>

<span *ngIf="!canEdit">
  {{position}}
</span>
