<h1 mat-dialog-title>Confirm removing pending invitation</h1>

<mat-dialog-content>
  Are you sure that you want to remove the pending invitation for {{ target }}?

  <ul>
    <li>The invitation will be removed from the list of pending invitations</li>
    <li>The user will not be able to use the invitation mail to join this {{ invitationTypeString }}</li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="checkd-btn cancel-btn-color" mat-button mat-dialog-close>Cancel</button>
  <button class="checkd-btn save-btn-color" mat-button mat-dialog-close="userConfirmedRemovePendingInvitation">Remove invitation</button>
</mat-dialog-actions>
