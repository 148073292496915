import { Component } from "@angular/core"
import { MatDialogRef } from "@angular/material/dialog"
import { CsvExportRowColumnName } from "@models/common"

@Component({
  selector: "checkd-csv-export-dialog",
  templateUrl: "./csv-export-dialog.component.html",
  styleUrls: ["./csv-export-dialog.component.scss"],
})
export class CsvExportDialogComponent {
  // TODO: remove this when the export for these fields are fixed in the CF
  readonly removedCols: string[] = [
    CsvExportRowColumnName.pictureUploaderMail,
    CsvExportRowColumnName.comment,
    CsvExportRowColumnName.commentDate,
    CsvExportRowColumnName.commentUploader,
  ]

  public selectedColumns = Object.keys(CsvExportRowColumnName)
    .filter((col) => !this.removedCols.includes(col))
    .map((col) => ({ name: col, selected: true }))

  constructor(public dialogRef: MatDialogRef<CsvExportDialogComponent>) {}

  startExport() {
    this.dialogRef.close(this.selectedColumns.filter((column) => column.selected).map((selectedColumn) => selectedColumn.name))
  }
}
