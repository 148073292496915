<form class="full-width">
  <mat-form-field class="full-width">
    <mat-label>Emails</mat-label>
    <mat-chip-list #chipList>
      <!-- <mat-chip
        *ngFor="let person of selectedCompanyPeople"
        [selectable]="true"
        [removable]="true"
        (removed)="remove(person, selectedCompanyPeople)">
        {{person.person.email}}
        <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
      </mat-chip>
      <mat-chip
        *ngFor="let person of selectedCompanyAssociates"
        [selectable]="true"
        [removable]="true"
        (removed)="remove(person, selectedCompanyAssociates)">
        {{person.person.email}}
        <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
      </mat-chip> -->
      <mat-chip *ngFor="let email of emailInvitations" [selectable]="true" [removable]="true" (removed)="remove(email, emailInvitations)">
        {{ email }}
        <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
      </mat-chip>
      <input
        #mailInput
        (paste)="pasteEvent($event)"
        placeholder="Enter emails or paste one or more to send"
        [formControl]="myControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <!-- <mat-optgroup *ngFor="let group of filteredGroups | async" [label]="group.name">
        <mat-option *ngFor="let person of group.values" [value]="person">
            <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <ngx-avatars [src]="person.image" [name]="person.name" size="25"></ngx-avatars>
              <span>{{person.name}}</span>
              <span>|</span>
              <small> {{person.email}}</small>
            </span>
        </mat-option>
      </mat-optgroup> -->
      <!-- <mat-optgroup [label]="companyName">
          <mat-option *ngFor="let person of filteredCompanyMembers | async" [value]="person">
            <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <ngx-avatars [src]="person.image" [name]="person.name" size="25"></ngx-avatars>
              <span>{{person.name}}</span>
              <span>|</span>
              <small> {{person.email}}</small>
            </span>
          </mat-option>
      </mat-optgroup>
      <mat-optgroup label="Your company associates">
          <mat-option *ngFor="let person of filteredCompanyAssociates | async" [value]="person">
            <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <ngx-avatars [src]="person.image" [name]="person.name" size="25"></ngx-avatars>
              <span>{{person.name}}</span>
              <span>|</span>
              <small> {{person.email}}</small>
            </span>
          </mat-option>
      </mat-optgroup> -->
    </mat-autocomplete>
    <mat-hint *ngIf="myControl.hasError('email')" i18n><span class="mat-error">Enter a valid email</span></mat-hint>
  </mat-form-field>
</form>
