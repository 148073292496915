import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { Router } from "@angular/router"
import { CompanyFeatures, DB, LegacyTemplateAggregateData } from "@models/common"
import { IFormsLibraryMembershipData } from "@models/common/forms-library-member-company.interface"
import { LegacyTemplateData, TemplateSharingOption } from "@models/common/legacy-template.interface"
import { Relation } from "@models/common/relation"
import { LABELS } from "@models/common/relation.interface"
import { ModelService, RelationService, UserService } from "@services"
import firebase from "firebase/compat"
import { flatten } from "lodash-es"
import { combineLatest, iif, Observable, zip, of as observableOf, throwError, from } from "rxjs"
import { catchError, map, mergeMap, shareReplay, switchMap, tap } from "rxjs/operators"
import { COLLECTIONS } from "../../../models/common/collections.interface"
import { Company } from "../../../models/common/company"
import { LegacyTemplate } from "../../../models/common/legacy-template"
import { Person } from "../../../models/common/person"
import firestore = firebase.firestore
import { collection, documentId, getDocs, getFirestore, query, where } from "firebase/firestore"

@Injectable({
  providedIn: "root",
})
export class PublicTemplatesViewService {
  constructor(
    private db: AngularFirestore,
    private modelService: ModelService,
    private relationService: RelationService,
    private userService: UserService,
    private router: Router
  ) {}

  private _currentUser$ = this.userService.currentUser$
  private _currentCompany$ = this.userService.currentCompany$

  /**
   * A list of all templates that are shared with everyone.
   */
  public allPublicTemplates$: Observable<LegacyTemplate[]> = this.modelService
    .queryAndListen({
      collection: COLLECTIONS.LEGACY_TEMPLATES,
      modelData: {
        sharedWith: TemplateSharingOption.ALL_PUBLIC_TEMPLATE_USERS,
        isDraft: false,
      },
    })
    .pipe(shareReplay({ bufferSize: 1, refCount: true }))

  // TODO: This does not have real-time updates because throwError completes the stream. Find out how to keep it alive.
  /**
   * An array of all forms membership docs that a subscribing company has
   */
  public readonly companySubscriptions$: Observable<IFormsLibraryMembershipData[]> = this.userService.currentCompanyFeatures$.pipe(
    switchMap((features) =>
      iif(
        () => features.includes(CompanyFeatures.MEMBER_TEMPLATES_VIEWER),
        this._currentCompany$,
        throwError(() => [])
      )
    ),
    switchMap((company) => company.ref!.collection(COLLECTIONS.FORMS_LIBRARY_MEMBERSHIPS).where("disabled", "==", null).get()),
    map((memberShips) => memberShips.docs.map((x) => x.data() as IFormsLibraryMembershipData)),
    catchError(() => observableOf([]))
  )

  public readonly companyMembers$: Observable<Company[]> = this.userService.currentCompany$.pipe(
    switchMap((company) => company.ref!.collection(COLLECTIONS.FORMS_LIBRARY_SUBSCRIBERS).where("disabled", "==", null).get()),
    map((subscriptions) => subscriptions.docs.map((x) => x.data() as IFormsLibraryMembershipData)),
    switchMap((subscriptions) =>
      zip(
        subscriptions.map(
          (subscription) => this.modelService.listenTo(COLLECTIONS.COMPANIES, subscription.targetCompany.uid) as Observable<Company>
        )
      )
    )
  )

  public readonly companyMemberSubscriptions$: Observable<Map<string, IFormsLibraryMembershipData>> = this.userService.currentCompany$.pipe(
    switchMap((company) => company.ref!.collection(COLLECTIONS.FORMS_LIBRARY_SUBSCRIBERS).where("disabled", "==", null).get()),
    map((subscriptions) => subscriptions.docs.map((x) => x.data() as IFormsLibraryMembershipData)),
    map((subscriptions) => {
      const subs = new Map()

      for (const subscription of subscriptions) {
        subs.set(subscription.targetCompany.uid, subscription)
      }

      return subs
    })
  )

  public readonly allMemberCompaniesTemplates$: Observable<LegacyTemplate[]> = this.companySubscriptions$.pipe(
    map((subscriptions) =>
      Array.from(subscriptions.values()).map((sub) => {
        return this.modelService.queryAndListen({
          collection: COLLECTIONS.LEGACY_TEMPLATES,
          modelData: {
            sharedWith: TemplateSharingOption.COMPANY_SUBSCRIBERS,
            "aggregateData.templateCreatorCompanyUid": sub.targetCompany.uid,
            isDraft: false,
          },
        }) as Observable<LegacyTemplate[]>
      })
    ),
    switchMap((it) => (it.length > 0 ? zip(it) : observableOf([]))),
    map((it) => it.flat())
  )

  /**
   * A list of all templates that your company subscribes to.
   */
  public subscribedTemplates$: Observable<LegacyTemplate[]> = this._currentCompany$.pipe(
    switchMap((currentCompany) => this.listenToSubscribedTemplatesForCompany(currentCompany.uid)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public listenToSubscribedTemplatesForCompany(companyUid: string) {
    return this.modelService.queryAndListen({
      collection: COLLECTIONS.LEGACY_TEMPLATES,
      aggregateData: {
        subscriberCompanyUid: companyUid,
      },
    })
  }

  /**
   * A list of all companies that have marked themselves as publishers.
   * TODO Perhaps move this to a cloud function so that we don't need full access to company documents
   */
  public publisherCompanies$: Observable<Company[]> = this.modelService
    .queryAndListen({
      collection: COLLECTIONS.COMPANIES,
      modelData: {
        isTemplatePublisher: true,
      },
    })
    .pipe(shareReplay({ bufferSize: 1, refCount: true }))

  // @ts-ignore
  public publisherTemplates$: Observable<LegacyTemplate[][]> = this.publisherCompanies$.pipe(
    mergeMap((companies) => zip(...companies.map((company) => this.listenToPublisherTemplates(company.uid)))),
    shareReplay({ bufferSize: 1, refCount: true }),
    catchError((e) => {
      return e
    })
  )

  /**
   * A list of all relations from your company (as a subscriber) to companies (as publishers) that you're currently subscribing to.
   */
  public subscriberPublisherRelations$: Observable<Relation[]> = this._currentCompany$.pipe(
    switchMap((currentCompany) => this.relationService.listenToRelations(currentCompany, COLLECTIONS.COMPANIES, [LABELS.SUBSCRIBES_TO])),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public publisherCompaniesMarkedWithSubscriptions$: Observable<Company[]> = combineLatest([
    this.publisherCompanies$,
    this.subscriberPublisherRelations$,
  ]).pipe(
    map(([publisherCompanies, subscriptions]) => {
      const subscriptionCompanyUids = subscriptions.map((sub) => sub.uid)

      return publisherCompanies.map((company) => Object.assign(company, { isSubscribedTo: subscriptionCompanyUids.includes(company.uid) }))
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public publisherCompaniesWithTemplates$: Observable<{ [companyUid: string]: { company: Company; templates: LegacyTemplate[] } }> =
    combineLatest([this.publisherCompaniesMarkedWithSubscriptions$, this.publisherTemplates$, this.allPublicTemplates$]).pipe(
      map(([companies, templates, allPublicTemplates]) => {
        const result: any = {}

        for (const company of companies) {
          result[company.uid] = { company, templates: [] }
        }

        for (const template of [...flatten(templates), ...allPublicTemplates]) {
          const companyUid = template.aggregateData.templateCreatorCompanyUid || "none"
          result[companyUid].templates.push(template)
        }

        return result
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )

  public allPublicTemplatesWithCompanySubscribedStatus$: Observable<LegacyTemplate[]> = combineLatest([
    this.allPublicTemplates$,
    this.subscribedTemplates$,
  ]).pipe(
    map(([publicTemplates, subscribedTemplates]) => {
      // Subscribed templates are copies of original templates IF originalTemplateUid is set
      const subscribedTemplateUids = subscribedTemplates.map((template) => template?.data?.originalTemplateUid ?? null).filter(Boolean)

      // Mark public templates with isSubscribedTo to indicate whether your company subscribes to them
      publicTemplates.map((template) => (template.isSubscribedTo = subscribedTemplateUids.includes(template.uid)))

      return publicTemplates
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public listenToPublisherTemplates(publisherCompanyUid: string) {
    return this.modelService
      .queryAndListen({
        collection: COLLECTIONS.LEGACY_TEMPLATES,
        modelData: {
          sharedWith: TemplateSharingOption.COMPANY_SUBSCRIBERS,
        },
        aggregateData: {
          templateCreatorCompanyUid: publisherCompanyUid,
        },
      })
      .pipe(shareReplay({ bufferSize: 1, refCount: true }))
  }

  public async subscribeToTemplate(templateUid: string, copierPerson: Person, copierCompany: Company) {
    const originalTemplate = await LegacyTemplate.get(templateUid)

    if (!originalTemplate == null || originalTemplate.data == null) {
      throw new Error(`Template ${templateUid} not found.`)
    }

    const batch = this.db.firestore.batch()

    const overwrites: Partial<LegacyTemplateData> = {
      originalTemplateUid: originalTemplate.uid,
      isDraft: false,
      footerLogo: originalTemplate.footerLogo,
      pdfFooterText: originalTemplate.pdfFooterText,
    }

    const newAggregateData: LegacyTemplateAggregateData = {
      templateCreatorCompanyName: originalTemplate.aggregateData.templateCreatorCompanyName ?? "",
      templateCreatorCompanyUid: originalTemplate.aggregateData.templateCreatorCompanyUid ?? "",
      subscriberCompanyUid: copierCompany.uid || "",
      subscriberCompanyName: copierCompany.name || "",
    }

    const newTemplateData: LegacyTemplateData = this.duplicateTemplateData(originalTemplate, overwrites, newAggregateData)

    const newTemplate = LegacyTemplate.batchCreate(batch, LegacyTemplate, newTemplateData)
    newTemplate.batchAdd(batch, originalTemplate, [LABELS.COPY_OF])
    originalTemplate.batchAdd(batch, newTemplate, [LABELS.HAS_COPY])
    copierCompany.batchAdd(batch, newTemplate, [LABELS.SUBSCRIBES_TO])
    newTemplate.batchAdd(batch, copierCompany, [LABELS.SUBSCRIBED_BY])

    newTemplate.batchUpdateAggregateData(batch, newAggregateData)

    await batch.commit()

    return newTemplate
  }

  public async batchUnsubscribeToTemplate(batch: firestore.WriteBatch, template: LegacyTemplate, currentCompanyUid: string) {
    const originalTemplateUid = template.data["originalTemplateUid"]
    if (originalTemplateUid === undefined) {
      throw new Error("Could not fetch the original template UID")
    }

    const [templateToOriginalTemplate, originalTemplateToTemplate, templateToCompany, companyToTemplate] = await Promise.all([
      // Get relations between the template that was subscribed to and template
      this.db.firestore.doc(template.ref!.path).collection(template.collectionName).doc(originalTemplateUid).get(),
      this.db.firestore
        .collection(template.collectionName)
        .doc(originalTemplateUid)
        .collection(template.collectionName)
        .doc(template.ref?.id ?? template.uid)
        .get(),

      // Get relations between template and company
      this.db.firestore.doc(template.ref!.path).collection(COLLECTIONS.COMPANIES).doc(currentCompanyUid).get(),
      this.db.firestore
        .collection(COLLECTIONS.COMPANIES)
        .doc(currentCompanyUid)
        .collection(template.collectionName)
        .doc(template.ref?.id ?? template.uid)
        .get(),
    ])

    // The following two relations should exist for both the legacy standard templates, and the newer subscription templates
    this.assertDocExistsWithLabel(templateToOriginalTemplate, LABELS.COPY_OF)
    batch.update(templateToOriginalTemplate.ref, { disabled: true })

    this.assertDocExistsWithLabel(originalTemplateToTemplate, LABELS.HAS_COPY)
    batch.update(originalTemplateToTemplate.ref, { disabled: true })

    // If this is a standard template, then disabling those relations is all we had to do
    if (template.isStandard) {
      return
    }

    // If it's not a standard template, we disable the template
    template.batchDisable(batch)

    // The following two documents should only exist for newer subscriptions
    this.assertDocExistsWithLabel(templateToCompany, LABELS.SUBSCRIBED_BY)
    batch.update(templateToCompany.ref, { disabled: true })

    this.assertDocExistsWithLabel(companyToTemplate, LABELS.SUBSCRIBES_TO)
    batch.update(companyToTemplate.ref, { disabled: true })
  }

  private assertDocExistsWithLabel(doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>, requiredLabel: string) {
    if (!doc.exists) {
      throw new Error(`Expected relation doc ${doc.ref.path} to exist, but couldn't find it`)
    }

    const labels = doc.get("labels") ?? []
    if (!labels.includes(requiredLabel)) {
      throw new Error(`Found relation doc ${doc.ref.path}, but it did not include the required label ${requiredLabel}. (found ${labels})`)
    }
  }

  public subscribeToCompany(userUid: string, companyUid: string) {
    // TODO Implement call to cloud function
  }

  private duplicateTemplateData(
    originalTemplate: LegacyTemplate,
    rootDocumentOverWrites: Omit<Partial<LegacyTemplateData>, "aggregateData">,
    newAggregateData: LegacyTemplateAggregateData
  ): LegacyTemplateData {
    const oldHeaderTemplate = originalTemplate.headerTemplate
    const oldDetailTemplate = originalTemplate.detailTemplate

    return {
      headerTemplate: "[]",
      detailTemplate: JSON.stringify(oldHeaderTemplate.concat(oldDetailTemplate)),
      sharedWith: TemplateSharingOption.NONE,
      enableAnonymousReports: originalTemplate.enableAnonymousReports,
      internalVersion: originalTemplate.internalVersion ?? 1,
      publicVersion: originalTemplate.publicVersion,
      reportName: `${originalTemplate.name} (copy)`,
      description: originalTemplate.description,
      ...rootDocumentOverWrites,
      aggregateData: newAggregateData,
      tags: originalTemplate.tags,
    }
  }

  /**
   * Duplicates an existing template, copying all data from the original template to the new one.
   *
   * The duplicator will be shown as the template creator.
   *
   * Note: this function cleans up the old "isStandard" format as well.
   * @param batch
   * @param templateUid
   * @param companyUid
   * @param duplicator
   */
  public async batchDuplicateTemplate(batch: firestore.WriteBatch, templateUid: string, companyUid: string, duplicator: Person) {
    const [template, company] = await Promise.all([LegacyTemplate.get(templateUid), Company.get(companyUid)])

    const newAggregateData: LegacyTemplateAggregateData = {
      templateCreatorName: duplicator.name,
      templateCreatorUid: duplicator.uid,
      templateCreatorCompanyUid: duplicator.aggregateData["companyUid"],
      templateCreatorCompanyName: duplicator.aggregateData["companyName"],
    }

    const templateDataOverwrites: Partial<LegacyTemplateData> = {
      isDraft: true,
      footerLogo: template.footerLogo,
      pdfFooterText: template.pdfFooterText,
    }

    // NB: we do not add originalTemplateUid here. That's reserved for subscribed templates
    const newTemplateData = this.duplicateTemplateData(template, templateDataOverwrites, newAggregateData)
    const duplicatedTemplate = LegacyTemplate.batchCreate(batch, LegacyTemplate, newTemplateData)

    duplicatedTemplate.batchAdd(batch, company, [LABELS.CREATED_BY])
    company.batchAdd(batch, duplicatedTemplate, [LABELS.CREATOR])

    // This has been temporarily removed to keep consistency between old way of duplicating things as we don't have
    // references to which entity duplication originated from (i.e items duplication etc..)
    // Company templates view (old company templates table) also does not create these relations when duplicate action occurs
    // template.batchAdd(batch, duplicatedTemplate, [LABELS.HAS_COPY])
    // duplicatedTemplate.batchAdd(batch, template, [LABELS.COPY_OF])

    return duplicatedTemplate
  }

  /**
   * Hard deletes relations between company->template and template->company
   *
   * IMPORTANT: Only use if you know what you're doing
   * @param batch
   * @param companyUid
   * @param templateUid
   */
  batchHardDeleteCompanyTemplateRelationTwoWay(batch: firestore.WriteBatch, companyUid: string, templateUid: string) {
    const companyToLegacyTemplateRef = this.db
      .collection(COLLECTIONS.COMPANIES)
      .doc(companyUid)
      .collection(COLLECTIONS.LEGACY_TEMPLATES)
      .doc(templateUid).ref
    const legacyTemplateToCompanyRef = this.db
      .collection(COLLECTIONS.LEGACY_TEMPLATES)
      .doc(companyUid)
      .collection(COLLECTIONS.COMPANIES)
      .doc(companyUid).ref

    batch.delete(companyToLegacyTemplateRef)
    batch.delete(legacyTemplateToCompanyRef)
  }

  /**
   * Sets a template draft state.
   * Note: use batchSetTemplateDraftState if performing multiple operations at once
   *
   * If isDraft is true, the template will not be available for report creation, but it can be edited in the forms builder.
   * If isDraft is false, the template will be available for report creation, but will not be editable in the forms builder.
   * @param templateUid
   * @param isDraft
   */
  public async setTemplateDraftState(templateUid: string, isDraft: boolean) {
    const batch = this.db.firestore.batch()
    this.batchSetTemplateDraftState(batch, templateUid, isDraft)
    await batch.commit()
  }

  /**
   * batch sets a template draft state.
   * If isDraft is true, the template will not be available for report creation, but it can be edited in the forms builder.
   * If isDraft is false, the template will be available for report creation, but will not be editable in the forms builder.
   * @param batch
   * @param templateUid
   * @param isDraft
   */
  public batchSetTemplateDraftState(batch: firestore.WriteBatch, templateUid: string, isDraft: boolean) {
    const newData: Partial<LegacyTemplateData> = {
      isDraft,
    }
    batch.update(this.db.collection(COLLECTIONS.LEGACY_TEMPLATES).doc(templateUid).ref, newData)
  }

  /**
   * Archives or unarchives a template.
   * An archived template will not be available for report creation.
   *
   * @param templateUid the UID of the template to archive
   * @param archived the new archived state
   */
  public async setTemplateArchivedState(templateUid: string, archived: boolean) {
    const batch = this.db.firestore.batch()
    this.batchUpdateTemplateArchivedState(batch, templateUid, archived)
    await batch.commit()
  }

  /**
   * Batch updates a template's archived state
   * @param batch
   * @param templateUid
   * @param newArchivedState
   */
  batchUpdateTemplateArchivedState(batch: firestore.WriteBatch, templateUid: string, newArchivedState: boolean) {
    const templateRef = this.db.collection(COLLECTIONS.LEGACY_TEMPLATES).doc(templateUid).ref
    batch.update(templateRef, { archived: newArchivedState })
  }

  public async navigateToFormsBuilderWithTemplate(templateUid: string) {
    await this.router.navigate([`forms/builder/${templateUid}`])
  }

  public async navigateToLandingPageForTemplate(templateUid: string) {
    await this.router.navigate([`/forms/templates/${templateUid}`])
  }

  /**
   * Batch updates templateSharingOption on template specified by templateUid
   * @param batch
   * @param templateUid
   * @param templateSharingOption
   */
  batchSetTemplateSharedWithStatus(
    batch: firebase.firestore.WriteBatch,
    templateUid: string,
    templateSharingOption: TemplateSharingOption
  ) {
    const newData: Partial<LegacyTemplateData> = {
      sharedWith: templateSharingOption,
    }

    batch.update(this.db.collection(COLLECTIONS.LEGACY_TEMPLATES).doc(templateUid).ref, newData)
  }
}
