<div *ngIf="invitation$ | async as invitation" fxLayout="column" fxLayoutAlign="center center">
  <ng-container *ngIf="invitation.status != 'ACCEPTED' && !invitation.disabled; else invitationExpired">
    <ng-container
      *ngIf="{
        name: sourceUserName$ | async,
        companyName: sourceCompanyName$ | async,
        invitationCompany: invitationCompany$ | async
      } as sourceUser"
    >
      <ng-container [ngSwitch]="true">
        <ng-container *ngSwitchCase="invitation?.deliveryMethod == 'sms'">
          <ng-container [ngSwitch]="true">
            <ng-container *ngSwitchCase="!!currentUser">
              <div *ngIf="sourceUser.invitationCompany">
                <p class="title">{{ sourceUser.invitationCompany?.data.name }}</p>
                <p>
                  You have been invited to join this company
                  <span *ngIf="sourceUser?.name"> by {{ sourceUser.name }}</span>
                  <span *ngIf="sourceUser?.companyName"> from {{ sourceUser.companyName }}</span>
                </p>
              </div>

              <p class="welcome-message">
                Welcome
                <ng-container *ngIf="currentUser && currentUser.name">, {{ currentUser.name }}</ng-container>
              </p>

              <checkd-button bg="blue" fg="white" (buttonClick)="acceptInvitation()" [inProgress]="nextButtonInProgress">
                Accept invitation
              </checkd-button>
            </ng-container>

            <ng-container *ngSwitchCase="!currentUser">
              <ng-container *ngTemplateOutlet="smsInvitation"></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="invitation?.deliveryMethod == 'email'">
          <ng-container *ngTemplateOutlet="emailInvitation"></ng-container>

          <ng-container *ngIf="!currentUser; else loggedInWelcomeMessage"></ng-container>
          <ng-container *ngIf="currentUser; else ifLoggedOut"></ng-container>
        </ng-container>
      </ng-container>

      <!--  START If invitation is mail -->
      <ng-template #emailInvitation>
        <div *ngIf="sourceUser.invitationCompany">
          <p class="title">{{ sourceUser.invitationCompany?.data.name }}</p>
          <p>
            {{ targetEmail }} has been invited to join this company
            <span *ngIf="sourceUser?.name"> by {{ sourceUser.name }}</span>
            <span *ngIf="sourceUser?.companyName"> from {{ sourceUser.companyName }}</span>
          </p>
        </div>

        <div *ngIf="invitationProjectNames$ | async as invitationProjectNames">
          <span *ngFor="let projectName of invitationProjectNames">
            <p class="title">{{ projectName }}</p>
            <p>
              {{ targetEmail }} has been invited to join this project
              <span *ngIf="sourceUser?.name"> by {{ sourceUser.name }}</span>
              <span *ngIf="sourceUser?.companyName"> from {{ sourceUser.companyName }}</span>
            </p>
          </span>
        </div>
      </ng-template>
      <!--  END If invitation is mail -->

      <!--  START If invitation is sms -->
      <ng-template #smsInvitation>
        <checkd-email-address-submission
          [invitation]="invitation"
          [checkdAgreements]="checkdAgreements"
          (triggerLogin)="triggerLoginDialog()"
        ></checkd-email-address-submission>
      </ng-template>
      <!--  END If invitation is sms -->

      <!-- START If the user is logged in -->
      <ng-template #loggedInWelcomeMessage>
        <div class="welcome-message" *ngIf="currentUser; else ifLoggedOut">
          <p>
            Welcome
            <ng-container *ngIf="currentUser && currentUser.name">, {{ currentUser.name }}</ng-container>
          </p>

          <ng-container *ngIf="!isTargetEmailMatch">
            <p>This invitation seems to be for another user.</p>
            <p>
              You are currently logged in as <strong>{{ currentUser.email }}</strong
              >, and this invitation is for
              <strong>{{ targetEmail }}</strong>
            </p>

            <ng-container
              *ngIf="{
                name: sourceUserName$ | async,
                email: sourceUserEmail$ | async
              } as sourceUser"
            >
              <p>
                Please contact
                <a href="mailto:{{ sourceUser.email }}">{{ sourceUser.name }}</a>
                if you want to request an invitation.
              </p>
            </ng-container>
          </ng-container>

          <span *ngIf="isTargetEmailMatch">
            <checkd-button bg="blue" fg="white" (buttonClick)="acceptInvitation()" [inProgress]="nextButtonInProgress">
              Accept invitation
            </checkd-button>
          </span>
        </div>
      </ng-template>

      <!-- END If the user is logged in -->

      <!-- START If the user is logged out -->
      <ng-template #ifLoggedOut>
        <ng-container *ngIf="canLogInWithUsernamePassword()">
          <div *ngIf="targetEmailExists$ | async; else targetEmailDoesNotExist">
            <p>If this is your email address, please enter your password to log in and accept the invitation</p>

            <form #userForm="ngForm" [formGroup]="userInputs" fxLayout="column" (keyup.enter)="login()">
              <mat-form-field>
                <input matInput size="30" formControlName="password" placeholder="Password" type="password" value="" />
                <mat-hint>
                  <a target="_blank" rel="noopener noreferrer" href="/passwordrecovery">Forgot password?</a>
                </mat-hint>
              </mat-form-field>
            </form>

            <checkd-button bg="blue" fg="white" (buttonClick)="login()" [inProgress]="nextButtonInProgress"> Login</checkd-button>
          </div>

          <ng-template #targetEmailDoesNotExist>
            <form class="registration-form" [formGroup]="userRegistrationInputs" fxLayout="column" (keyup.enter)="registerUser()">
              <p class="welcome-message">You seem to be new here!</p>
              <p class="welcome-message">Is {{ targetEmail }} your email address?</p>
              <p class="welcome-message">If so, enter your details below to register a free CHECKD account</p>

              <mat-form-field *ngIf="invitation.invitationType != 'COMPANY'">
                <input autofocus matInput size="30" formControlName="companyName" placeholder="Company name" type="text" />
              </mat-form-field>

              <mat-form-field>
                <input matInput size="30" formControlName="userName" placeholder="Your name" type="text" />
              </mat-form-field>

              <mat-form-field>
                <input matInput size="30" formControlName="password" placeholder="Password" type="password" />
                <mat-hint>Password must be at least 6 characters</mat-hint>
              </mat-form-field>
            </form>

            <br />
            <mat-checkbox [(ngModel)]="userHasAgreed"> I have read and agreed to the</mat-checkbox>
            <a class="doclink" target="_blank" href="{{ checkdAgreements.termsAndConditions.url }}">{{
              checkdAgreements.termsAndConditions.name
            }}</a>
            and
            <a class="doclink" target="_blank" href="{{ checkdAgreements.privacyPolicy.url }}">{{ checkdAgreements.privacyPolicy.name }}</a>
            <br />
            <br />

            <checkd-button
              [disabled]="!(userRegistrationInputs.valid && userHasAgreed)"
              bg="blue"
              fg="white"
              (buttonClick)="registerUser()"
              [inProgress]="nextButtonInProgress"
            >
              Register
            </checkd-button>

            <br />
            <br />
            <br />
          </ng-template>
        </ng-container>

        <!--          registration with microsoft -->
        <button *ngIf="canLogInWithMicrosoft()" mat-button class="microsoft-login-button" (click)="loginWithMicrosoft()">
          <img src="assets/ms-symbollockup_signin_light.svg" />
        </button>
      </ng-template>
      <!-- END If the user is logged out -->
    </ng-container>
  </ng-container>

  <a
    *ngIf="!(userRegistrationInputs.valid && userHasAgreed)"
    target="_blank"
    rel="noopener noreferrer"
    [ngClass.gt-sm]="'floating-bottom-left'"
    class="mt-4"
    href="https://next-tech.com/"
  >
    <div class="flex align-items-center">
      <mat-icon svgIcon="next-logo-icon"></mat-icon>
      What is NEXT?
    </div>
  </a>

  <!--  START If invitation is accepted or disabled -->
  <ng-template #invitationExpired>
    <div class="invitation-expired">
      <p>This invitation is expired or not valid anymore.</p>
      <p>
        <a href="/projects"><strong>Click here to go to your projects</strong></a>
      </p>
    </div>
  </ng-template>
  <!--  END If invitation is accepted or disabled -->
</div>
