import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { COLLECTIONS, Company, LABELS, LegacyTemplate, LegacyTemplateData, Person } from "@models/common"
import { TemplateSharingOption } from "@models/common/legacy-template.interface"
import { ModelService } from "@services"
import { DocumentHistoryService } from "@services/document-history.service"
import firebase from "firebase/compat/app"
import { Observable } from "rxjs"
import { ITemplateData, Template, TemplateType } from "../models/template"
import { CheckboxElement } from "../models/template-elements/checkbox-element"
import { DropdownElement } from "../models/template-elements/dropdown-element"
import { FormsItemsElement } from "../models/template-elements/forms-items-element"
import { MainElement, MainSubElement, SubElement } from "../models/template-elements/main-sub-element"
import { TabularElement } from "../models/template-elements/tabular-element"
import { ElementOptionCode, OptionElement, TemplateElement, TemplateElementType } from "../models/template-elements/template-element"
import { AcceptedElement, RejectedElement, ToggleElement } from "../models/template-elements/toggle-element"
import { transformTemplate, transformTemplateElement, transformTemplateElements } from "../models/transformers"
import firestore = firebase.firestore

@Injectable({
  providedIn: "root",
})
export class LegacyTemplateService {
  constructor(private db: AngularFirestore, private modelService: ModelService, private documentHistoryService: DocumentHistoryService) {}

  listenToUid(uid: string): Observable<LegacyTemplate> {
    return this.modelService.listenTo(COLLECTIONS.LEGACY_TEMPLATES, uid)
  }

  getDefaultLegacyTemplate() {
    const defaultData: LegacyTemplateData = {
      reportName: "",
      lastElementId: 0,
      headerTemplate: "[]",
      detailTemplate: "[]",
      templateType: TemplateType.NONE,
      description: "",
      isDraft: true,
      sharedWith: TemplateSharingOption.NONE,
      enableAnonymousReports: false,
    }

    // @ts-ignore
    return new LegacyTemplate(defaultData, null, null)
  }

  transformTemplate(template: Template): LegacyTemplate {
    return transformTemplate(template)
  }

  transformTemplateElements(elements: TemplateElement[]) {
    return transformTemplateElements(elements)
  }

  transformLegacyTemplateElements(legacyElements: any[]) {
    return legacyElements.map((elm) => this.transformLegacyTemplateElement(elm))
  }

  public transformLegacyTemplate(legacyTemplate: LegacyTemplate): Template {
    const legacyTemplateData = legacyTemplate.data

    const data: ITemplateData = {
      logo: legacyTemplateData.logo ?? "",
      name: legacyTemplateData.reportName || "",
      elements: [
        ...this.transformLegacyTemplateElements(JSON.parse(legacyTemplateData.headerTemplate || "[]")),
        ...this.transformLegacyTemplateElements(JSON.parse(legacyTemplateData.detailTemplate || "[]")),
      ],
      isDraft: legacyTemplateData.isDraft || false,
      description: legacyTemplateData.description || "",
      pdfFooterText: legacyTemplateData.pdfFooterText,
      publicVersion: legacyTemplateData.publicVersion,
      internalVersion: legacyTemplateData.internalVersion,
      footerLogo: legacyTemplateData.footerLogo,
      sharingType: legacyTemplateData.sharedWith || TemplateSharingOption.NONE,
      enableAnonymousReports: legacyTemplateData.enableAnonymousReports || false,
      tags: legacyTemplateData.tags || [],
    }

    // @ts-ignore
    return new Template(data, legacyTemplate.uid || null, legacyTemplate.ref || null)
  }

  transformLegacyOptionCode(legacyOptionType: string): ElementOptionCode {
    switch (legacyOptionType) {
      case "quater":
        return ElementOptionCode.QUARTER
      case "half":
        return ElementOptionCode.HALF
      case "required":
        return ElementOptionCode.REQUIRED
      case "ismultiselect":
        return ElementOptionCode.ISMULTISELECT
      case "isvertical":
        return ElementOptionCode.ISVERTICAL
      case "conditional":
        return ElementOptionCode.CONDITIONAL
      case "include_title_in_pdf":
        return ElementOptionCode.INCLUDE_TITLE_IN_PDF
      case "addnew":
        return ElementOptionCode.ADDNEW
      case "text_field_max_length":
        return ElementOptionCode.TEXT_FIELD_MAX_LENGTH
      case "text_background_color":
        return ElementOptionCode.TEXT_BACKGROUND_COLOR
      case "text_color":
        return ElementOptionCode.TEXT_COLOR
      case "text_field_height":
        return ElementOptionCode.TEXT_FIELD_HEIGHT
      case "table_child_row_span":
        return ElementOptionCode.TABLE_CHILD_ROW_SPAN
      case "table_child_col_span":
        return ElementOptionCode.TABLE_CHILD_COL_SPAN
      case "table_columns":
        return ElementOptionCode.TABLE_COLUMNS
      case "include_in_email":
        return ElementOptionCode.INCLUDE_IN_EMAIL
      case "font_bold":
        return ElementOptionCode.FONT_BOLD
      case "font_cursive":
        return ElementOptionCode.FONT_CURSIVE
      case "font_size":
        return ElementOptionCode.FONT_SIZE
      case "checkbox_size":
        return ElementOptionCode.CHECKBOX_SIZE
      case "checkbox_border_color":
        return ElementOptionCode.CHECKBOX_BORDER_COLOR
      case "main_sub_field_background_color":
        return ElementOptionCode.MAIN_SUB_FIELD_BACKGROUND_COLOR
      case "main_sub_field_text_color":
        return ElementOptionCode.MAIN_SUB_FIELD_TEXT_COLOR
      case "table_column_widths":
        return ElementOptionCode.TABLE_COLUMN_WIDTHS
      case ElementOptionCode.HIDE_TITLE_IN_PDF.toLowerCase():
        return ElementOptionCode.HIDE_TITLE_IN_PDF
      default:
        return ElementOptionCode.NONE
    }
  }

  transformLegacyOption(legacyOption: any): OptionElement {
    return {
      id: legacyOption.id || 0,
      name: legacyOption.name || "",
      value: this.transformLegacyOptionValue(legacyOption.value),
      code: this.transformLegacyOptionCode(legacyOption.code.toLowerCase()),
      type: legacyOption.type || "NONE",
    }
  }

  transformLegacyOptionValue(value: unknown) {
    switch (true) {
      case value === "true":
        return true
      case value === "false":
        return false
      case typeof value === "string":
      case typeof value === "number":
      default:
        return value
    }
  }

  transformLegacyOptions(legacyOptions: any[]): OptionElement[] {
    return legacyOptions.map((o) => this.transformLegacyOption(o))
  }

  transformLegacyAttributes(legacyElement: any) {
    return {
      name: legacyElement.title || "",
      type: this.transformLegacyTemplateElementType(legacyElement),
      typeAlias: this.getCorrectTypeAlias(legacyElement),
      value: legacyElement.value || null,
      info: legacyElement.info || "",
      icon: this.getElementIconString(legacyElement),
      options: this.transformLegacyOptions(legacyElement.options || []),
    }
  }

  transformLegacyCheckbox(legacyElement: any): CheckboxElement {
    const children = (legacyElement.values || []).map((child: any) => ({
      id: child.id,
      name: child.name,
      value: child.value,
      children: this.transformLegacyTemplateElements([].concat(...(child.child || []))),
      canHaveChildren: (child.child || []).length > 0,
    }))

    return { ...this.transformLegacyAttributes(legacyElement), children }
  }

  transformLegacyDropdown(legacyElement: any): DropdownElement {
    const children = (legacyElement.values || []).map((child: any) => ({
      id: child.id,
      name: child.name,
      value: child.value,
    }))

    return { ...this.transformLegacyAttributes(legacyElement), children }
  }

  transformLegacyTabular(legacyElement: any): TabularElement {
    const child = legacyElement.child || [[]]
    const children = this.transformLegacyTemplateElements(child[0])

    return { ...this.transformLegacyAttributes(legacyElement), children }
  }

  transformLegacyTable(legacyElement: any): TabularElement {
    const child = legacyElement.child || [[]]
    const children = this.transformLegacyTemplateElements(child[0])

    return { ...this.transformLegacyAttributes(legacyElement), children }
  }

  transformLegacyTableChild(legacyElement: any): TabularElement {
    const child = legacyElement.child || [[]]
    const children = this.transformLegacyTemplateElements(child[0])

    return { ...this.transformLegacyAttributes(legacyElement), children }
  }

  transformLegacyFormsItemsElement(legacyElement: any): FormsItemsElement {
    const prefill = legacyElement.prefill

    return { ...this.transformLegacyAttributes(legacyElement), prefill, children: [] }
  }

  transformLegacyMainAndSubfield(legacyElement: any): MainSubElement {
    const mainValue = legacyElement.title || ""
    const subValue = legacyElement.info || ""

    const main = { ...new MainElement(), value: mainValue }
    const sub = { ...new SubElement(), value: subValue }

    const children = [main, sub]

    return { ...this.transformLegacyAttributes(legacyElement), children }
  }

  transformLegacyToggle(legacyElement: any): ToggleElement {
    const accepted = {
      ...new AcceptedElement(),
      children: this.transformLegacyTemplateElements([].concat(...(legacyElement.accepted || []))),
    }
    const rejected = {
      ...new RejectedElement(),
      children: this.transformLegacyTemplateElements([].concat(...(legacyElement.rejected || []))),
    }

    return { ...this.transformLegacyAttributes(legacyElement), children: [accepted, rejected] }
  }

  transformLegacyTemplateElement(legacyElement: any): TemplateElement {
    const elementType = this.transformLegacyTemplateElementType(legacyElement)

    switch (elementType) {
      case TemplateElementType.CHECKBOX:
        return this.transformLegacyCheckbox(legacyElement)
      case TemplateElementType.DROPDOWN:
        return this.transformLegacyDropdown(legacyElement)
      case TemplateElementType.MAIN_SUB_FIELD:
        return this.transformLegacyMainAndSubfield(legacyElement)
      case TemplateElementType.TOGGLE:
        return this.transformLegacyToggle(legacyElement)
      case TemplateElementType.TABULAR:
        return this.transformLegacyTabular(legacyElement)
      case TemplateElementType.FORMS_ITEMS:
        return this.transformLegacyFormsItemsElement(legacyElement)
      case TemplateElementType.TABLE:
        return this.transformLegacyTable(legacyElement)
      case TemplateElementType.TABLE_CHILD:
        return this.transformLegacyTableChild(legacyElement)
      default:
        return Object.assign(this.transformLegacyAttributes(legacyElement), { children: [] })
    }
  }

  async saveOrUpdateLegacyTemplate(data: {
    legacyTemplate: LegacyTemplate
    creatorOrEditor: Person
    company?: Company
    publishData?: {
      changelog: string
      publicVersion: string
    }
  }) {
    const batch = this.db.firestore.batch()

    const { legacyTemplate, creatorOrEditor, company } = data

    // FIXME hack to prevent undefined values for pdfFooterText from getting into the document updates
    if (legacyTemplate.data.pdfFooterText === undefined) {
      legacyTemplate.data.pdfFooterText = null
    }

    const newTemplateData = { ...legacyTemplate.data }

    if (data.publishData) {
      // @ts-ignore
      newTemplateData.internalVersion = (newTemplateData.internalVersion ?? 0) + 1
    }

    if (legacyTemplate.uid != null && legacyTemplate.ref != null) {
      const historyRef = await this.documentHistoryService.batchAddToHistory(batch, legacyTemplate, creatorOrEditor)
      const updatedLegacyTemplate = legacyTemplate.batchUpdate(batch, newTemplateData)

      if (data.publishData) {
        this.documentHistoryService.batchAddChangelog(batch, updatedLegacyTemplate, {
          changelog: data.publishData.changelog,
          editorUid: creatorOrEditor.uid,
          historyUid: historyRef.id,
          publicVersion: data.publishData.publicVersion,
          // @ts-ignore
          internalVersion: newTemplateData.internalVersion,
        })
      }

      await batch.commit()

      return updatedLegacyTemplate
    }

    const newLegacyTemplate = LegacyTemplate.batchCreate(batch, LegacyTemplate, newTemplateData)

    if (creatorOrEditor) {
      this.addLegacyTemplateCreator(batch, newLegacyTemplate, creatorOrEditor)
    }

    if (company) {
      this.addCompanyLegacyTemplate(batch, company, newLegacyTemplate)
    }

    if (data.publishData) {
      this.documentHistoryService.batchAddChangelog(batch, newLegacyTemplate, {
        changelog: data.publishData.changelog,
        editorUid: creatorOrEditor.uid,
        historyUid: null,
        publicVersion: data.publishData.publicVersion,
        // @ts-ignore
        internalVersion: newTemplateData.internalVersion,
      })
    }

    await batch.commit()

    return newLegacyTemplate
  }

  // NB: if we use this for anything else than template creation,
  //     we need to modifiy the aggregate data and relation labels
  addCompanyLegacyTemplate(batch: firestore.WriteBatch, company: Company, legacyTemplate: LegacyTemplate) {
    const aggregateData = {
      ...(legacyTemplate.aggregateData || {}),
      templateCreatorCompanyName: company.name,
      templateCreatorCompanyUid: company.uid,
    }

    legacyTemplate.batchAdd(batch, company, [LABELS.DEFAULT, LABELS.CREATED_BY])
    company.batchAdd(batch, legacyTemplate, [LABELS.DEFAULT, LABELS.CREATOR])
    legacyTemplate.batchUpdateAggregateData(batch, aggregateData)

    return legacyTemplate
  }

  addLegacyTemplateCreator(batch: firestore.WriteBatch, legacyTemplate: LegacyTemplate, creator: Person): LegacyTemplate {
    const aggregateData = {
      ...(legacyTemplate.aggregateData || {}),
      templateCreatorName: creator.name,
      templateCreatorUid: creator.uid,
    }

    legacyTemplate.batchAdd(batch, creator, [LABELS.CREATED_BY])
    creator.batchAdd(batch, legacyTemplate, [LABELS.CREATOR])
    legacyTemplate.batchUpdateAggregateData(batch, aggregateData)

    return legacyTemplate
  }

  transformLegacyTemplateElementType(element: any) {
    switch (element.type) {
      case "yesno":
        return TemplateElementType.TOGGLE
      case "textfield":
        return TemplateElementType.TEXT_FIELD
      case "textfieldint":
        return TemplateElementType.TEXT_FIELD
      case "multilinetextfield":
        return TemplateElementType.TEXT_FIELD
      case "slider":
        return TemplateElementType.SLIDER
      case "checkbox":
        return TemplateElementType.CHECKBOX
      case "signature":
        return TemplateElementType.SIGNATURE
      case "dropdown":
        return TemplateElementType.DROPDOWN
      case "date":
        return TemplateElementType.DATE
      case "time":
        return TemplateElementType.TIME
      case "camera":
        return TemplateElementType.CAMERA
      case "mainandsubfield":
        return TemplateElementType.MAIN_SUB_FIELD
      case "tabularform":
        return TemplateElementType.TABULAR
      case "main":
        return TemplateElementType.MAIN_FIELD
      case "sub":
        return TemplateElementType.SUB_FIELD
      case "gps":
        return TemplateElementType.NONE
      case "updown":
        return TemplateElementType.NONE
      case "formsitems":
        return TemplateElementType.FORMS_ITEMS
      case "image":
        return TemplateElementType.IMAGE
      case "text":
        return TemplateElementType.TEXT
      case "table":
        return TemplateElementType.TABLE
      case "table_child":
        return TemplateElementType.TABLE_CHILD
      case TemplateElementType.PAGE_BREAK.toLowerCase():
        return TemplateElementType.PAGE_BREAK
      default:
        return TemplateElementType.NONE
    }
  }

  // icon string is what's used in svgIcon in mat-icon
  getElementIconString(element: any): string {
    switch (element.type) {
      case "textfieldint":
      case "multilinetextfield":
      case "textfield":
      case "text":
      case TemplateElementType.PAGE_BREAK.toLowerCase():
        return "forms:text-field"
      case "slider":
      case "yesno":
        return "forms:toggle"
      case "checkbox":
        return "forms:checkbox"
      case "signature":
        return "forms:signature"
      case "dropdown":
        return "forms:dropdown"
      case "date":
        return "forms:date"
      case "time":
        return "forms:time"
      case "camera":
      case "image":
        return "forms:camera"
      case "mainandsubfield":
        return "forms:main-and-subfield"
      case "tabularform":
      case "main":
      case "sub":
      case "table":
        return "forms:tabular"
      case "formsitems":
        return "forms:item-element"
      default:
        return ""
    }
  }

  getCorrectTypeAlias(element: any): string {
    switch (element.type) {
      case "checkbox":
        return "Checkbox"
      case "yesno":
        return "Toggle"
      case "dropdown":
        return "Dropdown"
      case "tabularform":
        return "Tabular"
      case "image":
        return "Image"
      default:
        return element.typeAlies || ""
    }
  }

  transformTemplateElement(element: TemplateElement) {
    return transformTemplateElement(element)
  }
}
