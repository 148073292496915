<mat-dialog-content class="spinner-dialog-content">
  <ng-container [ngSwitch]="dialogState">
    <mat-spinner *ngSwitchCase="'spinning'" [diameter]="data.diameter" [strokeWidth]="data.strokeWidth"></mat-spinner>
    <div *ngSwitchCase="'finishedWithSuccess'">
      <mat-icon [ngStyle]="{ 'font-size': iconSize, height: iconSize, width: iconSize }" class="success">check_circle</mat-icon>
    </div>
    <div *ngSwitchCase="'finishedWithError'">
      <mat-icon [ngStyle]="{ 'font-size': iconSize, height: iconSize, width: iconSize }" class="error">error</mat-icon>
    </div>
  </ng-container>
  <div *ngIf="title || subTitle" class="mt-3">
    <p class="message">{{ title }}</p>
    <p class="sub-title" *ngIf="subTitle">{{ subTitle }}</p>
  </div>
</mat-dialog-content>
