<ng-container
  *ngIf="{
    legacyTemplate: legacyTemplate$ | async,
    templateCreatorCompany: templateCreatorCompany$ | async,
    currentCompany: userService.currentCompany$ | async,
    currentUser: userService.currentUser$ | async
  } as subs"
>
  <p-messages *ngIf="subscribedTemplateIsOutdated$ | async" severity="warn" styleClass="message">
    <ng-template pTemplate>
      <div class="message__container">
        <div class="ml-2">
          <span>New version of template available! </span
          ><b class="message__link" (click)="openChangelogDialog(subs.legacyTemplate)">View changes</b>
        </div>

        <button danger-button (click)="updateTemplate()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2511_16787)">
              <path
                d="M21.9354 13.6041C21.2966 13.4167 20.6327 13.7838 20.4482 14.4194C19.366 18.1932 15.8917 20.7976 11.9575 20.7976C8.6317 20.7976 5.61883 18.884 4.13414 15.9986H9.15309C9.81546 15.9986 10.3528 15.4612 10.3528 14.7989C10.3528 14.1365 9.86295 13.5991 9.19808 13.5991H1.19975C0.537388 13.5991 0 14.1365 0 14.7989V22.7972C0 23.4596 0.537388 23.997 1.19975 23.997C1.86211 23.997 2.3995 23.4596 2.3995 22.7972V17.6933C4.38909 21.0176 8.00833 23.1971 11.9575 23.1971C16.9065 23.1971 21.3256 19.8823 22.7103 15.1338C22.9402 14.4539 22.5703 13.8341 21.9354 13.6041ZM22.8002 0.00195312C22.1404 0.00195312 21.6005 0.539341 21.6005 1.2017V6.30564C19.6159 2.97883 15.9917 0.801786 12.0025 0.801786C7.05353 0.801786 2.63095 4.1166 1.25024 8.86511C1.06428 9.49997 1.4297 10.1648 2.06557 10.3498C2.70744 10.5388 3.3698 10.1686 3.55276 9.53447C4.63903 5.80574 8.11331 3.20129 12.0025 3.20129C15.3283 3.20129 18.3412 5.11489 19.8259 8.00029H14.8019C14.1421 8.00029 13.6022 8.54017 13.6022 9.20004C13.6022 9.8599 14.1421 10.3998 14.8019 10.3998H22.8002C23.4626 10.3998 24 9.8624 24 9.20004V1.2017C24 0.539341 23.4651 0.00195312 22.8002 0.00195312Z"
                fill="currentcolor"
              />
            </g>
            <defs>
              <clipPath id="clip0_2511_16787">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <span>Update now</span>
        </button>
      </div>
    </ng-template>
  </p-messages>
  <div class="container flex flex-column w-full" *ngIf="subs.legacyTemplate && subs.currentCompany">
    <div class="header">
      <a (click)="navigateBack()" class="forms-library-link flex align-items-center no-underline cursor-pointer">
        <i class="pi pi-chevron-left"></i>
        <span>Back</span>
      </a>
      <h2 class="header__title">{{ subs.legacyTemplate.aggregateData.templateCreatorCompanyName }} template</h2>
    </div>

    <div class="content">
      <section class="preview">
        <p-confirmDialog
          header="Confirm"
          icon="pi pi-exclamation-triangle"
          [style]="{ width: '50vw' }"
          [breakpoints]="{ '600px': '95vw' }"
          #cd
        >
          <ng-template pTemplate="footer">
            <div class="confirm-dialog__btn-container">
              <button type="button" primary-button [type]="'outline'" (click)="cd.reject()">No</button>
              <button type="button" primary-button (click)="cd.accept()">Yes</button>
            </div>
          </ng-template>
        </p-confirmDialog>

        <section class="preview-area" id="preview-area" *ngIf="previewData && previewData.length > 0">
          <div class="scrollable-wrapper">
            <ng-container *ngFor="let element of previewData">
              <app-form-element [element]="element"></app-form-element>
            </ng-container>
          </div>
        </section>
      </section>
      <section class="details-card">
        <div class="details-card__body">
          <!--          TODO: Get changelog out of the dialog to be able to show below-->
          <!--          <div *ngIf="subscribedTemplateIsOutdated$ | async" class="details-card__update-container">-->
          <!--            <h3 class="details-card__update-title">Update available:</h3>-->
          <!--            <p class="details-card__update-text" (click)="openChangelogDialog(subs.legacyTemplate)">-->
          <!--              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod earum error distinctio voluptate cumque magni, at vero inventore-->
          <!--              alias provident quasi molestias maiores fuga sunt aut soluta ad quos repellendus. Lorem, ipsum dolor sit amet consectetur-->
          <!--              adipisicing elit. Nostrum eius, explicabo modi quam ipsam possimus perspiciatis, facilis nam voluptatum laborum dolorem illum-->
          <!--              placeat, itaque voluptates quod magni aut in labore?Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum-->
          <!--              reprehenderit animi, exercitationem nisi repellendus beatae aspernatur delectus quibusdam dolor repudiandae, ex ipsam qui.-->
          <!--              Inventore aut, blanditiis ea fugit enim temporibus.-->
          <!--            </p>-->
          <!--          </div>-->

          <!--          Edit Button-->
          <ng-container *ngIf="subs.legacyTemplate.aggregateData.templateCreatorCompanyUid == subs.currentCompany.uid">
            <checkd-edit-forms-template-dialog
              [(open)]="editFormDialogOpen"
              [template]="subs.legacyTemplate"
              [currentCompany]="subs.currentCompany"
              (onSave)="saveButtonClicked(subs.legacyTemplate, $event)"
            >
            </checkd-edit-forms-template-dialog>

            <div class="details-card__edit">
              <div class="button__container">
                <button primary-button [disabled]="!(userCanEditFormInfo$ | async)" (click)="editFormButtonClicked()">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2511_17681)">
                      <path
                        d="M11.7492 0.664027C12.634 -0.221342 14.0699 -0.221342 14.9547 0.664027L15.3358 1.04567C16.2206 1.93114 16.2206 3.36671 15.3358 4.25154L9.12618 10.4643C8.85222 10.7351 8.5153 10.9335 8.14373 11.0405L4.99485 11.9411C4.73035 12.0167 4.44695 11.9411 4.25172 11.7207C4.05963 11.5538 3.98406 11.2704 4.05963 11.0059L4.96021 7.85701C5.06727 7.48544 5.26565 7.14851 5.53646 6.87456L11.7492 0.664027ZM13.8589 1.73276C13.5913 1.43771 13.1127 1.43771 12.8167 1.73276L11.9003 2.64876L13.352 4.1004L14.2683 3.15573C14.5643 2.88808 14.5643 2.40945 14.2683 2.1144L13.8589 1.73276ZM6.41499 8.27266L5.88598 10.1147L7.72808 9.58574C7.85403 9.5511 7.96424 9.48497 8.05556 9.39366L12.2845 5.16787L10.8329 3.71623L6.60708 7.94517C6.51576 8.03649 6.44963 8.1467 6.41499 8.27266ZM6.29849 1.89303C6.71729 1.89303 7.05422 2.23154 7.05422 2.64876C7.05422 3.06756 6.71729 3.40449 6.29849 3.40449H2.77174C2.07616 3.40449 1.51219 3.96814 1.51219 4.66404V13.229C1.51219 13.9249 2.07616 14.4885 2.77174 14.4885H11.3367C12.0326 14.4885 12.5962 13.9249 12.5962 13.229V9.70225C12.5962 9.28345 12.9332 8.94652 13.352 8.94652C13.7708 8.94652 14.1077 9.28345 14.1077 9.70225V13.229C14.1077 14.7593 12.867 16 11.3367 16H2.77174C1.24139 16 0.000732422 14.7593 0.000732422 13.229V4.66404C0.000732422 3.13369 1.24139 1.89303 2.77174 1.89303H6.29849Z"
                        fill="currentcolor"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2511_17681">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Edit info</span>
                </button>
              </div>
            </div>
          </ng-container>

          <h3 class="details-card__title">{{ subs.legacyTemplate.name }}</h3>
          <textarea disabled readonly class="details-card__text-desc" *ngIf="subs.legacyTemplate.description">
            {{ subs.legacyTemplate.description }}
          </textarea>
          <div class="details-card__data-container">
            <div class="details-card__data">
              <h4 class="details-card__subtitle">Template Version</h4>
              <p class="details-card__text-info--version">
                {{ subs.legacyTemplate.publicVersion }}
                <span class="details-card__version-link" (click)="openChangelogDialog(subs.legacyTemplate)">Version history</span>
              </p>
            </div>
            <div class="details-card__data">
              <h4 class="details-card__subtitle">Last Modified</h4>
              <p class="details-card__text-info">{{ subs.legacyTemplate.updatedAt | date: "dd.MM.YYYY" }}</p>
            </div>

            <div *ngIf="subs.legacyTemplate.tags" class="details-card__data">
              <h4 class="details-card__subtitle">Template Tags</h4>
              <div class="flex flex-wrap mt-1 gap-2">
                <p-tag *ngFor="let tag of subs.legacyTemplate.tags" [value]="tag"></p-tag>
              </div>
            </div>

            <!--            <div class="card-entry">-->
            <!--              <span class="header">Version:</span>-->
            <!--              <span class="value"-->
            <!--                >{{ subs.legacyTemplate.publicVersion }}-->
            <!--                <span-->
            <!--                  (click)="openChangelogDialog(subs.legacyTemplate)"-->
            <!--                  class="ml-2 text-blue-600 hover:text-blue-400 underline text-sm cursor-pointer">Version history-->
            <!--                </span>-->
            <!--                </span>-->
            <!--            </div>-->
            <div class="details-card__data">
              <h4 *ngIf="subs.legacyTemplate.sharedWith != TemplateSharingOption.NONE" class="details-card__subtitle">Shared with</h4>
              <div class="details-card__text-info">
                <ng-container [ngSwitch]="subs.legacyTemplate.sharedWith">
                  <ng-container *ngSwitchCase="TemplateSharingOption.ALL_PUBLIC_TEMPLATE_USERS">
                    <i class="pi pi-globe"></i>
                    <span class="value ml-1">Public</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="TemplateSharingOption.COMPANY_SUBSCRIBERS">
                    <img src="./assets/icons/forms_templates/Subs-icon.svg" />
                    <span class="details-card__subs">Subscribers</span>
                  </ng-container>
                  <ng-container *ngSwitchDefault> </ng-container>
                </ng-container>
              </div>
            </div>
            <div class="details-card__data--btns-container">
              <!--              Download Pdf-->
              <p-splitButton
                label="Download PDF"
                icon="pi pi-file-pdf"
                (onClick)="downloadPDF(subs.legacyTemplate, subs.templateCreatorCompany)"
                [model]="downloadMenuItems"
                styleClass="secondary-btn"
              >
              </p-splitButton>

              <!--              Edit in forms builder-->
              <div
                *ngIf="subs.legacyTemplate.aggregateData.templateCreatorCompanyUid == subs.currentCompany.uid; else subscribeButton"
                class="button__container"
              >
                <button
                  primary-button
                  (click)="updateFormButtonClicked(subs.legacyTemplate)"
                  [disabled]="!(userCanClickUpdateFormButton$ | async)"
                >
                  <img src="assets/icons/forms_templates/edit-forms-builder-icon.png" alt="edit-builder-icon" class="button__icon-left" />
                  <span>Edit in Forms Builder</span>
                </button>
              </div>

              <ng-template #subscribeButton>
                <div *ngIf="(alreadySubscribedToTemplate$ | async) == false" class="button__container mt-2">
                  <button
                    primary-button
                    [disabled]="
                      subscribeInProgress ||
                      !(this.userService.currentUserIsCompanyAdmin$ | async) ||
                      (alreadySubscribedToTemplate$ | async) ||
                      !(currentCompanyCanAddTemplateToCompany$ | async)
                    "
                    (click)="addTemplateToCompanyButtonClicked(subs.legacyTemplate.uid, subs.currentUser, subs.currentCompany)"
                  >
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_3910_12557)">
                        <path
                          d="M24 11.9994C24 12.7667 23.3827 13.384 22.6154 13.384H13.3846V22.6148C13.3846 23.3792 12.7644 24 12 24C11.2356 24 10.6154 23.3821 10.6154 22.6148V13.384H1.38462C0.620192 13.384 0 12.7644 0 12C0 11.2379 0.620192 10.6148 1.38462 10.6148H10.6154V1.38404C10.6154 0.619615 11.2356 0 12 0C12.7644 0 13.3846 0.619615 13.3846 1.38404V10.6148H22.6154C23.3827 10.6148 24 11.2379 24 11.9994Z"
                          fill="currentcolor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3910_12557">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Add template to company</span>
                  </button>
                </div>

                <div *ngIf="alreadySubscribedToTemplate$ | async" class="details-card__data--template-status">
                  <div class="template-status">
                    <i class="pi pi-check template-status__icon"></i>
                    <span class="template-status__text">Added to company</span>
                  </div>
                  <!-- Remove template component -->
                  <!-- <div class="template-status__trash-container">
                    <i class="pi pi-trash template-status__icon--trash"></i>
                    <span class="template-status__text--trash">Remove template</span>
                  </div> -->
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <!-- Company logo and share link commented to use later -->
        <div *ngIf="subs.templateCreatorCompany" class="details-card__footer">
          <div class="details-card__img-wrapper">
            <img [src]="subs.templateCreatorCompany.logo" alt="company-logo" class="details-card__company-img" />
          </div>
          <!--          <div class="details-card__share-wrapper">-->
          <!--            <img src="./assets/icons/forms_templates/share-icon.png" alt="" class="details-card__share-icon" />-->
          <!--            <span class="details-card__share-text">Share</span>-->
          <!--          </div>-->
        </div>
      </section>
    </div>
  </div>
</ng-container>
