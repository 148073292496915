<h1 matDialogTitle>{{data.title}}</h1>
<div matDialogContent>
  <p *ngFor="let m of data.message">{{m}}</p>

  <ng-container *ngIf="linkType === 'DOWNLOAD'">
    <p>Click <a [href]="data.link" download>here</a> to download</p>
  </ng-container>

  <ng-container *ngIf="linkType === 'OPEN_IN_NEW_WINDOW'">
    <p>Click <a [href]="data.link" target="_blank">here</a> to open in new window</p>
  </ng-container>

  <p>
    Click this button to copy the link to clipboard:
    <button
      mat-icon-button
      [ngxClipboard]="sharingLink"
      (click)="showMessage()"
      [class.copied-success]= "isCopied">
      <mat-icon>link</mat-icon>
    </button>
  </p>

  <mat-form-field class="full-width">
    <textarea
      #sharingLink
      matInput
      readonly
      [value]="data.link"
      matTextareaAutosize
      matAutosizeMinRows="1"
      matAutosizeMaxRows="1"
    ></textarea>
  </mat-form-field>

</div>
