<h2 mat-dialog-title>Configure table cell</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Select type of element</mat-label>
    <mat-select [(value)]="selectedElement" [compareWith]="compareFunc">
      <mat-option *ngFor="let element of allowedTableElements" [value]="element">
        {{element.typeAlias}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="selectedElement else elementNotSelected">
    <checkd-template-element-config [element]="selectedElement" [allowConditionals]="false" [embedInDropdown]="false"></checkd-template-element-config>
  </ng-container>

  <ng-template #elementNotSelected>
    <section>
      Select an element from the dropdown above to configure this cell
    </section>
  </ng-template>
</mat-dialog-content>
