import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../environments/environment"

@Injectable()
export class MigrationService {
  baseUrl: string = environment.firebase.functions.url

  constructor(private http: HttpClient) {}

  migrateLegacyUser(email: string, password: string) {
    const url = `${this.baseUrl}migrateLegacyUser`
    return this.http
      .post(url, {
        email: email,
        password: password,
      })
      .toPromise()
  }

  createUserOnFirebase(email: string, password: string) {
    const url = `${this.baseUrl}migrateUser`
    return this.http
      .post(url, {
        email: email,
        password: password,
      })
      .toPromise()
  }

  // TODO Create a cloud functions service and move this method there
  validate2FA(uid: string, token: string) {
    const url = `${this.baseUrl}validate2FA`
    return this.http
      .post(url, {
        uid: uid,
        token: token,
      })
      .toPromise()
  }

  // TODO Create a cloud functions service and move this method there
  get2FAqrcode(token: string) {
    const url = `${this.baseUrl}get2FALink`
    return this.http
      .post(url, {
        token: token,
      })
      .toPromise()
  }
}
