import { TemplateSharingOption } from "@models/common/legacy-template.interface"
import firebase from "firebase/compat/app"
import { TemplateElement } from "./template-elements/template-element"
import firestore = firebase.firestore
import FieldValue = firebase.firestore.FieldValue

export enum TemplateType {
  NONE = "NONE",
}

export interface ITemplateData {
  enableAnonymousReports: boolean
  publicVersion?: string | null
  internalVersion: number | FieldValue | undefined
  name?: string
  templateType?: TemplateType
  description?: string
  isDraft?: boolean
  elements: TemplateElement[]
  typeAlias?: string
  logo?: string
  pdfFooterText?: string | null
  footerLogo?: string
  sharingType?: TemplateSharingOption
  tags?: string[]
}

export class Template {
  get enableAnonymousReports(): boolean {
    return this.data.enableAnonymousReports
  }
  set enableAnonymousReports(val: boolean) {
    this.data.enableAnonymousReports = val
  }

  get name(): string {
    return this.data.name || ""
  }
  set name(val: string) {
    this.data.name = val
  }

  get typeAlias(): string {
    return this.data.typeAlias || ""
  }
  set typeAlias(val) {
    this.data.typeAlias = val
  }

  get elements(): TemplateElement[] {
    return this.data.elements || []
  }
  set elements(elements) {
    this.data.elements = elements
  }

  get isDraft(): boolean {
    return this.data.isDraft || false
  }
  set isDraft(val: boolean) {
    this.data.isDraft = val
  }

  get description(): string {
    return this.data.description || ""
  }
  set description(val: string) {
    this.data.description = val
  }

  get templateType(): TemplateType {
    return this.data.templateType || TemplateType.NONE
  }
  set templateType(type: TemplateType) {
    this.data.templateType = type
  }

  get logo(): string {
    return this.data.logo || ""
  }
  set logo(url: string) {
    this.data.logo = url
  }

  get footerLogo(): string {
    return this.data.footerLogo || ""
  }
  set footerLogo(url: string) {
    this.data.footerLogo = url
  }

  get pdfFooterText(): string | null {
    return this.data?.pdfFooterText ?? null
  }
  set pdfFooterText(text: string | null) {
    this.data.pdfFooterText = text
  }

  get publicVersion(): string {
    return this.data.publicVersion || ""
  }
  set publicVersion(version: string) {
    this.data.publicVersion = version
  }

  get internalVersion(): number | FieldValue {
    return this.data.internalVersion || 0
  }
  set internalVersion(version: number | FieldValue) {
    this.data.internalVersion = version
  }

  get sharingType(): TemplateSharingOption {
    return this.data.sharingType || TemplateSharingOption.NONE
  }
  set sharingType(val: TemplateSharingOption) {
    this.data.sharingType = val
  }

  get tags(): string[] {
    return this.data.tags || []
  }

  set tags(newTags: string[]) {
    this.data.tags = newTags
      .map((tag) => tag.trim().toUpperCase())
      .filter(Boolean)
      .sort()
  }

  constructor(public data: ITemplateData, public uid: string, public ref?: firestore.DocumentReference) {}
}
