<div [formGroup]="form" class="flex flex-column">
  <h4 *ngIf="ruleForms.length === 0; else rulesView">No rules added yet. Add one by clicking the button below</h4>

  <ng-template #rulesView>
    <div class="overflow-y-auto" formArrayName="rules">
      <ng-container *ngFor="let rule of ruleForms.controls; let index = index; let last = last">
        <div [formGroupName]="index" class="flex my-2 rule-row align-items-baseline">
          <mat-form-field>
            <mat-label>Person</mat-label>
            <mat-select formControlName="member">
              <mat-optgroup *ngFor="let companyGroup of _projectOwnersAndAdminsOrderedByCompany" [label]="companyGroup.companyName">
                <mat-option *ngFor="let member of companyGroup.members" [value]="member">{{ member.name }}</mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-error>Please select a project member</mat-error>
          </mat-form-field>

          <span>will receive</span>

          <mat-form-field class="small-dropdown">
            <mat-select formControlName="deliveryMethod">
              <mat-option [value]="'email'">an e-mail</mat-option>
            </mat-select>
          </mat-form-field>

          <span>every time the</span>

          <mat-form-field class="small-dropdown">
            <mat-select formControlName="subject">
              <mat-option [value]="'FormsReport'">report</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="ml-1">
            <mat-label>Report</mat-label>
            <mat-select formControlName="forTarget">
              <mat-optgroup *ngIf="projectTemplates && projectTemplates.length > 0" [label]="'Project Templates'">
                <mat-option *ngFor="let template of projectTemplates" [value]="template">{{ template.name }}</mat-option>
              </mat-optgroup>
              <mat-optgroup *ngIf="companyTemplates && companyTemplates.length > 0" [label]="'Company Templates'">
                <mat-option *ngFor="let template of companyTemplates" [value]="template">{{ template.name }}</mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-error>Please select a report</mat-error>
          </mat-form-field>

          <span>is logged and sent into this project</span>

          <button *ngIf="!readOnly" mat-icon-button class="ml-auto" (click)="deleteNotificationRule(index)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <div class="flex mt-2 justify-content-between">
    <button [disabled]="readOnly" primary-button [type]="'outline'" (click)="addEmptyNotificationRule()">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2511_17109)">
          <path
            d="M16 7.99962C16 8.51115 15.5885 8.92269 15.0769 8.92269H8.92308V15.0765C8.92308 15.5862 8.50962 16 8 16C7.49039 16 7.07692 15.5881 7.07692 15.0765V8.92269H0.923077C0.413462 8.92269 0 8.50962 0 8C0 7.49192 0.413462 7.07654 0.923077 7.07654H7.07692V0.922692C7.07692 0.413077 7.49039 0 8 0C8.50962 0 8.92308 0.413077 8.92308 0.922692V7.07654H15.0769C15.5885 7.07654 16 7.49192 16 7.99962Z"
            fill="currentcolor"
          />
        </g>
        <defs>
          <clipPath id="clip0_2511_17109">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <span>Add new rule</span>
    </button>
    <button primary-button [type]="'outline'" (click)="onSaveClicked()" [disabled]="form.invalid || form.pristine">
      <span>Save changes</span>
    </button>
  </div>
</div>
