<div class="flex justify-content-end align-items-center gap-2">
  <checkd-button
    lineHeight="30"
    [disabled]="projectIsArchived"
    fontSize="12"
    *ngIf="isVisibleAction(workflowStates.REJECTED)"
    bg="red"
    fg="white"
    (click)="doTaskAction(workflowStates.REJECTED)"
    >{{ "Reject task" | uppercase }}</checkd-button
  >
  <checkd-button
    lineHeight="30"
    [disabled]="projectIsArchived"
    fontSize="12"
    *ngIf="isVisibleAction(workflowStates.FIXED_REJECTED)"
    bg="red"
    fg="white"
    (click)="doTaskAction(workflowStates.FIXED_REJECTED)"
    >{{ "Reject fix" | uppercase }}</checkd-button
  >
  <checkd-button
    lineHeight="30"
    [disabled]="projectIsArchived"
    fontSize="12"
    *ngIf="isVisibleAction(workflowStates.FIXED)"
    bg="blue"
    fg="white"
    (click)="doTaskAction(workflowStates.FIXED)"
    >{{ "Set task as fixed" | uppercase }}</checkd-button
  >
  <checkd-button
    lineHeight="30"
    [disabled]="projectIsArchived"
    fontSize="12"
    *ngIf="isVisibleAction(workflowStates.FIXED_ACCEPTED)"
    bg="green"
    fg="white"
    (click)="doTaskAction(workflowStates.FIXED_ACCEPTED)"
    >{{ "Accept fix" | uppercase }}</checkd-button
  >
  <checkd-button
    lineHeight="30"
    [disabled]="projectIsArchived"
    fontSize="12"
    *ngIf="isVisibleAction(workflowStates.CLOSED)"
    bg="green"
    fg="white"
    (click)="doTaskAction(workflowStates.CLOSED)"
    >{{ "Accept Fix" | uppercase }}</checkd-button
  >
  <checkd-button
    lineHeight="30"
    [disabled]="projectIsArchived"
    fontSize="12"
    *ngIf="isVisibleAction(workflowStates.ACCEPTED)"
    bg="green"
    fg="white"
    (click)="doTaskAction(workflowStates.ACCEPTED)"
    >{{ "Accept task" | uppercase }}</checkd-button
  >
</div>
