import { TableFor } from "@items"

// 'select' and 'menu' column are added manually in item-list.component.ts if the
// selectItemsColumn and menuColumn properties are set to true in the default table configs
type TColumnDefinitions =
  | "numberAndStatus"
  | "itemNumber"
  | "name"
  | "itemDescription"
  | "tags"
  | "itemCreatorName"
  | "taskAssignerName"
  | "numberOfCollaborators"
  | "taskAssignerCompanyName"
  | "createdAt"
  | "updatedAt"
  | "taskAssigneeName"
  | "drawingName"
  | "projectName"
  | "dueDate"
  | "reportName"
  | "latestMessage"

export interface IColumnDefinition {
  readonly columnDef: TColumnDefinitions
  // What's displayed on top of the column and in the column selection menu
  readonly columnName: string
  // In some cases, columnName doesn't match with what we want to display in the column selection menu.
  // In that case, we define the name for the column selection menu here.
  readonly displayName?: string
}

export const allItemTableColumns: Readonly<{ [key in TColumnDefinitions]: IColumnDefinition }> = {
  numberAndStatus: { columnDef: "numberAndStatus", columnName: "#", displayName: "Number and status" },
  itemNumber: { columnDef: "itemNumber", columnName: "#", displayName: "Item number" },
  name: { columnDef: "name", columnName: "Title of item" },
  itemDescription: { columnDef: "itemDescription", columnName: "Description" },
  tags: { columnDef: "tags", columnName: "Tags" },
  itemCreatorName: { columnDef: "itemCreatorName", columnName: "Created by" },
  taskAssignerName: { columnDef: "taskAssignerName", columnName: "Assigned by" },
  taskAssignerCompanyName: { columnDef: "taskAssignerCompanyName", columnName: "Assigner company" },
  numberOfCollaborators: { columnDef: "numberOfCollaborators", columnName: "Collaborators" },
  latestMessage: { columnDef: "latestMessage", columnName: "Latest Message" },
  createdAt: { columnDef: "createdAt", columnName: "Created at" },
  updatedAt: { columnDef: "updatedAt", columnName: "Updated at" },
  dueDate: { columnDef: "dueDate", columnName: "Due at" },
  taskAssigneeName: { columnDef: "taskAssigneeName", columnName: "Assigned to" },
  drawingName: { columnDef: "drawingName", columnName: "Drawing" },
  projectName: { columnDef: "projectName", columnName: "Project" },
  reportName: { columnDef: "reportName", columnName: "Report" },
}

export interface IDefaultTableConfig {
  // Defines whether the user can select rows and perform item actions
  readonly allowItemActions: boolean

  // whether to add the select items column. Added manually in item-list.component.ts and always on the left
  readonly selectItemsColumn: boolean

  // This column is sticky end, so it's added manually if needed in item-list.component.ts
  readonly menuColumn: boolean

  // defines whether to show the column selection menu
  readonly columnsEditable: boolean

  // defaultColumns defines all the available columns in the table for that page,
  // the default order, and whether those columns are visible by default.
  readonly defaultColumns: IColumnConfig[]
  readonly pageSizes: number[]
  readonly pageSize: number
}

export interface IColumnConfig {
  checked: boolean
  column: IColumnDefinition
}

export interface IUserTableConfig {
  columns: IColumnConfig[]
  pageSize: number
}

export interface IUserTableConfigDBFormat {
  pageSize: number
  columns: { checked: boolean; columnDef: string }[]
}

const defaultPageSizes = [15, 30, 50, 75]
const defaultPageSize = defaultPageSizes[1]

const projectItemsConfig: Readonly<IDefaultTableConfig> = {
  allowItemActions: false,
  columnsEditable: true,
  selectItemsColumn: true,
  menuColumn: true,
  defaultColumns: [
    { checked: true, column: allItemTableColumns.numberAndStatus },
    { checked: false, column: allItemTableColumns.itemNumber },
    { checked: true, column: allItemTableColumns.name },
    { checked: false, column: allItemTableColumns.itemDescription },
    { checked: false, column: allItemTableColumns.latestMessage },
    { checked: true, column: allItemTableColumns.tags },
    { checked: true, column: allItemTableColumns.itemCreatorName },
    { checked: true, column: allItemTableColumns.taskAssignerName },
    { checked: false, column: allItemTableColumns.numberOfCollaborators },
    { checked: true, column: allItemTableColumns.taskAssignerCompanyName },
    { checked: true, column: allItemTableColumns.drawingName },
    { checked: true, column: allItemTableColumns.createdAt },
    { checked: true, column: allItemTableColumns.dueDate },
    { checked: true, column: allItemTableColumns.taskAssigneeName },
    { checked: true, column: allItemTableColumns.reportName },
  ],
  pageSizes: defaultPageSizes,
  pageSize: defaultPageSize,
}

const projectDrawingConfig: Readonly<IDefaultTableConfig> = {
  allowItemActions: true,
  columnsEditable: true,
  selectItemsColumn: true,
  menuColumn: true,
  defaultColumns: [
    { checked: true, column: allItemTableColumns.numberAndStatus },
    { checked: false, column: allItemTableColumns.itemNumber },
    { checked: true, column: allItemTableColumns.name },
    { checked: false, column: allItemTableColumns.itemDescription },
    { checked: false, column: allItemTableColumns.latestMessage },
    { checked: true, column: allItemTableColumns.tags },
    { checked: true, column: allItemTableColumns.itemCreatorName },
    { checked: false, column: allItemTableColumns.taskAssignerName },
    { checked: false, column: allItemTableColumns.numberOfCollaborators },
    { checked: false, column: allItemTableColumns.taskAssignerCompanyName },
    { checked: false, column: allItemTableColumns.dueDate },
    { checked: true, column: allItemTableColumns.taskAssigneeName },
    { checked: true, column: allItemTableColumns.createdAt },
    { checked: false, column: allItemTableColumns.reportName },
  ],
  pageSizes: defaultPageSizes,
  pageSize: defaultPageSize,
}

const legacyReportConfig: Readonly<IDefaultTableConfig> = {
  allowItemActions: false,
  columnsEditable: false,
  selectItemsColumn: false,
  menuColumn: true,
  defaultColumns: [
    { checked: true, column: allItemTableColumns.numberAndStatus },
    { checked: true, column: allItemTableColumns.name },
    { checked: true, column: allItemTableColumns.tags },
    { checked: true, column: allItemTableColumns.itemCreatorName },
    { checked: true, column: allItemTableColumns.taskAssignerName },
    { checked: true, column: allItemTableColumns.taskAssignerCompanyName },
    { checked: true, column: allItemTableColumns.createdAt },
    { checked: true, column: allItemTableColumns.updatedAt },
    { checked: true, column: allItemTableColumns.taskAssigneeName },
    { checked: true, column: allItemTableColumns.projectName },
  ],
  pageSizes: defaultPageSizes,
  pageSize: defaultPageSize,
}

const myItemsConfig: Readonly<IDefaultTableConfig> = {
  allowItemActions: false,
  columnsEditable: true,
  selectItemsColumn: false,
  menuColumn: true,
  defaultColumns: [
    { checked: true, column: allItemTableColumns.numberAndStatus },
    { checked: true, column: allItemTableColumns.name },
    { checked: false, column: allItemTableColumns.itemDescription },
    { checked: true, column: allItemTableColumns.tags },
    { checked: true, column: allItemTableColumns.itemCreatorName },
    { checked: true, column: allItemTableColumns.taskAssignerName },
    { checked: true, column: allItemTableColumns.taskAssignerCompanyName },
    { checked: false, column: allItemTableColumns.itemNumber },
    { checked: false, column: allItemTableColumns.latestMessage },
    { checked: false, column: allItemTableColumns.reportName },
    { checked: false, column: allItemTableColumns.numberOfCollaborators },
    { checked: true, column: allItemTableColumns.createdAt },
    { checked: true, column: allItemTableColumns.dueDate },
    { checked: true, column: allItemTableColumns.taskAssigneeName },
    { checked: true, column: allItemTableColumns.drawingName },
    { checked: true, column: allItemTableColumns.projectName },
  ],
  pageSizes: [...defaultPageSizes],
  pageSize: defaultPageSize,
}

export const getDefaultTable = (forTable: TableFor): IDefaultTableConfig => {
  switch (forTable) {
    case "projectItems":
      return { ...projectItemsConfig }
    case "drawings":
      return { ...projectDrawingConfig }
    case "reports":
      return { ...legacyReportConfig }
    case "myItems":
      return { ...myItemsConfig }
    default:
      throw Error(`${forTable} is an unknown default table`)
  }
}

export const transformToDBFormat = (config: IUserTableConfig): IUserTableConfigDBFormat => {
  const pageSize = config.pageSize
  const columnDefs = config.columns.map((x) => {
    return { checked: x.checked, columnDef: x.column.columnDef }
  })

  return { pageSize, columns: columnDefs }
}

export const transformFromDBFormat = (config: IUserTableConfigDBFormat): IUserTableConfig => {
  const pageSize = config.pageSize

  const columns = config.columns.map((column) => {
    // @ts-ignore
    return { checked: column.checked, column: { ...allItemTableColumns[column.columnDef] } }
  })

  return { pageSize, columns }
}
