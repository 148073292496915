<p-confirmDialog [breakpoints]="{ '600px': '95vw' }" [style]="{ width: '50vw' }" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="flex w-full menu-bar flex-column align-items-center justify-content-center shadow-3">
  <h1 class="min-w-0 m-0 overflow-hidden white-space-nowrap text-overflow-ellipsis menu-bar__title">
    <ng-container *ngIf="userService.currentCompany$ | async as currentCompany">{{ currentCompany.name }}</ng-container> Form Templates
  </h1>

  <div class="mx-2 my-3">
    <span (click)="activeTab$.next('active')" class="cursor-pointer" [class.bottom-border]="activeTab$.value == 'active'"
      >Active ({{ ((activeTemplates$ | async) || []).length }})</span
    >
    <span (click)="activeTab$.next('draft')" class="mx-4 cursor-pointer" [class.bottom-border]="activeTab$.value == 'draft'"
      >Draft ({{ ((draftTemplates$ | async) || []).length }})</span
    >
    <span (click)="activeTab$.next('archived')" class="cursor-pointer" [class.bottom-border]="activeTab$.value == 'archived'"
      >Archived ({{ ((archivedTemplates$ | async) || []).length }})</span
    >
  </div>
</div>

<ng-template #loadingState>
  <div class="skeleton">
    <div class="skeleton__table-header">
      <p-skeleton height="50px"></p-skeleton>
      <p-skeleton height="50px"></p-skeleton>
      <p-skeleton height="50px"></p-skeleton>
      <p-skeleton height="50px"></p-skeleton>
      <p-skeleton height="50px"></p-skeleton>
      <p-skeleton height="50px"></p-skeleton>
      <p-skeleton height="50px"></p-skeleton>
    </div>

    <div class="skeleton__table-body">
      <hr class="skeleton__line" />
      <div class="skeleton__body-row">
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
      </div>
      <hr class="skeleton__line" />
      <div class="skeleton__body-row">
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
      </div>
      <hr class="skeleton__line" />
      <div class="skeleton__body-row">
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
      </div>
      <hr class="skeleton__line" />
      <div class="skeleton__body-row">
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
      </div>
      <hr class="skeleton__line" />
      <div class="skeleton__body-row">
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
      </div>
      <hr class="skeleton__line" />
      <div class="skeleton__body-row">
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
      </div>
      <hr class="skeleton__line" />
      <div class="skeleton__body-row">
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
      </div>
      <hr class="skeleton__line" />
      <div class="skeleton__body-row">
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
      </div>
      <hr class="skeleton__line" />
      <div class="skeleton__body-row">
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
      </div>
      <hr class="skeleton__line" />
      <div class="skeleton__body-row">
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
        <p-skeleton height="30px"></p-skeleton>
      </div>
    </div>
  </div>
</ng-template>

<div class="p-2 content">
  <p-card styleClass="p-card-component">
    <div class="buttons__container">
      <p-selectButton [options]="optionButtons" [(ngModel)]="selectedFilterType" styleClass="optionButtons">
        <ng-template let-item pTemplate>
          <i
            *ngIf="item.value != 'all'"
            [ngClass]="{
              'buttons__btn-icon--blue': item.value == 'created',
              'buttons__btn-icon--green': item.value == 'added'
            }"
            [class]="item.icon"
            class="buttons__btn-icon"
          ></i>
          <span>{{ item.name }}</span>
        </ng-template>
      </p-selectButton>

      <div class="buttons__search-bar">
        <i class="buttons__search-icon pi pi-search"></i>
        <input
          (input)="dataViewFilter$.next($event.target.value)"
          type="text"
          pInputText
          placeholder="Search for templates"
          class="buttons__search-input"
        />
      </div>

      <button
        fxHide.sm
        fxHide.lt-sm
        primary-button
        routerLink="/forms/builder/"
        [disabled]="!(currentCompanyCanUseFormsBuilder$ | async)"
        type="button"
      >
        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3910_12557)">
            <path
              d="M24 11.9994C24 12.7667 23.3827 13.384 22.6154 13.384H13.3846V22.6148C13.3846 23.3792 12.7644 24 12 24C11.2356 24 10.6154 23.3821 10.6154 22.6148V13.384H1.38462C0.620192 13.384 0 12.7644 0 12C0 11.2379 0.620192 10.6148 1.38462 10.6148H10.6154V1.38404C10.6154 0.619615 11.2356 0 12 0C12.7644 0 13.3846 0.619615 13.3846 1.38404V10.6148H22.6154C23.3827 10.6148 24 11.2379 24 11.9994Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_3910_12557">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <span>New forms template</span>
      </button>

      <button
        fxHide.gt-sm
        primary-button
        [isRounded]="true"
        routerLink="/forms/builder/"
        [disabled]="!(currentCompanyCanUseFormsBuilder$ | async)"
        type="button"
        class="buttons__template-btn-mini"
        icon="pi pi-plus-circle"
      >
        <svg width="24" height="24" viewBox="24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2511_16739)">
            <path
              d="M10.875 16.125V13.125H7.875C7.25156 13.125 6.75 12.6234 6.75 12C6.75 11.3766 7.25156 10.875 7.875 10.875H10.875V7.875C10.875 7.25156 11.3766 6.75 12 6.75C12.6234 6.75 13.125 7.25156 13.125 7.875V10.875H16.125C16.7484 10.875 17.25 11.3766 17.25 12C17.25 12.6234 16.7484 13.125 16.125 13.125H13.125V16.125C13.125 16.7484 12.6234 17.25 12 17.25C11.3766 17.25 10.875 16.7484 10.875 16.125ZM24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12ZM12 2.25C6.61406 2.25 2.25 6.61406 2.25 12C2.25 17.3859 6.61406 21.75 12 21.75C17.3859 21.75 21.75 17.3859 21.75 12C21.75 6.61406 17.3859 2.25 12 2.25Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_2511_16739">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>

    <ng-container *ngIf="!isLoading; else loadingState">
      <p-table
        *ngIf="hasTemplates$ | async; else emptyState"
        [columns]="cols"
        [value]="filteredTemplateList$ | async"
        [scrollable]="true"
        scrollDirection="vertical"
        styleClass="p-datatable-sm table"
        [rowTrackBy]="trackRowByUid"
        [paginator]="true"
        [rows]="15"
        [rowsPerPageOptions]="[15, 30, 45]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [resizableColumns]="true"
        columnResizeMode="expand"
        responsiveLayout="stack"
      >
        <ng-template pTemplate="emptymessage">
          <h3 [attr.colspan]="cols.length" class="table__no-results">No templates matching your search</h3>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th [pSortableColumn]="col.field" [class]="col.field" *ngFor="let col of cols" pResizableColumn>
              {{ col.header }} <p-sortIcon [field]="col.field"></p-sortIcon>

              <p-columnFilter
                *ngIf="col.field == 'tags'"
                field="tags"
                class="ml-auto"
                matchMode="tagMatcher"
                display="menu"
                [showMatchModes]="false"
                [showOperator]="false"
                [showAddButton]="false"
              >
                <ng-template pTemplate="header">
                  <div class="px-3 pt-3 pb-0">
                    <span class="font-bold">Select Tags</span>
                  </div>
                </ng-template>

                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect
                    [ngModel]="value"
                    [options]="filteredTemplateTags$ | async"
                    placeholder="Select tags"
                    (onChange)="filter($event.value)"
                  >
                    <!--                    <ng-template pTemplate="selectedItems" let-value>-->
                    <!--                      <div>-->
                    <!--                        <p-tag class="mr-1" [value]="value"></p-tag>-->
                    <!--                      </div>-->
                    <!--                    </ng-template>-->
                    <ng-template let-tag pTemplate="item">
                      <p-tag [value]="tag"></p-tag>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
            </th>
            <!-- three-dot menu column -->
            <th class="context-menu-column"></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-row let-columns="columns" let-rowIndex="rowIndex">
          <ng-container *ngIf="menuItems$ | async as menuItems">
            <p-menu #threeDotMenu [popup]="true" [model]="menuItems[rowIndex]" appendTo="body"></p-menu>
            <tr>
              <td *ngFor="let col of columns" [class]="col.field" (click)="navigateToTemplateLandingPage(row)">
                <ng-container [ngSwitch]="col.field">
                  <div class="flex" *ngSwitchCase="'type'">
                    <ng-container *ngIf="row[col.field] === 'internal'; else publicTemplate">
                      <i class="pi pi-lock align-self-center"></i>
                      <span class="ml-2">{{ row[col.field] }}</span>
                      Internal
                    </ng-container>

                    <ng-template #publicTemplate>
                      <i class="pi pi-globe align-self-center public-template-color"></i>
                      <span class="ml-2 public-template-color">{{ row[col.field] }}</span>
                      Public
                    </ng-template>
                  </div>

                  <span
                    *ngSwitchCase="'name'"
                    [title]="row[col.field]"
                    class="inline-block overflow-hidden font-bold text-overflow-ellipsis white-space-nowrap"
                  >
                    {{ row[col.field] }}
                  </span>

                  <span
                    *ngSwitchCase="'description'"
                    [title]="row[col.field]"
                    class="inline-block overflow-hidden text-overflow-ellipsis white-space-nowrap"
                  >
                    {{ row[col.field] }}
                  </span>

                  <div *ngSwitchCase="'tags'" class="tags-container">
                    <p-tag
                      class="inline-block mb-1 mr-1"
                      [style]="{ 'font-size': '0.6rem' }"
                      *ngFor="let tag of row[col.field]"
                      [value]="tag"
                    ></p-tag>
                  </div>

                  <ng-container *ngSwitchCase="'updatedAt'">
                    {{ row[col.field] | date: "mediumDate" }}
                  </ng-container>
                  <span *ngSwitchCase="'publicVersion'"> {{ row[col.field] }}</span>

                  <div *ngSwitchCase="'templateCreatorCompanyName'" class="creator-company__container">
                    <i
                      [class]="'pi pi-check'"
                      [ngStyle]="{ background: templateIsCreatedByCurrentCompany(currentCompany, row) ? '#415BD8' : '#1DD890' }"
                      class="buttons__btn-icon"
                    >
                    </i>
                    <span class="inline-block overflow-hidden text-overflow-ellipsis white-space-nowrap">
                      {{ getRowAggregateDataField(col.field, row) }}
                    </span>
                  </div>
                  <!-- Change the message depending of the value -->
                  <div *ngSwitchCase="'published'">
                    <div *ngFor="let publishedOption of publishedOptions">
                      <ng-container *ngIf="row.sharedWith == TemplateSharingOption.ALL_PUBLIC_TEMPLATE_USERS">
                        <div *ngIf="publishedOption.name == 'Public'" class="published__container">
                          <img [src]="publishedOption.iconSrc" class="published__icon" />
                          <span class="published__text"> {{ publishedOption.name }} </span>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="row.sharedWith == TemplateSharingOption.COMPANY_SUBSCRIBERS">
                        <div *ngIf="publishedOption.name == 'Subscription'" class="published__container">
                          <img class="published__icon" [src]="publishedOption.iconSrc" />
                          <span class="published__text"> {{ publishedOption.name }} </span>
                        </div>
                      </ng-container>
                    </div>
                  </div>

                  <div *ngSwitchCase="'templateUpdateInfo'" class="version">
                    <div class="version__container" *ngIf="row[col.field]; else upToDate">
                      <span class="version__text"> Outdated </span>
                      <i class="version__icon pi pi-exclamation-triangle"></i>
                    </div>
                    <ng-template #upToDate>
                      <div class="version__update-container">
                        <span>Up to date</span>
                      </div>
                    </ng-template>
                  </div>

                  <ng-container *ngSwitchDefault>{{ row[col.field] }}</ng-container>
                </ng-container>
              </td>
              <td style="text-align: center" class="context-menu-column">
                <button
                  (click)="threeDotMenu.toggle($event)"
                  [disabled]="!(currentCompanyCanUseFormsBuilder$ | async)"
                  pButton
                  type="button"
                  icon="pi pi-ellipsis-v"
                  class="p-button-rounded p-button-text p-button-secondary"
                ></button>
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
    </ng-container>

    <ng-template #emptyState>
      <div class="relative flex p-4 mx-auto empty-card flex-column justify-content-center shadow-4">
        <img alt="female construction worker graphic" class="absolute image-size woman" src="assets/images/thinkingwoman.svg" />
        <img alt="male construction worker graphic" class="absolute image-size man" src="assets/images/thinkingman.svg" />
        <h3 class="m-0 text-center" *ngIf="activeTab$.value === 'active'">Need more forms?</h3>
        <h3 class="m-0 text-center" *ngIf="activeTab$.value === 'draft'">No drafts yet</h3>
        <h3 class="m-0 text-center" *ngIf="activeTab$.value === 'archived'">Nothing here?</h3>

        <p class="text-center" *ngIf="activeTab$.value === 'active'">
          Browse our Forms Library for reports, checklists & more to save time and money, improve your company's effectiveness and deliver
          great documentation to your customers.
        </p>
        <p class="text-center" *ngIf="activeTab$.value === 'draft'">
          Form templates you edit or create, but do not publish, will be visible as a draft. Start creating a form template here.
        </p>
        <p class="text-center" *ngIf="activeTab$.value === 'archived'">
          That is because you haven’t archived anything yet.
          <br />
          <br />
          Templates you don’t actively use, but may want to use again in the future may be archived. To archive, simply press the three dot
          menu of a company form template and choose archive.
        </p>

        <button
          [routerLink]="'/forms/library'"
          pButton
          type="button"
          class="p-button-raised p-button-rounded p-button-success align-self-center"
          label="Visit Forms Library"
        ></button>
      </div>
    </ng-template>
  </p-card>
</div>
