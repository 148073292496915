<div>
  <legacy-template-list
    [userCompany]="userCompany"
    [projectCompany]="projectCompany"
    [project]="data?.project"
    [currentCompanyTemplates]="currentCompanyTemplates"
    [projectTemplates]="projectTemplates"
    (onTemplateSelected)="templateSelected($event)"
  >
  </legacy-template-list>
</div>
