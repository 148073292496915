import { COLLECTIONS } from "../collections.interface"
import { LABELS } from "../relation.interface"
import { Role } from "./role"
import { RoleType } from "./roles-and-permissions.interface"

export class CompanyNormalRole extends Role {
  setupRoleData() {
    this.roleData = {
      name: "Company normal user",
      description: "A person who is part of a company, with no administrator privileges",
      roleType: RoleType.COMPANY_NORMAL,
      requirements: {
        sourceCollection: COLLECTIONS.PEOPLE,
        labels: [LABELS.DEFAULT],
        targetCollection: COLLECTIONS.COMPANIES,
      },

      targetPermissions: { create: false, read: true, update: false, delete: false },

      permissions: [],

      inferredRoleTypes: {},
    }
  }
}
