import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"
import { FilestackMetadataOptions, FilestackUploadData, FilestackUploadResult, ImageData, PdfDocInfo } from "@models/common"
import { SnackbarService } from "@services/snackbar.service"
import * as filestack from "filestack-js"
import urlJoin from "url-join"
import { environment } from "../../../environments/environment"
import { DialogService } from "../dialogs/dialog.service"

type ResizeFit = "clip" | "crop" | "scale" | "max"
type ResizeAlign =
  | "top"
  | "bottom"
  | "left"
  | "right"
  | "faces"
  | "center"
  | "['top','left']"
  | "['top','right']"
  | "['bottom','left']"
  | "['bottom','right']"

type storeLocation = "gcs"
type storePaths = "images/" | "drawings/" | "signatures/" | "avatars/" | "companyLogos/"

@Injectable()
export class FilestackService {
  public client: any
  private defaultConfig: any = {
    imageMax: [5000, 5000],
    maxFiles: 20,
    fromSources: ["local_file_system", "box", "googledrive", "onedrive"],
    accept: [".pdf", "image/jpeg", "image/png"],
  }

  private imagesConfig: any = {
    accept: ["image/jpeg", "image/png"],
    storeTo: {
      location: "gcs",
      path: "images/",
    },
  }

  private signatureStoreConfig: any = {
    filename: "signature.png",
    path: "/signatures",
  }

  private base64StoreConfig: any = {
    filename: "default.png",
    path: "/images",
    base64decode: true,
  }

  private apiKey: string

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private snackbarService: SnackbarService,
    private dialogService: DialogService
  ) {
    this.apiKey = environment.FILESTACK_KEY
    this.client = filestack.init(this.apiKey)
  }

  // Stores to cloud storage bucket
  async pickImages(): Promise<FilestackUploadResult> {
    return this.pick(this.imagesConfig)
  }

  async pick(config: { storeTo: { location: storeLocation; path: storePaths }; [key: string]: any }): Promise<FilestackUploadResult> {
    return new Promise((resolve, reject) => {
      const onUploadDone = async (response: FilestackUploadResult) => {
        if ((response.filesFailed || []).length > 0) {
          await this.dialogService.showFailedUploadsDialog(
            response.filesUploaded.map((file) => file.filename),
            response.filesFailed!.map((file) => file.filename)
          )
        }
        resolve(response)
      }

      try {
        this.client
          .picker({
            ...this.defaultConfig,
            ...config,
            onUploadDone,
          })
          .open()
      } catch (err) {
        reject(err.message)
      }
    })
  }

  async uploadBase64Image(base64Data: string, options: any = {}) {
    const result = await this.client.upload(base64Data, {}, { ...this.base64StoreConfig, ...options })

    return result
  }

  metadata(handle: string, options: FilestackMetadataOptions) {
    return this.client.metadata(handle, options)
  }

  /**
   * Duplicates an image pointed to by a filestack url. This is a hack
   * as I couldn't find any proper way of hard copying a file.
   *
   * @param url a filestack URL
   * @param filename the name of the new file
   */
  duplicateImageUrl(url: string, filename: string) {
    const duplicationUrl = `${this.getBaseUrl()}sharpen=amount:0/store/${url}`

    return this.http.get(duplicationUrl).toPromise()
  }

  uploadSignature(file: any) {
    return this.client.upload(file, {}, this.signatureStoreConfig)
  }

  uploadDrawingReportSignature(file: string) {
    return this.client.upload(
      file,
      {},
      {
        path: "drawingReportSignatures/",
        filename: "drawingReportSignature.png",
      }
    )
  }

  getPdfDocinfo(file: FilestackUploadData): Promise<PdfDocInfo> {
    const url = `https://cdn.filestackcontent.com/output=docinfo:true/${file.handle}`

    return this.http.get(url).toPromise() as Promise<PdfDocInfo>
  }

  getPdfPageInfo(pdfPageUrl: string): Promise<PdfDocInfo> {
    const url = `${this.getBaseUrl()}output=docinfo:true/${pdfPageUrl}`

    return this.http.get(url).toPromise() as Promise<PdfDocInfo>
  }

  async getPdfPages(file: FilestackUploadData): Promise<{ url: string; width: number; height: number }[]> {
    const docInfo = await this.getPdfDocinfo(file)
    const pdfPageUrls = []

    for (let i = 1; i <= docInfo.numpages; i++) {
      const pdfPageUrl = `https://process.filestackapi.com/output=format:png,page:${i}/${file.handle}`
      const pageInfo = await this.getPdfPageInfo(pdfPageUrl)
      const page = {
        width: pageInfo.dimensions.width,
        height: pageInfo.dimensions.height,
        url: pdfPageUrl,
      }
      pdfPageUrls.push(page)
    }

    return Promise.resolve(pdfPageUrls)
  }

  getExifRotatedImage(filestackHandle: string) {
    const processedUrl = urlJoin(this.getBaseUrl(), "rotate=deg:exif", "store", filestackHandle)

    return this.http.get(processedUrl).toPromise()
  }

  async createImageDataFromUploadData(uploadData: FilestackUploadData): Promise<ImageData> {
    const metadata = await this.metadata(uploadData.handle, { width: true, height: true })

    return {
      name: uploadData.filename,
      mimetype: uploadData.mimetype,
      url: uploadData.url,
      width: metadata.width,
      height: metadata.height,
    } as ImageData
  }

  getThumbnailUrl(url: string, width: number, sanitize = true, format = "jpg", fit: ResizeFit = "max", align: ResizeAlign = "center") {
    // Note: getBaseUrl adds the first a slash at the end of the string, so it's not added to rotateOpts
    const baseURL = this.getBaseUrl()
    const rotateOpts = "rotate=deg:exif"
    const resizeOpts = `/resize=width:${width},height:${width},fit:${fit},align:${align}`

    const partialURL = `${baseURL}${rotateOpts}${resizeOpts}`

    if (!sanitize) {
      return `${partialURL}/output=format:${format}/${url}`
    }

    return this.sanitizer.bypassSecurityTrustUrl(`${partialURL}/output=format:png/${url}`)
  }

  getExifRotationUrl(url: string) {
    return urlJoin(this.getBaseUrl(), "rotate=deg:exif", url)
  }

  getCompressedImage(url: string, sanitize = true) {
    if (!sanitize) {
      return `${this.getBaseUrl()}compress/${url}`
    }

    return this.sanitizer.bypassSecurityTrustUrl(`${this.getBaseUrl()}compress/${url}`)
  }

  getBaseUrl(): string {
    return `https://process.filestackapi.com/${this.apiKey}/`
  }

  async uploadLogo() {
    const maxSizeInMB = 1
    const uploadResult = await this.pick({
      accept: ["image/png", "image/jpeg"],
      maxSize: maxSizeInMB * 1024 * 1024,
      storeTo: { location: "gcs", path: "companyLogos/" },
    })

    const isFailedUpload =
      (uploadResult.filesFailed != null && uploadResult.filesFailed.length > 0) ||
      uploadResult.filesUploaded == null ||
      uploadResult.filesUploaded.length < 1 ||
      uploadResult.filesUploaded[0] == null ||
      uploadResult.filesUploaded[0].url == null

    if (isFailedUpload) {
      this.snackbarService.showMessage("An error occurred while trying to upload your logo.")

      return null
    }

    return uploadResult.filesUploaded[0].url
  }
}
