<ng-template #imageDivs>
  <div *ngFor="let image of _album | slice:0:MAX_IMAGES; let i = index">
    <img
      [src]="image.thumb"
      [width]="THUMBNAIL_SIZE"
      (click)="open(i)"
      [height]="THUMBNAIL_SIZE"/>
  </div>
</ng-template>

<div class="thumbnail-container" *ngIf="images && images.length > 0 && !hasHiddenThumbnails"
     fxLayout="row" fxLayoutAlign="start center" [style.max-height.px]="THUMBNAIL_SIZE">

  <ng-container *ngTemplateOutlet="imageDivs"></ng-container>
</div>

<div class="thumbnail-container" *ngIf="images && images.length > 0 && hasHiddenThumbnails"
     [matBadge]=badgeLabel matBadgeSize="medium"
     fxLayout="row" fxLayoutAlign="start center" [style.max-height.px]="THUMBNAIL_SIZE">

  <ng-container *ngTemplateOutlet="imageDivs"></ng-container>
</div>

<span class="no-images-text" *ngIf="images && images.length == 0">No images</span>


