import { Directive, EventEmitter, Input, NgZone, OnInit } from "@angular/core"
import { LeafletDirective } from "@asymmetrik/ngx-leaflet"
import * as L from "leaflet"
import { LatLng, LatLngBounds, Map, Marker, Point } from "leaflet"
import { LeafletService } from "../../services"
import { ItemMarkerOptions } from "../checkd-drawing/checkd-drawing.component"

interface LeafletClickEvent {
  containerPoint: Point
  latlng: LatLng
  layerPoint: Point
  originalEvent: any
  target: any
  type: string
  [propName: string]: any
}

@Directive({
  selector: "[leafletCheckdDrawing]",
  outputs: ["clickInsideBounds", "clickOutsideBounds", "addItemGesture", "markerMoved"],
})
export class LeafletCheckdDrawingDirective implements OnInit {
  @Input() drawingBounds: LatLngBounds

  @Input() enableGestureToAddItemMarker: boolean
  @Input() maxAddableItemMarkersWithGesture: number
  @Input() initializeMarkerInMiddle = false

  leafletDirective: LeafletDirective

  clickInsideBounds = new EventEmitter()
  clickOutsideBounds = new EventEmitter()
  addItemGesture = new EventEmitter()
  markerMoved = new EventEmitter()

  private icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    iconUrl: "assets/marker-icon.png",
    shadowUrl: "assets/marker-shadow.png",
  })

  constructor(protected leafletService: LeafletService, leafletDirective: LeafletDirective, private zone: NgZone) {
    this.leafletDirective = leafletDirective
    this.initialize()
  }

  ngOnInit() {
    if (this.initializeMarkerInMiddle) {
      this.addMarkerAtLocation(this.drawingBounds.getCenter(), this.leafletDirective.getMap())
    }
  }

  initialize() {
    this.leafletDirective.mapReady.subscribe((map: Map) => {
      if (this.enableGestureToAddItemMarker) {
        map.addEventListener("contextmenu", (event: LeafletClickEvent) => this.handleClick(event, map))
      }
    })
  }

  handleClick(event: LeafletClickEvent, map: Map) {
    if (this.isClickWithinBounds(event, map)) {
      this.clickInsideBounds.emit(this.normalizeClickCoords(event, map))
      this.addMarker(event, map)

      if (this.maxAddableItemMarkersWithGesture <= 1) {
        map.removeEventListener("contextmenu")
      }
    } else {
      this.clickOutsideBounds.emit(this.normalizeClickCoords(event, map))
    }
  }

  // Returns true if the click coordinates are inside the bounds of
  // the drawing/map, false otherwise
  isClickWithinBounds(event: LeafletClickEvent, map: Map): boolean {
    let bounds = this.drawingBounds
    return bounds.contains(event.latlng)
  }

  // Returns an object with x and y attributes between 0.0 and 1.0, e.g.:
  //
  // {x: 0.0, y: 0.0} is the upper left corner of the image/map
  // {x: 1.0, y: 1.0} is the bottom right corner of the image/map
  private normalizeClickCoords(event: LeafletClickEvent, map: Map): { x: number; y: number } {
    const bounds = this.drawingBounds

    return this.leafletService.normalizeLatLngCoordinates(event.latlng, bounds.getNorthWest(), bounds.getSouthEast())
  }

  private normalizeCoords(position: LatLng, map: Map): { x: number; y: number } {
    const bounds = this.drawingBounds

    return this.leafletService.normalizeLatLngCoordinates(position, bounds.getNorthWest(), bounds.getSouthEast())
  }

  addMarkerAtLocation(coords: LatLng, map: Map) {
    const options = {
      icon: this.leafletService.statusToIconHtml("open"),
      draggable: true,
    } as ItemMarkerOptions
    const marker = new L.Marker(coords, options).addTo(map)

    this.preventMarkerToGoOutOfBounds(marker, map)
    // @ts-ignore
    marker.addEventListener("moveend", (dragEvent: LeafletClickEvent) => {
      this.zone.run(() => {
        this.markerMoved.emit(Object.assign({ layer: marker }, this.normalizeCoords(marker.getLatLng(), this.leafletDirective.getMap())))
      })
    })
    this.zone.run(() => {
      this.addItemGesture.emit(Object.assign({ layer: marker }, this.normalizeCoords(coords, map)))
    })
  }

  addMarker(event: LeafletClickEvent, map: Map) {
    const options = {
      icon: this.leafletService.statusToIconHtml("open"),
      draggable: true,
    } as ItemMarkerOptions
    const marker = new L.Marker(event.latlng, options).addTo(map)

    this.preventMarkerToGoOutOfBounds(marker, map)
    // @ts-ignore
    marker.addEventListener("moveend", (dragEvent: LeafletClickEvent) => {
      this.zone.run(() => {
        this.markerMoved.emit(Object.assign({ layer: marker }, this.normalizeCoords(marker.getLatLng(), this.leafletDirective.getMap())))
      })
    })
    this.zone.run(() => {
      this.addItemGesture.emit(Object.assign({ layer: marker }, this.normalizeClickCoords(event, map)))
    })
  }

  private preventMarkerToGoOutOfBounds(marker: Marker, map: Map) {
    let lastValidPosition = marker.getLatLng()
    const bounds = this.drawingBounds
    marker.addEventListener("drag", (event) => {
      let newPosition = marker.getLatLng()
      if (bounds.contains(newPosition)) lastValidPosition = newPosition
      else marker.setLatLng(lastValidPosition)
    })
  }
}
