import { DatePipe } from "@angular/common"
import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
  name: "fieldChatDate",
  pure: false,
})
export class FieldChatDatePipe implements PipeTransform {
  readonly oneDay = 1000 * 60 * 60 * 24
  readonly oneWeek = this.oneDay * 7

  constructor(private datePipe: DatePipe) {}

  transform(value: string): string {
    if (!value) {
      return ""
    }

    const now = Date.now()
    const givenDate = new Date(value).getTime()

    if (now - givenDate < this.oneDay) {
      return this.datePipe.transform(givenDate, "HH:mm") ?? ""
    } else if (now - givenDate < this.oneWeek) {
      return this.datePipe.transform(givenDate, "EEE") ?? ""
    } else {
      return this.datePipe.transform(givenDate, "d LLL yyyy") ?? ""
    }
  }
}
