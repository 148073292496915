import { Injectable } from "@angular/core"
import { ModalImage } from "@ks89/angular-modal-gallery/"
import { COLLECTIONS, Item, Notification, Person, Task, User } from "@models/common"
import { ModelService } from "@services/model.service"
import { RelationService } from "@services/relation.service"
import { RoleHandlerService } from "@services/role-handler.service"
import { map } from "rxjs/operators"
import { DialogService } from "../../dialogs/dialog.service"
import { ProjectInvitationReceivedDialogComponent } from "../../dialogs/project-invitation-recieved-dialog/project-invitation-received-dialog.component"
import { ItemDialogService } from "../../items/item-dialog.service"

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(
    private modelService: ModelService,
    private relationService: RelationService,
    private itemDialogService: ItemDialogService,
    private dialogService: DialogService,
    private roleService: RoleHandlerService
  ) {}

  listenToNotificationsForUser(user: User, limit = 100) {
    return this.modelService.queryAndListen({
      collection: COLLECTIONS.NOTIFICATIONS,
      modelData: { receiverUid: user.uid },
      orderBy: { createdAt: "desc" },
      limit,
    })
  }

  listenToUnreadNotificationCountForUser(user: User) {
    return this.modelService
      .queryAndListen({
        collection: COLLECTIONS.NOTIFICATIONS,
        modelData: {
          receiverUid: user.uid,
          isOpened: false,
        },
      })
      .pipe(map((it) => it.length))
  }

  async markAllAsReadForUser(user: User) {
    return Notification.markAllAsReadForUser(user.uid)
  }

  async markAsRead(notifications: Notification[]) {
    return Promise.all(notifications.map((notification) => notification.markAsRead()))
  }

  async openItemFromNotification(opener: Person, itemUid: string) {
    const item = await Item.get(itemUid)

    // @ts-ignore
    return this.itemDialogService.openItem(item, null, null, null)
  }

  async openTaskFromNotification(opener: Person, taskUid: string) {
    const task = await Task.get(taskUid)
    const [item] = await task.getTargets(COLLECTIONS.ITEMS) // this.relationService.listenToTargets(task, COLLECTIONS.ITEMS).toPromise()

    return this.openItemFromNotification(opener, item.uid)
  }

  openNotificationTarget(opener: Person, notification: Notification) {
    const components = notification.targetPathComponents()
    if (components == null) {
      return undefined
    }

    switch (components.collection) {
      case COLLECTIONS.ITEMS: {
        return this.openItemFromNotification(opener, components.uid)
      }
      case COLLECTIONS.TASKS: {
        return this.openTaskFromNotification(opener, components.uid)
      }
      case COLLECTIONS.INVITATIONS: {
        return this.dialogService.openDialog(ProjectInvitationReceivedDialogComponent, { data: { notification }, maxWidth: "600px" })
      }
      default: {
        return undefined
      }
    }
  }
}
