import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms"
import { combineLatest, Observable, of, pipe, Subscription } from "rxjs"
import { combineLatestWith, map, switchMap, tap } from "rxjs/operators"
import { Channel } from "stream-chat"
import { ChannelService, DefaultStreamChatGenerics } from "stream-chat-angular"
import { FieldChatViewService, IFieldChatProjectData, IFieldChatUserData, UserService } from "../../services"

@Component({
  selector: "checkd-add-members-dialog",
  templateUrl: "./add-members-dialog.component.html",
  styleUrls: ["./add-members-dialog.component.scss"],
})
export class AddMembersDialogComponent implements OnInit, OnDestroy {
  isLoading = true
  private subscriptions = new Subscription()

  users$: Observable<IFieldChatUserData[]> = combineLatest([
    this.getListenerForChannelTypeUsers().pipe(this.naturalSortUsers()),
    this.userService.currentUser$,
  ]).pipe(
    map(([users, currentUser]) => users.filter((user) => user.uid !== currentUser.uid)),
    combineLatestWith(this.channelService.activeChannel$),
    tap(async ([fieldUsers, activeGetStreamChannel]) => this.setupMembersForm(fieldUsers, activeGetStreamChannel)),
    map(([users, _]) => users),
    tap((_) => (this.isLoading = false))
  )

  membersForm: UntypedFormGroup

  /**
   * Emits a list of UIDs of users selected when user clicks button to add members
   */
  @Output() onClose = new EventEmitter<string[]>()

  constructor(
    private channelService: ChannelService,
    public userService: UserService,
    public fcVS: FieldChatViewService,
    private afs: AngularFirestore,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.users$.subscribe())
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  private naturalSortUsers() {
    return pipe(
      map((users: IFieldChatUserData[]) =>
        users.sort((a, b) => (a?.name ?? "").localeCompare(b?.name ?? "", ["en", "no"], { sensitivity: "base", numeric: true }))
      )
    )
  }

  private async setupMembersForm(members: IFieldChatUserData[], activeGetStreamChannel?: Channel<DefaultStreamChatGenerics>) {
    const streamChannelMembers = (await activeGetStreamChannel?.queryMembers({})) ?? []
    // @ts-ignore
    const membersAlreadyInChatroom = new Set<string>(streamChannelMembers?.members?.map((member: any) => member.user.id).filter(Boolean))

    const membersArray = this.fb.array([])
    const newMembersForms = this.fb.group({
      members: membersArray,
    })

    for (const member of members) {
      const isMemberAlreadyInChatroom = membersAlreadyInChatroom.has(member.uid)

      const newMember = this.fb.group({
        uid: member.uid,
        name: member.name,
        checked: isMemberAlreadyInChatroom,
      })

      if (isMemberAlreadyInChatroom) {
        newMember.disable()
      }

      membersArray.push(newMember)
    }

    this.membersForm = newMembersForms
  }

  get memberArray(): UntypedFormArray | undefined {
    return this.membersForm?.get("members") as UntypedFormArray
  }

  private getListenerForChannelTypeUsers(): Observable<IFieldChatUserData[]> {
    if (this.fcVS.getCurrentlyVisibleChannelType() === "company") {
      return this.userService.userCompanyMembers$
    }

    const selectedProject = this.fcVS.getSelectedProject()
    if (selectedProject !== null) {
      return this.listenToProjectMembers(selectedProject.uid)
    }

    return of([])
  }

  private listenToProjectMembers(projectUid: string): Observable<IFieldChatUserData[]> {
    return this.afs
      .collection("projects")
      .doc<IFieldChatProjectData>(projectUid)
      .collection<{ targetPath: string }>("users", (ref) => {
        return ref.where("disabled", "==", false)
      })
      .valueChanges()
      .pipe(
        map((rels) => rels.filter(Boolean).map((rel) => rel.targetPath)),
        map((targetPaths) => targetPaths.map((path) => this.afs.doc<IFieldChatUserData>(path))),
        map((afsDocs) => afsDocs.map((afsDoc) => afsDoc.snapshotChanges())),
        switchMap((targets) => combineLatest(targets)),
        map((users) => users.filter((user) => user.payload.exists).map((user) => ({ ...user.payload.data()!, uid: user.payload.id })))
      )
  }

  onAddMembersClicked() {
    const membersArr = this.memberArray
    if (membersArr === undefined) {
      return
    }

    const memberUidsToAdd: string[] = []

    for (const memberGroup of this.memberArray?.controls ?? []) {
      const { uid, checked } = memberGroup.value
      if (memberGroup.disabled || !checked) {
        continue
      }

      memberUidsToAdd.push(uid)
    }

    if (memberUidsToAdd.length < 1) {
      return
    }

    this.onClose.emit(memberUidsToAdd)
  }
}
