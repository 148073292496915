import { CheckdColors } from "@checkd-colors"
import { LegacyTemplate } from "../legacy-template"
import { LegacyTemplateMenuActions } from "./menu-actions.interface"

// NB The action handling in the template service and components do not yet conform to this pattern.
//    This function is therefore unused as of now, but provided so that we have a reference for refactoring.
// TODO Make template actions conform to this pattern (just like e.g. project-menu-actions)

function createLegacyTemplateMenuActionData(legacyTemplate: LegacyTemplate, legacyTemplateMenuAction: LegacyTemplateMenuActions) {
  switch (legacyTemplateMenuAction) {
    case LegacyTemplateMenuActions.DUPLICATE_TEMPLATE:
      return {
        name: "Duplicate template",
        color: CheckdColors.CHECKD_DARK,
        action: LegacyTemplateMenuActions.DUPLICATE_TEMPLATE,
      }

    case LegacyTemplateMenuActions.EDIT_TEMPLATE:
      return {
        name: "Edit template",
        color: CheckdColors.CHECKD_DARK,
        action: LegacyTemplateMenuActions.EDIT_TEMPLATE,
      }

    case LegacyTemplateMenuActions.PUBLISH_TEMPLATE:
      return {
        name: "Publish template",
        color: CheckdColors.CHECKD_DARK,
        action: LegacyTemplateMenuActions.PUBLISH_TEMPLATE,
      }

    case LegacyTemplateMenuActions.UNPUBLISH_TEMPLATE:
      return {
        name: "Unpublish template",
        color: CheckdColors.CHECKD_DARK,
        action: LegacyTemplateMenuActions.UNPUBLISH_TEMPLATE,
      }

    case LegacyTemplateMenuActions.REMOVE_TEMPLATE:
      return {
        name: "Remove template",
        color: CheckdColors.CHECKD_DARK,
        action: LegacyTemplateMenuActions.REMOVE_TEMPLATE,
      }

    case LegacyTemplateMenuActions.GO_TO_QR_CODE_VIEW:
      return {
        name: "Go to QR-code view",
        color: CheckdColors.CHECKD_DARK,
        action: LegacyTemplateMenuActions.GO_TO_QR_CODE_VIEW,
      }
    case LegacyTemplateMenuActions.GET_URL_IN_CLIPBOARD:
      return {
        name: "Get URL in clipboard",
        color: CheckdColors.CHECKD_DARK,
        action: LegacyTemplateMenuActions.GET_URL_IN_CLIPBOARD,
      }

    default:
      return null
  }
}

export { createLegacyTemplateMenuActionData }
