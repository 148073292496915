import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core"
import { convertWorkflowStateToStatus } from "@models/common"

@Component({
  selector: "checkd-status-icon",
  templateUrl: "./checkd-status-icon.component.html",
  styleUrls: ["./checkd-status-icon.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckdStatusIconComponent implements OnInit {
  @Input() type: string
  @Input() number: number
  @Input() checked: boolean = false

  statusIconsPath = "assets/icons/task_status/"
  iconExt = ".png"

  ngOnInit() {}

  get statusIcon() {
    if (this.type == null) {
      return ""
    }

    const iconType = convertWorkflowStateToStatus(this.type)
    if (iconType == null) {
      return ""
    }

    const toggledStr = this.isChecked ? "toggled" : "untoggled"

    return `${this.statusIconsPath}${iconType.toLowerCase()}_${toggledStr}${this.iconExt}`
  }

  get isChecked() {
    return this.checked !== null && `${this.checked}` !== "false"
  }

  get hasNumber() {
    return this.number != null
  }

  get statusNumberClass() {
    if (!this.hasNumber) {
      return "status-number-1"
    }
    if (this.number > 999) {
      return "status-number-4"
    }
    if (this.number > 99) {
      return "status-number-3"
    }
    if (this.number > 9) {
      return "status-number-2"
    }

    return "status-number-1"
  }
}
