<div class="page-container" fxLayout="column" fxLayoutAlign="center center">
  <!--  <img src="assets/logos/next-logo-icon.svg" alt="CHECKD logo" width="80"/>-->
  <img class="checkd-logo-gray" src="assets/logos/next-logo-full-white.svg" alt="CHECKD logo" width="200" />

  <div class="login-container flex flex-column align-content-center">
    <p class="title">SIGN IN TO YOUR ACCOUNT</p>

    <div class="input-area">
      <form>
        <div class="input-field-container">
          <label>
            <input #usernameInput placeholder="Your login email address" type="email" required [(ngModel)]="email" name="email" />
            <mat-icon class="input-field-icon" svgIcon="email"></mat-icon>
          </label>
        </div>

        <div class="input-field-container">
          <label>
            <input
              #passwordInput
              placeholder="Your password"
              name="password"
              type="password"
              required
              [(ngModel)]="password"
              autocomplete="current-password"
            />
            <mat-icon class="input-field-icon" svgIcon="password"></mat-icon>
          </label>
        </div>
        <p class="forgot-your-password">
          <a href="javascript:void(0);" (click)="onForgotPassword()"> Forgot your password? </a>
        </p>

        <button
          primary-button
          isRounded="true"
          class="login-button"
          [disabled]="loginFieldsContainEmptyInputs"
          (click)="login(usernameInput.value.trim(), passwordInput.value.trim())"
        >
          SIGN IN
        </button>
      </form>
    </div>

    <hr style="width: 100%; margin-bottom: 1.5em; color: red; border-top: 1px solid rgba(0, 0, 0, 0.1)" />

    <button class="microsoft-login-button" (click)="loginWithMicrosoft()">
      <img src="assets/ms-symbollockup_signin_light.svg" />
    </button>

    <p class="footer">
      By signing in, you agree to Next One Technology's
      <a href="https://www.checkd.it/terms"><b>Terms and conditions</b></a>
      &amp;
      <a href="https://www.checkd.it/personvern-sikkerhet"><b>privacy policy</b></a>
    </p>
  </div>

  <div class="store-links-container">
    <a
      href="https://apps.apple.com/us/app/checkd-field/id1328077051"
      target="_blank"
      title="Click here to download CHECKD Field on the App Store"
      class="app-store-link"
    >
      <img class="store-link-image" src="assets/logos/app_store.svg" alt="App store logo" />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=checkd.com.fieldapp&hl=en"
      target="_blank"
      title="Click here to download CHECKD Field on the Play Store"
      class="play-store-link"
    >
      <img class="store-link-image" src="assets/logos/play_store.svg" alt="Play store logo" />
    </a>
  </div>
</div>
