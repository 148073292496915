<mat-card-header>
  <mat-card-title>Please login to continue</mat-card-title>
</mat-card-header>

<mat-card-content>
  <form fxLayout="column">
    <mat-form-field appearance="fill">
      <input #usernameInput matInput placeholder="Your login email address" type="email" required [(ngModel)]="email" name="email" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <input
        #passwordInput
        placeholder="Your password"
        name="password"
        type="password"
        required
        [(ngModel)]="password"
        autocomplete="current-password"
        matInput
      />
    </mat-form-field>
  </form>
</mat-card-content>

<mat-card-footer>
  <mat-card-actions>
    <checkd-button
      bg="green"
      fg="white"
      (buttonClick)="login(usernameInput.value.trim(), passwordInput.value.trim())"
      [disabled]="loginFieldsContainEmptyInputs"
      >Login
    </checkd-button>
  </mat-card-actions>
</mat-card-footer>
