import { NgSignaturePadOptions, SignaturePadComponent } from "@almothafar/angular-signature-pad"
import { Component, OnInit, ViewChild } from "@angular/core"
import { MatDialogRef } from "@angular/material/dialog"

@Component({
  selector: "app-signature-dialog",
  templateUrl: "./signature-dialog.component.html",
  styleUrls: ["./signature-dialog.component.scss"],
})
export class SignatureDialogComponent implements OnInit {
  @ViewChild(SignaturePadComponent, { static: true }) signaturePad: SignaturePadComponent
  signaturePadOptions: NgSignaturePadOptions = {
    // passed through to szimek/signature_pad constructor
    canvasWidth: 500,
    canvasHeight: 300,
    penColor: "rgb(74, 74, 74)",
    backgroundColor: "rgb(255, 255, 255)",
  }
  constructor(private dialogRef: MatDialogRef<SignatureDialogComponent>) {}

  ngOnInit() {}

  ngAfterViewInit() {
    // this.signaturePad is now available
    //this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
  }

  clear() {
    this.signaturePad.clear()
  }

  save() {
    let result = {
      isEmpty: this.signaturePad.isEmpty(),
      signatureImage: this.signaturePad.toDataURL(),
    }
    this.dialogRef.close(result)
  }
}
