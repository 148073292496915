import firebase from "firebase/compat/app"
import { RelationAggregateData } from "./aggregate-data"
import firestore = firebase.firestore

export interface RelationInterface {
  link: string
  labels: string[]
  disabled?: boolean
  source?: string

  // TODO Remove optionals when we're done transitioning to this format
  sourceRef?: firestore.DocumentReference
  targetRef?: firestore.DocumentReference
  sourcePath?: string
  targetPath?: string
}

export interface RelationData {
  link: string // TODO Deprecate (use targetRef/targetPath instead)
  labels: string[]
  disabled?: boolean
  archived?: boolean
  lockedByCheckd?: boolean
  source?: string // TODO Deprecate (use sourceRef/sourcePath instead)

  // TODO Remove optionals when we're done transitioning to this format
  sourceRef?: firestore.DocumentReference
  targetRef?: firestore.DocumentReference
  sourcePath?: string
  targetPath?: string
  aggregateData?: RelationAggregateData
}

export const LABELS = {
  CREATOR: "CREATOR",
  CREATED_BY: "CREATED_BY",
  OWNER: "OWNER",
  OWNED_BY: "OWNED_BY",
  ADMINISTRATOR: "ADMINISTRATOR",
  ADMINISTRATED_BY: "ADMINISTRATED_BY",
  MANAGER: "MANAGER",
  MANAGED_BY: "MANAGED_BY",
  DEFAULT: "DEFAULT",
  CONTAINS: "CONTAINS",
  CONTAINED_BY: "CONTAINED_BY",
  CONCERNS: "CONCERNS",
  CONCERNED_BY: "CONCERNED_BY",
  HAS_ASSIGNED: "HAS_ASSIGNED",
  ASSIGNED_BY: "ASSIGNED_BY",
  IS_ASSIGNED: "IS_ASSIGNED",
  ASSIGNED_TO: "ASSIGNED_TO",
  CAN_VIEW: "CAN_VIEW",
  VIEWABLE_BY: "VIEWABLE_BY",
  NONE: "NONE",
  COLLABORATOR_TO: "COLLABORATOR_TO",
  HAS_COLLABORATOR: "HAS_COLLABORATOR",
  HAS_MAIN_COMPANY: "HAS_MAIN_COMPANY",
  IS_MAIN_COMPANY_FOR: "IS_MAIN_COMPANY_FOR",
  HAS_PARENT_COMPANY: "HAS_PARENT_COMPANY",
  IS_PARENT_COMPANY_FOR: "IS_PARENT_COMPANY_FOR",
  HAS_TEMPLATE: "HAS_TEMPLATE",
  IS_TEMPLATE_FOR: "IS_TEMPLATE_FOR",
  INACTIVE: "INACTIVE",
  SUBSCRIBES_TO: "SUBSCRIBES_TO",
  SUBSCRIBED_BY: "SUBSCRIBED_BY",
  COPY_OF: "COPY_OF",
  HAS_COPY: "HAS_COPY",
}

export const INVERTED_LABELS = {
  [LABELS.DEFAULT]: LABELS.DEFAULT,
  [LABELS.COLLABORATOR_TO]: LABELS.HAS_COLLABORATOR,
  [LABELS.HAS_COLLABORATOR]: LABELS.COLLABORATOR_TO,
  [LABELS.CREATOR]: LABELS.CREATED_BY,
  [LABELS.CREATED_BY]: LABELS.CREATOR,
  [LABELS.OWNER]: LABELS.OWNED_BY,
  [LABELS.OWNED_BY]: LABELS.OWNER,
  [LABELS.ADMINISTRATOR]: LABELS.ADMINISTRATED_BY,
  [LABELS.ADMINISTRATED_BY]: LABELS.ADMINISTRATOR,
  [LABELS.MANAGER]: LABELS.MANAGED_BY,
  [LABELS.MANAGED_BY]: LABELS.MANAGER,
  [LABELS.CONTAINS]: LABELS.CONTAINED_BY,
  [LABELS.CONTAINED_BY]: LABELS.CONTAINS,
  [LABELS.CONCERNS]: LABELS.CONCERNED_BY,
  [LABELS.CONCERNED_BY]: LABELS.CONCERNS,

  // E.g. a task is assigned by a person
  [LABELS.HAS_ASSIGNED]: LABELS.ASSIGNED_BY,
  // E.g. a person has assigned a task
  [LABELS.ASSIGNED_BY]: LABELS.HAS_ASSIGNED,

  // E.g. a task is assigned to a person
  [LABELS.IS_ASSIGNED]: LABELS.ASSIGNED_TO,
  // E.g. a person is assigned a task
  [LABELS.ASSIGNED_TO]: LABELS.IS_ASSIGNED,

  // E.g. a person can view a project
  [LABELS.CAN_VIEW]: LABELS.VIEWABLE_BY,

  // E.g. a project can be viewed by a person
  [LABELS.VIEWABLE_BY]: LABELS.CAN_VIEW,

  [LABELS.HAS_MAIN_COMPANY]: LABELS.IS_MAIN_COMPANY_FOR,
  [LABELS.IS_MAIN_COMPANY_FOR]: LABELS.HAS_MAIN_COMPANY,

  [LABELS.HAS_PARENT_COMPANY]: LABELS.IS_PARENT_COMPANY_FOR,
  [LABELS.IS_PARENT_COMPANY_FOR]: LABELS.HAS_PARENT_COMPANY,

  [LABELS.HAS_TEMPLATE]: LABELS.IS_TEMPLATE_FOR,
  [LABELS.IS_TEMPLATE_FOR]: LABELS.HAS_TEMPLATE,

  [LABELS.NONE]: LABELS.NONE,

  [LABELS.INACTIVE]: LABELS.INACTIVE,

  [LABELS.SUBSCRIBES_TO]: LABELS.SUBSCRIBED_BY,
  [LABELS.SUBSCRIBED_BY]: LABELS.SUBSCRIBES_TO,

  [LABELS.COPY_OF]: LABELS.HAS_COPY,
  [LABELS.HAS_COPY]: LABELS.COPY_OF,
}
// TODO Add inverse label precedence
export const LABEL_PRECEDENCE = [
  LABELS.OWNER,
  LABELS.ADMINISTRATOR,
  LABELS.MANAGER,
  LABELS.CONTAINS,
  LABELS.CONCERNS,
  LABELS.HAS_ASSIGNED,
  LABELS.IS_ASSIGNED,
  LABELS.CREATOR,
  LABELS.CAN_VIEW,
  LABELS.DEFAULT,
]
