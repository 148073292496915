<div class="tw-p-4 tw-flex tw-flex-col tw-gap-8">
  <button
    *ngIf="currentUserCanAddMembers$ | async"
    class="member-list__btn tw-bg-next-orange-500 tw-p-2 tw-text-white tw-w-full tw-cursor-pointer tw-h-11 tw-min-h-full tw-rounded-md tw-flex tw-justify-center tw-items-center tw-gap-2 tw-transform tw-transition-transform active:tw-scale-90 hover:tw-bg-next-orange-700 active:tw-bg-next-orange-300 focus-visible:tw-bg-next-orange-500 focus-visible:tw-text-next-white-50 disabled:tw-pointer-events-none disabled:tw-opacity-30"
    (click)="onAddMembersClicked()"
  >
    <field-chat-lib-users-icon></field-chat-lib-users-icon>
    <span> Edit members </span>
  </button>
  <div>
    <h3 class="tw-font-bold tw-to-brand-text-500">Room members</h3>
    <div class="tw-flex tw-flex-col tw-gap-4 tw-p-4">
      <ng-container *ngIf="members$ | async as members">
        <field-chat-member-card
          *ngFor="let member of members"
          [name]="member?.name"
          [image]="member?.image"
          [userUid]="member?.id ?? ''"
        ></field-chat-member-card>
      </ng-container>
    </div>
  </div>
</div>
