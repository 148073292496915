import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { NavigationService } from "@services"

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router, private navigationService: NavigationService) {
    this.navigationService.hideTopAndSideBar()
  }

  ngOnInit() {}

  redirectToHome() {
    this.router.navigate([""])
  }
}
