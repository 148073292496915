<ng-container *ngIf="item$ | async as item">
  <item-details
    fxFill
    [item]="item"
    [availableItemActions]="availableItemActions"
    [currentCompanyFeatures]="currentCompanyFeatures"
    [restrictedItemMenuActions]="restrictedItemMenuActions"
    [withCarousel]="withCarousel"
    [itemImages]="itemImages$ | async"
    [task]="task$ | async"
    [project]="project"
    [itemDrawing]="itemDrawing$ | async"
    [projectMembers]="projectMembers"
    [currentUser]="currentUser$ | async"
    [userProjectRole]="userProjectRole"
    [userItemRole]="userItemRole"
    [showDrawingSection]="true"
    [timeline]="timeline"
    [currentCompany]="currentCompany"
    [currentCompanyMembers]="currentCompanyMembers"
    [currentCompanyAssociates]="currentCompanyAssociates"
  >
  </item-details>
</ng-container>
