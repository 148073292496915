import { A11yModule } from "@angular/cdk/a11y"
import { CommonModule, DatePipe } from "@angular/common"
import { ModuleWithProviders, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { AvatarModule } from "ngx-avatars"
import { InputTextareaModule } from "primeng/inputtextarea"
import { StreamAutocompleteTextareaModule, StreamChatModule } from "stream-chat-angular"
import {
  AddMembersDialogComponent,
  EmptyChannelListComponent,
  EmptyChannelMainChatComponent,
  FieldChatMainViewComponent,
  LinkButtonComponent,
  MemberCardComponent,
  MemberListComponent,
  ProjectsListComponent,
  RoomDetailsPanelComponent,
  SkelettonLoaderMainChatComponent,
} from "./components"

import { GET_STREAM_ACCESS_TOKEN } from "./constants"
import { FieldChatDatePipe } from "./pipes/field-chat-date.pipe"
import {
  AddUserPlusIconComponent,
  ArrowLeftIconComponent,
  BriefcaseProjectIconComponent,
  BriefcaseProjectSolidIconComponent,
  BuildingSolidIconComponent,
  ChatBubbleIconComponent,
  ChatInfoIconComponent,
  ChatRoomIconComponent,
  ChevronDownIconComponent,
  CheckedIconComponent,
  ChevronRightIconComponent,
  CircleQuestionIconComponent,
  CloseChatRoomIconComponent,
  CompanyIconComponent,
  CustomCandyBoxIconComponent,
  DropdownDownArrowIconComponent,
  EmptyChannelListIconComponent,
  LeaveRoomIconComponent,
  MagnifyingGlassIconComponent,
  MembersIconComponent,
  NextChatIconComponent,
  NextChatLogoComponent,
  PenToSquareIconComponent,
  PlusIconComponent,
  TrashIconComponent,
  UsersIconComponent,
} from "./icons-components"

@NgModule({
  declarations: [
    LinkButtonComponent,
    FieldChatDatePipe,
    FieldChatMainViewComponent,
    AddMembersDialogComponent,
    MemberCardComponent,
    MemberListComponent,
    ProjectsListComponent,
    RoomDetailsPanelComponent,
  ],
  imports: [
    A11yModule,
    AvatarModule,
    CommonModule,
    StreamChatModule,
    StreamAutocompleteTextareaModule,
    InputTextareaModule,
    FormsModule,
    ReactiveFormsModule,
    SkelettonLoaderMainChatComponent,
    EmptyChannelMainChatComponent,
    EmptyChannelListComponent,
    AddUserPlusIconComponent,
    ArrowLeftIconComponent,
    BriefcaseProjectIconComponent,
    BriefcaseProjectSolidIconComponent,
    BuildingSolidIconComponent,
    ChatBubbleIconComponent,
    ChatInfoIconComponent,
    ChatRoomIconComponent,
    ChevronDownIconComponent,
    ChevronRightIconComponent,
    CheckedIconComponent,
    CircleQuestionIconComponent,
    CloseChatRoomIconComponent,
    CompanyIconComponent,
    CustomCandyBoxIconComponent,
    DropdownDownArrowIconComponent,
    EmptyChannelListIconComponent,
    LeaveRoomIconComponent,
    MagnifyingGlassIconComponent,
    MembersIconComponent,
    NextChatIconComponent,
    NextChatLogoComponent,
    PenToSquareIconComponent,
    PlusIconComponent,
    TrashIconComponent,
    UsersIconComponent,
  ],
  exports: [FieldChatMainViewComponent],
  providers: [DatePipe],
})
export class FieldChatLibModule {
  static forRoot(streamAccessKey: string): ModuleWithProviders<FieldChatLibModule> {
    return {
      ngModule: FieldChatLibModule,
      providers: [
        {
          provide: GET_STREAM_ACCESS_TOKEN,
          useValue: streamAccessKey,
        },
      ],
    }
  }
}
