import { RelationData } from ".."

// TODO Deprecate
export class Roles {
  private static LABELS_ROLES = {
    CREATOR: { read: true, write: false, delete: false },
    CREATED_BY: { read: true, write: false, delete: false },
    OWNER: { read: true, write: false, delete: false },
    OWNED_BY: { read: true, write: false, delete: false },
    ADMINISTRATOR: { read: true, write: false, delete: false },
    ADMINISTRATED_BY: { read: true, write: false, delete: false },
    MANAGER: { read: true, write: false, delete: false },
    MANAGED_BY: { read: true, write: false, delete: false },
    DEFAULT: { read: true, write: false, delete: false },
  }

  read: boolean
  write: boolean
  delete?: boolean

  constructor(private relation: RelationData) {
    const roleKeys = Object.keys(Roles.LABELS_ROLES)
    // TODO support multiple labels as well
    const label = relation.labels[0]
    const labelIndex = roleKeys.indexOf(label)
    if (labelIndex != -1) {
      // @ts-ignore
      this.read = Roles.LABELS_ROLES[roleKeys[labelIndex]].read
      // @ts-ignore
      this.write = Roles.LABELS_ROLES[roleKeys[labelIndex]].write
      // @ts-ignore
      this.delete = Roles.LABELS_ROLES[roleKeys[labelIndex]].delete
    } else {
      throw new Error(`Unsupported relation label: ${label}`)
    }
  }
}
