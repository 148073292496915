import { Component, Input, OnInit } from "@angular/core"

import { Person, TimelineElement, WorkflowStates } from "@models/common"

@Component({
  selector: "timeline-status-element",
  templateUrl: "./timeline-status-element.component.html",
  styleUrls: ["./timeline-status-element.component.scss"],
})
export class TimelineStatusElementComponent implements OnInit {
  @Input() timeline: TimelineElement
  @Input() creator: Person
  @Input() currentUser: Person

  get creatorName() {
    return this.creator ? this.creator.name : ""
  }

  get statusMessage() {
    const statusCurr = this.timeline?.data?.currentData?.item?.status || this.timeline?.data?.currentData?.task?.status

    switch (statusCurr) {
      case WorkflowStates.DELEGATED:
        return this.creatorName ? `${this.creatorName} delegated the task` : "The task was delegated"
      case WorkflowStates.ACCEPTED:
        return this.creatorName ? `${this.creatorName} accepted the task` : "The task was accepted"
      case WorkflowStates.REJECTED:
        return this.creatorName ? `${this.creatorName} declined the task` : "The task was declined"
      case WorkflowStates.CANCELLED:
        return this.creatorName ? `${this.creatorName} cancelled the task` : "The task was cancelled"
      case WorkflowStates.FIXED:
        return this.creatorName ? `${this.creatorName} fixed the task.` : "The task was fixed"
      case WorkflowStates.CLOSED:
        return this.creatorName ? `${this.creatorName} closed the task.` : "The task was closed"
      case WorkflowStates.FIXED_ACCEPTED:
        return this.creatorName ? `${this.creatorName} accepted the fix` : "The fix was accepted"
      case WorkflowStates.FIXED_REJECTED:
        return this.creatorName ? `${this.creatorName} rejected the fix` : "The fix was rejected"
      default:
        return `Status is now ${statusCurr}`
    }
  }

  ngOnInit() {}
}
