import { Injectable } from "@angular/core"
import { COLLECTIONS, LegacyTemplate, Report } from "@models/common"
import { ModelService, ReportService } from "@services"
import { Observable } from "rxjs"
import { LegacyTemplateService } from "../../template-creator/services/legacy-template.service"
import { OpenReport } from "../models/open-report"
import { GeneralReport } from "@models/common/general-report"

@Injectable({
  providedIn: "root",
})
export class OpenReportService {
  constructor(
    private legacyTemplateService: LegacyTemplateService,
    private reportService: ReportService,
    private modelService: ModelService
  ) {}

  public listen(uid: string) {
    return this.modelService.listenTo(COLLECTIONS.OPEN_REPORTS, uid) as Observable<OpenReport>
  }

  public listenToOpenTemplate(uid: string) {
    return this.legacyTemplateService.listenToUid(uid)
  }

  public createOpenReport(reportTitle: string, template: LegacyTemplate, projectUid: string) {
    return this.reportService.createOpenReportFromTemplate(reportTitle, template, projectUid)
  }

  public listenToOpenReportsForProject(projectUid: string): Observable<OpenReport[]> {
    return this.modelService.queryAndListen({
      collection: COLLECTIONS.OPEN_REPORTS,
      aggregateData: {
        projectUid,
      },
    })
  }
}
