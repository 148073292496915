<ng-template #removeSelf>
  <h1 *ngIf="data.canRemoveMember; else cannotRemoveSelfHeader" mat-dialog-title>Confirm leaving this project</h1>
  <ng-template #cannotRemoveSelfHeader>
    <h3 mat-dialog-title>Could not leave project</h3>
  </ng-template>

  <mat-dialog-content *ngIf="data.canRemoveMember; else cannotRemoveSelfDialogContent">
    Are you sure you want to leave this project?
    <ul>
      <li *ngIf="data.numActiveItems > 0">
        <span class="warn">
          You still have {{ data.numActiveItems }} active item{{ data.numActiveItems == 1 ? "" : "s" }} in this project
        </span>
      </li>
      <li>You will not be able to access this project again without a new invite from a project administrator</li>
      <li>All your logged actions, items, reports, pictures and history will remain visible for other members of the project</li>
    </ul>
  </mat-dialog-content>
  <ng-template #cannotRemoveSelfDialogContent>
    <mat-dialog-content>{{ data.errorMsg }}</mat-dialog-content>
  </ng-template>
</ng-template>

<ng-template #removeMember>
  <h1 *ngIf="data.canRemoveMember; else cannotRemoveMemberHeader" mat-dialog-title>Confirm removing member from project</h1>
  <ng-template #cannotRemoveMemberHeader>
    <h2 mat-dialog-title>Could not remove member from project</h2>
  </ng-template>

  <mat-dialog-content *ngIf="data.canRemoveMember; else cannotRemoveMemberDialogContent">
    Are you sure you want to remove {{ data.member.name }} from this project?
    <ul>
      <li *ngIf="data.numActiveItems > 0">
        <span class="warn">
          {{ data.member.name }} still has {{ data.numActiveItems }} active item{{ data.numActiveItems == 1 ? "" : "s" }} in this project
        </span>
      </li>
      <li>{{ data.member.name }} will not be able to access this project again without a new invite from a project administrator</li>
      <li>logged actions, items, reports, pictures and history will remain visible for other members of the project</li>
    </ul>
  </mat-dialog-content>
  <ng-template #cannotRemoveMemberDialogContent>
    You do not have permission to remove {{ data.member.name }} from this project
  </ng-template>
</ng-template>

<ng-container *ngIf="data.selfRemove; then removeSelf; else removeMember"></ng-container>

<mat-dialog-actions align="end">
  <button class="cancel" *ngIf="data.canRemoveMember" mat-dialog-close>Cancel</button>
  <button [ngClass]="data.canRemoveMember ? 'confirm' : 'ok-button'" mat-dialog-close="userClickedConfirm">
    {{ confirmButtonText }}
  </button>
</mat-dialog-actions>
