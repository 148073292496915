<mat-tab-group mat-align-tabs="center" (selectedTabChange)="onTabChange($event)" #tabs dynamicHeight>
  <mat-tab id="data-input-tab">
    <ng-template mat-tab-label>
      <div class="flex flex-column justify-content-end align-items-center">
        <img src="assets/icons/data_input.png" width="20" />
        Data input
      </div>
    </ng-template>

    <mat-card>
      <mat-card-title class="flex justify-content-between align-items-center">
        Form Input Preview

        <mat-slide-toggle
          #previewToggle
          class="text-sm"
          [matTooltip]="'Disabling preview can increase performance with very large templates'"
          checked="true"
          (change)="previewEnabledChanged.emit($event.checked)"
        >
          Enable
        </mat-slide-toggle>
      </mat-card-title>
      <mat-card-content id="preview-area" *ngIf="previewData.length > 0 && previewToggle.checked">
        <div *ngFor="let element of previewData">
          <app-form-element [element]="element"> </app-form-element>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-tab>

  <mat-tab id="template-settings-tab">
    <ng-template mat-tab-label>
      <div class="flex flex-column justify-content-end align-items-center">
        <mat-icon>settings</mat-icon>
        <span>Template Options</span>
      </div>
    </ng-template>

    <mat-card>
      <mat-card-header>
        <mat-card-title> Template Options </mat-card-title>
      </mat-card-header>

      <mat-card-content id="template-logo-area" class="content-area">
        <div class="flex flex-column justify-content-center gap-1" [matTooltip]="logoTooltip">
          <p class="logo-text">Template specific logo:</p>
          <img class="logo" [src]="logo" alt="Company logo" />
          <checkd-button bg="blue" fg="white" (buttonClick)="uploadLogoClicked()"> Change logo </checkd-button>
        </div>

        <section class="pdf-footer-input-area">
          <form [formGroup]="templateOptionsForm">
            <mat-form-field>
              <mat-label>PDF footer</mat-label>
              <input
                #footerInput
                matInput
                type="text"
                [maxLength]="MAX_PDF_FOOTER_LENGTH"
                [placeholder]="company.pdfFooter"
                formControlName="footerText"
              />
              <mat-hint align="end"> {{ footerInput.value?.length || 0 }}/{{ MAX_PDF_FOOTER_LENGTH }} </mat-hint>
            </mat-form-field>

            <mat-checkbox
              formControlName="enableAnonymousReports"
              matTooltip="If you enable this, your project members can choose to fill out a report anonymously. No information about them will be saved."
              matTooltipPosition="right"
            >
              Enable anonymous reports
            </mat-checkbox>
          </form>

          <div class="flex flex-column justify-content-center gap-1" [matTooltip]="logoTooltip">
            <p class="logo-text">Footer logo:</p>
            <img *ngIf="footerLogo" class="logo" [src]="footerLogo" alt="Footer logo" />
            <checkd-button bg="blue" fg="white" (buttonClick)="uploadFooterLogoClicked()"> Upload footer logo </checkd-button>
          </div>
        </section>
      </mat-card-content>
    </mat-card>
  </mat-tab>

  <mat-tab id="pdf-preview-tab">
    <ng-template mat-tab-label>
      <div class="flex flex-column justify-content-end align-items-center">
        <img src="assets/icons/pdf_view.png" width="20" alt="PDF preview icon" />
        <span>PDF view</span>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
