import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { PrimaryButtonDirective } from "./primary-button.directive"
import { SecondaryButtonDirective } from "./secondary-button.directive"
import { TertiaryButtonDirective } from "./tertiary-button.directive"
import { DangerButtonDirective } from "./danger-button.directive"

@NgModule({
  declarations: [PrimaryButtonDirective, SecondaryButtonDirective, TertiaryButtonDirective, DangerButtonDirective],
  imports: [CommonModule],
  exports: [PrimaryButtonDirective, SecondaryButtonDirective, TertiaryButtonDirective, DangerButtonDirective],
})
export class ButtonModule {}
