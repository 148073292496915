<h1 class="checkd-white">This module is not activated for this project</h1>

<mat-card>

  <mat-card-content>
    <p *ngFor="let paragraph of data?.featureDescription?.salesPitch">
      {{paragraph}}
    </p>
  </mat-card-content>

  <mat-card-actions>
    <checkd-button fg="white" bg="blue"
                   (buttonClick)="dialogRef.triggerIntercom()">{{data?.buttonText || 'Talk to us'}}
    </checkd-button>
  </mat-card-actions>
</mat-card>
