import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { ICountry } from "../models/country"

@Injectable({
  providedIn: "root",
})
export class GeoServiceService {
  countryInfo: any = [
    { name: "Afghanistan", twoLetterCode: "AF", threeLetterCode: "AFG", countryCode: "93" },
    { name: "Albania", twoLetterCode: "AL", threeLetterCode: "ALB", countryCode: "355" },
    { name: "Algeria", twoLetterCode: "DZ", threeLetterCode: "DZA", countryCode: "213" },
    { name: "American Samoa", twoLetterCode: "AS", threeLetterCode: "ASM", countryCode: "1-684" },
    { name: "Andorra", twoLetterCode: "AD", threeLetterCode: "AND", countryCode: "376" },
    { name: "Angola", twoLetterCode: "AO", threeLetterCode: "AGO", countryCode: "244" },
    { name: "Anguilla", twoLetterCode: "AI", threeLetterCode: "AIA", countryCode: "1-264" },
    { name: "Antarctica", twoLetterCode: "AQ", threeLetterCode: "ATA", countryCode: "672" },
    { name: "Antigua and Barbuda", twoLetterCode: "AG", threeLetterCode: "ATG", countryCode: "1-268" },
    { name: "Argentina", twoLetterCode: "AR", threeLetterCode: "ARG", countryCode: "54" },
    { name: "Armenia", twoLetterCode: "AM", threeLetterCode: "ARM", countryCode: "374" },
    { name: "Aruba", twoLetterCode: "AW", threeLetterCode: "ABW", countryCode: "297" },
    { name: "Australia", twoLetterCode: "AU", threeLetterCode: "AUS", countryCode: "61" },
    { name: "Austria", twoLetterCode: "AT", threeLetterCode: "AUT", countryCode: "43" },
    { name: "Azerbaijan", twoLetterCode: "AZ", threeLetterCode: "AZE", countryCode: "994" },
    { name: "Bahamas", twoLetterCode: "BS", threeLetterCode: "BHS", countryCode: "1-242" },
    { name: "Bahrain", twoLetterCode: "BH", threeLetterCode: "BHR", countryCode: "973" },
    { name: "Bangladesh", twoLetterCode: "BD", threeLetterCode: "BGD", countryCode: "880" },
    { name: "Barbados", twoLetterCode: "BB", threeLetterCode: "BRB", countryCode: "1-246" },
    { name: "Belarus", twoLetterCode: "BY", threeLetterCode: "BLR", countryCode: "375" },
    { name: "Belgium", twoLetterCode: "BE", threeLetterCode: "BEL", countryCode: "32" },
    { name: "Belize", twoLetterCode: "BZ", threeLetterCode: "BLZ", countryCode: "501" },
    { name: "Benin", twoLetterCode: "BJ", threeLetterCode: "BEN", countryCode: "229" },
    { name: "Bermuda", twoLetterCode: "BM", threeLetterCode: "BMU", countryCode: "1-441" },
    { name: "Bhutan", twoLetterCode: "BT", threeLetterCode: "BTN", countryCode: "975" },
    { name: "Bolivia", twoLetterCode: "BO", threeLetterCode: "BOL", countryCode: "591" },
    { name: "Bosnia and Herzegovina", twoLetterCode: "BA", threeLetterCode: "BIH", countryCode: "387" },
    { name: "Botswana", twoLetterCode: "BW", threeLetterCode: "BWA", countryCode: "267" },
    { name: "Brazil", twoLetterCode: "BR", threeLetterCode: "BRA", countryCode: "55" },
    { name: "British Indian Ocean Territory", twoLetterCode: "IO", threeLetterCode: "IOT", countryCode: "246" },
    { name: "British Virgin Islands", twoLetterCode: "VG", threeLetterCode: "VGB", countryCode: "1-284" },
    { name: "Brunei", twoLetterCode: "BN", threeLetterCode: "BRN", countryCode: "673" },
    { name: "Bulgaria", twoLetterCode: "BG", threeLetterCode: "BGR", countryCode: "359" },
    { name: "Burkina Faso", twoLetterCode: "BF", threeLetterCode: "BFA", countryCode: "226" },
    { name: "Burundi", twoLetterCode: "BI", threeLetterCode: "BDI", countryCode: "257" },
    { name: "Cambodia", twoLetterCode: "KH", threeLetterCode: "KHM", countryCode: "855" },
    { name: "Cameroon", twoLetterCode: "CM", threeLetterCode: "CMR", countryCode: "237" },
    { name: "Canada", twoLetterCode: "CA", threeLetterCode: "CAN", countryCode: "1" },
    { name: "Cape Verde", twoLetterCode: "CV", threeLetterCode: "CPV", countryCode: "238" },
    { name: "Cayman Islands", twoLetterCode: "KY", threeLetterCode: "CYM", countryCode: "1-345" },
    { name: "Central African Republic", twoLetterCode: "CF", threeLetterCode: "CAF", countryCode: "236" },
    { name: "Chad", twoLetterCode: "TD", threeLetterCode: "TCD", countryCode: "235" },
    { name: "Chile", twoLetterCode: "CL", threeLetterCode: "CHL", countryCode: "56" },
    { name: "China", twoLetterCode: "CN", threeLetterCode: "CHN", countryCode: "86" },
    { name: "Christmas Island", twoLetterCode: "CX", threeLetterCode: "CXR", countryCode: "61" },
    { name: "Cocos Islands", twoLetterCode: "CC", threeLetterCode: "CCK", countryCode: "61" },
    { name: "Colombia", twoLetterCode: "CO", threeLetterCode: "COL", countryCode: "57" },
    { name: "Comoros", twoLetterCode: "KM", threeLetterCode: "COM", countryCode: "269" },
    { name: "Cook Islands", twoLetterCode: "CK", threeLetterCode: "COK", countryCode: "682" },
    { name: "Costa Rica", twoLetterCode: "CR", threeLetterCode: "CRI", countryCode: "506" },
    { name: "Croatia", twoLetterCode: "HR", threeLetterCode: "HRV", countryCode: "385" },
    { name: "Cuba", twoLetterCode: "CU", threeLetterCode: "CUB", countryCode: "53" },
    { name: "Curacao", twoLetterCode: "CW", threeLetterCode: "CUW", countryCode: "599" },
    { name: "Cyprus", twoLetterCode: "CY", threeLetterCode: "CYP", countryCode: "357" },
    { name: "Czech Republic", twoLetterCode: "CZ", threeLetterCode: "CZE", countryCode: "420" },
    { name: "Democratic Republic of the Congo", twoLetterCode: "CD", threeLetterCode: "COD", countryCode: "243" },
    { name: "Denmark", twoLetterCode: "DK", threeLetterCode: "DNK", countryCode: "45" },
    { name: "Djibouti", twoLetterCode: "DJ", threeLetterCode: "DJI", countryCode: "253" },
    { name: "Dominica", twoLetterCode: "DM", threeLetterCode: "DMA", countryCode: "1-767" },
    { name: "Dominican Republic", twoLetterCode: "DO", threeLetterCode: "DOM", countryCode: "1-809, 1-829, 1-849" },
    { name: "East Timor", twoLetterCode: "TL", threeLetterCode: "TLS", countryCode: "670" },
    { name: "Ecuador", twoLetterCode: "EC", threeLetterCode: "ECU", countryCode: "593" },
    { name: "Egypt", twoLetterCode: "EG", threeLetterCode: "EGY", countryCode: "20" },
    { name: "El Salvador", twoLetterCode: "SV", threeLetterCode: "SLV", countryCode: "503" },
    { name: "Equatorial Guinea", twoLetterCode: "GQ", threeLetterCode: "GNQ", countryCode: "240" },
    { name: "Eritrea", twoLetterCode: "ER", threeLetterCode: "ERI", countryCode: "291" },
    { name: "Estonia", twoLetterCode: "EE", threeLetterCode: "EST", countryCode: "372" },
    { name: "Ethiopia", twoLetterCode: "ET", threeLetterCode: "ETH", countryCode: "251" },
    { name: "Falkland Islands", twoLetterCode: "FK", threeLetterCode: "FLK", countryCode: "500" },
    { name: "Faroe Islands", twoLetterCode: "FO", threeLetterCode: "FRO", countryCode: "298" },
    { name: "Fiji", twoLetterCode: "FJ", threeLetterCode: "FJI", countryCode: "679" },
    { name: "Finland", twoLetterCode: "FI", threeLetterCode: "FIN", countryCode: "358" },
    { name: "France", twoLetterCode: "FR", threeLetterCode: "FRA", countryCode: "33" },
    { name: "French Polynesia", twoLetterCode: "PF", threeLetterCode: "PYF", countryCode: "689" },
    { name: "Gabon", twoLetterCode: "GA", threeLetterCode: "GAB", countryCode: "241" },
    { name: "Gambia", twoLetterCode: "GM", threeLetterCode: "GMB", countryCode: "220" },
    { name: "Georgia", twoLetterCode: "GE", threeLetterCode: "GEO", countryCode: "995" },
    { name: "Germany", twoLetterCode: "DE", threeLetterCode: "DEU", countryCode: "49" },
    { name: "Ghana", twoLetterCode: "GH", threeLetterCode: "GHA", countryCode: "233" },
    { name: "Gibraltar", twoLetterCode: "GI", threeLetterCode: "GIB", countryCode: "350" },
    { name: "Greece", twoLetterCode: "GR", threeLetterCode: "GRC", countryCode: "30" },
    { name: "Greenland", twoLetterCode: "GL", threeLetterCode: "GRL", countryCode: "299" },
    { name: "Grenada", twoLetterCode: "GD", threeLetterCode: "GRD", countryCode: "1-473" },
    { name: "Guam", twoLetterCode: "GU", threeLetterCode: "GUM", countryCode: "1-671" },
    { name: "Guatemala", twoLetterCode: "GT", threeLetterCode: "GTM", countryCode: "502" },
    { name: "Guernsey", twoLetterCode: "GG", threeLetterCode: "GGY", countryCode: "44-1481" },
    { name: "Guinea", twoLetterCode: "GN", threeLetterCode: "GIN", countryCode: "224" },
    { name: "Guinea-Bissau", twoLetterCode: "GW", threeLetterCode: "GNB", countryCode: "245" },
    { name: "Guyana", twoLetterCode: "GY", threeLetterCode: "GUY", countryCode: "592" },
    { name: "Haiti", twoLetterCode: "HT", threeLetterCode: "HTI", countryCode: "509" },
    { name: "Honduras", twoLetterCode: "HN", threeLetterCode: "HND", countryCode: "504" },
    { name: "Hong Kong", twoLetterCode: "HK", threeLetterCode: "HKG", countryCode: "852" },
    { name: "Hungary", twoLetterCode: "HU", threeLetterCode: "HUN", countryCode: "36" },
    { name: "Iceland", twoLetterCode: "IS", threeLetterCode: "ISL", countryCode: "354" },
    { name: "India", twoLetterCode: "IN", threeLetterCode: "IND", countryCode: "91" },
    { name: "Indonesia", twoLetterCode: "ID", threeLetterCode: "IDN", countryCode: "62" },
    { name: "Iran", twoLetterCode: "IR", threeLetterCode: "IRN", countryCode: "98" },
    { name: "Iraq", twoLetterCode: "IQ", threeLetterCode: "IRQ", countryCode: "964" },
    { name: "Ireland", twoLetterCode: "IE", threeLetterCode: "IRL", countryCode: "353" },
    { name: "Isle of Man", twoLetterCode: "IM", threeLetterCode: "IMN", countryCode: "44-1624" },
    { name: "Israel", twoLetterCode: "IL", threeLetterCode: "ISR", countryCode: "972" },
    { name: "Italy", twoLetterCode: "IT", threeLetterCode: "ITA", countryCode: "39" },
    { name: "Ivory Coast", twoLetterCode: "CI", threeLetterCode: "CIV", countryCode: "225" },
    { name: "Jamaica", twoLetterCode: "JM", threeLetterCode: "JAM", countryCode: "1-876" },
    { name: "Japan", twoLetterCode: "JP", threeLetterCode: "JPN", countryCode: "81" },
    { name: "Jersey", twoLetterCode: "JE", threeLetterCode: "JEY", countryCode: "44-1534" },
    { name: "Jordan", twoLetterCode: "JO", threeLetterCode: "JOR", countryCode: "962" },
    { name: "Kazakhstan", twoLetterCode: "KZ", threeLetterCode: "KAZ", countryCode: "7" },
    { name: "Kenya", twoLetterCode: "KE", threeLetterCode: "KEN", countryCode: "254" },
    { name: "Kiribati", twoLetterCode: "KI", threeLetterCode: "KIR", countryCode: "686" },
    { name: "Kosovo", twoLetterCode: "XK", threeLetterCode: "XKX", countryCode: "383" },
    { name: "Kuwait", twoLetterCode: "KW", threeLetterCode: "KWT", countryCode: "965" },
    { name: "Kyrgyzstan", twoLetterCode: "KG", threeLetterCode: "KGZ", countryCode: "996" },
    { name: "Laos", twoLetterCode: "LA", threeLetterCode: "LAO", countryCode: "856" },
    { name: "Latvia", twoLetterCode: "LV", threeLetterCode: "LVA", countryCode: "371" },
    { name: "Lebanon", twoLetterCode: "LB", threeLetterCode: "LBN", countryCode: "961" },
    { name: "Lesotho", twoLetterCode: "LS", threeLetterCode: "LSO", countryCode: "266" },
    { name: "Liberia", twoLetterCode: "LR", threeLetterCode: "LBR", countryCode: "231" },
    { name: "Libya", twoLetterCode: "LY", threeLetterCode: "LBY", countryCode: "218" },
    { name: "Liechtenstein", twoLetterCode: "LI", threeLetterCode: "LIE", countryCode: "423" },
    { name: "Lithuania", twoLetterCode: "LT", threeLetterCode: "LTU", countryCode: "370" },
    { name: "Luxembourg", twoLetterCode: "LU", threeLetterCode: "LUX", countryCode: "352" },
    { name: "Macau", twoLetterCode: "MO", threeLetterCode: "MAC", countryCode: "853" },
    { name: "Macedonia", twoLetterCode: "MK", threeLetterCode: "MKD", countryCode: "389" },
    { name: "Madagascar", twoLetterCode: "MG", threeLetterCode: "MDG", countryCode: "261" },
    { name: "Malawi", twoLetterCode: "MW", threeLetterCode: "MWI", countryCode: "265" },
    { name: "Malaysia", twoLetterCode: "MY", threeLetterCode: "MYS", countryCode: "60" },
    { name: "Maldives", twoLetterCode: "MV", threeLetterCode: "MDV", countryCode: "960" },
    { name: "Mali", twoLetterCode: "ML", threeLetterCode: "MLI", countryCode: "223" },
    { name: "Malta", twoLetterCode: "MT", threeLetterCode: "MLT", countryCode: "356" },
    { name: "Marshall Islands", twoLetterCode: "MH", threeLetterCode: "MHL", countryCode: "692" },
    { name: "Mauritania", twoLetterCode: "MR", threeLetterCode: "MRT", countryCode: "222" },
    { name: "Mauritius", twoLetterCode: "MU", threeLetterCode: "MUS", countryCode: "230" },
    { name: "Mayotte", twoLetterCode: "YT", threeLetterCode: "MYT", countryCode: "262" },
    { name: "Mexico", twoLetterCode: "MX", threeLetterCode: "MEX", countryCode: "52" },
    { name: "Micronesia", twoLetterCode: "FM", threeLetterCode: "FSM", countryCode: "691" },
    { name: "Moldova", twoLetterCode: "MD", threeLetterCode: "MDA", countryCode: "373" },
    { name: "Monaco", twoLetterCode: "MC", threeLetterCode: "MCO", countryCode: "377" },
    { name: "Mongolia", twoLetterCode: "MN", threeLetterCode: "MNG", countryCode: "976" },
    { name: "Montenegro", twoLetterCode: "ME", threeLetterCode: "MNE", countryCode: "382" },
    { name: "Montserrat", twoLetterCode: "MS", threeLetterCode: "MSR", countryCode: "1-664" },
    { name: "Morocco", twoLetterCode: "MA", threeLetterCode: "MAR", countryCode: "212" },
    { name: "Mozambique", twoLetterCode: "MZ", threeLetterCode: "MOZ", countryCode: "258" },
    { name: "Myanmar", twoLetterCode: "MM", threeLetterCode: "MMR", countryCode: "95" },
    { name: "Namibia", twoLetterCode: "NA", threeLetterCode: "NAM", countryCode: "264" },
    { name: "Nauru", twoLetterCode: "NR", threeLetterCode: "NRU", countryCode: "674" },
    { name: "Nepal", twoLetterCode: "NP", threeLetterCode: "NPL", countryCode: "977" },
    { name: "Netherlands", twoLetterCode: "NL", threeLetterCode: "NLD", countryCode: "31" },
    { name: "Netherlands Antilles", twoLetterCode: "AN", threeLetterCode: "ANT", countryCode: "599" },
    { name: "New Caledonia", twoLetterCode: "NC", threeLetterCode: "NCL", countryCode: "687" },
    { name: "New Zealand", twoLetterCode: "NZ", threeLetterCode: "NZL", countryCode: "64" },
    { name: "Nicaragua", twoLetterCode: "NI", threeLetterCode: "NIC", countryCode: "505" },
    { name: "Niger", twoLetterCode: "NE", threeLetterCode: "NER", countryCode: "227" },
    { name: "Nigeria", twoLetterCode: "NG", threeLetterCode: "NGA", countryCode: "234" },
    { name: "Niue", twoLetterCode: "NU", threeLetterCode: "NIU", countryCode: "683" },
    { name: "North Korea", twoLetterCode: "KP", threeLetterCode: "PRK", countryCode: "850" },
    { name: "Northern Mariana Islands", twoLetterCode: "MP", threeLetterCode: "MNP", countryCode: "1-670" },
    { name: "Norway", twoLetterCode: "NO", threeLetterCode: "NOR", countryCode: "47" },
    { name: "Oman", twoLetterCode: "OM", threeLetterCode: "OMN", countryCode: "968" },
    { name: "Pakistan", twoLetterCode: "PK", threeLetterCode: "PAK", countryCode: "92" },
    { name: "Palau", twoLetterCode: "PW", threeLetterCode: "PLW", countryCode: "680" },
    { name: "Palestine", twoLetterCode: "PS", threeLetterCode: "PSE", countryCode: "970" },
    { name: "Panama", twoLetterCode: "PA", threeLetterCode: "PAN", countryCode: "507" },
    { name: "Papua New Guinea", twoLetterCode: "PG", threeLetterCode: "PNG", countryCode: "675" },
    { name: "Paraguay", twoLetterCode: "PY", threeLetterCode: "PRY", countryCode: "595" },
    { name: "Peru", twoLetterCode: "PE", threeLetterCode: "PER", countryCode: "51" },
    { name: "Philippines", twoLetterCode: "PH", threeLetterCode: "PHL", countryCode: "63" },
    { name: "Pitcairn", twoLetterCode: "PN", threeLetterCode: "PCN", countryCode: "64" },
    { name: "Poland", twoLetterCode: "PL", threeLetterCode: "POL", countryCode: "48" },
    { name: "Portugal", twoLetterCode: "PT", threeLetterCode: "PRT", countryCode: "351" },
    { name: "Puerto Rico", twoLetterCode: "PR", threeLetterCode: "PRI", countryCode: "1-787, 1-939" },
    { name: "Qatar", twoLetterCode: "QA", threeLetterCode: "QAT", countryCode: "974" },
    { name: "Republic of the Congo", twoLetterCode: "CG", threeLetterCode: "COG", countryCode: "242" },
    { name: "Reunion", twoLetterCode: "RE", threeLetterCode: "REU", countryCode: "262" },
    { name: "Romania", twoLetterCode: "RO", threeLetterCode: "ROU", countryCode: "40" },
    { name: "Russia", twoLetterCode: "RU", threeLetterCode: "RUS", countryCode: "7" },
    { name: "Rwanda", twoLetterCode: "RW", threeLetterCode: "RWA", countryCode: "250" },
    { name: "Saint Barthelemy", twoLetterCode: "BL", threeLetterCode: "BLM", countryCode: "590" },
    { name: "Saint Helena", twoLetterCode: "SH", threeLetterCode: "SHN", countryCode: "290" },
    { name: "Saint Kitts and Nevis", twoLetterCode: "KN", threeLetterCode: "KNA", countryCode: "1-869" },
    { name: "Saint Lucia", twoLetterCode: "LC", threeLetterCode: "LCA", countryCode: "1-758" },
    { name: "Saint Martin", twoLetterCode: "MF", threeLetterCode: "MAF", countryCode: "590" },
    { name: "Saint Pierre and Miquelon", twoLetterCode: "PM", threeLetterCode: "SPM", countryCode: "508" },
    { name: "Saint Vincent and the Grenadines", twoLetterCode: "VC", threeLetterCode: "VCT", countryCode: "1-784" },
    { name: "Samoa", twoLetterCode: "WS", threeLetterCode: "WSM", countryCode: "685" },
    { name: "San Marino", twoLetterCode: "SM", threeLetterCode: "SMR", countryCode: "378" },
    { name: "Sao Tome and Principe", twoLetterCode: "ST", threeLetterCode: "STP", countryCode: "239" },
    { name: "Saudi Arabia", twoLetterCode: "SA", threeLetterCode: "SAU", countryCode: "966" },
    { name: "Senegal", twoLetterCode: "SN", threeLetterCode: "SEN", countryCode: "221" },
    { name: "Serbia", twoLetterCode: "RS", threeLetterCode: "SRB", countryCode: "381" },
    { name: "Seychelles", twoLetterCode: "SC", threeLetterCode: "SYC", countryCode: "248" },
    { name: "Sierra Leone", twoLetterCode: "SL", threeLetterCode: "SLE", countryCode: "232" },
    { name: "Singapore", twoLetterCode: "SG", threeLetterCode: "SGP", countryCode: "65" },
    { name: "Sint Maarten", twoLetterCode: "SX", threeLetterCode: "SXM", countryCode: "1-721" },
    { name: "Slovakia", twoLetterCode: "SK", threeLetterCode: "SVK", countryCode: "421" },
    { name: "Slovenia", twoLetterCode: "SI", threeLetterCode: "SVN", countryCode: "386" },
    { name: "Solomon Islands", twoLetterCode: "SB", threeLetterCode: "SLB", countryCode: "677" },
    { name: "Somalia", twoLetterCode: "SO", threeLetterCode: "SOM", countryCode: "252" },
    { name: "South Africa", twoLetterCode: "ZA", threeLetterCode: "ZAF", countryCode: "27" },
    { name: "South Korea", twoLetterCode: "KR", threeLetterCode: "KOR", countryCode: "82" },
    { name: "South Sudan", twoLetterCode: "SS", threeLetterCode: "SSD", countryCode: "211" },
    { name: "Spain", twoLetterCode: "ES", threeLetterCode: "ESP", countryCode: "34" },
    { name: "Sri Lanka", twoLetterCode: "LK", threeLetterCode: "LKA", countryCode: "94" },
    { name: "Sudan", twoLetterCode: "SD", threeLetterCode: "SDN", countryCode: "249" },
    { name: "Suriname", twoLetterCode: "SR", threeLetterCode: "SUR", countryCode: "597" },
    { name: "Svalbard and Jan Mayen", twoLetterCode: "SJ", threeLetterCode: "SJM", countryCode: "47" },
    { name: "Swaziland", twoLetterCode: "SZ", threeLetterCode: "SWZ", countryCode: "268" },
    { name: "Sweden", twoLetterCode: "SE", threeLetterCode: "SWE", countryCode: "46" },
    { name: "Switzerland", twoLetterCode: "CH", threeLetterCode: "CHE", countryCode: "41" },
    { name: "Syria", twoLetterCode: "SY", threeLetterCode: "SYR", countryCode: "963" },
    { name: "Taiwan", twoLetterCode: "TW", threeLetterCode: "TWN", countryCode: "886" },
    { name: "Tajikistan", twoLetterCode: "TJ", threeLetterCode: "TJK", countryCode: "992" },
    { name: "Tanzania", twoLetterCode: "TZ", threeLetterCode: "TZA", countryCode: "255" },
    { name: "Thailand", twoLetterCode: "TH", threeLetterCode: "THA", countryCode: "66" },
    { name: "Togo", twoLetterCode: "TG", threeLetterCode: "TGO", countryCode: "228" },
    { name: "Tokelau", twoLetterCode: "TK", threeLetterCode: "TKL", countryCode: "690" },
    { name: "Tonga", twoLetterCode: "TO", threeLetterCode: "TON", countryCode: "676" },
    { name: "Trinidad and Tobago", twoLetterCode: "TT", threeLetterCode: "TTO", countryCode: "1-868" },
    { name: "Tunisia", twoLetterCode: "TN", threeLetterCode: "TUN", countryCode: "216" },
    { name: "Turkey", twoLetterCode: "TR", threeLetterCode: "TUR", countryCode: "90" },
    { name: "Turkmenistan", twoLetterCode: "TM", threeLetterCode: "TKM", countryCode: "993" },
    { name: "Turks and Caicos Islands", twoLetterCode: "TC", threeLetterCode: "TCA", countryCode: "1-649" },
    { name: "Tuvalu", twoLetterCode: "TV", threeLetterCode: "TUV", countryCode: "688" },
    { name: "U.S. Virgin Islands", twoLetterCode: "VI", threeLetterCode: "VIR", countryCode: "1-340" },
    { name: "Uganda", twoLetterCode: "UG", threeLetterCode: "UGA", countryCode: "256" },
    { name: "Ukraine", twoLetterCode: "UA", threeLetterCode: "UKR", countryCode: "380" },
    { name: "United Arab Emirates", twoLetterCode: "AE", threeLetterCode: "ARE", countryCode: "971" },
    { name: "United Kingdom", twoLetterCode: "GB", threeLetterCode: "GBR", countryCode: "44" },
    { name: "United States", twoLetterCode: "US", threeLetterCode: "USA", countryCode: "1" },
    { name: "Uruguay", twoLetterCode: "UY", threeLetterCode: "URY", countryCode: "598" },
    { name: "Uzbekistan", twoLetterCode: "UZ", threeLetterCode: "UZB", countryCode: "998" },
    { name: "Vanuatu", twoLetterCode: "VU", threeLetterCode: "VUT", countryCode: "678" },
    { name: "Vatican", twoLetterCode: "VA", threeLetterCode: "VAT", countryCode: "379" },
    { name: "Venezuela", twoLetterCode: "VE", threeLetterCode: "VEN", countryCode: "58" },
    { name: "Vietnam", twoLetterCode: "VN", threeLetterCode: "VNM", countryCode: "84" },
    { name: "Wallis and Futuna", twoLetterCode: "WF", threeLetterCode: "WLF", countryCode: "681" },
    { name: "Western Sahara", twoLetterCode: "EH", threeLetterCode: "ESH", countryCode: "212" },
    { name: "Yemen", twoLetterCode: "YE", threeLetterCode: "YEM", countryCode: "967" },
    { name: "Zambia", twoLetterCode: "ZM", threeLetterCode: "ZMB", countryCode: "260" },
    { name: "Zimbabwe", twoLetterCode: "ZW", threeLetterCode: "ZWE", countryCode: "263" },
  ]

  constructor(private http: HttpClient) {}

  public findCountryCode(abbreviation: string): string {
    let code = null
    const countryCodes: ICountry[] = this.countryInfo

    if (abbreviation && abbreviation.trim().length > 0) {
      for (const country of countryCodes) {
        if (country.twoLetterCode.toUpperCase() === abbreviation.toUpperCase()) {
          code = country.countryCode
          break
        }
      }
    }

    // @ts-ignore
    return code
  }

  // Returns a record with each countryCode and their respective phone number code
  // This is for the dropdown menu
  public findCountryRecords(abbreviations: string[]): Record<string, string> {
    // Map each country code (E.g. "no, se, de")
    const returnValue = abbreviations.map((abbreviation) => {
      // Find the number for each country (E.g. "no" = "47")
      const countryNumber = this.findCountryCode(abbreviation)

      // Return a list of all countrycodes and their numbers
      return [abbreviation, ` + ${countryNumber} `]
    })

    // Make it into a big object, essentially returning a record
    return Object.fromEntries(returnValue)
  }
}
