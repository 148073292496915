import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { COLLECTIONS, Company, CompanyFeatures, getObjects, LABELS, LegacyTemplate, Person, Relation } from "@models/common"
import { FilestackService, RelationService, SnackbarService, TimelineService } from "@services"
// @ts-ignore
import * as deepEqual from "deep-equal"
import firebase from "firebase/compat/app"
import { BehaviorSubject, Observable } from "rxjs"
import { distinctUntilChanged } from "rxjs/operators"
import { Template, TemplateType } from "../models/template"
import { CameraElement } from "../models/template-elements/camera-element"
import { CheckboxElement } from "../models/template-elements/checkbox-element"
import { DateElement } from "../models/template-elements/date-element"
import { DropdownElement } from "../models/template-elements/dropdown-element"
import { FormsItemsElement } from "../models/template-elements/forms-items-element"
import { ImageElement } from "../models/template-elements/image-element"
import { MainSubElement } from "../models/template-elements/main-sub-element"
import { PagebreakElement } from "../models/template-elements/pagebreak-element"
import { SignatureElement } from "../models/template-elements/signature-element"
import { TableElement } from "../models/template-elements/table-element"
import { TabularElement } from "../models/template-elements/tabular-element"
import { ElementOptionCode, OptionElement, TemplateElement, TemplateElementType } from "../models/template-elements/template-element"
import { TextElement } from "../models/template-elements/text-element"
import { TextfieldElement } from "../models/template-elements/textfield-element"
import { TimeElement } from "../models/template-elements/time-element"
import { ToggleElement } from "../models/template-elements/toggle-element"
import { LegacyTemplateService } from "./legacy-template.service"

@Injectable({
  providedIn: "root",
})
export class TemplateCreationService {
  public messageListener$ = new BehaviorSubject({ name: "init", data: {} })
  private _templateHasUnsavedDataSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(
    private db: AngularFirestore,
    private legacyTemplateService: LegacyTemplateService,
    private relationService: RelationService,
    private filestackService: FilestackService,
    private snackbarService: SnackbarService,
    private timelineService: TimelineService
  ) {}

  public getDefaultTemplateElements(): TemplateElement[] {
    return [
      new TextfieldElement(),
      new CheckboxElement(),
      new DateElement(),
      new TimeElement(),
      new MainSubElement(),
      new ToggleElement(),
      new DropdownElement(),
      new CameraElement(),
      new ImageElement(),
      new SignatureElement(),
      new TabularElement(),
      new TextElement(),
      new PagebreakElement(),
    ]
  }

  /** returns an observable that signals whether a template has unsaved data */
  public get templateHasUnsavedData$(): Observable<boolean> {
    return this._templateHasUnsavedDataSubject.pipe(distinctUntilChanged())
  }

  /** Checks if two templates are equal using `areEqualTemplates` and updates `templatesHasUnsavedData$` accordingly */
  public checkIfTemplatesAreEqual(template1: Template, template2: Template) {
    this._templateHasUnsavedDataSubject.next(!this.areEqualTemplates(template1, template2))
  }

  public getFeatureBasedTemplateElements(company: Company): TemplateElement[] {
    // @ts-ignore
    return [
      company?.features?.includes(CompanyFeatures.FORMS_ITEMFLOW) ? new FormsItemsElement() : null,
      company?.features?.includes(CompanyFeatures.FORMS_TABLE) ? new TableElement() : null,
    ].filter((it) => it != null)
  }

  sendMessage(name: string, data: any) {
    this.messageListener$.next({ name, data })
  }

  listenToUid(uid: string) {
    return this.legacyTemplateService.listenToUid(uid)
  }

  getNewTemplate(): Template {
    return new Template(
      {
        templateType: TemplateType.NONE,
        internalVersion: firebase.firestore.FieldValue.increment(1),
        description: "",
        isDraft: true,
        elements: [],
        enableAnonymousReports: false,
      },
      // @ts-ignore
      null,
      null
    )
  }

  getNewLegacyTemplate(): LegacyTemplate {
    return new LegacyTemplate(
      {
        reportName: "",
        description: "",
        headerTemplate: "[]",
        detailTemplate: "[]",
        isDraft: true,
        templateType: TemplateType.NONE,
        enableAnonymousReports: false,
      },
      // @ts-ignore
      null,
      null
    )
  }

  transformLegacyTemplate(legacyTemplate: LegacyTemplate): Template {
    return this.legacyTemplateService.transformLegacyTemplate(legacyTemplate)
  }

  public generatePreviewData(elements: any[]) {
    return this.legacyTemplateService.transformTemplateElements(elements)
  }

  public async saveTemplate(data: {
    template: Template
    creatorOrEditor: Person
    company?: Company
    publishData?: {
      changelog: string
      publicVersion: string
    }
  }): Promise<Template> {
    const transformedTemplate = this.legacyTemplateService.transformTemplate(data.template)

    const saveData = { ...data, legacyTemplate: transformedTemplate }
    const savedTemplate = await this.legacyTemplateService.saveOrUpdateLegacyTemplate(saveData)

    return this.transformLegacyTemplate(savedTemplate)
  }

  public async publishTemplate(template: Template, publisher?: Person) {
    if (template.uid && template.ref) {
      await template.ref.update({ isDraft: false })
      template.data.isDraft = false
    }

    return template
  }

  public async publishLegacyTemplate(template: LegacyTemplate) {
    const batch = this.db.firestore.batch()
    template.batchUpdate(batch, { isDraft: false })
    await batch.commit()

    return template
  }

  public async unpublishLegacyTemplate(template: LegacyTemplate, fromCompany: Company, unpublisher?: Person) {
    // Make sure we're not doing anything with standard templates here
    if (template.isStandard) {
      return undefined
    }

    if (template.uid && template.ref) {
      if (template.data.isDraft == null) {
        // legacy template made with old forms system

        // Disable relations to remove from companies templates list
        this.relationService.disableRelation(COLLECTIONS.LEGACY_TEMPLATES, template.uid, COLLECTIONS.COMPANIES, fromCompany.uid, true)

        return template
      }

      const batch = this.db.firestore.batch()
      template.batchUpdate(batch, { isDraft: true })
      await batch.commit()

      return template
    }

    return undefined
  }

  // TODO Update the apps to filter out enabled relations with an INACTIVE label.
  //      When we have that on the apps we don't need to account for enabled/disabled relations anymore.
  public async deactivateTemplate(template: LegacyTemplate, company: Company) {
    const relations = await Promise.all([
      this.relationService.getRelation(template, company),
      this.relationService.getRelation(company, template),
    ])

    if (relations.every((r) => r.disabled && r.labels.includes(LABELS.INACTIVE))) {
      return
    }

    const batch = this.db.firestore.batch()

    for (const r of relations) {
      r.batchAddLabels(batch, [LABELS.INACTIVE])
      r.batchDisable(batch)
    }

    await batch.commit()
  }

  // TODO Update the apps to filter out enabled relations with an INACTIVE label.
  //      When we have that on the apps we don't need to account for enabled/disabled relations anymore.
  public async activateTemplate(template: LegacyTemplate, company: Company) {
    const relations = await Promise.all([
      this.relationService.getRelation(template, company),
      this.relationService.getRelation(company, template),
    ])

    if (relations.every((r) => !(r.disabled && r.labels.includes(LABELS.INACTIVE)))) {
      return
    }

    const batch = this.db.firestore.batch()

    for (const r of relations) {
      r.batchRemoveLabels(batch, [LABELS.INACTIVE])

      // TODO Update the apps to filter out enabled relations with an INACTIVE label.
      //      When we have that on the apps we don't need to disable the relations anymore.
      r.batchEnable(batch)
    }

    await batch.commit()
  }

  public async enableLegacyTemplateRelations(template: LegacyTemplate, company: Company) {
    this.relationService.enableRelation(COLLECTIONS.LEGACY_TEMPLATES, template.uid, COLLECTIONS.COMPANIES, company.uid, true)

    return template
  }

  public async convertAndduplicateLegacyTemplate(template: LegacyTemplate, company: Company, creator: Person) {
    // Duplicate template and merge header + details
    const duplicatedTemplate = await this.convertLegacyTemplate(template)

    return this.legacyTemplateService.saveOrUpdateLegacyTemplate({
      legacyTemplate: duplicatedTemplate,
      creatorOrEditor: creator,
      company,
    })
  }

  public areEqualTemplates(a: Template, b: Template): boolean {
    const haveEqualAttributes =
      a.uid === b.uid &&
      a.name === b.name &&
      a.description === b.description &&
      a.templateType === b.templateType &&
      a.sharingType === b.sharingType

    const haveEqualData = deepEqual(a.data, b.data)

    return haveEqualAttributes && haveEqualData
  }

  public cloneTemplate(template: Template): Template {
    const clonedTemplate: Template = new Template(JSON.parse(JSON.stringify(template.data)), template.uid, template.ref)

    return clonedTemplate
  }

  /**
   * This is a hack we use to update old templates with new functionality in elements we have changed.
   * Whenever Forms Builder loads an existing template, it only loads the data that exists
   * without merging it with the building blocks. We could generalize this, but we need to make sure
   * that it doesn't break or remove any data in existing templates.
   *
   * Until we improve this, add updates here for elements with new functionality, and explain why
   * it's in use
   */
  updateExistingTemplate(template: Template) {
    this.updateFormsItemsElementsInTemplate(template)

    return template
  }

  /**
   * Updates FormsItemsElements in the template with the option to include the element title in the PDF.
   */
  updateFormsItemsElementsInTemplate(template: Template) {
    const formsItems = getObjects(template.data, "type", TemplateElementType.FORMS_ITEMS)
    for (const fi of formsItems) {
      const options = (fi.options || []) as OptionElement[]

      if (options == null || options.find((option) => option.code === ElementOptionCode.INCLUDE_TITLE_IN_PDF) == null) {
        const newFormsItemsElement = new FormsItemsElement()
        const includeTitleInPdfOption = newFormsItemsElement.options.find(
          (option) => option.code === ElementOptionCode.INCLUDE_TITLE_IN_PDF
        )

        if (includeTitleInPdfOption != null) {
          fi.options = [...options, includeTitleInPdfOption]
        }
      }
    }
  }

  public async removeCompanyTemplate(remover: Person, company: Company, template: LegacyTemplate) {
    const batch = this.db.firestore.batch()
    const relations = await Relation.getBoth(company, template)

    for (const relation of relations) {
      relation.batchDisable(batch)
    }

    await this.timelineService.companyLegacyTemplateRemoved(batch, remover, company, template)

    return batch.commit()
  }

  async uploadLogo() {
    const maxSizeInMB = 1
    const uploadResult = await this.filestackService.pick({
      accept: ["image/png", "image/jpeg"],
      maxSize: maxSizeInMB * 1024 * 1024,
      storeTo: { location: "gcs", path: "companyLogos/" },
    })

    const isFailedUpload =
      (uploadResult.filesFailed != null && uploadResult.filesFailed.length > 0) ||
      uploadResult.filesUploaded == null ||
      uploadResult.filesUploaded.length < 1 ||
      uploadResult.filesUploaded[0] == null ||
      uploadResult.filesUploaded[0].url == null

    if (isFailedUpload) {
      this.snackbarService.showMessage("An error occurred while trying to upload your logo.")

      return null
    }

    return uploadResult.filesUploaded[0].url
  }

  // merge header + details
  private async convertLegacyTemplate(template: LegacyTemplate) {
    const oldHeader = template.headerTemplate
    const oldDetails = template.detailTemplate
    const merged = oldHeader.concat(oldDetails)

    const newTemplateData = {
      reportName: template.name,
      isDraft: true,
      headerTemplate: "[]",
      detailTemplate: JSON.stringify(merged),
      enableAnonymousReports: template.enableAnonymousReports,
    }

    // @ts-ignore
    return new LegacyTemplate(newTemplateData, null, null)
  }
}
