import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-progress-dialog",
  templateUrl: "./progress-dialog.component.html",
  styleUrls: ["./progress-dialog.component.scss"],
})
export class ProgressDialogComponent implements OnInit {
  value = 10
  description = ""
  constructor() {}

  ngOnInit() {}

  resetValue() {
    this.value = 10
    this.description = ""
  }
}
