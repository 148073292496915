<mat-list-item>
  <mat-card style="box-shadow: none">
    <div class="item-topbar" fxLayout="row" fxLayoutAlign="space-between center">
      <span>
        <checkd-multiselect
          #numberFilter
          (onSelection)="onAcFilterChange('number', $event)"
          [values]="stringNumbers"
          class="search-number"
          placeholder="#"
          multiple="true"
        ></checkd-multiselect>
      </span>

      <span
        fxLayout.lt-md="column"
        fxLayout.gt-sm="row"
        fxLayoutGap.gt-sm="5px"
        fxLayoutGap.lt-md="0px"
        fxLayoutAlign="begin center"
        style="text-align: left"
      >
        <span class="item-title">
          <checkd-ac-search
            #titleFilter
            (onSelection)="onAcFilterChange('title', $event)"
            [searchOptions]="titles"
            class="search-title"
            placeholder="Title"
          ></checkd-ac-search>
        </span>
        <checkd-multiselect
          fxHide.xs
          #tagsFilter
          (onSelection)="onAcFilterChange('tags', $event)"
          [values]="tags"
          class="search-tags"
          placeholder="Tags"
          multiple="true"
        ></checkd-multiselect>
      </span>

      <span fxHide.sm fxHide.xs>
        <checkd-multiselect
          fxHide.xs
          #creatorFilter
          (onSelection)="onAcFilterChange('creator', $event)"
          [values]="assignerNames"
          class="search-creator"
          placeholder="Creator"
          multiple="true"
        ></checkd-multiselect>
      </span>

      <span fxHide.sm fxHide.xs>
        <checkd-multiselect
          fxHide.xs
          #assignerFilter
          (onSelection)="onAcFilterChange('assigner', $event)"
          [values]="assignerNames"
          class="search-assigner"
          placeholder="Assigner"
          multiple="true"
        ></checkd-multiselect>
      </span>

      <span fxHide.sm fxHide.xs>
        <checkd-multiselect
          fxHide.xs
          #assignerCompanyFilter
          (onSelection)="onAcFilterChange('assignerCompany', $event)"
          [values]="assignerCompanyNames"
          class="search-company"
          placeholder="Assigner company"
          multiple="true"
        ></checkd-multiselect>
      </span>

      <span fxHide.xs class="task-updated">Updated at</span>

      <span fxHide.sm fxHide.xs>
        <checkd-multiselect
          fxHide.xs
          #drawingFilter
          (onSelection)="onAcFilterChange('drawing', $event)"
          [values]="drawingNames"
          class="search-drawing"
          placeholder="Drawing"
          multiple="true"
        ></checkd-multiselect>
      </span>

      <span fxHide.sm fxHide.xs>
        <checkd-multiselect
          fxHide.xs
          #projectFilter
          (onSelection)="onAcFilterChange('project', $event)"
          [values]="projectNames"
          class="search-project"
          placeholder="Project"
          multiple="true"
        ></checkd-multiselect>
      </span>

      <span>
        <checkd-multiselect
          #assigneeFilter
          (onSelection)="onAcFilterChange('assignee', $event)"
          [values]="assigneeNames"
          class="search-assignee"
          placeholder="Assignee"
          multiple="true"
        ></checkd-multiselect>
      </span>
    </div>
  </mat-card>
</mat-list-item>
