import { ElementOptionCode, TemplateElement, TemplateElementType } from "./template-element"

class TableChildElement extends TemplateElement {
  override name: string = ""
  override type: TemplateElementType = TemplateElementType.TABLE_CHILD
  override typeAlias: string = "Table Child"
  override value = ""
  override children: any[] = []
  override options = [
    {
      id: 1,
      name: "Row span",
      type: "numberdropdown",
      code: ElementOptionCode.TABLE_CHILD_ROW_SPAN,
      value: 1,
    },
    {
      id: 2,
      name: "Col span",
      type: "numberdropdown",
      code: ElementOptionCode.TABLE_CHILD_COL_SPAN,
      value: 1,
    },
  ]

  static withData(data: TemplateElement) {
    const child = new TableChildElement()
    Object.assign(child, data)

    return child
  }

  public isOccupied = false

  get rowSpan() {
    return ((this.options || []).find((option) => option.code === ElementOptionCode.TABLE_CHILD_ROW_SPAN) || { value: 1 }).value
  }
  set rowSpan(n: number) {
    // @ts-ignore
    ;(this.options || []).find((option) => option.code === ElementOptionCode.TABLE_CHILD_ROW_SPAN).value = n
  }
  get colSpan() {
    return ((this.options || []).find((option) => option.code === ElementOptionCode.TABLE_CHILD_COL_SPAN) || { value: 1 }).value
  }
  set colSpan(n: number) {
    // @ts-ignore
    ;(this.options || []).find((option) => option.code === ElementOptionCode.TABLE_CHILD_COL_SPAN).value = n
  }

  readonly requiredTableElementCellCount = {
    [TemplateElementType.TEXT]: 1,
    [TemplateElementType.IMAGE]: 1,
    [TemplateElementType.TABLE]: 1,
    [TemplateElementType.CHECKBOX]: 2,
    [TemplateElementType.TEXT_FIELD]: 2,
    [TemplateElementType.DATE]: 2,
    [TemplateElementType.TIME]: 2,
  }

  get requiredCellCount(): number {
    if (this.children.length < 1) {
      return 1
    }

    if (this.isElementWithHiddenTitleInPdf()) {
      return 1
    }

    // @ts-ignore
    return this.requiredTableElementCellCount[this.children[0].type]
  }

  /** Whether this table child element contains a text field element with title hidden */
  private isElementWithHiddenTitleInPdf(): boolean {
    const element = this.children[0]

    if (element === undefined) {
      return false
    }

    const hideTitleInPdfOptionElement = (element.options || []).find((option: any) => option.code === ElementOptionCode.HIDE_TITLE_IN_PDF)

    if (hideTitleInPdfOptionElement === undefined) {
      return false
    }

    return hideTitleInPdfOptionElement.value
  }
}

class TableElement extends TemplateElement {
  override name: string = ""
  override icon?: string = "forms:tabular"
  override type: TemplateElementType = TemplateElementType.TABLE
  override typeAlias: string = "Table"
  override value = ""
  override children: any[] = []
  override options = [
    {
      id: 1,
      name: "Quarter",
      type: "checkbox",
      code: ElementOptionCode.QUARTER,
      value: false,
    },
    {
      id: 2,
      name: "Half",
      type: "checkbox",
      code: ElementOptionCode.HALF,
      value: false,
    },
    {
      id: 3,
      name: "Required",
      type: "checkbox",
      code: ElementOptionCode.REQUIRED,
      value: false,
    },
    {
      id: 4,
      name: "Columns",
      type: "numberdropdown",
      code: ElementOptionCode.TABLE_COLUMNS,
      value: 2,
    },
    {
      id: 5,
      name: "Column widths",
      type: "columnwidthselection",
      code: ElementOptionCode.TABLE_COLUMN_WIDTHS,
      value: ["evenly", "evenly"],
    },
  ]
}

export { TableElement, TableChildElement }
