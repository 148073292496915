import { NgModule } from "@angular/core"

import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button"
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card"
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list"
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox"
import { MatLegacySliderModule as MatSliderModule } from "@angular/material/legacy-slider"
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete"
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input"
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio"
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle"
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu"
import { MatSidenavModule } from "@angular/material/sidenav"
import { MatToolbarModule } from "@angular/material/toolbar"
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs"
import { MatExpansionModule } from "@angular/material/expansion"
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner"
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar"
import { MatDialogModule } from "@angular/material/dialog"
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip"
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar"
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table"
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select"
import { MatRippleModule } from "@angular/material/core"
import { OverlayModule } from "@angular/cdk/overlay"
import { MatGridListModule } from "@angular/material/grid-list"
import { MatNativeDateModule } from "@angular/material/core"
import { MatDatepickerModule } from "@angular/material/datepicker"
import { MatStepperModule } from "@angular/material/stepper"
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator"
import { MatSortModule } from "@angular/material/sort"

import { MatBottomSheetModule } from "@angular/material/bottom-sheet"
import { MatButtonToggleModule } from "@angular/material/button-toggle"

@NgModule({
  exports: [
    MatButtonModule,
    MatCardModule,
    MatListModule,
    MatCheckboxModule,
    MatSliderModule,
    MatAutocompleteModule,
    MatInputModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTabsModule,
    MatExpansionModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSelectModule,
    MatRippleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OverlayModule,
    MatGridListModule,
    MatProgressBarModule,
    MatStepperModule,
    MatPaginatorModule,
    MatSortModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
  ],
})
export class CheckdMaterialModule {}
