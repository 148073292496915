import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { AngularFirestore, DocumentSnapshot } from "@angular/fire/compat/firestore"
import { createNewModelInstance } from "@models/common"
import { COLLECTIONS } from "@models/common/collections.interface"
import { Image } from "@models/common/image"
import { CloudFunctionsService } from "@services"
import { ModelService } from "@services/model.service"
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs"
import { distinctUntilChanged, map, shareReplay, switchMap, tap } from "rxjs/operators"
import firebase from "firebase/compat"
import OrderByDirection = firebase.firestore.OrderByDirection

@Injectable({
  providedIn: "root",
})
export class ProjectGalleryService {
  // @ts-ignore
  private _startAt$ = new BehaviorSubject<DocumentSnapshot<any>>(null)
  // @ts-ignore
  private _endBefore$ = new BehaviorSubject<DocumentSnapshot<any>>(null)
  private _limit$ = new BehaviorSubject<number>(10)
  private _currentProjectUid$ = new Subject<string>()
  // @ts-ignore
  private _firstSnapInCurrentChunk$ = new BehaviorSubject<DocumentSnapshot<any>>(null)
  // @ts-ignore
  private _lastSnapInCurrentChunk$ = new BehaviorSubject<DocumentSnapshot<any>>(null)
  private _orderDirection$ = new BehaviorSubject<"asc" | "desc">("desc")

  private _hasNext$ = new BehaviorSubject(false)
  private _hasPrevious$ = new BehaviorSubject(false)
  private _currentPage$ = new BehaviorSubject(0)

  // @ts-ignore
  private _dateFilter$ = new BehaviorSubject<{ startDate: number; endDate: number }>({ startDate: null, endDate: null })

  private currentProjectUid$ = this._currentProjectUid$.asObservable()

  private startAt$ = this._startAt$.asObservable()
  private endBefore$ = this._endBefore$.asObservable()

  private dateFilter$ = this._dateFilter$.asObservable()

  public readonly limit$ = this._limit$.asObservable().pipe(distinctUntilChanged())
  public readonly currentPage$ = this._currentPage$.asObservable()
  public readonly orderDirection$: Observable<"asc" | "desc"> = this._orderDirection$.asObservable().pipe(distinctUntilChanged())

  public readonly hasNext$: Observable<boolean> = this._hasNext$.asObservable()
  public readonly hasPrevious$: Observable<boolean> = this._hasPrevious$.asObservable()

  public currentProjectItemImages$: Observable<Image[]> = combineLatest([
    this.currentProjectUid$,
    this.limit$,
    this.startAt$,
    this.endBefore$,
    this.currentPage$,
    this.orderDirection$,
    this.dateFilter$,
  ]).pipe(
    switchMap((data) =>
      this.db
        .collection(COLLECTIONS.IMAGES, (ref) => {
          const projectUid: string = data[0] as string
          const limit: number = data[1] as number
          const startAt: DocumentSnapshot<any> = data[2] as DocumentSnapshot<any>
          const endBefore: DocumentSnapshot<any> = data[3] as DocumentSnapshot<any>
          const currentPage: number = data[4] as number
          const order: OrderByDirection = data[5] as OrderByDirection
          const dateFilter = data[6] as { startDate: number; endDate: number }

          let query = ref
            .where("disabled", "==", false)
            .where("aggregateData.projectUid", "==", projectUid)
            .where("createdAt", ">=", dateFilter.startDate || 0)
            .where("createdAt", "<=", dateFilter.endDate || Number.MAX_SAFE_INTEGER)
            .orderBy("createdAt", order)

          // If we're on the first page we just check if there's a limit provided
          if (currentPage < 1) {
            if (limit) {
              query = query.limit(limit + 1)
            }

            return query
          }

          // If we're going to the previous page we use limitToLast
          if (limit && endBefore) {
            query = query.limitToLast(limit + 1)
          } else if (limit) {
            // Otherwise we're probably going to the next page
            query = query.limit(limit + 1)
          }

          // startAt is provided if we're going to the next page
          if (startAt) {
            query = query.startAfter(startAt)
          }

          // endBefore is provided if we're going to the previous page
          if (endBefore) {
            query = query.endBefore(endBefore)
          }

          return query
        })
        .snapshotChanges()
    ),
    tap((snaps) => {
      if (snaps == null || snaps.length < 1) {
        return
      }

      // @ts-ignore -- some typing weirdness here
      this._firstSnapInCurrentChunk$.next(snaps[0].payload.doc)

      // @ts-ignore -- some typing weirdness here
      this._lastSnapInCurrentChunk$.next(snaps[snaps.length - 1].payload.doc)

      this._hasPrevious$.next(this._currentPage$.value > 0)
      this._hasNext$.next(snaps.length > this._limit$.value)
    }),
    map((snaps) => {
      // Slice to the current limit
      // We're fetching 1 extra image in order to use it for the hasNext$ observable
      // and startAfter() query calls
      const slicedSnaps = snaps.slice(0, this._limit$.value)

      return slicedSnaps.map((snap) =>
        createNewModelInstance(COLLECTIONS.IMAGES, snap.payload.doc.data(), snap.payload.doc.id, snap.payload.doc.ref)
      )
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  constructor(private db: AngularFirestore, public cloudFunctionService: CloudFunctionsService, private http: HttpClient) {}

  /**
   * Goes to the first page/chunk of the image documents
   */
  public first() {
    // @ts-ignore
    this._startAt$.next(null)
    // @ts-ignore
    this._endBefore$.next(null)
    this._currentPage$.next(0)
  }

  /**
   * Goes to the previous page/chunk of the image documents
   */
  public previous() {
    if (!this._hasPrevious$.value) {
      return
    }

    this._currentPage$.next(Math.max(0, this._currentPage$.value - 1))
    // @ts-ignore
    this._startAt$.next(null)
    this._endBefore$.next(this._firstSnapInCurrentChunk$.value || null)
  }

  /**
   * Goes to the next page/chunk of the image documents
   */
  public next() {
    if (!this._hasNext$.value) {
      return
    }

    this._currentPage$.next(this._currentPage$.value + 1)
    this._startAt$.next(this._lastSnapInCurrentChunk$.value || null)
    // @ts-ignore
    this._endBefore$.next(null)
  }

  /**
   * Sets the limit, i.e. the amount of image documents to fetch per page
   *
   * @param limit a number (0 turns off limiting) specifying the amount of documents
   */
  public setLimit(limit: number) {
    if (limit === this._limit$.value) {
      return
    }

    // @ts-ignore
    this._limit$.next(limit < 1 ? null : limit)
  }

  /**
   * Sets the order direction of the image documents to either ascending or descending.
   *
   * @param direction either 'asc' or 'desc'
   */
  public setOrderDirection(direction: "asc" | "desc") {
    this._orderDirection$.next(direction)
  }

  /**
   * Sets the project UID for which to fetch the item images for
   *
   * @param projectUid a string containing the project UID
   */
  public setCurrentProjectUid(projectUid: string) {
    this._currentProjectUid$.next(projectUid)
  }

  download(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: "blob",
    })
  }
  /**
   * Sets date filter between which the images are fetched
   *
   * @param startDate a Date to start from
   * @param endDate a Date to end at
   */
  public setDateFilter(startDate: Date, endDate: Date) {
    // If a user selects the same day for start and end,
    //   we assume that the user wants to se images from that day specifically
    if (startDate != null && endDate != null && startDate.getTime() === endDate.getTime()) {
      endDate.setDate(endDate.getDate() + 1)
    }

    this._dateFilter$.next({
      // @ts-ignore
      startDate: startDate ? startDate.getTime() : null,
      // @ts-ignore
      endDate: endDate ? endDate.getTime() : null,
    })
  }
}
