import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-users-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" [attr.viewBox]="getViewBox()" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9963 14.3984C14.1491 14.3984 15.8625 12.6524 15.8625 10.4984C15.8625 8.34444 14.1173 6.59844 11.9963 6.59844C9.84338 6.59844 8.13 8.34444 8.13 10.4984C8.09625 12.6509 9.84375 14.3984 11.9963 14.3984ZM11.9963 8.39844C13.1531 8.39844 14.0948 9.34044 14.0948 10.4984C14.0948 11.6564 13.1512 12.5984 11.9963 12.5984C10.8413 12.5984 9.89625 11.6572 9.89625 10.4984C9.89625 9.33969 10.8375 8.39844 11.9963 8.39844ZM19.2 8.39844C20.8568 8.39844 22.2 7.05519 22.2 5.39844C22.2 3.74169 20.8575 2.39844 19.2 2.39844C17.5433 2.39844 16.2 3.74169 16.2 5.39844C16.2 7.05519 17.5425 8.39844 19.2 8.39844ZM13.8713 15.5984H10.1288C7.185 15.5984 4.8 17.8372 4.8 20.5972C4.8 21.1522 5.27625 21.5984 5.865 21.5984H18.135C18.7238 21.5984 19.2 21.1522 19.2 20.5972C19.2 17.8372 16.815 15.5984 13.8713 15.5984ZM6.67875 19.7984C7.07138 18.4199 8.45475 17.3984 10.0965 17.3984H13.8713C15.5126 17.3984 16.896 18.4199 17.289 19.7984H6.67875ZM20.6963 9.59844H18.3773C17.8973 9.59844 17.4443 9.71233 17.0325 9.90744C17.055 10.1047 17.0925 10.2959 17.0925 10.4984C17.0925 11.7626 16.6133 12.9063 15.849 13.7984H23.3378C23.7038 13.7984 24 13.4834 24 13.0972C24 11.1659 22.5225 9.59844 20.6963 9.59844ZM6.89625 10.4984C6.89625 10.2941 6.93309 10.0998 6.95659 9.90181C6.5475 9.67719 6.0975 9.59844 5.62125 9.59844H3.303C1.479 9.59844 0 11.1659 0 13.0972C0 13.4834 0.295763 13.7984 0.66075 13.7984H8.142C7.37625 12.9059 6.89625 11.7622 6.89625 10.4984ZM4.8 8.39844C6.45675 8.39844 7.8 7.05519 7.8 5.39844C7.8 3.74169 6.4575 2.39844 4.8 2.39844C3.14325 2.39844 1.8 3.74169 1.8 5.39844C1.8 7.05519 3.14325 8.39844 4.8 8.39844Z"
        fill="currentColor"
        [attr.transform]="getScale()"
      />
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class UsersIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }

  getViewBox(): string | undefined {
    return this.svgIconsService.getViewBox(this.size)
  }
  getScale(): string | undefined {
    return this.svgIconsService.getScale(this.size)
  }
}
