import { Component, OnDestroy, OnInit } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { UntypedFormBuilder } from "@angular/forms"
import { Router } from "@angular/router"
import { Company, LegacyTemplate, Person, ProjectData } from "@models/common"
import { Observable, Subscription } from "rxjs"
import { map, switchMap } from "rxjs/operators"
import { CompanyService, ProjectService, SnackbarService, UserService } from "../../services"
import { ProjectTemplateService } from "../services"

@Component({
  selector: "app-new-project-view",
  templateUrl: "./new-project-view.component.html",
  styleUrls: ["./new-project-view.component.scss"],
})
export class NewProjectViewComponent implements OnInit, OnDestroy {
  projectData: ProjectData = { name: "", location: { address: "" } }
  selectedProjectTemplates: LegacyTemplate[] = []
  reportTags: string[] = []
  currentUser: Person
  currentCompany: Company

  isCreatingProject: boolean = false

  userAndMainCompany$: Observable<{ user: Person; company: Company }>
  companyTemplates$: Observable<LegacyTemplate[]>

  subscriptions: Subscription[]

  constructor(
    private formBuilder: UntypedFormBuilder,
    private projectService: ProjectService,
    private projectTemplateService: ProjectTemplateService,
    private companyService: CompanyService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private afAuth: AngularFireAuth,
    private router: Router
  ) {}

  ngOnInit() {
    this.setupListeners()
    this.setupSubscriptions()
  }

  setupListeners() {
    this.userAndMainCompany$ = this.userService.listenToCurrentUserAndMainCompany()
    this.companyTemplates$ = this.userAndMainCompany$.pipe(
      switchMap((userAndCompany) => this.companyService.listenToTemplates(userAndCompany.company)),
      map((templates) => templates.filter((template) => !template.inactive && !template.isDraft))
    )
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.userAndMainCompany$.subscribe((userAndCompany) => {
        this.currentUser = userAndCompany.user
        this.currentCompany = userAndCompany.company
      }),
    ]
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  get canCreate() {
    return !this.isCreatingProject && this.isValidProjectData
  }

  get canCreateProject() {
    return this.currentCompany != null && !this.currentCompany.lockedByCheckd
  }

  get isValidProjectData() {
    return this.projectData.name.trim() != ""
  }

  onProjectDataChange(projectData: ProjectData) {
    this.projectData = projectData
  }

  onSelectedTemplatesChange(templates: LegacyTemplate[]) {
    this.selectedProjectTemplates = templates || []
  }

  onReportTagsChange(tags: string[]) {
    this.reportTags = tags
  }

  async createProject() {
    if (!this.canCreate) {
      return
    }

    if (this.currentUser == null || this.currentCompany == null) {
      const msg = `ERROR: unable to create project, missing user and/or company data`
      this.snackbarService.showMessage(msg)
      this.isCreatingProject = false

      return
    }

    try {
      this.isCreatingProject = true
      const project = await this.projectService.createProject(this.currentUser, this.projectData, this.currentCompany, this.reportTags)
      if (this.selectedProjectTemplates != null && this.selectedProjectTemplates.length > 0) {
        await this.projectTemplateService.addTemplatesToProject(this.selectedProjectTemplates, project)
      }
      this.snackbarService.showMessage(`Project ${project.name} created!`)
      this.isCreatingProject = false
      this.router.navigateByUrl(`projects/${project.ref!.id}/people`)
    } catch (err) {
      this.snackbarService.showMessage(err.message)
    }
  }
}
