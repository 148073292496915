import { Component } from "@angular/core"
import { getCorrectOptionValue } from "@checkd-form/models/utilities"
import { DefaultElementComponent } from "../default-element/default-element.component"

@Component({
  selector: "app-checkbox-collection-element",
  templateUrl: "./checkbox-collection-element.component.html",
  styleUrls: ["./checkbox-collection-element.component.scss"],
})
export class CheckboxCollectionElementComponent extends DefaultElementComponent {
  getLayoutDirection() {
    return this.isVertical() ? "column" : "row"
  }

  isVertical() {
    const isVerticalOption = this.getOption("isvertical")

    if (isVerticalOption) {
      return getCorrectOptionValue(isVerticalOption.value)
    }

    if (this.element.isVertical !== null) {
      return this.element.isVertical
    }

    return false
  }

  get isMultiSelect() {
    const isMultiSelectOption = this.getOption("ismultiselect")

    if (isMultiSelectOption) {
      return getCorrectOptionValue(isMultiSelectOption.value)
    }

    if (this.element.isMultiSelect !== null) {
      return this.element.isMultiSelect
    }

    return false
  }

  get checkboxElements() {
    return this.element.values
  }

  selectionChanged(changedComponent: any) {
    const newValue = changedComponent.element.value

    for (const element of this.checkboxElements!) {
      if (changedComponent.element.id === element.id) {
        element.value = newValue
      } else {
        if (!this.isMultiSelect) {
          element.value = !newValue
        }
      }
    }
  }
}
