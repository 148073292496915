import { FloorplanUserInterface } from "../floorplan"
import { FormsUserInterface } from "../forms"
import { RelationInterface } from "./relation.interface"

export interface IUserPreferences {
  favoriteProjects?: string[]
  smsConfig: {
    acceptSmsNotifications: boolean
    phoneNumber: string
  }
}

/**
 * @deprecated use PersonData instead
 */
export interface IUserInterface {
  email: string
  name?: string
  fullName?: string
  profilePicture?: string
  companyName?: string // <-- Used for newly registered users without a proper company
  image?: string
  jobTitle?: string
  createdDateTime?: Date
  notifications?: {
    assignedTaskMail?: boolean
  }
  modifiedDateTime?: Date
  deviceTokens?: string[]
  legacyDataForms?: FormsUserInterface
  legacyDataFloorplan?: FloorplanUserInterface

  companies?: RelationInterface[]
  projects?: RelationInterface[]
}
