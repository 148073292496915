import { Component, inject } from "@angular/core"
import { CompanyFeatures, FilestackUploadResult } from "@models/common"
import { FilestackService, UserService } from "@services"

@Component({
  selector: "profile-view",
  templateUrl: "./profile-view.component.html",
  styleUrls: ["./profile-view.component.scss"],
})
export class ProfileViewComponent {
  public readonly userService: UserService = inject(UserService)
  private readonly filestackService: FilestackService = inject(FilestackService)

  readonly CompanyFeatures = CompanyFeatures

  savePersonal(personalData: any) {
    this.userService.updateCurrentUser(personalData)
  }

  async uploadProfileImage() {
    const result = await this.filestackService.pick({ storeTo: { location: "gcs", path: "avatars/" } })

    return this.handleFilesUpload(result)
  }

  private handleFailedFilesUpload(failedFiles: any) {
    // TODO implement
    console.error("Failed upload:", failedFiles)
  }

  private handleFilesUpload(uploadResult: FilestackUploadResult) {
    if (uploadResult.filesFailed != null && uploadResult.filesFailed.length > 0) {
      return this.handleFailedFilesUpload(uploadResult.filesFailed)
    }

    if (uploadResult.filesUploaded != null && uploadResult.filesUploaded.length > 0) {
      return this.userService.updateCurrentUser({ image: uploadResult.filesUploaded[0].url })
    }
  }
}
