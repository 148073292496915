import { Injectable } from "@angular/core"
import { AngularFirestore, AngularFirestoreDocument, DocumentChangeAction } from "@angular/fire/compat/firestore"
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap } from "rxjs/operators"
import { combineLatest, Observable } from "rxjs"
import firebase from "firebase/compat"
import User = firebase.User
import { AngularFireAuth } from "@angular/fire/compat/auth"

export interface IFieldChatUserData {
  uid: string
  name: string
  image: string
  email: string
  aggregateData: {
    companyUid?: string
  }
}

interface IFieldChatCompanyData {
  uid?: string
  name: string
}

export interface IFieldChatProjectData {
  uid: string
  name: string
}

@Injectable({
  providedIn: "root",
})
export class UserService {
  private _authState$: Observable<User> = this.auth.authState.pipe(filter(Boolean), shareReplay({ bufferSize: 1, refCount: true }))

  private _userUid$: Observable<string> = this._authState$.pipe(
    map((authState) => authState.uid),
    filter(Boolean),
    distinctUntilChanged()
  )

  private _userDocRef$: Observable<AngularFirestoreDocument<IFieldChatUserData>> = this._userUid$.pipe(
    map((uid) => this.db.collection("users").doc<IFieldChatUserData>(uid))
  )

  private _userCompanyRelations$: Observable<DocumentChangeAction<IFieldChatCompanyData>[]> = this._userUid$.pipe(
    switchMap((uid) => this.db.collection<IFieldChatCompanyData>(`users/${uid}/companies`).snapshotChanges())
  )

  private _userProjectRelations$: Observable<DocumentChangeAction<IFieldChatProjectData>[]> = this._userUid$.pipe(
    switchMap((uid) => this.db.collection<IFieldChatProjectData>(`users/${uid}/projects`).snapshotChanges())
  )

  public userEmail$: Observable<string> = this._authState$.pipe(
    map((authState) => authState.email),
    filter(Boolean),
    distinctUntilChanged()
  )

  public currentUser$: Observable<Partial<IFieldChatUserData> & { uid: string; ref: firebase.firestore.DocumentReference }> =
    this._userDocRef$.pipe(
      switchMap((docRef) => docRef.snapshotChanges()),
      map((doc) => ({ ...doc.payload.data(), uid: doc.payload.id, ref: doc.payload.ref })),
      filter(Boolean),
      shareReplay({ bufferSize: 1, refCount: true })
    )

  public readonly currentUserIsAdminForTheirCompany$: Observable<boolean> = this.currentUser$.pipe(
    distinctUntilChanged((previous, current) => previous.ref.path === current.ref.path),
    switchMap((currentUser) => this.db.doc(currentUser.ref.path).collection<{ labels?: string[] }>("companies").valueChanges()),
    map((colDocs) => (colDocs[0]?.labels ?? []).includes("ADMINISTRATOR")),
    startWith(false),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public userCompanyUid$: Observable<string> = this.currentUser$.pipe(
    map((data) => data.aggregateData?.companyUid),
    filter(Boolean),
    distinctUntilChanged()
  )

  public userCompany$ = this.userCompanyUid$.pipe(
    switchMap((companyUid) => this.db.collection("companies").doc<IFieldChatCompanyData>(companyUid).snapshotChanges()),
    filter(Boolean),
    map((doc) => ({ ...doc.payload.data(), uid: doc.payload.ref.id, ref: doc.payload.ref })),
    filter(Boolean),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  //TODO: Add a sort alphabetically
  public userProjects$: Observable<IFieldChatProjectData[]> = this._userProjectRelations$.pipe(
    switchMap((rels) =>
      combineLatest(rels.map((rel) => this.db.collection<IFieldChatProjectData>("projects").doc(rel.payload.doc.id).snapshotChanges()))
    ),
    map((docs) => docs.map((doc) => ({ ...(doc.payload.data() as IFieldChatProjectData), uid: doc.payload.ref.id, ref: doc.payload.ref }))),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  readonly userProjectsCount$ = this.userProjects$.pipe(map((p) => p.length))

  readonly userCompanyMembers$ = this.userCompany$.pipe(
    map((company) =>
      this.db.collection<{ targetPath: string }>(company.ref.path + "/users", (ref) => {
        return ref.where("disabled", "==", false)
      })
    ),
    switchMap((query) => query.valueChanges()),
    map((rels) => rels.map((rel) => rel.targetPath)),
    map((targetPaths) => targetPaths.map((path) => this.db.doc<IFieldChatUserData>(path).snapshotChanges())),
    switchMap((targets) => combineLatest(targets)),
    map((users) => users.filter((user) => user.payload.exists).map((user) => ({ ...user.payload.data()!, uid: user.payload.id })))
  )

  constructor(private db: AngularFirestore, private auth: AngularFireAuth) {}

  public async login(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password)
  }
}
