interface IImageCollageGenerationSettings {
  width: number
  height: number
  borderColor?: string
  borderWidth?: number
  autorotate?: boolean
  fit?: "auto" | "crop"
}

/**
 * Creates a single collage image out of several image URL's using Filestack.
 *
 * @param imageUrls the image URL's to be combined into a single collage image
 * @param collageGenerationSettings Misc. settings to specify how the collage is put together. width and height are required attributes.
 */
export function createImageCollageUrl(
  filestackApiKey: string,
  imageUrls: string[],
  collageGenerationSettings: IImageCollageGenerationSettings
) {
  const FILESTACK_URL_BASE = `https://process.filestackapi.com/${filestackApiKey}`
  const FILESTACK_URL_COLLAGE = `${FILESTACK_URL_BASE}/collage`

  // NB I couldn't decide if I should just return null here or throw an exception.
  //    It depends a bit on how we're going to use this function elsewhere.
  //    If an exception fits better, just change it.
  if (imageUrls.length < 1) {
    return null
  }

  // Don't make a collage if there is just one image URL.
  // Just make a thumbnail with the default size used elsewhere.
  if (imageUrls.length < 2) {
    return getThumbnailUrlWithDimensions(filestackApiKey, imageUrls[0], collageGenerationSettings.width, collageGenerationSettings.height)
  }

  // Filestack's collage api requires one of the images to be the last component of the URL.
  //   So we treat the first image URL differently and pass the rest of the URL's to the files parameter.
  const imageFirst = imageUrls[0]
  const imageRest = imageUrls.slice(1).map((url) => encodeURIComponent(url))

  const parameters = [
    `files:${JSON.stringify(imageRest)}`,
    `width:${collageGenerationSettings.width}`,
    `height:${collageGenerationSettings.height}`,
    `color:${collageGenerationSettings.borderColor || "000000"}`,
    `margin:${Math.max(collageGenerationSettings.borderWidth || 1, 1)}`,
    `autorotate:${collageGenerationSettings.autorotate || false}`,
    `fit:${collageGenerationSettings.fit || "auto"}`,
  ]

  return `${FILESTACK_URL_COLLAGE}=${parameters.join(",")}/${imageFirst}`
}

export function getThumbnailUrl(filestackApiKey: string, url: string, width: number, format = "jpg", fit = "max", align = "center") {
  const FILESTACK_URL_BASE = `https://process.filestackapi.com/${filestackApiKey}`
  const baseURL = FILESTACK_URL_BASE
  const rotateOpts = "/rotate=deg:exif"
  const resizeOpts = `/resize=width:${width},height:${width},fit:${fit},align:${align}`
  const partialURL = `${baseURL}${rotateOpts}${resizeOpts}`

  return `${partialURL}/output=format:${format}/${url}`
}

export function getThumbnailUrlWithDimensions(
  filestackApiKey: string,
  url: string,
  width: number,
  height: number,
  format = "jpg",
  fit = "max",
  align = "center"
) {
  const FILESTACK_URL_BASE = `https://process.filestackapi.com/${filestackApiKey}`
  const baseURL = FILESTACK_URL_BASE
  const rotateOpts = "/rotate=deg:exif"
  const resizeOpts = `/resize=width:${width},height:${height},fit:${fit},align:${align}`
  const partialURL = `${baseURL}${rotateOpts}${resizeOpts}`

  return `${partialURL}/output=format:${format}/${url}`
}
