import { CheckdColors } from "@checkd-colors"
import { Drawing } from "../drawing"
import { DrawingMenuActions, IMenuOption } from "./menu-actions.interface"

function createDrawingMenuActionData(drawing: Drawing, drawingMenuAction: DrawingMenuActions): IMenuOption {
  switch (drawingMenuAction) {
    case DrawingMenuActions.RENAME_DRAWING:
      return {
        name: "Rename drawing",
        color: CheckdColors.CHECKD_DARK,
        action: DrawingMenuActions.RENAME_DRAWING,
      }

    case DrawingMenuActions.REMOVE_DRAWING:
      return {
        name: "Remove drawing",
        color: CheckdColors.CHECKD_RED,
        action: DrawingMenuActions.REMOVE_DRAWING,
        confirmationDialog: {
          title: "Are you sure?",
          content: `Drawing ${drawing.name || ""} and all its items will be removed for all users.`,
        },
      }

    default:
      // @ts-ignore
      return null
  }
}

export { createDrawingMenuActionData }
