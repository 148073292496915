import { Component, Inject } from "@angular/core"
import { MAT_DIALOG_DATA } from "@angular/material/dialog"
import { GeneralReport } from "@models/common/general-report"

interface IReportPdfLinkDialogData {
  report: GeneralReport
  url: string
}

@Component({
  selector: "checkd-report-pdf-link-dialog",
  templateUrl: "./report-pdf-link-dialog.component.html",
  styleUrls: ["./report-pdf-link-dialog.component.scss"],
})
export class ReportPdfLinkDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IReportPdfLinkDialogData) {}
}
