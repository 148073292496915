<div class="drawing-list-item" matTooltip="{{ drawing?.name }}">
  <div class="drawing-list-item-image" (click)="onDrawingClick()">
    <img *ngIf="drawingThumbnail()" alt="{{ drawing?.name }}" [src]="drawingThumbnail()" />
  </div>

  <div class="drawing-list-item-info flex justify-content-between align-items-end">
    <div class="left-info flex flex-column align-items-end">
      <span class="drawing-name">{{ drawing?.name }}</span>

      <span class="items-count"
        >{{ itemsCountStr }}
        <ng-container *ngIf="currentUserActiveItems">
          <span
            >, <span class="checkd-red">{{ currentUserActiveItems }}</span> active tasks</span
          >
        </ng-container></span
      >
    </div>

    <span class="right-actions">
      <checkd-drawing-contextmenu
        [drawing]="drawing"
        [userProjectRole]="userProjectRole"
        (menuOptionSelected)="menuOptionSelected.emit($event)"
      >
      </checkd-drawing-contextmenu>
    </span>
  </div>
</div>
