import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"

export interface ITextfieldDialogData {
  title: string
  content: string
  value?: string
  okValue?: string
  cancelValue?: string
  placeholder?: boolean
}

@Component({
  selector: "checkd-textfield-dialog",
  templateUrl: "./textfield-dialog.component.html",
  styleUrls: ["./textfield-dialog.component.scss"],
})
export class TextfieldDialogComponent {
  get isValidValue() {
    return this.data != null && this.data.value != null && this.data.value.trim && this.data.value.trim() !== ""
  }

  constructor(private dialogRef: MatDialogRef<TextfieldDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ITextfieldDialogData) {}

  cancel() {
    this.dialogRef.close(null)
  }
  ok() {
    if (this.isValidValue) {
      this.dialogRef.close(this.data)
    }
  }
}
