import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-chat-room-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="26" cy="26" r="25" fill="white" stroke="#075643" stroke-width="2" />
      <path
        d="M21.3 16.9C25.6088 16.9 29.1 19.855 29.1 23.5C29.1 27.145 25.6088 30.1 21.3 30.1C20.5988 30.1 19.92 30.0137 19.2712 29.8675C18.1237 30.58 16.4674 31.3 14.4356 31.3C14.0614 31.3 13.7224 31.0787 13.5757 30.7337C13.4289 30.3887 13.4997 29.9912 13.7527 29.7212C13.7722 29.7062 14.6018 28.8025 15.2149 27.6212C14.1443 26.4925 13.5 25.06 13.5 23.5C13.5 19.855 16.9924 16.9 21.3 16.9ZM19.6725 28.0787C20.22 28.2362 20.7675 28.3 21.3 28.3C24.6075 28.3 27.3 26.1475 27.3 23.5C27.3 20.8525 24.6075 18.7 21.3 18.7C17.9925 18.7 15.3 20.8525 15.3 23.5C15.3 24.82 15.9641 25.795 16.5214 26.3837L17.4038 27.3175L16.8116 28.4537C16.6778 28.6787 16.5274 28.9712 16.3706 29.2187C17.0348 29.0275 17.6888 28.7312 18.3263 28.3037L18.9525 27.9475L19.6725 28.0787ZM30.06 21.7075C34.2 21.865 37.5 24.7562 37.5 28.3C37.5 29.86 36.855 31.2925 35.7863 32.4212C36.3975 33.6025 37.2262 34.5062 37.2488 34.5212C37.5 34.7912 37.5713 35.1887 37.3913 35.5337C37.2787 35.8787 36.9375 36.1 36.5625 36.1C34.5338 36.1 32.8763 35.38 31.7288 34.6675C31.08 34.8137 30.4013 34.9 29.7 34.9C26.625 34.9 23.9663 33.3925 22.695 31.2062C23.3438 31.12 23.9663 30.9737 24.5588 30.7712C25.6088 32.1662 27.5213 33.1 29.7 33.1C30.2325 33.1 30.78 33.0362 31.3275 32.8787L32.0475 32.7475L32.6737 33.1037C33.3113 33.5312 33.9638 33.8275 34.6313 34.0187C34.4738 33.7712 34.3238 33.4787 34.1888 33.2537L33.5963 32.1175L34.4775 31.1837C35.0363 30.5987 35.7 29.62 35.7 28.3C35.7 25.8137 33.3263 23.7662 30.2663 23.5225L30.3 23.5C30.3 22.8812 30.2175 22.2812 30.06 21.7075Z"
        fill="#075643"
      />
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class ChatRoomIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }
}
