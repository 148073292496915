import { Injectable } from "@angular/core"

export interface Size {
  sm: number
  md: number
  lg?: number
  xl?: number
}

@Injectable({
  providedIn: "root",
})
export class SvgIconsService {
  sizeMap: Size = {
    sm: 16,
    md: 24,
  }

  getSize(size: keyof Size | number): number | undefined {
    if (typeof size === "number") {
      return size
    } else {
      return this.sizeMap[size]
    }
  }
  getViewBox(size: keyof Size | number): string | undefined {
    const sizes = this.calculateViewBox(size)
    return sizes !== undefined ? `0 0 ${sizes} ${sizes}` : undefined
  }

  getScale(size: keyof Size | number): string | undefined {
    const scaleFactor = this.calculateScaleFactor(size)
    return scaleFactor !== undefined ? `scale(${scaleFactor})` : undefined
  }

  private calculateViewBox(size: keyof Size | number): number | undefined {
    if (typeof size === "number") {
      return size
    } else if (size === "sm") {
      return this.sizeMap.sm
    } else if (size === "md") {
      return this.sizeMap.md
    }

    return undefined
  }

  private calculateScaleFactor(size: keyof Size | number): number | undefined {
    if (typeof size === "number") {
      return size / this.sizeMap.md
    } else if (size === "sm") {
      return this.sizeMap.sm / this.sizeMap.md
    } else if (size === "md") {
      return 1
    }

    return undefined
  }
}
