import { Location } from "@angular/common"
import { Component, OnDestroy, OnInit } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { ActivatedRoute, ParamMap, Router } from "@angular/router"
import { Company, CompanyFeatures, LegacyReport, LegacyReportData, LegacyTemplate, LegacyTemplateData, Person } from "@models/common"
import { GeneralReport } from "@models/common/general-report"
import { TemplateSharingOption } from "@models/common/legacy-template.interface"
import { CompanyService, ExportService, ModelService, SnackbarService, UserService } from "@services"
import { DocumentHistoryService } from "@services/document-history.service"
import { ConfirmationService, MenuItem } from "primeng/api"
import { DialogService } from "primeng/dynamicdialog"
import { combineLatest, firstValueFrom, Observable, Subscription, timeout } from "rxjs"
import { map, shareReplay, switchMap } from "rxjs/operators"
import sanitize from "sanitize-filename"
import { ChangelogDialogComponent } from "../../components/changelog-dialog/changelog-dialog.component"
import { GeneralReportViewService } from "../../../../reports/general-report-view/general-report-view.service"
import { Template } from "../../../../template-creator/models/template"
import { LegacyTemplateService } from "../../../../template-creator/services/legacy-template.service"
import { TemplateCreationService } from "../../../../template-creator/services/template-creation.service"
import { IEditFormsTemplateDialogSaveData } from "../../edit-forms-template-dialog/edit-forms-template-dialog/edit-forms-template-dialog.component"
import { PublicTemplatesViewService } from "../../services/public-templates-view.service"
import { saveAs } from "file-saver"
import { transformLegacyToInternal } from "../../../../../../../functions/src/exports/forms-exports/transformations/legacy-to-internal"
import { transformInternalToExternal } from "../../../../../../../functions/src/exports/forms-exports/transformations/internal-to-external"
import { Form } from "@checkd-form/models/form"
import { TemplateUpdateDialogComponent } from "../../components/template-update-dialog/template-update-dialog.component"

@Component({
  selector: "checkd-form-landing-page",
  templateUrl: "./form-landing-page.component.html",
  styleUrls: ["./form-landing-page.component.scss"],
  providers: [DialogService],
})
export class FormLandingPageComponent implements OnInit, OnDestroy {
  editFormDialogOpen = false
  subscribeInProgress = false
  hasUpdate = true

  downloadMenuItems: MenuItem[] = [{ label: "Export JSON", command: () => this.exportJSON() }]

  legacyTemplate$: Observable<LegacyTemplate> = this.route.paramMap.pipe(
    map((params: ParamMap) => params.get("templateUid") || ""),
    switchMap((templateUid) => this.legacyTemplateService.listenToUid(templateUid)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  alreadySubscribedToTemplate$: Observable<boolean> = combineLatest([
    this.legacyTemplate$,
    this.publicTemplatesViewService.subscribedTemplates$,
  ]).pipe(
    map(
      ([currentTemplate, subscribedTemplates]) =>
        subscribedTemplates.findIndex((sub) => sub.data.originalTemplateUid === currentTemplate.uid) > -1
    )
  )

  currentlySubscribedTemplate$: Observable<LegacyTemplate | undefined> = combineLatest([
    this.legacyTemplate$,
    this.publicTemplatesViewService.subscribedTemplates$,
  ]).pipe(
    map(([currentTemplate, subscribedTemplates]) => subscribedTemplates.find((sub) => sub.data.originalTemplateUid === currentTemplate.uid))
  )

  subscribedTemplateIsOutdated$: Observable<boolean> = this.currentlySubscribedTemplate$.pipe(
    map((currentlySubscribedTemplate) => {
      if (currentlySubscribedTemplate && currentlySubscribedTemplate.templateUpdateInfo) return true
      return false
    })
  )

  addToTemplateButtonText$: Observable<string> = this.alreadySubscribedToTemplate$.pipe(
    map((alreadySubscribed) => (alreadySubscribed ? "Already added" : "Add to company"))
  )

  // For generating preview data
  template$: Observable<Template> = this.route.paramMap.pipe(
    map((params: ParamMap) => params.get("templateUid") || ""),
    switchMap((templateUid) => this.templateCreationService.listenToUid(templateUid)),
    map((legacyTemplate) => this.templateCreationService.transformLegacyTemplate(legacyTemplate))
  )

  templateCreatorCompany$: Observable<Company> = this.legacyTemplate$.pipe(
    switchMap((legacyTemplate) => this.companyService.listenToUid(legacyTemplate.aggregateData.templateCreatorCompanyUid || ""))
  )

  readonly userCanEditFormInfo$: Observable<boolean> = combineLatest([
    this.legacyTemplate$,
    this.userService.currentUser$,
    this.userService.currentUserIsCompanyAdmin$,
  ]).pipe(
    map(([template, user, userIsCompanyAdmin]) => {
      if (template.isStandard) {
        return true
      }

      let templateIsOwnedByUserCompany = false
      if (template.aggregateData?.templateCreatorCompanyUid && user.aggregateData?.["companyUid"]) {
        templateIsOwnedByUserCompany = !!(template.aggregateData.templateCreatorCompanyUid && user.aggregateData["companyUid"])
      }

      return userIsCompanyAdmin && templateIsOwnedByUserCompany
    })
  )

  readonly userCanClickUpdateFormButton$: Observable<boolean> = combineLatest([this.userCanEditFormInfo$, this.legacyTemplate$]).pipe(
    map(([userCanEditFormInfo, legacyTemplate]) => {
      return userCanEditFormInfo && !legacyTemplate.isStandard && !legacyTemplate.archived
    })
  )

  public readonly currentCompanyCanAddTemplateToCompany$: Observable<boolean> = combineLatest([
    this.userService.currentCompanyHasFeature(CompanyFeatures.FORMS),
    this.userService.currentCompanyHasFeature(CompanyFeatures.MEMBER_TEMPLATES_VIEWER),
  ]).pipe(map(([hasForms, isMemberTemplatesViewer]) => hasForms || isMemberTemplatesViewer))

  previewData: any[] = []
  subscriptions: Subscription[] = []

  TemplateSharingOption = TemplateSharingOption

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private legacyTemplateService: LegacyTemplateService,
    private templateCreationService: TemplateCreationService,
    private publicTemplatesViewService: PublicTemplatesViewService,
    private companyService: CompanyService,
    public userService: UserService,
    private confirmationService: ConfirmationService,
    private db: AngularFirestore,
    private snackbar: SnackbarService,
    private location: Location,
    private generalReportViewService: GeneralReportViewService,
    private documentHistoryService: DocumentHistoryService,
    private dialogService: DialogService,
    private exportService: ExportService
  ) {}

  public navigateBack() {
    this.location.back()
  }

  ngOnInit() {
    this.setupSubscriptions()
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  async openChangelogDialog(template: LegacyTemplate) {
    const currentlySubscribedTemplate = await firstValueFrom(this.currentlySubscribedTemplate$)
    let currentVersion = null

    if (currentlySubscribedTemplate) {
      currentVersion = currentlySubscribedTemplate.internalVersion
    }

    const ref = this.dialogService.open(ChangelogDialogComponent, {
      showHeader: false,
      width: "70%",
      height: "70%",
      contentStyle: {
        padding: 0,
        "border-radius": "20px",
      },
      data: {
        OGTemplateUid: template.uid,
        currentVersion,
      },
    })
  }

  async downloadPDF(template: LegacyTemplate, templateCreatorCompany: Company) {
    const legacyReportData = {
      headerTemplateData: "[]",
      detailTemplateData: JSON.stringify(this.previewData),
      reportName: template.name,
      name: template.name,
      status: "",
      description: "",
    } as LegacyReportData
    const form = Form.fromJson(new LegacyReport(legacyReportData, ""))
    const logo =
      template && template.logo ? template.logo : templateCreatorCompany && templateCreatorCompany.logo ? templateCreatorCompany.logo : ""
    const logoBase64 = await this.companyService.logoUrlToBase64(logo)
    const footerLogoBase64 = await this.companyService.logoUrlToBase64(template.footerLogo)

    this.exportService.exportReportToPdf(
      form,
      "",
      null,
      null,
      logoBase64,
      null,
      null,
      null,
      template.pdfFooterText || templateCreatorCompany.pdfFooter || "",
      template.publicVersion,
      footerLogoBase64
    )
  }

  async exportJSON() {
    const legacyTemplate = await firstValueFrom(this.legacyTemplate$.pipe(timeout(10)))

    if (!legacyTemplate) {
      return
    }

    const internalFormat = transformLegacyToInternal(legacyTemplate.data)
    const externalFormat = transformInternalToExternal(internalFormat)
    const filename = sanitize(`${legacyTemplate.name || "template_export"}.json`)

    saveAs(new Blob([JSON.stringify(externalFormat)]), filename)
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.template$.subscribe((template) => {
        this.previewData = this.templateCreationService.generatePreviewData(template.elements)
      }),
    ]
  }

  editFormButtonClicked() {
    if (this.editFormDialogOpen) {
      return
    }

    this.editFormDialogOpen = true
  }

  async addTemplateToCompanyButtonClicked(templateUid: string, copier: Person, currentCompany: Company) {
    try {
      this.subscribeInProgress = true
      const duplicatedTemplate = await this.publicTemplatesViewService.subscribeToTemplate(templateUid, copier, currentCompany)
      this.snackbar.showMessage(`You are now subscribed to template ${duplicatedTemplate.name}`)
    } finally {
      this.subscribeInProgress = false
    }
  }

  async saveButtonClicked(template: LegacyTemplate, data: IEditFormsTemplateDialogSaveData) {
    const updateData: Partial<LegacyTemplateData> = {
      reportName: data.reportName,
      description: data.description,
      sharedWith: data.sharedWith,
      // @ts-ignore
      internalVersion: (template.internalVersion ?? 0) + 1,
      publicVersion: data.publicVersion,
      tags: data.tags,
    }
    const currentUser = await firstValueFrom(this.userService.currentUser$.pipe(timeout(5000)))

    if (!currentUser) {
      return this.snackbar.showMessage("Error: couldn't fetch current user")
    }

    const batch = this.db.firestore.batch()
    const historyRef = await this.documentHistoryService.batchAddToHistory(batch, template, currentUser)
    this.documentHistoryService.batchAddChangelog(batch, template, {
      changelog: data.changelogText,
      historyUid: historyRef.id,
      editorUid: currentUser.uid,
      // @ts-ignore
      internalVersion: updateData.internalVersion,
      // @ts-ignore
      publicVersion: updateData.publicVersion,
    })
    template.batchUpdate(batch, updateData)
    await batch.commit()
  }

  updateFormButtonClicked(template: LegacyTemplate) {
    this.confirmationService.confirm({
      message: "Are you sure you want to update a published template? This will automatically unpublish it and set it as draft",
      accept: async () => {
        const batch = this.db.firestore.batch()
        this.publicTemplatesViewService.batchSetTemplateDraftState(batch, template.uid, true)
        this.publicTemplatesViewService.batchSetTemplateSharedWithStatus(batch, template.uid, TemplateSharingOption.NONE)
        await batch.commit()
        await this.publicTemplatesViewService.navigateToFormsBuilderWithTemplate(template.uid)
      },
    })
  }

  async updateTemplate() {
    const currentlySubscribedTemplate = await firstValueFrom(this.currentlySubscribedTemplate$)
    if (!currentlySubscribedTemplate) {
      return
    }

    const templateUid = currentlySubscribedTemplate.uid
    this.dialogService.open(TemplateUpdateDialogComponent, { header: "Updating template...", closable: false, data: { templateUid } })
  }
}
