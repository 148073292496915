<div class="card">
  <!--  TODO: This header should be sticky on top -->
  <div class="flex card-container bg-indigo-500 text-white font-bold">
    <div class="flex text-center p-4 cursor-pointer" (click)="closeDialog()"><i class="pi pi-arrow-left mr-3"></i>Back</div>
    <div class="flex-1 text-center p-4">Version history</div>
    <div class="flex text-center p-4 cursor-pointer" (click)="closeDialog()"><i class="pi pi-times"></i></div>
  </div>
</div>

<!--TODO: Make this layout look better-->
<ng-container *ngIf="changelog$ | async as changelog">
  <ng-container *ngIf="changelog.length; else noChangelog">
    <div class="ml-8 mr-8 mt-5" *ngFor="let change of changelog">
      <p class="text-3xl">
        {{ change.publicVersion === "" ? "" : change.publicVersion }}
        <span class="ml-6 text-lg"
          >{{ change.publishedAt.toDate().getDate() }}.{{ change.publishedAt.toDate().getMonth() }}.{{
            change.publishedAt.toDate().getFullYear()
          }}
        </span>
        <span class="ml-2">
          <p-tag *ngIf="isNewestVersion(change)" severity="warning">NEW</p-tag>
          <p-tag *ngIf="isCurrentVersion(change)" severity="primary">CURRENT</p-tag>
        </span>
      </p>
      <p class="text-base">{{ change.changelog === "" ? "No description" : change.changelog }}</p>

      <p-divider></p-divider>
    </div>
  </ng-container>

  <ng-template #noChangelog>
    <h1 class="text-center">No changes registered</h1>
  </ng-template>
</ng-container>
