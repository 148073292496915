import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from "@angular/core"
import { AngularFireFunctions } from "@angular/fire/compat/functions"
import { MatBottomSheet } from "@angular/material/bottom-sheet"
import { MatDialog } from "@angular/material/dialog"
import { ActivatedRoute, Router } from "@angular/router"
import { CheckdStatusFilterComponent, defaultStatusFilter, DisabledOverlayService } from "@checkd-ui"
import {
  ConfirmDialogComponent,
  ItemCreationDialogComponent,
  OptionListDialogComponent,
  PeopleListDialogComponent,
  SpinnerDialogComponent,
} from "@dialogs"
import { ItemDetailsDialogComponent, ItemImageGalleryDialogComponent, ItemListComponent } from "@items"
import {
  CompanyFeatures,
  Drawing,
  FilestackUploadResult,
  IMenuOption,
  Item,
  ItemMenuActions,
  ItemReportData,
  Person,
  Project,
  Report,
  ReportGenerationData,
  Role,
  RoleType,
  Task,
  TaskReportData,
  WorkflowStatusFilter,
} from "@models/common"
import { ReportGenerationDialogComponent, ReportSharingDialogComponent } from "@reports"
import {
  DrawingService,
  FilestackService,
  ItemFilterService,
  ItemService,
  ProjectService,
  ReportService,
  RoleHandlerService,
  SnackbarService,
  TimelineService,
  UserService,
} from "@services"
import { CheckdDrawingComponent } from "app/dashboard/checkd-ui/checkd-drawing/checkd-drawing.component"
import {
  IDuplicatonSettings,
  ItemDuplicationDialogComponent,
} from "app/dashboard/project/dialogs/item-duplication-dialog/item-duplication-dialog.component"
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs"
import { distinctUntilChanged, filter, map, share, shareReplay, switchMap, take } from "rxjs/operators"
import { FeatureCheckerService } from "../../features/feature-checker/services/feature-checker.service"
import { DrawingDetailsDialogComponent } from "../dialogs/drawing-details-dialog/drawing-details-dialog.component"
import { DrawingItemCreationDialogComponent } from "../dialogs/drawing-item-creation-dialog/drawing-item-creation-dialog.component"

@Component({
  selector: "app-drawing-view",
  templateUrl: "./drawing-view.component.html",
  styleUrls: ["./drawing-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawingViewComponent implements OnInit {
  statusFilter$ = new BehaviorSubject(defaultStatusFilter)

  // @ts-ignore
  projectUid$: Observable<string> = this.route.paramMap.pipe(
    map((paramMap) => paramMap.get("projectId")),
    filter((it): it is string => it !== null),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  // @ts-ignore
  drawingUid$: Observable<string> = this.route.paramMap.pipe(
    map((paramMap) => paramMap.get("drawingId")),
    filter((it): it is string => it !== null),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  project$: Observable<Project> = this.projectUid$.pipe(
    switchMap((uid) => this.projectService.listenToUid(uid)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  projectMembers$: Observable<Person[]> = this.project$.pipe(
    switchMap((project) => this.projectService.listenToPeople(project)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  drawing$: Observable<Drawing> = this.drawingUid$.pipe(
    switchMap((uid) => this.drawingService.listenToUid(uid)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  drawingItems$: Observable<Item[]> = this.drawing$.pipe(
    switchMap((drawing) => this.drawingService.listenToItems(drawing)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  currentUser$: Observable<Person> = this.userService.currentUser$
  userProjectRole$: Observable<Role> = this.projectService.currentUserProjectRole$

  drawingId: string
  projectId: string
  projectUid: string
  drawingUid: string

  drawingItems: Item[] = []

  roleFilteredDrawingItems$: Observable<Item[]> = combineLatest([
    this.drawingItems$,
    this.userProjectRole$,
    this.drawing$,
    this.currentUser$,
  ]).pipe(
    map((it) => {
      const [drawingItems, role, drawing, currentUser] = it

      return this.filterItemsOnRoleType(drawingItems, role, currentUser)
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  roleFilteredDrawingItems: Item[] = []
  selectedItems: Item[] = []

  statusFilteredDrawingItems$: Observable<Item[]> = combineLatest([this.roleFilteredDrawingItems$, this.statusFilter$]).pipe(
    map((it) => it[0].filter((item) => this.itemFilterService.hasIncludedStatus(item, it[1]))),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  finishedLoadingStatusFilteredDrawingItems$: Observable<boolean> = this.statusFilteredDrawingItems$.pipe(
    map((val) => true),
    take(1)
  )

  itemListFilteredDrawingItems: Item[] = []

  project: Project
  drawing: Drawing
  currentUser: Person
  userProjectRole: Role
  projectMembers: Person[] = []

  subscriptions: Subscription[] = []

  @ViewChild("checkdDrawing") drawingComponent: CheckdDrawingComponent
  @ViewChild("checkdItemList") itemListComponent: ItemListComponent
  @ViewChild("statusFilter") statusFilter: CheckdStatusFilterComponent

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private drawingService: DrawingService,
    private filestackService: FilestackService,
    private itemService: ItemService,
    private timelineService: TimelineService,
    private itemFilterService: ItemFilterService,
    private dialog: MatDialog,
    private userService: UserService,
    private firebaseFunctions: AngularFireFunctions,
    private reportService: ReportService,
    private roleHandlerService: RoleHandlerService,
    private snackbarService: SnackbarService,
    private zone: NgZone,
    private bottomSheet: MatBottomSheet,
    private cdr: ChangeDetectorRef,
    private disabledOverlay: DisabledOverlayService,
    private featureCheckerService: FeatureCheckerService
  ) {}

  ngOnInit() {
    this.setupSubscriptions()
  }

  get hasSelectedItems() {
    return this.selectedItems.length > 0
  }

  filterItemsOnRoleType(items: Item[], role: Role, currentUser: Person) {
    return role.roleType == RoleType.PROJECT_OWNER || role.roleType == RoleType.PROJECT_ADMINISTRATOR
      ? items
      : items.filter((item) => this.itemFilterService.isUserItem(item, currentUser, true))
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.projectUid$.subscribe((uid) => {
        this.projectUid = uid
        this.projectService.setCurrentProject(uid)
      }),
      this.drawingUid$.subscribe((uid) => {
        this.drawingUid = uid
        this.drawingService.setCurrentDrawing(uid)
      }),
      this.currentUser$.subscribe((currentUser) => (this.currentUser = currentUser)),
      this.userProjectRole$.subscribe((role) => (this.userProjectRole = role)),
      this.projectMembers$.subscribe((projectMembers) => (this.projectMembers = projectMembers)),
      this.project$.subscribe((project) => {
        this.project = project
        if (this.project.lockedByCheckd) {
          return this.showDisabledOverlay(project)
        }
        if (
          !(
            this.project.aggregateData &&
            this.project.aggregateData.companyFeatures &&
            this.project.aggregateData.companyFeatures.includes(CompanyFeatures.DRAWINGS)
          )
        ) {
          return this.featureCheckerService.displayMissingFeatureOverlay(CompanyFeatures.DRAWINGS, `projects/${project.uid}`)
        }
      }),
      this.drawing$.subscribe((drawing) => {
        this.drawing = drawing
      }),
      this.drawingItems$.subscribe((items) => {
        this.drawingItems = items
      }),
      this.roleFilteredDrawingItems$.subscribe((items) => (this.roleFilteredDrawingItems = items)),
    ]
  }

  showDisabledOverlay(project: Project) {
    const ref = this.disabledOverlay.openProjectLockedOverlay(project)
  }

  createTaskReportData(task: Task): Promise<TaskReportData> {
    return Report.createTaskReportData(task)
  }

  createItemReportData(item: Item): Promise<ItemReportData> {
    return Report.createItemReportData(item)
  }

  generateReport() {
    const itemUids = Array.from(new Set(this.selectedItems.map((item) => item.uid)))
    const dialog = this.dialog.open(ReportGenerationDialogComponent, {
      data: {
        totalItemCount: (this.drawingItems || []).length,
        filteredItemCount: (itemUids || []).length,
        project: this.project,
        creator: this.currentUser,
        drawingUid: this.drawingUid,
        reportType: "DRAWING",
      },
    })
    dialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((reportGenerationData: ReportGenerationData) => {
        if (reportGenerationData == null) {
          return
        }
        reportGenerationData.items = itemUids
        if (reportGenerationData.name != null && reportGenerationData.name != "") {
          const reportGenerationFunction = this.firebaseFunctions.httpsCallable("generateFieldReport")

          const spinnerDialog = this.dialog.open(SpinnerDialogComponent, {
            disableClose: false,
            data: {
              message: "Generating report - please wait.",
            },
          })

          reportGenerationFunction(reportGenerationData)
            .pipe(take(1))
            .subscribe((result) => {
              spinnerDialog.close()
              const sharingDialog = this.dialog.open(ReportSharingDialogComponent, {
                data: {
                  reportUid: result.reportUid,
                  reportSharingLink: this.reportService.createReportSharingLink(result.reportUid, "FIELD"),
                  showAnchor: true,
                  closeOnCopyLink: false,
                  message: `Report "${reportGenerationData.name}" is ready!`,
                },
              })
            })
        }
      })
  }

  bulkAssign() {
    const dialogRef = this.dialog.open(PeopleListDialogComponent, {
      // width: '250px',
      data: { people: this.projectMembers, title: "Select assignee" },
    })
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (selectedAssignee) => {
        if (selectedAssignee != null) {
          const items = this.selectedItems
          const assigner = this.currentUser
          const assignerProjectRole = await this.roleHandlerService.getPersonProjectRole(assigner, this.project)
          this.snackbarService.showMessage(`Assigning ${items.length} items...`)

          try {
            await this.itemService.bulkAssign(assigner, selectedAssignee, assignerProjectRole, this.selectedItems)
            this.snackbarService.showMessage(`Done assigning ${items.length} items!`)
          } catch (err) {
            this.snackbarService.showMessage(`ERROR: ${err.message}`)
          }
        }
      })
  }

  // TODO Figure out if we need this method for something
  itemListChange(event: any) {}

  itemSelected(item: Item) {
    this.zone.run(() => {
      this.dialog.open(ItemDetailsDialogComponent, {
        panelClass: "no-padding-dialog",
        maxWidth: "100vw",
        maxHeight: "100vh",
        height: "60%",
        width: "100%",
        position: {
          bottom: "0px",
          left: "0px",
        },
        autoFocus: false,
        data: {
          item,
          project: this.project,
          projectMembers: this.projectMembers,
          userProjectRole: this.userProjectRole,
          withCarousel: true,
        },
      })
    })
  }

  openDrawingDetail(drawing: any) {
    const dialogRef = this.dialog
      .open(DrawingDetailsDialogComponent, {
        data: {
          drawing: this.drawing,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((drawingData) => {
        if (drawingData) {
          this.drawing.ref!.update({ name: drawingData.name, storage: drawingData.storage })
        }
      })
  }

  goToDrawingsList() {
    this.router.navigate([this.router.url.substring(0, this.router.url.lastIndexOf("/"))])
  }

  async createDrawingItem() {
    const [creator, drawing, project] = [this.currentUser, this.drawing, this.project]

    const dialogRef = this.dialog.open(DrawingItemCreationDialogComponent, {
      data: {
        drawing,
        project,
        projectMembers: this.projectMembers,
        currentUser: this.currentUser,
      },
    })

    const { itemData, taskData } = (await dialogRef.componentInstance.onSave.pipe(take(1)).toPromise())!
    const { item, task } = await this.itemService.createDrawingItem(creator, project, drawing, itemData, taskData)
    this.snackbarService.showMessage(`Done creating item ${item.name} in drawing ${drawing.name}!`)
  }

  onItemSelected(event: Item) {
    this.itemSelected(event)
  }

  async duplicateItemOnDrawing(item: Item) {
    this.dialog
      .open(ItemDuplicationDialogComponent, {
        data: { item },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result: IDuplicatonSettings) => {
        if (result != null && result.numberOfDuplicates != null) {
          this.snackbarService.showMessage(`Duplicating item ${item.number} ${result.numberOfDuplicates} time(s)...`)
          const duplicationPromises = []

          for (let i = 0; i < result.numberOfDuplicates; i++) {
            duplicationPromises.push(this.itemService.duplicateDrawingItem(item, this.project, this.drawing, this.currentUser))
          }

          await Promise.all(duplicationPromises)
          this.snackbarService.showMessage(`Done duplicating item ${item.number} (${result.numberOfDuplicates} times)!`)
        }
      })
  }

  removeItem(item: Item) {
    return this.itemService.disable(item)
  }

  async closeItem(item: Item) {
    this.snackbarService.showMessage(`Closing item ${item.number}...`)
    await this.itemService.close(this.currentUser, item)
    this.snackbarService.showMessage(`Item ${item.number} is closed!`)
  }

  async editItemDetails(item: Item) {
    const dialogRef = this.dialog.open(ItemCreationDialogComponent, {
      width: "400px",
      data: { item, currentUser: this.currentUser, project: this.project },
    })
    const { itemData, dueDateChanged } = (await dialogRef.componentInstance.onSave.pipe(take(1)).toPromise())!
    await this.itemService.updateItem(item, itemData, this.currentUser, dueDateChanged)
    this.snackbarService.showMessage(`Item ${item.number} updated!`)
  }

  executeItemAction(item: Item, optionSelected: { action: ItemMenuActions }) {
    switch (optionSelected.action) {
      case ItemMenuActions.DUPLICATE_ITEM:
        return this.duplicateItemOnDrawing(item)
      case ItemMenuActions.REMOVE_ITEM:
        return this.removeItem(item)
      case ItemMenuActions.SET_AS_CLOSED:
        return this.closeItem(item)
      case ItemMenuActions.EDIT_DETAILS:
        return this.editItemDetails(item)
      case ItemMenuActions.UPLOAD_IMAGE:
        return this.uploadImage(item)
      case ItemMenuActions.VIEW_IMAGES:
        return this.openImageGallery(item)
      default: {
        break
      }
    }
  }

  onMenuOptionSelected(event: { action: ItemMenuActions; item: Item }) {
    const { action, item } = event
    this.executeItemAction(item, { action })
  }

  openConfirmationDialog(title: string, content: string) {
    return this.dialog
      .open(ConfirmDialogComponent, {
        data: { title, content },
      })
      .afterClosed()
      .pipe(take(1))
      .toPromise()
  }

  async openItemActionsDialog(menuOptions: IMenuOption[], item: Item) {
    const optionSelected = await this.dialog
      .open(OptionListDialogComponent, {
        data: {
          list: menuOptions,
          title: `Choose action for item ${item.number}`,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .toPromise()

    if (optionSelected != null) {
      if (optionSelected.confirmationDialog != null) {
        const confirmed = await this.openConfirmationDialog(
          optionSelected.confirmationDialog.title,
          optionSelected.confirmationDialog.content
        )
        if (confirmed) {
          return this.executeItemAction(item, optionSelected)
        }
      } else {
        return this.executeItemAction(item, optionSelected)
      }
    }
  }

  async onItemContextMenu(item: Item) {
    this.zone.run(async () => {
      const menuOptions = this.itemService.getMenuOptions(item, item.creatorName, this.userProjectRole, this.currentUser)
      this.openItemActionsDialog(menuOptions, item)
    })
  }

  onStatusFilterChange(filter: WorkflowStatusFilter) {
    this.statusFilter$.next(filter)
  }

  onItemsFiltered(items: Item[]) {
    this.itemListFilteredDrawingItems = items
  }

  onItemsSelected(items: Item[]) {
    this.selectedItems = items
  }

  resetFilters() {
    this.statusFilter.clear()
    this.itemListComponent.clear()
  }

  openImageGallery(item: Item) {
    this.dialog.open(ItemImageGalleryDialogComponent, {
      data: { item },
    })
  }

  async uploadImage(item: Item) {
    const result: FilestackUploadResult = await this.filestackService.pick({ storeTo: { location: "gcs", path: "drawings/" } })
    await this.itemService.handleFilesUpload(item, result, this.currentUser)
    this.snackbarService.showMessage(`Done uploading image for item ${item.number || ""}`)
  }
}
