<ng-container *ngIf="backendReady$ | async; else loading">
  <ng-container *ngIf="userService.currentUser$ | async as currentUser">
    <ng-container *ngIf="userCanAcceptInvite(targetUser, currentUser); else userCannotAcceptInvite">
      <p-card header="Accept invite" styleClass="card">
        <p>
          Your company has been invited by {{ invitation.invitedByUser.name }} in {{ invitation.invitedByPublisherCompany.name }} to see
          their Forms Templates.
        </p>
        <p>Do you want to accept this invitation?</p>
        <ng-template pTemplate="footer">
          <p-button
            [disabled]="isInProgress"
            label="Decline"
            icon="pi pi-times"
            styleClass="p-button-secondary"
            (click)="declineInvitation()"
          ></p-button>
          <p-button [disabled]="isInProgress" label="Accept" icon="pi pi-check" (click)="acceptInvitation()"></p-button>
        </ng-template>
      </p-card>
    </ng-container>

    <ng-template #userCannotAcceptInvite>Invalid invite</ng-template>
  </ng-container>
</ng-container>

<ng-template #loading>
  <p-progressSpinner></p-progressSpinner>
</ng-template>
