import { Injectable } from "@angular/core"
import { Invitation } from "@models/common"
import { Person } from "@models/common/person"
import { Project } from "@models/common/project"
import { Role } from "@models/common/roles-and-permissions"
import { ProjectInvitationDialogComponent } from "@project"
import { DialogService } from "../../dialogs/dialog.service"
import {
  IMemberRemovalDialogData,
  ProjectMemberRemovalDialogComponent,
} from "../project-member-removal-dialog/project-member-removal-dialog.component"
import { PendingInvitationRemovalDialogComponent } from "../../invitations/dialogs/pending-invitation-removal-dialog/pending-invitation-removal-dialog.component"
import { CsvExportDialogComponent } from "../dialogs/csv-export-dialog/csv-export-dialog.component"

@Injectable({
  providedIn: "root",
})
export class ProjectDialogService {
  constructor(private dialogService: DialogService) {}

  showPeopleInvitationDialog(
    inviterRole: Role,
    companyName: string,
    companyMembers: Person[],
    companyAssociates: Person[],
    project: Project
  ) {
    return this.dialogService.openDialog(ProjectInvitationDialogComponent, {
      data: {
        inviterRole,
        companyMembers,
        companyAssociates,
        projectName: project.data.name,
        companyName,
      },
      disableClose: true,
    })
  }

  showRemoveUserDialog(dialogData: IMemberRemovalDialogData) {
    return this.dialogService.openDialog(ProjectMemberRemovalDialogComponent, {
      data: dialogData,
    })
  }

  showCsvExportDialog() {
    return this.dialogService.openDialog(CsvExportDialogComponent, {})
  }
}
