import { Component, OnInit } from "@angular/core"
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms"
import { Company } from "@models/common"
import { InvitationService, SnackbarService, UserService } from "@services"
import { ConfirmationService } from "primeng/api"
import { combineLatest, Observable, startWith } from "rxjs"
import { map, tap } from "rxjs/operators"
import { MenuItem } from "primeng/api"
import { PublicTemplatesViewService } from "../../services/public-templates-view.service"
import { DialogService } from "app/dashboard/dialogs/dialog.service"

@Component({
  selector: "checkd-member-companies-page",
  templateUrl: "./member-companies-page.component.html",
  styleUrls: ["./member-companies-page.component.scss"],
})
export class MemberCompaniesPageComponent implements OnInit {
  emailAddressForm: UntypedFormGroup

  constructor(
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private fb: UntypedFormBuilder,
    private invitationService: InvitationService,
    private snackbarService: SnackbarService,
    private publicTemplateViewService: PublicTemplatesViewService,
    private dialogService: DialogService
  ) {
    this.emailAddressForm = this.fb.group({
      email: ["", [Validators.required, Validators.email, Validators.pattern("^(?=\\s*\\S).*$")]], // at least one non-whitespace character required
    })
  }

  inviteDialogVisible = false

  memberData$ = combineLatest([
    this.publicTemplateViewService.companyMembers$,
    this.publicTemplateViewService.companyMemberSubscriptions$,
  ]).pipe(startWith([[], []]))

  showConfirmRemoveMemberCompanyDialog(member: Company) {
    this.confirmationService.confirm({
      message: `Are you sure you want to remove ${member.name} as a member company?`,
      // TODO: remove/accept logic here
      accept: () => console.log("Accept clicked"),
      reject: () => {
        console.log("Reject clicked")
      },
    })
  }

  resetForm() {
    this.emailAddressForm.reset({ email: "" })
  }

  async sendInvitationClicked() {
    const emailFormControl = this.emailAddressForm!.get("email") as UntypedFormControl
    const targetEmail = emailFormControl.value as string

    try {
      await this.invitationService.createFormsLibraryMemberCompanyInvitation(targetEmail)
      this.snackbarService.showMessage(`Invitation sent to: ${targetEmail}`)
    } catch (e: any) {
      this.snackbarService.showMessage(e.message)
    }

    this.inviteDialogVisible = false
  }

  items: MenuItem[] = []

  ngOnInit(): void {
    this.items = [
      {
        label: "Actions",
        items: [
          {
            label: "Remove Company",
            icon: "pi pi-fw pi-trash",
            command: (event) => {
              this.dialogService.confirm("Remove Company", "Are you sure you want to remove the company?", false)
            },
          },
        ],
      },
    ]
  }
}
