import { Component, Inject, OnInit } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"

@Component({
  selector: "checkd-tags-selection-dialog",
  templateUrl: "./tags-selection-dialog.component.html",
  styleUrls: ["./tags-selection-dialog.component.scss"],
})
export class TagsSelectionDialogComponent implements OnInit {
  // report: GeneralReport = null
  tags: string[] = []
  tagSet: string[] = []

  // @ts-ignore
  updatedTags: string[] = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; tagSet: string[]; selectedTags: string[] },
    public dialogRef: MatDialogRef<TagsSelectionDialogComponent>
  ) {
    // this.report = data.report
    this.tagSet = data.tagSet || []
    // this.tags = this.report.tags || []
    this.tags = data.selectedTags
  }

  ngOnInit() {
    return
  }

  onSaveClicked() {
    if (this.updatedTags === null) {
      return
    }

    this.dialogRef.close(this.updatedTags)
  }

  onTagsUpdated(tags: string[]) {
    this.updatedTags = tags
  }
}
