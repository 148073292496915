<div class="dialog-content-wrapper">
  <ng-container [ngSwitch]="isLoading">
    <div *ngSwitchCase="true" class="spinner-container">
      <mat-spinner [diameter]="100" [strokeWidth]="10"></mat-spinner>
      <p>Generating preview...</p>
    </div>
    <div *ngSwitchCase="false" class="pdf-preview-container">
      <pdf-viewer [src]="pdfData" [render-text]="true" [original-size]="false" style="width: 400px; height: 500px"></pdf-viewer>
    </div>
  </ng-container>
</div>

<mat-dialog-actions align="end">
  <button mat-button (click)="close()">Close</button>
  <button mat-button [disabled]="isLoading" (click)="save()">Download</button>
</mat-dialog-actions>
