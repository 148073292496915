<div
  class="leaflet-container"
  [class.min-height]="forceMinHeight"
  leaflet
  leafletCheckdDrawing
  [leafletOptions]="drawingMapOptions"
  (leafletMapReady)="onMapReady($event)"
>
  <div
    *ngFor="let m of itemLayers"
    leafletCheckdItem
    [leafletLayer]="m"
    (itemClicked)="itemClicked($event, $event.target.options.item)"
  ></div>
</div>
