import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core"

@Component({
  standalone: true,
  selector: "field-chat-modal-dialog",
  templateUrl: "./modal-dialog.component.html",
  styleUrls: ["./modal-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDialogComponent {
  @Output() onClose = new EventEmitter<boolean>()

  description = ""

  confirmButtonLabel = "Yes, I'm sure"
  cancelButtonLabel = "No, cancel"

  closeOverlay(response: boolean) {
    this.onClose.emit(response)
  }
}
