import { ChangeDetectionStrategy, Component, Input, OnInit, Type } from "@angular/core"

@Component({
  selector: "field-chat-link-button",
  templateUrl: "./link-button.component.html",
  styleUrls: ["./link-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkButtonComponent implements OnInit {
  @Input() text: string = ""
  @Input() isDeleteLink: boolean = false
  @Input() componentToRender: Type<any>

  constructor() {}

  ngOnInit(): void {}
}
