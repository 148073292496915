<ng-container *ngIf="combinedInvitationData$ | async as data; else dataLoadingState">
  <ng-container *ngIf="data.isTargetMatch; else templateForDifferentUser">
    <h2>Welcome</h2>
    <ng-container [ngSwitch]="true">
      <p *ngSwitchCase="data.invitationCompany">
        You have been invited to join the company
        <span *ngIf="data?.companyName"> {{ data.companyName }}</span>
        <span *ngIf="data.sourceUserName"> by {{ data.sourceUserName }}</span>
      </p>

      <p *ngSwitchCase="data.invitationProjects">
        <span *ngFor="let projectName of data.invitationProjects">
          <p>{{ projectName }}</p>
          <p>
            You have been invited to join this project
            <span *ngIf="data.sourceUserName"> by {{ data.sourceUserName }}</span>
            <span *ngIf="data.companyName"> from {{ data.companyName }}</span>
          </p>
        </span>
      </p>

      <button [disabled]="acceptingInProgress" (click)="acceptInvitation(data.invitation)">Accept invitation</button>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #templateForDifferentUser>
  <h2>Invitation is for different user</h2>
</ng-template>

<ng-template #dataLoadingState>
  <h2>Loading...</h2>
</ng-template>
