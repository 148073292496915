import { Pipe, PipeTransform } from "@angular/core"
import { Person } from "@models/common"

@Pipe({
  name: "personToCompanyName",
})
export class PersonToCompanyNamePipe implements PipeTransform {
  /*
   * Fetches a user's company name from Person->aggregateData->companyName
   *
   * An optional `fallback` value can be provided as an argument. This is useful in case you don't want to display "Unknown Company"
   * for users that are not part of a company.
   *
   * Note that if aggregateData.companyName doesn't exist on the user document and no fallback is given,
   * an empty string will be returned
   */
  transform(value: Person, fallback: string | null = null): unknown {
    const companyName = value?.aggregateData?.["companyName"]

    if (companyName === undefined) {
      if (fallback) {
        return fallback
      }

      return ""
    }

    return companyName
  }
}
