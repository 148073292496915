import { Component, Input } from "@angular/core"
import { MatLegacySlideToggleChange as MatSlideToggleChange } from "@angular/material/legacy-slide-toggle"
import { Person } from "@models/common"

@Component({
  selector: "profile-settings",
  templateUrl: "./profile-settings.component.html",
  styleUrls: ["./profile-settings.component.scss"],
})
export class ProfileSettingsComponent {
  @Input() person: Person

  updateNotificationSetting(event: MatSlideToggleChange) {
    const propertyKey = event.source.id
    const propertyVal = event.checked
    const notifications = this.person.notifications

    // @ts-ignore
    if (notifications[propertyKey] == null || notifications[propertyKey] != propertyVal) {
      // @ts-ignore
      notifications[propertyKey] = propertyVal
      this.person.update({ notifications }).then((person) => (this.person = person))
    }
  }
}
