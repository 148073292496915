import { ChangeDetectionStrategy, Component } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-empty-channel-list-icon",
  standalone: true,
  imports: [CommonModule],
  template: ` <svg xmlns="http://www.w3.org/2000/svg" height="72" viewBox="0 0 640 512">
    <defs>
      <style>
        .fa-primary {
          fill: var(--next-orange-700);
        }
        .fa-secondary {
          opacity: 0.4;
          fill: var(--next-orange-300);
        }
      </style>
    </defs>
    <path
      class="fa-primary"
      d="M224 128A64 64 0 1 0 224 0a64 64 0 1 0 0 128zm352 64a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm64 256a64 64 0 1 0 -128 0 64 64 0 1 0 128 0zM64 368a64 64 0 1 0 0-128 64 64 0 1 0 0 128z"
    />
    <path
      class="fa-secondary"
      d="M276.4 100.7C264.9 117.2 245.7 128 224 128c-1.9 0-3.7-.1-5.6-.2l29.2 62.5C221.8 210.4 203 239 195.6 272H119.4c5.4 9.4 8.6 20.3 8.6 32s-3.1 22.6-8.6 32h76.1c14.6 64.1 71.9 112 140.4 112c40.8 0 77.7-17 103.9-44.3L512 446.9c.4-23.6 13.6-44.1 32.9-54.9l-72-43.2c4.6-14.1 7.1-29.2 7.1-44.8c0-20.3-4.2-39.7-11.8-57.2l82-60.2c-20.5-9-35.3-28.5-37.8-51.6l-82.1 60.2C405 173.3 372.1 160 336 160c-10.4 0-20.6 1.1-30.4 3.2l-29.2-62.5zM336 256a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
    />
  </svg>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class EmptyChannelListIconComponent {}
