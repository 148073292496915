import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-chat-info-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" [attr.viewBox]="getViewBox()" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2511_18151)" [attr.transform]="getScale()">
        <path
          d="M12 0C5.37188 0 0 5.37188 0 12C0 18.6281 5.37188 24 12 24C18.6281 24 24 18.6281 24 12C24 5.37188 18.6281 0 12 0ZM12 21.75C6.62344 21.75 2.25 17.3761 2.25 12C2.25 6.62391 6.62344 2.25 12 2.25C17.3766 2.25 21.75 6.62391 21.75 12C21.75 17.3761 17.3766 21.75 12 21.75ZM13.875 15.75H13.125V11.625C13.125 11.0063 12.6234 10.5 12 10.5H10.5C9.88125 10.5 9.375 11.0063 9.375 11.625C9.375 12.2437 9.88125 12.75 10.5 12.75H10.875V15.75H10.125C9.50625 15.75 9 16.2563 9 16.875C9 17.4937 9.50625 18 10.125 18H13.875C14.4961 18 15 17.4961 15 16.875C15 16.2539 14.4984 15.75 13.875 15.75ZM12 9C12.8283 9 13.5 8.32828 13.5 7.5C13.5 6.67172 12.8283 6 12 6C11.1717 6 10.5 6.67031 10.5 7.5C10.5 8.32969 11.1703 9 12 9Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2511_18151">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class ChatInfoIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }

  getViewBox(): string | undefined {
    return this.svgIconsService.getViewBox(this.size)
  }

  getScale(): string | undefined {
    return this.svgIconsService.getScale(this.size)
  }
}
