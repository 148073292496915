import { ElementOptionCode, TemplateElement, TemplateElementType } from "./template-element"

class DropdownValueElement extends TemplateElement {
  override name: string = ""
  override type: TemplateElementType = TemplateElementType.DROPDOWN_VALUE
  override typeAlias: string = "Dropdown"
  override value = false
}

class DropdownElement extends TemplateElement {
  override name: string = ""
  override icon?: string = "forms:dropdown"
  override type: TemplateElementType = TemplateElementType.DROPDOWN
  override typeAlias: string = "Dropdown"
  override value = ""
  override options = [
    {
      id: 0,
      name: "Add New",
      type: "checkbox",
      code: ElementOptionCode.ADDNEW,
      value: false,
    },
    {
      id: 0,
      name: "Multi Select",
      code: ElementOptionCode.ISMULTISELECT,
      type: "checkbox",
      value: false,
    },
  ]
}

export { DropdownElement, DropdownValueElement }
