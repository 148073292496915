import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { Company } from "@models/common"
import { isBetaElement, TemplateElement, TemplateElementType } from "../../models/template-elements/template-element"
import { TemplateCreationService } from "../../services/template-creation.service"

@Component({
  selector: "checkd-element-selection-menu",
  templateUrl: "./element-selection-menu.component.html",
  styleUrls: ["./element-selection-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElementSelectionMenuComponent implements OnInit {
  _elements: TemplateElement[] = this.tcs.getDefaultTemplateElements()

  // Elements we do not want to appear in the menu. Note that elements that require a company feature will be automatically filtered out
  @Input() filterElements: TemplateElementType[] = []

  @Input() company: Company

  // Emitted when an element in the dropdown is clicked
  @Output() onElementClicked = new EventEmitter<TemplateElement>()

  // Add reference to be able to use it in the HTML template
  readonly isBetaElement = isBetaElement

  constructor(private tcs: TemplateCreationService) {}

  ngOnInit(): void {
    const defaultElements = this._elements.filter((element) => !this.filterElements.includes(element.type))

    const featureBasedTemplateElements = this.tcs.getFeatureBasedTemplateElements(this.company)

    const featureElements = featureBasedTemplateElements.filter((element) => !this.filterElements.includes(element.type))

    this._elements = [...defaultElements, ...featureElements]
  }
}
