// NB: This enum should always correspond to the values in checkd-_colors.scss.
// Make sure to update the values there whenever you make any changes to this enum.
export enum CheckdColors {
  CHECKD_RED = "#FC6042",
  CHECKD_BLUE = "#415BD8",
  CHECKD_YELLOW = "#F8CB10",
  CHECKD_GREEN = "#19E57F",
  CHECKD_GRAY = "#9B9B9B",
  CHECKD_GRAY_TRANS = "rgba(95,95,95,0.5)",
  CHECKD_WHITE = "#FFFFFF",
  CHECKD_DARK = "#4a4a4a",
  CHECKD_74 = "#4A4A4A",
  CHECKD_MAIN_BG_COLOR = "#EFF3F6",
  CHECKD_ORANGE = "#FF8C19",
  CHECKD_BLACK = "#2C2C2C",
}
