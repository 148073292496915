<h1 mat-dialog-title>{{ title }}</h1>

<mat-dialog-content>
  <div class="language-selection">
    <span [class.active-language]="language == 'no'" (click)="language = 'no'">NO</span>
    /
    <span [class.active-language]="language == 'en'" (click)="language = 'en'">EN</span>
  </div>

  <img src="assets/images/checkd_figures_sign_updated_terms.svg" alt="sign updated terms graphic" />

  <p *ngIf="language == 'no'; else englishFirstParagraph">
    Hei, <a href="{{ privacyPolicyUrl }}" target="_blank">Personvern & Sikkerhet</a> og
    <a href="{{ termsAndConditionsUrl }}" target="_blank">Våre Vilkår</a> er nylig oppdatert.
  </p>

  <ng-template #englishFirstParagraph>
    <p>
      Hi, our <a href="{{ termsAndConditionsUrl }}" target="_blank">Terms & Conditions</a> and
      <a href="{{ privacyPolicyUrl }}" target="_blank">Privacy Policy</a> have recently been updated.
    </p>
  </ng-template>

  <p *ngIf="language == 'no'; else englishSecondParagraph">
    For å fortsette å bruke CHECKD-tjenesten, vennligst gå gjennom og godta våre oppdaterte vilkår.
  </p>

  <ng-template #englishSecondParagraph>
    <p>To continue using our CHECKD services, please review and agree to our updated terms.</p>
  </ng-template>

  <p>
    <strong>{{ agreeText }}</strong>
    <mat-slide-toggle #toggle></mat-slide-toggle>
  </p>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-button (click)="confirmButtonClicked()" [disabled]="!toggle.checked" class="checkd-btn save-btn-color">
    {{ buttonText }}
  </button>
</mat-dialog-actions>
