import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import { Router } from "@angular/router"
import { PeopleListDialogComponent } from "@dialogs"
import { Item, ItemData, Person, Project, TaskData, WorkflowStates } from "@models/common"
import { ItemService, ProjectService, TimelineService, UserService } from "@services"
import { take } from "rxjs/operators"

// Data saved in localStorage
interface IUnsavedData {
  itemDetailsForm: { name: string; description: string }
  itemData: ItemData
  selectedNextCustomer: any
  dueDate: Date
  tags: string[]
}

@Component({
  selector: "item-creation-view",
  templateUrl: "./item-creation-view.component.html",
  styleUrls: ["./item-creation-view.component.scss"],
})
export class ItemCreationViewComponent implements OnInit, OnDestroy {
  @Input() project: Project
  @Input() item: Item
  @Input() currentUser: Person
  @Input() projectMembers: Person[] = []
  @Input() prefill = { name: "", description: "", tags: [] as string[] }
  // @ts-ignore
  @Input() _dueDate: Date = null

  @Output() onSave = new EventEmitter()

  itemDetailsForm: UntypedFormGroup

  public tags: any[] = []
  public itemData: ItemData
  public taskData: TaskData

  public assigner: Person
  public assignee: Person

  // TODO: these should be part of itemDetailsForm
  private _dueDateInitialValue: Date | null = null
  private _tagsInitialValue: string[]

  selectedNextCustomer: any

  private shouldStoreUnsavedData = false
  private writeUnsavedDataToLocalStorageOnClose = true

  constructor(
    public dialog: MatDialog,
    public userService: UserService,
    public itemService: ItemService,
    public projectService: ProjectService,
    public db: AngularFirestore,
    public timelineService: TimelineService,
    private fb: UntypedFormBuilder, // public nextService: NextExperimentalService
    private router: Router
  ) {}

  get isInEditMode() {
    return !this.isCreatingNewItem
  }

  get isCreatingNewItem() {
    return this.item == null
  }

  get statusColorClass() {
    return this.itemService.statusToColorClass(this.taskStatus)
  }

  get taskStatus() {
    return this.taskData != null && this.taskData.status != null ? this.taskData.status : "OPEN"
  }

  get nextTaskNumber() {
    return this.project.nextTaskNumber
  }
  get assignerName() {
    return this.hasAssigner() ? this.assigner.name : ""
  }
  get assigneeName() {
    return this.hasAssignee() ? this.assignee.name : ""
  }

  get itemName() {
    const itemNumber = this.itemData?.number ? `#${this.itemData?.number} - ` : ""
    const itemStr = this.itemData?.name ?? ""
    const newStr = !this.hasItemName() ? "New Item" : ""

    return `${itemNumber}${newStr}${itemStr}`
  }

  get projectTags() {
    return this.project ? this.project.tags : []
  }

  hasItemName() {
    return this.itemData?.name != null && this.itemData.name.trim() !== ""
  }
  hasAssigner() {
    return this.assigner != null
  }
  hasAssignee() {
    return this.assignee != null
  }

  private restoreUnsavedDataFromLocalStorage() {
    const projectUid = this.project?.uid
    if (!projectUid) {
      return
    }

    const stringData = localStorage.getItem(projectUid)
    if (stringData === null) {
      return
    }

    const data: IUnsavedData = JSON.parse(stringData)

    this.itemDetailsForm = this.fb.group(data.itemDetailsForm)
    this.itemData = data.itemData
    this.selectedNextCustomer = data.selectedNextCustomer
    this.tags = this.itemData?.tags ?? []
    this._dueDate = data.dueDate
  }

  private hasUnsavedDataInLocalStorage() {
    const projectUid = this.project?.uid
    if (!projectUid) {
      return false
    }

    return localStorage.getItem(projectUid) !== null
  }

  private writeUnsavedDataToLocalStorage() {
    const projectUid = this.project?.uid
    if (!projectUid) {
      return
    }

    const data: IUnsavedData = {
      itemData: this.itemData,
      itemDetailsForm: this.itemDetailsForm.value,
      selectedNextCustomer: this.selectedNextCustomer,
      dueDate: this._dueDate,
      tags: this.tags,
    }

    localStorage.setItem(projectUid, JSON.stringify(data))
  }

  private populateNewItemDataWithoutRestoredData() {
    const controlsConfig = {
      name: [this.prefill?.name ?? "", [this.requiredNoWhitespaceOnlyValidator]],
      description: this.prefill?.description ?? "",
    }
    this.itemData = {
      // @ts-ignore
      name: this.prefill?.name ?? "",
      // @ts-ignore
      description: this.prefill?.description ?? "",
      itemType: "DEFAULT",
      status: "OPEN",
      dueDate: null,
      ...(this.prefill || {}),
    }

    this.tags = this.prefill?.tags ?? []
    this.itemDetailsForm = this.fb.group(controlsConfig)
  }

  private clearDataFromLocalStorage() {
    const projectUid = this.project?.uid
    if (projectUid) {
      localStorage.removeItem(projectUid)
    }
  }

  private shouldStoreAndRestoreUnsavedData(currentUrl: string) {
    // This should only match if the user is on the project items view.
    // Launching this dialog from other views (such as drawing view, etc.) should not
    const expression = /^\/projects\/[a-zA-Z0-9]+\/items$/

    return !this.prefill && this.isCreatingNewItem && expression.test(currentUrl)
  }

  ngOnInit() {
    this.shouldStoreUnsavedData = this.shouldStoreAndRestoreUnsavedData(this.router.url)

    // If item is null, then we are creating a new item
    if (this.item == null) {
      // Restore from localStorage if there's unsaved data
      if (this.shouldStoreUnsavedData && this.hasUnsavedDataInLocalStorage()) {
        try {
          this.restoreUnsavedDataFromLocalStorage()
        } catch (e: any) {
          console.error("An error occurred when trying to restore unsaved data from localStorage:", e)
          this.populateNewItemDataWithoutRestoredData()
        }
      } else {
        // New items without restoring unsaved data
        this.populateNewItemDataWithoutRestoredData()
      }
    } else {
      // .. restore item details for existing/saved item. Remove localStorage data
      this.clearDataFromLocalStorage()
      const controlsConfig = {
        name: [this.item?.name ?? "", [this.requiredNoWhitespaceOnlyValidator]],
        description: this.item?.description ?? "",
      }

      this.itemData = { ...this.item.data }
      this.tags = this.item.tags || []
      this.itemDetailsForm = this.fb.group(controlsConfig)
    }

    this._tagsInitialValue = [...(this.tags || [])]

    if (!this.itemData.tags) {
      this.itemData.tags = []
    }

    if (this.prefill && this.prefill.name && this.prefill.name.trim() !== "") {
      this.itemDetailsForm.markAsDirty()
    }
    this.initDueDatePicker()
  }

  public resetButtonClicked() {
    this.populateNewItemDataWithoutRestoredData()
  }

  ngOnDestroy() {
    if (this.shouldStoreUnsavedData && this.writeUnsavedDataToLocalStorageOnClose) {
      this.writeUnsavedDataToLocalStorage()
    }
  }

  requiredNoWhitespaceOnlyValidator(control: UntypedFormControl) {
    const notBlank = ((control && control.value && control.value.toString()) || "").trim().length > 0

    // returning null means that it's valid
    return notBlank ? null : { whitespace: true }
  }

  formIsValidAndValuesChanged(): boolean {
    if (!this.itemDetailsForm?.valid) {
      return false
    }

    return [
      this.itemDetailsForm.dirty,
      this._dueDate !== this._dueDateInitialValue,
      // @ts-ignore
      !this.tagsListsAreEqual(this._tagsInitialValue, this.itemData.tags),
    ].includes(true)
  }

  initDueDatePicker() {
    if (this.item && this.item.dueDate) {
      const date = new Date(0)
      date.setUTCSeconds(this.item.dueDate)

      this._dueDate = date
      this._dueDateInitialValue = this._dueDate
    }
  }

  assignUser() {
    const dialogRef = this.dialog.open(PeopleListDialogComponent, {
      minWidth: "250px",
      data: { people: this.projectMembers },
    })

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((assignee) => {
        if (assignee != null) {
          if (this.taskData == null) {
            this.updateTaskData({ status: "DELEGATED" })
          }

          const assigner = this.currentUser
          this.assigner = assigner
          this.assignee = assignee

          const taskAggregateData = (this.taskData || { aggregateData: {} }).aggregateData || {}
          this.updateTaskData({
            assigner: assigner.ref,
            assignee: assignee.ref,
            status: WorkflowStates.DELEGATED,
            aggregateData: {
              ...taskAggregateData,
              taskAssignerName: assigner.name,
              taskAssignerCompanyName: assigner.aggregateData["companyName"] || null,
              taskAssignerUid: assigner.uid,
              taskAssigneeName: assignee.name,
              taskAssigneeCompanyName: assignee.aggregateData.companyName || null,
              taskAssigneeUid: assignee.uid,
            },
          })

          const itemAggregateData = (this.itemData || { aggregateData: {} }).aggregateData || {}
          this.updateItemData({
            status: WorkflowStates.DELEGATED,
            aggregateData: {
              ...itemAggregateData,
              taskAssignerName: assigner.name,
              taskAssignerCompanyName: assigner.aggregateData["companyName"] || null,
              taskAssignerUid: assigner.uid,
              taskAssigneeName: assignee.name,
              taskAssigneeCompanyName: assignee.aggregateData.companyName || null,
              taskAssigneeUid: assignee.uid,
            },
          })
        }
      })
  }

  updateTaskData(data: any) {
    this.taskData = { ...(this.taskData || { status: WorkflowStates.OPEN }), ...data }
  }

  updateItemData(data: any) {
    this.itemData = { ...(this.itemData || { status: WorkflowStates.OPEN }), ...data }
  }

  onTagsUpdated(tags: string[]) {
    this.itemData.tags = tags
  }

  onDueDateDeleteClicked() {
    // @ts-ignore
    this._dueDate = null
    this.itemData.dueDate = null
  }

  save() {
    this.writeUnsavedDataToLocalStorageOnClose = false

    const itemDetailsChanged =
      // @ts-ignore
      (this.itemDetailsForm.valid && this.itemDetailsForm.dirty) || !this.tagsListsAreEqual(this._tagsInitialValue, this.itemData.tags)
    const dueDateChanged = this._dueDate !== this._dueDateInitialValue

    if (dueDateChanged) {
      this.itemData.dueDate = this._dueDate ? this._dueDate.valueOf() : null
    }

    this.onSave.emit({
      itemData: { ...this.itemData, ...this.itemDetailsForm.value },
      taskData: this.taskData,
      isInEditMode: this.isInEditMode,
      itemDetailsChanged,
      dueDateChanged,
      selectedNextCustomer: this.selectedNextCustomer,
    })

    this.clearDataFromLocalStorage()
  }

  public updateItemCoordinates(positionX: number, positionY: number) {
    this.itemData.positionX = positionX
    this.itemData.positionY = positionY
  }

  private tagsListsAreEqual(original: string[], updated: string[]) {
    if (original.length !== updated.length) {
      return false
    }

    const listA = [...original].sort()
    const listB = [...updated].sort()

    return listA.every((element, index) => {
      return element === listB[index]
    })
  }
}
