<div class="my-items" fxLayout="column">
  <div class="my-items__top-bar">
    <h1>My Items</h1>
    <div fxLayout="column" fxLayoutAlign="space-between none">
      <section class="filters" fxLayout="row">
        <div fxFlex="1 1 0"></div>

        <checkd-status-filter class="status-filter" #statusFilter [isForMyItemsPage]="true" (change)="onStatusFilterChange($event)">
        </checkd-status-filter>

        <div class="my-items__btn-container">
          <!-- <button fxHide.lt-md (click)="resetFilters()" mat-stroked-button>Clear filters</button> -->
          <button fxHide.lt-md (click)="resetFilters()" primary-button [type]="'outline'"><span>Clear filters</span></button>
        </div>
      </section>
    </div>
  </div>

  <checkd-item-list
    #checkdItemList
    [items]="filteredItems"
    [currentUser]="currentUser$ | async"
    [hideNewItemButton]="true"
    [hideItemsSelectedText]="true"
    [hideItemsActions]="true"
    [tableFor]="'myItems'"
    (itemSelected)="itemSelected($event)"
    (itemsFiltered)="onItemsFiltered($event)"
    [itemTags]="tagValues"
    [itemCreatorNames]="creatorValues"
    [taskAssignerNames]="assignerValues"
    [taskAssignerCompanyNames]="companyValues"
    [taskAssigneeNames]="assigneeValues"
    [drawingNames]="drawingValues"
    [projectNames]="projectValues"
    [isItemListReady]="isItemListReady"
  >
  </checkd-item-list>
</div>

<div class="floating-csv-export-button actions" fxLayout="column" fxLayoutGap="8px">
  <button primary-button [disabled]="csvExportInProgress" (click)="exportToCsv()"><span>Export to CSV</span></button>
  <!--  <checkd-button [inProgress]="csvExportInProgress" bg="white" fg="blue" (buttonClick)="exportToCsv()">Export to CSV</checkd-button>-->
  <!-- <checkd-button bg="blue" fg="white" (buttonClick)="generateReport()">Generate report</checkd-button> -->
</div>
