/**
 * Default settings for pdf generation settings.
 * These are used for backwards compatibility, i.e. they mimic the way PDF's were generated before we added these settings.
 */
export const DEFAULT_PDF_GENERATION_SETTINGS: IPdfGenerationSettings = {
  includeItemImageThumbnails: false,
  includeItemListImageSection: true,
  connectedItemsSection: "full",
  timezone: null,
  uploadPdfToNextProject: false,
}

export interface IPdfGenerationSettings {
  // TODO: Rename this property to includeItemThumbnails
  // Includes an extra row under item row (item list in pdf) or forms camera element  with image thumbnails (collage)
  includeItemImageThumbnails: boolean
  // Includes a table with pictures right under item table (in pdf) with high quality images and item information
  includeItemListImageSection: boolean
  // Chose either to view full connected items on the bottom of a report (with duplicates)
  // or filter out items that are already shown elsewhere in the report (forms item connection items)
  connectedItemsSection: "full" | "filtered"
  timezone: string | null
  uploadPdfToNextProject?: boolean
}

export interface IGeneratePdfConfig {
  reportDocumentPath?: string
  reportLink?: string
  result: "url" | "stream"
  pdfGenerationSettings: IPdfGenerationSettings
}
