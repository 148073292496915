import { COMMA, ENTER } from "@angular/cdk/keycodes"
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core"
import { UntypedFormControl, Validators } from "@angular/forms"
import { MatDialogRef } from "@angular/material/dialog"
import { MatLegacyChipInputEvent as MatChipInputEvent } from "@angular/material/legacy-chips"

@Component({
  selector: "checkd-report-email-dialog",
  templateUrl: "./report-email-dialog.component.html",
  styleUrls: ["./report-email-dialog.component.scss"],
})
export class ReportEmailDialogComponent implements OnInit {
  myControl = new UntypedFormControl("", [Validators.required, Validators.email])
  separatorKeysCodes: number[] = [ENTER, COMMA]

  emails: string[] = []
  @ViewChild("mailInput", { static: true }) mailInput: ElementRef<HTMLInputElement>

  constructor(private dialogRef: MatDialogRef<ReportEmailDialogComponent>) {}

  ngOnInit(): void {}

  add(event: MatChipInputEvent): void {
    const input = event.input
    const value = event.value

    if ((value || "").trim()) {
      this.emails.push(value.trim())
    }

    if (input) {
      input.value = ""
    }

    this.myControl.setValue(null)
  }

  extractEmails(text: string) {
    return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)
  }

  pasteEvent(event: ClipboardEvent) {
    const pasteText = event.clipboardData!.getData("text/plain")
    // @ts-ignore
    this.emails = this.extractEmails(pasteText)

    // Timeout required to clear input otherwise it doesnt work
    setTimeout(() => {
      this.mailInput.nativeElement.value = ""
      this.myControl.setValue(null)
    }, 100)
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email)

    if (index >= 0) {
      this.emails.splice(index, 1)
    }
  }

  sendEmails() {
    this.dialogRef.close({
      emails: ((this.emails || []) as string[]).map((it) => it.trim()).filter((it) => it),
    })
  }
}
