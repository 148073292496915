<span class="signature-screen">
  <div class="checkd-signature-element" (click)="openSignaturePad()" fxLayout="column">
    <p>
      <mat-icon>gesture</mat-icon> {{title}}
    </p>
    <div [ngClass]="(signatureUrl.length > 0) ? 'signature-size' : ''" fxLayout="row">
      <img *ngIf="signatureImage" [src]="signatureImage"/>
    </div>
  </div>
</span>

<span class="signature-print">
  <div fxLayout="column">
    <div [ngClass]="(signatureUrl.length > 0) ? 'signature-size' : ''" fxLayout="row">
      <img *ngIf="signatureImage" [src]="signatureImage"/>
    </div>
  </div>

</span>
