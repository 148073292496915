import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"

@Injectable()
export class SharedConfigService {
  readonly pageTitle = new BehaviorSubject<string>("")
  readonly showTopbar = new BehaviorSubject<boolean>(true)
  readonly showTopbarMenu = new BehaviorSubject<boolean>(true)
  readonly topbarMenuItems = new BehaviorSubject<any[]>([])
  readonly backButton = new BehaviorSubject<boolean>(false)

  setPageTitle(pageTitle: string) {
    this.pageTitle.next(pageTitle)
  }

  setTopbarVisibility(visible: boolean) {
    this.showTopbar.next(visible)
  }

  setTopbarMenuVisibility(visible: boolean) {
    this.showTopbarMenu.next(visible)
  }

  setTopbarMenuItems(items: any) {
    this.topbarMenuItems.next(items)
  }

  setBackButtonVisibility(visible: boolean) {
    this.backButton.next(visible)
  }
}
