import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { take } from "rxjs/operators"

import { Company, IMenuOption, Person, Project, ProjectMenuActions, Role } from "@models/common"

import { ConfirmDialogComponent } from "@dialogs"
import { ProjectService } from "@services"

@Component({
  selector: "project-contextmenu",
  templateUrl: "./project-contextmenu.component.html",
  styleUrls: ["./project-contextmenu.component.scss"],
})
export class ProjectContextmenuComponent implements OnInit {
  @Input() project: Project
  @Input() currentUser: Person
  @Input() currentCompany: Company
  @Input() userProjectRole: Role
  @Input() userCompanyRole: Role

  @Output() menuOptionSelected = new EventEmitter()

  menuOptions: IMenuOption[] = []

  constructor(public dialog: MatDialog, private projectService: ProjectService) {}

  get hasOptions() {
    return this.menuOptions.length > 0
  }

  ngOnInit() {
    // @ts-ignore
    this.menuOptions = this.projectService.getMenuOptions(this.project, this.userProjectRole, this.currentCompany, this.userCompanyRole)
  }

  // TODO: refactor duplicate settings into Interface
  menuClicked(action: ProjectMenuActions, confirmationDialog?: any, duplicateSettings?: any) {
    if (confirmationDialog) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: { title: confirmationDialog.title, content: confirmationDialog.content },
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe((data) => {
          if (data == true) {
            this.emitMenuSelection(action, duplicateSettings)
          }
        })
    } else {
      this.emitMenuSelection(action, duplicateSettings)
    }
  }

  private emitMenuSelection(action: ProjectMenuActions, duplicateSettings: any) {
    this.menuOptionSelected.emit({ project: this.project, action, duplicateSettings })
  }
}
