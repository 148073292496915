import { Component, OnInit } from "@angular/core"
import { MatDialogRef } from "@angular/material/dialog"
import { SnackbarService, UserService } from "../../../services"

@Component({
  selector: "checkd-invitation-login-dialog",
  templateUrl: "./invitation-login-dialog.component.html",
  styleUrls: ["./invitation-login-dialog.component.scss"],
})
export class InvitationLoginDialogComponent implements OnInit {
  email: string = ""
  password: string = ""

  constructor(
    private dialogRef: MatDialogRef<InvitationLoginDialogComponent>,
    private userService: UserService,
    private snackBar: SnackbarService
  ) {}

  ngOnInit(): void {}

  public async login(username: string, password: string) {
    try {
      const emailPattern = /^[^\s]+@[^\s]+\.[^\s]+$/i

      if (!emailPattern.test(username)) {
        return this.showErrorMessage("Please enter a valid email address.")
      }

      await this.userService.login(username, password)
      this.dialogRef.close()
    } catch (e) {
      switch (e.code) {
        case "auth/user-not-found": // User not created on firebase auth
          return this.showErrorMessage("User not found, Please register or contact support")
        case "auth/wrong-password":
          return this.showErrorMessage("Wrong username or password")
        default:
          return this.showErrorMessage("There was an error trying to log you in. Please contact support.")
      }
    }
  }

  showErrorMessage(errorMessage?: string) {
    this.snackBar.showMessage(errorMessage == null ? SnackbarService.LOGIN_ERROR : errorMessage)
  }

  get loginFieldsContainEmptyInputs(): boolean {
    return this.email.trim() === "" || this.password.trim() === ""
  }
}
