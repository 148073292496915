import { Component, Input, OnInit } from "@angular/core"
import { isBetaElement, TemplateElement } from "../../models/template-elements/template-element"

@Component({
  selector: "checkd-template-element",
  templateUrl: "./template-element.component.html",
  styleUrls: ["./template-element.component.scss"],
})
export class TemplateElementComponent implements OnInit {
  @Input() element: TemplateElement

  constructor() {
    return
  }

  ngOnInit() {
    return
  }

  readonly isBetaElement = isBetaElement
}
