import { TimelineType } from "@models/common/timeline.interface"

interface ICheckdEventData {
  [p: string]: CheckdEventPayload
}

type ITimelineEventMap = {
  [timelineType in TimelineType]: CheckdEventName
}

type CheckdEventName =
  | "none"

  // TODO These are deprecated - remove these
  | "project_person_removed"
  | "project_person_left"
  | "project_person_added"
  | "project_invitation_accepted"
  // END TODO
  | "task_delegated_by_user"
  | "user_invited_to_project_by_task_delegation"
  | "user_added_a_collaborator_on_a_task"
  | "user_added_a_comment_on_an_item"
  | "user_uploaded_image_to_an_item"
  | "user_removed_image_from_an_item"
  | "user_created_an_item"
  | "user_edited_an_item"
  | "user_archived_a_project"
  | "user_created_a_project"
  | "user_removed_a_project"
  | "user_accepted_a_project_invitation"
  | "user_sent_project_invitation"
  | "user_added_a_user_to_a_project"
  | "user_has_left_a_project"
  | "user_has_removed_a_project_member"
  | "user_has_unarchived_a_project"
  | "user_has_toggled_the_report_lock_feature"
  | "user_edited_report_tags"
  | "user_changed_status_of_an_item"
  | "user_created_a_forms_template"
  | "user_published_a_forms_template"
  | "user_unpublished_a_forms_template"
  | "report_shared_project_owners_admins"
  | "report_shared_project_members"
  | "report_shared_everyone"
  | "user_accepted_a_company_invitation"
  | "due_date_set"
  | "user_connected_item_to_report"
  | "user_removed_item_from_report"

type CheckdEventPayload = any

const TIMELINE_EVENT_MAP: ITimelineEventMap = {
  [TimelineType.ASSIGNEE_ADDED]: "task_delegated_by_user",
  [TimelineType.ASSIGNEE_INVITED]: "user_invited_to_project_by_task_delegation",
  [TimelineType.COLLABORATOR_ADDED]: "user_added_a_collaborator_on_a_task",
  [TimelineType.COMMENT_ADDED]: "user_added_a_comment_on_an_item",
  [TimelineType.IMAGE_ADDED]: "user_uploaded_image_to_an_item",
  [TimelineType.IMAGE_REMOVED]: "user_removed_image_from_an_item",
  [TimelineType.ITEM_CREATED]: "user_created_an_item",
  [TimelineType.ITEM_EDITED]: "user_edited_an_item",
  [TimelineType.COMPANY_INVITATION_ACCEPTED]: "user_accepted_a_company_invitation",
  [TimelineType.ITEM_EXPORTED_TO_BCF]: "none",
  [TimelineType.NONE]: "none",
  [TimelineType.PROJECT_ARCHIVED]: "user_archived_a_project",
  [TimelineType.PROJECT_CREATED]: "user_created_a_project",
  [TimelineType.PROJECT_DISABLED]: "user_removed_a_project",
  [TimelineType.PROJECT_INVITATION_ACCEPTED]: "user_accepted_a_project_invitation",
  [TimelineType.PROJECT_INVITATION_SENT]: "user_sent_project_invitation",
  [TimelineType.PROJECT_PERSON_ADDED]: "user_added_a_user_to_a_project",
  [TimelineType.PROJECT_PERSON_LEFT]: "user_has_left_a_project",
  [TimelineType.PROJECT_PERSON_REMOVED]: "user_has_removed_a_project_member",
  [TimelineType.PROJECT_UNARCHIVED]: "user_has_unarchived_a_project",
  [TimelineType.REPORT_READONLY_CHANGED]: "user_has_toggled_the_report_lock_feature",
  [TimelineType.STATUS_CHANGE]: "user_changed_status_of_an_item",
  [TimelineType.TEMPLATE_CREATED]: "user_created_a_forms_template",
  [TimelineType.TEMPLATE_PUBLISHED]: "user_published_a_forms_template",
  [TimelineType.TEMPLATE_UNPUBLISHED]: "user_unpublished_a_forms_template",
  [TimelineType.REPORT_SHARE_WITH_PROJECT_OWNERS_AND_ADMINS]: "report_shared_project_owners_admins",
  [TimelineType.REPORT_SHARE_WITH_PROJECT_MEMBERS]: "report_shared_project_members",
  [TimelineType.REPORT_SHARE_WITH_EVERYONE]: "report_shared_everyone",
  [TimelineType.REPORT_TAGS_EDITED]: "user_edited_report_tags",
  [TimelineType.DUE_DATE_SET]: "due_date_set",
  [TimelineType.LEGACY_TEMPLATE_REMOVED]: "none",
  [TimelineType.COMPANY_MEMBER_REMOVED]: "none",
  [TimelineType.REPORT_DUPLICATED]: "none",
  [TimelineType.ITEM_CONNECTED_TO_REPORT]: "user_connected_item_to_report",
  [TimelineType.ITEM_REMOVED_FROM_REPORT]: "user_removed_item_from_report",
}

export { ICheckdEventData, ITimelineEventMap, CheckdEventName, TIMELINE_EVENT_MAP }
