<ng-container *ngIf="hasOptions">
  <button mat-icon-button [matMenuTriggerFor]="rightActionsMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #rightActionsMenu="matMenu" overlapTrigger="false" xPosition="before">
    <button
      mat-menu-item
      *ngFor="let option of menuOptions"
      [ngStyle]="{ 'color': option.color }"
      (click)="menuClicked(option)">
      {{option.name}}
    </button>
  </mat-menu>
</ng-container>
