import { DrawingAggregateData } from "./aggregate-data"
import { BaseModelData } from "./base-model.interface"

export interface UrlInterface {
  url: string
  fileType?: string
}

export interface DrawingUrlData {
  url: string
  fileType?: string
}

export interface DrawingData extends BaseModelData {
  name: string
  drawingType?: string
  legacyDataFloorplan?: any
  storage: DrawingUrlData
  width?: number
  height?: number
  aggregateData?: DrawingAggregateData
}

/**
 * @deprecated use DrawingData instead
 */
export interface DrawingInterface {
  name: string
  drawingType?: string
  legacyDataFloorplan?: any
  storage: UrlInterface
  width?: number
  height?: number
}
