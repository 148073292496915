<div [hidden]="items == null || items.length < 1">
  <ng-container *ngIf="!belongsToDrawing">
    <span class="item-list-title">Items without locations or drawings</span>
  </ng-container>

  <div class="item-list-container">
    <table mat-table [dataSource]="dataSource">

      <!-- Number/status -->
      <ng-container matColumnDef="taskStatus" sticky>
        <th mat-header-cell *matHeaderCellDef> # </th>
        <td mat-cell *matCellDef="let item">
          <div>
            <div [class]="getWorkflowStatusClass(item.numberAndStatus.status)">
              {{getCorrectWorkflowStatus(item.numberAndStatus.status)}}
            </div>
            <checkd-status-icon
              [type]="item.numberAndStatus.status"
              [number]="item.numberAndStatus.itemNumber">
            </checkd-status-icon>
          </div>
        </td>
      </ng-container>

      <!-- NAME -->
      <ng-container matColumnDef="itemTitle">
        <th mat-header-cell *matHeaderCellDef>
          <span class="header-text">Title of item</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="item-title-column-text">{{element.name}}</span>
        </td>
      </ng-container>

      <!-- DESCRIPTION -->
      <ng-container matColumnDef="itemDescription">
        <th mat-header-cell *matHeaderCellDef>
          <span class="header-text">Description</span>
        </th>
        <td mat-cell *matCellDef="let element">
        <span *ngIf="!isDescriptionStringTooLong(element.description); else truncatedView">
          {{element.description}}
        </span>

          <ng-template #truncatedView>
            <div class="truncated-text" (click)="openDialog(element.description)">
              {{shortenDescriptionString(element.description)}}
            </div>
          </ng-template>
        </td>
      </ng-container>

      <!-- images -->
      <ng-container matColumnDef="images">
        <th mat-header-cell *matHeaderCellDef>
          <span class="header-text">Images</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <checkd-report-thumbnail-row [images]="element.images" [galleryID]="element.galleryID">
          </checkd-report-thumbnail-row>
        </td>
      </ng-container>

      <ng-container matColumnDef="itemTags">
        <th mat-header-cell *matHeaderCellDef>
          <span class="header-text">Tags</span>
        </th>
        <td mat-cell *matCellDef="let item">
          <checkd-tags [tags]="item.tags"></checkd-tags>
        </td>
      </ng-container>

      <!-- Item Creator -->
      <ng-container matColumnDef="creator">
        <th mat-header-cell *matHeaderCellDef>
          <span class="header-text">Created by</span>
        </th>
        <td mat-cell *matCellDef="let item"> {{item.itemCreatorName}} </td>
      </ng-container>

      <!-- Task assigner -->
      <ng-container matColumnDef="taskAssignerName">
        <th mat-header-cell *matHeaderCellDef>
          <span class="header-text">Assigned by</span>
        </th>
        <td mat-cell *matCellDef="let item"> {{item.taskAssignerName}} </td>
      </ng-container>

      <!-- Task assigner company -->
      <ng-container matColumnDef="taskAssignerCompanyName">
        <th mat-header-cell *matHeaderCellDef>
          <span class="header-text">Assigner company</span>
        </th>
        <td mat-cell *matCellDef="let item">
          {{item.taskAssignerCompanyName}}
        </td>
      </ng-container>

      <!-- Drawing -->
      <ng-container matColumnDef="drawingName">
        <th mat-header-cell *matHeaderCellDef>
          <span class="header-text">Drawing</span>
        </th>
        <td mat-cell *matCellDef="let item">
          {{item.drawingName}}
        </td>
      </ng-container>

      <!-- Created at -->
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>
          <span class="header-text">Created at</span>
        </th>
        <td  mat-cell *matCellDef="let item">
          {{item.createdAt | amFromUnix | amDateFormat: 'DD-MM-YYYY HH:mm'}}
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <span class="header-text">Created at</span>
        </td>
      </ng-container>

      <!-- Due at/Due date -->
      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef>
          <span class="header-text">Due at</span>
        </th>
        <td  mat-cell *matCellDef="let item">
          <ng-container *ngIf="item.dueDate">
            {{item.dueDate * 0.001 | amFromUnix | amDateFormat: 'DD-MM-YYYY HH:mm'}}
          </ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <span class="header-text">Due at</span>
        </td>
      </ng-container>

      <!-- Assigned to -->
      <ng-container matColumnDef="assignee">
        <th mat-header-cell *matHeaderCellDef>
          <span class="header-text">Assigned to</span>
        </th>
        <td mat-cell *matCellDef="let item">
          {{item.taskAssigneeName}}
        </td>
      </ng-container>

      <!-- DUMMY COLUMN/STICKY END -->
      <ng-container matColumnDef="end" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div class="table-footer" fxLayout="row" fxLayoutAlign="end">
    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      class="mat-paginator-header"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>

