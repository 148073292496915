import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { Form } from "@checkd-form/models/form"
import { GeneralReport } from "@models/common/general-report"

@Component({
  selector: "checkd-report-footer",
  templateUrl: "./report-footer.component.html",
  styleUrls: ["./report-footer.component.scss"],
})
export class ReportFooterComponent implements OnInit {
  @Input() form: Form
  @Input() report: GeneralReport
  @Input() hasReportLockFeature: boolean
  @Input() canLockAndUnlockReport: boolean
  @Input() isFormChanged: boolean
  @Input() canShareReport: boolean

  @Output() previewPdf = new EventEmitter()
  @Output() lockReport = new EventEmitter()
  @Output() save = new EventEmitter()
  @Output() shareLink = new EventEmitter()
  @Output() sendEmail = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  onPreviewPdf() {
    this.previewPdf.emit()
  }
  onLockReport() {
    this.lockReport.emit()
  }
  onSaveForm() {
    this.save.emit()
  }
  onShareLink() {
    this.shareLink.emit()
  }
  onSendEmail() {
    this.sendEmail.emit()
  }
}
