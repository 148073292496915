import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { IDocumentPermissions, RoleType } from "@models/common"

export type VisibilitySelection = "owners_and_admins" | "all_project_members" | "public_for_everyone"
interface IVisibilityOption {
  text: String
  selection: VisibilitySelection
}

export type ComponentSize = "dynamic" | "full" | "minimized"

@Component({
  selector: "checkd-report-visibility-toggle",
  templateUrl: "./report-visibility-toggle.component.html",
  styleUrls: ["./report-visibility-toggle.component.scss"],
})
export class ReportVisibilityToggleComponent implements OnInit {
  @Input() reportPermissions: IDocumentPermissions = {
    roles: {},
  }

  @Input() size: ComponentSize = "dynamic"
  /* Optional input value. Will use default if not provided */
  @Input() smallIconColor: string
  @Input() editable: boolean = false

  @Output() changes = new EventEmitter<VisibilitySelection>()

  _currentVisibility: VisibilitySelection

  readonly visibilityOptions: IVisibilityOption[] = [
    { text: "Project owners and admins", selection: "owners_and_admins" },
    { text: "All project members", selection: "all_project_members" },
    { text: "Open for everyone", selection: "public_for_everyone" },
  ]

  ngOnInit(): void {
    this.setInitialVisibility()
  }

  private setInitialVisibility() {
    let current: VisibilitySelection = "owners_and_admins"

    const roles = this.reportPermissions.roles
    // @ts-ignore
    if (roles[RoleType.NONE] && roles[RoleType.NONE].read) {
      current = "public_for_everyone"
    } else {
      // @ts-ignore
      if (roles[RoleType.PROJECT_NORMAL] && roles[RoleType.PROJECT_NORMAL].read) {
        current = "all_project_members"
      }
    }

    this._currentVisibility = current
  }

  set currentVisibility(visibility: VisibilitySelection) {
    if (!this.editable) {
      return
    }

    if (this._currentVisibility === visibility) {
      return
    }

    this._currentVisibility = visibility
    this.changes.emit(this._currentVisibility)
  }

  get currentVisibility(): VisibilitySelection {
    return this._currentVisibility
  }
}
