import { Component, Input, OnInit } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms"
import { Person } from "@models/common"
import { MessageService } from "primeng/api"

@Component({
  selector: "checkd-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.scss"],
})
export class SignInComponent implements OnInit {
  @Input() targetUser!: Person
  signInForm: UntypedFormGroup | null = null
  formSubmissionInProgress = false

  constructor(private afAuth: AngularFireAuth, private fb: UntypedFormBuilder, private messageService: MessageService) {}

  ngOnInit(): void {
    this.signInForm = this.fb.group({
      email: this.targetUser.email,
      password: ["", [Validators.required, Validators.pattern("^(?=\\s*\\S).*$")]],
    })

    const emailField = this.signInForm.get("email") as UntypedFormControl
    emailField.disable()
  }

  async submitHandler() {
    const email = this.signInForm!.get("email") as UntypedFormControl
    const password = this.signInForm!.get("password") as UntypedFormControl
    try {
      this.formSubmissionInProgress = true
      await this.afAuth.signInWithEmailAndPassword(email.value, password.value)
    } catch (e: any) {
      let detail = ""
      switch (e.code) {
        case "auth/invalid-email":
          detail = "Invalid email"
          break
        case "auth/user-disabled":
          detail = "This user is disabled"
          break
        case "auth/user-not-found":
          detail = "User not found"
          break
        case "auth/wrong-password":
          detail = "Wrong password"
          break
        default:
          detail = "An error has occurred"
          break
      }

      this.messageService.add({ severity: "error", detail, summary: "Error", life: 6000 })
    } finally {
      this.formSubmissionInProgress = false
    }
  }
}
