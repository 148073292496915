import { ModelInCollection, SortingDirection } from "../models/common"

/**
 *
 * @deprecated use orderByCreatedAt with ASC/DESC parameter instead
 */
export function orderByCreatedAtDesc<M extends ModelInCollection>(models: M[]) {
  return models.sort((pA, pB) => {
    const sA = pA.createdAt || 0
    const sB = pB.createdAt || 0

    return sA < sB ? 1 : sA > sB ? -1 : 0
  })
}

export function orderByCreatedAt<M extends ModelInCollection>(models: M[], direction: SortingDirection = SortingDirection.ASC) {
  return models.sort((pA, pB) => {
    const sA = direction === SortingDirection.ASC ? pB.createdAt || 0 : pA.createdAt || 0
    const sB = direction === SortingDirection.ASC ? pA.createdAt || 0 : pB.createdAt || 0

    return sA < sB ? 1 : sA > sB ? -1 : 0
  })
}

export function naturalSort(elements: string[]) {
  return elements.sort((a, b) =>
    a.localeCompare(b, ["no", "nb", "nn", "kn"], {
      sensitivity: "base",
      numeric: true,
    })
  )
}

export function naturalSortObjectsByProperty(objects: any[], key: string, direction: SortingDirection = SortingDirection.ASC) {
  return objects.sort((a, b) =>
    ((direction === SortingDirection.ASC ? a[key] : b[key]) || "").localeCompare(
      (direction === SortingDirection.ASC ? b[key] : a[key]) || "",
      ["no", "nb", "nn", "kn"],
      {
        sensitivity: "base",
        numeric: true,
      }
    )
  )
}

export function uniquifyAndSort(elementsA: string[], elementsB?: string[]) {
  return naturalSort(
    Array.from(new Set([...(elementsA || []).filter((it) => it), ...(elementsB || []).filter((it) => it)])).filter((it) => it)
  )
}

export function orderImagesByNumberAndTimestamp(images: any[]): any[] {
  return images.sort((imageA, imageB) => {
    return (
      (imageA.number || 0) - (imageB.number || 0) ||
      (imageA.createdAt || 0) - (imageB.createdAt || 0) ||
      ((imageA.imageData || {}).createdAt || 0) - ((imageB.imageData || {}).createdAt || 0)
    )
  })
}
