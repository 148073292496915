<span class="element-screen">
  <mat-accordion [displayMode]="'default'" [multi]="false">
    <mat-expansion-panel #panel>
      <mat-expansion-panel-header>{{title}}</mat-expansion-panel-header>
      <ng-container *ngFor="let c of tableChild; let i=index">

        <!--        This works for table child inside a legacy template preview (formsbuilder)-->
        <ng-container *ngIf="c.child[0].length">
          <app-form-element *ngFor="let subc of c.child[0]" [element]="subc" [readOnly]="readOnly"></app-form-element>
        </ng-container>

        <!--        This works for table child inside a legacy report-->
        <ng-container *ngIf="c.child[0].values.length">
          <app-form-element *ngFor="let subc of c.child[0].values" [element]="subc" [readOnly]="readOnly"></app-form-element>
        </ng-container>

      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</span>
