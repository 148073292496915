import { Injectable } from "@angular/core"

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  usedRandomNumbers: number[] = []

  constructor() {}

  private getRandomNumber() {
    return Math.floor(Math.random() * (Number.MAX_SAFE_INTEGER - 1))
  }

  getNextRandomNumber() {
    let n = this.getRandomNumber()
    while (this.usedRandomNumbers.indexOf(n) > -1) {
      n = this.getRandomNumber()
    }
    this.usedRandomNumbers = [...this.usedRandomNumbers, n]
    return n
  }
}
