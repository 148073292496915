import { FontSize, Style } from "./styles.docdefinition"

export const headerText = (text: string, fontSize: number, additionalStyles: Style[] = []) => ({
  text,
  fontSize,
  style: [Style.header, ...additionalStyles],
})

export const text = (text: string, fontSize: number = FontSize.default, additionalStyles: Style[] = []) => ({
  text,
  fontSize,
  style: [...additionalStyles],
})

export const columns = (...cols: any[]) => ({
  margin: [0, 0, 0, 10],
  columns: cols,
})

export const formCombo = (value: string, values: string[], multiSelect: boolean = true) => ({
  canvas: [
    { x: 0, y: 0, w: 1, h: 15, type: "formCombo", fillCellWidth: true, select: [""].concat(values), defaultValue: value, multiSelect },
  ],
})
