import { ElementOptionCode, OptionElement, TemplateElement, TemplateElementType } from "./template-element"

class CheckboxElement extends TemplateElement {
  override name: string = ""
  override icon?: string = "forms:checkbox"
  override type: TemplateElementType = TemplateElementType.CHECKBOX
  override typeAlias: string = "Checkbox"
  override value = ""
  override options: OptionElement[] = [
    {
      id: 0,
      name: "Vertical",
      code: ElementOptionCode.ISVERTICAL,
      type: "checkbox",
      value: false,
    },
    {
      id: 0,
      name: "Multi Select",
      code: ElementOptionCode.ISMULTISELECT,
      type: "checkbox",
      value: false,
    },
    {
      id: 2,
      name: "Checkbox Size",
      code: ElementOptionCode.CHECKBOX_SIZE,
      type: "size_dropdown",
      value: "medium",
    },
    {
      id: 3,
      name: "Border Color",
      type: "color",
      code: ElementOptionCode.CHECKBOX_BORDER_COLOR,
      value: "#000000",
    },
    {
      id: 4,
      name: "Hide title in PDF",
      type: "checkbox",
      code: ElementOptionCode.HIDE_TITLE_IN_PDF,
      value: false,
    },
  ]
}

export { CheckboxElement }
