<h1 matDialogTitle class="checkd-dark">Signature</h1>

<mat-dialog-content>
  <signature-pad [options]="signaturePadOptions"></signature-pad>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button class="checkd-btn cancel-btn-color" (click)="clear()">Clear</button>
  <button mat-button class="checkd-btn save-btn-color" (click)="save()">Save</button>
</mat-dialog-actions>
