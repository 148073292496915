<div class="project-report" fxLayout="row" (mouseEnter)="showMoreInfo(true)" (mouseLeave)="showMoreInfo(false)">
  <mat-card>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column">
        <span matToolTip="Click to open report in new window" class="report-name">
          <a [href]="reportUrl" target="_blank">{{report.name}}</a>
        </span>
        <span class="report-created-at">{{createdAt | amFromUnix | amDateFormat: 'YYYY-MM-DD HH:mm'}}</span>
      </div>
      <span class="report-creator">{{(reportCreator$ | async)?.name}}</span>
    </div>
  </mat-card>

  <span class="right-actions" fxLayout="row" fxLayoutAlign="begin center">
    <button *ngIf="!report.archived" mat-button class="right-actions-button" [matMenuTriggerFor]="rightActionsMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #rightActionsMenu="matMenu" overlapTrigger="false" xPosition="before">
      <button (click)="disableReport()" mat-menu-item>Delete report</button>
    </mat-menu>
    <span class="signature-indicator" *ngIf="hasSignatures" fxLayout="row">
      <mat-icon>gesture</mat-icon>
      <sup>{{signatureCount}}</sup>
    </span>

  </span>

</div>
