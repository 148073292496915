<div class="container w-full flex flex-column justify-content-center align-items-center">
  <ng-container *ngIf="invitationData$ | async as invitationData">
    <ng-container *ngIf="!invitationData.isLoading; else loadingState">
      <ng-container *ngIf="invitationData.data !== null; else inviteNotFound">
        <ng-container [ngSwitch]="invitationData.data.invitation.status">
          <ng-container *ngSwitchCase="'PENDING'">
            <ng-container *ngIf="invitationData.data.isUserSignedIn; else notSignedIn">
              <checkd-forms-library-accept-invitation-with-signed-in-user
                [invitation]="invitationData.data.invitation"
                [invitationUid]="invitationData.data.invitationUid"
                [targetUser]="invitationData.data.targetUser"
              ></checkd-forms-library-accept-invitation-with-signed-in-user>
            </ng-container>

            <ng-template #notSignedIn>
              <!-- This is now handled by one-login, component should redirect to one-login if not signed in -->
              <!-- TODO: remove this template after the redirect is properly tested -->
              <p>Redirecting to One-Login...</p>
              <!--              <checkd-not-signed-in [invitation]="invitationData.data.invitation" [targetUser]="invitationData.data.targetUser">-->
              <!--              </checkd-not-signed-in>-->
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="'ACCEPTED'">
            <p>This invitation has already been accepted</p>
          </ng-container>

          <ng-container *ngSwitchCase="'CANCELLED'">
            <p>This invitation has been cancelled.</p>
            <p>If this happened by accident, you need to request a new one</p>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<ng-template #inviteNotFound>
  <span>Invite was not found</span>
</ng-template>

<ng-template #loadingState>
  <p-progressSpinner></p-progressSpinner>
</ng-template>
