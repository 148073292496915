export interface StateData {
  name: string
  transitions: { [name: string]: TransitionData }
}

export interface TransitionData {
  actions?: any
  requirements?: any
}

export interface WorkflowData {
  name: string
  start: string
  stop: string
  current: string
  states: StateData[]
}

export enum WorkflowStates {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  DELEGATED = "DELEGATED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  FIXED = "FIXED",
  CANCELLED = "CANCELLED",
  FIXED_REJECTED = "FIXED_REJECTED",
  FIXED_ACCEPTED = "FIXED_ACCEPTED",
}

export enum WorkflowParentStates {
  OPEN = "OPEN",
  DELEGATED = "DELEGATED",
  INPROGRESS = "INPROGRESS",
  FIXED = "FIXED",
  CLOSED = "CLOSED",
}

export enum ReportStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}

export const WORKFLOW_STATES_MAPPING = {
  // TODO remove legacy 'CANCELLED' state when we are sure it isn't in use
  OPEN: ["OPEN", "REJECTED", "CANCELLED"], // NB removed legacy 'CREATED',  states
  DELEGATED: ["DELEGATED"],
  INPROGRESS: ["FIXED_REJECTED", "ACCEPTED"], // NB Remove legacy 'INPROGRESS' state
  FIXED: ["FIXED", "FIXED_ACCEPTED"],
  CLOSED: ["CLOSED"], // NB: Removed legacy 'COMPLETED' state
}
export const WORKFLOW_STATES_MAPPING_REVERSE = {
  OPEN: "OPEN",
  REJECTED: "OPEN",
  CREATED: "OPEN",
  CANCELLED: "OPEN",
  DELEGATED: "DELEGATED",
  INPROGRESS: "INPROGRESS",
  FIXED_REJECTED: "INPROGRESS",
  ACCEPTED: "INPROGRESS",
  FIXED: "FIXED",
  FIXED_ACCEPTED: "FIXED",
  CLOSED: "CLOSED",
  COMPLETED: "CLOSED",
}

export interface WorkflowStatusFilter {
  CLOSED: boolean
  DELEGATED: boolean
  FIXED: boolean
  INPROGRESS: boolean
  OPEN: boolean
}
