import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { MISSING_FEATURE_DIALOG_DATA } from "./feature-checker.interface"
import { FeatureCheckerService } from "./services/feature-checker.service"
import { CheckdCommonModule } from "@checkd-ui"
import { MissingFeatureDialogComponent } from "./dialogs/missing-feature-dialog/missing-feature-dialog.component"

@NgModule({
  declarations: [MissingFeatureDialogComponent],
  imports: [CommonModule, CheckdCommonModule],
  providers: [
    {
      provide: MISSING_FEATURE_DIALOG_DATA,
      useClass: FeatureCheckerService,
    },
  ],
})
export class FeatureCheckerModule {}
