/**
 * This enum contains all the different types of roles in the system.
 * Used for keys and/or property names.
 */

/**
 * This enum contains all the different types of roles in the system.
 * Used for keys and/or property names.
 */
export enum RoleType {
  USER = "USER",
  SYSTEM_ADMINISTRATOR = "SYSTEM_ADMINISTRATOR",
  COMPANY_ADMINISTRATOR = "COMPANY_ADMINISTRATOR",
  COMPANY_NORMAL = "COMPANY_NORMAL",
  PROJECT_OWNER = "PROJECT_OWNER",
  PROJECT_ADMINISTRATOR = "PROJECT_ADMINISTRATOR",
  PROJECT_ADMINISTRATOR_RESTRICTED = "PROJECT_ADMINISTRATOR_RESTRICTED",
  PROJECT_NORMAL = "PROJECT_NORMAL",
  PROJECT_NORMAL_RESTRICTED = "PROJECT_NORMAL_RESTRICTED",
  PROJECT_VIEWER = "PROJECT_VIEWER",
  DRAWING_VIEWER = "DRAWING_VIEWER",
  REPORT_CREATOR = "REPORT_CREATOR",
  LEGACY_REPORT_CREATOR = "LEGACY_REPORT_CREATOR",
  NONE = "NONE",
  ITEM_CREATOR = "ITEM_CREATOR",
}

/**
 * Contains all information for a given role.
 *
 * Name and description are human readable and meant to describe the
 * role type and permissions for non-programmers.
 *
 */
export interface RoleData {
  roleType: RoleType
  name: string
  description: string
  requirements: PermissionRequirement
  targetPermissions?: PermissionMap
  permissions: PermissionData[]
  inferredRoleTypes?: { [collectionName: string]: RoleType }

  sourceCollection?: string
  sourceUid?: string
  targetCollection?: string
  targetUid?: string
}

export interface PermissionData {
  description: string
  collection: string
  documentPermissions?: PermissionMap
  relationPermissions?: PermissionMap
}

export interface IPermissionDataOverride {
  documentPermissions?: PermissionMap
  relationPermissions?: PermissionMap
}

/**
 * Contains boolean properties for each CRUD operation.
 * Each property with a value of true indicates that such an operation is allowed.
 */
export interface PermissionMap {
  create: boolean
  read: boolean
  update: boolean
  delete: boolean
}

/**
 * Describes the requirements for getting a role type.
 *
 * To get a role, there must be a relation between the source and
 * target that satisfies the following requirements:
 *   - the source must have a relation starting from the sourceCollection (i.e. COLLECTIONS.PEOPLE)
 *   - the source must have a relation ending at the targetCollection (i.e. COLLECTIONS.COMPANIES)
 *   - the labels in this relation must include all of the labels
 *     present in the labels attribute in the PermissionRequirement
 */
export interface PermissionRequirement {
  sourceCollection: string
  labels: string[]
  targetCollection: string
}

export interface IDocumentPermissions {
  roles: { [roleType in RoleType]?: PermissionMap }
}

export interface IPartialDocumentPermissions {
  roles: { [roleType in RoleType]?: Partial<PermissionMap> }
}
