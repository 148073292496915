<div class="projects-list tw-py-2 tw-px-4 tw-h-full tw-gap-8 tw-flex tw-flex-col">
  <!-- Searchbar input -->
  <div class="projects-list__search-container tw-relative">
    <div class="tw-absolute tw-inset-y-0 tw-left-2 tw-flex tw-items-center tw-pointer-events-none">
      <img src="/assets/chat-images/icons/magnifying-glass-icon.svg" alt="magnifying icon" class="projects-list__icon" />
    </div>
    <input
      #searchInput
      type="search"
      id="default-search"
      class="tw-pl-12 tw-border-b-2 tw-w-full tw-border-brand-blue-500 tw-px-4 tw-py-2 focus: tw-outline-brand-blue-500 projects-list__input-text"
      placeholder="Search..."
      required
      (input)="updateInputSearchText(searchInput.value)"
    />
  </div>
  <div class="tw-flex tw-flex-col tw-gap-6">
    <div class="projects-list__projects" *ngIf="((filteredProjects$ | async) ?? []).length > 0; else noProjects">
      <ul class="tw-flex tw-flex-col tw-gap-6">
        <li
          class="tw-flex tw-gap-2 tw-items-center tw-relative tw-cursor-pointer"
          (click)="onProjectSelected.emit(project)"
          *ngFor="let project of filteredProjects$ | async"
        >
          <div
            class="tw-grid tw-place-items-center tw-text-xs tw-font-bold tw-text-white tw-bg-red-500 tw-border-white tw-rounded-full tw-top-2 tw-right-2 dark:tw-border-gray-900"
            [ngClass]="isUnreadMention ? 'tw-px-2 tw-py-1' : ' tw-p-2'"
            *ngIf="isThereANotification"
          >
            <span>{{ isUnreadMention }}</span>
          </div>
          <span class="tw-font-bold" [ngClass]="isActive ? 'tw-text-brand-blue-500' : 'tw-brand-text-500'">{{ project.name }}</span>
          <img class="tw-ml-auto" src="/assets/chat-images/icons/checked-icon.svg" alt="Checked icon" *ngIf="isActive" />
        </li>
      </ul>
    </div>
    <ng-template #noProjects>
      <p>No project matching your search...</p>
    </ng-template>
  </div>
</div>
