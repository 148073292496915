import { Component, Input, OnInit } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { Observable } from "rxjs"

import { Person, Report } from "@models/common"

import { ReportService } from "@services"

import { ConfirmDialogComponent } from "@dialogs"

@Component({
  selector: "field-report-list-item",
  templateUrl: "./field-report-list-item.component.html",
  styleUrls: ["./field-report-list-item.component.scss"],
})
export class FieldReportListItemComponent implements OnInit {
  @Input() report: Report

  shouldShowOptionsButton: boolean = false

  reportCreator$: Observable<Person>

  constructor(private dialog: MatDialog, private reportService: ReportService) {}

  ngOnInit() {
    this.reportCreator$ = this.reportService.listenToCreator(this.report)
  }

  get createdAt() {
    return this.report.createdAt / 1000
  }
  get signatures() {
    return this.report.signatures
  }
  get signatureCount() {
    return this.hasSignatures ? this.signatures.length : 0
  }
  get hasSignatures() {
    return this.report.signatures != null && this.report.signatures.length > 0
  }
  get reportUrl() {
    return this.reportService.createReportSharingLink(this.report.uid, this.report!.data!.reportType!)
  }

  showMoreInfo(shouldShow: boolean) {
    this.shouldShowOptionsButton = shouldShow
  }

  disableReport() {
    let confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Are you sure you want to delete the report "${this.report.name}"?`,
        content: "The report will be removed from this project and from any users list of reports.",
      },
    })

    confirmDialog.afterClosed().subscribe((result) => {
      if (result == true) {
        this.reportService.disableReport(this.report)
      }
    })
  }
}
