import { A11yModule } from "@angular/cdk/a11y"
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms"
import { BrowserModule } from "@angular/platform-browser"
import { InputTextareaModule } from "primeng/inputtextarea"
import { filter, map } from "rxjs/operators"
import { ChannelService } from "stream-chat-angular"

export interface ICreateChannelData {
  name: string
  description: string
}

@Component({
  selector: "field-chat-room-data",
  templateUrl: "./room-data.component.html",
  styleUrls: ["./room-data.component.scss"],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, A11yModule, InputTextareaModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomDataComponent implements OnInit {
  @ViewChild("submitButtonRef") submitButtonRef: HTMLButtonElement
  readonly channelData$ = this.channelService.activeChannel$.pipe(
    filter(Boolean),
    map((channel) => channel.data)
  )

  @Output() onClose = new EventEmitter<null | ICreateChannelData>()

  @Input() dialogTitleHeader: string = ""

  @Input() currentChannelName: string | undefined
  @Input() currentChannelDescription: string | undefined

  @Input() buttonTitle: string = ""

  registerChannelForm = this.fb.group({
    name: ["", [Validators.required]],
    description: "",
  })

  constructor(private channelService: ChannelService, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    if (this.currentChannelName !== undefined) {
      this.channelName.setValue(this.currentChannelName)
    }

    if (this.currentChannelDescription !== undefined) {
      this.channelDescription.setValue(this.currentChannelDescription)
    }
  }

  get channelName() {
    return this.registerChannelForm.get("name") as UntypedFormControl
  }

  get channelDescription() {
    return this.registerChannelForm.get("description") as UntypedFormControl
  }

  closeOverlay() {
    this.onClose.emit()
  }

  onSubmit() {
    const newChannelName = this.channelName.value as string
    const newChannelDescription = this.channelDescription.value as string
    // const dasherizedName = newChannelName.replace(/\s+/g, "-").toLocaleLowerCase()

    const data: ICreateChannelData = {
      name: newChannelName ?? "",
      description: newChannelDescription ?? "",
    }

    this.onClose.emit(data)
  }
}
