import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"

interface IFailedUploadsDialogData {
  failedUploads: string[]
  successfulUploads: string[]
}

@Component({
  selector: "checkd-failed-uploads-dialog",
  templateUrl: "./failed-uploads-dialog.component.html",
  styleUrls: ["./failed-uploads-dialog.component.scss"],
})
export class FailedUploadsDialogComponent {
  message: string = ""
  failedUploads: string[] = []
  successfulUploads: string[] = []

  constructor(
    public dialogRef: MatDialogRef<FailedUploadsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFailedUploadsDialogData
  ) {
    this.failedUploads = data.failedUploads || []
    this.successfulUploads = data.successfulUploads || []
    this.message = `We had some trouble uploading${
      this.failedUploads.length > 0 && this.successfulUploads.length > 0 ? " some of " : " "
    }your files.`
  }
}
