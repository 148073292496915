import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-custom-candy-box-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4195_19459)">
        <path
          d="M2.66667 9.33374C1.2 9.33374 0 10.5337 0 12.0004C0 13.4671 1.2 14.6671 2.66667 14.6671C4.13333 14.6671 5.33333 13.4671 5.33333 12.0004C5.33333 10.5337 4.13333 9.33374 2.66667 9.33374ZM21.3333 9.33374C19.8667 9.33374 18.6667 10.5337 18.6667 12.0004C18.6667 13.4671 19.8667 14.6671 21.3333 14.6671C22.8 14.6671 24 13.4671 24 12.0004C24 10.5337 22.8 9.33374 21.3333 9.33374ZM12 9.33374C10.5333 9.33374 9.33333 10.5337 9.33333 12.0004C9.33333 13.4671 10.5333 14.6671 12 14.6671C13.4667 14.6671 14.6667 13.4671 14.6667 12.0004C14.6667 10.5337 13.4667 9.33374 12 9.33374Z"
          fill="#FEFDFD"
        />
        <path
          d="M2.66667 18.4001C1.2 18.4001 0 19.6001 0 21.0668C0 22.5335 1.2 23.7335 2.66667 23.7335C4.13333 23.7335 5.33333 22.5335 5.33333 21.0668C5.33333 19.6001 4.13333 18.4001 2.66667 18.4001ZM21.3333 18.4001C19.8667 18.4001 18.6667 19.6001 18.6667 21.0668C18.6667 22.5335 19.8667 23.7335 21.3333 23.7335C22.8 23.7335 24 22.5335 24 21.0668C24 19.6001 22.8 18.4001 21.3333 18.4001ZM12 18.4001C10.5333 18.4001 9.33333 19.6001 9.33333 21.0668C9.33333 22.5335 10.5333 23.7335 12 23.7335C13.4667 23.7335 14.6667 22.5335 14.6667 21.0668C14.6667 19.6001 13.4667 18.4001 12 18.4001Z"
          fill="#FEFDFD"
        />
        <path
          d="M2.66667 0.266602C1.2 0.266602 0 1.4666 0 2.93327C0 4.39993 1.2 5.59993 2.66667 5.59993C4.13333 5.59993 5.33333 4.39993 5.33333 2.93327C5.33333 1.4666 4.13333 0.266602 2.66667 0.266602ZM21.3333 0.266602C19.8667 0.266602 18.6667 1.4666 18.6667 2.93327C18.6667 4.39993 19.8667 5.59993 21.3333 5.59993C22.8 5.59993 24 4.39993 24 2.93327C24 1.4666 22.8 0.266602 21.3333 0.266602ZM12 0.266602C10.5333 0.266602 9.33333 1.4666 9.33333 2.93327C9.33333 4.39993 10.5333 5.59993 12 5.59993C13.4667 5.59993 14.6667 4.39993 14.6667 2.93327C14.6667 1.4666 13.4667 0.266602 12 0.266602Z"
          fill="#FEFDFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_4195_19459">
          <rect [attr.width]="getSize()" [attr.height]="getSize()" fill="white" />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class CustomCandyBoxIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }
}
