import { Component, OnInit } from "@angular/core"
import { getCorrectOptionValue } from "@checkd-form/models/utilities"
import { take } from "rxjs/operators"
import { FormElement } from "../../../models/form-element"
import { DefaultElementComponent } from "../default-element/default-element.component"
import { AddOptionDialogComponent } from "./add-option-dialog/add-option-dialog.component"

@Component({
  selector: "app-dropdown-element",
  templateUrl: "./dropdown-element.component.html",
  styleUrls: ["./dropdown-element.component.scss"],
})
export class DropdownElementComponent extends DefaultElementComponent implements OnInit {
  // string[] if isMultiSelect === true, string otherwise
  dropdownValue: string | string[]

  override ngOnInit() {
    this.dropdownValue = this.value
  }

  get isMultiSelect(): boolean {
    const multiSelectOption = this.getOption("ismultiselect")

    if (!multiSelectOption) {
      return false
    }

    return getCorrectOptionValue(multiSelectOption.value) === true
  }

  get isAddable(): boolean {
    const addNewOption = this.getOption("addnew")

    if (!addNewOption) {
      return false
    }

    return getCorrectOptionValue(addNewOption.value) === true
  }

  selectionChange() {
    if (this.dropdownValue == null) {
      this.setValue("")
    } else {
      this.setValue(this.dropdownValue)
    }

    this.selectOption(this.dropdownValue)
  }

  private selectOption(selectedValue: string | string[]) {
    for (const element of this.element.values!) {
      element.value = element.name === selectedValue
    }
  }

  addOption(_: MouseEvent) {
    const dialogRef = this.dialogRef.open(AddOptionDialogComponent)
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result && result.trim()) {
          this.addOptionFormElement(result)
        }
      })
  }

  addOptionFormElement(value: string) {
    const newOptionFormElement = new FormElement()
    // @ts-ignore
    newOptionFormElement.id = this.element.values.length > 0 ? this.element.values[this.element.values.length - 1].id + 1 : 1
    newOptionFormElement.name = value
    this.element.values!.push(newOptionFormElement)

    if (!this.isMultiSelect) {
      this.dropdownValue = value
      this.element.value = value
    }
    this.selectionChange()
  }
}
