import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay"
import { ComponentPortal } from "@angular/cdk/portal"
import { EventEmitter, Injectable } from "@angular/core"
import { finalize, lastValueFrom } from "rxjs"
import { take, takeUntil } from "rxjs/operators"
import { ModalDialogComponent } from "../components/modal-dialog/modal-dialog.component"
import { RoomDataComponent } from "../components/room-data/room-data.component"

interface IConfirmationDialogOptions {
  description: string
  confirmButtonLabel?: string
  cancelButtonLabel?: string
}

@Injectable({
  providedIn: "root",
})
export class FieldChatDialogService {
  constructor(private overlayService: Overlay) {}

  /**
   * Creates an overlayRef (which is a PortalOutlet) with our standard configuration (which can be overridden in options)
   * @param overlayConfig Lets you customise and/or overwrite our standard overlay configuration
   * @private
   */
  private getOverlayRef(overlayConfig: Partial<OverlayConfig> = {}) {
    const defaultOverlayConfig: OverlayConfig = {
      hasBackdrop: true,
      positionStrategy: this.overlayService.position().global().centerHorizontally().centerVertically(),
      maxWidth: 400,
      width: "100%",
    }

    return this.overlayService.create({ ...defaultOverlayConfig, ...overlayConfig })
  }

  /**
   * Helper method to create a "racing" awaitable that emits either the result of the event emitter, or nothing if
   * the user clicks outside the dialog (i.e. the backdrop). Calls `detatch()` on the overlay on completion.
   *
   * @param overlayRef The portal outlet that contains the UI for the dialog including the backdrop
   * @param eventEmitter The event emitter for the UI component that emits when the user clicks the dialog to close it
   * @private
   */
  private createRacerAwaitable<T>(overlayRef: OverlayRef, eventEmitter: EventEmitter<T>): Promise<T | undefined> {
    // A notifying observable that completes if the user clicks on the backdrop
    const backdropListener$ = overlayRef.backdropClick()

    const racer$ = eventEmitter.pipe(
      take(1),
      takeUntil(backdropListener$),
      finalize(() => overlayRef.detach())
    )

    return lastValueFrom(racer$, { defaultValue: undefined })
  }

  /**
   * Returns an awaitable that opens a dialog with buttons for confirming and canceling.
   * Returns Promise<boolean>
   *
   * @param options Customise description and button labels
   */
  public openConfirmationDialog(options: IConfirmationDialogOptions) {
    const overlayRef = this.getOverlayRef()
    const componentRef = overlayRef.attach(new ComponentPortal(ModalDialogComponent))

    componentRef.instance.description = options.description
    if (options.confirmButtonLabel) {
      componentRef.instance.confirmButtonLabel = options.confirmButtonLabel
    }

    if (options.cancelButtonLabel) {
      componentRef.instance.cancelButtonLabel = options.cancelButtonLabel
    }

    return this.createRacerAwaitable(overlayRef, componentRef.instance.onClose)
  }

  /**
   * Returns an awaitable that opens a dialog to confirm deleting a channel.
   * The awaitable returns a boolean that returns true if the user confirmed, otherwise false,
   */
  async openDeleteChannelDialog() {
    const overlayRef = this.getOverlayRef()
    const componentRef = overlayRef.attach(new ComponentPortal(ModalDialogComponent))

    componentRef.instance.description = "Are you sure you want to delete this room?"

    return this.createRacerAwaitable(overlayRef, componentRef.instance.onClose)
  }

  /**
   * Returns an awaitable that opens the channel creation dialog.
   * The awaitable returns an object containing the channel name and description if the user fills in the information
   * and clicks the 'Create room' button, or nothing if the user cancels either by clicking the cancel button, or
   * clicks outside the dialog (on the backdrop)
   */
  openCreateChannelDialog() {
    const createRoomUiLabels = {
      header: "Create room",
      confirmButtonLabel: "Create room",
    }

    return this.openCreateOrEditChannelDialog(createRoomUiLabels)
  }

  /**
   * Returns an awaitable that opens the edit creation dialog.
   * The awaitable returns an object containing the channel name and description if the user fills in the information
   * and clicks the 'Create room' button, or nothing if the user cancels either by clicking the cancel button, or
   * clicks outside the dialog (on the backdrop)
   */
  openEditChannelDialog(currentChannelName: string, currentChannelDescription: string) {
    const editRoomUiLabels = {
      header: "Edit room",
      confirmButtonLabel: "Save room",
      channelName: currentChannelName,
      channelDescription: currentChannelDescription,
    }

    return this.openCreateOrEditChannelDialog(editRoomUiLabels)
  }

  /**
   * Helper function that creates an overlay for creating or editing rooms
   * @param uiLabels Specify what the labels in the dialog will display
   * @private
   */
  private openCreateOrEditChannelDialog(uiLabels: {
    header: string
    confirmButtonLabel: string
    channelName?: string
    channelDescription?: string
  }) {
    const overlayRef = this.getOverlayRef()
    const componentRef = overlayRef.attach(new ComponentPortal(RoomDataComponent))

    componentRef.instance.dialogTitleHeader = uiLabels.header
    componentRef.instance.buttonTitle = uiLabels.confirmButtonLabel
    componentRef.instance.currentChannelName = uiLabels.channelName
    componentRef.instance.currentChannelDescription = uiLabels.channelDescription

    return this.createRacerAwaitable(overlayRef, componentRef.instance.onClose)
  }
}
