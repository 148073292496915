<div fxLayout="column">
  <div fxLayout="column" fxLayoutGap="10px">
    <label>{{title}}</label>
    <mat-slide-toggle [checked]="value" [(ngModel)]="value" [disabled]="readOnly"></mat-slide-toggle>
  </div>

  <div *ngIf="isConditional()" class="toggle-options" fxLayout="row">

    <div *ngIf="value">
      <app-form-element *ngFor="let child of accepted" [element]="child" [readOnly]="readOnly"></app-form-element>
    </div>

    <div *ngIf="!value">
      <app-form-element *ngFor="let child of rejected" [element]="child" [readOnly]="readOnly"></app-form-element>
    </div>

  </div>
</div>
