import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop"
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { Company } from "@models/common/company"
import { flatten } from "lodash-es"
import { take } from "rxjs/operators"

import { Options } from "sortablejs"
import { DropdownValueElement } from "../../models/template-elements/dropdown-element"
import { TableChildElement, TableElement } from "../../models/template-elements/table-element"
import { ElementOptionCode, OptionElement, TemplateElement, TemplateElementType } from "../../models/template-elements/template-element"
import { TemplateCreationService } from "../../services/template-creation.service"
import { TableCellConfigDialogComponent } from "../table-cell-config-dialog/table-cell-config-dialog.component"
import { CheckboxValueElement } from "./../../models/template-elements/checbox-value-element"

@Component({
  selector: "checkd-template-element-config",
  templateUrl: "./template-element-config.component.html",
  styleUrls: ["./template-element-config.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateElementConfigComponent implements OnInit {
  ElementOptionCode = ElementOptionCode

  private _element: TemplateElement
  @Input()
  get element(): TemplateElement {
    return this._element
  }
  set element(element: TemplateElement) {
    this._element = element

    if (this._element.type === TemplateElementType.TABLE) {
      this.setupFillableTableChildren(element)
    }
  }

  @Output() elementRemoved: EventEmitter<TemplateElement> = new EventEmitter()
  @Output() duplicateClicked: EventEmitter<TemplateElement> = new EventEmitter()
  @Output() elementChange = new EventEmitter()
  // @Output() addChildren: EventEmitter<any> = new EventEmitter()

  @Input() currentCompany: Company

  @Input() allowConditionals: boolean = true
  @Input() embedInDropdown: boolean = true

  /** Just a reference to this enum so we can use it in the HTML file */
  readonly templateElementType = TemplateElementType

  opened: boolean = false

  get tableChildren(): TableChildElement[] {
    return ((this.element && this.element.children) || []) as TableChildElement[]
  }

  set tableChildren(tableChildren: TableChildElement[]) {
    this.element.children = tableChildren
  }

  get initialTableConfig(): TableChildElement[] {
    return [new TableChildElement(), new TableChildElement(), new TableChildElement(), new TableChildElement()]
  }

  public get numCols(): number {
    const options = (this.element as TableElement).options
    const tableColumns = options.find((option) => option.code === ElementOptionCode.TABLE_COLUMNS)

    return tableColumns?.value as number
  }

  public set numCols(n: number) {
    const options = (this.element as TableElement).options
    const tableColumns = options.find((option) => option.code === ElementOptionCode.TABLE_COLUMNS)
    // @ts-ignore
    tableColumns.value = n
  }

  updateTableChildren() {
    const splitIndexes = this.tableChildren.map((c, i) => (c.isOccupied ? null : i)).filter((it) => it != null)

    const chunks = []
    for (let i = 0; i < splitIndexes.length; i++) {
      const from = splitIndexes[i]
      const to = i === splitIndexes.length ? splitIndexes[i] : splitIndexes[i + 1]
      const chunk = this.tableChildren.slice(from!, to!)
      chunks.push(chunk)
    }

    const updatedChunks = []
    for (const chunk of chunks) {
      const firstTableChild = chunk[0]
      const cellCount = firstTableChild.requiredCellCount || 1

      // Remove table children if there are too many
      if (chunk.length > cellCount) {
        updatedChunks.push(chunk.slice(0, cellCount))
        continue
      }

      // Add new table children if there are too few
      if (chunk.length < cellCount) {
        for (let i = 0; i < cellCount - chunk.length; i++) {
          const t = new TableChildElement()
          t.isOccupied = true
          chunk.push(t)
        }
        updatedChunks.push(chunk)
        continue
      }

      // Push unmodified chunk if everything is ok
      updatedChunks.push(chunk)
    }

    const flattenedChunks = flatten(updatedChunks)

    this.tableChildren = flattenedChunks
    this.element.children = this.tableChildren
    this.onElementChange(this.element)
  }

  get tableColumnWidthOption() {
    return (this._element.options || []).find((it) => it.code === ElementOptionCode.TABLE_COLUMN_WIDTHS)
  }

  columnWidthChanged(withIndex: number, newValue: string) {
    // @ts-ignore
    this.tableColumnWidthOption.value[withIndex] = newValue
    this.onElementChange("foo")
  }

  /**  Tracks menu items in the dropdown menu where the user selects column widths
   *
   * Without this, the menu items are rendered with wrong values in the radio buttons when making changes */
  trackFnColumnWidthMenuByIndex(index: number): number {
    return index
  }

  increaseColumns() {
    // Note: After trackBy was added to the ngFor loop inside the column width menu (to fix another issue), change detection stopped working
    // (i.e. the number of menu items didn't update), so we can't use array mutation (array.push) to update array. Assigning a new array
    // thereby giving a new array reference fixes this issue.
    // @ts-ignore
    this.tableColumnWidthOption.value = [...this.tableColumnWidthOption.value, "evenly"]
    this.numCols += 1
    this.onElementChange(this.element)
  }

  isCellConfigured(tableChild: TableChildElement) {
    if (tableChild.isOccupied) {
      return true
    }

    return tableChild.children[0] !== undefined
  }

  getTableChildPreviewText(tableChild: TableChildElement) {
    // Is it a 'value' element?
    if (tableChild.isOccupied) {
      return "Fillable field"
    }

    const element: TemplateElement = tableChild.children[0]

    let str = `${element.typeAlias}`

    if (element.name) {
      str += `: ${element.name}`
    }

    return str
  }

  canDecreaseNumColumns(): boolean {
    // app will crash if we decrease numColumns below any cell's colspan
    let max_cell_colspan = Math.max(...this.tableChildren.map((child) => this.getColspanForCell(child)))
    max_cell_colspan = isFinite(max_cell_colspan) ? max_cell_colspan : 0

    return !(this.numCols <= 1 || this.numCols <= max_cell_colspan)
  }

  canIncreaseNumColumns(): boolean {
    return this.numCols < 9
  }

  decreaseColumns() {
    // app will crash if we decrease numColumns below any cell's colspan
    let max_cell_colspan = Math.max(...this.tableChildren.map((child) => this.getColspanForCell(child)))
    max_cell_colspan = isFinite(max_cell_colspan) ? max_cell_colspan : 0

    if (this.numCols <= 1 || this.numCols <= max_cell_colspan) {
      return
    }

    // @ts-ignore
    this.tableColumnWidthOption.value.pop()
    // Note: see comment in increaseColumns() for an explanation as to why this is necessary
    // @ts-ignore
    this.tableColumnWidthOption.value = [...this.tableColumnWidthOption.value]
    this.numCols -= 1
    this.onElementChange(this.element)
  }

  increaseColspan(tableChild: TableChildElement) {
    const colspanOption = this.getColspanOptionElement(tableChild)
    if (!colspanOption || colspanOption.value === this.numCols) {
      return
    }
    colspanOption.value += 1
    this.onElementChange(this.element)
    this.updateTableChildren()
  }

  decreaseColspan(tableChild: TableChildElement) {
    const colspanOption = this.getColspanOptionElement(tableChild)
    // @ts-ignore
    colspanOption.value -= 1
    this.onElementChange(this.element)
    this.updateTableChildren()
  }

  increaseRowspan(tableChild: TableChildElement) {
    const rowspanOption = this.getRowSpanOptionElement(tableChild)
    // @ts-ignore
    rowspanOption.value += 1
    this.onElementChange(this.element)
    this.updateTableChildren()
  }

  decreaseRowspan(tableChild: TableChildElement) {
    const rowspanOption = this.getRowSpanOptionElement(tableChild)
    // @ts-ignore
    rowspanOption.value -= 1
    this.onElementChange(this.element)
    this.updateTableChildren()
  }

  canDecreaseRowSpan(tableChild: TableChildElement): boolean {
    const rowspanOption = this.getRowSpanOptionElement(tableChild)

    // @ts-ignore
    return rowspanOption.value > 1
  }

  canDecreaseColSpan(tableChild: TableChildElement) {
    const colspanOption = this.getColspanOptionElement(tableChild)

    // @ts-ignore
    return colspanOption.value > 1
  }

  addCell() {
    this.tableChildren.push(new TableChildElement())
    this.onElementChange(this.element)
    this.updateTableChildren()
  }

  removeCell(index: number) {
    let deleteCount = 1

    while (this.tableChildren[index + deleteCount]?.isOccupied) {
      deleteCount++
    }

    this.tableChildren.splice(index, deleteCount)
    this.onElementChange(this.element)
    this.updateTableChildren()
  }

  async openCellConfigurationDialog(tableChild: TableChildElement) {
    await this.dialog
      .open(TableCellConfigDialogComponent, {
        data: { tableChild },
      })
      .afterClosed()
      .pipe(take(1))
      .toPromise()
    this._cdr.markForCheck()
    this.updateTableChildren()
  }

  getBackgroundColor(tableChild: TableChildElement): string {
    const defaultBackgroundColor = "#FFFFFF"

    // The table child children will have zero or one element depending on whether the user has selected an element
    const childElement: TemplateElement = tableChild.children[0]

    if (childElement === undefined) {
      return defaultBackgroundColor
    }

    const backgroundColorOptionElement = childElement.options.find((option) => option.code === ElementOptionCode.TEXT_BACKGROUND_COLOR)

    return backgroundColorOptionElement?.value ? backgroundColorOptionElement.value : defaultBackgroundColor
  }

  getRowSpanOptionElement(tableChild: TableChildElement) {
    return tableChild.options.find((option) => option.code === ElementOptionCode.TABLE_CHILD_ROW_SPAN)
  }

  getRowspanForCell(tableChild: TableChildElement): number {
    const rowSpanOptionElement = this.getRowSpanOptionElement(tableChild)

    return rowSpanOptionElement?.value ? rowSpanOptionElement.value : 1
  }

  getColspanOptionElement(tableChild: TableChildElement) {
    return tableChild.options.find((option) => option.code === ElementOptionCode.TABLE_CHILD_COL_SPAN)
  }

  getColspanForCell(tableChild: TableChildElement): number {
    const colSpanOptionElement = this.getColspanOptionElement(tableChild)

    return colSpanOptionElement?.value ? colSpanOptionElement.value : 1
  }

  templateConfigOptions: Options = {
    group: {
      name: "templateConfig",
      put: ["templates"],
    },
    onSort: (event: any) => {
      this._cdr.detectChanges()
      this.onElementChange("foo")

      return
    },
    onUpdate: (event: any) => {
      this._cdr.detectChanges()
      this.onElementChange("foo")

      return
    },
    onAdd: (event: any) => {
      // Disallow table elements from being dragged into tabular elements
      if (this.element.type === TemplateElementType.TABULAR && typeof event.newIndex === "number") {
        const newDraggedElement = this.element.children[event.newIndex]

        if (!newDraggedElement) {
          return false
        }

        if (newDraggedElement.type === TemplateElementType.TABLE) {
          this.element.children.splice(event.newIndex, 1)
        }
      }

      return false
    },
    handle: ".handle",
  }

  hover = false

  constructor(private templateCreationService: TemplateCreationService, private _cdr: ChangeDetectorRef, private dialog: MatDialog) {}

  ngOnInit() {}

  get tags() {
    if (this.element && this.element.prefill) {
      return this.element.prefill.tags
    }

    return []
  }

  // TODO: triggers ExpressionChangedAfterItHasBeenCheckedError
  // get companyTags(): string[] {
  //   return this.currentCompany ? this.currentCompany.tags : []
  // }

  deepCopy = (item: TemplateElement): TemplateElement => {
    return JSON.parse(JSON.stringify(item))
  }

  appendElementToChild(element: TemplateElement, array: any[]) {
    array.push(this.deepCopy(element))
  }

  dropValue(event: { previousIndex: number; currentIndex: number }, array: any[]) {
    moveItemInArray(array, event.previousIndex, event.currentIndex)
    this.onElementChange("foo")
  }

  dropChildElement(event: CdkDragDrop<TemplateElement[]>, array: any[]) {
    moveItemInArray(array, event.previousIndex, event.currentIndex)
    this._cdr.detectChanges()
    this.onElementChange("foo")
  }

  onTagsUpdated(tags: string[]) {
    if (this.element.type === TemplateElementType.FORMS_ITEMS) {
      // @ts-ignore
      this.element.prefill.tags = tags
    }
  }

  removeClicked() {
    this.elementRemoved.emit(this.element)
    this.templateCreationService.sendMessage("removeClicked", this.element)
  }

  duplicate() {
    this.duplicateClicked.emit(this.element)
    this.templateCreationService.sendMessage("duplicate", this.element)
  }

  addCheckboxValue() {
    this.element.children.push(new CheckboxValueElement())
    this.templateCreationService.sendMessage("addChecboxValue", this.element)
  }

  addDropdownValue() {
    this.element.children.push(new DropdownValueElement())
    this.templateCreationService.sendMessage("addDropdownValue", this.element)
  }

  removeValue(index: number) {
    this.element.children.splice(index, 1)
  }

  onElementChange(event: any) {
    this.templateCreationService.sendMessage("onElementChange", this.element)
  }

  OnChildElementChange() {
    this.templateCreationService.sendMessage("onChildElementChange", this.element)
  }

  onElementRemoved(source: any[], element: any) {
    const index = source.indexOf(element)
    if (index > -1) {
      source.splice(index, 1)
      this.templateCreationService.sendMessage("onElementRemoved", this.element)
    }
  }

  onDuplicate(container: TemplateElement[], element: any, index: number) {
    const elementClone = JSON.parse(JSON.stringify(element))
    container.splice(index, 0, elementClone)
    this.templateCreationService.sendMessage("onDuplicate", this.element)
  }

  isCheckboxOption(option: OptionElement): boolean {
    const deprecatedCheckboxOptionFields = [
      ElementOptionCode.REQUIRED,
      ElementOptionCode.ISDESCRIPTION,
      ElementOptionCode.AUTOGENARATED,
      ElementOptionCode.NONE,
    ]

    return option.type === "checkbox" && !deprecatedCheckboxOptionFields.includes(option.code)
  }

  isColorInputOption(code: ElementOptionCode): boolean {
    return [
      ElementOptionCode.TEXT_COLOR,
      ElementOptionCode.TEXT_BACKGROUND_COLOR,
      ElementOptionCode.CHECKBOX_BORDER_COLOR,
      ElementOptionCode.MAIN_SUB_FIELD_BACKGROUND_COLOR,
      ElementOptionCode.MAIN_SUB_FIELD_TEXT_COLOR,
    ].includes(code)
  }

  isnumberDropdownOption(code: ElementOptionCode): boolean {
    return [ElementOptionCode.TABLE_CHILD_ROW_SPAN, ElementOptionCode.TABLE_CHILD_COL_SPAN].includes(code)
  }

  isSmallMediumLargeSizeSelectionOption(code: ElementOptionCode): boolean {
    return [ElementOptionCode.FONT_SIZE, ElementOptionCode.CHECKBOX_SIZE].includes(code)
  }

  isTableCellSpacingOption(code: ElementOptionCode): boolean {
    return code === ElementOptionCode.TABLE_COLUMN_WIDTHS
  }

  onUpload(result: any) {
    if (result.filesFailed.length > 0) {
      console.error("TODO handle failed image uploads")
    }

    const imageUploadedFile = result.filesUploaded[0]
    if (imageUploadedFile) {
      this.element.value = imageUploadedFile.url
      this.templateCreationService.sendMessage("onElementChange", this.element)
    }
  }

  get isElementWithoutTitleAndInfoInputs(): boolean {
    return [TemplateElementType.MAIN_SUB_FIELD, TemplateElementType.PAGE_BREAK].includes(this.element.type)
  }

  /** Setup a Table's tableChildren
   *
   * Instantiates a TableElement's data into an actual TableChildElement class instead of a plain object containing data from the DB.
   * This ensures that we have access to TableChildElement's methods/getters/setters and fields defined there that are not saved
   * in the database. After that, mark cells as occupied when applicable */
  private setupFillableTableChildren(element: TemplateElement) {
    this._element.children = (element.children || []).map((child) => TableChildElement.withData(child))

    for (let index = 0; index < this.tableChildren.length; index += 1) {
      if (this.tableChildren[index].requiredCellCount < 2) {
        continue
      }

      // This is probably not necessary
      if (this.tableChildren[index + 1] === undefined) {
        this.tableChildren[index + 1] = new TableChildElement()
      }

      this.tableChildren[index + 1].isOccupied = true

      // Skip the next element, as it's already been fixed
      index += 1
    }
  }
}
