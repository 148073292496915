import { inject, Injectable, OnInit } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { EMPTY, Observable, of } from "rxjs"
import { map, switchMap } from "rxjs/operators"

@Injectable()
export class FirebaseAuthService implements OnInit {
  readonly afAuth = inject(AngularFireAuth)
  readonly aFirestore = inject(AngularFirestore)

  ngOnInit() {
    // this.backChannelLogoutListener$.subscribe()
  }

  // private backChannelLogoutListener$: Observable<string | null> = this.afAuth.authState.pipe(
  //   switchMap(user => {
  //     if (!user) {
  //       return of(null)
  //     }
  //
  //     return this.afAuth.idTokenResult.pipe(
  //       map(idTokenResult => {
  //         const sid: string = idTokenResult?.claims?.['firebase']?.['sign_in_attributes']?.['sid']
  //         if (!sid) {
  //           console.error('SID not found in claims')
  //
  //           return of(null)
  //         }
  //
  //         return of(sid)
  //       }))
  //   }),
  //   switchMap(sid => {
  //     if (!sid) {
  //       return of(null)
  //     }
  //
  //     return this.aFirestore.firestore.collection('sessions').doc(sid)
  //   })
  // )

  login(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
  }

  loginWithCustomToken(token: string) {
    return this.afAuth.signInWithCustomToken(token)
  }

  logout() {
    return this.afAuth.signOut()
  }

  verifyPasswordResetCode(code: string) {
    return this.afAuth.verifyPasswordResetCode(code)
  }

  checkActionCode(code: string) {
    return this.afAuth.checkActionCode(code)
  }

  applyActionCode(code: string) {
    return this.afAuth.applyActionCode(code)
  }

  resetPassword(code: string, newPassword: string) {
    return this.afAuth.confirmPasswordReset(code, newPassword)
  }

  get auth() {
    return this.afAuth
  }

  get authState$() {
    return this.afAuth.authState
  }

  isLoggedIn() {
    return this.afAuth.authState.pipe(map((user) => !!user))
  }
}
