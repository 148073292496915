import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-company-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.125 4.875C7.125 4.46062 7.46062 4.125 7.875 4.125H10.125C10.5375 4.125 10.875 4.46062 10.875 4.875V7.125C10.875 7.5375 10.5375 7.875 10.125 7.875H7.875C7.46062 7.875 7.125 7.5375 7.125 7.125V4.875ZM16.125 4.125C16.5375 4.125 16.875 4.46062 16.875 4.875V7.125C16.875 7.5375 16.5375 7.875 16.125 7.875H13.875C13.4625 7.875 13.125 7.5375 13.125 7.125V4.875C13.125 4.46062 13.4625 4.125 13.875 4.125H16.125ZM7.125 10.875C7.125 10.4625 7.46062 10.125 7.875 10.125H10.125C10.5375 10.125 10.875 10.4625 10.875 10.875V13.125C10.875 13.5375 10.5375 13.875 10.125 13.875H7.875C7.46062 13.875 7.125 13.5375 7.125 13.125V10.875ZM16.125 10.125C16.5375 10.125 16.875 10.4625 16.875 10.875V13.125C16.875 13.5375 16.5375 13.875 16.125 13.875H13.875C13.4625 13.875 13.125 13.5375 13.125 13.125V10.875C13.125 10.4625 13.4625 10.125 13.875 10.125H16.125ZM3 3C3 1.34297 4.34297 0 6 0H18C19.6547 0 21 1.34297 21 3V21C21 22.6547 19.6547 24 18 24H6C4.34297 24 3 22.6547 3 21V3ZM5.25 3V21C5.25 21.4125 5.58562 21.75 6 21.75H9.75V18.75C9.75 17.5078 10.7578 16.5 12 16.5C13.2422 16.5 14.25 17.5078 14.25 18.75V21.75H18C18.4125 21.75 18.75 21.4125 18.75 21V3C18.75 2.58562 18.4125 2.25 18 2.25H6C5.58562 2.25 5.25 2.58562 5.25 3Z"
        fill="currentcolor"
      />
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class CompanyIconComponent {
  @Input() size: keyof Size | number = "md"
  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }
}
