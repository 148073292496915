import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import {
  IAgreementsStatus,
  ICheckdAgreement,
  ICheckdAgreements,
  IUserAgreements,
  UserAgreementType,
} from "@models/common/agreements.interface"
import { COLLECTIONS, USER_SUB_COLLECTIONS } from "@models/common/collections.interface"
import { Person } from "@models/common/person"
import { FirebaseAuthService } from "@services/firebase-auth.service"
import firebase from "firebase/compat/app"
import firestore = firebase.firestore
import { combineLatest, Observable, of as observableOf, Subject } from "rxjs"
import { catchError, distinctUntilChanged, map, shareReplay, switchMap, tap, throttleTime } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class AgreementsService {
  public currentUserUid$ = new Subject<string>()

  private _currentUserUid$: Observable<string> = this.currentUserUid$.pipe(distinctUntilChanged())

  /**
   * All user agreements for the current user.
   * Contains an empty object if the user hasn't agreed to anything yet.
   */
  public currentUserAgreements$: Observable<IUserAgreements | null> = this._currentUserUid$.pipe(
    switchMap((userUid) =>
      this.db
        .collection(COLLECTIONS.PEOPLE)
        .doc(userUid)
        .collection(USER_SUB_COLLECTIONS.PRIVATE)
        .doc<IUserAgreements>("agreements")
        .snapshotChanges()
    ),
    map((action) => (action.payload.exists ? action.payload.data() : null)),
    distinctUntilChanged((previous, current) => {
      // User is new/has not accepted any policies yet, return false to emit
      if (current === null) {
        return false
      }

      // If previous value is null, it should mean that the user has just accepted for the first time
      if (previous === null) {
        return true
      }

      return [
        previous.privacyPolicy?.version === current.privacyPolicy?.version,
        previous.termsAndConditions?.version === current.termsAndConditions?.version,
      ].every((x) => x === true)
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  /**
   * All general agreements as defined by CHECKD
   */
  public checkdAgreements$: Observable<ICheckdAgreements> = this.db
    .collection(COLLECTIONS.CHECKD_CONFIG)
    .doc<ICheckdAgreements>("agreements")
    .valueChanges()
    .pipe(
      // @ts-ignore
      distinctUntilChanged((previous, current) => {
        return [
          previous?.termsAndConditions?.version === current?.termsAndConditions?.version,
          previous?.privacyPolicy?.version === current?.privacyPolicy?.version,
        ].every((x) => x === true)
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )

  /**
   * User agreements that are either not agreed to or that are out of date.
   */
  public currentUserAgreementsStatus$: Observable<IAgreementsStatus> = combineLatest([
    this.checkdAgreements$,
    this.currentUserAgreements$,
  ]).pipe(
    map(([checkdAgreements, currentUserAgreements]) => {
      let notUpToDate: any[] = []
      let notAgreedTo: any[] = []

      for (const agreementType of Object.keys(checkdAgreements)) {
        // Filter out unknown attributes
        if (!(agreementType in UserAgreementType)) {
          continue
        }

        // If it doesn't exist the user has never agreed to it
        // @ts-ignore
        if (currentUserAgreements === null || currentUserAgreements[agreementType] == null) {
          // @ts-ignore
          notAgreedTo = [...notAgreedTo, checkdAgreements[agreementType]]
          continue
        }

        // If the version for the user is lower than the CHECKD version, the user has agreed to a previous version
        // @ts-ignore
        if (checkdAgreements[agreementType].version > currentUserAgreements[agreementType].version) {
          // @ts-ignore
          notUpToDate = [...notUpToDate, checkdAgreements[agreementType]]
        }
      }

      return { notUpToDate, notAgreedTo }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  constructor(private auth: FirebaseAuthService, private db: AngularFirestore) {}

  /**
   * Stores user agreements in the appropriate place under the users private subcollection.
   * Any previous agreements are added to the agreements history.
   *
   * @param batch firestore write batch
   * @param user the Person that is about to accept the aggreements
   * @param agreements the agreements that the user is about to accept
   */
  public async acceptUserAgreements(batch: firestore.WriteBatch, user: Person, agreements: ICheckdAgreement[]) {
    const currentAgreements = await user.collection(USER_SUB_COLLECTIONS.PRIVATE).doc("agreements").get()

    const currentAgreementsData = currentAgreements.data() || {}

    for (const agreement of agreements.filter((a) => a.type in UserAgreementType)) {
      const agreementType = agreement.type

      if (currentAgreementsData[agreementType]) {
        // Copy the existing agreement to the history subcollection before updating the current one
        const newAgreementHistory = currentAgreements.ref.collection(COLLECTIONS.HISTORY).doc()
        batch.set(newAgreementHistory, currentAgreementsData[agreementType])
      }

      // Update the current user's agreements document
      batch.set(
        currentAgreements.ref,
        {
          [agreementType]: {
            ...agreement,
            acceptedDate: firebase.firestore.FieldValue.serverTimestamp(),
          },
        },
        { merge: true }
      )
    }

    return batch
  }
}
