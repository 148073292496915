export enum ExportType {
  ITEMS_CSV = "ITEMS_CSV",
  ITEMS_BCF = "ITEMS_BCF",
}

export interface ExportGenerationData {
  name: string
  exportType: ExportType
  items: string[]
  csvColumns: string[]
}

export interface ExportData {
  name: string
  exportType: string
  rows: CsvExportRow[]
}

export interface ICSVExportData extends ExportData {
  csv: string
}

export enum CsvExportRowColumnName {
  projectName = "projectName",
  projectAddress = "projectAddress",
  itemNumber = "itemNumber",
  itemTitle = "itemTitle",
  itemDescription = "itemDescription",
  itemTags = "itemTags",
  dueDate = "dueDate",
  drawingName = "drawingName",
  creatorName = "creatorName",
  creatorCompany = "creatorCompany",
  creatorMail = "creatorMail",
  createdDate = "createdDate",
  assignerName = "assignerName",
  assignerCompany = "assignerCompany",
  assignerMail = "assignerMail",
  assigneeName = "assigneeName",
  assigneeCompany = "assigneeCompany",
  assigneeMail = "assigneeMail",
  assignedDate = "assignedDate",
  collaboratorMail = "collaboratorMail",
  lastModified = "lastModified",
  status = "status",
  pictureLink = "pictureLink",
  pictureDate = "pictureDate",
  pictureUploaderMail = "pictureUploaderMail",
  comment = "comment",
  commentDate = "commentDate",
  commentUploader = "commentUploader",
}

export type CsvExportRow = {
  [key in CsvExportRowColumnName]: string
}
