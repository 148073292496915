import { Component, OnInit, Output, EventEmitter } from "@angular/core"
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms"

@Component({
  selector: "checkd-password-recovery",
  templateUrl: "./password-recovery.component.html",
  styleUrls: ["./password-recovery.component.scss"],
})
export class PasswordRecoveryComponent implements OnInit {
  @Output() submit = new EventEmitter()
  email = new UntypedFormControl("", [Validators.required, Validators.email])
  form: UntypedFormGroup
  wasEmailSubmitted: boolean = false

  constructor(private fb: UntypedFormBuilder) {
    this.form = fb.group({
      email: this.email,
    })
  }

  ngOnInit() {}

  onSubmit(email: string) {
    if (this.email.valid) {
      this.wasEmailSubmitted = true
      this.submit.emit(email)
    }
  }
}
