import { COLLECTIONS } from "../collections.interface"
import { LABELS } from "../relation.interface"
import { Role } from "./role"
import { RoleType } from "./roles-and-permissions.interface"

export class ProjectOwnerRole extends Role {
  setupRoleData() {
    this.roleData = {
      name: "Project owner",
      description: "A person who owns the project",
      roleType: RoleType.PROJECT_OWNER,
      requirements: {
        sourceCollection: COLLECTIONS.PEOPLE,
        labels: [LABELS.OWNER],
        targetCollection: COLLECTIONS.PROJECTS,
      },

      targetPermissions: { create: true, read: true, update: true, delete: true },

      permissions: [
        {
          description: "Can do everything with items.",
          collection: COLLECTIONS.ITEMS,
          documentPermissions: { create: true, read: true, update: true, delete: true },
          relationPermissions: { create: true, read: true, update: true, delete: true },
        },
        {
          description: "Can do everything with reports.",
          collection: COLLECTIONS.REPORTS,
          documentPermissions: { create: true, read: true, update: true, delete: true },
          relationPermissions: { create: true, read: true, update: true, delete: true },
        },
        {
          description: "Can do everything with legacy reports.",
          collection: COLLECTIONS.LEGACY_REPORTS,
          documentPermissions: { create: true, read: true, update: true, delete: true },
          relationPermissions: { create: true, read: true, update: true, delete: true },
        },
        {
          description: "Can do everything with drawings.",
          collection: COLLECTIONS.DRAWINGS,
          documentPermissions: { create: true, read: true, update: true, delete: true },
          relationPermissions: { create: true, read: true, update: true, delete: true },
        },
        {
          description: "Can invite people to the project, and remove pending invitations.",
          collection: COLLECTIONS.INVITATIONS,
          documentPermissions: { create: true, read: true, update: false, delete: true },
          relationPermissions: { create: true, read: true, update: false, delete: true },
        },
        {
          description: "Can add and remove people to/from a project, and change their roles.",
          collection: COLLECTIONS.PEOPLE,
          documentPermissions: { create: false, read: true, update: false, delete: false },
          relationPermissions: { create: true, read: true, update: true, delete: true },
        },
      ],
      inferredRoleTypes: {},
    }
  }
}
