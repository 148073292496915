import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-arrow-left-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26 16.8707C26 17.3449 25.6149 17.73 25.1408 17.73H13.0022L17.7511 22.2606C18.0956 22.5883 18.1079 23.1341 17.7802 23.4752C17.4526 23.8185 16.9092 23.8308 16.5656 23.5043L10.2671 17.4921C10.0962 17.3324 10 17.1069 10 16.8707C10 16.6345 10.0962 16.4127 10.2662 16.248L16.5646 10.2359C16.9066 9.91056 17.4505 9.92273 17.7798 10.2663C18.1074 10.6063 18.0951 11.152 17.7507 11.4809L13.0018 16.0115H25.1729C25.6457 16.0119 26 16.3984 26 16.8707Z"
        fill="black"
      />
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class ArrowLeftIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }
}
