<div class="item-actions" fxLayout="row" fxLayoutAlign="begin center">

  <div
    class="item-actions"
    fxLayout="row"
    fxFlexAlign="begin center"
    fxLayoutGap="15px">

    <div fxLayout="row" fxLayoutAlign="begin center" fxLayoutGap="10px">
      <div matTooltip="{{itemAssignTooltipMessage()}}">
        <button *ngIf="isAvailableAction('ASSIGN')" (click)="onAssign.emit()"
                [disabled]="projectIsArchived || !hasPermissionToAssignTask()"
                mat-icon-button>
          <mat-icon svgIcon="user_icon_white"></mat-icon>
        </button>
      </div>

      <button *ngIf="isAvailableAction('UPLOAD_IMAGE')" (click)="onUploadImage.emit()" [disabled]="projectIsArchived"
              matTooltip="Upload an image" mat-icon-button>
        <mat-icon svgIcon="camera_icon_white"></mat-icon>
      </button>

      <button *ngIf="isAvailableAction('EDIT_TAGS')" (click)="onEditTags.emit()" [disabled]="projectIsArchived"
              matTooltip="Edit tags" mat-icon-button>
        <mat-icon svgIcon="tag_icon_white"></mat-icon>
      </button>
    </div>

  </div>

</div>
