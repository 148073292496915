import { ElementOptionCode, TemplateElement, TemplateElementType } from "./template-element"

export class TextElement extends TemplateElement {
  override name: string = ""
  override icon?: string = "forms:text-field"
  override type: TemplateElementType = TemplateElementType.TEXT
  override typeAlias: string = "Text"
  override value = ""
  override options = [
    {
      id: 1,
      name: "Quarter",
      type: "checkbox",
      code: ElementOptionCode.QUARTER,
      value: false,
    },
    {
      id: 2,
      name: "Half",
      type: "checkbox",
      code: ElementOptionCode.HALF,
      value: false,
    },
    {
      id: 3,
      name: "Background Color",
      type: "color",
      code: ElementOptionCode.TEXT_BACKGROUND_COLOR,
      value: "#FFFFFF",
    },
    {
      id: 4,
      name: "Text Color",
      type: "color",
      code: ElementOptionCode.TEXT_COLOR,
      value: "#000000",
    },
    {
      id: 4,
      name: "Font Size",
      type: "size_dropdown",
      code: ElementOptionCode.FONT_SIZE,
      value: "medium",
    },
    {
      id: 5,
      name: "Bold",
      type: "checkbox",
      code: ElementOptionCode.FONT_BOLD,
      value: false,
    },
    {
      id: 6,
      name: "Cursive",
      type: "checkbox",
      code: ElementOptionCode.FONT_CURSIVE,
      value: false,
    },
  ]
}
