import { Inject, inject, Injectable } from "@angular/core"
import { AngularFireFunctions } from "@angular/fire/compat/functions"
import { GET_STREAM_ACCESS_TOKEN } from "field-chat-lib"
import { BehaviorSubject, firstValueFrom, merge, Observable, ReplaySubject } from "rxjs"
import { combineLatestWith, distinctUntilChanged, filter, map, shareReplay, switchMap, take, timeout } from "rxjs/operators"
import { ChannelService, ChatClientService } from "stream-chat-angular"
import { UserService } from "./user.service"

/**
 * This service was added because adding the FieldChatService from FieldChatLib to dashboard caused null injector errors that
 * proved difficult to resolve
 */
@Injectable({
  providedIn: "root",
})
export class FieldChatDashboardService {
  private readonly chatClientService = inject(ChatClientService)
  private readonly userService = inject(UserService)
  private readonly fns = inject(AngularFireFunctions)
  private readonly channelService = inject(ChannelService)

  constructor(@Inject(GET_STREAM_ACCESS_TOKEN) STREAM_ACCESS_KEY: string) {
    this._streamAccessKey$.next(STREAM_ACCESS_KEY)
  }

  private readonly _getStreamUserTokenFn = this.fns.httpsCallable("ext-auth-chat-getStreamUserToken")
  private readonly _streamAccessKey$ = new BehaviorSubject<string>("")
  private readonly _initialUnreadCount$ = new ReplaySubject<number>(1)

  private readonly currentStreamUserToken$: Observable<string> = this.userService.currentUser$.pipe(
    distinctUntilChanged((previous, current) => previous.uid === current.uid),
    switchMap((_) => this._getStreamUserTokenFn({}))
  )

  private readonly credentials$ = this._streamAccessKey$.pipe(
    filter(Boolean),
    distinctUntilChanged(),
    combineLatestWith(this.userService.currentUser$, this.currentStreamUserToken$),
    filter(([key, user, token]) => [key, user, token].every((value) => !!value)),
    distinctUntilChanged((p, c) => [p[0], p[1].uid, p[2]].join("") === [c[0], c[1].uid, c[2]].join("")),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public async initializeChatClient() {
    const [credentials, currentCompany] = await Promise.all([
      firstValueFrom(this.credentials$.pipe(timeout(10_000))),
      firstValueFrom(this.userService.currentCompany$),
    ])

    const [key, user, token] = credentials
    const response = await this.chatClientService.init(key, { id: user.uid, name: user.name, image: user.image }, token)

    if (response) {
      const unreadCount = response.me?.total_unread_count

      if (unreadCount !== undefined) {
        this._initialUnreadCount$.next(unreadCount)
      }
    }
  }

  public async disconnectUser() {
    await this.chatClientService.disconnectUser()
  }

  private readonly unreadCountFromEvents$ = this.chatClientService.events$.pipe(
    filter((n) => ["notification.message_new", "notification.mark_read", "connection.changed"].includes(n.eventType)),
    filter((event) => "total_unread_count" in event.event),
    map((e) => e.event.total_unread_count),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  private isNotNullOrUndefinedGuard<T>(input: null | undefined | T): input is T {
    return input !== undefined && input !== null
  }

  /**
   * Emits the current number of unread messages that the user has
   */
  public readonly currentUserUnreadCount$: Observable<number> = merge(
    this._initialUnreadCount$.pipe(take(1)),
    this.unreadCountFromEvents$
  ).pipe(filter(this.isNotNullOrUndefinedGuard), distinctUntilChanged())
}
