import { IPermissionDataOverride, PermissionData, RoleData } from "./roles-and-permissions.interface"
import { PermissionsHandler } from "./permissions-handler"

export abstract class Role {
  get labels() {
    return this.roleData.requirements.labels
  }
  get roleType() {
    return this.roleData.roleType
  }
  get requirements() {
    return this.roleData.requirements
  }
  get permissions() {
    return this.roleData.permissions
  }
  get targetPermissions() {
    return this.roleData.targetPermissions
  }
  get inferredRoleTypes() {
    return this.roleData.inferredRoleTypes
  }
  get name() {
    return this.roleData.name
  }
  public roleData: RoleData
  protected permissionMap: { [key: string]: PermissionData } = {}

  constructor() {
    this.setupRoleData()
    if (this.roleData.permissions != null) {
      this.setupPermissionMap()
    }
  }

  public canCreateTarget() {
    return this.targetPermissions!.create
  }
  public canReadTarget() {
    return this.targetPermissions!.read
  }
  public canUpdateTarget() {
    return this.targetPermissions!.update
  }
  public canDeleteTarget() {
    return this.targetPermissions!.delete
  }

  public canReadTargetDocuments(collectionName: string, override?: IPermissionDataOverride) {
    return this.getPermissionsForCollection(collectionName, override).documentPermissions!.read
  }

  public canCreateTargetDocuments(collectionName: string, override?: IPermissionDataOverride) {
    return this.getPermissionsForCollection(collectionName, override).documentPermissions!.create
  }

  public canUpdateTargetDocuments(collectionName: string, override?: IPermissionDataOverride) {
    return this.getPermissionsForCollection(collectionName, override).documentPermissions!.update
  }

  public canDeleteTargetDocuments(collectionName: string, override?: IPermissionDataOverride) {
    return this.getPermissionsForCollection(collectionName, override).documentPermissions!.delete
  }

  public canReadTargetRelations(collectionName: string, override?: IPermissionDataOverride) {
    return this.getPermissionsForCollection(collectionName, override).relationPermissions!.read
  }

  public canCreateTargetRelations(collectionName: string, override?: IPermissionDataOverride) {
    return this.getPermissionsForCollection(collectionName, override).relationPermissions!.create
  }

  public canUpdateTargetRelations(collectionName: string, override?: IPermissionDataOverride) {
    return this.getPermissionsForCollection(collectionName, override).relationPermissions!.update
  }

  public canDeleteTargetRelations(collectionName: string, override?: IPermissionDataOverride) {
    return this.getPermissionsForCollection(collectionName, override).relationPermissions!.delete
  }

  public getPermissionsForCollection(collectionName: string, override: Partial<IPermissionDataOverride> = {}) {
    if (this.permissionMap == null || !Object.keys(this.permissionMap).includes(collectionName)) {
      return {
        description: "",
        collection: "",
        documentPermissions: PermissionsHandler.mergePermissionMaps(
          { create: false, read: false, update: false, delete: false },
          override.documentPermissions || {}
        ),
        relationPermissions: PermissionsHandler.mergePermissionMaps(
          { create: false, read: false, update: false, delete: false },
          override.relationPermissions || {}
        ),
      } as PermissionData
    }

    return {
      ...this.permissionMap[collectionName],
      documentPermissions: PermissionsHandler.mergePermissionMaps(
        // @ts-ignore
        this.permissionMap[collectionName].documentPermissions,
        override.documentPermissions || {}
      ),
      relationPermissions: PermissionsHandler.mergePermissionMaps(
        // @ts-ignore
        this.permissionMap[collectionName].relationPermissions,
        override.relationPermissions || {}
      ),
    } as PermissionData
  }

  abstract setupRoleData(): void

  private setupPermissionMap() {
    this.permissions.forEach((permission) => {
      this.permissionMap[permission.collection] = permission
    })
  }

  public clone() {
    const copy = new (this.constructor as new () => Role)()
    Object.assign(copy, this, {
      roleData: { ...this.roleData },
      permissionMap: { ...this.permissionMap },
    })

    return copy
  }
}
