import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-add-user-plus-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4 18C17.0513 18 19.2 15.8509 19.2 13.2C19.2 10.5491 17.0513 8.39999 14.4 8.39999C11.7488 8.39999 9.6 10.5491 9.6 13.2C9.6 15.8509 11.7488 18 14.4 18ZM14.4 10.2C16.0541 10.2 17.4 11.5459 17.4 13.2C17.4 14.8541 16.0541 16.2 14.4 16.2C12.7463 16.2 11.4 14.8537 11.4 13.2C11.4 11.5459 12.7463 10.2 14.4 10.2ZM16.3013 19.8H12.4988C8.91038 19.8 6 22.71 6 26.2987C6 27.0187 6.582 27.6 7.29975 27.6H21.501C22.2188 27.6 22.8 27.0187 22.8 26.2987C22.8 22.71 19.89 19.8 16.3013 19.8ZM7.82662 25.8C8.07675 23.4412 10.0763 21.6 12.4988 21.6H16.3013C18.7241 21.6 20.6925 23.4424 20.9738 25.8H7.82662ZM29.1 15.9H27.3V14.1C27.3 13.605 26.8988 13.2 26.4 13.2C25.9013 13.2 25.5 13.6031 25.5 14.1V15.9H23.7C23.205 15.9 22.8 16.305 22.8 16.8C22.8 17.295 23.2031 17.7 23.7 17.7H25.5V19.5C25.5 19.9987 25.905 20.4 26.4 20.4C26.895 20.4 27.3 19.9969 27.3 19.5V17.7H29.1C29.5988 17.7 30 17.2987 30 16.8C30 16.3012 29.5988 15.9 29.1 15.9Z"
        fill="currentColor"
      />
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class AddUserPlusIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }
}
