interface ITemplateElementOption {
  [key: string]: any
}

interface ITemplateElement {
  name: string
  icon?: string
  type: TemplateElementType
  typeAlias?: string
  value?: any
  values?: any
  id?: number
  canHaveChildren?: boolean
  children?: TemplateElement[]
  // childElements?: TemplateElement[]
  options?: ITemplateElementOption[]
  info?: string
  accepted?: any[]
  rejected?: any[]
  [key: string]: any
}

enum TemplateElementType {
  TEXT_FIELD = "TEXT_FIELD",
  CONTAINER = "CONTAINER",
  SLIDER = "SLIDER",
  CHECKBOX = "CHECKBOX",
  CHECKBOX_VALUE = "CHECKBOX_VALUE",
  DATE = "DATE",
  TIME = "TIME",
  MAIN_FIELD = "MAIN_FIELD",
  SUB_FIELD = "SUB_FIELD",
  MAIN_SUB_FIELD = "MAIN_SUB_FIELD",
  SIGNATURE = "SIGNATURE",
  TOGGLE = "TOGGLE",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  DROPDOWN = "DROPDOWN",
  DROPDOWN_VALUE = "DROPDOWN_VALUE",
  CAMERA = "CAMERA",
  TABULAR = "TABULAR",
  NONE = "NONE",
  FORMS_ITEMS = "FORMS_ITEMS",
  TABLE = "TABLE",
  IMAGE = "IMAGE",
  TEXT = "TEXT",
  TABLE_CHILD = "TABLE_CHILD",
  PAGE_BREAK = "PAGE_BREAK",
}

enum OptionElementType {
  QUATER = "QUATER",
  HALF = "HALF",
  REQUIRED = "REQUIRED",
  ISDESCRIPTION = "ISDESCRIPTION",
  ISMULTISELECT = "ISMULTISELECT",
  AUTOGENARATED = "AUTOGENARATED",
  ISVERTICAL = "ISVERTICAL",
  ADDNEW = "ADDNEW",
  CONDITIONAL = "CONDITIONAL",
  CAN_ADD_NEW_DROPDOWN = "CAN_ADD_NEW_DROPDOWN",
  INCLUDE_TITLE_IN_PDF = "INCLUDE_TITLE_IN_PDF",
  NONE = "NONE",
}

enum ElementOptionCode {
  QUARTER = "QUARTER",
  HALF = "HALF",
  REQUIRED = "REQUIRED",
  ISMULTISELECT = "ISMULTISELECT",
  ISDESCRIPTION = "ISDESCRIPTION",
  AUTOGENARATED = "AUTOGENARATED",
  ISVERTICAL = "ISVERTICAL",
  ADDNEW = "ADDNEW",
  CONDITIONAL = "CONDITIONAL",
  CAN_ADD_NEW_DROPDOWN = "CAN_ADD_NEW_DROPDOWN",
  INCLUDE_TITLE_IN_PDF = "INCLUDE_TITLE_IN_PDF",
  NONE = "NONE",
  INCLUDE_IN_EMAIL = "INCLUDE_IN_EMAIL",
  TEXT_FIELD_MAX_LENGTH = "TEXT_FIELD_MAX_LENGTH",
  TEXT_BACKGROUND_COLOR = "TEXT_BACKGROUND_COLOR",
  TEXT_COLOR = "TEXT_COLOR",
  TABLE_CHILD_ROW_SPAN = "TABLE_CHILD_ROW_SPAN",
  TABLE_CHILD_COL_SPAN = "TABLE_CHILD_COL_SPAN",
  MAIN_SUB_FIELD_TEXT_COLOR = "MAIN_SUB_FIELD_TEXT_COLOR",
  MAIN_SUB_FIELD_BACKGROUND_COLOR = "MAIN_SUB_FIELD_BACKGROUND_COLOR",
  FONT_SIZE = "FONT_SIZE",
  FONT_BOLD = "FONT_BOLD",
  FONT_CURSIVE = "FONT_CURSIVE",
  CHECKBOX_SIZE = "CHECKBOX_SIZE",
  TABLE_COLUMN_WIDTHS = "TABLE_COLUMN_WIDTHS",
  TABLE_COLUMNS = "TABLE_COLUMNS",
  HIDE_TITLE_IN_PDF = "HIDE_TITLE_IN_PDF",
  CHECKBOX_BORDER_COLOR = "CHECKBOX_BORDER_COLOR",
  TEXT_FIELD_HEIGHT = "TEXT_FIELD_HEIGHT",
}

class OptionElement implements ITemplateElementOption {
  id: number
  code: ElementOptionCode
  name: string
  type: string
  value: any
}

const betaElements: ReadonlyArray<TemplateElementType> = [
  TemplateElementType.FORMS_ITEMS,
  TemplateElementType.TABLE,
  TemplateElementType.TABLE_CHILD,
  TemplateElementType.TEXT,
  TemplateElementType.IMAGE,
]

function isBetaElement(elementType: TemplateElementType) {
  return betaElements.includes(elementType)
}

class TemplateElement implements ITemplateElement {
  name: string
  icon?: string
  type: TemplateElementType
  typeAlias?: string
  prefill?: { name: string; description: string; tags: string[] }
  value: any
  info?: any
  children: TemplateElement[] = []
  options: OptionElement[] = []

  canHaveChildren?: boolean = false
}

export { TemplateElement, TemplateElementType, OptionElement, ElementOptionCode, isBetaElement }
