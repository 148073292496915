<h2 mat-dialog-title>Generate report</h2>

<mat-dialog-content>
  <section class="description">
    <p>Please specify a name and description for the report.</p>
    <p>A report will be created for {{ filteredItemCount }} out of {{ totalItemCount }} items.</p>
  </section>

  <mat-form-field>
    <input [(ngModel)]="reportGenerationData.name" matInput placeholder="Report name" />
  </mat-form-field>

  <mat-form-field>
    <textarea matInput placeholder="Description" [(ngModel)]="reportGenerationData.description"> </textarea>
  </mat-form-field>

  <span class="description-settings"> Select what you want the report to contain: </span>

  <section class="report-options-selection-area-wrapper">
    <div class="option-selection-column">
      <mat-checkbox [(ngModel)]="reportGenerationData.settings.projectName">Project name</mat-checkbox>
      <mat-checkbox [disabled]="!hasDrawingFeature" [(ngModel)]="reportGenerationData.settings.drawing">Drawing</mat-checkbox>
      <mat-checkbox [(ngModel)]="reportGenerationData.settings.itemTitle">Title column</mat-checkbox>
      <mat-checkbox [(ngModel)]="reportGenerationData.settings.itemDescription">Description column</mat-checkbox>
      <mat-checkbox [(ngModel)]="reportGenerationData.settings.itemTags">Tags column</mat-checkbox>
      <mat-checkbox [(ngModel)]="reportGenerationData.settings.qrCode">QRcode and link</mat-checkbox>
      <mat-checkbox [(ngModel)]="reportGenerationData.settings.pageNumber">Pagenumber in PDF</mat-checkbox>
    </div>
    <div class="option-selection-column">
      <mat-checkbox [(ngModel)]="reportGenerationData.settings.drawingName">Drawing name column</mat-checkbox>
      <mat-checkbox [(ngModel)]="reportGenerationData.settings.taskStatus">Status column</mat-checkbox>
      <mat-checkbox [(ngModel)]="reportGenerationData.settings.creator">Creator column</mat-checkbox>
      <mat-checkbox [(ngModel)]="reportGenerationData.settings.assignee">Assignee column</mat-checkbox>
      <mat-checkbox [(ngModel)]="reportGenerationData.settings.collaborators">Collaborators column</mat-checkbox>
      <mat-checkbox [(ngModel)]="reportGenerationData.settings.createdDate">Created date column</mat-checkbox>
      <mat-checkbox [(ngModel)]="reportGenerationData.settings.dueDate">Due date</mat-checkbox>
      <!-- <mat-checkbox [(ngModel)]="rgData.settings.modifiedDate">modifiedDate</mat-checkbox> -->
      <!-- <mat-checkbox [(ngModel)]="rgData.settings.company">company</mat-checkbox> -->
      <!-- <mat-checkbox [(ngModel)]="rgData.settings.dueDate">dueDate</mat-checkbox> -->
    </div>
  </section>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()" class="checkd-btn cancel-btn-color">Cancel</button>
  <button mat-button [disabled]="!hasReportName" (click)="onOk()" class="checkd-btn save-btn-color">OK</button>
</mat-dialog-actions>
