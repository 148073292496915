import { Injectable } from "@angular/core"
import { RelationService } from "@services"
import { LegacyTemplate, LABELS, Project, COLLECTIONS, CompanyFeatures, SortingDirection, Relation } from "@models/common"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { map, tap } from "rxjs/operators"
import { naturalSortObjectsByProperty } from "@services/utilities"

@Injectable({
  providedIn: "root",
})
export class ProjectTemplateService {
  constructor(private relationService: RelationService, private db: AngularFirestore) {}

  addTemplateToProject(template: LegacyTemplate, project: Project) {
    const batch = this.db.firestore.batch()

    template.batchAdd(batch, project, [LABELS.DEFAULT])
    project.batchAdd(batch, template, [LABELS.DEFAULT])

    return batch.commit()
  }

  async removeTemplatesFromProject(templates: LegacyTemplate[], project: Project) {
    const batch = this.db.firestore.batch()

    for (const template of templates) {
      const templateProjectRel = await Relation.get(template, project)
      const projectTemplateRel = await Relation.get(project, template)

      Relation.batchDelete(batch, templateProjectRel)
      Relation.batchDelete(batch, projectTemplateRel)
    }

    return batch.commit()
  }

  addTemplatesToProject(templates: LegacyTemplate[], project: Project) {
    const batch = this.db.firestore.batch()

    for (const template of templates) {
      template.batchAdd(batch, project, [LABELS.DEFAULT])
      project.batchAdd(batch, template, [LABELS.DEFAULT])
    }

    return batch.commit()
  }

  listenToProjectTemplates(project: Project) {
    return this.relationService.listenToTargets(project, COLLECTIONS.LEGACY_TEMPLATES).pipe(
      map((templates) => templates.filter((template) => !(template.inactive || template.isDraft || template.isArchived))),
      map((templates) => naturalSortObjectsByProperty(templates, "name", SortingDirection.ASC))
    )
  }

  canHaveProjectTemplates(project: Project) {
    return (
      (project.aggregateData.companyFeatures || []).indexOf(CompanyFeatures.FORMS) > -1 &&
      (project.aggregateData.companyFeatures || []).indexOf(CompanyFeatures.PROJECT_TEMPLATES) > -1
    )
  }
}
