<ng-container *ngIf="invitation">
  <ng-container
    *ngIf="{
      companyName: invitation.aggregateData?.['sourceCompanyName'],
      inviterName: invitation.aggregateData?.['sourceName']
    } as info"
  >
    <ng-container *ngIf="!formSubmissionDone; else finishedView">
      <div class="w-full flex flex-column align-items-center">
        <p class="invite-text mx-3">
          <strong class="white-space-nowrap">{{ info.inviterName }}</strong>
          has invited you to join
          <strong class="white-space-nowrap">{{ info.companyName }}</strong>
        </p>

        <div class="mb-5">
          <p class="mb-2">If you already have a CHECKD user</p>
          <button (click)="triggerLogin.emit()" mat-button class="checkd-btn confirm-btn-color">Click here to log in</button>
        </div>

        <mat-card class="mat-elevation-z3">
          <h2 mat-card-title>Or, fill out the form to create one</h2>

          <form [formGroup]="registrationForm" class="flex flex-column" (ngSubmit)="onSubmitButtonClicked()">
            <ng-container formGroupName="emailGroup">
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email" />
                <mat-error *ngIf="email!.hasError('email')">Email is not in a valid format</mat-error>
                <mat-error *ngIf="email!.hasError('required')">Email is required</mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Verify email address</mat-label>
                <input type="email" matInput formControlName="confirmEmail" [errorStateMatcher]="matcher" />
                <mat-error *ngIf="emailGroup!.hasError('notSame')">Emails do no match</mat-error>
              </mat-form-field>
            </ng-container>

            <mat-form-field>
              <mat-label>Name</mat-label>
              <input type="text" matInput formControlName="userName" />
              <mat-error *ngIf="userName!.errors">Name is required</mat-error>
            </mat-form-field>

            <ng-container formGroupName="passwordGroup">
              <mat-form-field>
                <mat-label>Password</mat-label>
                <input type="password" matInput placeholder="At least 6 characters" formControlName="password" />
                <mat-error *ngIf="password!.hasError('minlength')">Password has to be at least 6 characters</mat-error>
                <mat-error *ngIf="password!.hasError('required')">Password is required</mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Verify password</mat-label>
                <input
                  type="password"
                  matInput
                  placeholder="re-type your password"
                  formControlName="confirmPassword"
                  [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="passwordGroup!.hasError('notSame')">Passwords do not match</mat-error>
              </mat-form-field>
            </ng-container>

            <p *ngIf="checkdAgreements">
              <mat-checkbox formControlName="agree"> I have read and agree to the&nbsp;</mat-checkbox>
              <a class="doclink" target="_blank" href="{{ checkdAgreements.termsAndConditions.url }}">{{
                checkdAgreements.termsAndConditions.name
              }}</a>
              &nbsp;and&nbsp;
              <a class="doclink" target="_blank" href="{{ checkdAgreements.privacyPolicy.url }}">{{
                checkdAgreements.privacyPolicy.name
              }}</a>
            </p>

            <button
              type="submit"
              mat-button
              class="checkd-btn confirm-btn-color align-self-center"
              [disabled]="registrationForm.invalid || this.formSubmissionInProgress"
            >
              Send activation link to email
            </button>
          </form>
        </mat-card>
      </div>
    </ng-container>

    <ng-template #finishedView>
      <div class="flex flex-column align-items-center">
        <h2>{{ info.companyName }}</h2>

        <p>Great!</p>

        <p>
          The activation link has been sent to <strong class="white-space-nowrap">{{ email!.value }}</strong>
        </p>

        <p>It will be valid for 48 hours</p>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
