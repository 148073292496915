import { Injectable } from "@angular/core"
import { Company, ModelInCollection, Person, Project, Relation, Role } from "@models/common"
import { RoleHandler } from "@models/common/roles-and-permissions"
import { from as observableFrom, Observable } from "rxjs"

@Injectable()
export class RoleHandlerService {
  public roleHandler: RoleHandler

  constructor() {
    this.roleHandler = RoleHandler.get("")
  }

  public fromRelation(relation: Relation) {
    return this.roleHandler.fromRelation(relation)
  }

  public fromRelations(relations: Relation[]) {
    return relations.map((rel) => this.fromRelation(rel))
  }

  /**
   * @deprecated use relation listener combined with fromRelation() instead
   */
  async getPersonProjectRole(person: Person, project: Project) {
    return this.roleHandler.getRoleFor(person, project)
  }

  /**
   * @deprecated use relation listener combined with fromRelation() instead
   */
  listenToUserProjectRole(person: Person, project: Project) {
    return observableFrom(this.roleHandler.getRoleFor(person, project))
  }

  /**
   * @deprecated use relation listener combined with fromRelation() instead
   */
  listenToRole<M1 extends ModelInCollection, M2 extends ModelInCollection>(source: M1, target: M2): Observable<Role> {
    return observableFrom(this.roleHandler.getRoleFor(source, target))
  }
}
