<div class="bottom-bar">
  <div class="bottom-bar__left-container">
    <div id="new-template-name" class="title-and-description" fxLayout="column">
      <span *ngIf="template && template.name != ''">{{ template.name }}</span>
      <span *ngIf="hasDescription">{{ template.description }}</span>
    </div>
    <button class="edit-button" *ngIf="template && template.name" mat-icon-button (click)="onEdit.emit()">
      <mat-icon class="checkd-white">edit</mat-icon>
    </button>
  </div>

  <checkd-edit-forms-template-dialog
    [(open)]="editFormDialogOpen"
    [disabledPristineFormCheck]="true"
    (onSave)="onPublish.emit($event)"
    [currentCompany]="currentCompany"
    [template]="legacyTemplate"
  >
  </checkd-edit-forms-template-dialog>

  <div id="template-action-buttons" class="bottom-bar__right-container">
    <button
      primary-button
      [isRounded]="true"
      id="save-template-btn"
      [disabled]="!(templateCreationService.templateHasUnsavedData$ | async)"
      (click)="onSave.emit(template)"
    >
      Save
    </button>
    <button primary-button [isRounded]="true" id="publish-template-btn" (click)="editFormDialogOpen = true" [disabled]="!isDraft">
      Publish
    </button>
    <button
      primary-button
      [isRounded]="true"
      *ngIf="canExportToJson"
      id="export-json-btn"
      [disabled]="!isDraft"
      (click)="onExportJson.emit()"
    >
      Export JSON
    </button>
  </div>

  <!--  <div>-->
  <!--    <ng-container *ngIf="template && template.name != ''">-->
  <!--      <span fxLayout="column" fxLayoutAlign="begin center">-->
  <!--        <span fxLayout="row" fxLayoutAlign="start start">-->
  <!--          <p>{{ template.name }}</p>-->
  <!--          <button mat-icon-button (click)="onEdit.emit()"><mat-icon class="checkd-white">edit</mat-icon></button>-->
  <!--        </span>-->
  <!--        <p *ngIf="hasDescription">{{template.description}}</p>-->
  <!--      </span>-->
  <!--    </ng-container>-->
  <!--  </div>-->

  <!--  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">-->
  <!--    &lt;!&ndash; <checkd-button bg="red" fg="white" (buttonClick)="newTemplate()">New</checkd-button> &ndash;&gt;-->
  <!--    <checkd-button [disabled]="!hasUnsavedData" bg="green" fg="white" (buttonClick)="save()">Save</checkd-button>-->
  <!--    <checkd-button [disabled]="!isDraft" bg="green" fg="white" (buttonClick)="publish()">Publish</checkd-button>-->
  <!--  </div>-->
</div>
