/**
 * @deprecated Don't use this. Get it out of the codebase when you have some time to spare.
 */
export enum ReportStatus_FROM_20_DONT_USE {
  Archived = 1,
  Shared = 2,
  Handover = 3,
  SendToVerification = 4,
  ArchivedAsAccepted = 5,
  Rejected = 6,
  Inprogress = 7,
  Modified = 8,
}
