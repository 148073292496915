import { inject, Injectable } from "@angular/core"
import { Channel } from "stream-chat"
import { ChatClientService, DefaultStreamChatGenerics } from "stream-chat-angular"
import { v4 as uuid } from "uuid"
import { ChatRoomType } from "../constants"
import { FieldChatDialogService } from "./field-chat-dialog.service"

interface ICreateChatroomOptions {
  invokedByUserUid: string
  invokerCompanyOrProjectUid: string
  chatRoomType: ChatRoomType
}

@Injectable({
  providedIn: "root",
})
export class AdminService {
  private readonly fieldChatDialogService = inject(FieldChatDialogService)
  private readonly chatClientService = inject(ChatClientService)

  /**
   * Shows a dialog that asks user to confirm removing the user from the chatroom, removes user if user confirms
   *
   * IMPORTANT: This does **NOT** check whether the invoking user has the permissions to actually remove the user
   *            from the chatroom. This should be checked in the method invoking this method
   * @param description The text to display in the dialog
   * @param userUid UID of the user to be removed
   * @param activeChannel The channel we want to remove the user from
   */
  async removeUserFromChatRoom(description: string, userUid: string, activeChannel: Channel<DefaultStreamChatGenerics>) {
    const userConfirmed = await this.fieldChatDialogService.openConfirmationDialog({
      description,
    })

    if (userConfirmed) {
      await activeChannel.removeMembers([userUid])
    }
  }

  /**
   * Shows a dialog that asks user to confirm deleting the chatroom, deletes it if the user confirms
   *
   * IMPORTANT: This does **NOT** check whether the invoking user has the permissions to delete the
   *            chatroom. This should be checked in the method invoking this method
   */
  async deleteChannel(channel: Channel<DefaultStreamChatGenerics>) {
    const userConfirmed = await this.fieldChatDialogService.openDeleteChannelDialog()

    if (userConfirmed) {
      await channel.delete()
    }
  }

  /**
   * Shows the dialog for channel creation. Creates channel if user confirms
   *
   * IMPORTANT: This does **NOT** check whether the invoking user has the permissions to create rooms
   *            This should be checked in the method invoking this method
   * @param data The data that we want to create a room with
   */
  async createChannel(data: ICreateChatroomOptions) {
    const response = await this.fieldChatDialogService.openCreateChannelDialog()

    if (!response) {
      return
    }

    const customData: Record<string, string | string[]> = {
      members: [data.invokedByUserUid],
      name: response.name,
      description: response.description,
    }

    switch (data.chatRoomType) {
      case "team":
        customData["fieldCompanyUid"] = data.invokerCompanyOrProjectUid
        break
      case "messaging":
        customData["fieldProjectUid"] = data.invokerCompanyOrProjectUid
        break
    }

    const newChannel = this.chatClientService.chatClient.channel(data.chatRoomType, uuid(), customData)
    await newChannel.create()
  }

  async editChannel(activeChannel: Channel<DefaultStreamChatGenerics>, currentName: string, currentDescription: string) {
    const result = await this.fieldChatDialogService.openEditChannelDialog(currentName, currentDescription)

    if (!result) {
      return
    }

    await activeChannel.updatePartial({ set: { ...result } })
  }
}
