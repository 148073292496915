<div class="flex flex-column">
  <div class="flex justify-content-center">
    <span>{{ itemName }}</span>
  </div>

  <div class="status-info">
    <span [class]="statusColorClass">{{ taskStatus }}</span>
    <span *ngIf="hasAssignee()"> to {{ assigneeName }}</span>
  </div>

  <div class="flex gap-3 justify-content-center">
    <form id="new-item-form" [formGroup]="itemDetailsForm" class="flex flex-column flex-auto" (ngSubmit)="save()">
      <mat-form-field appearance="outline">
        <mat-label>Title</mat-label>
        <input matInput placeholder="Title of the item" formControlName="name" />
        <mat-error>An item needs a title</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput matAutosize placeholder="Description of the item" formControlName="description"> </textarea>
      </mat-form-field>

      <section class="due-date flex justify-content-start align-items-center">
        <mat-icon class="calendar-icon" [owlDateTimeTrigger]="dt1">today</mat-icon>
        <input
          fxFlex
          [(ngModel)]="_dueDate"
          [ngModelOptions]="{ standalone: true }"
          [owlDateTime]="dt1"
          [owlDateTimeTrigger]="dt1"
          placeholder="Due date"
        />
        <owl-date-time [pickerMode]="'dialog'" #dt1></owl-date-time>

        <button *ngIf="_dueDate" mat-icon-button (click)="onDueDateDeleteClicked()">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </section>

      <div class="flex align-items-center">
        <mat-icon svgIcon="tag" class="flex-none tag-icon"></mat-icon>

        <checkd-tags
          class="flex-auto"
          [tags]="tags"
          [tagSet]="projectTags"
          [areTagsEditable]="true"
          (onTagsUpdated)="onTagsUpdated($event)"
          placeholder="Tags"
        >
        </checkd-tags>
      </div>
    </form>

    <div class="ng-content-wrapped flex-auto">
      <ng-content select=".mid-right-content"></ng-content>
    </div>
  </div>
  <!--  <ng-container *ngIf="project && project.nextProjectId">-->
  <!--    <mat-form-field>-->
  <!--      <mat-select placeholder="Select next customer" [(value)]="selectedNextCustomer">-->
  <!--        <mat-option *ngFor="let customer of (nextService.nextProjectCustomers$ | async)?.items" [value]="customer">{{-->
  <!--          customer?.name-->
  <!--        }}</mat-option>-->
  <!--      </mat-select>-->
  <!--    </mat-form-field>-->
  <!--  </ng-container>-->

  <div *ngIf="isInEditMode" fxLayout="row" fxLayoutAlign="end" class="bottom-actions">
    <checkd-button bg="green" fg="white" [disabled]="!formIsValidAndValuesChanged()" (buttonClick)="save()">Save</checkd-button>
  </div>

  <div *ngIf="!isInEditMode" class="bottom-actions">
    <button primary-button [type]="'outline'" (click)="assignUser()"><span>Assign to</span></button>
    <button primary-button [disabled]="!formIsValidAndValuesChanged()" (click)="save()">
      <span>Create item</span>
    </button>
  </div>
</div>
