<div class="grid grid-nogutter">
  <div class="col-12">
    <div class="p-inputgroup">
      <input
        #searchInput
        [disabled]="templatesAreLoading"
        [defaultValue]="flViewService.currentSearchbarText()"
        type="text"
        class="searchbar__input-component"
        pInputText
        placeholder="Search for template, publisher, tag"
        (keydown.enter)="setSearchTextAndNavigateToBrowseAllTemplates(searchInput.value)"
      />
      <button primary-button (click)="setSearchTextAndNavigateToBrowseAllTemplates(searchInput.value)">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2511_16760)">
            <path
              d="M23.6329 22.0781L17.3517 15.7969C18.7111 14.1328 19.4611 12.0375 19.4611 9.75C19.4611 4.36406 15.0956 0 9.71106 0C4.32653 0 0.00323486 4.36547 0.00323486 9.75C0.00323486 15.1345 4.36824 19.5 9.71106 19.5C11.9981 19.5 14.0962 18.7073 15.7579 17.3888L22.0392 23.67C22.3017 23.8922 22.5923 24 22.8782 24C23.1642 24 23.4539 23.8901 23.6737 23.6704C24.1111 23.2312 24.1111 22.5187 23.6329 22.0781ZM2.25324 9.75C2.25324 5.61469 5.61793 2.25 9.75324 2.25C13.8886 2.25 17.2532 5.61469 17.2532 9.75C17.2532 13.8853 13.8886 17.25 9.75324 17.25C5.61793 17.25 2.25324 13.8844 2.25324 9.75Z"
              fill="currentcolor"
            />
          </g>
          <defs>
            <clipPath id="clip0_2511_16760">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</div>
