import { Component, Inject, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA } from "@angular/material/dialog"
import { TableChildElement, TableElement } from "../../models/template-elements/table-element"
import { TemplateElement } from "../../models/template-elements/template-element"
import { TextfieldElement } from "../../models/template-elements/textfield-element"
import { CheckboxElement } from "../../models/template-elements/checkbox-element"
import { DateElement } from "../../models/template-elements/date-element"
import { TimeElement } from "../../models/template-elements/time-element"
import { ImageElement } from "../../models/template-elements/image-element"
import { TextElement } from "../../models/template-elements/text-element"

export interface ITableCellConfigDialogComponentData {
  tableChild: TableChildElement
}

@Component({
  selector: "checkd-table-cell-config-dialog",
  templateUrl: "./table-cell-config-dialog.component.html",
  styleUrls: ["./table-cell-config-dialog.component.scss"],
})
export class TableCellConfigDialogComponent implements OnInit {
  readonly allowedTableElements = [
    new TextElement(),
    new TextfieldElement(),
    new CheckboxElement(),
    new ImageElement(),
    new DateElement(),
    new TimeElement(),

    // Let's wait with this one until we have the other elements properly configured
    new TableElement(),
  ]

  _selectedElement: TemplateElement
  get selectedElement() {
    return this._selectedElement
  }

  set selectedElement(element: TemplateElement) {
    this._selectedElement = element
    this.data.tableChild.children = [element]
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: ITableCellConfigDialogComponentData) {
    this._selectedElement = this.data.tableChild.children[0]
  }

  ngOnInit(): void {}

  compareFunc(o1: TemplateElement, o2: TemplateElement): boolean {
    return o1 && o2 ? o1.type === o2.type : o1 === o2
  }
}
