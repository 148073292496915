import { DocumentReference } from "@angular/fire/compat/firestore"
import { LegacyTemplateAggregateData } from "./aggregate-data"
import firebase from "firebase/compat/app"
import FieldValue = firebase.firestore.FieldValue

export enum TemplateSharingOption {
  NONE = "NONE",
  ALL_PUBLIC_TEMPLATE_USERS = "ALL_PUBLIC_TEMPLATE_USERS",
  COMPANY_SUBSCRIBERS = "COMPANY_SUBSCRIBERS",
}

export interface LegacyTemplateListElementData {
  name: string
  uid: string
}

export interface ITemplateUpdateInfo {
  // In the future, we can add different severity levels here. For example, "outdated" if a publisher wants to notify subscribers that a
  // template isn't up to code anymore
  severity: "info"
  // A reference to a document which contains detailed changelog info
  // changeLogRef?: DocumentReference
}

export interface LegacyTemplateData {
  enableAnonymousReports: boolean
  name?: string
  reportName: string
  lastElementId?: number
  createdDateTime?: string
  reportCategoryId?: number
  headerTemplate: string
  isStandard?: boolean
  isMigrated?: boolean
  reportId?: number
  isActive?: boolean
  modifiedDateTime?: string
  isDeleted?: boolean
  detailTemplate: string

  createdAt?: number
  updatedAt?: number

  templateType?: string
  description?: string
  isDraft?: boolean
  inactive?: boolean

  logo?: string
  pdfFooterText?: string | null
  footerLogo?: string

  sharedWith?: TemplateSharingOption

  aggregateData?: LegacyTemplateAggregateData
  internalVersion?: number | FieldValue
  publicVersion?: string | null

  /**
   * If this is a template that has been duplicated/is a "subscribing" template, then the UID of the original template will
   * be in this field. For all templates that are "original", this field will be undefined
   */
  originalTemplateUid?: string

  templateUpdateInfo?: ITemplateUpdateInfo | null

  tags?: string[]
}
