<div class="builder-container flex justify-content-around">
  <div class="flex flex-column align-items-center flex-none" id="building-blocks-column">
    <div
      id="building-blocks-image-and-text-wrapper"
      matTooltip="Here are all your building blocks and components.
                  Drag these over in the report structure and create
                  your own specific report."
      class="blocks-and-report-column-info-container-height info-image"
    >
      <div class="info-image__img-container">
        <img id="building-blocks-image" class="info-image__img" src="assets/icons/blocks.png" alt="building blocks" />
      </div>
      <p id="building-blocks-text" class="info-image__text">Building blocks</p>
    </div>

    <div class="template-element-list scroll-container">
      <div *ngFor="let element of templateElements" class="flex flex-column align-items-center">
        <checkd-template-element [element]="element" (click)="addNewElement(element)"></checkd-template-element>
      </div>
    </div>
  </div>

  <div class="middle-and-right flex justify-content-between flex-2-2-auto">
    <div id="report-structure-column" class="center flex flex-column align-items-center flex-50-percent">
      <div
        id="report-structure-image-and-text-wrapper"
        class="info-image blocks-and-report-column-info-container-height flex flex-column align-items-center justify-content-center flex-none"
        matTooltip="In this section you will structure the template
                    in your designated order and style."
      >
        <div class="info-image__img-container">
          <img id="report-structure-image" class="info-image__img" src="assets/icons/structure.png" alt="report structure" />
        </div>
        <p id="report-structure-text" class="info-image__text">Report structure</p>
      </div>

      <div
        id="report-structure-drop-zone"
        class="flex-auto flex flex-column align-items-center gap-1 w-full px-2 overflow-y-auto"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
      >
        <ng-container *ngFor="let element of droppedElements; let i = index">
          <checkd-template-element-config
            [element]="element"
            [currentCompany]="currentCompany"
            (elementRemoved)="onElementRemoved(droppedElements, $event)"
            (duplicateClicked)="onDuplicate($event, i)"
            cdkDrag
          >
          </checkd-template-element-config>
        </ng-container>
      </div>
    </div>

    <div id="preview-column" class="flex flex-column flex-50-percent align-items-center">
      <div
        id="preview-image-and-text-wrapper"
        class="info-image"
        matTooltip="Here you can view and preview how your template will look and feel before you publish."
      >
        <div class="info-image__img-container">
          <img id="preview-image" class="info-image__img" src="assets/icons/preview.png" alt="preview" />
        </div>
        <p id="preview-text" class="info-image__text">Preview</p>
      </div>

      <div class="w-full px-2 overflow-y-auto">
        <checkd-legacy-report-preview
          [company]="currentCompany"
          [template]="template"
          [previewData]="previewData"
          (onTextChanged)="onTemplateOptionsTextChanged.emit()"
          (uploadLogo)="uploadLogo.emit($event)"
          (previewEnabledChanged)="onPreviewEnabledChanged($event)"
        >
        </checkd-legacy-report-preview>
      </div>
    </div>
  </div>
</div>
