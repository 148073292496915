<ng-container *ngIf="!isProjectLockedOrArchived && hasOptions">
  <button mat-icon-button [matMenuTriggerFor]="rightActionsMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #rightActionsMenu="matMenu" overlapTrigger="false" xPosition="after">
    <button
    *ngFor="let item of menuOptions"
    [ngStyle]="{ 'color': item.color }"
    (click)="menuClicked(item.action, item.confirmationDialog)"
    mat-menu-item>{{item.name}}</button>
  </mat-menu>
</ng-container>
