import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { VisibilitySelection } from "@checkd-form/components/report-visibility-toggle/report-visibility-toggle.component"
import { Form } from "@checkd-form/models/form"
import { COLLECTIONS, ReportType, SortingDirection, SortingType } from "@models/common"
import { GeneralReport } from "@models/common/general-report"
import { ReportService } from "@services"
import { Observable, Subscription } from "rxjs"
import { map } from "rxjs/operators"
import { OpenReport } from "../../../reports/models/open-report"

@Component({
  selector: "checkd-report-header",
  templateUrl: "./report-header.component.html",
  styleUrls: ["./report-header.component.scss"],
})
export class ReportHeaderComponent {
  @Input() form: Form
  @Input() report: GeneralReport
  @Input() reportNumber: number
  @Input() reportName: string
  @Input() creatorName: string
  @Input() projectTitle: string
  @Input() createdAt: number
  @Input() lastTimelineElementMessage: string
  @Input() hasSortableItems: boolean = false
  @Input() editable: boolean = false
  @Input() canEditStatus: boolean = false
  @Input() showVisibilityToggle: boolean = true
  @Input() reportStatus: string = "unknown"
  @Input() reportType: ReportType = ReportType.NONE

  @Output() markReportAsClosed = new EventEmitter()
  @Output() editReportTitle = new EventEmitter()
  @Output() generateDocx = new EventEmitter()
  @Output() sortItemsBy = new EventEmitter<{ sortingType: SortingType; sortingDirection: SortingDirection }>()
  @Output() visibilityChange = new EventEmitter<VisibilitySelection>()

  // Used for gaining access to these enums in the template
  readonly sortingType = SortingType
  readonly sortingDirection = SortingDirection

  onSortItemsBy(sortingType: SortingType, sortingDirection: SortingDirection) {
    this.sortItemsBy.emit({ sortingType, sortingDirection })
  }
}
