import {
  checkdFooter,
  docDefinition,
  heading,
  horizontalLine,
  images,
  paragraph,
  qrCode,
  templateLogoHeader,
} from "./general.docdefinition"
import { Style } from "./styles.docdefinition"

interface IProjectTemplateQrConfig {
  title: string
  url: string
  projectName: string
  companyName: string
  checkdLogo: string
  templateLogo?: string
  companyLogo?: string
  description?: string
}

export const projectTemplateDocDefinition = (config: IProjectTemplateQrConfig) =>
  docDefinition({
    content: [
      heading(config.title, [Style.center, Style.redText]),
      qrCode(config.url, 300, [Style.center]),
      paragraph("Collected FORMS data will be added to this project", [Style.center]),
      paragraph(config.projectName!, [Style.center, Style.heading02]),
      paragraph(config.description!, [Style.center]),
      // paragraph(config.companyName, [ Style.center ]),
    ],
    header: config.templateLogo ? templateLogoHeader : {},
    footer: checkdFooter(config.companyName),
    images: images({
      checkdLogo: config.checkdLogo!,
      companyLogo: config.companyLogo!,
      templateLogo: config.templateLogo!,
    }),
  })
