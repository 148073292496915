<ng-template #large>
  <mat-form-field appearance="outline" style="position: relative; top: 4px">
    <mat-label>
      <mat-icon>remove_red_eye</mat-icon>
      Shared with:
    </mat-label>
    <mat-select [disabled]="!editable" [(value)]="currentVisibility">
      <mat-option *ngFor="let option of visibilityOptions" value="{{option.selection}}">
        {{option.text}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #small>
  <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="!editable" >
    <mat-icon [ngStyle]="{ 'color': smallIconColor }">remove_red_eye</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let option of visibilityOptions" (click)="currentVisibility = option.selection">
      <span [ngClass]="{'active-visibility': currentVisibility === option.selection}">{{option.text}}</span>
    </button>
  </mat-menu>
</ng-template>

<ng-container [ngSwitch]="size">
  <ng-container *ngSwitchCase="'dynamic'">
    <div fxHide.sm fxHide.lt-sm>
      <ng-container *ngTemplateOutlet="large"></ng-container>
    </div>
    <div fxHide.gt-sm>
      <ng-container *ngTemplateOutlet="small"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'full'">
    <ng-container *ngTemplateOutlet="large"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'minimized'">
    <ng-container *ngTemplateOutlet="small"></ng-container>
  </ng-container>
</ng-container>
