<div class="project-template-list-container">
  <div class="toggle-public-templates">
    <mat-checkbox
      (change)="onTogglePublicTemplatesChange($event)"
      [(ngModel)]="_hasPublicTemplates">
      Enable public templates for all project members
    </mat-checkbox>
  </div>

  <div class="loading-spinner-container" *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner diameter="50" mode="indeterminate"></mat-spinner>
  </div>

  <div class="project-template-list-table-container" *ngIf="!isLoading && tableHasData">
    <!-- FILTER -->
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter templates">
    </mat-form-field>

    <mat-table [dataSource]="dataSource">
      <!-- CHECKBOX COLUMN -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation() || handleCheckboxToggled(row)"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- TEMPLATE NAME COLUMN -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Templates</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.name }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"
          (click)="selection.toggle(row)"></mat-row>
    </mat-table>


    <mat-paginator [pageSize]="pageSize" [hidePageSize]="hidePageSize"></mat-paginator>

    <div class="bottom-button-and-label-container">
      <button mat-stroked-button class="display-selected-toggle-button"
              (click)="toggleDisplaySelectedOnly()">
        {{ displaySelectedOnlyToggle === true ? "show all" : "show selected" }}
      </button>
      <span class="selected-forms-text">{{selection.selected.length}} forms selected</span>
    </div>
  </div>

  <div class="no-templates-available-container" *ngIf="!isLoading && !tableHasData">
    No templates available
  </div>

</div>
