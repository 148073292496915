import { Role } from "./role"
import { RoleType } from "./roles-and-permissions.interface"

export class DummyRole extends Role {
  setupRoleData() {
    this.roleData = {
      name: "",
      description: "",
      roleType: RoleType.NONE,
      requirements: {
        sourceCollection: "",
        labels: [],
        targetCollection: "",
      },

      targetPermissions: { create: false, read: false, update: false, delete: false },
      permissions: [],
      inferredRoleTypes: {},
    }
  }
}
