import { Component, Input, OnInit } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms"
import { IFormsLibraryMemberCompanyEmailInvitation } from "@models/common/forms-library-member-company-invitation.interface"
import { CloudFunctionsService } from "@services"
import { MessageService } from "primeng/api"
import { firstValueFrom, timeout } from "rxjs"

@Component({
  selector: "checkd-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  @Input() invite!: IFormsLibraryMemberCompanyEmailInvitation

  signUpForm: UntypedFormGroup | null = null
  signUpInProgress = false

  constructor(
    private cfService: CloudFunctionsService,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private afAuth: AngularFireAuth
  ) {}

  ngOnInit(): void {
    this.signUpForm = this.fb.group({
      email: this.invite.targetEmail,
      companyName: ["", [Validators.required, Validators.pattern("^(?=\\s*\\S).*$")]],
      name: ["", [Validators.required, Validators.pattern("^(?=\\s*\\S).*$")]],
      password1: ["", [Validators.required, Validators.pattern("^(?=\\s*\\S).*$")]],
      password2: ["", [Validators.required, Validators.pattern("^(?=\\s*\\S).*$")]],
    })

    const emailController = this.signUpForm.get("email") as UntypedFormControl
    emailController.disable()
  }

  async handleSubmit() {
    try {
      this.signUpInProgress = true
      const emailControl = this.signUpForm!.get("email") as UntypedFormControl
      const companyNameControl = this.signUpForm!.get("companyName") as UntypedFormControl
      const nameControl = this.signUpForm!.get("name") as UntypedFormControl
      const password1Control = this.signUpForm!.get("password1") as UntypedFormControl
      const password2Control = this.signUpForm!.get("password2") as UntypedFormControl

      const email: string = emailControl.value.trim()
      const companyName: string = companyNameControl.value.trim()
      const name: string = nameControl.value.trim()
      const password1: string = password1Control.value
      const password2: string = password2Control.value

      if (password1 !== password2) {
        return this.messageService.add({ severity: "error", summary: "Error", detail: "Passwords do not match", life: 6000 })
      }

      const result = await firstValueFrom(
        this.cfService
          .createAuthUserAndCompany({ company: companyName, password: password1, personData: { companyName, email, name, fullName: name } })
          .pipe(timeout(10000))
      )

      if (!result) {
        throw new Error("Timed out")
      }

      if (result.error) {
        throw new Error(result.error)
      }

      await this.afAuth.signInWithEmailAndPassword(email, password1)
    } catch (e: any) {
      let detail = "An error has occurred"
      if (e.message) {
        detail = e.message
      }

      this.messageService.add({ severity: "error", summary: "Error", detail, life: 6000 })
    } finally {
      this.signUpInProgress = false
    }
  }
}
