<h2 mat-dialog-title>Duplicate {{ data.title }}</h2>

<mat-dialog-content formArrayName="duplicates" class="dialog__content">
  <form *ngIf="duplicateForm" [formGroup]="duplicateForm">
    <!--Input for each item in duplicate list-->
    <div *ngFor="let duplicate of duplicates!.controls; let i = index" [formGroupName]="i" class="dialog__card-container">
      <mat-form-field class="dialog__form-field">
        <mat-label>Name</mat-label>
        <input matInput formControlName="duplicateName" maxlength="256" placeholder="Report nr.1" />
      </mat-form-field>
      <!--        Remove duplicate from list-->
      <button mat-stroked-button color="warn" class="dialog__button" (click)="removeDuplicate(i)" [disabled]="duplicates.length <= 1">
        Remove
      </button>
    </div>
  </form>
</mat-dialog-content>

<!--  Buttons to either confirm duplicate or cancel process-->
<mat-dialog-actions class="dialog-actions">
  <button
    mat-stroked-button
    color="primary"
    (click)="addDuplicate(data.title)"
    [disabled]="duplicates.length >= 10"
    class="dialog-actions__button-to-add-more"
  >
    Add More
  </button>
  <div class="dialog-actions__confirm-btn-container">
    <button class="checkd-btn cancel-btn-color" mat-raised-button color="warn" (click)="dialogRef.close(null)">Cancel</button>
    <button class="checkd-btn save-btn-color" mat-stroked-button (click)="onDuplicateClicked()">Duplicate</button>
  </div>
</mat-dialog-actions>
