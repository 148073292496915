import { ItemAggregateData } from "./aggregate-data"
import { BaseModelData } from "./base-model.interface"
import { FloorplanAnnotationInterface } from "../floorplan"

export interface ItemInterface {
  name: string
  itemType: string
  number?: number
  positionX?: number
  positionY?: number
  status?: string
}

export interface ItemData extends BaseModelData {
  title?: string
  name?: string
  description?: string
  number?: number
  itemType: string
  positionX?: number
  positionY?: number
  positionZ?: number
  status?: string
  tags?: string[]
  dueDate: number | null
  legacyDataFloorplan?: FloorplanAnnotationInterface
  aggregateData?: ItemAggregateData
  bcfGuid?: string
}
