import { TemplateElement, TemplateElementType, ElementOptionCode } from "./template-element"

class CameraElement extends TemplateElement {
  override name: string = ""
  override icon = "forms:camera"
  override type: TemplateElementType = TemplateElementType.CAMERA
  override typeAlias: string = "Camera"
  override value = ""
  override options = [
    {
      id: 1,
      name: "Quarter",
      type: "checkbox",
      code: ElementOptionCode.QUARTER,
      value: false,
    },
    {
      id: 2,
      name: "Half",
      type: "checkbox",
      code: ElementOptionCode.HALF,
      value: false,
    },
    {
      id: 3,
      name: "Required",
      type: "checkbox",
      code: ElementOptionCode.REQUIRED,
      value: false,
    },
  ]
}

export { CameraElement }
