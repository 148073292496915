<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<ng-container *ngIf="item && currentUser">
  <div fxLayout="column">
    <item-contextmenu
      *ngIf="userProjectRole"
      [item]="item"
      [currentUser]="currentUser"
      [userProjectRole]="userProjectRole"
      [restrictedItemMenuActions]="restrictedItemMenuActions"
      (menuOptionSelected)="onMenuOptionSelected($event)"
    >
    </item-contextmenu>

    <div fxFlex="1 0 auto" fxLayout="row" class="top-bar" fxLayoutAlign="space-between center">
      <div [ngStyle]="{ cursor: (isBelowMaxWidth$ | async) ? 'pointer' : 'default' }" (click)="showComponent('left')">Chat</div>
      <div [ngStyle]="{ cursor: (isBelowMaxWidth$ | async) ? 'pointer' : 'default' }" (click)="showComponent('center')">Info</div>
      <div [ngStyle]="{ cursor: (isBelowMaxWidth$ | async) ? 'pointer' : 'default' }" (click)="showComponent('right')">Drawing</div>
    </div>

    <div fxFlex="9 0 auto" class="item-details" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center none">
      <!-- COMMENT COLUMN -->
      <div *ngIf="showLeft$ | async" class="left" fxLayout="column" fxLayoutAlign="space-between center" fxFlex="1 0 0">
        <item-timeline class="content overflow-y-auto" fxFlex="9 0 auto" [item]="item" [currentUser]="currentUser" [timeline]="timeline">
        </item-timeline>

        <checkd-item-comment-input
          class="bottom-bar"
          fxFlex="1 0 auto"
          [item]="item"
          [projectIsArchived]="project && project.archived"
          (onSendComment)="sendComment($event)"
        >
        </checkd-item-comment-input>
      </div>
      <!-- END COMMENT COLUMN -->

      <div *ngIf="showCenter$ | async" class="center" fxLayout="column" fxFlex="3 0 0" fxLayoutAlign="space-between center">
        <div class="content" fxFlex="9 0 auto" fxLayout="column">
          <div class="info-and-images" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px">
            <item-info [areTagsEditable]="areTagsEditable" [item]="item" [project]="project" (onTagsUpdated)="updateTags($event)">
            </item-info>

            <item-images
              *ngIf="withCarousel"
              [images]="itemImages"
              imageHeight="100px"
              [userItemRole]="userItemRole"
              [userProjectRole]="userProjectRole"
              [shadowVisible]="true"
              (removeImage)="removeImage($event)"
            >
            </item-images>

            <item-metadata
              (removeCollaborator)="removeCollaborator($event)"
              [item]="item"
              [project]="project"
              [currentUser]="currentUser"
              [userProjectRole]="userProjectRole"
            >
            </item-metadata>
          </div>
        </div>

        <div fxFlex="1 0 auto" class="bottom-bar" fxLayout="row" fxLayoutAlign="space-between center">
          <item-actions
            fxFlex="1 0 auto"
            *ngIf="item"
            [item]="item"
            [availableActions]="availableItemActions"
            [userItemRole]="userItemRole"
            [userProjectRole]="userProjectRole"
            [projectIsArchived]="project && project.archived"
            (onAssign)="assign()"
            (onUploadImage)="uploadImage()"
            (onEditTags)="editTags()"
          >
          </item-actions>

          <task-actions
            fxFlex="1 0 auto"
            *ngIf="item && hasTask"
            (taskAction)="handleTaskAction($event)"
            [projectIsArchived]="project && project.archived"
            [item]="item"
            [task]="task"
            [currentUser]="currentUser"
          >
          </task-actions>
        </div>
      </div>

      <div *ngIf="showRight$ | async" fxFlex="1 0 0" class="right" fxLayout="column" fxLayoutAlign="space-between center">
        <div class="content" fxFlex="9 0 auto" fxLayout="column">
          <drawing-section
            fxHide.xs
            fxFlex="1 1 0"
            *ngIf="showDrawingSection && item.itemType !== 'TOPIC_ITEM'"
            (onClick)="onDrawingSectionClicked($event)"
            (onDrawingSelected)="drawingSelected($event.drawing, $event.coordinates)"
            [currentCompanyHasDrawingFeature]="hasDrawingFeature"
            [actionButtonEvent]="drawingActionButtonClicked.asObservable()"
            [(showCancelButton)]="cancelButtonVisible"
            [(showSavePositionButton)]="savePositionButtonVisible"
            [item]="item"
            [project]="project"
          >
          </drawing-section>
        </div>
        <div fxFlex="1 0 auto" fxLayout fxLayoutAlign="end center" fxLayoutGap="10px" class="bottom-bar drawing-bottom-bar">
          <button primary-button [type]="'outline'" *ngIf="cancelButtonVisible" (click)="drawingActionButtonClicked.next('cancel')">
            Cancel
          </button>
          <button primary-button *ngIf="savePositionButtonVisible" (click)="drawingActionButtonClicked.next('save_position')">
            Save position
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
