import { InjectionToken } from "@angular/core"

const GET_STREAM_ACCESS_TOKEN = new InjectionToken<string>("GET_STREAM_ACCESS_TOKEN")

export { GET_STREAM_ACCESS_TOKEN }

/**
 * Chat room channel types we use in GetStream
 */
export type ChatRoomType =
  | "team" // For company chat rooms
  | "messaging" // For project chat rooms
