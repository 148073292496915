import { CheckdColors } from "@checkd-colors"
import { Item } from "../item"
import { IMenuOption, ItemMenuActions } from "./menu-actions.interface"

function createItemMenuActionData(item: Item, itemMenuAction: ItemMenuActions): IMenuOption {
  switch (itemMenuAction) {
    case ItemMenuActions.REMOVE_FROM_REPORT:
      return {
        name: "Remove from report",
        color: CheckdColors.CHECKD_RED,
        action: ItemMenuActions.REMOVE_FROM_REPORT,
        confirmationDialog: {
          title: `Really remove item ${item.number || ""} from the report?`,
          content: `Item ${item.number || ""} will be removed from this report.
          It will still be available in its project.`,
        },
      }

    case ItemMenuActions.SET_AS_CLOSED:
      return {
        name: "Set as closed",
        color: CheckdColors.CHECKD_GREEN,
        action: ItemMenuActions.SET_AS_CLOSED,
        confirmationDialog: { title: "Are you sure?", content: `Item ${item.number} will be set as closed.` },
      }

    case ItemMenuActions.REMOVE_ITEM:
      return {
        name: "Delete item",
        color: CheckdColors.CHECKD_RED,
        action: ItemMenuActions.REMOVE_ITEM,
        confirmationDialog: { title: "Confirm deleting item", content: `Are you sure you want to delete this item?` },
      }

    case ItemMenuActions.ADD_COLLABORATOR:
      return {
        name: "Add collaborator",
        color: CheckdColors.CHECKD_DARK,
        action: ItemMenuActions.ADD_COLLABORATOR,
        confirmationDialog: {
          title: `Add new collaborator?`,
          content: `This will add collaborator to this item and to the project.`,
        },
      }

    case ItemMenuActions.DUPLICATE_ITEM:
      return {
        name: "Duplicate item",
        color: CheckdColors.CHECKD_DARK,
        action: ItemMenuActions.DUPLICATE_ITEM,
      }

    case ItemMenuActions.EDIT_DETAILS:
      return {
        name: "Edit details",
        color: CheckdColors.CHECKD_DARK,
        action: ItemMenuActions.EDIT_DETAILS,
      }

    case ItemMenuActions.UPLOAD_IMAGE:
      return {
        name: "Upload image",
        color: CheckdColors.CHECKD_DARK,
        action: ItemMenuActions.UPLOAD_IMAGE,
      }

    case ItemMenuActions.VIEW_IMAGES:
      return {
        name: "View images",
        color: CheckdColors.CHECKD_DARK,
        action: ItemMenuActions.VIEW_IMAGES,
      }

    default:
      // @ts-ignore
      return null
  }
}

export { createItemMenuActionData }
