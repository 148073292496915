<h2 matDialogTitle>{{ title }}</h2>

<mat-dialog-content>
  <p>Please specify how many duplicates you want (max {{ maxDuplicates }}).</p>
  <p>The title, description, and images (if any) will be duplicated.</p>

  <form [formGroup]="duplicationForm" (ngSubmit)="onSubmit()">
    <mat-form-field>
      <input
        matInput
        min="1"
        formControlName="numberOfDuplicates"
        [max]="maxDuplicates"
        placeholder="Number of duplicates"
        type="number"
        value="1"
      />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <checkd-button bg="red" fg="white" (buttonClick)="onCancel()">Cancel</checkd-button>

  <checkd-button [disabled]="maxDuplicatesExceeded" bg="green" fg="white" (buttonClick)="onSubmit()">
    <span *ngIf="!maxDuplicatesExceeded">Duplicate</span>
    <span *ngIf="maxDuplicatesExceeded">Max {{ maxDuplicates }}</span>
  </checkd-button>
</mat-dialog-actions>
