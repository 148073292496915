import { CompanyAggregateData } from "./aggregate-data"
import { BaseModelData } from "./base-model.interface"

/**
 * @deprecated use CompanyData instead
 */
export interface CompanyInterface {
  name: string
  logo?: string
  orgNumber?: number
  address?: {
    street?: string
    city?: string
    country?: string
  }
  plan?: string
  tags?: any[]

  contactPerson?: string

  legacyDataForms?: {
    [propName: string]: any
  }
  legacyDataFloorplan?: {
    [propName: string]: any
  }
}

export enum CompanyFeatures {
  ASSOCIATES = "ASSOCIATES",
  BASIC = "BASIC",
  DRAWINGS = "DRAWINGS",
  ENTERPRISE = "ENTERPRISE",
  FIELD_CHAT = "FIELD_CHAT",
  FORMS = "FORMS",
  FREE = "FREE",
  REPORT_LOCKING = "REPORT_LOCKING",
  TEMPLATE_CREATION = "TEMPLATE_CREATION",
  PROJECT_ARCHIVING = "PROJECT_ARCHIVING",
  PROJECT_TEMPLATES = "PROJECT_TEMPLATES",
  FORMS_ITEMFLOW = "FORMS_ITEMFLOW",
  FORMS_TEMPLATE_JSON_EXPORT = "FORMS_TEMPLATE_JSON_EXPORT",
  FORMS_TABLE = "FORMS_TABLE",
  ITEM_CREATION_ACTIONBUTTON = "ITEM_CREATION_ACTIONBUTTON",
  COMPANY_INSIGHTS = "COMPANY_INSIGHTS",
  EXPORT_BCF = "EXPORT_BCF",
  QR_OPEN_TEMPLATES = "QR_OPEN_TEMPLATES",
  FORMS_LIBRARY = "FORMS_LIBRARY",
  SSO_AD_LOGIN = "SSO_AD_LOGIN",
  FORMS_PDF_BY_MAIL = "FORMS_PDF_BY_MAIL",
  MEMBER_TEMPLATES_VIEWER = "MEMBER_TEMPLATES_VIEWER",
}

export const AG_RELEVANT_COMPANY_FEATURES = [
  CompanyFeatures.DRAWINGS,
  CompanyFeatures.FORMS,
  CompanyFeatures.PROJECT_TEMPLATES,
  CompanyFeatures.TEMPLATE_CREATION,
  CompanyFeatures.FIELD_CHAT,
]

export enum LoginProviders {
  MICROSOFT = "microsoft",
  USERNAME_PASSWORD = "usernameAndPassword",
}

export interface CompanyData extends BaseModelData {
  name: string
  logo?: string
  orgNumber?: number
  address?: {
    street?: string
    city?: string
    country?: string
  }
  plan?: string
  tags?: any[]

  loginMethods?: string[]

  contactPerson?: string

  legacyDataForms?: {
    [propName: string]: any
  }
  legacyDataFloorplan?: {
    [propName: string]: any
  }

  email?: string

  features?: string[]

  associatedPeople?: string[]
  companyType?: string
  aggregateData?: CompanyAggregateData
  pdfFooter?: string
  isTemplatePublisher?: boolean

  // See the company model (company.ts) for an explanation of this field
  showNextProjectIntegration?: boolean
}
