<div class="password-recovery" fxLayout="column" fxLayoutAlign="center center">
  <h1>Velkommen til CHECKD 3.0!</h1>

  <div *ngIf="!wasEmailSubmitted" class="message-before-recovery">
    <p>For å få tilgang til den nye versjonen må du aktivere kontoen din.<br>Skriv inn din mailaddresse under. Du vil deretter motta en e-post med informasjon om aktiveringen.</p>

    <p>
      To gain access to the new version, you must activate your account.<br>Type in your email address below. You will then receive an email with more information.
    </p>
  </div>

  <div *ngIf="!wasEmailSubmitted" class="password-recovery-form">
    <mat-form-field class="example-full-width">
      <input #emailInput matInput placeholder="Enter your email" value="" [formControl]="email">
    </mat-form-field>
    <checkd-button (click)="onSubmit(emailInput.value.trim())">Submit</checkd-button>
  </div>

  <div *ngIf="wasEmailSubmitted" class="message-after-recovery">
    <p>
      Mailen har blitt sendt. Vennligst sjekk inboksen din for informasjon om hvordan du resetter passordet ditt og aktiverer kontoten.
    </p>

    <p>Email has been sent. Please check your inbox for instructions
      on how to reset your password and activate your account.</p>

    <a href="/login">Proceed to the login page.</a>

  </div>
</div>
