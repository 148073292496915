import { OverlayRef } from "@angular/cdk/overlay"
import { Intercom } from "@supy-io/ngx-intercom"

export class DisabledOverlayRef {
  constructor(private overlayRef: OverlayRef, private intercom: Intercom) {}

  close(): void {
    this.overlayRef.dispose()
  }

  triggerIntercom(): void {
    this.intercom.show()
  }
}
