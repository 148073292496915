import { Component, OnInit } from "@angular/core"
import { FormsLibraryConfigService } from "../../../../edit-forms-template-dialog/services/forms-library-config.service"
import { FormsLibraryViewService } from "../../../../services/forms-library-view.service"

@Component({
  selector: "checkd-filter-templates",
  templateUrl: "./filter-templates.component.html",
  styleUrls: ["./filter-templates.component.scss"],
})
export class FilterTemplatesComponent implements OnInit {
  constructor(public formsLibraryConfigService: FormsLibraryConfigService, public formsLibViewService: FormsLibraryViewService) {}

  viewCompanyTemplatesChecked = false
  selectedPublisherCompanyUids: string[] = []
  selectedPredefinedTags: string[] = []

  checked: boolean = false

  ngOnInit() {
    const currentFilters = this.formsLibViewService.getCurrentFilterData()

    this.viewCompanyTemplatesChecked = currentFilters.viewCompanyTemplates
    this.selectedPublisherCompanyUids = [...currentFilters.creatorCompanyUids]
    this.selectedPredefinedTags = [...currentFilters.predefinedTags]
  }

  onFilterTemplatesClicked() {
    this.formsLibViewService.setCompanyAndTagsSearchFilter({
      viewCompanyTemplates: this.viewCompanyTemplatesChecked,
      creatorCompanyUids: [...this.selectedPublisherCompanyUids],
      predefinedTags: [...this.selectedPredefinedTags],
    })
  }
}
