import { DrawingData } from "@models/common/drawing.interface"
import firebase from "firebase/compat/app"
import { BaseModel } from "./base-model"
import { COLLECTIONS } from "./collections.interface"
import { Item } from "./item"
import { Relation } from "./relation"

export class Drawing extends BaseModel<DrawingData> {
  public static override COLLECTION: string = "drawings"

  get name() {
    return this.data.name
  }
  get storage() {
    return this.data.storage
  }

  public static override doc(uid: string): firebase.firestore.DocumentReference {
    return Drawing.db.collection(Drawing.COLLECTION).doc(uid)
  }

  public static get(uid: string): Promise<Drawing> {
    return Drawing.getModel(Drawing, uid)
  }

  public static create(data: DrawingData): Promise<Drawing> {
    return Drawing.db
      .collection(Drawing.COLLECTION)
      .add(data)
      .then((docRef) => docRef.get())
      .then((docSnapshot) => new Drawing(docSnapshot.data() as DrawingData, docSnapshot.id, docSnapshot.ref))
  }

  getItems(): Promise<Item[]> {
    return Relation.getAllTargets(this, COLLECTIONS.ITEMS).then((targets) => targets as Item[])
  }

  getUrlPath(projectUid: string): string {
    return `projects/${projectUid}/drawings/${this.uid}`
  }
}
