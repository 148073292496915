import { Component, OnInit, Input } from "@angular/core"

@Component({
  selector: "checkd-template-card",
  templateUrl: "./template-card.component.html",
  styleUrls: ["./template-card.component.scss"],
})
export class TemplateCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input() title: string = ""
  @Input() description: string = ""
  @Input() img: string = ""
}
