<main class="project-items">
  <div class="project-items__report-top-container">
    <div class="project-items__filter-reports">
      <span class="reports-count">
        {{ reportsCountText | async }}
      </span>

      <div *ngIf="currentlyShownReports | async as currentSelectedReportsTab" class="project-items__report-list-selector">
        <button
          mat-button
          [ngClass]="currentSelectedReportsTab === 'all_reports' ? 'active' : 'inactive'"
          (click)="currentlyShownReports.next('all_reports')"
        >
          <mat-icon>people</mat-icon>
          <span>All reports</span>
        </button>

        <button
          mat-button
          [ngClass]="currentSelectedReportsTab === 'my_reports' ? 'active' : 'inactive'"
          (click)="currentlyShownReports.next('my_reports')"
        >
          <div class="avatar-wrapper" fxLayout fxLayoutAlign="center center">
            <ngx-avatars class="user-avatar" [src]="currentUser?.image || ''" [name]="currentUser?.name" size="40"></ngx-avatars>
          </div>
          <span>My reports</span>
        </button>

        <ng-container *ngIf="hasQrOpenTemplatesFeature">
          <button
            mat-button
            [ngClass]="currentSelectedReportsTab === 'templates' ? 'active' : 'inactive'"
            (click)="currentlyShownReports.next('templates')"
            class="active"
          >
            <mat-icon class="colored" [ngStyle]="{ color: 'blue' }" svgIcon="template_icon_grey"></mat-icon>
            <span>Templates</span>
          </button>

          <button
            mat-button
            [ngClass]="currentSelectedReportsTab === 'open_reports' ? 'active' : 'inactive'"
            (click)="currentlyShownReports.next('open_reports')"
          >
            <mat-icon class="colored" [ngStyle]="{ color: 'blue' }" svgIcon="template_icon_grey"></mat-icon>
            <span>Open Reports</span>
          </button>
        </ng-container>
      </div>

      <button (click)="resetFilters()" style="display: inline-block; white-space: nowrap; font-size: 0.75em" mat-button>
        Clear filters
      </button>
    </div>
    <div class="project-items__btn-container">
      <button
        primary-button
        [isRounded]="true"
        *ngIf="showNewFormButton(project, currentCompany, hasProjectTemplates, hasCompanyTemplates)"
        [@showNewFormButton]
        [disabled]="project.archived || project.lockedByCheckd"
        [inProgress]="legacyReportCreationInProgress || true"
        (click)="newLegacyReport()"
      >
        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3910_12557)">
            <path
              d="M24 11.9994C24 12.7667 23.3827 13.384 22.6154 13.384H13.3846V22.6148C13.3846 23.3792 12.7644 24 12 24C11.2356 24 10.6154 23.3821 10.6154 22.6148V13.384H1.38462C0.620192 13.384 0 12.7644 0 12C0 11.2379 0.620192 10.6148 1.38462 10.6148H10.6154V1.38404C10.6154 0.619615 11.2356 0 12 0C12.7644 0 13.3846 0.619615 13.3846 1.38404V10.6148H22.6154C23.3827 10.6148 24 11.2379 24 11.9994Z"
              fill="currentcolor"
            />
          </g>
          <defs>
            <clipPath id="clip0_3910_12557">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span> New form</span>
      </button>
    </div>
  </div>

  <div class="report-list">
    <checkd-report-list
      #reportList
      [reports]="selectedReports$ | async"
      [columns]="reportListColumns$ | async"
      [reportsTagCollection]="reportTagCollection"
      [currentUser]="currentUser"
      [userProjectRole]="userProjectRole"
      (menuOptionSelected)="onMenuOptionSelected($event)"
      [isReportListReady]="projectReportsReady"
    >
    </checkd-report-list>
  </div>
</main>
