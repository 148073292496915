<div class="wrapper">
  <mat-form-field [style.width]="width">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      #ac
      matInput
      aria-label="State"
      [matAutocomplete]="auto"
      [formControl]="acCtrl"
      (keyup)="onKeyup()"
      (keyup.enter)="onKeyupEnter()"
    />

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected()">
      <mat-option *ngFor="let val of filteredValues" [value]="val">
        <span>{{ val }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
