import { MatIconRegistry } from "@angular/material/icon"
import { DomSanitizer } from "@angular/platform-browser"
import { Injectable } from "@angular/core"

@Injectable()
export class CheckdIconService {
  constructor(private iconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.setupStatusIcons()
  }

  setupStatusIcons() {
    this.iconRegistry.addSvgIconInNamespace(
      "checkdStatus",
      "completed_toggled",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/task_status/completed_toggled.svg")
    )
    this.iconRegistry.addSvgIconInNamespace(
      "checkdStatus",
      "completed_untoggled",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/task_status/completed_untoggled.svg")
    )
    this.iconRegistry.addSvgIconInNamespace(
      "checkdStatus",
      "open_toggled",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/task_status/open_toggled.svg")
    )
    this.iconRegistry.addSvgIconInNamespace(
      "checkdStatus",
      "open_untoggled",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/task_status/open_untoggled.svg")
    )
    this.iconRegistry.addSvgIconInNamespace(
      "checkdStatus",
      "delegated_toggled",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/task_status/delegated_toggled.svg")
    )
    this.iconRegistry.addSvgIconInNamespace(
      "checkdStatus",
      "delegated_untoggled",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/task_status/delegated_untoggled.svg")
    )
    this.iconRegistry.addSvgIconInNamespace(
      "checkdStatus",
      "fixed_toggled",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/task_status/fixed_toggled.svg")
    )
    this.iconRegistry.addSvgIconInNamespace(
      "checkdStatus",
      "fixed_untoggled",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/task_status/fixed_untoggled.svg")
    )
    this.iconRegistry.addSvgIconInNamespace(
      "checkdStatus",
      "inprogress_toggled",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/task_status/inprogress_toggled.svg")
    )
    this.iconRegistry.addSvgIconInNamespace(
      "checkdStatus",
      "inprogress_untoggled",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/task_status/inprogress_untoggled.svg")
    )
  }
}
