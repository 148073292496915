import { AngularFirestore } from "@angular/fire/compat/firestore"
import { Injectable } from "@angular/core"
import { TimelineElement, ITimelineData, Timeline, TimelineType, Person, LegacyReport, FieldReport } from "@models/common"
import { map, switchMap } from "rxjs/operators"
import { EMPTY, Observable, of as observableOf } from "rxjs"
import * as moment from "moment"
import { ModelService } from "./model.service"
import { TimelineService } from "./timeline.service"
import firebase from "firebase/compat/app"
import firestore = firebase.firestore
import { GeneralReport } from "@models/common/general-report"

@Injectable({
  providedIn: "root",
})
export class ReportTimelineService {
  constructor(private db: AngularFirestore, private modelService: ModelService, private timelineService: TimelineService) {}

  public listenForReport(legacyReport: LegacyReport | FieldReport) {
    return this.timelineService.listenFor(legacyReport)
  }

  public listenToLastTimelineElement(report: GeneralReport): Observable<TimelineElement> {
    if (report.ref == null) {
      throw `No ref found for legacy report ${report.uid}`
    }
    const ref = this.db.doc(report.ref.path).collection(TimelineElement.COLLECTION, (ref) => ref.orderBy("createdDate", "desc").limit(1))

    return ref.snapshotChanges().pipe(
      switchMap((docSnapshots) => {
        const elements = docSnapshots.map(
          (docSnapshot) =>
            new TimelineElement(docSnapshot.payload.doc.data() as ITimelineData, docSnapshot.payload.doc.id, docSnapshot.payload.doc.ref)
        )

        return elements.length > 0 ? observableOf(elements[0]) : EMPTY
        // return new Timeline(elements, ref.ref);
      })
    )
  }

  public readOnlyChanged(creator: Person, report: GeneralReport, readOnly: boolean) {
    const timelineData = {
      type: TimelineType.REPORT_READONLY_CHANGED,
      message: `The report was ${readOnly ? "" : "un"}locked.`,
      currentData: {
        report: { readOnly },
      },
    }
    return this.addTimelineElement(creator, report, timelineData)
  }

  public batchReadOnlyChanged(batch: firestore.WriteBatch, creator: Person, report: GeneralReport, readOnly: boolean) {
    const timelineData = {
      type: TimelineType.REPORT_READONLY_CHANGED,
      message: `The report was ${readOnly ? "" : "un"}locked.`,
      currentData: {
        report: { readOnly },
      },
    }

    return this.batchAddTimelineElement(batch, creator, report, timelineData)
  }

  public batchAddTimelineElement(batch: firestore.WriteBatch, creator: Person, report: GeneralReport, data: any) {
    const mergedData = Object.assign({}, data, {
      creator: creator.ref,
      createdDate: Date.now(),
    })

    return Timeline.batchAdd(batch, report, mergedData)
  }

  public tagsChanged(batch: firestore.WriteBatch, editor: Person, report: GeneralReport, previousTags: string[], newTags: string[]) {
    const timelineData = {
      type: TimelineType.REPORT_TAGS_EDITED,
      message: "The report tags were edited",
      previousData: { tags: previousTags },
      currentData: { tags: newTags },
    }

    return this.batchAddTimelineElement(batch, editor, report, timelineData)
  }

  public async addTimelineElement(creator: Person, report: GeneralReport, data: any) {
    const mergedData = Object.assign({}, data, {
      creator: creator.ref,
      createdDate: Date.now(),
    })
    const timeline = await Timeline.getFor(report)
    return timeline.add(mergedData)
  }

  public createMessageFromTimelineElement(timelineElement: TimelineElement, creator?: Person) {
    switch (timelineElement.type) {
      case TimelineType.REPORT_READONLY_CHANGED: {
        const timelineData = timelineElement.data

        if (timelineData && timelineData.currentData && timelineData.currentData.report) {
          const currentData = timelineData.currentData
          const readOnly = currentData!.report!.readOnly

          if (readOnly !== null) {
            const lockedStr = readOnly ? "Locked" : "Unlocked"
            const byStr = creator && creator.name ? `by ${creator.name}` : ""
            const atStr =
              timelineData.createdDate && timelineData.createdDate > 0
                ? `at ${moment(timelineData.createdDate).format("DD-MM-YYYY, HH:mm")}`
                : ""
            return [lockedStr, byStr, atStr].join(" ")
          }
        }

        return ""
      }
      default:
        return ""
    }
  }

  public batchDuplicateReportTimeline(batch: firestore.WriteBatch, creator: Person, report: GeneralReport, previousCreator: Person) {
    const timelineData: ITimelineData = {
      creator: creator.ref!,
      type: TimelineType.REPORT_DUPLICATED,
      currentData: { person: creator.data },
      previousData: { person: previousCreator.data },
    }

    return this.batchAddTimelineElement(batch, creator, report, timelineData)
  }
}
