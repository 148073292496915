<div fxLayout="row" fxLayoutAlign="space-around">
  <button
    mat-button
    [ngClass]="isActive('items') ? 'button-active' : 'button-inactive'"
    fxLayout="column"
    fxLayoutAlign="center center"
    (click)="onTabSelected('items')"
  >
    <mat-icon [ngClass.gt-xs]="'icon-fix'" id="projectItemsIcon" svgIcon="project_view:items" class="tabbar__icon"></mat-icon>
    <div fxHide.lt-sm>ITEMS</div>
  </button>

  <button
    mat-button
    [ngClass]="isActive('reports') ? 'button-active' : 'button-inactive'"
    fxLayout="column"
    fxLayoutAlign="center center"
    (click)="onTabSelected('reports')"
  >
    <mat-icon id="projectReportsIcon" svgIcon="project_view:reports" class="tabbar__icon"></mat-icon>
    <div fxHide.lt-sm>REPORTS</div>
  </button>

  <!--
  NB: The checks for ngClass here are a bit convoluted.
  Basically, we don't want to disable the 'drawings' tab before the project is fetched because of user experience (hence the check for !projectReady).
  After the project is fetched, we check for the DRAWINGS feature.
  If the DRAWINGS feature is available we run the normal check for an active tab like for the other tabs.
  If not, we assign the button-disabled class to the button.
  -->
  <button
    mat-button
    [ngClass]="
      !projectReady || projectService.hasDrawingsFeature(project)
        ? isActive('drawings')
          ? 'button-active'
          : 'button-inactive'
        : 'button-disabled'
    "
    fxLayout="column"
    fxLayoutAlign="center center"
    (click)="onTabSelected('drawings')"
  >
    <mat-icon id="projectDrawingsIcon" svgIcon="project_view:drawings" class="tabbar__icon"></mat-icon>
    <div fxHide.lt-sm>DRAWINGS</div>
  </button>

  <button
    mat-button
    [ngClass]="isActive('people') ? 'button-active' : 'button-inactive'"
    fxLayout="column"
    fxLayoutAlign="center center"
    (click)="onTabSelected('people')"
  >
    <mat-icon id="projectPeopleIcon" svgIcon="project_view:person" class="tabbar__icon"></mat-icon>
    <div fxHide.lt-sm>PEOPLE</div>
  </button>

  <button
    mat-button
    [ngClass]="isActive('gallery') ? 'button-active' : 'button-inactive'"
    fxLayout="column"
    fxLayoutAlign="center center"
    (click)="onTabSelected('gallery')"
  >
    <mat-icon id="projectGalleryIcon" svgIcon="project_view:gallery" class="tabbar__icon"></mat-icon>
    <div fxHide.lt-sm>GALLERY</div>
  </button>

  <button
    mat-button
    [ngClass]="isActive('info') ? 'button-active' : 'button-inactive'"
    fxLayout="column"
    fxLayoutAlign="center center"
    (click)="onTabSelected('info')"
  >
    <mat-icon id="projectInfoIcon" svgIcon="project_view:info" class="tabbar__icon"></mat-icon>
    <div fxHide.lt-sm>INFO</div>
  </button>
</div>
