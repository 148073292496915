import { BaseModelData, ReportGenerationData } from "."

export interface LegacyReportData extends BaseModelData {
  reportDataId?: number
  isMigrated?: boolean
  reportId?: number
  createdDateTime?: string
  qrCode?: any // Drop this, not in use
  notSynced?: boolean
  modifiedUserId?: number
  verifierUserId?: number
  number?: number
  isArchived?: boolean
  modifiedDateTime?: string
  isDeleted?: boolean
  projectId?: number
  createdUserId?: number
  ownerUserId?: number
  reportStatus?: number

  name: string
  // TODO reportName added for legacy support. Switch to name attribute
  reportName?: string
  status: string
  description: string
  headerTemplateData: string
  detailTemplateData: string

  noOfElements?: number
  noOfFilledElements?: number

  createdAt?: number
  updatedAt?: number
  reportGenerationData?: ReportGenerationData
  reportType?: string

  logo?: string
  templatePublicVersion?: string | null
  pdfFooterText: string | null
  footerLogo?: string

  isAnonymous?: boolean
}
