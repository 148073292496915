<main class="project-people">
  <!-- <checkd-button *ngIf="true" bg="blue" fg="white" (buttonClick)="addPerson()" [disabled]="project.archived || project.lockedByCheckd">
    Add people
  </checkd-button> -->

  <button
    primary-button
    [isRounded]="true"
    (click)="addPerson()"
    [disabled]="project.archived || project.lockedByCheckd"
    class="project-people__add-btn"
  >
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3910_12557)">
        <path
          d="M24 11.9994C24 12.7667 23.3827 13.384 22.6154 13.384H13.3846V22.6148C13.3846 23.3792 12.7644 24 12 24C11.2356 24 10.6154 23.3821 10.6154 22.6148V13.384H1.38462C0.620192 13.384 0 12.7644 0 12C0 11.2379 0.620192 10.6148 1.38462 10.6148H10.6154V1.38404C10.6154 0.619615 11.2356 0 12 0C12.7644 0 13.3846 0.619615 13.3846 1.38404V10.6148H22.6154C23.3827 10.6148 24 11.2379 24 11.9994Z"
          fill="currentcolor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3910_12557">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <span> Add people </span>
  </button>

  <app-placeholder *ngIf="projectMembers?.length <= 0" svgIcon="personscreen_empty" heading="No people">
    <p>Add workers by pressing the orange button</p>
  </app-placeholder>

  <div>
    <table>
      <thead>
        <tr>
          <th *ngFor="let column of ['', 'Name', 'Mail', 'Position', 'Phone', 'Role', '']" [scope]="column">{{ column }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of projectMembersWithRoles">
          <td><ngx-avatars [src]="createAvatarThumbnailUrl(item.member)" [name]="item.member.name" size="30"></ngx-avatars></td>
          <td scope="row" data-label="Name">{{ item.member.name }}</td>
          <td data-label="Mail">
            <a href="mailto:{{ item.member.email }}">{{ item.member.email }}</a>
          </td>
          <td data-label="Position">
            <people-position-item [person]="item.member" [positionFor]="project" [canEdit]="canEditPosition"> </people-position-item>
          </td>

          <td data-label="Phone">
            <a href="tel:{{ item.member.phone }}">{{ item.member.phone }}</a>
          </td>

          <td data-label="Role">
            <people-role-selector
              [roles]="roles"
              [person]="item.member"
              [peopleListOf]="project"
              [showRoleSelector]="canChangePeopleRole && !project?.archived && !project?.lockedByCheckd"
              [editorCount]="editorCount"
              [ownerCount]="ownerCount"
              [currentUser]="currentUser"
              [currentUserRole]="currentUserProjectRole"
            >
            </people-role-selector>
          </td>

          <td>
            <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #actionsMenu="matMenu">
              <button
                mat-menu-item
                (click)="removeMember(item.member, item.role)"
                [disabled]="!userCanRemoveProjectMember(item.member, item.role)"
              >
                <mat-icon>remove_circle_outline</mat-icon>
                <span>{{ contextMenuRemoveMemberText(item.member) }}</span>
              </button>
            </mat-menu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Pending invitations -->
  <div fxLayoutAlign="center">
    <mat-list>
      <h3 mat-subheader>Pending invitations</h3>
      <mat-list-item *ngFor="let invitation of pendingProjectInvitations">
        <mat-icon mat-list-icon svgIcon="user"></mat-icon>
        <p mat-line>
          <strong>{{ invitation?.targetEmail }}</strong>
        </p>
        <p *ngIf="invitation && invitation.createdAt" mat-line>sent {{ invitation?.createdAt / 1000 | amFromUnix | amTimeAgo }}</p>

        <button *ngIf="canManageInvitations" mat-icon-button [matMenuTriggerFor]="invitationsMenu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #invitationsMenu="matMenu">
          <button mat-menu-item (click)="copyInvitationLink(invitation)">
            <mat-icon>content_copy</mat-icon>
            Copy invitation link
          </button>

          <button style="color: red" mat-menu-item (click)="removeInvitationDialog(invitation)">
            <mat-icon>delete</mat-icon>
            Remove invitation
          </button>
        </mat-menu>
      </mat-list-item>
    </mat-list>
  </div>
</main>
