<div class="tw-bg-white tw-rounded-lg tw-shadow tw-h-auto tw-max-w-md tw-w-full tw-p-6 tw-grid tw-gap-6">
  <h2 class="tw-px-4 tw-text-3xl tw-font-bold tw-brand-text-500">Add members to chatroom</h2>

  <ng-container *ngIf="!isLoading && membersForm; else loading">
    <div class="tw-grid tw-gap-4">
      <form [formGroup]="membersForm" (ngSubmit)="onAddMembersClicked()">
        <div class="tw-grid tw-gap-4">
          <ng-container formArrayName="members">
            <div class="tw-grid tw-gap-2 tw-h-[400px] tw-overflow-auto">
              <label class="tw-flex tw-gap-2" *ngFor="let member of memberArray?.controls ?? []; let idx = index" [formGroupName]="idx">
                <input type="checkbox" formControlName="checked" />
                {{ member.get("name")?.value }}
              </label>
            </div>
          </ng-container>

          <div class="tw-flex tw-justify-end tw-gap-4">
            <button
              class="tw-text-next-orange-500 tw-font-bold tw-py-2 tw-px-4 tw-rounded hover:tw-bg-next-orange-50 tw-transform tw-transition-transform active:tw-scale-90 hover:tw-text-next-orange-500 active:tw-bg-next-orange-500 active:tw-text-next-white-50 focus-visible:tw-bg-next-orange-50 focus-visible:tw-text-next-orange-500 focus-visible:tw-outline-2 focus-visible:tw-outline-next-orange-500 disabled:tw-pointer-events-none disabled:tw-opacity-30"
              (click)="onClose.emit()"
            >
              Cancel
            </button>
            <button
              class="add-members__add-btn tw-bg-next-orange-500 tw-py-2 tw-px-4 tw-text-white tw-cursor-pointer tw-h-11 tw-min-h-full tw-rounded-md tw-flex tw-justify-center tw-items-center tw-gap-2 tw-transform tw-transition-transform active:tw-scale-90 hover:tw-bg-next-orange-700 active:tw-bg-next-orange-300 focus-visible:tw-bg-next-orange-500 focus-visible:tw-text-next-white-50 disabled:tw-pointer-events-none disabled:tw-opacity-30"
              type="submit"
              [disabled]="membersForm.pristine"
            >
              Add
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-container>

  <ng-template #loading>
    <div>Loading...</div>
  </ng-template>
</div>
