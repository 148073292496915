import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core"
import { CommonModule } from "@angular/common"
import { EmptyChannelListIconComponent, PlusIconComponent } from "../../icons-components"
@Component({
  selector: "field-chat-lib-empty-channel-main-chat",
  standalone: true,
  imports: [CommonModule, EmptyChannelListIconComponent, PlusIconComponent],
  template: `
    <div class="tw-grid tw-place-items-center tw-h-full tw-content-center tw-gap-8 tw-p-10">
      <div>
        <field-chat-lib-empty-channel-list-icon></field-chat-lib-empty-channel-list-icon>
      </div>
      <h3 class="tw-text-lg tw-font-semibold tw-text-next-navy-500 tw-text-center tw-max-w-lg">
        Hello and welcome to our chat! It appears that you haven't created a channel yet, but don't worry - it's a simple process. All you
        need to do is click on the button below and you can start chatting right away!
      </h3>
      <button
        (click)="onCreateChannelClicked.emit()"
        class="main-chat__create-btn tw-bg-next-orange-500 tw-p-2 tw-text-white tw-w-fit tw-cursor-pointer tw-h-11 tw-min-h-full tw-rounded-md tw-flex tw-justify-center tw-items-center tw-gap-2 tw-transform tw-transition-transform active:tw-scale-90 hover:tw-bg-next-orange-700 active:tw-bg-next-orange-300 focus-visible:tw-bg-next-orange-500 focus-visible:tw-text-next-white-50 disabled:tw-pointer-events-none disabled:tw-opacity-30 ng-star-inserted"
      >
        <field-chat-lib-plus-icon [size]="'sm'"></field-chat-lib-plus-icon>
        <span>Create channel</span>
      </button>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyChannelMainChatComponent {
  @Output() onCreateChannelClicked = new EventEmitter<void>()
}
