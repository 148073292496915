import { Component, OnInit, Inject } from "@angular/core"
import { DisabledOverlayRef } from "./checkd-disabled-overlay-ref"
import { DISABLED_OVERLAY_DIALOG_DATA } from "./checkd-disabled-overlay.tokens"
import { Project, Company } from "@models/common"
import { UserService, ProjectService } from "@services"
import { Observable } from "rxjs"

@Component({
  selector: "checkd-disabled-overlay",
  templateUrl: "./checkd-disabled-overlay.component.html",
  styleUrls: ["./checkd-disabled-overlay.component.scss"],
})
export class CheckdDisabledOverlayComponent implements OnInit {
  userCompany$: Observable<Company>
  projectCompany$: Observable<Company>
  project: Project

  constructor(
    public dialogRef: DisabledOverlayRef,
    private userService: UserService,
    private projectService: ProjectService,
    @Inject(DISABLED_OVERLAY_DIALOG_DATA) public data: any
  ) {
    if (data.project) {
      this.project = data.project as Project
      this.userCompany$ = this.userService.currentCompany$
      this.projectCompany$ = this.projectService.listenToCompany(this.project)
    }
  }

  ngOnInit() {}
}
