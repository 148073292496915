<div
  [ngClass]="{ 'notification-container': true, unread: !notification.isOpened }"
  fxLayout="row"
  matRipple
  [matRippleCentered]="true"
  [matRippleColor]="'#415bd850'"
  (click)="rowClicked()"
>
  <div class="icon-area" fxFlex="15" [ngSwitch]="notification.notificationType">
    <ng-container *ngSwitchCase="notificationType.TASK_NEW_ASSIGNED">
      <mat-icon svgIcon="notifications:task-assigned"></mat-icon>
    </ng-container>

    <ng-container *ngSwitchCase="notificationType.TASK_REJECTED">
      <mat-icon class="warn-icon">warning</mat-icon>
    </ng-container>

    <ng-container *ngSwitchCase="notificationType.TASK_FIXED">
      <mat-icon svgIcon="notifications:task-fixed"></mat-icon>
    </ng-container>

    <ng-container *ngSwitchCase="notificationType.ITEM_CHAT_MESSAGE_NEW">
      <mat-icon>chat</mat-icon>
    </ng-container>

    <ng-container *ngSwitchCase="notificationType.PROJECT_ARCHIVED">
      <mat-icon svgIcon="notifications:project-archived"></mat-icon>
    </ng-container>

    <ng-container *ngSwitchCase="notificationType.TASK_FIX_REJECTED">
      <mat-icon svgIcon="notifications:fix-rejected"></mat-icon>
    </ng-container>

    <ng-container *ngSwitchCase="notificationType.TASK_COLLABORATOR_ADDED">
      <mat-icon svgIcon="notifications:collaborator-added"></mat-icon>
    </ng-container>

    <ng-container *ngSwitchCase="notificationType.PROJECT_USER_INVITED">
      <mat-icon svgIcon="notifications:project-invitation"></mat-icon>
    </ng-container>
  </div>

  <div fxFlex="85">
    <div fxLayout="row wrap">
      <div class="text-area" fxFlex="0 0 80%" [ngSwitch]="notification.notificationType">
        <p *ngSwitchCase="notificationType.TASK_NEW_ASSIGNED">
          New task <span class="bold">{{ taskNumber }} {{ taskName }}</span> is assigned to you by
          <span class="bold">{{ assignerName }}</span> in project <span class="bold">{{ projectName }}</span>
        </p>

        <p *ngSwitchCase="notificationType.TASK_REJECTED">
          Task <span class="bold">{{ taskNumber }} {{ taskName }}</span> in project <span class="bold">{{ projectName }}</span> was rejected
          by <span class="bold">{{ assigneeName }}</span
          >, and needs your attention
        </p>

        <p *ngSwitchCase="notificationType.TASK_FIXED">
          Task <span class="bold">{{ taskNumber }} {{ taskName }}</span> in project <span class="bold">{{ projectName }}</span> was set as
          fixed by <span class="bold">{{ assigneeName }}</span
          >, and is ready for your review
        </p>

        <p *ngSwitchCase="notificationType.ITEM_CHAT_MESSAGE_NEW">
          New chat message from <span class="bold">{{ timelineCreatorName }}</span> on item
          <span class="bold">{{ timelineItemNumber }}{{ timelineItemName }}</span> in project <span class="bold">{{ projectName }}</span
          >. Click to respond
        </p>

        <p *ngSwitchCase="notificationType.PROJECT_ARCHIVED">
          Project <span class="bold">{{ projectName }}</span> has been archived by
          <span class="bold">{{ archiverName }}</span>
        </p>

        <p *ngSwitchCase="notificationType.TASK_FIX_REJECTED">
          Fix for task <span class="bold">{{ taskNumber }} {{ taskName }}</span> in project <span class="bold">{{ projectName }}</span> was
          rejected by <span class="bold">{{ assigneeName }}</span>
        </p>

        <p *ngSwitchCase="notificationType.TASK_COLLABORATOR_ADDED">
          <span class="bold">{{ collaboratorName }}</span> has been added as a collaborator by
          <span class="bold">{{ assignerName }}</span> on task <span class="bold">{{ taskNumber }} {{ taskName }}</span> in project
          <span class="bold">{{ projectName }}</span>
        </p>

        <p *ngSwitchCase="notificationType.PROJECT_USER_INVITED">
          <span class="bold">{{ assignerName }}</span> has invited you to join project
          <span class="bold">{{ projectName }}</span>
        </p>
      </div>

      <div class="avatar-area" fxFlex="0 0 20%">
        <ngx-avatars [src]="avatarThumbnailUrl" [name]="avatarFallbackName" size="30"></ngx-avatars>
      </div>

      <div class="bottom-row-drawing-name-and-time" fxFlex="0 0 100%">
        <div *ngIf="hasDrawingName" class="drawing-name-container">
          <mat-icon class="drawing-icon" svgIcon="drawing-dark"></mat-icon>
          <span class="drawing-name-text">{{ drawingName }}</span>
        </div>

        <span class="time">
          {{ notification.createdAt ? (notification.createdAt / 1000 | amFromUnix | amTimeAgo) : "" }}
        </span>
      </div>
    </div>
  </div>
</div>
