import { ElementOptionCode, TemplateElement, TemplateElementType } from "./template-element"

class TextfieldElement extends TemplateElement {
  override name: string = ""
  override icon = "forms:text-field"
  override type: TemplateElementType = TemplateElementType.TEXT_FIELD
  override typeAlias: string = "Text Field"
  override value = ""
  override options = [
    {
      id: 1,
      name: "Quarter",
      type: "checkbox",
      code: ElementOptionCode.QUARTER,
      value: false,
    },
    {
      id: 2,
      name: "Half",
      type: "checkbox",
      code: ElementOptionCode.HALF,
      value: false,
    },
    {
      id: 3,
      name: "Required",
      type: "checkbox",
      code: ElementOptionCode.REQUIRED,
      value: false,
    },
    {
      id: 4,
      name: "Is Description",
      type: "checkbox",
      code: ElementOptionCode.ISDESCRIPTION,
      value: false,
    },
    {
      id: 5,
      name: "Include in email",
      type: "checkbox",
      code: ElementOptionCode.INCLUDE_IN_EMAIL,
      value: false,
    },
    {
      id: 6,
      name: "Max length",
      type: "number",
      code: ElementOptionCode.TEXT_FIELD_MAX_LENGTH,
      value: 50,
    },
    {
      id: 7,
      name: "Font Size",
      type: "size_dropdown",
      code: ElementOptionCode.FONT_SIZE,
      value: "medium",
    },
    {
      id: 8,
      name: "Hide title in PDF",
      type: "checkbox",
      code: ElementOptionCode.HIDE_TITLE_IN_PDF,
      value: false,
    },
    {
      id: 9,
      name: "Height",
      type: "number",
      code: ElementOptionCode.TEXT_FIELD_HEIGHT,
      value: 15,
    },
  ]
}

export { TextfieldElement }
