<h2 mat-dialog-title>You have been invited to a project</h2>

<mat-dialog-content>
  <ng-container *ngIf="invitation$ | async as invitation; else loadingSpinner">
    <p>{{ invitationText() }}. Click "Accept invitation" to accept and go to project</p>
  </ng-container>

  <ng-template #loadingSpinner>
    <mat-spinner></mat-spinner>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="checkd-btn cancel-btn-color" mat-raised-button (click)="closeDialog()">Ignore</button>
  <button
    class="checkd-btn save-btn-color"
    mat-raised-button
    (click)="acceptInvitationButtonClicked(invitation)"
    [disabled]="(invitationIsLoading$ | async) || acceptInvitationInProgress"
  >
    Accept invitation
  </button>
</mat-dialog-actions>
