import { Injectable, Injector, ComponentRef } from "@angular/core"
import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay"
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal"

import { CheckdDisabledOverlayComponent } from "@checkd-ui"
import { DisabledOverlayRef } from "../checkd-disabled-overlay/checkd-disabled-overlay-ref"
import { DISABLED_OVERLAY_DIALOG_DATA } from "../checkd-disabled-overlay/checkd-disabled-overlay.tokens"

import { Project } from "@models/common"
import { Intercom } from "@supy-io/ngx-intercom"

interface DisabledOverlayConfigData {
  project: Project
}

interface DisabledOverlayConfig {
  panelClass?: string
  hasBackdrop?: boolean
  backdropClass?: string
  data?: DisabledOverlayConfigData
}

const DEFAULT_CONFIG: DisabledOverlayConfig = {
  hasBackdrop: true,
  backdropClass: "dark-backdrop",
  panelClass: "checkd-disabled-dialog-panel",
}

@Injectable({
  providedIn: "root",
})
export class DisabledOverlayService {
  constructor(private overlay: Overlay, private injector: Injector, private intercom: Intercom) {}

  open(config: DisabledOverlayConfig = {}) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...config }
    const overlayRef = this.createOverlay(dialogConfig)
    const dialogRef = new DisabledOverlayRef(overlayRef, this.intercom)
    const overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef)
    // overlayRef.backdropClick().subscribe(_ => dialogRef.close());

    return dialogRef
  }

  openProjectLockedOverlay(project: Project) {
    return this.open({ data: { project: project } })
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: DisabledOverlayConfig, dialogRef: DisabledOverlayRef) {
    const injector = this.createInjector(config, dialogRef)

    const containerPortal = new ComponentPortal(CheckdDisabledOverlayComponent, null, injector)
    const containerRef: ComponentRef<CheckdDisabledOverlayComponent> = overlayRef.attach(containerPortal)

    return containerRef.instance
  }

  private createOverlay(config: DisabledOverlayConfig) {
    const overlayConfig = this.getOverlayConfig(config)
    return this.overlay.create(overlayConfig)
  }

  private createInjector(config: DisabledOverlayConfig, dialogRef: DisabledOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap()

    injectionTokens.set(DisabledOverlayRef, dialogRef)
    injectionTokens.set(DISABLED_OVERLAY_DIALOG_DATA, config.data)

    return new PortalInjector(this.injector, injectionTokens)
  }

  private getOverlayConfig(config: DisabledOverlayConfig): OverlayConfig {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      positionStrategy,
    })

    return overlayConfig
  }
}
