import { Injectable } from "@angular/core"
import { COLLECTIONS, LegacyTemplate, Person, Project, Report, ReportType } from "@models/common"
import { GeneralReport } from "@models/common/general-report"
import { ModelService, ProjectService, ReportService, UserService } from "@services"
import { PermissionsService } from "@services/permissions.service"
import { orderByCreatedAtDesc } from "@services/utilities"
import { combineLatest, from as observableFrom, Observable } from "rxjs"
import { combineLatestWith, map, switchMap } from "rxjs/operators"
import { ProjectTemplateService } from "../services/project-template.service"

@Injectable({
  providedIn: "root",
})
export class ProjectViewService {
  constructor(
    private modelService: ModelService,
    private projectService: ProjectService,
    private projectTemplateService: ProjectTemplateService,
    private userService: UserService,
    private reportService: ReportService,
    private permissionsService: PermissionsService
  ) {}

  listenToAllProjectReports(project: Project): Observable<GeneralReport[]> {
    return combineLatest([this.listenToProjectReports(project), this.listenToProjectLegacyReports(project)]).pipe(
      map((it) => orderByCreatedAtDesc([...it[0], ...it[1]]))
    )
  }

  listenToAllProjectReportsForUser(project: Project, user: Person): Observable<GeneralReport[]> {
    return combineLatest([this.listenToProjectReportsForUser(project, user), this.listenToProjectLegacyReportsForUser(project, user)]).pipe(
      map((it) => orderByCreatedAtDesc([...it[0], ...it[1]]))
    )
  }

  listenToProjectReports(project: Project): Observable<Report[]> {
    return this.modelService.queryAndListen({
      collection: COLLECTIONS.REPORTS,
      aggregateData: { projectUid: project.uid },
      orderBy: { createdAt: "desc" },
    })
  }

  listenToProjectReportsForUser(project: Project, user: Person): Observable<Report[]> {
    return this.modelService.queryAndListen({
      collection: COLLECTIONS.REPORTS,
      aggregateData: { projectUid: project.uid, creatorUid: user.uid },
      orderBy: { createdAt: "desc" },
    })
  }

  listenToProjectLegacyReports(project: Project): Observable<Report[]> {
    return this.modelService.queryAndListen({
      collection: COLLECTIONS.LEGACY_REPORTS,
      aggregateData: { projectUid: project.uid },
      orderBy: { createdAt: "desc" },
    })
  }

  listenToProjectLegacyReportsForUser(project: Project, user: Person): Observable<Report[]> {
    return this.modelService.queryAndListen({
      collection: COLLECTIONS.LEGACY_REPORTS,
      aggregateData: { projectUid: project.uid, creatorUid: user.uid },
      orderBy: { createdAt: "desc" },
    })
  }

  listenToProjectTemplates(project: Project): Observable<LegacyTemplate[]> {
    return this.projectTemplateService.canHaveProjectTemplates(project)
      ? this.projectTemplateService.listenToProjectTemplates(project)
      : observableFrom([])
  }

  readonly allProjectReports$: Observable<GeneralReport[]> = this.projectService.currentProject$.pipe(
    combineLatestWith(this.projectService.currentUserProjectRole$, this.userService.currentUser$),
    switchMap(([project, role, currentUser]) =>
      this.listenToAllProjectReports(project).pipe(
        map((reports) =>
          reports.filter((report) => this.reportService.isCreator(report, currentUser) || this.permissionsService.canRead(role, report))
        )
      )
    )
  )

  // aka My Reports
  readonly projectUserReports$: Observable<GeneralReport[]> = this.projectService.currentProject$.pipe(
    combineLatestWith(this.userService.currentUser$),
    switchMap(([project, user]) => this.listenToAllProjectReportsForUser(project, user))
  )

  readonly filteredAndSortedProjectAndProjectUserReports$ = this.allProjectReports$.pipe(
    combineLatestWith(this.projectUserReports$),
    map(([projectReports, projectUserReports]) => [...projectReports, ...projectUserReports]),
    map((reports) => reports.filter(ProjectViewService.filterBlackListedReportTypes)),
    map((reports) => ProjectViewService.removeDuplicateReports(reports)),
    map((reports) => [...reports].sort((reportA, reportB) => (reportB.createdAt || 0) - (reportA.createdAt || 0)))
  )

  private static filterBlackListedReportTypes(report: GeneralReport): boolean {
    // We have some inconsistencies wrt the type of the reportType attribute, so let's include both versions
    const reportTypeBlacklist = ["FIELD", "NONE", ReportType.FIELD, ReportType.NONE]

    return !reportTypeBlacklist.includes(report.reportType) && !report.readOnly
  }

  private static removeDuplicateReports(reports: GeneralReport[]) {
    const reduced = reports
      .reduce((m, report) => {
        return m.has(report.uid) ? m : m.set(report.uid, report)
      }, new Map<string, GeneralReport>())
      .values()

    return Array.from(reduced)
  }
}
