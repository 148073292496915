import { Component, OnInit } from "@angular/core"
import { MatDialogRef } from "@angular/material/dialog"

@Component({
  selector: "app-add-option-dialog",
  templateUrl: "./add-option-dialog.component.html",
  styleUrls: ["./add-option-dialog.component.scss"],
})
export class AddOptionDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AddOptionDialogComponent>) {}

  ngOnInit() {}
}
