import { Relation } from "./relation"
import { ModelInCollection } from "./collections"
import { RelationData } from "./relation.interface"

export class RelationCollection {
  constructor(public relations: Relation[], private comparisonPredicate?: (relA: Relation, relB: Relation) => boolean) {
    if (comparisonPredicate == null) this.comparisonPredicate = Relation.isEqual
  }

  get length() {
    return this.relations != null ? this.relations.length : 0
  }

  public distinct() {
    return new RelationCollection(RelationCollection.distinct(this.relations, this.comparisonPredicate), this.comparisonPredicate)
  }

  public union(relCol: RelationCollection): RelationCollection {
    return new RelationCollection(
      RelationCollection.union(this.relations, relCol.relations, this.comparisonPredicate),
      this.comparisonPredicate
    )
  }

  public static get<M extends ModelInCollection>(source: M, collectionName: string): Promise<RelationCollection> {
    return source
      .collection(collectionName)
      .where("disabled", "==", false)
      .get()
      .then((qSnaps) => qSnaps.docs.map((snap) => new Relation(snap.data() as RelationData, snap.id, snap.ref)))
      .then((relations) => new RelationCollection(relations))
  }

  static distinct(relations: Relation[], predicate?: (relA: Relation, relB: Relation) => boolean): Relation[] {
    return RelationCollection.addRelations([], relations, predicate)
  }

  static union(relationsA: Relation[], relationsB: Relation[], predicate?: (relA: Relation, relB: Relation) => boolean): Relation[] {
    return RelationCollection.distinct([...relationsA, ...relationsB], predicate)
  }

  /**
   * Returns the index of the position of the relation in the
   * relations if a match is found (according to the predicate), -1
   * otherwise.
   * If no predicate is supplied, Relation.isEqual is used as default.
   *
   * @param relations An array of relations
   * @param relation The relation to match
   * @param predicate A function taking two relations as arguments and returning a boolean
   */
  static indexOf(relations: Relation[], relation: Relation, predicate: (relA: Relation, relB: Relation) => boolean = Relation.isEqual) {
    let i = -1
    relations.forEach((rel, index) => {
      if (predicate(rel, relation)) i = index
    })
    return i
  }

  /**
   * Adds a relation to an array of relations.
   * If an equal  relation exists, the labels are merged.
   * Relation equality is defined by the predicate argument.
   * If none are supplied, the default predicate for RelationCollection.indexOf is used.
   *
   * @param relations An array of relations
   * @param relation A relation to be added to the relations array
   * @param predicate A function taking two relations as arguments and returning a boolean
   */
  static addRelation(relations: Relation[], relation: Relation, predicate?: (relA: Relation, relB: Relation) => boolean) {
    let index = RelationCollection.indexOf(relations, relation)
    if (index < 0) return [...relations, relation]

    relations[index] = new Relation(Relation.mergeData(relations[index].data, relation.data), relation.uid, relation.ref)
    return relations
  }

  /**
   * Returns a copy of relationsA with all relations in relationB added.
   * Equal relations are merged.
   * Relation equality is defined by the predicate argument.
   * If none are supplied, the default predicate for Relation.indexOf is used.
   *
   * @param relationsA The array of relations to be added to
   * @param relationsB The array of relations to add
   * @param predicate A function taking two relations as arguments and returning a boolean
   */
  static addRelations(relationsA: Relation[], relationsB: Relation[], predicate?: (relA: Relation, relB: Relation) => boolean) {
    let relations = relationsA.slice(0) // Clone the first array
    relationsB.forEach((rel) => {
      relations = RelationCollection.addRelation(relations, rel)
    })
    return relations
  }

  /**
   * Sets the source properties for an array of relation to the
   * provided newSource model object.
   * The original DocumentReference for the relation is removed in
   * order to prevent accidental modification of the original
   * relation.
   *
   * @param relations An array of relations
   * @param newSource An object extending ModelInCollection
   */
  static setSources<M extends ModelInCollection>(relations: Relation[], newSource: M): Relation[] {
    return relations.map(
      (rel) =>
        new Relation(
          Object.assign({}, rel.data, {
            source: newSource.uid,
            sourcePath: newSource.ref!.path,
            sourceRef: newSource.ref,
          }),
          rel.uid,
          // @ts-ignore
          null
        )
    )
  }
}
