import firebase from "firebase/compat/app"
import Timestamp = firebase.firestore.Timestamp

export enum UserAgreementType {
  termsAndConditions = "termsAndConditions",
  privacyPolicy = "privacyPolicy",
}

export interface ICheckdAgreement {
  type: UserAgreementType
  version: number
  name: string
  url: string
}

export interface IUserAgreement extends ICheckdAgreement {
  acceptedDate: Timestamp
}

export type ICheckdAgreements = {
  [agreementType in UserAgreementType]: ICheckdAgreement
}

export type IUserAgreements = {
  [agreementType in UserAgreementType]: IUserAgreement
}

export interface IAgreementsStatus {
  notUpToDate: ICheckdAgreement[]
  notAgreedTo: ICheckdAgreement[]
}
