import { ReportStatus } from "./workflow.interface"
import { BaseModel } from "./base-model"
import { LegacyReportData } from "./legacy-report.interface"

export class LegacyReport extends BaseModel<LegacyReportData> {
  public static override COLLECTION: string = "legacyReports"

  // FIXME Uncommenting this causes typescript to barf
  //   a bunch of type inference errors all across the models.
  //   Figure out why and uncomment this, or add a workaround.
  /*
  protected readonly defaultPermissions: IDocumentPermissions = {
    roles: {
      [RoleType.NONE]: { create: false, read: false, update: false, delete: false },
    },
  }

  This one also causes issues
  get creatorName { return this.aggregateData.creatorName | '' }
   */

  // TODO reportName added for legacy support. Remove when appropriate.
  get name() {
    return this.data.name || this.data.reportName || ""
  }

  // TODO reportName added for legacy support. Switch to name attribute
  get reportName() {
    return this.data.name || this.data.reportName
  }

  get creatorUid() {
    return this.aggregateData["creatorUid"] || ""
  }
  get status() {
    return this.data.status || ReportStatus.OPEN
  }
  get description() {
    return this.data.description
  }
  get header() {
    return this.headerTemplateData
  }
  get details() {
    return this.detailTemplateData
  }
  get reportDataId() {
    return this.data.reportDataId
  }
  get number() {
    return this.data.number
  }
  get headerTemplateData() {
    return JSON.parse(this.data.headerTemplateData || "[]")
  }
  get detailTemplateData() {
    return JSON.parse(this.data.detailTemplateData || "[]")
  }
  get reportStatus() {
    return this.data.reportStatus
  }
  get notSynced() {
    return this.data.notSynced
  }
  get noOfElements() {
    return this.data.noOfElements
  }
  get noOfFilledElements() {
    return this.data.noOfFilledElements
  }
  get isArchived() {
    return this.data.isArchived
  }
  get reportId() {
    return this.data.reportId
  }
  get createdUserId() {
    return this.data.createdUserId
  }
  get modifiedUserId() {
    return this.data.modifiedUserId
  }
  get ownerUserId() {
    return this.data.ownerUserId
  }
  get isDeleted() {
    return this.data.isDeleted
  }
  get pdfFieldsSettings() {
    return this.data.reportGenerationData != null ? this.data.reportGenerationData.settings : null
  }
  get totalItems(): number {
    return 0
  }
  get urlPath() {
    return `legacyReports/${this.uid}`
  }
  get reportType() {
    return "LEGACY"
  }
  get logo() {
    return this.data.logo || ""
  }
  get templatePublicVersion() {
    return this.data.templatePublicVersion || ""
  }

  public static get(uid: string) {
    return LegacyReport.getModel(LegacyReport, uid)
  }

  public static create(data: LegacyReportData) {
    return LegacyReport.createModel(LegacyReport, data)
  }
}
