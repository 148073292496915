import { ComponentRef, Injectable, Injector } from "@angular/core"
import { Overlay, OverlayRef } from "@angular/cdk/overlay"
import { Intercom } from "@supy-io/ngx-intercom"
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal"
import { MISSING_FEATURE_DIALOG_DATA, MissingFeatureOverlayConfig, MissingFeatureOverlayRef } from "../feature-checker.interface"
import { MissingFeatureDialogComponent } from "../dialogs/missing-feature-dialog/missing-feature-dialog.component"
import { FEATURE_DESCRIPTIONS } from "../feature-description.interface"
import { Subscription } from "rxjs"
import { Router } from "@angular/router"

@Injectable({
  providedIn: "root",
})
export class MissingFeatureOverlayService {
  private defaultConfig: Partial<MissingFeatureOverlayConfig> = {
    hasBackdrop: true,
    backdropClass: "blue-backdrop",
  }

  public featureDescriptions = FEATURE_DESCRIPTIONS

  public backdropClickSubscription: Subscription

  constructor(private overlay: Overlay, private injector: Injector, private intercom: Intercom, private router: Router) {}

  open(config: Partial<MissingFeatureOverlayConfig> = {}) {
    const scrollStrategy = this.overlay.scrollStrategies.noop()

    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()

    const fullConfig = { ...this.defaultConfig, ...config, positionStrategy, scrollStrategy }
    // @ts-ignore
    fullConfig.data.featureDescription = this.featureDescriptions[fullConfig.data.feature]

    const overlayRef = this.overlay.create(fullConfig)
    const dialogRef = new MissingFeatureOverlayRef(overlayRef, this.intercom)
    this.attachDialogContainer(overlayRef, fullConfig, dialogRef)

    // @ts-ignore
    this.backdropClickSubscription = overlayRef.backdropClick().subscribe((_) => {
      this.backdropClickSubscription.unsubscribe()
      dialogRef.close()
      if (fullConfig.data && fullConfig.data.redirectUrl) {
        return this.router.navigate([fullConfig.data.redirectUrl])
      }
    })

    return dialogRef
  }

  private createInjector(config: Partial<MissingFeatureOverlayConfig>, dialogRef: MissingFeatureOverlayRef) {
    const injectionTokens = new WeakMap()

    injectionTokens.set(MissingFeatureOverlayRef, dialogRef)
    injectionTokens.set(MISSING_FEATURE_DIALOG_DATA, config.data)

    return new PortalInjector(this.injector, injectionTokens)
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: Partial<MissingFeatureOverlayConfig>, dialogRef: MissingFeatureOverlayRef) {
    const injector = this.createInjector(config, dialogRef)

    const containerPortal = new ComponentPortal(MissingFeatureDialogComponent, null, injector)
    const containerRef: ComponentRef<MissingFeatureDialogComponent> = overlayRef.attach(containerPortal)

    return containerRef.instance
  }
}
