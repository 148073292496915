import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-next-chat-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" viewBox="0 0 57 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1449_28560)">
        <path
          d="M56.25 16.7959V33.5918C56.25 36.6899 53.7231 39.1377 50.625 39.1377H47.8125V43.9049C47.8125 44.6078 47.0105 45.0141 46.4502 44.5968L39.1772 39.1371L28.0459 39.2959C25.0225 39.2959 22.5 36.8438 22.5 33.6709V30.8602L30.9375 30.86C35.5852 30.86 39.375 27.0701 39.375 22.4234V11.25H50.625C53.7275 11.25 56.25 13.7725 56.25 16.7959Z"
          fill="#FF772C"
        />
        <path
          opacity="0.4"
          d="M30.7898 -0.109668L5.4783 0.111222C2.38027 0.138258 -0.124434 2.68707 -0.0980879 5.706L0.0498627 22.6595C0.0768604 25.7531 2.62571 28.2622 5.72374 28.2352L8.53614 28.2115L8.57774 32.9794C8.58388 33.6823 9.3894 34.0815 9.94604 33.6596L17.1711 28.1366L31.0352 28.0055C34.1333 27.9894 36.638 25.4358 36.611 22.3386L36.463 5.38693C36.4367 2.368 33.8923 -0.136743 30.7898 -0.109668Z"
          fill="#EFB99B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1449_28560">
          <rect width="56.25" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class NextChatIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }
}
