import { Injectable, OnDestroy } from "@angular/core"
import { LegacyTemplate } from "@models/common"
import { UserService } from "@services"
import { naturalSortObjectsByProperty } from "@services/utilities"
import { BehaviorSubject, combineLatest, combineLatestWith, Observable, startWith, Subject, takeUntil } from "rxjs"
import { map, shareReplay, tap } from "rxjs/operators"
import { FormsLibraryConfigService } from "../edit-forms-template-dialog/services/forms-library-config.service"
import { PublicTemplatesViewService } from "./public-templates-view.service"

interface IAvailableTemplates {
  isLoading: boolean
  legacyTemplates: LegacyTemplate[] | null
}

interface ICompanyAndTagsFilters {
  viewCompanyTemplates: boolean
  creatorCompanyUids: string[]
  predefinedTags: string[]
}

@Injectable()
export class FormsLibraryViewService implements OnDestroy {
  private onDestroy$ = new Subject<void>()
  displayedComponent: "browse_all_templates" | "templates_showcase" = "browse_all_templates"

  private readonly _searchbarFilterText$ = new BehaviorSubject<string>("")
  private readonly _companyAndTagsFilters$ = new BehaviorSubject<ICompanyAndTagsFilters>({
    predefinedTags: [],
    creatorCompanyUids: [],
    viewCompanyTemplates: false,
  })

  private readonly allAvailableTemplates$ = combineLatest([
    this.publicTemplatesViewService.allPublicTemplatesWithCompanySubscribedStatus$,
    this.publicTemplatesViewService.allMemberCompaniesTemplates$,
  ]).pipe(
    map(([a, b]) => [...a, ...b]),
    map((templates) => naturalSortObjectsByProperty(templates, "name")),
    shareReplay({ bufferSize: 1, refCount: true }),
    takeUntil(this.onDestroy$)
  )

  public readonly publisherCompanies$: Observable<{ name: string; uid: string }[]> = this.allAvailableTemplates$.pipe(
    map((templates) => {
      // Removes duplicates
      const reduced = templates.reduce((m, template) => {
        const companyUid = template.aggregateData?.templateCreatorCompanyUid
        const companyName = template.aggregateData?.templateCreatorCompanyName

        if (!companyUid || !companyName) {
          return m
        }

        return m.has(companyUid) ? m : m.set(companyUid, companyName)
      }, new Map<string, string>())

      return Array.from(reduced, (entry: string[]) => ({ name: entry[1], uid: entry[0] }))
    })
  )

  public readonly availableTemplatesFiltered$: Observable<IAvailableTemplates> = combineLatest([
    this.allAvailableTemplates$,
    this._searchbarFilterText$,
  ]).pipe(
    map(([templates, searchText]) =>
      templates.filter((template) => {
        if (searchText.trim() === "") {
          return true
        }

        const searchFields = this.joinTemplateSearchFields(template)

        return searchFields.includes(searchText.trim().toLowerCase())
      })
    ),
    combineLatestWith(this._companyAndTagsFilters$, this.userService.currentCompany$),
    map(([templates, filters, currentCompany]) => {
      let filtered = templates.filter((template) => filters.predefinedTags.every((tag) => template.tags.includes(tag)))

      if (filters.viewCompanyTemplates) {
        filtered = filtered.filter((template) => template.aggregateData.templateCreatorCompanyUid === currentCompany.uid)
      }

      if (filters.creatorCompanyUids.length > 0) {
        filtered = filtered.filter((template) => filters.creatorCompanyUids.includes(template.aggregateData?.templateCreatorCompanyUid))
      }

      return filtered
    }),
    map((legacyTemplates: LegacyTemplate[]) => ({ isLoading: false, legacyTemplates })),
    startWith({ isLoading: true, legacyTemplates: null }),
    shareReplay({ bufferSize: 1, refCount: true }),
    takeUntil(this.onDestroy$)
  )

  private joinTemplateSearchFields(template: LegacyTemplate): string {
    return [template.name, template.aggregateData["templateCreatorCompanyName"] ?? "", template.tags.join("")].join("").toLowerCase()
  }

  constructor(
    private publicTemplatesViewService: PublicTemplatesViewService,
    private flConfigService: FormsLibraryConfigService,
    private userService: UserService
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next()
    this.onDestroy$.complete()
  }

  public setSearchbarText(text: string) {
    this._searchbarFilterText$.next(text)
  }

  public setCompanyAndTagsSearchFilter(filters: Partial<ICompanyAndTagsFilters>) {
    const defaultEmpty: ICompanyAndTagsFilters = {
      viewCompanyTemplates: false,
      creatorCompanyUids: [],
      predefinedTags: [],
    }

    this._companyAndTagsFilters$.next({ ...defaultEmpty, ...filters })
  }

  public getCurrentFilterData() {
    return this._companyAndTagsFilters$.value
  }

  public currentSearchbarText(): string {
    return this._searchbarFilterText$.value
  }

  toggleDisplayedComponent() {
    if (this.displayedComponent === "browse_all_templates") {
      this.displayedComponent = "templates_showcase"

      return
    }
    this.displayedComponent = "browse_all_templates"
  }
}
