<div fxLayout="row" fxLayoutAlign="end center">

  <button
    [disabled]="previousPageButtonDisabled"
    *ngIf="maxPageLength > 0"
    (click)="previousPage()"
    mat-icon-button
    [disableRipple]="true">
    <mat-icon>navigate_before</mat-icon>
  </button>

  <div
    *ngIf="maxPageLength > 0"
    style="display: inline-block; white-space: nowrap; font-size: 0.75em;"
    class="page-counter"
    class="currentPage">
    {{pageNumber}} / {{maxPageLength}}
  </div>

  <button
    [disabled]="nextPageButtonDisabled"
    *ngIf="maxPageLength > 0"
    (click)="nextPage()"
    mat-icon-button
    [disableRipple]="true">
    <mat-icon>navigate_next</mat-icon>

  </button>

  <mat-form-field *ngIf="maxPageLength > 0" style="width: 10em;">
    <span style="font-size: 0.75em;">Items per page:</span>
    <mat-select
      style="width: 3em; font-size: 0.75em;"
      [(value)]="pageSize">

      <mat-option *ngFor="let size of pageSizes" [value]="size">
        <span style="font-size: 0.75em;">{{size}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>
