import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-plus-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" [attr.viewBox]="getViewBox()" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2511_16756)" [attr.transform]="getScale()">
        <path
          d="M24 11.9994C24 12.7667 23.3827 13.384 22.6154 13.384H13.3846V22.6148C13.3846 23.3792 12.7644 24 12 24C11.2356 24 10.6154 23.3821 10.6154 22.6148V13.384H1.38462C0.620192 13.384 0 12.7644 0 12C0 11.2379 0.620192 10.6148 1.38462 10.6148H10.6154V1.38404C10.6154 0.619615 11.2356 0 12 0C12.7644 0 13.3846 0.619615 13.3846 1.38404V10.6148H22.6154C23.3827 10.6148 24 11.2379 24 11.9994Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2511_16756">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class PlusIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }

  getViewBox(): string | undefined {
    return this.svgIconsService.getViewBox(this.size)
  }
  getScale(): string | undefined {
    return this.svgIconsService.getScale(this.size)
  }
}
