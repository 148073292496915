<div
  *ngIf="{
    currentUser: currentUser$ | async,
    currentCompany: currentCompany$ | async,
    project: project$ | async,
    currentUserProjectRole: currentUserProjectRole$ | async,
    projectItems: projectItems$ | async,
    projectDrawings: this.projectService.currentProjectDrawings$ | async,
    projectCompany: projectCompany$ | async,
    projectCompanyTemplates: projectCompanyTemplates$ | async
  } as subs"
  class="project-view-container"
  fxLayout="column"
  [class.watermark]="(project$ | async)?.archived == true"
>
  <div class="project-view-top-bar" fxLayout="column" fxLayoutAlign="center center" fxFlex="0 0 auto">
    <div class="title-container" [ngClass.lt-sm]="'smaller-title-width'" fxLayout="row" fxLayoutAlign="center center">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3976_12560)">
          <path
            d="M21 5.25H18V3.75C18 2.09297 16.657 0.75 15 0.75H9C7.34531 0.75 6 2.09297 6 3.75V5.25H3C1.34297 5.25 0 6.59531 0 8.25V20.25C0 21.907 1.34297 23.25 3 23.25H21C22.657 23.25 24 21.907 24 20.25V8.25C24 6.59531 22.6547 5.25 21 5.25ZM9 3H15C15.4142 3 15.75 3.33577 15.75 3.75V5.25H8.25V3.75C8.25 3.33562 8.5875 3 9 3ZM3 7.5H21C21.4125 7.5 21.75 7.8375 21.75 8.25V12.75H2.25V8.25C2.25 7.8375 2.5875 7.5 3 7.5ZM21 21H3C2.5875 21 2.25 20.6625 2.25 20.25V15H9V15.75C9 16.5783 9.67172 17.25 10.5 17.25H13.5C14.3283 17.25 15 16.5783 15 15.75V15H21.75V20.25C21.75 20.6625 21.4125 21 21 21Z"
            fill="currentcolor"
          />
        </g>
        <defs>
          <clipPath id="clip0_3976_12560">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <h1 class="project-name min-w-0 overflow-hidden white-space-nowrap text-overflow-ellipsis">
        <span class="min-w-0">{{ subs.project?.name }}</span>
        <sup *ngIf="subs.project?.archived" class="sup-archived">Archived</sup>
      </h1>
    </div>
    <checkd-project-tabbar
      [currentCompany]="subs.currentCompany"
      [project]="subs.project"
      [projectReady]="projectReady"
      [currentTab]="selectedTab"
      (tabSelected)="setSelectedTab($event)"
    >
    </checkd-project-tabbar>
  </div>

  <ng-container *ngIf="isSelectedTabContent('items')">
    <checkd-project-items
      [isLoadingProjectItems]="isLoadingProjectItems$ | async"
      [project]="subs.project"
      [projectItems]="subs.projectItems"
      [currentUser]="subs.currentUser"
      [userProjectRole]="subs.currentUserProjectRole"
      [currentCompany]="subs.currentCompany"
      [projectMembers]="projectMembers"
      [userCompanyRole]="currentUserCompanyRole$ | async"
      [currentCompanyMembers]="currentCompanyMembers"
      [currentCompanyAssociates]="currentCompanyAssociates"
      [projectItemsReady]="projectItemsReady"
    >
    </checkd-project-items>
  </ng-container>

  <ng-container *ngIf="isSelectedTabContent('gallery')">
    <checkd-project-gallery
      [project]="subs.project"
      [projectItems]="subs.projectItems"
      [projectMembers]="projectMembers"
      [userProjectRole]="subs.currentUserProjectRole"
    >
    </checkd-project-gallery>
  </ng-container>

  <ng-container *ngIf="isSelectedTabContent('reports')">
    <checkd-project-reports
      [project]="subs.project"
      [currentUser]="subs.currentUser"
      [currentCompany]="subs.currentCompany"
      [projectTemplates]="projectTemplates"
      [currentCompanyLegacyTemplates]="currentCompanyLegacyTemplates"
      [projectCompany]="subs?.projectCompany"
      [reports]="projectReports$ | async"
      [projectOpenReports]="projectOpenReports$ | async"
      [userProjectRole]="subs.currentUserProjectRole"
      [projectReportsReady]="projectReportsReady"
    >
    </checkd-project-reports>
  </ng-container>

  <ng-container *ngIf="isSelectedTabContent('drawings') && projectDrawingsReady">
    <checkd-project-drawings
      fxFill
      [projectReady]="projectReady"
      [project]="subs.project"
      [projectDrawings]="subs.projectDrawings"
      [currentUser]="subs.currentUser"
      [currentUserProjectRole]="subs.currentUserProjectRole"
    >
    </checkd-project-drawings>
  </ng-container>

  <ng-container *ngIf="isSelectedTabContent('people') && projectPeopleReady">
    <checkd-project-people
      [project]="subs.project"
      [currentUser]="subs.currentUser"
      [currentUserProjectRole]="subs.currentUserProjectRole"
      [currentCompany]="subs.currentCompany"
      [currentCompanyMembers]="currentCompanyMembers"
      [currentCompanyAssociates]="currentCompanyAssociates"
      [projectMembers]="projectMembers"
      [projectEditorRoles]="projectEditorRoles$ | async"
      [projectOwnerRoles]="projectOwnerRoles$ | async"
      [pendingProjectInvitations]="pendingProjectInvitations$ | async"
      [projectMembersWithRoles]="projectMembersWithRoles$ | async"
    >
    </checkd-project-people>
  </ng-container>

  <ng-container *ngIf="isSelectedTabContent('info') && projectReady && subs.project">
    <checkd-project-settings
      fxFlex="1 1 auto"
      [project]="subs.project"
      [projectTemplates]="projectTemplates"
      [reportTagsCollection]="projectReportsService.reportTagCollection$ | async"
      [currentUser]="subs.currentUser"
      [projectCompany]="subs?.projectCompany"
      [currentCompany]="subs.currentCompany"
      [projectOwners]="projectOwners$ | async"
      [projectOwnersAndAdmins]="projectOwnersAndAdmins$ | async"
      [currentUserProjectRole]="subs.currentUserProjectRole"
      [companyTemplates]="currentCompanyLegacyTemplates"
      [projectCompanyTemplates]="subs?.projectCompanyTemplates"
    >
    </checkd-project-settings>
  </ng-container>
</div>
