<div class="textfield-screen" matTooltip="You can't edit this element! The report is locked" [matTooltipDisabled]="!readOnly">
  <mat-form-field class="full-width" *ngIf="getType() == 'number'" [hintLabel]="getHintLabel()">
    <mat-label>{{ title }}</mat-label>
    <input
      matInput
      #numberInput
      [maxlength]="getOption(MAX_LENGTH_OPT_CODE)?.value!"
      [placeholder]="title"
      [(ngModel)]="value"
      [disabled]="readOnly"
    />
    <mat-hint *ngIf="hasOption(MAX_LENGTH_OPT_CODE)" align="end">
      {{ numberInput.value?.length || 0 }}/{{ getOption(MAX_LENGTH_OPT_CODE)!.value! }}
    </mat-hint>
  </mat-form-field>

  <mat-form-field class="full-width" *ngIf="getType() == 'text'" [hintLabel]="getHintLabel()">
    <mat-label>{{ title }}</mat-label>
    <textarea
      class="text-area"
      matInput
      #textAreaInput
      [maxlength]="getOption(MAX_LENGTH_OPT_CODE)?.value!"
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="15"
      [placeholder]="title"
      [(ngModel)]="value"
      [disabled]="readOnly"
    >
    </textarea>
    <mat-hint *ngIf="hasOption(MAX_LENGTH_OPT_CODE)" align="end">
      {{ textAreaInput.value?.length || 0 }}/{{ getOption(MAX_LENGTH_OPT_CODE)!.value }}
    </mat-hint>
  </mat-form-field>
</div>
