import firebase from "firebase/compat/app"
import { BaseModel } from "./base-model"
import { ModelInCollection } from "./collections"
import { Person } from "./person"
import { Item } from "./item"
import { Relation } from "./relation"
import { TaskAggregateData } from "./aggregate-data"
import firestore = firebase.firestore
import { BaseModelData } from "./base-model.interface"

export interface TaskAction {
  type: string
  item: Item
  task?: Task
}

export interface TaskData extends BaseModelData {
  taskType?: string
  status: string
  number?: number
  assigner?: firestore.DocumentReference
  assignee?: firestore.DocumentReference
  collaborators?: firestore.DocumentReference[]
  dueDate?: any // TODO Change dueDate to proper type
  legacyDataFloorplan?: any
  preventNotifications?: boolean
  aggregateData?: TaskAggregateData
  bulkUid?: string
}

export class Task extends BaseModel<TaskData> {
  public static override COLLECTION: string = "tasks"

  get status() {
    return this.data.status != null ? this.data.status : ""
  }
  get number() {
    return this.data.number
  }
  get dueDate() {
    return this.data.dueDate
  }
  get isAssigned() {
    return this.data.assignee != null
  }
  get assignerRef() {
    return this.data.assigner
  }
  get assigneeRef() {
    return this.data.assignee
  }
  get collaboratorRefs() {
    return this.data.collaborators || []
  }

  async getItems(): Promise<Item[]> {
    const itemRelations = await this.ref!.collection(Item.COLLECTION).get()
    const links = itemRelations.docs.map((doc) => doc.data()["link"])
    return Promise.all(links.map((link) => Item.get(link)))
  }

  getAssigner(): Promise<Person> {
    if (this.data.assigner != null) {
      return Person.get(this.data.assigner.id)
    }

    // @ts-ignore
    return Promise.resolve(null)
  }

  getAssignee(): Promise<Person> {
    if (this.data.assignee != null) {
      return Person.get(this.data.assignee.id)
    }

    // @ts-ignore
    return Promise.resolve(null)
  }

  async getAssignerName(): Promise<string> {
    const assigner = await this.getAssigner()
    return assigner != null ? assigner.name : ""
  }

  async getAssigneeName(): Promise<string> {
    const assignee = await this.getAssignee()
    return assignee != null ? assignee.name : ""
  }

  getJson() {
    let taskData = this.data as any
    if (taskData) {
      taskData.assignee = this.data.assignee != null ? this.data.assignee.path : null
      taskData.assigner = this.data.assigner != null ? this.data.assigner.path : null
      taskData.collaborators = (this.data.collaborators || []).map((collaborator) => collaborator.path)
      taskData.legacyDataFloorplan = {}
    }
    return taskData
  }

  public async assignTo<M extends ModelInCollection>(assignee: M): Promise<Task> {
    await this.add(assignee, [Relation.LABELS.ASSIGNED_TO])
    await assignee.add(this, [Relation.LABELS.IS_ASSIGNED])
    return await this.update({ assignee: assignee.ref })
  }

  public async unAssign(): Promise<Task> {
    if (!this.isAssigned) {
      return Promise.resolve(this)
    }

    const assignee = await this.getAssignee()
    const relations = await Promise.all([Relation.get(this, assignee), Relation.get(assignee, this)])
    const relations_1 = await Promise.all([
      relations[0].removeLabels([Relation.LABELS.ASSIGNED_TO]),
      relations[1].removeLabels([Relation.LABELS.IS_ASSIGNED]),
    ])
    if (relations_1[0].hasNoLabels) {
      Relation.delete(relations_1[0])
    }
    if (relations_1[1].hasNoLabels) {
      Relation.delete(relations_1[1])
    }
    return this.update({ assignee: null })
  }

  public static override doc(uid: string): firestore.DocumentReference {
    return Task.db.collection(Task.COLLECTION).doc(uid)
  }

  public static get(uid: string): Promise<Task> {
    return Task.doc(uid)
      .get()
      .then((snapshot) => {
        if (!snapshot.exists) throw `Task ${uid} not found`
        let data = snapshot.data()
        return new Task(snapshot.data() as TaskData, snapshot.id, snapshot.ref)
      })
  }

  setConcernsItem(item: Item) {
    // @ts-ignore
    return this.add(item, [Relation.LABELS.CONCERNS])
      .then((_) => item.add(this, [Relation.LABELS.CONCERNED_BY]))
      .then((_) => this)
  }

  public static async create(data: TaskData): Promise<Task> {
    const docRef = await Task.db.collection(Task.COLLECTION).add(data)
    const docSnapshot = await docRef.get()
    return new Task(docSnapshot.data() as TaskData, docSnapshot.id, docSnapshot.ref)
  }
}
