<div class="info" fxLayout="column" fxLayoutAlign="center center">
  <h1><span *ngIf="item?.number">{{'#' + item?.number}} </span>{{item?.name}}</h1>

  <span style="font-size: 75%;" fxLayout="row" fxLayoutGap="5px">
    <span>Status:</span>
    <span [ngClass]="statusClasses">{{status || 'OPEN'}}</span>
  </span>

  <span class="description">
    <textarea
      readonly
      matInput
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="15"
      [value]="item?.description || ''"
    ></textarea>
  </span>

  <span class="tags" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <button *ngIf="!item.archived" mat-icon-button (click)="areTagsEditable = !areTagsEditable"><mat-icon svgIcon="tag"></mat-icon></button>
    <checkd-tags
      [areTagsEditable]="areTagsEditable"
      [tags]="itemTags$ | async"
      [tagSet]="project?.tags"
      (onTagsUpdated)="onTagsUpdated($event)"
    ></checkd-tags>

  </span>
</div>
