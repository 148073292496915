import firebase from "firebase/compat/app"
import { ModelInCollection } from "./collections"
import { createNewModelInstance, createNewModelInstanceFrom } from "./utilities"
import { DB } from "./constants"
import { Relation } from "./relation"
import { RelationData } from "./relation.interface"
import { PermissionsHandler, IDocumentPermissions } from "./roles-and-permissions"
import { BaseModelData } from "./base-model.interface"
import firestore = firebase.firestore

export class BaseModel<D extends BaseModelData> extends ModelInCollection {
  public static get db() {
    return DB
  }
  protected get db() {
    return BaseModel.db
  }

  get data() {
    return this._data || ({} as D)
  }
  set data(data: D) {
    this._data = data
  }

  get disabled(): boolean {
    if (this.data == null) {
      return true
    }

    return this.data.disabled ? this.data.disabled : false
  }

  get archived(): boolean {
    if (this.data == null) {
      return true
    }

    return this.data.archived ? this.data.archived : false
  }

  get lockedByCheckd(): boolean {
    if (this.data == null) {
      return true
    }

    return this.data.lockedByCheckd ? this.data.lockedByCheckd : false
  }

  get readOnly(): boolean {
    return this.data.readOnly || false
  }
  get createdAt(): number {
    return this.data.createdAt || 0
  }
  get updatedAt(): number {
    return this.data.updatedAt || 0
  }
  get aggregateData(): { [key: string]: any } {
    return this.data.aggregateData || {}
  }

  get tags(): string[] {
    return this.data.tags == null ? [] : this.data.tags
  }

  get collectionName(): string {
    return (<typeof BaseModel>this.constructor).COLLECTION
  }

  get permissions(): IDocumentPermissions {
    return PermissionsHandler.mergePermissions(this.defaultPermissions, this.data.permissions || { roles: {} })
  }

  set readOnly(value: boolean) {
    this.data.readOnly = value
  }
  public static override COLLECTION: string = "none"
  protected static override duplicationAttributeBlacklist: string[] = [
    "legacyDataForms",
    "legacyDataFloorplan",
    "uid",
    "createdAt",
    "updatedAt",
  ]

  protected readonly defaultPermissions: IDocumentPermissions = {
    roles: {},
  }

  constructor(private _data: D, public override uid: string, public override ref?: firestore.DocumentReference) {
    super()
  }

  public async update(data?: any) {
    if (data != null) {
      await this.ref!.update(data)
      this.data = { ...this.data, ...data }
    }

    return this
  }

  public batchUpdate(batch: firestore.WriteBatch, data?: any) {
    if (data != null) {
      batch.update(this.ref!, data)
      this.data = { ...this.data, ...data }
    }

    return this
  }

  public batchUpdateAggregateData(batch: firestore.WriteBatch, data: { [key: string]: any }) {
    const currentAggregateData = this.data.aggregateData
    const newAggregateData = { ...currentAggregateData, ...data }
    batch.update(this.ref!, { aggregateData: newAggregateData })
    this.data.aggregateData = newAggregateData

    return this
  }

  public refresh() {
    return this.ref!.get().then((docSnap) => {
      this.data = docSnap.data() as D

      return this
    })
  }

  public duplicateData(attributeBlacklist: string[] = []) {
    return BaseModel.duplicateModelData(this, attributeBlacklist)
  }

  public batchDuplicate(batch: firestore.WriteBatch, attributeBlacklist: string[] = [], data: any = {}) {
    const duplicatedData = this.duplicateData(attributeBlacklist)
    const mergedData = {
      ...duplicatedData,
      ...data,
    }

    const collection = this.ref!.parent

    const docRef = collection.doc()

    batch.set(docRef, mergedData, { merge: true })

    return createNewModelInstance(collection.id, mergedData, docRef.id, docRef)
  }

  public duplicate(attributeBlacklist: string[] = [], data: any = {}) {
    const duplicatedData: any = this.duplicateData(attributeBlacklist)
    const mergedData: any = { ...duplicatedData, ...data }

    return this.ref!.parent.add(mergedData).then((docRef) => {
      return createNewModelInstance(this.ref!.parent.id, mergedData, docRef.id, docRef)
    })
  }

  public collection(collection: string): firestore.CollectionReference {
    return this.ref!.collection(collection)
  }

  public disable() {
    return this.ref!.update({ disabled: true })
  }

  public batchDisable(batch: firestore.WriteBatch) {
    return batch.update(this.ref!, { disabled: true })
  }

  public archive() {
    return this.ref!.update({ archived: true })
  }

  public batchArchive(batch: firestore.WriteBatch) {
    return batch.update(this.ref!, { archived: true })
  }

  public async getRelations(targetCollectionName: string, withLabels: string[] = []) {
    const relQ = await this.collection(targetCollectionName).where("disabled", "==", false).get()

    const snaps = withLabels
      ? relQ.docs.filter((doc) => {
          const relationData = doc.data() as RelationData
          for (const label of withLabels) {
            if (!relationData.labels.includes(label)) {
              return false
            }
          }

          return true
        })
      : relQ.docs

    return snaps.map((snap) => new Relation(snap.data() as RelationData, snap.id, snap.ref))
  }

  public async getTargets(targetCollectionName: string, withLabels: string[] = []) {
    const relations = await this.getRelations(targetCollectionName, withLabels)
    const docs = await Promise.all(relations.map((relation) => this.db.doc(relation.targetPath!).get()))

    return docs.map((snap) => createNewModelInstance(targetCollectionName, snap.data(), snap.id, snap.ref))
  }

  public add<M extends ModelInCollection>(target: M, labels: string[]): Promise<Relation> {
    const relationData: RelationData = {
      source: this.uid,
      link: target.uid!,
      sourcePath: this.ref!.path,
      sourceRef: this.ref,
      targetPath: target.ref!.path,
      targetRef: target.ref,
      labels,
      disabled: false,
      archived: false,
    }

    return Relation.addOrUpdate(this, target, relationData)
  }

  public batchAdd<M extends ModelInCollection>(batch: firestore.WriteBatch, target: M, labels: string[]): Relation {
    const relationData: RelationData = {
      source: this.uid,
      link: target.uid!,
      sourcePath: this.ref!.path,
      sourceRef: this.ref,
      targetPath: target.ref!.path,
      targetRef: target.ref,
      labels,
      disabled: false,
      archived: false,
    }

    return Relation.batchAddOrUpdate(batch, this, target, relationData)
  }

  public static doc(uid: string): firestore.DocumentReference {
    return BaseModel.db.collection(BaseModel.COLLECTION).doc(uid)
  }

  public static async getModel<M extends ModelInCollection, D extends BaseModelData>(
    model: { COLLECTION: string; new (data: D, uid: string, ref?: firestore.DocumentReference): M },
    uid: string
  ): Promise<M> {
    const docSnap = await BaseModel.db.collection(model.COLLECTION).doc(uid).get()
    if (!docSnap.exists) {
      throw new Error(`Model in ${model.COLLECTION} not found`)
    }

    return new model(docSnap.data() as D, docSnap.id, docSnap.ref) as M
  }

  public static addDefaultData(data: any) {
    return {
      disabled: false,
      ...data,
    }
  }

  public static async createModel<M extends ModelInCollection, D extends BaseModelData>(
    model: { COLLECTION: string; new (data: D, uid: string, ref?: firestore.DocumentReference): M },
    data: D
  ): Promise<M> {
    const fullData = BaseModel.addDefaultData(data)
    const docRef = await BaseModel.db.collection(model.COLLECTION).add(fullData)

    return new model(fullData as D, docRef.id, docRef)
  }

  public static batchCreate<M extends ModelInCollection, D extends BaseModelData>(
    batch: firestore.WriteBatch,
    model: { COLLECTION: string; new (data: D, uid: string, ref?: firestore.DocumentReference): M },
    data: D
  ): M {
    const fullData = BaseModel.addDefaultData(data)
    const docRef = BaseModel.db.collection(model.COLLECTION).doc()
    batch.set(docRef, fullData, { merge: true })

    return new model(fullData, docRef.id, docRef)
  }

  public static duplicateModel<M extends ModelInCollection>(model: M, attributeBlacklist: string[] = []) {
    return BaseModel.db
      .collection(model.collectionName)
      .add(BaseModel.duplicateModelData(model, attributeBlacklist))
      .then((docRef) => docRef.get())
      .then((docSnap) => {
        const duplicateModel = createNewModelInstanceFrom(model, docSnap.data())
        duplicateModel.uid = docSnap.id
        duplicateModel.ref = docSnap.ref

        return duplicateModel
      })
  }

  public static duplicateModelData<M extends ModelInCollection, D extends BaseModelData>(model: M, attributeBlacklist: string[] = []): D {
    const filteredData = { ...model.data }
    const blacklist = [...BaseModel.duplicationAttributeBlacklist, ...attributeBlacklist]
    blacklist.forEach((attributeName) => delete filteredData[attributeName])

    return filteredData
  }

  /**
   * Returns an array containing only one instance of each of the
   * models in the supplied array as defined by their UID's. Order is
   * preserved.
   *
   * NB: This is a somewhat expensive operation, so use it sparingly,
   * preferably on fairly short arrays.
   *
   * @param models an array of models
   */
  public static unique<M extends ModelInCollection>(models: M[]): M[] {
    const ids: string[] = []
    const uniqueModels: M[] = []

    models.forEach((model) => {
      if (ids.includes(model.uid!)) {
        return
      }
      uniqueModels.push(model)
      ids.push(model.uid!)
    })

    return uniqueModels
  }
}
