import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { Company, LegacyTemplate, Project } from "@models/common"
import { ProjectReportsService } from "../../project/project-reports/project-reports.service"
import { MatButtonToggleChange } from "@angular/material/button-toggle"

type TabChoices = "project" | "company" | "neither"

@Component({
  selector: "legacy-template-list",
  templateUrl: "./legacy-template-list.component.html",
  styleUrls: ["./legacy-template-list.component.scss"],
})
export class LegacyTemplateListComponent implements OnInit {
  @Input() projectTemplates: LegacyTemplate[] = []
  @Input() currentCompanyTemplates: LegacyTemplate[] = []

  @Input() userCompany: Company
  @Input() projectCompany: Company
  @Input() project: Project
  @Output() onTemplateSelected = new EventEmitter<LegacyTemplate>()

  filterString: string = ""

  _currentTemplates: LegacyTemplate[]
  _currentTab: TabChoices = "company"

  constructor(private projectReportsService: ProjectReportsService) {}

  get isProjectTemplatesAvailable(): boolean {
    return (
      this.project &&
      this.projectTemplates &&
      this.projectTemplates.length > 0 &&
      this.projectReportsService.hasPermissionsToSeeProjectTemplates(this.project, this.userCompany, this.projectCompany)
    )
  }

  get isCompanyTemplatesAvailable(): boolean {
    return (
      this.userCompany &&
      this.currentCompanyTemplates &&
      this.currentCompanyTemplates.length > 0 &&
      this.projectReportsService.hasPermissionsToSeeCompanyTemplates(this.userCompany)
    )
  }

  ngOnInit() {
    if (!this.isCompanyTemplatesAvailable && !this.isCompanyTemplatesAvailable) {
      this._currentTab = "neither"
      this._currentTemplates = []
    } else {
      this._currentTab = this.isProjectTemplatesAvailable ? "project" : "company"
      this._currentTemplates = this.isProjectTemplatesAvailable ? this.projectTemplates : this.currentCompanyTemplates
    }
  }

  filterArray() {
    this.assignCopy(this._currentTab)

    this._currentTemplates = this._currentTemplates.filter((item) => {
      return item.name.toLocaleLowerCase().indexOf(this.filterString.toLocaleLowerCase()) > -1
    })
  }

  assignCopy(templateType: TabChoices) {
    switch (templateType) {
      case "project":
        this._currentTemplates = this.projectTemplates || []
        break
      case "company":
        this._currentTemplates = this.currentCompanyTemplates || []
        break
      case "neither":
        this._currentTemplates = []
        break
      default:
        const assertUnreachable: never = templateType
    }
  }

  tabButtonValueChanged(event: MatButtonToggleChange) {
    this.filterArray()
  }

  selectTemplate(template: LegacyTemplate) {
    this.onTemplateSelected.emit(template)
  }
}
