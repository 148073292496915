import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-briefcase-project-solid-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" [attr.viewBox]="getViewBox()" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2511_17598)" [attr.transform]="getScale()">
        <path
          d="M8.625 3H15.375C15.5813 3 15.75 3.16875 15.75 3.375V5.25H8.25V3.375C8.25 3.16875 8.41875 3 8.625 3ZM6 3.375V5.25H3C1.34531 5.25 0 6.59531 0 8.25V12.75H9H15H24V8.25C24 6.59531 22.6547 5.25 21 5.25H18V3.375C18 1.92656 16.8234 0.75 15.375 0.75H8.625C7.17656 0.75 6 1.92656 6 3.375ZM24 14.25H15V15.75C15 16.5797 14.3297 17.25 13.5 17.25H10.5C9.67031 17.25 9 16.5797 9 15.75V14.25H0V20.25C0 21.9047 1.34531 23.25 3 23.25H21C22.6547 23.25 24 21.9047 24 20.25V14.25Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2511_17598">
          <rect [attr.width]="getSize()" [attr.height]="getSize()" fill="white" />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class BriefcaseProjectSolidIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }

  getViewBox(): string | undefined {
    return this.svgIconsService.getViewBox(this.size)
  }
  getScale(): string | undefined {
    return this.svgIconsService.getScale(this.size)
  }
}
