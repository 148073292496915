import { Injectable } from "@angular/core"
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/compat/firestore"
import { createNewModelInstance } from "@models/common/utilities"
import { map, tap } from "rxjs/operators"
import firebase from "firebase/compat/app"
import firestore = firebase.firestore

export interface IModelQuery {
  collection: string
  modelData?: any
  aggregateData?: any
  orderBy?: { [key: string]: "asc" | "desc" }
  limit?: number
  startAt?: number
}

@Injectable()
export class ModelService {
  constructor(private db: AngularFirestore) {}

  public listenTo(collectionName: string, uid: string) {
    return this.db
      .collection(collectionName)
      .doc(uid)
      .snapshotChanges()
      .pipe(
        map((docSnapshot) =>
          createNewModelInstance(collectionName, docSnapshot.payload.data(), docSnapshot.payload.id, docSnapshot.payload.ref)
        )
      )
  }

  public queryAndListen(modelQuery: IModelQuery) {
    if (modelQuery.collection == null) {
      throw new Error("Cannot query without specifying a collection")
    }

    return this.db
      .collection(modelQuery.collection, (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref

        for (const k of Object.keys(modelQuery.modelData || {})) {
          query = query.where(k, "==", modelQuery.modelData[k])
        }

        for (const k of Object.keys(modelQuery.aggregateData || {})) {
          query = query.where(`aggregateData.${k}`, "==", modelQuery.aggregateData[k])
        }

        for (const k of Object.keys(modelQuery.orderBy || {})) {
          // @ts-ignore
          query = query.orderBy(k, modelQuery.orderBy[k])
        }

        if (modelQuery.limit != null) {
          query = query.limit(modelQuery.limit)
        }

        if (modelQuery.startAt != null) {
          query = query.startAt(modelQuery.startAt)
        }

        return query
      })
      .snapshotChanges()
      .pipe(
        map((docs) =>
          docs
            .map((doc) => createNewModelInstance(modelQuery.collection, doc.payload.doc.data(), doc.payload.doc.id, doc.payload.doc.ref))
            .filter((m) => !m.disabled)
        )
      )
  }
}
