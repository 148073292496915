export enum Style {
  header = "header",
  blueHeaderText = "blueHeaderText",
  blackHeaderTextGrayBackground = "blackHeaderTextGrayBackground",
}

export const FontSize = {
  h1: 18,
  h2: 14,
  default: 8,
}

export const styles = {
  styles: {
    [Style.header]: {
      margin: [0, 0, 0, 10],
      alignment: "center",
    },
    [Style.blueHeaderText]: {
      color: "white",
      alignment: "center",
      fillColor: "#164790",
    },
    [Style.blackHeaderTextGrayBackground]: {
      color: "white",
      fillColor: "#aaaaaa",
    },
  },
  defaultStyle: {
    fontSize: FontSize.default,
    columnGap: 10,
  },
}
