import { Component, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"

// TODO use this when we want an actual form ID from the route param
import { ActivatedRoute, Router } from "@angular/router"
import { pluck } from "rxjs/operators"

import { FormsUser } from "../../models/forms"
import { Form } from "../models/form"

import { ProjectService } from "../../services/project.service"
import { UserService } from "../../services/user.service"

import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import { FilestackService } from "../../services/filestack.service"
import { FormMessageService } from "../services/form-message.service"

import { LegacyReport } from "@models/common"

import { ConfirmDialogComponent, ProgressDialogComponent } from "../../dialogs"

import { ExportService } from "../../services/export.service"
import { SnackbarService } from "../../services/snackbar.service"

@Component({
  selector: "app-form-view",
  templateUrl: "./form-view.component.html",
  styleUrls: ["./form-view.component.scss"],
})
export class FormViewComponent implements OnInit, OnDestroy {
  form: Form
  projectId: number
  projectTitle: string

  isFormChanged: boolean = false

  // Handle upload with filestack for everything insisde these arrays
  signatures = {}
  images: any

  readonly subscriptions: Subscription = new Subscription()

  constructor(
    private userService: UserService,
    private formMessageService: FormMessageService,
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private fileStackService: FilestackService,
    private exportService: ExportService,
    private dialogRef: MatDialog,
    private snackBar: SnackbarService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.route.params.pipe(pluck("reportId")).subscribe((reportId) => {
        LegacyReport.get(reportId).then((legacyReport) => {
          this.form = Form.fromJson(legacyReport)
        })
      })
    )

    this.subscriptions.add(this.formMessageService.getMessage().subscribe((msg) => this.handleMessage(msg.name, msg.data)))
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  getSaveButtonToolTip() {
    const tooltipMessage = "There are no changes to save!"

    return !this.isFormChanged ? tooltipMessage : ""
  }

  handleMessage(name: any, data: any) {
    switch (name) {
      case FormMessageService.MSG_ELEMENT_VALUE_CHANGED: {
        this.handleElementValueChanged(data)
        break
      }

      case FormMessageService.MSG_ELEMENT_CHILD_ADDED: {
        this.handleElementChildAdded(data)
        break
      }

      case FormMessageService.MSG_ELEMENT_CHILD_REMOVED: {
        this.handleElementChildRemoved(data)
        break
      }
      case FormMessageService.SIGNATURE_ADDED: {
        if (data.signatureImage.length > 0) {
          // @ts-ignore
          this.signatures[data.element.id] = {
            signatureImage: data.signatureImage,
            element: data.element,
          }
          this.handleElementValueChanged(data)
        } else {
          // Remove signature from upload array because user removed it
          // @ts-ignore
          this.signatures.hasOwnProperty(data.element.id) ? delete this.signatures[data.element.id] : ""
        }
        break
      }
      case FormMessageService.IMAGE_ADDED: {
        this.handleElementValueChanged(data)
        break
      }
      default: {
        break
      }
    }
  }

  handleElementValueChanged(element: any) {
    this.form.updateFilledElementCount()
    this.isFormChanged = true
  }

  handleElementChildAdded(element: any) {
    this.form.updateFilledElementCount()
    this.isFormChanged = true
  }

  handleElementChildRemoved(data: any) {
    this.form.updateFilledElementCount()
    this.isFormChanged = true
  }

  getFormDetails(user: FormsUser, projectId: number, reportId: number) {}

  goToReportsList() {
    if (this.isFormChanged) this.showDiscardChangesDialog()
    else this.router.navigate([this.router.url.substring(0, this.router.url.lastIndexOf("/"))])
  }

  async saveForm() {}

  showSnackBarActionToDownloadPdf() {
    const snackRef = this.snackBar.showMessageWithAction(
      SnackbarService.FORM_SAVED,
      SnackbarService.ACTION_BUTTON_DOWNLOAD,
      10000 // 10 seconds
    )

    snackRef.onAction().subscribe(() => this.generatePdf())
  }

  showDiscardChangesDialog() {
    const confirmDialog = this.dialogRef.open(ConfirmDialogComponent, {
      data: { title: "Unsaved Changes", content: "Are you sure you want to discard changes on this form?" },
    })
    confirmDialog.afterClosed().subscribe((result) => {
      if (result) this.router.navigate([this.router.url.substring(0, this.router.url.lastIndexOf("/"))])
    })
  }

  async uploadSignatures(saveProgress: MatDialogRef<ProgressDialogComponent>) {
    const signatures = Object.keys(this.signatures)
    if (signatures.length > 0) {
      saveProgress.componentInstance.resetValue()
      saveProgress.componentInstance.description = "Uploading Signatures..."
    }
    const progressIncrementOffset = 100 / signatures.length
    let progressCount = 1
    for (const key of Object.keys(this.signatures)) {
      // @ts-ignore
      await this.fileStackService.uploadSignature(this.signatures[key].signatureImage).then((result: { url: string }) => {
        //TODO add catch for failed upload request
        // @ts-ignore
        this.signatures[key].element.value = result.url
        saveProgress.componentInstance.value = progressCount * progressIncrementOffset
        progressCount++
      })
    }
  }

  previewPdf() {}

  generatePdf() {}
}
