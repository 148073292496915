export interface ImageAggregateData {
  projectUid?: string
  drawingUid?: string
  itemUid?: string
  itemName?: string
  itemNumber?: number
  itemStatus?: string
}

export interface RelationAggregateData {
  sourceCreatedAt?: number
  sourceUpdatedAt?: number
  targetCreatedAt?: number
  targetUpdatedAt?: number
  companyUid?: string
  companyName?: string
  legacyTemplateUid?: string
  legacyTemplateName?: string
}

export interface TaskAggregateData {
  taskAssignerUid?: string
  taskAssignerName?: string
  taskAssignerCompanyName?: string
  taskAssigneeUid?: string
  taskAssigneeName?: string
  taskAssigneeCompanyName?: string
}

export interface ItemAggregateData {
  legacyReportUid?: string
  legacyReportName?: string
  itemTags?: string[]
  itemCreatorUid?: string
  itemCreatorName?: string
  itemCreatorCompanyName?: string
  taskAssignerUid?: string
  taskAssignerName?: string
  taskAssignerCompanyName?: string
  taskAssigneeUid?: string
  taskAssigneeName?: string
  taskAssigneeCompanyName?: string
  taskCollaboratorUids?: string[]
  taskCollaboratorNames?: string[]
  drawingUid?: string
  drawingName?: string
  projectUid?: string
  projectName?: string
  latestMessage?: string
}

export interface DrawingAggregateData {
  totalAndActiveItemsPerUser?: any
  drawingCreatorUid?: string
  drawingCreatorName?: string
  drawingCreatorCompanyName?: string
  itemTags?: string[]
  itemCreatorNames?: string[]
  itemCreatorCompanyNames?: string[]
  taskAssignerNames?: string[]
  taskAssignerCompanyNames?: string[]
  taskAssigneeNames?: string[]
  taskAssigneeCompanyNames?: string[]

  itemsCount?: number
}

export interface ProjectAggregateData {
  totalAndActiveItemsPerUser?: any
  drawingNames?: string[]
  itemTags?: string[]
  itemCreatorNames?: string[]
  itemCreatorCompanyNames?: string[]
  taskAssignerNames?: string[]
  taskAssignerCompanyNames?: string[]
  taskAssigneeNames?: string[]
  taskAssigneeCompanyNames?: string[]

  itemsCount?: number
  drawingsCount?: number
  usersCount?: number
  reportsCount?: number
  legacyReportsCount?: number

  companyName?: string
  companyUid?: string
  companyFeatures?: string[]

  projectCreatorName?: string
  projectCreatorUid?: string
  projectCreatorEmail?: string
}

export interface CompanyAggregateData {
  totalAndActiveItemsPerUser?: any
  companyCount?: number
  companyUsersCount?: number

  usersCount?: number
  projectsCount?: number

  projectItemTags?: string[]

  projectItemsCount?: number
  projectDrawingsCount?: number
  projectUsersCount?: number
  projectReportsCount?: number
  projectLegacyReportsCount?: number
}

export interface InvitationAggregateData {
  sourceName?: string
  sourceEmail?: string
  sourceCompanyName?: string
  resourceProjectName?: string
  resourceCompanyName?: string
}

export interface LegacyTemplateAggregateData {
  templateCreatorName?: string
  templateCreatorUid?: string
  templateCreatorCompanyName?: string
  templateCreatorCompanyUid?: string
  subscriberCompanyName?: string
  subscriberCompanyUid?: string
}

export interface LegacyReportAggregateData {
  creatorUid?: string
  creatorName?: string
  creatorCompanyName?: string
  projectName?: string
  projectUid?: string
  legacyTemplateName?: string
  legacyTemplateUid?: string
}

export interface ReportAggregateData {
  creatorUid?: string
  creatorName?: string
  creatorCompanyName?: string
  projectName?: string
  projectUid?: string
}
