import { Location } from "@angular/common"
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import { CompanyFeatures, LegacyTemplate, ProjectData } from "@models/common"
import { NextExperimentalService } from "@services/apis/next-experimental.service"
import { Observable, Subscription } from "rxjs"
import { map } from "rxjs/operators"
import {
  IMultiLangDialogData,
  MultiLangConfirmationDialogComponent,
} from "../../dialogs/multi-lang-confirmation-dialog/multi-lang-confirmation-dialog.component"
import { ProjectTemplateService } from "../services"

@Component({
  selector: "project-creation",
  templateUrl: "./project-creation.component.html",
  styleUrls: ["./project-creation.component.scss"],
})
export class ProjectCreationComponent implements OnInit, OnDestroy {
  @Input() companyTags: string[]
  @Input() companyTemplates: LegacyTemplate[] = []
  @Input() companyFeatures: CompanyFeatures[] = []
  @Input() canCreateProject: boolean
  @Input() isCreatingProject: boolean

  @Output() selectedTemplatesChange = new EventEmitter()
  @Output() projectDataChange = new EventEmitter<ProjectData>()
  @Output() reportTagsChange = new EventEmitter<string[]>()
  @Output() createProjectButtonEventEmitter = new EventEmitter()

  mainProjectInfoForm: UntypedFormGroup
  projectDetails: UntypedFormGroup
  projectTags: string[] = []
  reportTags: string[] = []
  hasPublicTemplates: boolean = false

  subscriptions: Subscription[] = []
  readonly availableNextProjectProjects$: Observable<{ name: string; id: number }[]> =
    this.nextExperimentalService.nextProjectProjects$.pipe(
      map((projects) => projects.items.map((project) => ({ name: project.name, id: project.id })))
    )
  nextProjectProjectIdToConnect: number | null = null

  get canAddProjectTemplates() {
    return this.companyFeatures.indexOf(CompanyFeatures.FORMS) > -1 && this.companyFeatures.indexOf(CompanyFeatures.PROJECT_TEMPLATES) > -1
  }

  constructor(
    private projectTemplateService: ProjectTemplateService,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialog,
    public location: Location,
    public nextExperimentalService: NextExperimentalService
  ) {
    this.nextExperimentalService.nextProjectProjectSearch$.next("")
  }

  ngOnInit() {
    this.setupProjectInfo()
    this.setupSubscriptions()

    // Mark the project name field as touched on init to trigger validation check
    // Required to make the box invalid/red initially to signify to the user that he/she needs to fill this out
    this.nameField!.markAsTouched()
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.mainProjectInfoForm.valueChanges.subscribe((_) => this.emitProjectData()),
      this.projectDetails.valueChanges.subscribe((_) => this.emitProjectData()),
    ]
  }

  emitProjectData() {
    this.projectDataChange.emit({
      name: this.mainProjectInfoForm.value.name,
      description: this.mainProjectInfoForm.value.description,
      location: { address: this.mainProjectInfoForm.value.address },
      details: this.projectDetails.value,
      tags: this.projectTags,
      hasPublicTemplates: this.hasPublicTemplates,
      nextProjectId: this.nextProjectProjectIdToConnect,
    } as ProjectData)
  }

  setupProjectInfo() {
    this.mainProjectInfoForm = this.formBuilder.group({
      name: [
        "",
        [
          Validators.required, // At least one character required
          Validators.pattern("^(?=\\s*\\S).*$"), // at least one non-whitespace character required
        ],
      ],
      address: "",
      description: "",
    })

    this.projectDetails = this.formBuilder.group({
      projectType: "",
      municipalNumber: "",
      cadastrialUnitNumber: "",
      idNumber: "",
      projectPhase: "",
      owner: "",
      client: "",
      cost: "",
    })

    this.projectTags.push(...this.companyTags)
  }

  get nameField() {
    return this.mainProjectInfoForm.get("name")
  }

  onItemTagsUpdated(tags: string[]) {
    this.projectTags = tags
    this.emitProjectData()
  }

  onReportTagsUpdated(tags: string[]) {
    this.reportTags = tags
    this.reportTagsChange.emit(tags)
  }

  onHasPublicTemplatesChange(val: boolean) {
    this.hasPublicTemplates = val
    this.emitProjectData()
  }

  get createButtonIdText(): string {
    // We use two distinct buttons IDs to make it easier for Intercom to trigger on the correct event
    return this.canCreateProject ? "button-project-create" : "button-project-create-disabled"
  }

  openProjectItemTagsInfoDialog() {
    this.dialogRef.open(MultiLangConfirmationDialogComponent, {
      data: <IMultiLangDialogData>{
        cancelButton: false,
        languageEntries: [
          {
            lang_button_label: "EN",
            title_text: "Project Item Tags",
            paragraphs: [
              "These tags will appear as choices every time you create an item in this project. ",
              "Tags are keywords that describe items, and by using them, your items and tasks will be " +
                "organised and easier to find later. ",
              "Examples of tags include: plumber, painter, carpenter, HSEQ, snag, quality check.",
            ],
          },
          {
            lang_button_label: "NO",
            title_text: "Prosjekt Punkt Tagger",
            paragraphs: [
              "Disse taggene vil vises som valg hver gang du oppretter ett punkt i dette prosjektet.",
              "Tags er nøkkelord som beskriver punkter, og ved å bruke de vil punktene dine bli organisert og lettere å finne senere.",
              "Eksempler på tagger inkluderer: rørlegger, maler, snekker, HMS og KS, kvalitetskontroll, avvik.",
            ],
          },
        ],
      },
    })
  }

  openProjectReportTagsInfoDialog() {
    this.dialogRef.open(MultiLangConfirmationDialogComponent, {
      data: <IMultiLangDialogData>{
        cancelButton: false,
        languageEntries: [
          {
            lang_button_label: "EN",
            title_text: "Project Report Tags",
            paragraphs: [
              "You can now add tags to project reports from WEB!",
              "Project Owners, admins and report creators can tag reports.",
              "You will not be able to tag a locked Report, it needs to be unlocked. ",
              "All Report tags will be available for managing on the project info page.",
              "Report tags are not global and will only work in the specified project.",
            ],
          },
          {
            lang_button_label: "NO",
            title_text: "Prosjekt Raport Tagger",
            paragraphs: [
              "Du kan nå tagge prosjekt rapporter på WEB!",
              "Prosjekteiere, administratorer og rapport-opprettere kan tagge rapporter.",
              "Du kan ikke tagge en låst rapport.",
              "Rapport tagger kan administreres på prosjekt-info siden.",
              "Rapport tagger er ikke globale og fungerer bare i det spesifiserte prosjektet.",
            ],
          },
        ],
      },
    })
  }
}
