import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"

import { Person } from "@models/common"
import { SnackbarService } from "@services"

@Component({
  selector: "profile-personal",
  templateUrl: "./profile-personal.component.html",
  styleUrls: ["./profile-personal.component.scss"],
})
export class ProfilePersonalComponent implements OnInit, OnChanges {
  @Input() person: Person
  @Output() onSave = new EventEmitter()

  isEditable: boolean = false
  personalFormGroup: UntypedFormGroup
  tags: string[] = []

  constructor(private formBuilder: UntypedFormBuilder, private snackBar: SnackbarService) {}

  ngOnInit() {
    this.setupFormGroup()
    this.onEditable(false)
  }

  get personTags() {
    return this.person && this.person.tags ? this.person.tags : []
  }

  ngOnChanges() {
    if (this.person != null) {
      this.setupFormGroup()
      this.onEditable(false)
    }
  }

  onEditable(isEditable: boolean) {
    this.isEditable = isEditable

    return isEditable ? this.personalFormGroup.enable() : this.personalFormGroup.disable()
  }

  onTagsUpdated(event: any) {
    // TODO implement
  }

  setupFormGroup() {
    this.personalFormGroup = this.formBuilder.group({
      name: [
        this.person ? this.person.name : "",
        [
          Validators.required, // At least one character required
          Validators.pattern("^(?=\\s*\\S).*$"),
        ], // at least one non-whitespace character required
      ],
      phone: this.person ? this.person.phone : "",
      email: this.person ? this.person.email : "",
      jobTitle: this.person ? this.person.jobTitle : "",
    })
  }

  get name() {
    return this.personalFormGroup.get("name")
  }

  save() {
    const cleanedName: string = this.name!.value.trim()

    // TODO: Figure out why regular form validation doesn't work here
    if (cleanedName === "") {
      this.snackBar.showMessage("A name is required")

      return
    }

    this.onSave.emit({
      ...this.personalFormGroup.value,
      name: cleanedName,
      fullName: cleanedName,
    })
  }
}
