import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-building-solid-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.25 0C4.00781 0 3 1.00781 3 2.25V21.75C3 22.9922 4.00781 24 5.25 24H9.75V20.25C9.75 19.0078 10.7578 18 12 18C13.2422 18 14.25 19.0078 14.25 20.25V24H18.75C19.9922 24 21 22.9922 21 21.75V2.25C21 1.00781 19.9922 0 18.75 0H5.25ZM6 11.25C6 10.8375 6.3375 10.5 6.75 10.5H8.25C8.6625 10.5 9 10.8375 9 11.25V12.75C9 13.1625 8.6625 13.5 8.25 13.5H6.75C6.3375 13.5 6 13.1625 6 12.75V11.25ZM11.25 10.5H12.75C13.1625 10.5 13.5 10.8375 13.5 11.25V12.75C13.5 13.1625 13.1625 13.5 12.75 13.5H11.25C10.8375 13.5 10.5 13.1625 10.5 12.75V11.25C10.5 10.8375 10.8375 10.5 11.25 10.5ZM15 11.25C15 10.8375 15.3375 10.5 15.75 10.5H17.25C17.6625 10.5 18 10.8375 18 11.25V12.75C18 13.1625 17.6625 13.5 17.25 13.5H15.75C15.3375 13.5 15 13.1625 15 12.75V11.25ZM6.75 4.5H8.25C8.6625 4.5 9 4.8375 9 5.25V6.75C9 7.1625 8.6625 7.5 8.25 7.5H6.75C6.3375 7.5 6 7.1625 6 6.75V5.25C6 4.8375 6.3375 4.5 6.75 4.5ZM10.5 5.25C10.5 4.8375 10.8375 4.5 11.25 4.5H12.75C13.1625 4.5 13.5 4.8375 13.5 5.25V6.75C13.5 7.1625 13.1625 7.5 12.75 7.5H11.25C10.8375 7.5 10.5 7.1625 10.5 6.75V5.25ZM15.75 4.5H17.25C17.6625 4.5 18 4.8375 18 5.25V6.75C18 7.1625 17.6625 7.5 17.25 7.5H15.75C15.3375 7.5 15 7.1625 15 6.75V5.25C15 4.8375 15.3375 4.5 15.75 4.5Z"
        fill="currentColor"
      />
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class BuildingSolidIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }
}
