import { Injectable } from "@angular/core"

import { Item, Person, WORKFLOW_STATES_MAPPING, WorkflowStatusFilter } from "@models/common"
import { ProjectItemsDisplayed } from "../project/project-items/project-items-displayed.enum"
import * as moment from "moment"
import { ItemFilterPipe } from "../filters/item-filter.pipe"
import { FilterableItem } from "../project/models"

// TODO Refactor all item filterinng functionality in project items
// component and drawing view into this service.

export const emptyAcSearchFilter: SearchFilterCollection = {
  number: [],
  title: "",
  tags: [],
  creator: [],
  assignerCompany: [],
  drawing: [],
  project: [],
  assigner: [],
  assignee: [],
  dateFrom: null,
  dateTo: null,
}

export interface SearchFilterCollection {
  number: number[]
  title: string
  tags: string[]
  creator: string[]
  assignerCompany: string[]
  drawing: string[]
  project: string[]
  assigner: string[]
  assignee: string[]
  dateFrom: any
  dateTo: any
}

@Injectable()
export class ItemFilterService {
  acSearchFilter: SearchFilterCollection = emptyAcSearchFilter

  constructor() {}

  hasIncludedStatus(item: Item, statusFilter: WorkflowStatusFilter) {
    if (!this.isAnyFilterActive(statusFilter)) return true

    // @ts-ignore
    let whitelist = Object.keys(statusFilter).filter((k) => statusFilter[k])
    // @ts-ignore
    let mappings = whitelist.map((w) => WORKFLOW_STATES_MAPPING[w])
    if (mappings.length > 0) {
      let completeWhitelist = mappings.reduce((acc, val) => [...acc, ...val])
      return completeWhitelist.includes(item.status)
    } else {
      return false
    }
  }

  isAnyFilterActive(statusFilter: WorkflowStatusFilter) {
    for (let k of Object.keys(statusFilter)) {
      // @ts-ignore
      if (!statusFilter[k]) return true
    }
    return false
  }

  isMatchingString(searchString: string, match: string) {
    if (searchString == null) return true

    let processedSearchString = searchString.toString().trim().toLowerCase()
    if (processedSearchString == "") return true

    if (match == null) return false

    let processedMatch = match.toString().trim().toLowerCase()
    return processedMatch.includes(processedSearchString)
  }

  isMatchingNumberArraySubset(search: number[], match: number[]) {
    if (search == null || search.length < 1) return true
    if (match == null || match.length < 1) return false

    return match.filter((m) => search.includes(m)).length > 0
  }

  isMatchingStringArraySubset(search: string[], match: string[]) {
    if (search == null || search.length < 1) return true
    if ((search == null || search.length < 1) && (match == null || match.length < 1)) return true
    if (match == null || match.length < 1) return false

    let processedSearch = search.filter((it) => it).map((s) => s.trim().toLowerCase())
    let processedMatch = match.filter((it) => it).map((s) => s.trim().toLowerCase())

    return processedMatch.filter((m) => processedSearch.includes(m)).length > 0
  }

  hasAcNumberMatch(item: Item) {
    return this.isMatchingNumberArraySubset(this.acSearchFilter.number, [item.number])
  }

  hasAcTitleMatch(item: Item) {
    if (this.acSearchFilter.title == null || this.acSearchFilter.title.trim() == "") return true

    if (item.name == null || item.name.trim() == "") return false

    return this.isMatchingString(this.acSearchFilter.title, item.name)
  }

  hasAcTagsMatch(item: Item) {
    return this.isMatchingStringArraySubset(this.acSearchFilter.tags || [], item.tags || [])
  }

  hasAcCreatorMatch(item: Item) {
    let creatorNameArr = item.creatorName ? [item.creatorName] : []
    return this.isMatchingStringArraySubset(this.acSearchFilter.creator, creatorNameArr)
  }

  hasAcCompanyMatch(item: Item) {
    let companyNameArr = item.assignerCompanyName ? [item.assignerCompanyName] : []
    return this.isMatchingStringArraySubset(this.acSearchFilter.assignerCompany, companyNameArr)
  }

  hasAcCreatedAtMatch(item: Item) {
    if (this.acSearchFilter.dateFrom == null && this.acSearchFilter.dateTo == null) return true

    if (item.createdAt == null) return false

    if (this.acSearchFilter.dateFrom != null && moment(item.createdAt).diff(moment(this.acSearchFilter.dateFrom), "seconds") < 0)
      return false

    if (this.acSearchFilter.dateTo != null && moment(item.createdAt).diff(moment(this.acSearchFilter.dateTo), "days") > 0) return false

    return true
  }

  hasAcUpdatedMatch(item: Item) {
    if (this.acSearchFilter.dateFrom == null && this.acSearchFilter.dateTo == null) return true

    if (item.updatedAt == null) return false

    if (this.acSearchFilter.dateFrom != null && moment(item.updatedAt).diff(moment(this.acSearchFilter.dateFrom), "seconds") < 0)
      return false

    if (this.acSearchFilter.dateTo != null && moment(item.updatedAt).diff(moment(this.acSearchFilter.dateTo), "days") > 0) return false

    return true
  }

  hasAcDrawingMatch(item: Item) {
    let drawingNameArr = item.drawingName ? [item.drawingName] : []
    return this.isMatchingStringArraySubset(this.acSearchFilter.drawing, drawingNameArr)
  }

  hasAcAssignerMatch(item: Item) {
    let assignerNameArr = item.assignerName ? [item.assignerName] : []
    return this.isMatchingStringArraySubset(this.acSearchFilter.assigner, assignerNameArr)
  }

  hasAcAssigneeMatch(item: Item) {
    let assigneeNameArr = item.assigneeName ? [item.assigneeName] : []
    return this.isMatchingStringArraySubset(this.acSearchFilter.assignee, assigneeNameArr)
  }

  hasAcProjectMatch(item: Item) {
    let projectNameArr = item.projectName ? [item.projectName] : []
    return this.isMatchingStringArraySubset(this.acSearchFilter.project, projectNameArr)
  }

  hasAcSearchMatch(item: Item, acSearchFilter: SearchFilterCollection) {
    this.acSearchFilter = acSearchFilter
    return (
      this.hasAcNumberMatch(item) &&
      this.hasAcTitleMatch(item) &&
      this.hasAcTagsMatch(item) &&
      this.hasAcCreatorMatch(item) &&
      this.hasAcCompanyMatch(item) &&
      this.hasAcCreatedAtMatch(item) &&
      // && this.hasAcUpdatedMatch(item)
      this.hasAcDrawingMatch(item) &&
      this.hasAcProjectMatch(item) &&
      this.hasAcAssignerMatch(item) &&
      this.hasAcAssigneeMatch(item)
    )
  }

  isUserItem(item: Item, user: Person, userItemsOnly: boolean) {
    if (!userItemsOnly) return true

    // let user = this.currentUser;

    if (item.aggregateData.itemCreatorUid === user.uid) return true

    if (item.aggregateData.taskAssignerUid === user.uid) return true

    if (item.aggregateData.taskAssigneeUid === user.uid) return true

    if ((item.aggregateData.taskCollaboratorUids || []).includes(user.uid)) {
      return true
    }

    return false
  }

  // isUserFilterableItem(item: FilterableItem, user: Person, userItemsOnly: boolean) {
  //   if (!userItemsOnly) return true
  //
  //   if (this.isUserItem(item as Item, user, userItemsOnly)) {
  //     return true
  //   }
  //
  //   if (item.creator != null && item.creator.uid === user.uid) return true
  //   if (item.assigner != null && item.assigner.uid === user.uid) return true
  //   if (item.assignee != null && item.assignee.uid === user.uid) return true
  //
  //   // TODO Add collaborator when finished with it
  //   return false
  // }
}
