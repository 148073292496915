import { Component, OnInit } from "@angular/core"
import { DefaultElementComponent } from "../default-element/default-element.component"

@Component({
  selector: "app-slider-element",
  templateUrl: "./slider-element.component.html",
  styleUrls: ["./slider-element.component.scss"],
})
export class SliderElementComponent extends DefaultElementComponent {
  // TODO see if these are specified dynamically somewhere in Forms
  min: number = 0
  max: number = 32

  override ngOnInit() {}
}
