import { Component, OnInit } from "@angular/core"

@Component({
  selector: "checkd-expansion-panel",
  templateUrl: "./checkd-expansion-panel.component.html",
  styleUrls: ["./checkd-expansion-panel.component.scss"],
})
export class CheckdExpansionPanelComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
