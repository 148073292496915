import { Component, OnInit, Output, EventEmitter } from "@angular/core"
import { FilestackService } from "../services/filestack.service"

@Component({
  selector: "app-filestack-uploader",
  templateUrl: "./filestack-uploader.component.html",
  styleUrls: ["./filestack-uploader.component.scss"],
})
export class FilestackUploaderComponent implements OnInit {
  @Output() onUpload: EventEmitter<any> = new EventEmitter()

  constructor(private filestackService: FilestackService) {}

  ngOnInit() {}

  upload() {
    this.filestackService
      .pick({
        accept: ["image/jpeg", "image/png"],
        storeTo: { location: "gcs", path: "images/" },
      })
      .then((result) => this.onUpload.emit(result))
  }
}
