import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Notification, Person } from "@models/common"
import { UserService } from "@services/user.service"
import { Observable, Subscription } from "rxjs"
import { map } from "rxjs/operators"
import { NotificationType } from "@models/common/notification-type"
import { NotificationsService } from "./services/notifications.service"

@Component({
  selector: "checkd-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("notificationsContainer", { static: true }) notificationContainer: ElementRef<HTMLDivElement>

  notificationsShownState = false

  notifications$: Observable<Notification[]>
  unreadCount$: Observable<number>

  notificationFilter = [
    NotificationType.TASK_NEW_ASSIGNED,
    NotificationType.TASK_REJECTED,
    NotificationType.TASK_FIXED,
    NotificationType.ITEM_CHAT_MESSAGE_NEW,
    NotificationType.PROJECT_ARCHIVED,
    NotificationType.TASK_FIX_REJECTED,
    NotificationType.TASK_COLLABORATOR_ADDED,
    NotificationType.PROJECT_USER_INVITED,
  ]

  subscriptions: Subscription = new Subscription()
  private currentUser: Person

  constructor(private userService: UserService, private notificationService: NotificationsService) {
    this.notifications$ = this.userService.currentUserNotifications$.pipe(
      map((notificationArray) => {
        return notificationArray.filter((notification: Notification) => {
          return this.notificationFilter.includes(notification.notificationType as NotificationType)
        })
      })
    )

    this.unreadCount$ = this.notifications$.pipe(
      map((notificationArray) => {
        return notificationArray.filter((n) => !n.isOpened).length
      })
    )
  }

  ngOnInit() {
    this.setupSubscriptions()
  }

  trackByFn(index: number, notification: Notification) {
    return notification.uid
  }

  setupSubscriptions() {
    this.subscriptions.add(this.userService.currentUser$.subscribe((currentUser) => (this.currentUser = currentUser)))
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  ngAfterViewInit() {
    document.addEventListener("click", this.offClickHandler.bind(this))
  }

  toggleNotificationsDropdownMenuShownState() {
    this.notificationsShownState = !this.notificationsShownState
  }

  offClickHandler(event: any) {
    if (!this.notificationsShownState) {
      return
    }

    if (!this.notificationContainer.nativeElement.contains(event.target)) {
      this.toggleNotificationsDropdownMenuShownState()
    }
  }

  async markAllAsRead() {
    const currentUser = this.currentUser
    await this.notificationService.markAllAsReadForUser(currentUser)
  }

  notificationClicked(notification: Notification) {
    return this.notificationService.openNotificationTarget(this.currentUser, notification)
  }
}
