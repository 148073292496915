export const checkBoxNoColumn = (rectSize: number, checked: boolean, fillable = false, lineColor: string = "#000000") => {
  const checkMark =
    checked && !fillable
      ? [
          {
            lineColor,
            type: "line",
            x1: 0,
            x2: rectSize - 5,
            y1: 0,
            y2: rectSize - 5,
          },
          {
            lineColor,
            type: "line",
            x1: rectSize - 5,
            x2: 0,
            y1: 0,
            y2: rectSize - 5,
          },
        ]
      : []
  const checkBox = {
    width: rectSize,
    canvas: [
      {
        lineColor,
        fillable: false,
        type: "rect",
        x: 0,
        y: 0,
        w: rectSize - 5,
        h: rectSize - 5,
      },
      {
        lineColor,
        fillable: fillable,
        type: "rect",
        x: 0,
        y: 0,
        w: rectSize - 5,
        h: rectSize - 5,
      },
      ...checkMark,
    ],
  }

  return checkBox
}

export const checkBox = (title: string, rectSize: number, checked: boolean, fillable = false, lineColor: string = "#000000") => {
  const titleText = { text: title }

  return {
    columns: [{ text: titleText, alignment: "right", margin: [0, 0, 8, 0] }, checkBoxNoColumn(rectSize, checked, fillable, lineColor)],
  }
}
