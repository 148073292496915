import { Component, Input, OnInit } from "@angular/core"

import { Image } from "@models/common"

@Component({
  selector: "item-image-gallery",
  templateUrl: "./item-image-gallery.component.html",
  styleUrls: ["./item-image-gallery.component.scss"],
})
export class ItemImageGalleryComponent implements OnInit {
  @Input() images: Image[]
  @Input() image: Image
  imageMap: { [imageUid: string]: Image }

  constructor() {}

  ngOnInit() {
    this.imageMap = this.images != null ? this.images.reduce((it, image) => Object.assign(it, { [image.uid]: image }), {}) : {}
  }

  get currentImage() {
    if (this.image != null) return this.image
    return this.images != null && this.images.length > 0 ? this.images[0] : null
  }

  showPreviousImage() {
    let nextPosition = (((this.currentImagePosition - 1) % this.imagesLength) + this.imagesLength) % this.imagesLength
    this.image = this.images[nextPosition]
  }

  showNextImage() {
    let nextPosition = (this.currentImagePosition + 1) % this.imagesLength
    this.image = this.images[nextPosition]
  }

  private get imagesLength() {
    if (this.images == null) return 0
    return this.images.length
  }

  private get currentImagePosition() {
    if (this.image == null) return 0
    if (this.images.length < 1) return 0

    for (let i = 0; i < this.images.length; i++) {
      if (this.images[i].uid == this.image.uid) return i
    }

    return 0
  }
}
