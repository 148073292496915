import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { Company, LABELS, Person, Project, ProjectOwnerRole, Relation, Role } from "@models/common"
import { RoleHandlerService, SnackbarService } from "@services"
import { from as observableFrom, Observable, Subscription } from "rxjs"
import { take } from "rxjs/operators"

@Component({
  selector: "people-role-selector",
  templateUrl: "./people-role-selector.component.html",
  styleUrls: ["./people-role-selector.component.scss"],
})
export class PeopleRoleSelectorComponent implements OnInit, OnDestroy {
  get hasMoreThanOneEditor() {
    return (this.editorCount || 0) > 1
  }
  get hasMoreThanOneOwner() {
    return (this.ownerCount || 0) > 1
  }
  get isCurrentRoleEditor() {
    return this.currentRole!.label && this.editorLabels.indexOf(this.currentRole!.label) > -1
  }
  get isCurrentRoleOwner() {
    return this.currentRole != null && this.currentRole.label === LABELS.OWNER
  }
  get isCurrentUserOwner() {
    return this.currentUserRole != null && this.currentUserRole instanceof ProjectOwnerRole
  }
  get isCurrentUserEditor() {
    if (this.currentUserRole == null) {
      return false
    }
    for (const label of this.currentUserRole.labels || []) {
      if (this.editorLabels.indexOf(label) > -1) {
        return true
      }
    }

    return false
  }

  get isRoleUpdatable() {
    return (
      this.currentRole != null &&
      // Make sure current user has editor privileges
      this.isCurrentUserEditor &&
      // Prevent non-normal roles from being changed unless there are more than one editor
      (this.hasMoreThanOneEditor || !this.isCurrentRoleEditor) &&
      // Prevent non-owners from downgrading/upgrading owners
      (!this.isCurrentRoleOwner || (this.isCurrentRoleOwner && this.isCurrentUserOwner)) &&
      // Prevent owner roles from being changed unless there are more than one owner
      (this.hasMoreThanOneOwner || !this.isCurrentRoleOwner)
    )
  }

  get availableRoles() {
    return this.isCurrentUserOwner ? this.roles : (this.roles || []).filter((role) => role.label !== LABELS.OWNER)
  }

  get show() {
    return this.showRoleSelector && this.isRoleUpdatable
  }
  @Input() person: Person
  @Input() currentUser: Person
  @Input("peopleListOf") roleTarget: Company | Project
  @Input() showRoleSelector: boolean
  @Input() editorCount: number
  @Input() ownerCount: number
  @Input() currentUserRole: Role

  // TODO Use actual Role instances here to get the description etc.
  @Input() roles: any[] = [
    { label: LABELS.DEFAULT, description: "Project normal user" },
    { label: LABELS.ADMINISTRATOR, description: "Project administrator" },
    { label: LABELS.OWNER, description: "Project owner" },
  ]
  public selected: { label: string; description: string } | null = null

  public userRole$: Observable<Role>

  private subscriptions: Subscription[] = []

  private relation: Relation // person to company | project
  private inverseRelation: Relation // company | project to person
  private currentRole: { label: string; description: string } | null = null

  private editorLabels = [LABELS.ADMINISTRATOR, LABELS.OWNER]

  constructor(private snackbar: SnackbarService, private roleHandler: RoleHandlerService) {}

  setupListeners() {
    this.userRole$ = this.roleHandler.listenToRole(this.person, this.roleTarget)
  }

  setupSubscriptions() {
    this.subscriptions = [
      observableFrom(Promise.all([Relation.get(this.person, this.roleTarget), Relation.get(this.roleTarget, this.person)]))
        .pipe(take(1))
        .subscribe((rels) => {
          this.relation = rels[0]
          this.inverseRelation = rels[1]
          // @ts-ignore
          this.selected = this.relation != null ? this.relation.getHighestPrecedentLabel() : null
          this.currentRole = this.selected != null ? this.roles.find((role) => role.label === this.selected) : null
        }),
    ]
  }

  compareFn(r1: any, r2: any): boolean {
    return r1 && r2 ? r1.label === r2 : r1 === r2
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  ngOnInit() {
    this.setupListeners()
    this.setupSubscriptions()
  }

  selectionChange(newRole: { label: string; description: string }) {
    this.updateRelationLabel(newRole)
  }

  private updateRelationLabel(newRole: { label: string; description: string }) {
    const index = this.relation.labels.indexOf(this.currentRole!.label)
    // @ts-ignore
    const label = Relation.LABELS[newRole.label]

    // inverse relation
    // @ts-ignore
    const inverseIndex = this.inverseRelation.labels.indexOf(Relation.invertLabel(this.currentRole.label))
    const inverseLabel = Relation.invertLabel(label)
    if (label && index !== -1 && inverseLabel) {
      this.relation.labels[index] = label

      if (inverseIndex !== -1) {
        this.inverseRelation.labels[inverseIndex] = inverseLabel
      } else {
        this.inverseRelation.labels.unshift(inverseLabel)
      }

      Promise.all([this.relation.update(), this.inverseRelation.update()]).then((_) =>
        this.snackbar.showMessage(`Role updated for ${this.person.name}`)
      )
    }
  }
}
