import { HttpClient } from "@angular/common/http"
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { AngularFireStorage } from "@angular/fire/compat/storage"
import { MatDialog } from "@angular/material/dialog"
import { ActivatedRoute, Router } from "@angular/router"
import { FormElementOption } from "@checkd-form/models/form-element-option"
import { CheckdFormDialogService } from "@checkd-form/services/checkd-form-dialog.service"
import { ItemService } from "@services"
import { UtilityService } from "@services/utility.service"
import { FilestackService } from "../../../../services/filestack.service"
import { GeocodingService } from "../../../../services/geocoding.service"
import { ImageService } from "../../../../services/image.service"
import { SnackbarService } from "../../../../services/snackbar.service"
import { FormElement } from "../../../models/form-element"
import { FormMessageService } from "../../../services/form-message.service"

@Component({
  selector: "app-default-element",
  templateUrl: "./default-element.component.html",
  styleUrls: ["./default-element.component.scss"],
})
export class DefaultElementComponent implements OnInit {
  @Input() config: { [key: string]: any }
  @Input() readOnly: boolean
  @Input() parentElement: FormElement
  @Input() element: FormElement

  @Output() valueChanged = new EventEmitter()

  constructor(
    public formMessageService: FormMessageService,
    protected dialogRef: MatDialog,
    protected dialogService: CheckdFormDialogService,
    protected snackBar: SnackbarService,
    protected geocodingService: GeocodingService,
    protected filestackService: FilestackService,
    protected imageService: ImageService,
    protected itemService: ItemService,
    protected storage: AngularFireStorage,
    protected route: ActivatedRoute,
    protected router: Router,
    protected utilityService: UtilityService,
    protected cdr: ChangeDetectorRef,
    protected httpClient: HttpClient
  ) {}

  ngOnInit() {
    return
  }

  // TODO Change into getter/setter combo
  getOption(optionCode: string): FormElementOption | undefined {
    for (const option of this.element.options!) {
      if (option.code && option.code.toLowerCase() === optionCode.toLowerCase()) {
        return option
      }

      if (option.name && option.name.toLowerCase() === optionCode.toLowerCase()) {
        return option
      }
    }

    return undefined
  }

  hasOption(optionCode: string) {
    return this.getOption(optionCode) != null
  }

  protected setValue(value: any) {
    const oldValue = this.element.value
    this.element.value = value
    if (value !== oldValue) {
      this.formMessageService.sendMessage(FormMessageService.MSG_ELEMENT_VALUE_CHANGED, this)
      this.valueChanged.emit(this)
    }
  }

  protected getValue() {
    return this.element && this.element.value ? this.element.value : null
  }

  protected getValuesByName(name: string) {
    if (this.values != null) {
      return this.values.filter((v) => v.name != null && v.name === name)
    }

    return undefined
  }

  /**
   * Default setter for an elements value.
   * Used in the ngModels in form element templates for subclasses of this class.
   * Override this in a subclass if an element needs special treatment.
   */
  set value(value: any) {
    this.setValue(value)
  }

  /**
   * Default getter for an elements value.
   * Used in the ngModels in form element templates for subclasses of this class.
   * Override this in a subclass if an element needs special treatment.
   */
  get value() {
    return this.getValue()
  }

  /**
   * Default getter for an elements values (i.e. child nodes).
   * Used in some ngModels in form element templates if relevant.
   */
  get values() {
    return this.element && this.element.values ? this.element.values : []
  }

  /**
   * Default getter for an elements value.
   * Used in some ngModels in form element templates if relevant.
   */
  get title() {
    return this.element && this.element.title ? this.element.title : null
  }

  /**
   * Default getter for an elements value.
   * Used in some ngModels in form element templates if relevant.
   */
  get name() {
    return this.element && this.element.name ? this.element.name : null
  }

  /**
   * Default getter for an elements accepted child elements, whatever that is.
   */
  get accepted() {
    return this.element && this.element.accepted ? this.element.accepted : []
  }

  /**
   * Default getter for an elements rejected child elements, whatever that is.
   */
  get rejected() {
    return this.element && this.element.rejected ? this.element.rejected : []
  }

  /**
   * Default getter for an elements child nodes. Can apparently have
   * more than one child node, but someone apparently hates plural
   * variable naming.  It also seems to be pretty similar in
   * functionality to 'accepted', 'rejected', 'values', and so forth.
   */
  get child(): FormElement[] {
    return this.element && this.element.child ? this.element.child : []
  }

  protected addChild(child: any) {
    if (this.element.child == null) {
      this.element.child = [child]
    } else {
      this.element.child.push(child)
    }
    this.formMessageService.sendMessage(FormMessageService.MSG_ELEMENT_CHILD_ADDED, this)
  }

  protected removeChild(index: number) {
    if (index < this.element.child!.length) {
      this.element.child!.splice(index + 1, 1)
    } else {
      console.error(`Index ${index} out of bounds for element`, this.element)
    }

    this.formMessageService.sendMessage(FormMessageService.MSG_ELEMENT_CHILD_REMOVED, this)
  }
}
