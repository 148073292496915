import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"

import { catchError, combineLatest, firstValueFrom, Observable, of, Subject } from "rxjs"
import { filter, map, startWith, take, tap, timeout } from "rxjs/operators"

import {
  ChannelService,
  CustomAttachmentUploadContext,
  CustomTemplatesService,
  DefaultStreamChatGenerics,
  MentionTemplateContext,
} from "stream-chat-angular"

import { Channel } from "stream-chat"

import { AdminService, FieldChatService, FieldChatViewService, IFieldChatProjectData, UserService } from "../../services"

@Component({
  selector: "field-chat-main-view",
  templateUrl: "./field-chat-main-view.component.html",
  styleUrls: ["./field-chat-main-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldChatMainViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("emptyTemplate", { read: TemplateRef })
  private emptyTemplateRef!: TemplateRef<CustomAttachmentUploadContext>

  private readonly onDestroy$ = new Subject<void>()

  @ViewChild("mentionTemplate")
  private mentionTemplate!: TemplateRef<MentionTemplateContext>

  constructor(
    public fieldChatService: FieldChatService,
    public fieldChatViewService: FieldChatViewService,
    private userService: UserService,
    public channelService: ChannelService,
    private customTemplatesService: CustomTemplatesService,
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    const [projectUid, projectName] = await firstValueFrom(
      this.route.queryParamMap.pipe(
        map((paramMap) => [paramMap.get("projectUid"), paramMap.get("projectName")]),
        take(1)
      )
    )

    await this.fieldChatService.initializeChatClient()

    if (projectUid && projectName) {
      await this.removeUrlQueryParams()
      await this.onProjectSelected({ name: projectName, uid: projectUid })
      this.fieldChatViewService.setCurrentlyVisibleChannelType("project")
    } else {
      await this.fieldChatService.initChannels(null)
    }
  }

  private async removeUrlQueryParams() {
    const currentUrl = this.router.url.split("?")[0]

    if (!currentUrl) {
      return
    }

    await this.router.navigate([currentUrl], { queryParams: {} })
  }

  ngAfterViewInit(): void {
    this.customTemplatesService.customAttachmentUploadTemplate$.next(this.emptyTemplateRef)
    this.customTemplatesService.mentionTemplate$.next(this.mentionTemplate)
  }

  @Input() channel: Channel<DefaultStreamChatGenerics> | undefined

  readonly isInitializing$: Observable<boolean> = combineLatest([
    this.channelService.channelQueryState$,
    this.channelService.activeChannel$,
  ]).pipe(
    map(([state, activeChannel]) => {
      return !activeChannel && state?.state === "in-progress"
    })
  )

  readonly isActiveChannel$: Observable<boolean> = this.channelService.activeChannel$.pipe(map((c) => !!c))

  readonly isError$: Observable<boolean> = combineLatest([this.channelService.channelQueryState$, this.channelService.activeChannel$]).pipe(
    map(([state, activeChannel]) => {
      return !activeChannel && state?.state === "error"
    })
  )

  readonly membersCount$ = this.channelService.activeChannel$.pipe(
    filter(Boolean),
    map((channel) => channel.data?.member_count)
  )

  readonly membersOnline$ = this.channelService.activeChannel$.pipe(
    filter(Boolean),
    map((channel) => channel.state?.watcher_count)
  )

  public readonly userCompanyName$ = this.userService.userCompany$.pipe(
    filter((company) => company?.name !== undefined),
    map((company) => company.name),
    startWith("")
  )

  async ngOnDestroy() {
    await this.fieldChatService.disconnectUser()
    this.onDestroy$.next()
    this.onDestroy$.complete()
  }

  toggleShowProjects() {
    this.fieldChatViewService.showProjects = !this.fieldChatViewService.showProjects
  }

  async changeChannelType(selectedChannelType: "company" | "project") {
    const currentChannelType = await firstValueFrom(this.fieldChatViewService.channelTypeVisible$)
    if (currentChannelType === selectedChannelType) {
      return
    }

    this.fieldChatViewService.setCurrentlyVisibleChannelType(selectedChannelType)

    if (selectedChannelType === "company") {
      this.fieldChatViewService.showProjects = false

      return this.fieldChatService.initChannels(null)
    }

    const projectSelected = await firstValueFrom(this.fieldChatViewService.projectSelected$)
    if (projectSelected) {
      return this.fieldChatService.initChannels(projectSelected.uid)
    }

    this.fieldChatViewService.showProjects = true
  }

  async onProjectSelected(project: IFieldChatProjectData) {
    this.fieldChatViewService.setSelectedProject(project)

    await this.fieldChatService.initChannels(project.uid)
    this.fieldChatViewService.showProjects = false
  }

  async onCreateRoomClicked() {
    // TODO: check if user has permissions to create channels
    const roomTypeVisible = await firstValueFrom(this.fieldChatViewService.currentChatRoomType$)

    switch (roomTypeVisible) {
      case "team": {
        const [currentUser, currentUserCompanyUid] = await Promise.all([
          firstValueFrom(this.userService.currentUser$),
          firstValueFrom(this.userService.userCompanyUid$),
        ])
        await this.adminService.createChannel({
          chatRoomType: roomTypeVisible,
          invokedByUserUid: currentUser.uid,
          invokerCompanyOrProjectUid: currentUserCompanyUid,
        })
        break
      }
      case "messaging": {
        const [currentUser, currentProject] = await Promise.all([
          firstValueFrom(this.userService.currentUser$),
          firstValueFrom(this.fieldChatViewService.projectSelected$),
        ])

        if (!currentProject) {
          throw new Error("Could not get current project")
        }

        await this.adminService.createChannel({
          chatRoomType: roomTypeVisible,
          invokedByUserUid: currentUser.uid,
          invokerCompanyOrProjectUid: currentProject?.uid,
        })
        break
      }
    }

    // Hack: When creating new channels, the new channel shows up twice in the channel list. A short timeout seems to fix it.
    // Not sure why it's happening
    setTimeout(async () => {
      const selectedProject = this.fieldChatViewService.getSelectedProject()
      await this.fieldChatService.initChannels(selectedProject?.uid ?? null)
    }, 500)
  }

  async openEditChannelDialog() {
    const activeChannel = await firstValueFrom(
      this.channelService.activeChannel$.pipe(
        timeout(500),
        catchError((_) => of(undefined))
      )
    )

    if (!activeChannel) {
      return console.error("Could not fetch the current active channel")
    }

    const name = activeChannel.data?.name ?? ""
    const description = (activeChannel.data?.["description"] as string) ?? ""

    await this.adminService.editChannel(activeChannel, name, description)
  }
}
