import { Component, OnDestroy, OnInit } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"
import { CompanyFeatures } from "@models/common"
import { FirebaseAuthService, NavigationService, ProjectService, UserService } from "@services"
import { FieldChatDashboardService } from "@services/field-chat-dashboard.service"
import { SideNavService } from "@services/side-nav.service"
import { BehaviorSubject, Observable, Subject, combineLatest, firstValueFrom, of, startWith, timeout } from "rxjs"
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil } from "rxjs/operators"

@Component({
  selector: "checkd-top-bar",
  templateUrl: "./top-bar.component.html",
  styleUrls: ["./top-bar.component.scss"],
})
export class TopBarComponent implements OnInit, OnDestroy {
  pathComponents: string[]

  constructor(
    public userService: UserService,
    private firebaseAuth: FirebaseAuthService,
    private projectService: ProjectService,
    public navigationService: NavigationService,
    public sideNavService: SideNavService,
    private router: Router,
    public fieldChatDashboardService: FieldChatDashboardService
  ) {}

  readonly ngUnsubscribe = new Subject<void>()

  readonly authority$ = new BehaviorSubject<string | null>(null)
  readonly target$ = new BehaviorSubject<string | null>(null)
  readonly currentUser$ = this.userService.currentUser$
  readonly currentProject$ = this.projectService.currentProject$
  readonly currentCompany$ = this.userService.currentCompany$

  readonly userName$ = this.currentUser$.pipe(
    map((user) => (user?.name ? user.name : "")),
    distinctUntilChanged()
  )

  get isOneLogin() {
    return !!localStorage.getItem("one-login:issuer")
  }

  readonly oneLogin$ = combineLatest([this.userService.authority$, this.navigationService.showTopbar$]).pipe(
    map(async ([authority, showToolbar]) => {
      if (!showToolbar) {
        return false
      }

      const target = authority?.replace(/\/\/auth/g, "//account")
      if (authority) {
        const key = `oidc.user:${authority}:field`
        const data = localStorage.getItem(key)
        if (data) {
          sessionStorage.setItem(key, data)
          await this.addCustomElements(target)
        }
      }
      this.authority$.next(authority ?? "")
      this.target$.next(target ?? "")
      return true
    }),
    distinctUntilChanged()
  )

  private async addCustomElements(target: string) {
    return document.head.querySelector("script[data-onelogin]")
      ? Promise.resolve(null)
      : new Promise((resolve) => {
          const script = document.createElement("script")
          script.setAttribute("data-onelogin", "true")
          script.onload = resolve
          script.src = `${target}/custom-elements.js`
          document.head.appendChild(script)
        })
  }

  readonly userEmail$ = combineLatest([this.currentUser$, this.firebaseAuth.authState$]).pipe(
    map(([user, authedUser]) => {
      if (authedUser && authedUser.email) {
        return authedUser.email
      }

      return user?.email || ""
    }),
    distinctUntilChanged()
  )

  readonly userImage$ = this.currentUser$.pipe(
    map((user) => user?.image || ""),
    distinctUntilChanged()
  )

  readonly projectName$ = this.currentProject$.pipe(
    map((project) => project?.name || ""),
    distinctUntilChanged()
  )

  readonly currentCompanyName$ = this.currentCompany$.pipe(
    map((company) => company?.name || ""),
    distinctUntilChanged()
  )

  // Emits every time navigation occurs
  private readonly onNavigation$ = this.router.events.pipe(
    filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    shareReplay({ bufferSize: 1, refCount: true }),
    takeUntil(this.ngUnsubscribe)
  )

  readonly userCompanyHasFieldChatFeature$ = combineLatest([
    this.userService.currentCompanyHasFeature(CompanyFeatures.FIELD_CHAT),
    this.projectService.currentProjectHasCompanyFeature(CompanyFeatures.FIELD_CHAT).pipe(startWith(false)),
  ]).pipe(shareReplay({ bufferSize: 1, refCount: true }))

  readonly shouldDisplayChatBubbleIcon$: Observable<boolean> = this.onNavigation$.pipe(
    switchMap((_) => this.userCompanyHasFieldChatFeature$),
    map(([companyHasFieldChatFeature, currentProjectHasFieldChatCompanyFeature]) => {
      // Hack to detect whether user is currently inside a project
      const isInProject = this.projectService.isInProjectTest(this.router.url)

      return companyHasFieldChatFeature || (currentProjectHasFieldChatCompanyFeature && isInProject)
    }),
    takeUntil(this.ngUnsubscribe)
  )

  ngOnInit() {
    this.navigationService.pathComponents$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pathComponents) => (this.pathComponents = pathComponents))

    this.userCompanyHasFieldChatFeature$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((data) => data.includes(true)),
        distinctUntilChanged()
      )
      .subscribe(async (hasChatFeature) => {
        if (hasChatFeature) {
          try {
            await this.fieldChatDashboardService.initializeChatClient()
          } catch (e: any) {
            console.error(e)
          }
        }
      })
  }

  async ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
    this.authority$.complete()
    this.target$.complete()
    await this.fieldChatDashboardService.disconnectUser()
  }

  async logout() {
    await this.userService.logout()

    // If the user is currently on an invitation page, just reload the page
    if (this.pathComponents.includes("invitations")) {
      return window.location.reload()
    }

    // Redirecto to login in all other cases
    return this.navigationService.goToLoginPage()
  }

  get darkTopBar() {
    return this.pathComponents && this.pathComponents.includes("insights")
  }

  get useShadow() {
    const withoutShadow = [
      "insights", // TODO: project page
    ]

    return this.isOneLogin ? false : this.pathComponents && !!withoutShadow.filter((value) => this.pathComponents.includes(value))
  }

  async onChatBubbleClicked() {
    const isInProject = this.projectService.isInProjectTest(this.router.url)

    let url: string
    if (isInProject) {
      const project = await firstValueFrom(
        this.projectService.currentProject$.pipe(
          take(1),
          timeout(1000),
          catchError((err) => of(null))
        )
      )

      if (project === null) {
        return console.error("Could not get current project UID")
      }

      url = this.router.createUrlTree(["/chat"], { queryParams: { projectUid: project.uid, projectName: project.name } }).toString()
    } else {
      url = this.router.createUrlTree(["/chat"]).toString()
    }

    window.open(url, "_blank")
  }
}
