import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-chevron-down-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" [attr.viewBox]="getViewBox()" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.5885 7.85084L12.972 18.0229C12.6165 18.3229 12.2831 18.4451 11.9998 18.4451C11.7165 18.4451 11.3348 18.3219 11.0782 18.0736L0.411623 7.85084C-0.121481 7.34529 -0.138814 6.45086 0.371624 5.96753C0.878562 5.43281 1.72605 5.41543 2.25549 5.9276L11.9998 15.2674L21.7441 5.9342C22.2719 5.42204 23.1208 5.43943 23.628 5.97412C24.1385 6.45086 24.1219 7.34529 23.5885 7.85084Z"
        fill="#092C3E"
        [attr.transform]="getScale()"
      />
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class ChevronDownIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }

  getViewBox(): string | undefined {
    return this.svgIconsService.getViewBox(this.size)
  }

  getScale(): string | undefined {
    return this.svgIconsService.getScale(this.size)
  }
}
