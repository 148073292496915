<div
  (keyup.enter)="sendComment(_inputValue)"
  *ngIf="isAvailableAction('SEND_COMMENT')">

  <input
    [(ngModel)]="_inputValue"
    placeholder="Add comment"
    [disabled]="projectIsArchived">

  <button (click)="sendComment(_inputValue)" [disabled]="projectIsArchived || inputIsBlank(_inputValue)" mat-icon-button>
    <mat-icon>send</mat-icon>
  </button>
</div>
