<div class="items-view-container" fxLayout="column">
  <checkd-project-items-top-action-bar
    fxFlex="0 0 auto"
    fxFlexAlign="stretch"
    [canSeeAllItems]="canSeeAllItems"
    [currentUser]="currentUser"
    (notifyParentFilterStateChanged)="updateStatusFilter($event)"
    (clearFiltersButtonClicked)="resetFilters()"
    [projectItemsDisplayed$]="projectItemsDisplayed$"
    [projectItemsStatusCounts]="projectItemsStatusCounts$ | async"
  >
  </checkd-project-items-top-action-bar>

  <checkd-item-list
    #checkdItemList
    [items]="statusAndRoleFilteredItems$ | async"
    [tableFor]="'projectItems'"
    [currentUser]="currentUser"
    [userProjectRole]="userProjectRole"
    (itemSelected)="itemSelected($event)"
    (itemsSelected)="selectedItems = $event"
    (menuOptionSelected)="onMenuOptionSelected($event)"
    [itemCreatorNames]="creatorValues"
    [drawingNames]="drawingValues"
    [taskAssignerNames]="assignerValues"
    [taskAssignerCompanyNames]="companyValues"
    [taskAssigneeNames]="assigneeValues"
    [reportNames]="reportNameValues"
    [itemTags]="tagValues"
    [canUseItemFlow]="hasItemsFlowFeature && hasItemsFlowPermissions"
    [canExportToBcf]="hasBcfExportFeature"
    (itemActionClicked)="itemActionClickedHandler($event)"
    [tooManyItemsForBulkActions]="tooManyItemsForBulkActions"
    [maxItemsForBulkActions]="maxItemsForBulkActions"
    [isProjectLockedOrArchived]="isProjectLockedOrArchived"
    [noItemsTitle]="noProjectItemTitle"
    [noItemsDescription]="noProjectItemDesc"
    [noItemsImgSrc]="noProjectItemsImgSrc"
    [isItemListReady]="projectItemsReady"
  >
  </checkd-item-list>
</div>
<p-toast></p-toast>
