<div class="room-details">
  <div
    class="room__members-component tw-bg-white tw-absolute tw-h-full tw-top-0 tw-transition-all tw-ease-in-out tw-duration-300 tw-z-50 tw-overflow-auto"
    [ngStyle]="{
      transform: showMembers ? 'translateX(0)' : 'translateX(100%)',
      visibility: showMembers ? 'visible' : 'hidden'
    }"
  >
    <div class="room__header tw-flex tw-justify-between tw-px-8 tw-items-center">
      <button
        class="tw-flex tw-px-2 tw-items-center tw-gap-1 tw-cursor-pointer focus-visible:tw-outline-next-navy-500"
        (click)="toggleShowMembers()"
      >
        <img src="/assets/chat-images/icons/arrow-left.svg" alt="Arrow left" />
        <h2>Room Details</h2>
      </button>
      <button
        (click)="toggleIsOpen()"
        class="tw-cursor-pointer hover:tw-scale-105 hover:tw-transition-all focus-visible:tw-outline-next-navy-500"
      >
        <img src="/assets/chat-images/icons/close-chat-room.svg" alt="Close icon" />
      </button>
    </div>
    <field-chat-member-list></field-chat-member-list>
  </div>

  <div class="room__header tw-flex tw-justify-between tw-px-8 tw-items-center">
    <h2>Room Details</h2>
    <button
      class="tw-cursor-pointer hover:tw-scale-105 hover:tw-transition-all focus-visible:tw-outline-next-navy-500"
      (click)="toggleIsOpen()"
    >
      <img src="assets/chat-images/icons/close-chat-room.svg" alt="Close icon" />
    </button>
  </div>

  <div class="room__description-container tw-p-4 tw-flex tw-flex-col tw-gap-8">
    <div class="tw-p-4 tw-flex tw-flex-col tw-gap-6">
      <div class="tw-flex tw-flex-col tw-gap-6">
        <div class="tw-flex tw-items-center tw-gap-4">
          <img src="/assets/chat-images/icons/chat-room-icon.svg" alt="Room icon" class="room__chat-room-img" />
          <span>{{ fieldChatService.channelName$ | async }}</span>
        </div>
        <hr />
      </div>
      <div class="tw-flex tw-flex-col tw-gap-4">
        <h3 class="tw-font-bold">Description</h3>
        <p class="tw-max-h-96 tw-bg-none">
          {{ fieldChatService.channelDesc$ | async }}
        </p>
      </div>
    </div>
    <div class="room__chat-members">
      <h3 class="tw-font-bold">CHAT</h3>
      <div class="tw-p-4">
        <button
          class="room__list-btn tw-bg-next-gray-50 hover:tw-bg-next-gray-200"
          (click)="toggleShowMembers()"
          [ngClass]="showMembers ? 'tw-text-brand-blue-500' : ''"
        >
          <span class="tw-flex tw-items-center tw-gap-2">
            <field-chat-lib-members-icon></field-chat-lib-members-icon>
            <h2 class="room__text-members">View members</h2>
          </span>
          <field-chat-lib-chevron-right-icon [size]="'sm'"></field-chat-lib-chevron-right-icon>
        </button>
      </div>
    </div>
    <div class="room__security">
      <h3 class="tw-font-bold">SECURITY</h3>
      <div class="tw-flex tw-flex-col tw-gap-6 tw-p-4">
        <field-chat-link-button
          imgSrc="/assets/chat-images/icons/pen-to-square.svg"
          text="Edit room"
          imgDescription="Edit room icon"
          *ngIf="fieldChatViewService.currentUserCanEditRooms$ | async"
          (click)="onEditRoomClicked.emit()"
          [componentToRender]="penToSquareIcon"
        >
        </field-chat-link-button>

        <field-chat-link-button
          imgSrc="/assets/chat-images/icons/leave-room-icon.svg"
          text="Leave room"
          imgDescription="Leave room icon"
          (click)="onLeaveRoomClicked()"
          [componentToRender]="leaveRoomIcon"
        >
        </field-chat-link-button>

        <field-chat-link-button
          imgSrc="/assets/chat-images/icons/trash-icon.svg"
          text="Delete room"
          imgDescription="trash icon"
          [isDeleteLink]="true"
          *ngIf="fieldChatViewService.currentUserCanEditRooms$ | async"
          (click)="onDeleteChannelClicked()"
          [componentToRender]="trashIcon"
        >
        </field-chat-link-button>
      </div>
    </div>
  </div>
</div>
