<p-card *ngIf="signInForm" header="Sign in">
  <form [formGroup]="signInForm" (ngSubmit)="submitHandler()" id="form">
    <span class="p-float-label mt-1">
      <input id="float-input-email" class="w-full" type="email" pInputText formControlName="email" />
      <label for="float-input-email">Email</label>
    </span>

    <span class="p-float-label mt-4">
      <input id="float-input-password" class="w-full" type="password" pInputText formControlName="password" />
      <label for="float-input-password">Password</label>
    </span>
  </form>
  <p class="forgot-your-password">
    <a [routerLink]="'/passwordrecovery'" class="card__link"> Forgot your password? </a>
  </p>
  <span class="inline-block mt-3">Sign in to your <strong>Next Field</strong> account to accept the invitation</span>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-end w-full">
      <button
        class=""
        pButton
        label="Sign in"
        icon="pi pi-check"
        form="form"
        [disabled]="formSubmissionInProgress || signInForm.invalid"
      ></button>
    </div>
  </ng-template>
</p-card>

<p-toast></p-toast>
