import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-close-chat-room-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.1899 21.9677C23.5403 22.3182 23.5403 22.8867 23.1899 23.2372C22.8395 23.5876 22.2709 23.5876 21.9205 23.2372L17.501 18.7848L13.0501 23.2357C12.6997 23.5861 12.1311 23.5861 11.7807 23.2357C11.4302 22.8852 11.4302 22.3167 11.7807 21.9662L16.233 17.5168L11.7794 13.0323C11.429 12.6818 11.429 12.1133 11.7794 11.7628C12.1298 11.4124 12.6984 11.4124 13.0489 11.7628L17.501 16.2489L21.9519 11.798C22.3023 11.4476 22.8709 11.4476 23.2213 11.798C23.5718 12.1484 23.5718 12.717 23.2213 13.0674L18.7689 17.5168L23.1899 21.9677Z"
        fill="black"
      />
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class CloseChatRoomIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }
}
