import { Component, OnInit } from "@angular/core"
import { DefaultElementComponent } from "../default-element/default-element.component"

@Component({
  selector: "app-counter-element",
  templateUrl: "./counter-element.component.html",
  styleUrls: ["./counter-element.component.scss"],
})
export class CounterElementComponent extends DefaultElementComponent implements OnInit {
  override ngOnInit() {
    this.element.value = this.element.value || 0
  }

  increase() {
    this.setValue(this.element.value + 1)
  }

  decrease() {
    if (this.getValue() > 0) {
      this.setValue(this.getValue() - 1)
    }
  }
}
