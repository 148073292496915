import { Injectable } from "@angular/core"
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar"

@Injectable()
export class SnackbarService {
  public static FORM_SAVED = "Form saved successfully!"
  public static FORM_SAVE_ERROR = "Error saving form! Check internet connection and try again!"
  public static LOGIN_ERROR = "Error logging in. Check username or password and try again!"

  public static ACTION_BUTTON_DOWNLOAD = "Download PDF"
  constructor(private snackBar: MatSnackBar) {}

  showMessage(message: string, duration = 3000) {
    this.snackBar.open(message, "", {
      duration: duration,
    })
  }

  showMessageWithAction(message: string, actionMessage: string, duration = 5000) {
    let snackBarRef = this.snackBar.open(message, actionMessage, {
      duration: duration,
    })
    return snackBarRef
  }
}
