<mat-dialog-content>
  <mat-form-field>
    <mat-label>E-mail invitation list</mat-label>
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let email of emails" (removed)="remove(email)">
        {{ email }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>

      <input
        #mailInput
        (paste)="pasteEvent($event)"
        placeholder="Enter emails"
        [formControl]="myControl"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <checkd-button bg="blue" fg="white" (click)="sendEmails()">Send emails</checkd-button>
</mat-dialog-actions>
