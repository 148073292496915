import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs"
import { debounceTime, distinctUntilChanged, map, switchMap, take, tap } from "rxjs/operators"
import { Project } from "../../models/common"
import { ProjectService } from "../project.service"
import { UserService } from "../user.service"
import { CloudFunctionsService } from "@services"

interface IToken {
  access_token: string
  token_type: string
}

interface INextProjectListProjectsResponse {
  items: [
    {
      id: number
      changed: string
      changeduserid: number
      created: string
      createduserid: number
      basicscopeid: number
      budgethours: number
      costcenterid: number
      customercontactid: number
      customerid: number
      customerreference: string
      enddate: string
      finalinspectiondate: string
      frameworkcontractid: number
      guaranteeinspectiondate: string
      guaranteetime: number
      inheritcost: boolean
      internalnote: string
      markup: number
      markupmodelid: number
      name: string
      note: string
      orderdate: string
      parentprojectid: number
      partnersupplierid: number
      pricetypeid: number
      probability: number
      projectmanagerid: number
      projectno: string
      projectresourcegroupid: number
      projectstatusid: number
      projecttypeid: number
      sellerid: number
      startdate: string
      tagfield1id: number
      tagfield2id: number
      wofavorite: boolean
      workleaderid: number
      workplaceaddress1: string
      workplaceaddress2: string
      workplacecity: string
      workplacedistance: number
      workplaceid: string
      workplacezipcode: string
    }
  ]
  page: number
  size: number
  total: number
}

@Injectable({
  providedIn: "root",
})
export class NextExperimentalService {
  public nextProjectProjectSearch$ = new BehaviorSubject("")

  public nextProjectProjects$: Observable<INextProjectListProjectsResponse> = combineLatest([
    this.userService.currentUser$,
    this.nextProjectProjectSearch$,
  ]).pipe(
    map(([user, search]) => {
      const s = (search || "").trim()
      let filterStr = ""

      if (s) {
        filterStr = `&filter_str=${encodeURIComponent(JSON.stringify({ name__contains: s }))}`
      }

      const path = `project/?page=1&size=50${filterStr}`

      return { user, path }
    }),
    distinctUntilChanged((prev, curr) => prev.user.uid === curr.user.uid && prev.path === curr.path),
    debounceTime(1000),
    switchMap(({ user, path }) => {
      return this.cloudFunctionsService.integrationsNextProject({
        userUid: user.uid,
        path,
      })
    })
  )

  private currentProject: Project | null = null
  private nextProjectProjectId: number | null = null

  private nextProjectProjectId$: Observable<number> = this.projectService.currentProject$.pipe(
    map((currentProject) => currentProject.nextProjectProjectId)
  )

  constructor(
    private db: AngularFirestore,
    private http: HttpClient,
    private userService: UserService,
    private projectService: ProjectService,
    private cloudFunctionsService: CloudFunctionsService
  ) {
    this.projectService.currentProject$.subscribe((it) => (this.currentProject = it))
    this.nextProjectProjectId$.subscribe((it) => (this.nextProjectProjectId = it))
  }

  public async setNextProjectProjectId(id: number) {
    if (this.currentProject == null) {
      throw new Error(`No project to do anything with`)
    }

    await this.currentProject.update({
      nextProjectId: id,
    })
  }

  public getNextProject(id: number) {
    return this.userService.currentUser$.pipe(
      take(1),
      switchMap((user) =>
        this.cloudFunctionsService.integrationsNextProject({
          userUid: user.uid,
          path: `project/${id}`,
        })
      )
    )
  }

  /* NB: Commented out for now as we won't need it for a while

  public async createNextProjectWorkorder(name: string, customerId: number, projectId: number) {
    const headers = new HttpHeaders().set("Authorization", `Bearer ${this.token!.access_token}`)

    await this.http
      .post(
        this.workorderPath,
        {
          name,
          customerId,
          projectId,
        },
        { headers }
      )
      .toPromise()
  }
   */

  public nextProjectCustomers$: Observable<any> = this.userService.currentUser$.pipe(
    take(1),
    switchMap((user) =>
      this.cloudFunctionsService.integrationsNextProject({
        userUid: user.uid,
        path: `customer/?page=1&size=50`,
      })
    )
  )
}
