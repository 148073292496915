import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { FormElementComponent } from "../form-element/form-element.component"
import { FormMessageService } from "@checkd-form/services/form-message.service"

@Component({
  selector: "app-form-header",
  templateUrl: "./form-header.component.html",
  styleUrls: ["./form-header.component.scss"],
})
export class FormHeaderComponent extends FormElementComponent {
  @Output() contentChange = new EventEmitter()

  get values() {
    if (this.element == null || this.element.values == null) return []
    return this.element.values
  }

  ngOnInit() {}

  onContentChange(element: any) {
    this.contentChange.emit(this.element.transformAttributes().values)
  }
}
