import { COLLECTIONS } from "../collections.interface"
import { LABELS } from "../relation.interface"
import { Role } from "./role"
import { RoleType } from "./roles-and-permissions.interface"

export class ProjectNormalRole extends Role {
  setupRoleData() {
    this.roleData = {
      name: "Project normal user",
      description: "A person who is part of a project with no administrator privileges",
      roleType: RoleType.PROJECT_NORMAL,
      requirements: {
        sourceCollection: COLLECTIONS.PEOPLE,
        labels: [LABELS.DEFAULT],
        targetCollection: COLLECTIONS.PROJECTS,
      },

      targetPermissions: { create: false, read: true, update: false, delete: false },

      permissions: [
        {
          description: "Can invite people to the project.",
          collection: COLLECTIONS.INVITATIONS,
          documentPermissions: { create: true, read: true, update: false, delete: false },
          relationPermissions: { create: true, read: true, update: false, delete: false },
        },
        {
          description: "Can create and read reports.",
          collection: COLLECTIONS.REPORTS,
          documentPermissions: { create: true, read: true, update: false, delete: false },
          relationPermissions: { create: true, read: true, update: false, delete: false },
        },
        {
          description: "Can create and read legacy reports.",
          collection: COLLECTIONS.LEGACY_REPORTS,
          documentPermissions: { create: true, read: true, update: false, delete: false },
          relationPermissions: { create: true, read: true, update: false, delete: false },
        },
      ],

      inferredRoleTypes: {},
    }
  }
}
