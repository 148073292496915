import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"

@Component({
  selector: "checkd-button",
  templateUrl: "./checkd-button.component.html",
  styleUrls: ["./checkd-button.component.scss"],
})
export class CheckdButtonComponent implements OnInit {
  @Input() bg: string = "green"
  @Input() fg: string = "white"
  @Input() inProgress: boolean = false
  @Input() lineHeight: number = 36
  @Input() fontSize: number = 14
  @Input() disabled: boolean = false
  @Input() buttonId: string
  @Output() buttonClick = new EventEmitter()
  @Output() spinnerClick = new EventEmitter()
  colorClass: string[]

  ngOnInit() {
    this.colorClass = [this.bgColor, this.fgColor]
  }

  get style() {
    return {
      "line-height": `${this.lineHeight}px`,
      "font-size": `${this.fontSize}px`,
    }
  }

  private get bgColor() {
    return `bg-${this.bg}`
  }

  private get fgColor() {
    return `fg-${this.fg}`
  }
}
