<div class="tw-relative tw-bg-white tw-rounded-lg tw-shadow tw-dark:bg-gray-700 tw-w-full tw-h-auto tw-max-w-md">
  <button
    type="button"
    class="tw-absolute tw-top-3 tw-right-2.5 tw-text-gray-400 tw-bg-transparent hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-rounded-lg tw-text-sm tw-p-1.5 tw-ml-auto tw-inline-flex tw-items-center focus-visible:tw-outline-next-navy-500"
    data-modal-hide="popup-modal"
    (click)="closeOverlay(false)"
  >
    <svg aria-hidden="true" class="tw-w-5 tw-h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clip-rule="evenodd"
      ></path>
    </svg>
    <span class="tw-sr-only">Close modal</span>
  </button>
  <div class="tw-p-6 tw-text-center tw-grid tw-place-items-center tw-gap-6">
    <svg
      aria-hidden="true"
      class="tw-text-gray-400 tw-w-14 tw-h-14"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    </svg>
    <h3 class="tw-text-lg tw-font-normal tw-text-gray-500">{{ description }}</h3>
    <div class="tw-flex tw-gap-4 tw-items-center tw-justify-self-end">
      <button
        data-modal-hide="popup-modal"
        type="button"
        class="tw-h-11 tw-text-gray-500 tw-bg-white tw-py-2 tw-px-4 tw-rounded hover:tw-bg-gray-500 hover:tw-text-white tw-transform tw-transition-transform active:tw-scale-90 focus:tw-outline focus:tw-outline-2 focus:tw-outline-white"
        (click)="closeOverlay(false)"
      >
        {{ cancelButtonLabel }}
      </button>
      <button
        data-modal-hide="popup-modal"
        type="button"
        class="modal-dialog__confirm-btn tw-text-next-white-50 tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-transform tw-transition-transform tw-bg-next-red-500 hover:tw-bg-next-red-700 active:tw-scale-90 active:tw-bg-next-red-300 focus-visible:tw-bg-next-red-500 focus-visible:tw-outline-color-next-white-50 disabled:tw-pointer-events-none disabled:tw-opacity-30"
        (click)="closeOverlay(true)"
      >
        {{ confirmButtonLabel }}
      </button>
    </div>
  </div>
</div>
