import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from "@angular/core"
import { WorkflowStatusFilter } from "@models/common"
import { CheckdStatusButtonComponent } from "../checkd-status-button/checkd-status-button.component"

export const defaultStatusFilter: WorkflowStatusFilter = {
  CLOSED: false,
  DELEGATED: true,
  FIXED: true,
  INPROGRESS: true,
  OPEN: true,
}

type FilterState = "open" | "delegated" | "in-progress" | "fixed" | "closed"

@Component({
  selector: "checkd-status-filter",
  templateUrl: "./checkd-status-filter.component.html",
  styleUrls: ["./checkd-status-filter.component.scss"],
})
export class CheckdStatusFilterComponent {
  @ViewChildren(CheckdStatusButtonComponent) buttons: QueryList<CheckdStatusButtonComponent>

  @Input() statusFilter: WorkflowStatusFilter = { ...defaultStatusFilter }
  @Input() statusCounts: { [status: string]: number }

  // removes extra padding and item counts below the filter icons if set to true
  @Input() isForMyItemsPage: boolean = false
  @Input() noPadding: boolean = false
  @Input() showItemNumbers: boolean = true

  @Output() change = new EventEmitter<WorkflowStatusFilter>()

  toggleFilter(event: FilterState) {
    switch (event) {
      case "open":
        this.statusFilter.OPEN = !this.statusFilter.OPEN
        break
      case "delegated":
        this.statusFilter.DELEGATED = !this.statusFilter.DELEGATED
        break
      case "in-progress":
        this.statusFilter.INPROGRESS = !this.statusFilter.INPROGRESS
        break
      case "fixed":
        this.statusFilter.FIXED = !this.statusFilter.FIXED
        break
      case "closed":
        this.statusFilter.CLOSED = !this.statusFilter.CLOSED
        break
      default:
        const _exhaustiveCheck: never = event
    }

    this.change.emit(this.statusFilter)
  }

  getImageSource(current: FilterState): string {
    switch (current) {
      case "open":
        return this.statusFilter.OPEN ? "/assets/icons/task_status/open_toggled.svg" : "/assets/icons/task_status/open_untoggled.svg"
      case "delegated":
        return this.statusFilter.DELEGATED
          ? "/assets/icons/task_status/delegated_toggled.svg"
          : "/assets/icons/task_status/delegated_untoggled.svg"
      case "in-progress":
        return this.statusFilter.INPROGRESS
          ? "/assets/icons/task_status/inprogress_toggled.svg"
          : "/assets/icons/task_status/inprogress_untoggled.svg"
      case "fixed":
        return this.statusFilter.FIXED ? "/assets/icons/task_status/fixed_toggled.svg" : "/assets/icons/task_status/fixed_untoggled.svg"
      case "closed":
        return this.statusFilter.CLOSED ? "/assets/icons/task_status/closed_toggled.svg" : "/assets/icons/task_status/closed_untoggled.svg"
      default:
        const _exhaustiveCheck: never = current

        return ""
    }
  }

  // onChange(type: string, state: boolean) {
  //   this.statusFilter = this.getButtonCheckedStatuses()
  //   this.change.emit(this.statusFilter)
  // }

  // getButtonCheckedStatuses() {
  //   return Object.assign({ }, ...this.buttons.map(button => {
  //     return { [button.type]: button.isChecked }
  //   }))
  // }

  clear() {
    this.statusFilter = { ...defaultStatusFilter }
    this.change.emit(this.statusFilter)
  }
}
