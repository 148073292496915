import { ElementOptionCode, TemplateElement, TemplateElementType } from "./template-element"

class AcceptedElement extends TemplateElement {
  override name: string = "Accepted"
  override type: TemplateElementType = TemplateElementType.ACCEPTED
  override value = ""
}

class RejectedElement extends TemplateElement {
  override name: string = "Rejected"
  override type: TemplateElementType = TemplateElementType.REJECTED
  override value = ""
}

class ToggleElement extends TemplateElement {
  override name: string = ""
  override icon?: string = "forms:toggle"
  override type: TemplateElementType = TemplateElementType.TOGGLE
  override typeAlias: string = "Toggle"
  override value: any = null
  override children = [new AcceptedElement(), new RejectedElement()]
  override options = [
    {
      id: 0,
      name: "Conditional",
      code: ElementOptionCode.CONDITIONAL,
      type: "checkbox",
      value: false,
    },
  ]
}

export { ToggleElement, AcceptedElement, RejectedElement }
