import { Component, OnInit, Input } from "@angular/core"

import { TimelineElement, Person, ImageData } from "@models/common"
import { FilestackService } from "@services"

@Component({
  selector: "timeline-image-element",
  templateUrl: "./timeline-image-element.component.html",
  styleUrls: ["./timeline-image-element.component.scss"],
})
export class TimelineImageElementComponent implements OnInit {
  @Input() timeline: TimelineElement
  @Input() creator: Person
  @Input() currentUser: Person

  imageData?: ImageData

  constructor(private filestackService: FilestackService) {}

  get url() {
    const url = this.timeline && this.timeline.currentData ? this.imageData?.url : ""

    return url ? this.filestackService.getExifRotationUrl(url) : ""
  }

  get creatorName() {
    return this.creator ? this.creator.name : ""
  }

  ngOnInit() {
    this.imageData = this.timeline?.currentData?.image
  }
}
