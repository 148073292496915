import { Component, OnInit } from "@angular/core"
import { Observable, of } from "rxjs"
import { catchError, map, take } from "rxjs/operators"
import { FormElement } from "../../../models/form-element"
import { DefaultElementComponent } from "../default-element/default-element.component"

@Component({
  selector: "app-gps-element",
  templateUrl: "./gps-element.component.html",
  styleUrls: ["./gps-element.component.scss"],
})
export class GpsElementComponent extends DefaultElementComponent implements OnInit {
  zoomLevel: number = 13

  center: google.maps.LatLngLiteral
  options: google.maps.MapOptions = {
    zoom: this.zoomLevel,
    scrollwheel: false,
  }

  readonly apiLoaded: Observable<boolean> = this.httpClient
    .jsonp("https://maps.googleapis.com/maps/api/js?key=AIzaSyByOpcHcTuk6szYwKa9cu5TE7XII2yxBXs", "callback")
    .pipe(
      map(() => true),
      catchError((err) => {
        console.log(err)

        return of(false)
      })
    )

  override ngOnInit() {
    if (this.latitude == null && this.longitude == null) {
      this.setLocationFromGeolocation()
    } else {
      this.center = { lat: this.latitude, lng: this.longitude }
    }
  }

  protected coordStringToNumber(coordString: string) {
    return parseFloat(coordString.replace(/,/g, "."))
  }

  protected numberToCoordString(n: number) {
    return `${n}`.replace(/\./g, ",")
  }

  get latitude() {
    if (this.hasLatitudeValue()) {
      const val = this.getLatitudeValue()!.value

      return typeof val === "string" ? this.coordStringToNumber(val) : val
    }

    // @ts-ignore
    return undefined
  }

  set latitude(coord: number) {
    this.updateLatitudeValue(coord)
  }

  get longitude() {
    if (this.hasLongitudeValue()) {
      const val = this.getLongitudeValue()!.value

      return typeof val === "string" ? this.coordStringToNumber(val) : val
    }

    // @ts-ignore
    return undefined
  }

  set longitude(coord: number) {
    this.updateLongitudeValue(coord)
  }

  updateLatitudeValue(latitude: number) {
    if (this.element.values == null) {
      this.element.values = []
    }

    if (!this.hasLatitudeValue()) {
      this.element.values.push(
        new FormElement({
          id: 0,
          name: "latitude",
          type: "latitude",
          value: "",
        })
      )
    }

    this.getLatitudeValue()!.value = this.numberToCoordString(latitude)
  }

  updateLongitudeValue(longitude: number) {
    if (this.element.values == null) {
      this.element.values = []
    }

    if (!this.hasLongitudeValue()) {
      this.element.values.push(
        new FormElement({
          id: 0,
          name: "longtitude",
          type: "longtitude",
          value: "",
        })
      )
    }

    this.getLongitudeValue()!.value = this.numberToCoordString(longitude)
  }

  updateAddressValue(address: string) {
    if (this.element.values == null) {
      this.element.values = []
    }

    if (!this.hasAddressValue()) {
      this.element.values.push(new FormElement({ id: 0, name: "address", type: "address", value: address }))
    }

    this.getAddressValue()!.value = address
    this.value = address
  }

  hasLatitudeValue() {
    return this.getValuesByName("latitude")!.length > 0
  }

  hasLongitudeValue() {
    return this.getValuesByName("longtitude")!.length > 0
  }

  hasAddressValue() {
    return this.getValuesByName("address")!.length > 0
  }

  getLatitudeValue() {
    if (this.hasLatitudeValue()) {
      return this.getValuesByName("latitude")![0]
    }

    return undefined
  }

  getLongitudeValue() {
    if (this.hasLongitudeValue()) {
      return this.getValuesByName("longtitude")![0]
    }

    return undefined
  }

  getAddressValue() {
    if (this.hasAddressValue()) {
      return this.getValuesByName("address")![0]
    }

    return undefined
  }

  protected updateAddress() {
    if (this.latitude != null && this.longitude != null) {
      this.geocodingService
        .lookup(this.latitude, this.longitude)
        .pipe(take(1))
        .subscribe((result) => {
          if (result.status === "OK") {
            const data = result
            if (data.results.length > 0) {
              this.updateAddressValue(data.results[0].formatted_address)
            }
          } else {
            console.error("TODO handle error in geocoding request")
          }
        })
    }
  }

  protected setLocationFromGeolocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (!this.center) {
          this.center = { lat: position.coords.latitude, lng: position.coords.longitude }
        }

        this.updateLatitudeValue(position.coords.latitude)
        this.updateLongitudeValue(position.coords.longitude)
        this.updateAddress()
      })
    }
  }

  public onDragEnd(event: any) {
    // console.log(event)
    // this.latitude = event.coords.lat
    // this.longitude = event.coords.lng
    // this.updateAddress()
  }
}
