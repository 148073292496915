<div class="item-images" fxLayout="column" fxLayoutAlign="center center">
  <img
    style="max-width: 30px"
    *ngIf="!hasImages"
    class="placeholder-icon"
    alt="placeholder"
    src="assets/icons/placeholder_picture_icon.svg"
  />

  <ks-plain-gallery
    [class.gallery]="shadowVisible"
    *ngIf="hasImages"
    [id]="galleryId"
    [images]="galleryImages"
    [config]="libConfigPlainGallery"
    (clickImage)="onShow(galleryId, $event)"
  >
  </ks-plain-gallery>
</div>
Images: {{ galleryImages.length }}
<!--[buttonsConfig]="galleryButtonsConfig"-->
<!--[currentImageConfig]="currentImageConfig"-->
<!--[plainGalleryConfig]="galleryConfig"-->
<!--(buttonBeforeHook)="buttonBeforeHook($event)">-->
