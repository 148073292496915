import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { FormControl } from "@angular/forms"

import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from "@angular/material/legacy-autocomplete"
import { Subscription } from "rxjs"
import { map, startWith } from "rxjs/operators"

interface IACSearchResult {
  searchValue: string
  values: string[]
}

@Component({
  selector: "checkd-ac-search",
  templateUrl: "./checkd-ac-search.component.html",
  styleUrls: ["./checkd-ac-search.component.scss"],
})
export class CheckdAcSearchComponent implements OnInit, OnDestroy, OnChanges {
  @Input() placeholder: string
  @Input() searchOptions: string[] = []
  @Input() width: string | null = null
  @Output() onSelection = new EventEmitter<IACSearchResult>()

  @ViewChild("ac", { read: MatAutocompleteTrigger, static: true }) ac: MatAutocompleteTrigger

  readonly acCtrl = new FormControl<string>("")

  filteredValues: string[]
  searchValue: string

  private readonly subscriptions = new Subscription()

  @Input()
  set currentValue(value: string) {
    this.acCtrl.setValue(value)
  }
  get currentValue(): string {
    return this.acCtrl.value ?? ""
  }

  ngOnChanges() {
    this.filteredValues = this.searchOptions
  }

  ngOnInit() {
    const sub = this.acCtrl.valueChanges
      .pipe(
        startWith(""),
        map((val) => this.filterValues(val ?? ""))
      )
      .subscribe((values) => {
        this.filteredValues = values
      })
    this.subscriptions.add(sub)
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  filterValues(val: string) {
    this.searchValue = val
    this.filteredValues = (this.searchOptions || []).filter((acVal) =>
      acVal.toString().toLowerCase().includes(val.toString().trim().toLowerCase())
    )

    return this.filteredValues
  }

  onOptionSelected() {
    this.emitResult()
  }

  onKeyupEnter() {
    this.ac.closePanel()
    this.emitResult()
  }

  emitResult() {
    const result: IACSearchResult = {
      searchValue: this.searchValue,
      values: this.filteredValues,
    }
    this.onSelection.emit(result)
  }

  onKeyup() {
    this.emitResult()
  }

  public clear() {
    this.currentValue = ""
  }
}
