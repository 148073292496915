import { PersonData } from "./person.interface"
import { Person } from "./person"

export interface UserRegistrationData {
  company?: string
  password: string
  personData: PersonData
}

export interface UserCompanyRemovalData {
  userUid: string
  companyUid: string
}

export const USER_PRIVATE_SUBCOLLECTION = "private"

export class User extends Person {}
