import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { IMenuOption, IReportMenuActionsResponse, Person, ReportMenuActions, Role } from "@models/common"
import { GeneralReport } from "@models/common/general-report"
import { ReportService } from "@services"
import { DialogService } from "app/dashboard/dialogs/dialog.service"

@Component({
  selector: "checkd-report-contextmenu",
  templateUrl: "./report-contextmenu.component.html",
  styleUrls: ["./report-contextmenu.component.scss"],
})
export class ReportContextmenuComponent implements OnInit {
  @Input() report: GeneralReport
  @Input() currentUser: Person
  @Input() userProjectRole: Role

  @Output() menuOptionSelected = new EventEmitter<IReportMenuActionsResponse>()

  menuOptions: IMenuOption[] = []

  constructor(private reportService: ReportService, private dialogService: DialogService) {}

  ngOnInit() {
    // @ts-ignore
    this.menuOptions = this.reportService.getMenuOptions(this.report, this.userProjectRole, this.currentUser)
  }

  get hasOptions() {
    return this.menuOptions.length > 0
  }

  async menuClicked(action: ReportMenuActions, confirmationDialog?: any) {
    if (confirmationDialog) {
      const result = await this.dialogService.confirm(confirmationDialog.title, confirmationDialog.content)
      if (result) {
        this.menuOptionSelected.emit({ report: this.report, action })
      }
    } else {
      this.menuOptionSelected.emit({ report: this.report, action })
    }
  }
}
