import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator"
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table"
import { PointDescriptionModalDialogComponent } from "@checkd-form/form-view/elements/item-list-element/point-description-modal-dialog/point-description-modal-dialog.component"
import { convertWorkflowStateToStatus, Image, Item, Person, ReportGenerationSettings, SortingDirection } from "@models/common"
import { orderByCreatedAt } from "@services/utilities"
import { DateFormatPipe, FromUnixPipe } from "ngx-moment"
import { Observable, Subscription } from "rxjs"
import { map } from "rxjs/operators"
import { DrawingFormElement } from "../../../models/form-element"
import { DefaultElementComponent } from "../default-element/default-element.component"

const PDF_IMAGE_WIDTH = 400
const DESCRIPTION_STRING_MAX_LENGTH = 60

export interface IImage {
  url: string
  name: string
  caption: string
}

export interface IRowItem {
  numberAndStatus: { itemNumber: number; status: string }
  name: string
  description: string
  tags: string[]
  itemCreatorName: string
  taskAssignerName: string
  taskAssignerCompanyName: string
  drawingName: string
  createdAt: number
  taskAssigneeName: string
  images: IImage[]
  galleryID: number
  dueDate: number | null
}

@Component({
  selector: "app-item-list-element",
  styleUrls: ["./item-list-element.component.scss"],
  templateUrl: "./item-list-element.component.html",
})
export class ItemListElementComponent extends DefaultElementComponent implements OnInit, OnDestroy {
  settings: ReportGenerationSettings = {
    projectName: true,
    drawing: true,
    drawingName: true,
    itemTitle: true,
    itemDescription: true,
    itemTags: true,
    taskStatus: true,
    creator: true,
    assignee: true,
    collaborators: true,
    createdDate: true,
    modifiedDate: true,
    company: true,
    dueDate: true,
    qrCode: true,
  }

  items$: Observable<Item[]>
  subscriptions: Subscription[] = []

  _transformedRowItems: IRowItem[] = []

  dataSource = new MatTableDataSource<IRowItem>()
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  pageSizeOptions: number[] = [80, 15, 30, 50, 100, 200, 500]

  displayedColumns: string[] = []

  get items() {
    return (this.value as Item[]) || []
  }

  get belongsToDrawing() {
    return this.parentElement != null && this.parentElement instanceof DrawingFormElement
  }

  setupListeners() {
    this.items$ = this.formMessageService.config$.pipe(
      map((config) => config.itemListSort),
      map((itemListSort) => this.itemService.sortItems(this.items, itemListSort))
    )
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.items$.subscribe((items) => {
        this.value = items
        this._transformedRowItems = (items || []).map((x) => this.transformItemToRowItem(x))
        this.dataSource.data = this._transformedRowItems
      }),
    ]
  }

  transformItemToRowItem(item: Item): IRowItem {
    return {
      numberAndStatus: { itemNumber: item.number, status: item.status },
      name: item.name!,
      description: item.description,
      tags: item.tags,
      itemCreatorName: (item.aggregateData || {}).itemCreatorName || "",
      taskAssignerName: (item.aggregateData || {}).taskAssignerName || "",
      taskAssignerCompanyName: (item.aggregateData || {}).taskAssignerCompanyName || "",
      drawingName: (item.aggregateData || {}).drawingName || "",
      // FIXME Ugly hack to make sure we don't get weird date from createdAt in items stored in reports
      createdAt: (item.createdAt || 0) > 32503680000 ? item.createdAt! * 0.001 : item.createdAt || 0,
      taskAssigneeName: (item.aggregateData || {}).taskAssigneeName || "",
      dueDate: item.dueDate,
      // @ts-ignore
      images: orderByCreatedAt<Image>(item.images || [], SortingDirection.ASC).map((x) => {
        return {
          url: x.url,
          name: x.name,
          caption: `Uploaded: ${this.formatTimestamp(x.createdAt)}`,
        } as IImage
      }),
      galleryID: this.utilityService.getNextRandomNumber(),
    }
  }

  formatTimestamp(timestamp: any) {
    if (isNaN(timestamp as any)) {
      return timestamp
    }
    const fromUnix = new FromUnixPipe().transform(timestamp / 1000)
    const dateFormat = new DateFormatPipe().transform(fromUnix, "DD-MM-YYYY HH:mm")

    return dateFormat
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  override ngOnInit() {
    this.setupListeners()
    this.setupSubscriptions()
    this.dataSource.paginator = this.paginator

    this.element.options!.map((option) => {
      // @ts-ignore
      if (this.settings[option.code]) {
        // @ts-ignore
        this.settings[option.code] = option.value === "true"
      }
    })

    this.initDisplayedColumns()
    this.setupItemPeopleInfo()
  }

  // TODO Deprecate and use aggregate data instead (e.g. itemCreatorName etc.)
  setupItemPeopleInfo() {
    // Fetch creator and assigner of item and task
    this.value.forEach((item: any) => {
      if (item.task && item.task.assignee) {
        Person.getByPath(item.task.assignee).then((assignee) => {
          item.task.assigneeName = assignee.name
        })
      }

      if (item.task && item.task.assigner) {
        Person.getByPath(item.task.assigner).then((assigner) => {
          item.task.assignerName = assigner.name
        })
      }

      // TODO: Not guaranteed to have uid
      if (item.uid) {
        Item.get(item.uid)
          .then((item: Item) => item.getCreator())
          .then((creator) => (item.creatorName = creator && creator.name ? creator.name : ""))
      }
    })
  }

  initDisplayedColumns() {
    const allTableColumnsCorrectOrder = [
      "taskStatus",
      "itemTitle",
      "itemDescription",
      "itemTags",
      "images",
      "creator",
      "assignee",
      "drawingName",
      "createdDate",
      "dueDate",
      "end",
    ]
    const filteredSettings = []

    for (const [key, value] of Object.entries(this.settings)) {
      if (!value || !allTableColumnsCorrectOrder.includes(key)) {
        continue
      }
      filteredSettings.push(key)
    }

    filteredSettings.push("images")
    filteredSettings.push("end")

    filteredSettings.sort((a, b) => {
      return allTableColumnsCorrectOrder.indexOf(a) - allTableColumnsCorrectOrder.indexOf(b)
    })

    this.displayedColumns = filteredSettings
  }

  isDescriptionStringTooLong(description: string): boolean {
    return description.length > DESCRIPTION_STRING_MAX_LENGTH
  }

  shortenDescriptionString(description: string): string {
    const end = "..."

    return `${description.substring(0, DESCRIPTION_STRING_MAX_LENGTH - end.length)}${end}`
  }

  getCorrectWorkflowStatus(type: string): string {
    const workflowType = convertWorkflowStateToStatus(type)

    return workflowType === null ? type : workflowType
  }

  openDialog(desc: string) {
    this.dialogRef.open(PointDescriptionModalDialogComponent, {
      data: {
        description: desc,
      },
    })
  }

  getWorkflowStatusClass(type: string): string {
    let projectStatusClassPostfix: string

    switch (this.getCorrectWorkflowStatus(type)) {
      case "OPEN":
        projectStatusClassPostfix = "-open"
        break
      case "DELEGATED":
        projectStatusClassPostfix = "-delegated"
        break
      case "INPROGRESS":
        projectStatusClassPostfix = "-in-progress"
        break
      case "FIXED":
        projectStatusClassPostfix = "-fixed"
        break
      case "CLOSED":
        projectStatusClassPostfix = "-closed"
        break
      default:
        projectStatusClassPostfix = "-default-fallback"
    }

    return `project-status${projectStatusClassPostfix}`
  }
}
