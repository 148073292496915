<div fxLayout="column" fxLayoutAlign="center center">
  <p class="title">Email Verification</p>

  <ng-container *ngIf="authCodeInfo">
    <p>The following email is being verified now:</p>
    <p>
      <strong>{{ authCodeInfo.data.email }}</strong>
    </p>

    <ng-container *ngIf="emailVerified">
      <p>Email verified successfully!</p>

      <button mat-button class="checkd-btn confirm-btn-color" routerLink="/projects">Go to Home Page</button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="oobErrorMessage">
    <p>{{ oobErrorMessage }}</p>
  </ng-container>
</div>
