import { Component, Inject, OnInit } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"

@Component({
  selector: "app-legacy-template-edit-dialog",
  templateUrl: "./legacy-template-edit-dialog.component.html",
  styleUrls: ["./legacy-template-edit-dialog.component.scss"],
})
export class LegacyTemplateEditDialogComponent implements OnInit {
  reportForm: UntypedFormGroup

  constructor(
    private dialogRef: MatDialogRef<LegacyTemplateEditDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { reportName: string }
  ) {}

  ngOnInit() {
    this.reportForm = this.fb.group({
      title: [
        this.data.reportName || "",
        [
          Validators.required,
          Validators.pattern("^(?=\\s*\\S).*$"), // at least one non-whitespace character required
        ],
      ],
    })
  }

  get title() {
    return this.reportForm.get("title")
  }

  save() {
    const title: string = this.title!.value.trim()
    this.dialogRef.close(title)
  }

  cancel() {
    this.dialogRef.close(null)
  }
}
