import { DOCUMENT } from "@angular/common"
import { Inject, Injectable } from "@angular/core"
import { LegacyTemplate } from "@models/common/legacy-template"
import { Project } from "@models/common/project"
import { Company } from "@models/common/company"
import { PdfDataService } from "@services"
import { projectTemplateDocDefinition } from "../docdefinitions/project-template.docdefinition"

@Injectable({
  providedIn: "root",
})
export class QrService {
  constructor(@Inject(DOCUMENT) private document: Document, private pdfService: PdfDataService) {}

  public getProjectTemplateUrl(projectUid: string, templateUid: string) {
    return `${this.document.location.origin}/projects/${projectUid}/openTemplates/${templateUid}/new`
  }

  public getProjectTemplateQrUrl(projectUid: string, templateUid: string, withHost = false) {
    return `${withHost ? this.document.location.origin : ""}/qr/projects/${projectUid}/openTemplates/${templateUid}`
  }

  public makeProjectTemplatePdf(project: Project, template: LegacyTemplate, company: Company, description = "") {
    const docDefinition = projectTemplateDocDefinition({
      title: template.name,
      url: this.getProjectTemplateUrl(project.uid, template.uid),
      projectName: project.name,
      companyName: company.name,
      checkdLogo: `${this.document.location.origin}/assets/logos/next-logo-icon.png`,
      templateLogo: template.logo,
      companyLogo: company.logo,
      description,
    })

    return this.pdfService.generateReport(docDefinition).open()
  }
}
