<button mat-raised-button [matMenuTriggerFor]="elementMenu">Add element</button>

<mat-menu #elementMenu="matMenu">
  <button mat-menu-item *ngFor="let element of _elements" class="flex align-items-center" (click)="onElementClicked.emit(element)">
    <mat-icon svgIcon="{{ element.icon }}" class="mr-2"></mat-icon>
    <span>{{ element.typeAlias }}</span>
    <div class="ml-auto">
      <span *ngIf="isBetaElement(element.type)" class="ml-3 px-1 beta-label">beta</span>
    </div>
  </button>
</mat-menu>
