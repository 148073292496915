<mat-dialog-content>
  <mat-form-field>
    <input matInput placeholder="Drawing name" [(ngModel)]="drawingData.name" />
  </mat-form-field>
  <div *ngIf="showQualitySelector" class="drawing-quality-container pb-3">
    <p>Drawing Quality</p>
    <div>
      <mat-radio-button (click)="qualityChange(50)" [checked]="quality() <= 87"> low </mat-radio-button>
    </div>
    <div>
      <mat-radio-button (click)="qualityChange(125)" [checked]="quality() >= 88 && quality() <= 162"> medium </mat-radio-button>
    </div>
    <div>
      <mat-radio-button (click)="qualityChange(200)" [checked]="quality() >= 163"> high </mat-radio-button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="checkd-btn cancel-btn-color">Close</button>
  <button mat-button [mat-dialog-close]="drawingData" class="checkd-btn save-btn-color">Save</button>
</mat-dialog-actions>
