<mat-icon *ngIf="hasInfo()" (click)="openInfoDialog()">info</mat-icon>
<!--
<div #formElementContainer>
</div>
-->

<div [ngSwitch]="getElementType()">
  <app-default-element *ngSwitchCase="components.default" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-default-element>
  <app-time-element *ngSwitchCase="components.time" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-time-element>
  <app-date-element *ngSwitchCase="components.date" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-date-element>
  <app-gps-element *ngSwitchCase="components.gps" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-gps-element>
  <app-gps-coordinate-element *ngSwitchCase="components.latitude" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-gps-coordinate-element>
  <app-gps-coordinate-element *ngSwitchCase="components.longitude" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-gps-coordinate-element>
  <app-dropdown-element *ngSwitchCase="components.dropdown" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-dropdown-element>
  <app-dropdown-item-element *ngSwitchCase="components.dropdownItem" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-dropdown-item-element>
  <app-checkbox-collection-element *ngSwitchCase="components.checkbox" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-checkbox-collection-element>
  <app-checkbox-element *ngSwitchCase="components.checkboxItem" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-checkbox-element>
  <app-checkbox-radio-element *ngSwitchCase="components.checkboxRadioItem" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-checkbox-radio-element>
  <app-textfield-element *ngSwitchCase="components.textfield" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-textfield-element>
  <app-textfield-element *ngSwitchCase="components.textfieldint" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-textfield-element>
  <app-textfield-element *ngSwitchCase="components.multilinetextfield" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-textfield-element>
  <app-signature-element *ngSwitchCase="components.signature" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-signature-element>
  <app-main-and-subfield-element *ngSwitchCase="components.mainandsubfield" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-main-and-subfield-element>
  <app-mainfield-element *ngSwitchCase="components.main" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-mainfield-element>
  <app-subfield-element *ngSwitchCase="components.subfield" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-subfield-element>
  <app-mainfield-element *ngSwitchCase="components.header" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-mainfield-element>
  <app-subfield-element *ngSwitchCase="components.subject" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-subfield-element>
  <app-address-element *ngSwitchCase="components.address" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-address-element>
  <app-slider-element *ngSwitchCase="components.slider" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-slider-element>
  <app-camera-element *ngSwitchCase="components.camera" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-camera-element>
  <app-tabular-form-element *ngSwitchCase="components.tabularform" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-tabular-form-element>
  <app-counter-element *ngSwitchCase="components.updown" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-counter-element>
  <app-toggle-element *ngSwitchCase="components.yesno" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-toggle-element>
  <app-image-element *ngSwitchCase="components.image" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-image-element>
  <app-empty-element *ngSwitchCase="components.empty" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-empty-element>
  <app-field-report-element *ngSwitchCase="components.fieldreport" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-field-report-element>
  <app-drawing-element *ngSwitchCase="components.drawing" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-drawing-element>
  <app-item-list-element *ngSwitchCase="components.itemlist" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></app-item-list-element>
  <checkd-forms-items-element *ngSwitchCase="components.formsitems" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></checkd-forms-items-element>
  <checkd-text-element *ngSwitchCase="components.text" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement"></checkd-text-element>
  <checkd-table-element *ngSwitchCase="components.table" [element]="element" [readOnly]="readOnly" [parentElement]="parentElement" (valueChanged)="valueChanged.emit($event)"></checkd-table-element>
</div>
