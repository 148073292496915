<form>
  <mat-form-field>
    <label>
      <input matInput type="text" placeholder="Search" [(ngModel)]="filterString" (keyup)="filterArray()" name="foo" />
    </label>
  </mat-form-field>
</form>

<div class="title-container">
  <h4>Choose FORM:</h4>
</div>

<mat-button-toggle-group [(ngModel)]="_currentTab" fxLayout="row" (change)="tabButtonValueChanged($event)">
  <mat-button-toggle value="project" fxFlex="50" [disabled]="!isProjectTemplatesAvailable">
    <span>Project <span fxHide.sm fxHide.lt-sm>Templates</span> ({{ projectTemplates.length }})</span>
  </mat-button-toggle>

  <mat-button-toggle value="company" fxFlex="50" [disabled]="!isCompanyTemplatesAvailable">
    <span>Company <span fxHide.lt-md>Templates</span> ({{ currentCompanyTemplates.length }})</span>
  </mat-button-toggle>
</mat-button-toggle-group>

<ng-container *ngIf="_currentTab == 'project' || _currentTab == 'company'; else neither">
  <span class="templates-for-text" [ngSwitch]="_currentTab">
    <ng-container *ngSwitchCase="'company'">
      Templates for <span class="bold-text">{{ userCompany.name }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="'project'">
      Templates for project <span class="bold-text">{{ project.name }}</span>
    </ng-container>
  </span>

  <div [ngStyle.gt-sm]="{ width: '525px' }" [ngStyle.sm]="{ width: '425px' }" [ngStyle.lt-sm]="{ 'min-width': '300px' }">
    <mat-list>
      <mat-list-item *ngFor="let template of _currentTemplates; let lastItem = last" (click)="selectTemplate(template)">
        {{ template.name }}
        <mat-divider *ngIf="!lastItem"></mat-divider>
      </mat-list-item>
    </mat-list>
  </div>
</ng-container>

<ng-template #neither>
  <div class="no-templates-container" fxLayout fxLayoutAlign="center center">
    No templates are available for project <span class="bold-text">&nbsp;{{ project.name }}&nbsp;</span> or company
    <span class="bold-text">&nbsp;{{ userCompany.name }}&nbsp;</span>
  </div>
</ng-template>
