import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-next-chat-logo",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg width="125" height="48" viewBox="0 0 125 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.4033 28.4652C38.4029 28.5226 38.3833 28.5782 38.3475 28.623C38.3118 28.6679 38.262 28.6995 38.2062 28.7127C38.1503 28.7259 38.0917 28.72 38.0396 28.6959C37.9876 28.6718 37.9451 28.6309 37.919 28.5798L32.2151 17.1646H27.7661V30.7532H30.1365V19.4477C30.137 19.3904 30.1567 19.3349 30.1925 19.2901C30.2283 19.2453 30.2781 19.2139 30.3338 19.2007C30.3896 19.1876 30.4482 19.1935 30.5003 19.2176C30.5523 19.2417 30.5947 19.2826 30.6207 19.3336L36.3246 30.7532H40.7704V17.1646H38.4028L38.4033 28.4652Z"
        fill="white"
      />
      <path
        d="M65.0495 17.1636L61.6885 22.8298C61.6646 22.8696 61.6308 22.9025 61.5904 22.9253C61.55 22.9482 61.5044 22.9602 61.458 22.9602C61.4115 22.9602 61.3659 22.9482 61.3255 22.9253C61.2851 22.9025 61.2513 22.8696 61.2274 22.8298L57.8664 17.1636H55.1958L59.2267 23.9593L55.1958 30.7544H57.8664L61.2274 25.0882C61.2513 25.0484 61.2851 25.0155 61.3255 24.9927C61.3659 24.9698 61.4115 24.9578 61.458 24.9578C61.5044 24.9578 61.55 24.9698 61.5904 24.9927C61.6308 25.0155 61.6646 25.0484 61.6885 25.0882L65.0495 30.7544H67.7185L63.6876 23.9593L66.4647 19.2778H70.5484C70.6522 19.2779 70.7517 19.3193 70.825 19.3927C70.8984 19.4662 70.9396 19.5657 70.9396 19.6695V30.7544H73.2475L73.311 19.6695C73.3116 19.566 73.3531 19.4669 73.4265 19.3939C73.4999 19.3209 73.5992 19.2799 73.7027 19.2799H78V17.1652L65.0495 17.1636Z"
        fill="white"
      />
      <path
        d="M55.5908 26.6033H53.1602C52.5489 27.8802 51.2935 28.5663 49.1357 28.5663C46.4328 28.5663 45.0327 27.4971 44.6442 25.3582C44.6049 25.1833 44.7023 25.0278 44.8772 25.0278H55.6489C56.0955 20.9839 54.8132 17.0176 49.154 17.0176C43.1273 17.0176 42.1931 20.9839 42.1931 23.9591C42.1931 29.6947 46.1595 30.9006 49.0953 30.9006C53.3743 30.9001 55.1049 28.7224 55.5908 26.6033ZM49.154 19.311C51.915 19.311 53.2371 20.4948 53.334 22.5966C53.3539 22.7521 53.2565 22.8883 53.101 22.8883H44.7997C44.6442 22.8883 44.5661 22.7521 44.5661 22.5966C44.6635 20.4006 46.1406 19.311 49.154 19.311Z"
        fill="white"
      />
      <path
        d="M9.96439 14.815L9.96439 33.1766C9.96439 35.1302 12.3869 36.0389 13.6717 34.5672L13.8038 34.4158C14.1401 34.0306 14.3254 33.5366 14.3254 33.0252L14.3254 14.6636C14.3254 12.71 11.9029 11.8013 10.6181 13.273L10.486 13.4244C10.1497 13.8096 9.96439 14.3036 9.96439 14.815Z"
        fill="#FA742B"
      />
      <path
        d="M22.8664 23.8287L13.7814 34.4416C12.5079 35.9294 10.0708 35.0358 10.0607 33.0775L10.0545 31.8558L19.5654 20.9724C20.3348 20.0919 21.6727 20.0031 22.5518 20.7741L22.6543 20.864C23.5248 21.6275 23.6193 22.9491 22.8664 23.8287Z"
        fill="url(#paint0_linear_4181_19463)"
      />
      <path
        d="M1.44297 23.9255L10.5279 13.3125C11.8014 11.8248 14.2385 12.7183 14.2486 14.6767L14.2549 15.8983L4.74394 26.7818C3.97453 27.6622 2.63661 27.7511 1.75755 26.9801L1.65504 26.8902C0.784546 26.1267 0.690009 24.8051 1.44297 23.9255Z"
        fill="url(#paint1_linear_4181_19463)"
      />
      <path
        d="M81.6619 24.1929C81.6619 22.7747 81.9423 21.5362 82.5033 20.4773C83.0706 19.4121 83.8648 18.5832 84.8858 17.9908C85.9132 17.3983 87.1202 17.1021 88.5069 17.1021C89.8935 17.1021 91.0407 17.3573 91.9483 17.8679C92.8622 18.3784 93.543 19.056 93.9905 19.9006C94.4443 20.7452 94.6775 21.6685 94.6901 22.6707H92.8748C92.8496 21.9711 92.6794 21.3219 92.3643 20.7231C92.0555 20.1243 91.5827 19.6421 90.9461 19.2766C90.3158 18.911 89.5028 18.7282 88.5069 18.7282C87.4984 18.7282 86.6191 18.952 85.8691 19.3995C85.119 19.847 84.536 20.4804 84.12 21.2998C83.704 22.1129 83.496 23.0773 83.496 24.1929C83.496 25.3022 83.704 26.2666 84.12 27.0859C84.536 27.9053 85.119 28.5388 85.8691 28.9863C86.6191 29.4338 87.4984 29.6576 88.5069 29.6576C89.5091 29.6576 90.3379 29.4716 90.9934 29.0997C91.6552 28.7279 92.1532 28.2205 92.4872 27.5776C92.8213 26.9347 93.0072 26.213 93.045 25.4125H94.8603C94.8162 26.4777 94.5546 27.4547 94.0756 28.3434C93.5965 29.2321 92.8938 29.9443 91.9672 30.4801C91.047 31.0158 89.8935 31.2837 88.5069 31.2837C87.1202 31.2837 85.9132 30.9875 84.8858 30.395C83.8648 29.8025 83.0706 28.9768 82.5033 27.9179C81.9423 26.8527 81.6619 25.6111 81.6619 24.1929Z"
        fill="white"
      />
      <path
        d="M96.7269 31.0001V17.3857H98.4287V22.7558C98.4287 22.8314 98.4666 22.8693 98.5422 22.8693C98.6178 22.8693 98.6746 22.8314 98.7124 22.7558C98.9897 22.2075 99.3742 21.7599 99.8658 21.4133C100.357 21.0603 101.076 20.8838 102.021 20.8838C103.206 20.8838 104.133 21.2273 104.801 21.9144C105.469 22.5951 105.803 23.5752 105.803 24.8547V31.0001H104.101V25.1383C104.101 24.237 103.871 23.5563 103.411 23.0962C102.957 22.6298 102.305 22.3965 101.454 22.3965C100.509 22.3965 99.7681 22.6833 99.2324 23.2569C98.6966 23.8305 98.4287 24.7097 98.4287 25.8947V31.0001H96.7269Z"
        fill="white"
      />
      <path
        d="M107.906 28.107C107.906 27.2561 108.196 26.5817 108.775 26.0838C109.362 25.5795 110.269 25.3022 111.498 25.2518L115.091 25.1005V24.5711C115.091 23.8714 114.899 23.3294 114.514 22.9449C114.13 22.5541 113.503 22.3587 112.633 22.3587C111.763 22.3587 111.136 22.5384 110.751 22.8976C110.373 23.2506 110.175 23.6823 110.156 24.1929H108.397C108.422 23.5689 108.59 23.0079 108.898 22.51C109.207 22.0058 109.67 21.6087 110.288 21.3187C110.906 21.0288 111.687 20.8838 112.633 20.8838C114.051 20.8838 115.088 21.221 115.743 21.8955C116.405 22.5699 116.736 23.4933 116.736 24.6656V31.0001H115.091V29.5063C115.091 29.4307 115.053 29.3928 114.978 29.3928C114.889 29.3928 114.826 29.437 114.788 29.5252C114.568 30.042 114.205 30.4643 113.701 30.7921C113.203 31.1198 112.532 31.2837 111.687 31.2837C110.553 31.2837 109.639 30.9906 108.946 30.4045C108.252 29.8183 107.906 29.0525 107.906 28.107ZM109.607 28.107C109.607 28.6239 109.812 29.0367 110.222 29.3456C110.632 29.6544 111.215 29.8088 111.971 29.8088C112.916 29.8088 113.673 29.5441 114.24 29.0147C114.807 28.4789 115.091 27.691 115.091 26.651V26.5376L111.498 26.6889C110.837 26.7141 110.354 26.8496 110.052 27.0954C109.756 27.3412 109.607 27.6784 109.607 28.107Z"
        fill="white"
      />
      <path
        d="M118.069 22.6802V21.1675H120.338V18.5202H122.04V21.1675H124.876V22.6802H122.04V26.7456C122.04 27.3129 122.031 27.7604 122.012 28.0881C121.999 28.4159 121.983 28.6711 121.964 28.8539C121.952 29.0367 121.945 29.1911 121.945 29.3172C121.945 29.4054 121.971 29.4748 122.021 29.5252C122.072 29.5756 122.141 29.6008 122.229 29.6008C122.412 29.6008 122.661 29.5819 122.976 29.5441C123.297 29.5063 123.836 29.4874 124.593 29.4874H124.876V31.0001H123.175C121.284 31.0001 120.338 30.0546 120.338 28.1638V22.6802H118.069Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_4181_19463" x1="10.0726" y1="35.3975" x2="22.5528" y2="20.8757" gradientUnits="userSpaceOnUse">
          <stop offset="0.482292" stop-color="#FA742B" />
          <stop offset="1" stop-color="#FF772C" stop-opacity="0.22" />
        </linearGradient>
        <linearGradient id="paint1_linear_4181_19463" x1="14.2367" y1="12.3567" x2="1.75653" y2="26.8784" gradientUnits="userSpaceOnUse">
          <stop offset="0.544792" stop-color="#FA742B" />
          <stop offset="1" stop-color="#FF772C" stop-opacity="0.22" />
        </linearGradient>
      </defs>
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class NextChatLogoComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }
}
