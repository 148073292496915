import { ChangeDetectionStrategy, Component, inject } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { map } from "rxjs/operators"

@Component({
  selector: "checkd-expired-invitation",
  templateUrl: "./expired-invitation.component.html",
  styleUrls: ["./expired-invitation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpiredInvitationComponent {
  readonly afAuth = inject(AngularFireAuth)

  readonly isSignedIn$ = this.afAuth.authState.pipe(map((user) => !!user))
}
