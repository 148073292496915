import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SvgIconsService, Size } from "../../services/svg-icons.service"

@Component({
  selector: "field-chat-lib-checked-icon",
  standalone: true,
  imports: [CommonModule],
  template: `
    <svg [attr.width]="getSize()" [attr.height]="getSize()" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.09 9.52001C29.6367 10.0239 29.6367 10.8386 29.09 11.2942L14.9392 25.445C14.4836 25.9917 13.6688 25.9917 13.165 25.445L5.87676 18.1552C5.37441 17.6996 5.37441 16.8848 5.87676 16.381C6.37906 15.8825 7.1938 15.8825 7.69605 16.381L14.0762 22.7649L27.3157 9.52001C27.8196 9.01937 28.6343 9.01937 29.09 9.52001Z"
        fill="#415BD8"
      />
    </svg>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SvgIconsService],
})
export class CheckedIconComponent {
  @Input() size: keyof Size | number = "md"

  constructor(private svgIconsService: SvgIconsService) {}

  getSize(): number | undefined {
    return this.svgIconsService.getSize(this.size)
  }
}
