<div class="w-full flex flex-column justify-content-center align-items-center gap-3">
  <div fxHide.xs fxFill fxLayout="row" class="company-logo" fxLayoutAlign="start">
    <img fxFlexOffset="50px" alt="Company logo" src="{{ logoSource }}" />
    <button *ngIf="canEditCompany" (click)="uploadLogo()" mat-icon-button matTooltip="Change logo" fxFlexAlign="end">
      <mat-icon svgIcon="settings_edit_on"></mat-icon>
    </button>
  </div>

  <div class="flex flex-column-reverse align-items-center gap-2">
    <h1 class="company-view__title">Company Page</h1>
    <mat-icon class="construction-hat-icon" svgIcon="personscreen_empty"></mat-icon>
  </div>

  <div class="flex flex-column justify-content-center align-items-center">
    <button primary-button [isRounded]="true" *ngIf="canInvitePeople" id="intercom-tour-add-employee" (click)="addEmployee()">
      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3910_12557)">
          <path
            d="M24 11.9994C24 12.7667 23.3827 13.384 22.6154 13.384H13.3846V22.6148C13.3846 23.3792 12.7644 24 12 24C11.2356 24 10.6154 23.3821 10.6154 22.6148V13.384H1.38462C0.620192 13.384 0 12.7644 0 12C0 11.2379 0.620192 10.6148 1.38462 10.6148H10.6154V1.38404C10.6154 0.619615 11.2356 0 12 0C12.7644 0 13.3846 0.619615 13.3846 1.38404V10.6148H22.6154C23.3827 10.6148 24 11.2379 24 11.9994Z"
            fill="currentcolor"
          />
        </g>
        <defs>
          <clipPath id="clip0_3910_12557">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <span>Add employee</span>
    </button>
  </div>

  <mat-accordion displayMode="default" multi="true">
    <mat-expansion-panel id="intercom-tour-company-details">
      <mat-expansion-panel-header>
        <mat-panel-title>Company details</mat-panel-title>
        <mat-panel-description>Information about your company</mat-panel-description>
      </mat-expansion-panel-header>

      <div class="company-info-box" fxLayout fxLayout.lt-lg="column" fxLayoutAlign.gt-md="space-around center">
        <span *ngIf="company?.orgNumber">Org number: {{ company?.orgNumber }}</span>
        <span *ngIf="company?.address as address">Address: {{ address?.street + ", " + address?.city }}</span>
      </div>

      <div fxLayout="row">
        <app-settings-group
          class="full-width"
          [readonly]="!canEditCompany"
          title=""
          (onEditable)="onEditable($event)"
          (onSave)="saveCompanyInfo()"
        >
          <form [formGroup]="companyInfoForm" fxLayout="column" fxLayoutAlign="space-between">
            <h4>Name</h4>
            <mat-form-field>
              <input formControlName="name" matInput placeholder="Company name" />
            </mat-form-field>

            <h4>Reports PDF settings</h4>
            <mat-form-field>
              <textarea matInput #footer maxlength="80" formControlName="pdfFooter" type="text" placeholder="Pdf footer text"></textarea>
              <mat-hint align="start"><strong>This will be displayed on all pdf exports in the footer section</strong></mat-hint>
              <mat-hint align="end">{{ footer.value.length }} / 80</mat-hint>
            </mat-form-field>
          </form>
        </app-settings-group>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel id="intercom-tour-employees">
      <mat-expansion-panel-header>
        <mat-panel-title>Employees</mat-panel-title>
        <mat-panel-description>Add your colleagues as users</mat-panel-description>
      </mat-expansion-panel-header>

      <people-list-item *ngFor="let person of people$ | async; trackBy: trackByUid" [person]="person">
        <ng-container *ngIf="currentUserCompanyRole && company && roles">
          <people-role-selector
            [roles]="roles"
            [person]="person"
            [peopleListOf]="company"
            [showRoleSelector]="canChangePeopleRole"
            [currentUserRole]="currentUserCompanyRole"
            [editorCount]="2"
            [ownerCount]="2"
          >
          </people-role-selector>

          <button *ngIf="currentUserCompanyRole.roleType === roleType.COMPANY_ADMINISTRATOR" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button mat-menu-item [disabled]="userRemovalInProgress" (click)="removeCompanyMemberClicked(person)">
              Remove member from company
            </button>
          </mat-menu>
        </ng-container>
      </people-list-item>

      <checkd-pending-invitations-list
        *ngIf="pendingCompanyInvites$ | async as invitations"
        [canRemoveInvitations]="currentUserCompanyRole.roleType === roleType.COMPANY_ADMINISTRATOR"
        [invitations]="invitations"
      ></checkd-pending-invitations-list>

      <mat-action-row>
        <button primary-button [isRounded]="true" *ngIf="canInvitePeople" (click)="addEmployee()">
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3910_12557)">
              <path
                d="M24 11.9994C24 12.7667 23.3827 13.384 22.6154 13.384H13.3846V22.6148C13.3846 23.3792 12.7644 24 12 24C11.2356 24 10.6154 23.3821 10.6154 22.6148V13.384H1.38462C0.620192 13.384 0 12.7644 0 12C0 11.2379 0.620192 10.6148 1.38462 10.6148H10.6154V1.38404C10.6154 0.619615 11.2356 0 12 0C12.7644 0 13.3846 0.619615 13.3846 1.38404V10.6148H22.6154C23.3827 10.6148 24 11.2379 24 11.9994Z"
                fill="currentcolor"
              />
            </g>
            <defs>
              <clipPath id="clip0_3910_12557">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Add employee</span>
        </button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header id="intercom-tour-item-tag-manager">
        <mat-panel-title>Item tag manager</mat-panel-title>
        <mat-panel-description>Create and manage company item tags</mat-panel-description>
      </mat-expansion-panel-header>
      <checkd-tags placeholder="Add more item tags" [tags]="tags" [areTagsEditable]="canEditCompany" (onTagsUpdated)="updateTags($event)">
      </checkd-tags>
    </mat-expansion-panel>

    <!--    UNCOMMENT THIS WHEN WE ENABLE 2FA-->
    <mat-expansion-panel *ngIf="canEditCompany && companyHasSsoAdLoginFeature">
      <mat-expansion-panel-header id="invite-people-using-ad">
        <mat-panel-title>Invitation settings</mat-panel-title>
        <mat-panel-description>Settings of how invitations behave</mat-panel-description>
      </mat-expansion-panel-header>
      <p>
        <p-checkbox
          name="restrict-to-ad"
          [disabled]="checkLoginMethodPresent('microsoft')"
          [(ngModel)]="loginMethods"
          value="microsoft"
          (onChange)="updateLoginMethods()"
        ></p-checkbox>
        Enable AD login
      </p>
      <p>
        <p-checkbox
          name="restrict-to-ad"
          [disabled]="false"
          [(ngModel)]="loginMethods"
          value="usernameAndPassword"
          (onChange)="updateLoginMethods()"
        ></p-checkbox>
        Enable username and password
      </p>
    </mat-expansion-panel>

    <!--    <mat-expansion-panel *ngIf="companyHasPublicTemplateLibraryFeature">-->
    <!--      <mat-expansion-panel-header id="intercom-tour-public-templates-library">-->
    <!--        <mat-panel-title>Forms Library</mat-panel-title>-->
    <!--        <mat-panel-description>Change settings for Forms library</mat-panel-description>-->
    <!--      </mat-expansion-panel-header>-->

    <!--      <mat-slide-toggle-->
    <!--        *ngIf="company"-->
    <!--        id="assignedTaskMail"-->
    <!--        (change)="updateTemplatesLibraryOption($event)"-->
    <!--        [disabled]="!canEditCompany"-->
    <!--        [checked]="company.isTemplatePublisher"-->
    <!--      >-->
    <!--        Take part of CHECKD Forms Library-->
    <!--      </mat-slide-toggle>-->

    <!--      <mat-action-row>-->
    <!--        <button mat-button class="checkd-btn confirm-btn-color" routerLink="/forms/library">Go to forms library</button>-->
    <!--      </mat-action-row>-->
    <!--    </mat-expansion-panel>-->
  </mat-accordion>
</div>
<p-toast position="bottom-center" key="bc"></p-toast>
