import { Component, Inject, OnInit } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"

export interface ILangEntry {
  lang_button_label: string
  title_text?: string
  paragraphs: string[]
  confirm_button_label?: string
  cancel_button_label?: string
}

export interface IMultiLangDialogData {
  cancelButton: boolean
  languageEntries: ILangEntry[]
}

@Component({
  selector: "checkd-multi-lang-confirmation-dialog",
  templateUrl: "./multi-lang-confirmation-dialog.component.html",
  styleUrls: ["./multi-lang-confirmation-dialog.component.scss"],
})
export class MultiLangConfirmationDialogComponent implements OnInit {
  currentLanguageIndex: number = 0

  constructor(
    public dialogRef: MatDialogRef<MultiLangConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IMultiLangDialogData
  ) {}

  ngOnInit() {
    return
  }

  get showLanguageSelection(): boolean {
    return this.data.languageEntries.length > 1
  }

  get title() {
    return this.data.languageEntries[this.currentLanguageIndex].title_text
  }

  get confirmButtonText(): string {
    if (this.data.languageEntries[this.currentLanguageIndex].confirm_button_label) {
      // @ts-ignore
      return this.data.languageEntries[this.currentLanguageIndex].confirm_button_label
    }

    return "OK"
  }

  get cancelButtonText(): string {
    if (this.data.languageEntries[this.currentLanguageIndex].cancel_button_label) {
      // @ts-ignore
      return this.data.languageEntries[this.currentLanguageIndex].cancel_button_label
    }

    return "Cancel"
  }
}
