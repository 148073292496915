import { ImageAggregateData } from "./aggregate-data"
import { BaseModel } from "./base-model"
import { ImageData } from "./image.interface"

export class Image extends BaseModel<ImageData> {
  public static override COLLECTION: string = "images"

  get name() {
    if (this.data.name == null) return ""
    return this.data.name
  }

  get description() {
    if (this.data.description == null) return ""
    return this.data.description
  }

  get url() {
    if (this.data.url == null) return "" // TODO Add missing image placeholder
    return this.data.url
  }

  override get createdAt(): number {
    return this.data.createdAt || 0
  }

  override get aggregateData(): Partial<ImageAggregateData> {
    return this.data.aggregateData || {}
  }

  public static get(uid: string): Promise<Image> {
    return Image.doc(uid)
      .get()
      .then((snapshot) => {
        if (!snapshot.exists) throw `Image ${uid} not found`
        return new Image(snapshot.data() as ImageData, snapshot.id, snapshot.ref)
      })
  }

  public static create(data: ImageData): Promise<Image> {
    return Image.db
      .collection(Image.COLLECTION)
      .add(data)
      .then((docRef) => docRef.get())
      .then((docSnapshot) => new Image(docSnapshot.data() as ImageData, docSnapshot.id, docSnapshot.ref))
  }
}
