import firebase from "firebase/compat/app"
import firestore = firebase.firestore
import { ModelInCollection } from "./collections"
import { createNewModelInstance } from "./utilities"
import { DB } from "./constants"
import { RELATION_COLLECTIONS } from "./collections.interface"
import { INVERTED_LABELS, LABEL_PRECEDENCE, LABELS, RelationData } from "./relation.interface"

// NB: We're only establishing precendence for non-inverse labels for
// now as we mostly need it for roles from people to other documents
// and relations.

export class Relation {
  public static LABELS = LABELS
  public static INVERTED_LABELS = INVERTED_LABELS

  protected static db = DB

  constructor(public data: RelationData, public uid?: string, public ref?: firestore.DocumentReference) {}

  get link() {
    return this.data.link
  }
  get labels() {
    return this.data.labels
  }
  get disabled() {
    return this.data.disabled
  }
  get archived() {
    return this.data.archived ? this.data.archived : false
  }
  get lockedByCheckd() {
    return this.data.lockedByCheckd ? this.data.lockedByCheckd : false
  }
  get source() {
    return this.data.source
  }
  get sourcePath() {
    return this.data.sourcePath
  }
  get sourceRef() {
    return this.data.sourceRef
  }
  get targetPath() {
    return this.data.targetPath
  }
  get targetRef() {
    return this.data.targetRef
  }
  get hasNoLabels() {
    return this.labels == null || this.labels.length < 1
  }
  get sourceCollectionName() {
    return this.data.sourcePath!.split("/").filter((it) => it)[0]
  }
  get targetCollectionName() {
    return this.data.targetPath!.split("/").filter((it) => it)[0]
  }
  get sourceUid() {
    return this.data.sourcePath!.split("/").filter((it) => it)[1]
  }
  get targetUid() {
    return this.data.targetPath!.split("/").filter((it) => it)[1]
  }
  get aggregateData() {
    return this.data.aggregateData || {}
  }

  public batchUpdate(batch: firestore.WriteBatch, data: Partial<RelationData>) {
    batch.update(this.ref!, data)
    this.data = { ...this.data, ...data }
  }

  public batchDisable(batch: firestore.WriteBatch) {
    return this.batchUpdate(batch, { disabled: true })
  }

  public batchEnable(batch: firestore.WriteBatch) {
    return this.batchUpdate(batch, { disabled: false })
  }

  public getHighestPrecedentLabel() {
    return Relation.getHighestPrecedentLabel(this.labels)
  }

  public batchAddLabels(batch: firestore.WriteBatch, labels: string[]) {
    const newLabels = Relation.mergeLabels(this.labels, labels)
    batch.update(this.ref!, { labels: newLabels })
    this.data.labels = newLabels

    return this
  }

  public batchRemoveLabels(batch: firestore.WriteBatch, labels: string[]): Relation {
    const filteredLabels = this.labels.filter((label) => !labels.includes(label))
    batch.update(this.ref!, { labels: filteredLabels })
    this.data.labels = filteredLabels

    return this
  }

  public getInverse() {
    return Relation.getInverseRelation(this)
  }

  public static getBoth<M1 extends ModelInCollection, M2 extends ModelInCollection>(source: M1, target: M2): Promise<Relation[]> {
    return Promise.all([Relation.get(source, target), Relation.get(target, source)])
  }

  public static create<M1 extends ModelInCollection, M2 extends ModelInCollection>(
    source: M1,
    target: M2,
    data: RelationData
  ): Promise<Relation> {
    data.sourceRef = source.ref
    data.targetRef = target.ref
    data.sourcePath = source.ref!.path
    data.targetPath = target.ref!.path
    data.source = source.uid
    data.link = target.uid!

    return source
      .collection(target.collectionName)
      .doc(target.uid)
      .set(data, { merge: true })
      .then((_) => Relation.get(source, target))
  }

  // Creates the relation if doesn't exist
  //
  // If relation exists then copies then merges the new data in to it
  // and RENABLES the existing relation
  //
  public static addOrUpdate<M1 extends ModelInCollection, M2 extends ModelInCollection>(
    source: M1,
    target: M2,
    relationData: RelationData
  ): Promise<Relation> {
    return Relation.get(source, target)
      .then((rel) => rel.update(Relation.mergeData(rel.data, relationData)))
      .then((rel) => rel.enable())
      .catch((_) => Relation.create(source, target, relationData))
  }

  public static batchAddOrUpdate<M1 extends ModelInCollection, M2 extends ModelInCollection>(
    batch: firestore.WriteBatch,
    source: M1,
    target: M2,
    relationData: RelationData
  ): Relation {
    const docRef = Relation.db.collection(source.collectionName).doc(source.uid).collection(target.collectionName).doc(target.uid)
    const data = Object.assign({}, relationData)
    // @ts-ignore
    delete data.labels

    batch.set(docRef, data, { merge: true })

    for (const label of relationData.labels || []) {
      batch.update(docRef, { labels: firestore.FieldValue.arrayUnion(label) })
    }

    return new Relation(relationData, docRef.id, docRef)
  }

  public static getTarget(relation: Relation) {
    const collectionName = relation.ref!.parent.id

    return Relation.db
      .doc(relation.targetPath!)
      .get()
      .then((docSnap) => {
        return createNewModelInstance(collectionName, docSnap.data(), docSnap.id, docSnap.ref)
      })
  }

  public static async getInverseRelation(relation: Relation): Promise<Relation> {
    const relationSnap = await Relation.db
      .collection(relation.targetCollectionName)
      .doc(relation.targetUid)
      .collection(relation.sourceCollectionName)
      .doc(relation.sourceUid)
      .get()

    return new Relation(relationSnap.data() as RelationData, relationSnap.id, relationSnap.ref)
  }

  public static async get<M1 extends ModelInCollection, M2 extends ModelInCollection>(source: M1, target: M2): Promise<Relation> {
    const snapshot = await source.collection(target.collectionName).doc(target.uid).get()

    // @ts-ignore
    return snapshot.exists ? new Relation(snapshot.data() as RelationData, snapshot.id, snapshot.ref) : null
  }

  public static async getAll<M extends ModelInCollection>(source: M, collectionName: string, labels: string[] = []): Promise<Relation[]> {
    let baseQuery = source.collection(collectionName).where("disabled", "==", false)

    if (labels.length > 0) {
      const firstLabel = labels[0]
      baseQuery = baseQuery.where("labels", "array-contains", firstLabel)
    }

    const qSnaps = await baseQuery.get()

    const relations = qSnaps.docs.map((snap) => new Relation(snap.data() as RelationData, snap.id, snap.ref))

    if (labels.length > 1) {
      const remainingLabels = labels.slice(1)
      return relations.filter((relation) => relation.labels.every((element) => remainingLabels.includes(element)))
    }
    return relations
  }

  public static async getAllTargets<SourceM extends ModelInCollection>(source: SourceM, collectionName: string, labels: string[] = []) {
    const relations = await Relation.getAll(source, collectionName, labels)
    return Promise.all(relations.map((relation) => Relation.getTarget(relation)))
  }

  public static getAllRelations<M extends ModelInCollection>(source: M): Promise<{ [x: string]: Relation[] }> {
    let relationCollections = RELATION_COLLECTIONS.map((collectionName) =>
      Relation.getAll(source, collectionName).then((collection) => ({ [collectionName]: collection }))
    )
    return Promise.all(relationCollections).then((relationCollections) => {
      let mergedCollections = Object.assign({}, ...relationCollections)
      return (
        Object.keys(mergedCollections)
          .filter((key) => mergedCollections[key].length > 0)
          // @ts-ignore
          .reduce((res, key) => ((res[key] = mergedCollections[key]), res), {})
      )
    })
  }

  public static invertLabel(label: string) {
    if (!Relation.INVERTED_LABELS.hasOwnProperty(label)) return null
    return Relation.INVERTED_LABELS[label]
  }

  public static invertLabels(labels: string[]) {
    return labels.map(Relation.invertLabel).filter((rel) => rel)
  }

  public static mergeLabels(labelsA: string[], labelsB: string[]) {
    return Array.from(new Set([...labelsA, ...labelsB]))
  }

  public static invertRelationData(source: string, relationData: RelationData): RelationData {
    return {
      source: relationData.link,
      link: source,
      labels: Relation.invertLabels(relationData.labels),
      disabled: relationData.disabled,
      archived: relationData.archived,
      sourcePath: relationData.targetPath,
      targetPath: relationData.sourcePath,
      sourceRef: relationData.targetRef,
      targetRef: relationData.sourceRef,
    } as RelationData
  }

  public static invertRelation(source: any, relationData: RelationData) {
    return Object.assign({}, relationData, Relation.invertRelationData(source, relationData))
  }

  public static mergeData(rel1: RelationData, rel2: RelationData) {
    return Object.assign({}, rel1, {
      labels: Relation.mergeLabels(rel1.labels, rel2.labels),
    })
  }

  public static delete(relation: Relation): Promise<void> {
    return relation.ref!.delete()
  }

  public static batchDelete(batch: firestore.WriteBatch, relation: Relation) {
    return batch.delete(relation.ref!)
  }

  public static deleteTwoWay<M1 extends ModelInCollection, M2 extends ModelInCollection>(source: M1, target: M2) {
    return Promise.all([Relation.get(source, target), Relation.get(target, source)]).then((relations) =>
      Promise.all(relations.map((relation) => this.delete(relation)))
    )
  }

  public static disable(relation: Relation): Promise<Relation> {
    relation.data.disabled = true
    return relation.update()
  }

  public static enable(relation: Relation): Promise<Relation> {
    relation.data.disabled = false
    return relation.update()
  }

  public static disableTwoWay<M1 extends ModelInCollection, M2 extends ModelInCollection>(source: M1, target: M2) {
    return Promise.all([Relation.get(source, target), Relation.get(target, source)]).then((relations) =>
      Promise.all(relations.map((relation) => relation.disable()))
    )
  }

  public static copy<
    M1 extends ModelInCollection,
    M2 extends ModelInCollection,
    M3 extends ModelInCollection,
    M4 extends ModelInCollection
  >(oldSource: M1, oldTarget: M2, newSource: M3, newTarget: M4): Promise<Relation> {
    return Relation.get(oldSource, oldTarget).then((oldRelation) => Relation.addOrUpdate(newSource, newTarget, oldRelation.data))
  }

  public static copyTwoWay<
    M1 extends ModelInCollection,
    M2 extends ModelInCollection,
    M3 extends ModelInCollection,
    M4 extends ModelInCollection
  >(oldSource: M1, oldTarget: M2, newSource: M3, newTarget: M4): Promise<Relation[]> {
    return Promise.all([
      Relation.copy(oldSource, oldTarget, newSource, newTarget),
      Relation.copy(oldTarget, oldSource, newTarget, newSource),
    ])
  }

  public static copyAll<M1 extends ModelInCollection, M2 extends ModelInCollection>(oldSource: M1, newSource: M2, collectionName: string) {
    return Relation.getAllTargets(oldSource, collectionName).then((targets) =>
      Promise.all(targets.map((target) => Relation.copy(oldSource, target, newSource, target)))
    )
  }

  public static move<
    M1 extends ModelInCollection,
    M2 extends ModelInCollection,
    M3 extends ModelInCollection,
    M4 extends ModelInCollection
  >(oldSource: M1, oldTarget: M2, newSource: M3, newTarget: M4): Promise<Relation> {
    return Relation.get(oldSource, oldTarget).then((oldRelation) =>
      Relation.copy(oldSource, oldTarget, newSource, newTarget).then((newRelation) => oldRelation.disable().then((_) => newRelation))
    )
  }

  public static moveHard<
    M1 extends ModelInCollection,
    M2 extends ModelInCollection,
    M3 extends ModelInCollection,
    M4 extends ModelInCollection
  >(oldSource: M1, oldTarget: M2, newSource: M3, newTarget: M4): Promise<Relation> {
    return Relation.get(oldSource, oldTarget).then((oldRelation) =>
      Relation.copy(oldSource, oldTarget, newSource, newTarget).then((newRelation) => Relation.delete(oldRelation).then((_) => newRelation))
    )
  }

  public static hasEqualLabels(relA: Relation, relB: Relation) {
    let labelsA = relA.labels
    let labelsB = relB.labels
    if (labelsA.length != labelsB.length) return false
    return labelsA.every((label) => labelsB.includes(label)) && labelsB.every((label) => labelsA.includes(label))
  }

  public static hasEqualTargets(relA: Relation, relB: Relation) {
    return relA.link == relB.link || relA.targetPath == relB.targetPath
  }

  public static hasEqualSources(relA: Relation, relB: Relation) {
    return relA.source == relB.source || relA.sourcePath == relB.sourcePath
  }

  public static isEqual(relA: Relation, relB: Relation) {
    return Relation.hasEqualSources(relA, relB) && Relation.hasEqualTargets(relA, relB) && Relation.hasEqualLabels(relA, relB)
  }

  /**
   * Orders a list of labels according to the precedence as defined by
   * LABEL_PRECEDENCE. Label that don't exist in LABEL_PRECEDENCE are
   * added in arbitrary order after the matching labels.
   *
   * @param labels a list of strings
   */
  public static orderLabelsByPrecedence(labels: string[]): string[] {
    const matchingLabels: string[] = []
    const nonMatchingLabels: string[] = []

    LABEL_PRECEDENCE.forEach((labelA) => {
      labels.forEach((labelB) => {
        if (labelA == labelB) {
          matchingLabels.push(labelB)
        } else {
          nonMatchingLabels.push(labelB)
        }
      })
    })

    return [...matchingLabels, ...nonMatchingLabels]
  }

  public static getHighestPrecedentLabel(labels: string[]) {
    return Relation.orderLabelsByPrecedence(labels)[0] || LABELS.NONE
  }

  // The following methods are deprecated.
  // Avoid using them, and remove their usage elsewhere if you have the time.

  /**
   *
   * @deprecated use batchUpdate instead
   */
  public update(data?: RelationData): Promise<Relation> {
    let updatedData = data != null ? data : this.data
    return this.ref!.update(updatedData).then((_) => new Relation(updatedData, this.uid, this.ref))
  }

  /**
   * @deprecated use batchDisable instead
   */
  public disable(): Promise<Relation> {
    return Relation.disable(this)
  }

  /**
   * @deprecated use batchEnable instead
   */
  public enable(): Promise<Relation> {
    return Relation.enable(this)
  }

  /**
   *
   * @deprecated use batchAddLabels instead
   */
  public addLabels(labels: string[]): Promise<Relation> {
    this.data.labels = Relation.mergeLabels(this.labels, labels)
    return this.update()
  }

  /**
   *
   * @deprecated use batchRemoveLabels instead
   */
  public removeLabels(labels: string[]): Promise<Relation> {
    this.data.labels = this.labels.filter((label) => !labels.includes(label))
    return this.update()
  }
}
