import firebase from "firebase/compat/app"
import firestore = firebase.firestore

export interface ITagCollectionData {
  name: string
  tags: string[]
}

export class TagCollection {
  public uid: string

  public get data() {
    return this._data
  }
  public get name() {
    return this.data.name
  }
  public get tags() {
    return this.data.tags
  }

  constructor(public ref: firestore.DocumentReference, private _data: ITagCollectionData) {
    this.uid = ref.id
  }

  public update(batch: firestore.WriteBatch, data: ITagCollectionData): TagCollection {
    batch.update(this.ref, data)
    this._data = { ...this._data, ...data }

    return this
  }

  public updateTags(tags: string[]) {
    return this.ref.update({ tags })
  }

  public addTags(tags: string[]) {
    return this.ref.update({
      tags: firestore.FieldValue.arrayUnion(tags),
    })
  }

  public addTagsBatch(batch: firestore.WriteBatch, tags: string[]) {
    batch.update(this.ref, {
      tags: firestore.FieldValue.arrayUnion(...tags),
    })
  }
}
