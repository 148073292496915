import { Component, OnInit, Output, EventEmitter } from "@angular/core"
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms"
import { UserService, SnackbarService } from "@services"

@Component({
  selector: "app-account-activation-view",
  templateUrl: "./account-activation-view.component.html",
  styleUrls: ["./account-activation-view.component.scss"],
})
export class AccountActivationViewComponent implements OnInit {
  @Output() submit = new EventEmitter()
  email = new UntypedFormControl("", [Validators.required, Validators.email])
  form: UntypedFormGroup
  wasEmailSubmitted: boolean = false

  constructor(private fb: UntypedFormBuilder, private userService: UserService, private snackbarService: SnackbarService) {
    this.form = fb.group({
      email: this.email,
    })
  }

  ngOnInit() {}

  onSubmit(email: string) {
    if (this.email.valid) {
      this.wasEmailSubmitted = true
      //this.submit.emit(email);
      this.userService
        .sendPasswordResetEmail(email)
        .then((_) => {
          this.snackbarService.showMessage(`Password reset email sent to ${email}!`)
        })
        .catch((err) => {
          this.snackbarService.showMessage(`ERROR: ${err}`)
        })
    }
  }
}
